export const drugCatalog = [
  {
    groupCode: '13318',
    genericName: 'METFORMIN HCL TABLET 500 MG OR',
    strength: '500MG',
    unitPrice: 0.287,
    MFRs: {
      PMS: {
        _id: '6201ba27f65f11ad3d79cd07',
        DIN: '2223562',
        genericName: 'METFORMIN HCL TABLET 500 MG OR',
        supplierCode: 'PMS',
        brandName: 'METFORMIN TB 500MG 500 PMS',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '30',
        packPrice: 12.35,
        dailyAverageDispensed: 575,
      },
      SDZ: {
        _id: '6201ba22f65f11ad3d7948ba',
        DIN: '2246820',
        genericName: 'METFORMIN HCL TABLET 500 MG OR',
        supplierCode: 'SDZ',
        brandName: 'METFORMIN FC TB 500MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '30',
        packPrice: 2.47,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d173',
        DIN: '2257726',
        genericName: 'METFORMIN HCL TABLET 500 MG OR',
        supplierCode: 'TEV',
        brandName: 'METFORMIN TB 500MG 100 ACT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 2.47,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d790494',
        DIN: '2380196',
        genericName: 'METFORMIN HCL TABLET 500 MG OR',
        supplierCode: 'JAP',
        brandName: 'METFORMIN TB 500MG 500 JAMP',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '25',
        packPrice: 12.35,
        dailyAverageDispensed: 0,
      },
      SAC: {
        _id: '6201ba25f65f11ad3d798d94',
        DIN: '2099233',
        genericName: 'METFORMIN HCL TABLET 500 MG OR',
        supplierCode: 'SAC',
        brandName: 'GLUCOPHAGE TB 500MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 28.66,
        dailyAverageDispensed: 0,
      },
      RIV: {
        _id: '6201ba27f65f11ad3d79bfbf',
        DIN: '2239081',
        genericName: 'METFORMIN HCL TABLET 500 MG OR',
        supplierCode: 'RIV',
        brandName: 'METFORMIN TB 500MG 500 RIVA',
        form: 'TABLET',
        packSize: '500',
        rebateValue: null,
        packPrice: 12.35,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79b0a5',
        DIN: '2353377',
        genericName: 'METFORMIN HCL TABLET 500 MG OR',
        supplierCode: 'SNS',
        brandName: 'METFORMIN TB 500MG 360 SNS',
        form: 'TABLET',
        packSize: '360',
        rebateValue: null,
        packPrice: 8.89,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d794593',
        DIN: '2438275',
        genericName: 'METFORMIN HCL TABLET 500 MG OR',
        supplierCode: 'AUP',
        brandName: 'METFORMIN TB 500MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '0',
        packPrice: 2.47,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 575,
      total: 0,
    },
  },
  {
    groupCode: '51214',
    genericName: 'EZETIMIBE TABLET 10 MG ORAL',
    strength: '10MG',
    unitPrice: 2.051,
    MFRs: {
      AHC: {
        _id: '6201ba20f65f11ad3d791e54',
        DIN: '2425610',
        genericName: 'EZETIMIBE TABLET 10 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'EZETIMIBE TB 10MG UD 30 ACH',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '68',
        packPrice: 5.43,
        dailyAverageDispensed: 184,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d791c62',
        DIN: '2354101',
        genericName: 'EZETIMIBE TABLET 10 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'EZETIMIBE TB 10MG 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 5.43,
        dailyAverageDispensed: 263,
      },
      RAN: {
        _id: '6201ba20f65f11ad3d791bef',
        DIN: '2419548',
        genericName: 'EZETIMIBE TABLET 10 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'EZETIMIBE TB 10MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '57',
        packPrice: 18.11,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d791afe',
        DIN: '2422662',
        genericName: 'EZETIMIBE TABLET 10 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'EZETIMIBE TB 10MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 18.11,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba24f65f11ad3d79772a',
        DIN: '2416778',
        genericName: 'EZETIMIBE TABLET 10 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'EZETIMIBE TB 10MG BLS 3X10 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 5.43,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba20f65f11ad3d791ccb',
        DIN: '2423235',
        genericName: 'EZETIMIBE TABLET 10 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'EZETIMIBE TB 10MG UD 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 5.43,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d791c30',
        DIN: '2423243',
        genericName: 'EZETIMIBE TABLET 10 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'EZETIMIBE TB 10MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '45',
        packPrice: 18.11,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba20f65f11ad3d791cb8',
        DIN: '2416409',
        genericName: 'EZETIMIBE TABLET 10 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'EZETIMIBE TB 10MG UD 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '40',
        packPrice: 5.43,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba20f65f11ad3d791ce5',
        DIN: '2427826',
        genericName: 'EZETIMIBE TABLET 10 MG ORAL',
        supplierCode: 'APX',
        brandName: 'EZETIMIBE TB 10MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 18.11,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba20f65f11ad3d790ba9',
        DIN: '2247521',
        genericName: 'EZETIMIBE TABLET 10 MG ORAL',
        supplierCode: 'ORN',
        brandName: 'EZETROL TB 10MG BLS 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 61.54,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d7923d1',
        DIN: '2431300',
        genericName: 'EZETIMIBE TABLET 10 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'EZETIMIBE TB 10MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 18.11,
        dailyAverageDispensed: 0,
      },
      GLN: {
        _id: '6201ba24f65f11ad3d79852a',
        DIN: '2460750',
        genericName: 'EZETIMIBE TABLET 10 MG ORAL',
        supplierCode: 'GLN',
        brandName: 'EZETIMIBE TB 10MG 100 GLN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 18.11,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d796161',
        DIN: '2469286',
        genericName: 'EZETIMIBE TABLET 10 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'EZETIMIBE TB 10MG BLST 3X10 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 5.43,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 447,
      total: 0,
    },
  },
  {
    groupCode: '16995',
    genericName: 'ACETYLSALICYLIC ACID TABLET DR',
    strength: '81MG',
    unitPrice: 0.134,
    MFRs: {
      JAP: {
        _id: '6201ba21f65f11ad3d792400',
        DIN: '2427206',
        genericName: 'ACETYLSALICYLIC ACID TABLET DR',
        supplierCode: 'JAP',
        brandName: 'ASA TB 81MG EC TB 300 JAMP',
        form: 'TABLET',
        packSize: '300',
        rebateValue: '41',
        packPrice: 15.9,
        dailyAverageDispensed: 305,
      },
      PMS: {
        _id: '6201ba24f65f11ad3d79737e',
        DIN: '2283700',
        genericName: 'ACETYLSALICYLIC ACID TABLET DR',
        supplierCode: 'PMS',
        brandName: 'PRAXIS ASA TB COATED 81MG 1000',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '1',
        packPrice: 58.75,
        dailyAverageDispensed: 0,
      },
      BAY: {
        _id: '6201ba20f65f11ad3d7917e5',
        DIN: '2237726',
        genericName: 'ACETYLSALICYLIC ACID TABLET DR',
        supplierCode: 'BAY',
        brandName: 'ASPIRIN COAT TB 81MG DAILY LOW DOSE 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 4.02,
        dailyAverageDispensed: 0,
      },
      PPI: {
        _id: '6201ba26f65f11ad3d79bc17',
        DIN: '2242281',
        genericName: 'ACETYLSALICYLIC ACID TABLET DR',
        supplierCode: 'PPI',
        brandName: 'ENTROPHEN EC TB 81MG 120',
        form: 'TABLET',
        packSize: '120',
        rebateValue: null,
        packPrice: 10.15,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 305,
      total: 0,
    },
  },
  {
    groupCode: '51785',
    genericName: 'ROSUVASTATIN CALCIUM TABLET 20',
    strength: '20MG',
    unitPrice: 1.843,
    MFRs: {
      TAR: {
        _id: '6201ba24f65f11ad3d7988c6',
        DIN: '2382660',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 20',
        supplierCode: 'TAR',
        brandName: 'ROSUVASTATIN TB 20MG 100 TARO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 16.92,
        dailyAverageDispensed: 225,
      },
      JAP: {
        _id: '6201ba25f65f11ad3d799e57',
        DIN: '2498359',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 20',
        supplierCode: 'JAP',
        brandName: 'ROSUVASTATIN CALCIUM TB 20MG BLST 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '75',
        packPrice: 5.08,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba24f65f11ad3d797665',
        DIN: '2438933',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 20',
        supplierCode: 'AHC',
        brandName: 'ROSUVASTATIN TB 20MG 90 ACH',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '67',
        packPrice: 15.23,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d790169',
        DIN: '2354624',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 20',
        supplierCode: 'TEV',
        brandName: 'ROSUVASTATIN TB 20MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 5.08,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d79015b',
        DIN: '2378558',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 20',
        supplierCode: 'PMS',
        brandName: 'ROSUVASTATIN TB 20MG BLS 3X10 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 5.08,
        dailyAverageDispensed: 2,
      },
      APX: {
        _id: '6201ba1ff65f11ad3d790113',
        DIN: '2337991',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 20',
        supplierCode: 'APX',
        brandName: 'ROSUVASTATIN TB 20MG UD 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '58',
        packPrice: 5.08,
        dailyAverageDispensed: 35,
      },
      SDZ: {
        _id: '6201ba26f65f11ad3d79a5ca',
        DIN: '2338742',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 20',
        supplierCode: 'SDZ',
        brandName: 'ROSUVASTATIN TB 20MG UD BLST 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '57',
        packPrice: 5.08,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba1ff65f11ad3d7905bd',
        DIN: '2247163',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 20',
        supplierCode: 'AST',
        brandName: 'CRESTOR TB 20MG BLS 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 55.29,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79aca7',
        DIN: '2405644',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 20',
        supplierCode: 'SNS',
        brandName: 'ROSUVASTATIN TB 20MG 500 SNS',
        form: 'TABLET',
        packSize: '500',
        rebateValue: null,
        packPrice: 84.6,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba24f65f11ad3d797714',
        DIN: '2442590',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 20',
        supplierCode: 'AUP',
        brandName: 'ROSUVASTATIN TB 20MG 3X10 BLS AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 5.08,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 262,
      total: 0,
    },
  },
  {
    groupCode: '17956',
    genericName: 'BISOPROLOL FUMARATE TABLET 5 M',
    strength: '5MG',
    unitPrice: 0.072,
    MFRs: {
      SDZ: {
        _id: '6201ba24f65f11ad3d7985ee',
        DIN: '2494035',
        genericName: 'BISOPROLOL FUMARATE TABLET 5 M',
        supplierCode: 'SDZ',
        brandName: 'BISOPROLOL TB 5MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '66',
        packPrice: 7.15,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba28f65f11ad3d79dc2f',
        DIN: '2267470',
        genericName: 'BISOPROLOL FUMARATE TABLET 5 M',
        supplierCode: 'TEV',
        brandName: 'BISOPROLOL TB 5MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 7.15,
        dailyAverageDispensed: 226,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79d08a',
        DIN: '2256134',
        genericName: 'BISOPROLOL FUMARATE TABLET 5 M',
        supplierCode: 'APX',
        brandName: 'BISOPROLOL TB 5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '41',
        packPrice: 7.15,
        dailyAverageDispensed: 1,
      },
      MPH: {
        _id: '6201ba23f65f11ad3d796e90',
        DIN: '2465612',
        genericName: 'BISOPROLOL FUMARATE TABLET 5 M',
        supplierCode: 'MPH',
        brandName: 'BISOPROLOL TB 5MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 7.15,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba25f65f11ad3d799310',
        DIN: '2391589',
        genericName: 'BISOPROLOL FUMARATE TABLET 5 M',
        supplierCode: 'SNS',
        brandName: 'BISOPROLOL TB 5MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 7.15,
        dailyAverageDispensed: 0,
      },
      SIV: {
        _id: '6201ba25f65f11ad3d798d27',
        DIN: '2495562',
        genericName: 'BISOPROLOL FUMARATE TABLET 5 M',
        supplierCode: 'SIV',
        brandName: 'BISOPROLOL TABLETS TB 5MG 100 SIVEM',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 7.15,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 227,
      total: 0,
    },
  },
  {
    groupCode: '51784',
    genericName: 'ROSUVASTATIN CALCIUM TABLET 10',
    strength: '10MG',
    unitPrice: 1.481,
    MFRs: {
      TAR: {
        _id: '6201ba24f65f11ad3d7988c3',
        DIN: '2382652',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 10',
        supplierCode: 'TAR',
        brandName: 'ROSUVASTATIN TB 10MG 100 TARO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 13.54,
        dailyAverageDispensed: 194,
      },
      JAP: {
        _id: '6201ba25f65f11ad3d799e58',
        DIN: '2498340',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 10',
        supplierCode: 'JAP',
        brandName: 'ROSUVASTATIN CALCIUM TB 10MG BLST 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '75',
        packPrice: 4.06,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba24f65f11ad3d797658',
        DIN: '2438925',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 10',
        supplierCode: 'AHC',
        brandName: 'ROSUVASTATIN TB 10MG 90 ACH',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '66',
        packPrice: 12.19,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba26f65f11ad3d79a756',
        DIN: '2354616',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 10',
        supplierCode: 'TEV',
        brandName: 'ROSUVASTATIN TB 10MG UD BLST 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 4.06,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d790156',
        DIN: '2378531',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 10',
        supplierCode: 'PMS',
        brandName: 'ROSUVASTATIN TB 10MG BLS 3X10 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 4.06,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba1ff65f11ad3d790110',
        DIN: '2337983',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 10',
        supplierCode: 'APX',
        brandName: 'ROSUVASTATIN TB 10MG UD 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '58',
        packPrice: 4.06,
        dailyAverageDispensed: 8,
      },
      SDZ: {
        _id: '6201ba26f65f11ad3d79a450',
        DIN: '2338734',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 10',
        supplierCode: 'SDZ',
        brandName: 'ROSUVASTATIN TB 10MG UD BLST 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '57',
        packPrice: 4.06,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba1ff65f11ad3d7905ba',
        DIN: '2247162',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 10',
        supplierCode: 'AST',
        brandName: 'CRESTOR TB 10MG BLS 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 44.42,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79aca5',
        DIN: '2405636',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 10',
        supplierCode: 'SNS',
        brandName: 'ROSUVASTATIN TB 10MG 500 SNS',
        form: 'TABLET',
        packSize: '500',
        rebateValue: null,
        packPrice: 67.7,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba24f65f11ad3d797713',
        DIN: '2442582',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 10',
        supplierCode: 'AUP',
        brandName: 'ROSUVASTATIN TB 10MG 3X10 BLS AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 4.06,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 202,
      total: 0,
    },
  },
  {
    groupCode: '51786',
    genericName: 'ROSUVASTATIN CALCIUM TABLET 40',
    strength: '40MG',
    unitPrice: 2.163,
    MFRs: {
      TAR: {
        _id: '6201ba24f65f11ad3d7988c7',
        DIN: '2382679',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 40',
        supplierCode: 'TAR',
        brandName: 'ROSUVASTATIN TB 40MG 100 TARO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 19.9,
        dailyAverageDispensed: 132,
      },
      JAP: {
        _id: '6201ba25f65f11ad3d799e5a',
        DIN: '2498367',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 40',
        supplierCode: 'JAP',
        brandName: 'ROSUVASTATIN CALCIUM TB 40MG BLST 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '75',
        packPrice: 5.97,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba24f65f11ad3d797667',
        DIN: '2438941',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 40',
        supplierCode: 'AHC',
        brandName: 'ROSUVASTATIN TB 40MG 90 ACH',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '66',
        packPrice: 17.91,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d79016a',
        DIN: '2354632',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 40',
        supplierCode: 'TEV',
        brandName: 'ROSUVASTATIN TB 40MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 5.97,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d79015e',
        DIN: '2378566',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 40',
        supplierCode: 'PMS',
        brandName: 'ROSUVASTATIN TB 40MG BLS 3X10 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 5.97,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba1ff65f11ad3d790117',
        DIN: '2338009',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 40',
        supplierCode: 'APX',
        brandName: 'ROSUVASTATIN TB 40MG UD 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '58',
        packPrice: 5.97,
        dailyAverageDispensed: 15,
      },
      SDZ: {
        _id: '6201ba26f65f11ad3d79ad67',
        DIN: '2338750',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 40',
        supplierCode: 'SDZ',
        brandName: 'ROSUVASTATIN TB 40MG BLST 3X10 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '57',
        packPrice: 5.97,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba1ff65f11ad3d7905b8',
        DIN: '2247164',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 40',
        supplierCode: 'AST',
        brandName: 'CRESTOR TB 40MG BLS 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 64.89,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79ac5d',
        DIN: '2405652',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 40',
        supplierCode: 'SNS',
        brandName: 'ROSUVASTATIN TB 40MG 500 SNS',
        form: 'TABLET',
        packSize: '500',
        rebateValue: null,
        packPrice: 99.5,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba24f65f11ad3d797712',
        DIN: '2442604',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 40',
        supplierCode: 'AUP',
        brandName: 'ROSUVASTATIN TB 40MG 3X10 BLS AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 5.97,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 147,
      total: 0,
    },
  },
  {
    groupCode: '29054',
    genericName: 'LACTULOSE SOLUTION 10 G/15 ML',
    strength: '667MG/ML',
    unitPrice: 0.015,
    MFRs: {
      JAP: {
        _id: '6201ba27f65f11ad3d79d65c',
        DIN: '2295881',
        genericName: 'LACTULOSE SOLUTION 10 G/15 ML',
        supplierCode: 'JAP',
        brandName: 'LACTULOSE 667MG/ML 500ML JAMP',
        form: 'SYRUP',
        packSize: '500',
        rebateValue: '30',
        packPrice: 7.25,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d795b1d',
        DIN: '2469391',
        genericName: 'LACTULOSE SOLUTION 10 G/15 ML',
        supplierCode: 'PMS',
        brandName: 'LACTULOSE BTL 667MG/ML 500ML PHARMA',
        form: 'SYRUP',
        packSize: '500',
        rebateValue: '20',
        packPrice: 7.25,
        dailyAverageDispensed: 125,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d78f435',
        DIN: '2331551',
        genericName: 'LACTULOSE SOLUTION 10 G/15 ML',
        supplierCode: 'TEV',
        brandName: 'LACTULOSE SACHETS 667MG/ML 20X15ML TEVA',
        form: 'LIQUID',
        packSize: '15',
        rebateValue: '0',
        packPrice: 6.94,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 125,
      total: 0,
    },
  },
  {
    groupCode: '19412',
    genericName: 'INDAPAMIDE TABLET 1.25 MG ORAL',
    strength: '1.25MG',
    unitPrice: 0.149,
    MFRs: {
      APX: {
        _id: '6201ba26f65f11ad3d79bb04',
        DIN: '2245246',
        genericName: 'INDAPAMIDE TABLET 1.25 MG ORAL',
        supplierCode: 'APX',
        brandName: 'INDAPAMIDE TB 1.25MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 14.9,
        dailyAverageDispensed: 25,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d790103',
        DIN: '2373904',
        genericName: 'INDAPAMIDE TABLET 1.25 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'INDAPAMIDE TB 1.25MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 7.45,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba27f65f11ad3d79c76a',
        DIN: '2240067',
        genericName: 'INDAPAMIDE TABLET 1.25 MG ORAL',
        supplierCode: 'MYL',
        brandName: 'INDAPAMIDE TB 1.25MG 100 MYLAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 14.9,
        dailyAverageDispensed: 90,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 115,
      total: 0,
    },
  },
  {
    groupCode: '27462',
    genericName: 'PANTOPRAZOLE SODIUM TABLET DR',
    strength: '40MG',
    unitPrice: 2.195,
    MFRs: {
      PMS: {
        _id: '6201ba27f65f11ad3d79c177',
        DIN: '2307871',
        genericName: 'PANTOPRAZOLE SODIUM TABLET DR',
        supplierCode: 'PMS',
        brandName: 'PANTOPRAZOLE TB 40MG 100 PMS',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: '70',
        packPrice: 20.16,
        dailyAverageDispensed: 28,
      },
      SDZ: {
        _id: '6201ba27f65f11ad3d79d450',
        DIN: '2301083',
        genericName: 'PANTOPRAZOLE SODIUM TABLET DR',
        supplierCode: 'SDZ',
        brandName: 'PANTOPRAZOLE TB 40MG 30 SDZ',
        form: 'TAB DEL RELEASE',
        packSize: '30',
        rebateValue: '68',
        packPrice: 6.05,
        dailyAverageDispensed: 0,
      },
      RAN: {
        _id: '6201ba23f65f11ad3d79569d',
        DIN: '2305046',
        genericName: 'PANTOPRAZOLE SODIUM TABLET DR',
        supplierCode: 'RAN',
        brandName: 'PANTOPRAZOLE TB 40MG 100 RAN',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: '67',
        packPrice: 20.16,
        dailyAverageDispensed: 8,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d1e7',
        DIN: '2285487',
        genericName: 'PANTOPRAZOLE SODIUM TABLET DR',
        supplierCode: 'TEV',
        brandName: 'PANTOPRAZOLE TB 40MG 100 TEVA',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 20.16,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c69a',
        DIN: '2292920',
        genericName: 'PANTOPRAZOLE SODIUM TABLET DR',
        supplierCode: 'APX',
        brandName: 'PANTOPRAZOLE TB 40MG 100 APO',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 20.16,
        dailyAverageDispensed: 70,
      },
      JAP: {
        _id: '6201ba26f65f11ad3d79aa69',
        DIN: '2392623',
        genericName: 'PANTOPRAZOLE SODIUM TABLET DR',
        supplierCode: 'JAP',
        brandName: 'PANTOPRAZOLE SOD TB DEL REL 40MG UD BLST 30 JAMP',
        form: 'TAB DEL RELEASE',
        packSize: '30',
        rebateValue: '55',
        packPrice: 6.05,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d7919c8',
        DIN: '2416565',
        genericName: 'PANTOPRAZOLE SODIUM TABLET DR',
        supplierCode: 'MRC',
        brandName: 'PANTOPRAZOLE TB 40MG 100 MAR',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: '53',
        packPrice: 20.16,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d7919ca',
        DIN: '2417448',
        genericName: 'PANTOPRAZOLE SODIUM TABLET DR',
        supplierCode: 'MPH',
        brandName: 'PANTOPRAZOLE TB 40MG 100 MINT',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: '10',
        packPrice: 20.16,
        dailyAverageDispensed: 0,
      },
      NYC: {
        _id: '6201ba26f65f11ad3d79b71d',
        DIN: '2229453',
        genericName: 'PANTOPRAZOLE SODIUM TABLET DR',
        supplierCode: 'NYC',
        brandName: 'PANTOLOC TB 40MG 100',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: null,
        packPrice: 219.48,
        dailyAverageDispensed: 0,
      },
      RIV: {
        _id: '6201ba27f65f11ad3d79d712',
        DIN: '2316463',
        genericName: 'PANTOPRAZOLE SODIUM TABLET DR',
        supplierCode: 'RIV',
        brandName: 'PANTOPRAZOLE TB 40MG 500 RIVA',
        form: 'TAB DEL RELEASE',
        packSize: '500',
        rebateValue: null,
        packPrice: 100.8,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba24f65f11ad3d7986e6',
        DIN: '2370808',
        genericName: 'PANTOPRAZOLE SODIUM TABLET DR',
        supplierCode: 'SNS',
        brandName: 'PANTOPRAZOLE TB 40MG 100',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: null,
        packPrice: 20.16,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d792be4',
        DIN: '2415208',
        genericName: 'PANTOPRAZOLE SODIUM TABLET DR',
        supplierCode: 'AUP',
        brandName: 'PANTOPRAZOLE TB 40MG 100 AURO',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: null,
        packPrice: 20.16,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 106,
      total: 0,
    },
  },
  {
    groupCode: '59136',
    genericName: 'PANTOPRAZOLE MAGNESIUM TABLET',
    strength: '40MG',
    unitPrice: 0.791,
    MFRs: {
      TEV: {
        _id: '6201ba21f65f11ad3d792bf9',
        DIN: '2440628',
        genericName: 'PANTOPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'TEV',
        brandName: 'PANTOPRAZOLE MAGNESIUM TB 40MG 100 TEVA',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: '50',
        packPrice: 18.75,
        dailyAverageDispensed: 86,
      },
      NYC: {
        _id: '6201ba27f65f11ad3d79c0d8',
        DIN: '2267233',
        genericName: 'PANTOPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'NYC',
        brandName: 'TECTA TB 40MG BLISTER 30',
        form: 'TABLET EC',
        packSize: '30',
        rebateValue: null,
        packPrice: 23.74,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba21f65f11ad3d792af8',
        DIN: '2408570',
        genericName: 'PANTOPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'MYL',
        brandName: 'PANTOPRAZOLE MAGNESIUM T TB 40MG 100 MYLAN',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: null,
        packPrice: 18.75,
        dailyAverageDispensed: 0,
      },
      APR: {
        _id: '6201ba21f65f11ad3d792670',
        DIN: '2441853',
        genericName: 'PANTOPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'APR',
        brandName: 'ALTIUS PANTOPRAZOLE MAGN TB 40MG 100',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: null,
        packPrice: 18.75,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba22f65f11ad3d794ffa',
        DIN: '2466147',
        genericName: 'PANTOPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'SNS',
        brandName: 'PANTOPRAZOLE MAGNESIUM TB 40MG 100 SNS',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: null,
        packPrice: 18.75,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 86,
      total: 0,
    },
  },
  {
    groupCode: '16926',
    genericName: 'AMLODIPINE BESYLATE TABLET 5 M',
    strength: '5MG',
    unitPrice: 0.134,
    MFRs: {
      RAN: {
        _id: '6201ba27f65f11ad3d79d20f',
        DIN: '2321858',
        genericName: 'AMLODIPINE BESYLATE TABLET 5 M',
        supplierCode: 'RAN',
        brandName: 'AMLODIPINE TB 5MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 13.43,
        dailyAverageDispensed: 53,
      },
      APX: {
        _id: '6201ba25f65f11ad3d79a034',
        DIN: '2273373',
        genericName: 'AMLODIPINE BESYLATE TABLET 5 M',
        supplierCode: 'APX',
        brandName: 'AMLODIPINE TB 5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 13.43,
        dailyAverageDispensed: 29,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79d283',
        DIN: '2284065',
        genericName: 'AMLODIPINE BESYLATE TABLET 5 M',
        supplierCode: 'PMS',
        brandName: 'AMLODIPINE TB 5MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 13.43,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d242',
        DIN: '2297485',
        genericName: 'AMLODIPINE BESYLATE TABLET 5 M',
        supplierCode: 'TEV',
        brandName: 'AMLODIPINE TB 5MG 100 ACT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 13.43,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba27f65f11ad3d79d068',
        DIN: '2284383',
        genericName: 'AMLODIPINE BESYLATE TABLET 5 M',
        supplierCode: 'SDZ',
        brandName: 'AMLODIPINE TB 5MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '74',
        packPrice: 13.43,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba25f65f11ad3d79a2af',
        DIN: '2357194',
        genericName: 'AMLODIPINE BESYLATE TABLET 5 M',
        supplierCode: 'JAP',
        brandName: 'AMLODIPINE TB 5MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 13.43,
        dailyAverageDispensed: 0,
      },
      NRI: {
        _id: '6201ba25f65f11ad3d79928a',
        DIN: '2476460',
        genericName: 'AMLODIPINE BESYLATE TABLET 5 M',
        supplierCode: 'NRI',
        brandName: 'AMLODIPINE TB 5MG 250 NRA',
        form: 'TABLET',
        packSize: '250',
        rebateValue: '70',
        packPrice: 33.58,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba23f65f11ad3d7969a7',
        DIN: '2419564',
        genericName: 'AMLODIPINE BESYLATE TABLET 5 M',
        supplierCode: 'AHC',
        brandName: 'AMLODIPINE TB 5MG 250 ACH',
        form: 'TABLET',
        packSize: '250',
        rebateValue: '65',
        packPrice: 33.58,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba1ff65f11ad3d78f756',
        DIN: '2362651',
        genericName: 'AMLODIPINE BESYLATE TABLET 5 M',
        supplierCode: 'MPH',
        brandName: 'AMLODIPINE TB 5MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 13.43,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba1ff65f11ad3d790458',
        DIN: '2371715',
        genericName: 'AMLODIPINE BESYLATE TABLET 5 M',
        supplierCode: 'MRC',
        brandName: 'AMLODIPINE TB 5MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 13.43,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba27f65f11ad3d79d369',
        DIN: '2272113',
        genericName: 'AMLODIPINE BESYLATE TABLET 5 M',
        supplierCode: 'MYL',
        brandName: 'AMLODIPINE TB 5MG 100 MYLAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 13.43,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba23f65f11ad3d796a4f',
        DIN: '2331284',
        genericName: 'AMLODIPINE BESYLATE TABLET 5 M',
        supplierCode: 'SNS',
        brandName: 'AMLODIPINE BESYLATE TB 5MG 500 SNS',
        form: 'TABLET',
        packSize: '500',
        rebateValue: null,
        packPrice: 67.15,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790d10',
        DIN: '2397072',
        genericName: 'AMLODIPINE BESYLATE TABLET 5 M',
        supplierCode: 'AUP',
        brandName: 'AMLODIPINE TB 5MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 13.43,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba27f65f11ad3d79c7bd',
        DIN: '878928',
        genericName: 'AMLODIPINE BESYLATE TABLET 5 M',
        supplierCode: 'UPJ',
        brandName: 'NORVASC TB 5MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 160.18,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 82,
      total: 0,
    },
  },
  {
    groupCode: '47304',
    genericName: 'GLICLAZIDE TAB ER 24H 30 MG OR',
    strength: '30MG',
    unitPrice: 0.156,
    MFRs: {
      RAN: {
        _id: '6201ba23f65f11ad3d79578d',
        DIN: '2463571',
        genericName: 'GLICLAZIDE TAB ER 24H 30 MG OR',
        supplierCode: 'RAN',
        brandName: 'GLICLAZIDE MR TB 30MG 100 RAN',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '52',
        packPrice: 9.31,
        dailyAverageDispensed: 75,
      },
      SDZ: {
        _id: '6201ba22f65f11ad3d79481c',
        DIN: '2461323',
        genericName: 'GLICLAZIDE TAB ER 24H 30 MG OR',
        supplierCode: 'SDZ',
        brandName: 'GLICLAZIDE MR TB MRT 30MG BLST 60 SDZ',
        form: 'TABLET EXT REL',
        packSize: '60',
        rebateValue: '45',
        packPrice: 5.59,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c189',
        DIN: '2297795',
        genericName: 'GLICLAZIDE TAB ER 24H 30 MG OR',
        supplierCode: 'APX',
        brandName: 'GLICLAZIDE MR TB 30MG 100 APO',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '20',
        packPrice: 9.31,
        dailyAverageDispensed: 6,
      },
      MPH: {
        _id: '6201ba21f65f11ad3d792150',
        DIN: '2423286',
        genericName: 'GLICLAZIDE TAB ER 24H 30 MG OR',
        supplierCode: 'MPH',
        brandName: 'GLICLAZIDE MR TB 30MG 100 MINT',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '10',
        packPrice: 9.31,
        dailyAverageDispensed: 0,
      },
      SEV: {
        _id: '6201ba26f65f11ad3d79b98a',
        DIN: '2242987',
        genericName: 'GLICLAZIDE TAB ER 24H 30 MG OR',
        supplierCode: 'SEV',
        brandName: 'DIAMICRON MR TB 30MG 60',
        form: 'TABLET EXT REL',
        packSize: '60',
        rebateValue: null,
        packPrice: 9.38,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba21f65f11ad3d79285e',
        DIN: '2438658',
        genericName: 'GLICLAZIDE TAB ER 24H 30 MG OR',
        supplierCode: 'MYL',
        brandName: 'GLICLAZIDE MR TB 30MG 100 MYLAN',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 9.31,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 81,
      total: 0,
    },
  },
  {
    groupCode: '38164',
    genericName: 'CLOPIDOGREL BISULFATE TABLET 7',
    strength: '75MG',
    unitPrice: 2.862,
    MFRs: {
      TEV: {
        _id: '6201ba1ff65f11ad3d78feb3',
        DIN: '2293161',
        genericName: 'CLOPIDOGREL BISULFATE TABLET 7',
        supplierCode: 'TEV',
        brandName: 'CLOPIDOGREL TB 75MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 7.89,
        dailyAverageDispensed: 5,
      },
      APX: {
        _id: '6201ba1ff65f11ad3d78fe12',
        DIN: '2252767',
        genericName: 'CLOPIDOGREL BISULFATE TABLET 7',
        supplierCode: 'APX',
        brandName: 'CLOPIDOGREL TB 75MG UD 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '62',
        packPrice: 7.89,
        dailyAverageDispensed: 66,
      },
      RAN: {
        _id: '6201ba1ff65f11ad3d7903a7',
        DIN: '2379813',
        genericName: 'CLOPIDOGREL BISULFATE TABLET 7',
        supplierCode: 'RAN',
        brandName: 'CLOPIDOGREL TB 75MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '62',
        packPrice: 26.31,
        dailyAverageDispensed: 3,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d78fe0e',
        DIN: '2348004',
        genericName: 'CLOPIDOGREL BISULFATE TABLET 7',
        supplierCode: 'PMS',
        brandName: 'CLOPIDOGREL TB 75MG BLST 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 7.89,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d7921d3',
        DIN: '2415550',
        genericName: 'CLOPIDOGREL BISULFATE TABLET 7',
        supplierCode: 'JAP',
        brandName: 'CLOPIDOGREL TB 75MG BLST 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 7.89,
        dailyAverageDispensed: 0,
      },
      SAC: {
        _id: '6201ba28f65f11ad3d79da3e',
        DIN: '2238682',
        genericName: 'CLOPIDOGREL BISULFATE TABLET 7',
        supplierCode: 'SAC',
        brandName: 'PLAVIX TB 75MG BLISTER 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 80.13,
        dailyAverageDispensed: 0,
      },
      RIV: {
        _id: '6201ba25f65f11ad3d799d2a',
        DIN: '2388529',
        genericName: 'CLOPIDOGREL BISULFATE TABLET 7',
        supplierCode: 'RIV',
        brandName: 'CLOPIDOGREL TB 75MG UD 30 RIVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 7.89,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79a850',
        DIN: '2400553',
        genericName: 'CLOPIDOGREL BISULFATE TABLET 7',
        supplierCode: 'SNS',
        brandName: 'CLOPIDOGREL TB 75MG 500',
        form: 'TABLET',
        packSize: '500',
        rebateValue: null,
        packPrice: 131.55,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d7918ef',
        DIN: '2416387',
        genericName: 'CLOPIDOGREL BISULFATE TABLET 7',
        supplierCode: 'AUP',
        brandName: 'CLOPIDOGREL TB 75MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 7.89,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 74,
      total: 0,
    },
  },
  {
    groupCode: '5132',
    genericName: 'METOPROLOL TARTRATE TABLET 50',
    strength: '50MG',
    unitPrice: 0.062,
    MFRs: {
      JAP: {
        _id: '6201ba1ff65f11ad3d78fb2e',
        DIN: '2356821',
        genericName: 'METOPROLOL TARTRATE TABLET 50',
        supplierCode: 'JAP',
        brandName: 'METOPROLOL-L TB 50MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 6.24,
        dailyAverageDispensed: 56,
      },
      PMS: {
        _id: '6201ba28f65f11ad3d79e241',
        DIN: '2230803',
        genericName: 'METOPROLOL TARTRATE TABLET 50',
        supplierCode: 'PMS',
        brandName: 'METOPROLOL L TB 50MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 6.24,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d7921e9',
        DIN: '648035',
        genericName: 'METOPROLOL TARTRATE TABLET 50',
        supplierCode: 'TEV',
        brandName: 'METOPROLOL FC TB 50MG PINK 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 6.24,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79bf47',
        DIN: '618632',
        genericName: 'METOPROLOL TARTRATE TABLET 50',
        supplierCode: 'APX',
        brandName: 'METOPROLOL TB 50MG 1000 APO',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '54',
        packPrice: 62.4,
        dailyAverageDispensed: 12,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f4cb',
        DIN: '2350394',
        genericName: 'METOPROLOL TARTRATE TABLET 50',
        supplierCode: 'SNS',
        brandName: 'METOPROLOL FC TB 50MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 6.24,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 68,
      total: 0,
    },
  },
  {
    groupCode: '59110',
    genericName: 'TAMSULOSIN HCL TAB ER 24H 0.4',
    strength: '0.4MG',
    unitPrice: 0.699,
    MFRs: {
      APX: {
        _id: '6201ba1ff65f11ad3d790307',
        DIN: '2362406',
        genericName: 'TAMSULOSIN HCL TAB ER 24H 0.4',
        supplierCode: 'APX',
        brandName: 'TAMSULOSIN CR TB 0.4MG 30 APO',
        form: 'TABLET EXT REL',
        packSize: '30',
        rebateValue: '49',
        packPrice: 4.5,
        dailyAverageDispensed: 66,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d78f4aa',
        DIN: '2340208',
        genericName: 'TAMSULOSIN HCL TAB ER 24H 0.4',
        supplierCode: 'SDZ',
        brandName: 'TAMSULOSIN CR TB 0.4MG 100 SDZ',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '30',
        packPrice: 15,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d79102a',
        DIN: '2368242',
        genericName: 'TAMSULOSIN HCL TAB ER 24H 0.4',
        supplierCode: 'TEV',
        brandName: 'TAMSULOSIN CR TB 0.4MG BLS 30 TEVA',
        form: 'TABLET EXT REL',
        packSize: '30',
        rebateValue: '1',
        packPrice: 4.5,
        dailyAverageDispensed: 0,
      },
      BOE: {
        _id: '6201ba25f65f11ad3d799a1b',
        DIN: '2270102',
        genericName: 'TAMSULOSIN HCL TAB ER 24H 0.4',
        supplierCode: 'BOE',
        brandName: 'FLOMAX CR TB 0.4MG BLS 3X10',
        form: 'TABLET EXT REL',
        packSize: '30',
        rebateValue: null,
        packPrice: 20.98,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d791c68',
        DIN: '2427117',
        genericName: 'TAMSULOSIN HCL TAB ER 24H 0.4',
        supplierCode: 'SNS',
        brandName: 'TAMSULOSIN CR TB 0.4MG 100 SNS',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 15,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 66,
      total: 0,
    },
  },
  {
    groupCode: '29968',
    genericName: 'ATORVASTATIN CALCIUM TABLET 20',
    strength: '20MG',
    unitPrice: 2.463,
    MFRs: {
      RAN: {
        _id: '6201ba27f65f11ad3d79c199',
        DIN: '2313715',
        genericName: 'ATORVASTATIN CALCIUM TABLET 20',
        supplierCode: 'RAN',
        brandName: 'ATORVASTATIN TB 20MG 90 TARO',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '77',
        packPrice: 19.61,
        dailyAverageDispensed: 16,
      },
      JAP: {
        _id: '6201ba26f65f11ad3d79b21b',
        DIN: '2504200',
        genericName: 'ATORVASTATIN CALCIUM TABLET 20',
        supplierCode: 'JAP',
        brandName: 'ATORVASTATIN CALCIUM TB 20MG 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '75',
        packPrice: 6.54,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba24f65f11ad3d798baa',
        DIN: '2477157',
        genericName: 'ATORVASTATIN CALCIUM TABLET 20',
        supplierCode: 'PMS',
        brandName: 'ATORVASTATIN TB 20MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 21.79,
        dailyAverageDispensed: 41,
      },
      APX: {
        _id: '6201ba21f65f11ad3d7930b4',
        DIN: '2295288',
        genericName: 'ATORVASTATIN CALCIUM TABLET 20',
        supplierCode: 'APX',
        brandName: 'ATORVASTATIN TB 20MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 21.79,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba24f65f11ad3d7970f7',
        DIN: '2310902',
        genericName: 'ATORVASTATIN CALCIUM TABLET 20',
        supplierCode: 'TEV',
        brandName: 'ATORVASTATIN TB 20MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '67',
        packPrice: 6.54,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba25f65f11ad3d798f61',
        DIN: '2324954',
        genericName: 'ATORVASTATIN CALCIUM TABLET 20',
        supplierCode: 'SDZ',
        brandName: 'ATORVASTATIN TB 20MG BLST 3X10 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '67',
        packPrice: 6.54,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba24f65f11ad3d797c02',
        DIN: '2457768',
        genericName: 'ATORVASTATIN CALCIUM TABLET 20',
        supplierCode: 'AHC',
        brandName: 'ATORVASTATIN TB 20MG 90 ACH',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '65',
        packPrice: 19.61,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba22f65f11ad3d794604',
        DIN: '2454025',
        genericName: 'ATORVASTATIN CALCIUM TABLET 20',
        supplierCode: 'MRC',
        brandName: 'ATORVASTATIN TB 20MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 21.79,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba24f65f11ad3d7985be',
        DIN: '2479516',
        genericName: 'ATORVASTATIN CALCIUM TABLET 20',
        supplierCode: 'MPH',
        brandName: 'ATORVASTATIN TB 20MG 500 MINT',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '60',
        packPrice: 108.95,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba20f65f11ad3d7912e6',
        DIN: '2230713',
        genericName: 'ATORVASTATIN CALCIUM TABLET 20',
        supplierCode: 'UPJ',
        brandName: 'LIPITOR TB 20MG 90',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 221.7,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba25f65f11ad3d79962c',
        DIN: '2348713',
        genericName: 'ATORVASTATIN CALCIUM TABLET 20',
        supplierCode: 'SNS',
        brandName: 'ATORVASTATIN TB 20MG 500 SNS',
        form: 'TABLET',
        packSize: '500',
        rebateValue: null,
        packPrice: 108.95,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba20f65f11ad3d791d41',
        DIN: '2392941',
        genericName: 'ATORVASTATIN CALCIUM TABLET 20',
        supplierCode: 'MYL',
        brandName: 'ATORVASTATIN TB 20MG 90 MYLAN',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 19.61,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d791e3f',
        DIN: '2407264',
        genericName: 'ATORVASTATIN CALCIUM TABLET 20',
        supplierCode: 'AUP',
        brandName: 'ATORVASTATIN TB 20MG 90 AURO',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 19.61,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 57,
      total: 0,
    },
  },
  {
    groupCode: '29969',
    genericName: 'ATORVASTATIN CALCIUM TABLET 40',
    strength: '40MG',
    unitPrice: 2.648,
    MFRs: {
      RAN: {
        _id: '6201ba27f65f11ad3d79c1c5',
        DIN: '2313723',
        genericName: 'ATORVASTATIN CALCIUM TABLET 40',
        supplierCode: 'RAN',
        brandName: 'ATORVASTATIN TB 40MG 90 RAN',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '77',
        packPrice: 21.08,
        dailyAverageDispensed: 14,
      },
      JAP: {
        _id: '6201ba26f65f11ad3d79b221',
        DIN: '2504219',
        genericName: 'ATORVASTATIN CALCIUM TABLET 40',
        supplierCode: 'JAP',
        brandName: 'ATORVASTATIN CALCIUM TB 40MG 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '75',
        packPrice: 7.03,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba24f65f11ad3d798bac',
        DIN: '2477165',
        genericName: 'ATORVASTATIN CALCIUM TABLET 40',
        supplierCode: 'PMS',
        brandName: 'ATORVASTATIN TB 40MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 23.42,
        dailyAverageDispensed: 42,
      },
      APX: {
        _id: '6201ba21f65f11ad3d7927b9',
        DIN: '2295296',
        genericName: 'ATORVASTATIN CALCIUM TABLET 40',
        supplierCode: 'APX',
        brandName: 'ATORVASTATIN TB 40MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 23.42,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba24f65f11ad3d7970f8',
        DIN: '2310910',
        genericName: 'ATORVASTATIN CALCIUM TABLET 40',
        supplierCode: 'TEV',
        brandName: 'ATORVASTATIN TB 40MG UD 30 TEVA',
        form: 'TAB FC',
        packSize: '30',
        rebateValue: '67',
        packPrice: 7.03,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba25f65f11ad3d79954e',
        DIN: '2324962',
        genericName: 'ATORVASTATIN CALCIUM TABLET 40',
        supplierCode: 'SDZ',
        brandName: 'ATORVASTATIN TB 40MG BLST 3X10 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '67',
        packPrice: 7.03,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba24f65f11ad3d797bff',
        DIN: '2457776',
        genericName: 'ATORVASTATIN CALCIUM TABLET 40',
        supplierCode: 'AHC',
        brandName: 'ATORVASTATIN TB 40MG 90 ACH',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '65',
        packPrice: 21.08,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba23f65f11ad3d796497',
        DIN: '2454033',
        genericName: 'ATORVASTATIN CALCIUM TABLET 40',
        supplierCode: 'MRC',
        brandName: 'ATORVASTATIN TB 40MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 23.42,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba24f65f11ad3d7985bf',
        DIN: '2479524',
        genericName: 'ATORVASTATIN CALCIUM TABLET 40',
        supplierCode: 'MPH',
        brandName: 'ATORVASTATIN TB 40MG 500 MINT',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '60',
        packPrice: 117.1,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba20f65f11ad3d7912b9',
        DIN: '2230714',
        genericName: 'ATORVASTATIN CALCIUM TABLET 40',
        supplierCode: 'UPJ',
        brandName: 'LIPITOR TB 40MG 90',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 238.33,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba25f65f11ad3d79962e',
        DIN: '2348721',
        genericName: 'ATORVASTATIN CALCIUM TABLET 40',
        supplierCode: 'SNS',
        brandName: 'ATORVASTATIN TB 40MG 500 SNS',
        form: 'TABLET',
        packSize: '500',
        rebateValue: null,
        packPrice: 117.1,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba20f65f11ad3d791d42',
        DIN: '2392968',
        genericName: 'ATORVASTATIN CALCIUM TABLET 40',
        supplierCode: 'MYL',
        brandName: 'ATORVASTATIN TB 40MG 90 MYLAN',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 21.08,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d791e45',
        DIN: '2407272',
        genericName: 'ATORVASTATIN CALCIUM TABLET 40',
        supplierCode: 'AUP',
        brandName: 'ATORVASTATIN TB 40MG 90 AURO',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 21.08,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 56,
      total: 0,
    },
  },
  {
    groupCode: '21414',
    genericName: 'GABAPENTIN CAPSULE 300 MG ORAL',
    strength: '300MG',
    unitPrice: 1.211,
    MFRs: {
      AHC: {
        _id: '6201ba24f65f11ad3d797313',
        DIN: '2416859',
        genericName: 'GABAPENTIN CAPSULE 300 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'GABAPENTIN CAPS 300MG 100 ACH',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '68',
        packPrice: 10.12,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79cc0e',
        DIN: '2244514',
        genericName: 'GABAPENTIN CAPSULE 300 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'GABAPENTIN CAPS 300MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '60',
        packPrice: 10.12,
        dailyAverageDispensed: 50,
      },
      PMS: {
        _id: '6201ba26f65f11ad3d79b630',
        DIN: '2243447',
        genericName: 'GABAPENTIN CAPSULE 300 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'GABAPENTIN CAPS 300MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '45',
        packPrice: 10.12,
        dailyAverageDispensed: 4,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c109',
        DIN: '2244305',
        genericName: 'GABAPENTIN CAPSULE 300 MG ORAL',
        supplierCode: 'APX',
        brandName: 'GABAPENTIN CAPS 300MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '45',
        packPrice: 10.12,
        dailyAverageDispensed: 1,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d78febe',
        DIN: '2361485',
        genericName: 'GABAPENTIN CAPSULE 300 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'GABAPENTIN CAPS 300MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '40',
        packPrice: 10.12,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba27f65f11ad3d79d4aa',
        DIN: '2084279',
        genericName: 'GABAPENTIN CAPSULE 300 MG ORAL',
        supplierCode: 'UPJ',
        brandName: 'NEURONTIN CAPS 300MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 121.06,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba1ff65f11ad3d78fb39',
        DIN: '2321211',
        genericName: 'GABAPENTIN CAPSULE 300 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'GABAPENTIN CAPS 300MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 10.12,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d791824',
        DIN: '2353253',
        genericName: 'GABAPENTIN CAPSULE 300 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'GABAPENTIN CAPS 300MG 500 SNS',
        form: 'CAPSULE',
        packSize: '500',
        rebateValue: null,
        packPrice: 50.6,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 55,
      total: 0,
    },
  },
  {
    groupCode: '42783',
    genericName: 'PERINDOPRIL ERBUMINE TABLET 8',
    strength: '8MG',
    unitPrice: 1.292,
    MFRs: {
      JAP: {
        _id: '6201ba23f65f11ad3d796eeb',
        DIN: '2477025',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 8',
        supplierCode: 'JAP',
        brandName: 'PERINDOPRIL TB 8MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 28.31,
        dailyAverageDispensed: 10,
      },
      TEV: {
        _id: '6201ba23f65f11ad3d7956f3',
        DIN: '2465000',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 8',
        supplierCode: 'TEV',
        brandName: 'PERINDOPRIL TB 8MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 28.31,
        dailyAverageDispensed: 21,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d7957b9',
        DIN: '2470691',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 8',
        supplierCode: 'PMS',
        brandName: 'PERINDOPRIL TB 8MG BLST 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 8.49,
        dailyAverageDispensed: 23,
      },
      APX: {
        _id: '6201ba23f65f11ad3d79582f',
        DIN: '2289296',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 8',
        supplierCode: 'APX',
        brandName: 'PERINDOPRIL TB 8MG BLST 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '68',
        packPrice: 8.49,
        dailyAverageDispensed: 1,
      },
      MPH: {
        _id: '6201ba23f65f11ad3d796e17',
        DIN: '2476789',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 8',
        supplierCode: 'MPH',
        brandName: 'PERINDOPRIL TB 8MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 28.31,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba23f65f11ad3d79686b',
        DIN: '2474840',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 8',
        supplierCode: 'MRC',
        brandName: 'PERINDOPRIL TB 8MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '58',
        packPrice: 28.31,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d7957df',
        DIN: '2470241',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 8',
        supplierCode: 'SDZ',
        brandName: 'PERINDOPRIL ERBUMINE TB 8MG BLST 2X15 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '55',
        packPrice: 8.49,
        dailyAverageDispensed: 0,
      },
      SEV: {
        _id: '6201ba1ff65f11ad3d790093',
        DIN: '2246624',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 8',
        supplierCode: 'SEV',
        brandName: 'COVERSYL TB 8MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 38.77,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d79581f',
        DIN: '2459833',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 8',
        supplierCode: 'AUP',
        brandName: 'PERINDOPRIL TB 8MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 28.31,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba24f65f11ad3d7972be',
        DIN: '2481650',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 8',
        supplierCode: 'SNS',
        brandName: 'PERINDOPRIL TB 8MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 28.31,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 55,
      total: 0,
    },
  },
  {
    groupCode: '8209',
    genericName: 'FUROSEMIDE TABLET 40 MG ORAL',
    strength: '40MG',
    unitPrice: 0.033,
    MFRs: {
      MPH: {
        _id: '6201ba23f65f11ad3d7964a1',
        DIN: '2466767',
        genericName: 'FUROSEMIDE TABLET 40 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'FUROSEMIDE TB 40MG 1000 MINT',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '50',
        packPrice: 32.7,
        dailyAverageDispensed: 28,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c87b',
        DIN: '362166',
        genericName: 'FUROSEMIDE TABLET 40 MG ORAL',
        supplierCode: 'APX',
        brandName: 'FUROSEMIDE TB 40MG 1000 APO',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '10',
        packPrice: 32.7,
        dailyAverageDispensed: 25,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f4c5',
        DIN: '2351439',
        genericName: 'FUROSEMIDE TABLET 40 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'FUROSEMIDE TB 40MG 1000 SNS',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: null,
        packPrice: 32.7,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba22f65f11ad3d794fd7',
        DIN: '337749',
        genericName: 'FUROSEMIDE TABLET 40 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'FUROSEMIDE TB 40MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '0',
        packPrice: 3.27,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 53,
      total: 0,
    },
  },
  {
    groupCode: '21413',
    genericName: 'GABAPENTIN CAPSULE 100 MG ORAL',
    strength: '100MG',
    unitPrice: 0.506,
    MFRs: {
      AHC: {
        _id: '6201ba24f65f11ad3d797312',
        DIN: '2416840',
        genericName: 'GABAPENTIN CAPSULE 100 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'GABAPENTIN CAPS 100MG 100 ACH',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '68',
        packPrice: 4.16,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79cc0a',
        DIN: '2244513',
        genericName: 'GABAPENTIN CAPSULE 100 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'GABAPENTIN CAPS 100MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '60',
        packPrice: 4.16,
        dailyAverageDispensed: 35,
      },
      PMS: {
        _id: '6201ba26f65f11ad3d79b619',
        DIN: '2243446',
        genericName: 'GABAPENTIN CAPSULE 100 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'GABAPENTIN CAPS 100MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '45',
        packPrice: 4.16,
        dailyAverageDispensed: 14,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c105',
        DIN: '2244304',
        genericName: 'GABAPENTIN CAPSULE 100 MG ORAL',
        supplierCode: 'APX',
        brandName: 'GABAPENTIN CAPS 100MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '45',
        packPrice: 4.16,
        dailyAverageDispensed: 1,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d78febd',
        DIN: '2361469',
        genericName: 'GABAPENTIN CAPSULE 100 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'GABAPENTIN CAPS 100MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '40',
        packPrice: 4.16,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba27f65f11ad3d79d4a9',
        DIN: '2084260',
        genericName: 'GABAPENTIN CAPSULE 100 MG ORAL',
        supplierCode: 'UPJ',
        brandName: 'NEURONTIN CAPS 100MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 50.61,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba1ff65f11ad3d78fb37',
        DIN: '2321203',
        genericName: 'GABAPENTIN CAPSULE 100 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'GABAPENTIN CAPS 100MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 4.16,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 50,
      total: 0,
    },
  },
  {
    groupCode: '65975',
    genericName: 'GLICLAZIDE TAB ER 24H 60 MG OR',
    strength: '60MG',
    unitPrice: 0.28,
    MFRs: {
      RAN: {
        _id: '6201ba22f65f11ad3d7945d8',
        DIN: '2439328',
        genericName: 'GLICLAZIDE TAB ER 24H 60 MG OR',
        supplierCode: 'RAN',
        brandName: 'GLICLAZIDE MR TB 60MG 100 RAN',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '52',
        packPrice: 6.32,
        dailyAverageDispensed: 42,
      },
      SDZ: {
        _id: '6201ba22f65f11ad3d79481b',
        DIN: '2461331',
        genericName: 'GLICLAZIDE TAB ER 24H 60 MG OR',
        supplierCode: 'SDZ',
        brandName: 'GLICLAZIDE MR TB MRT 60MG BLST 60 SDZ',
        form: 'TABLET EXT REL',
        packSize: '60',
        rebateValue: '45',
        packPrice: 3.79,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba21f65f11ad3d7923e5',
        DIN: '2407124',
        genericName: 'GLICLAZIDE TAB ER 24H 60 MG OR',
        supplierCode: 'APX',
        brandName: 'GLICLAZIDE MR TB 60MG 100 APO',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '20',
        packPrice: 6.32,
        dailyAverageDispensed: 8,
      },
      MPH: {
        _id: '6201ba22f65f11ad3d794287',
        DIN: '2423294',
        genericName: 'GLICLAZIDE TAB ER 24H 60 MG OR',
        supplierCode: 'MPH',
        brandName: 'GLICLAZIDE MR TB 60MG 100 MINT',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '10',
        packPrice: 6.32,
        dailyAverageDispensed: 0,
      },
      SEV: {
        _id: '6201ba1ff65f11ad3d78f4d6',
        DIN: '2356422',
        genericName: 'GLICLAZIDE TAB ER 24H 60 MG OR',
        supplierCode: 'SEV',
        brandName: 'DIAMICRON MR TB 60MG 60',
        form: 'TABLET EXT REL',
        packSize: '60',
        rebateValue: null,
        packPrice: 16.79,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 50,
      total: 0,
    },
  },
  {
    groupCode: '8208',
    genericName: 'FUROSEMIDE TABLET 20 MG ORAL',
    strength: '20MG',
    unitPrice: 0.022,
    MFRs: {
      MPH: {
        _id: '6201ba23f65f11ad3d7964a0',
        DIN: '2466759',
        genericName: 'FUROSEMIDE TABLET 20 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'FUROSEMIDE TB 20MG 1000 MINT',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '50',
        packPrice: 21.9,
        dailyAverageDispensed: 26,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79cd25',
        DIN: '396788',
        genericName: 'FUROSEMIDE TABLET 20 MG ORAL',
        supplierCode: 'APX',
        brandName: 'FUROSEMIDE TB 20MG 1000 APO',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '10',
        packPrice: 21.9,
        dailyAverageDispensed: 23,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f4c2',
        DIN: '2351420',
        genericName: 'FUROSEMIDE TABLET 20 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'FUROSEMIDE TB 20MG 1000 SNS',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: null,
        packPrice: 21.9,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba26f65f11ad3d79bc70',
        DIN: '337730',
        genericName: 'FUROSEMIDE TABLET 20 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'FUROSEMIDE TB 20MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '0',
        packPrice: 2.19,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 49,
      total: 0,
    },
  },
  {
    groupCode: '3009',
    genericName: 'DOCUSATE SODIUM CAPSULE 100 MG',
    strength: '100MG',
    unitPrice: 0.036,
    MFRs: {
      TAR: {
        _id: '6201ba1ff65f11ad3d78f9df',
        DIN: '716731',
        genericName: 'DOCUSATE SODIUM CAPSULE 100 MG',
        supplierCode: 'TAR',
        brandName: 'DOCUSATE SOD CAPS 100MG 100 TARO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '56',
        packPrice: 3.28,
        dailyAverageDispensed: 15,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79c17b',
        DIN: '1994344',
        genericName: 'DOCUSATE SODIUM CAPSULE 100 MG',
        supplierCode: 'PMS',
        brandName: 'SOFLAX CAPS 100MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '50',
        packPrice: 3.59,
        dailyAverageDispensed: 33,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d79339b',
        DIN: '2245946',
        genericName: 'DOCUSATE SODIUM CAPSULE 100 MG',
        supplierCode: 'JAP',
        brandName: 'DOCUSATE SOD 100MG CAPS 1000 JAMP',
        form: 'CAPSULE',
        packSize: '1000',
        rebateValue: '1',
        packPrice: 32.8,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba24f65f11ad3d798ac1',
        DIN: '2247385',
        genericName: 'DOCUSATE SODIUM CAPSULE 100 MG',
        supplierCode: 'SDZ',
        brandName: 'DOCUSATE SODIUM CAPS 100MG 1000 SDZ',
        form: 'CAPSULE',
        packSize: '1000',
        rebateValue: '1',
        packPrice: 32.8,
        dailyAverageDispensed: 0,
      },
      ANB: {
        _id: '6201ba21f65f11ad3d793381',
        DIN: '2106256',
        genericName: 'DOCUSATE SODIUM CAPSULE 100 MG',
        supplierCode: 'ANB',
        brandName: 'COLACE CAPS 100MG 60',
        form: 'CAPSULE',
        packSize: '60',
        rebateValue: null,
        packPrice: 10.27,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d791d7b',
        DIN: '2426838',
        genericName: 'DOCUSATE SODIUM CAPSULE 100 MG',
        supplierCode: 'SNS',
        brandName: 'DOCUSATE SOD CAPS 100MG 1000 SNS',
        form: 'CAPSULE',
        packSize: '1000',
        rebateValue: null,
        packPrice: 32.8,
        dailyAverageDispensed: 0,
      },
      MTP: {
        _id: '6201ba24f65f11ad3d7981a8',
        DIN: '2437317',
        genericName: 'DOCUSATE SODIUM CAPSULE 100 MG',
        supplierCode: 'MTP',
        brandName: 'M-DOCUSATE SODIUM GEL 100MG 1000',
        form: 'GEL',
        packSize: '1000',
        rebateValue: null,
        packPrice: 27.3,
        dailyAverageDispensed: 0,
      },
      ODN: {
        _id: '6201ba27f65f11ad3d79c2d9',
        DIN: '514888',
        genericName: 'DOCUSATE SODIUM CAPSULE 100 MG',
        supplierCode: 'ODN',
        brandName: 'SELAX CAPS 100MG 1000',
        form: 'CAPSULE',
        packSize: '1000',
        rebateValue: null,
        packPrice: 29.25,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 48,
      total: 0,
    },
  },
  {
    groupCode: '50631',
    genericName: 'METOPROLOL TARTRATE TABLET 25',
    strength: '25MG',
    unitPrice: 0.064,
    MFRs: {
      JAP: {
        _id: '6201ba1ff65f11ad3d78fb31',
        DIN: '2356813',
        genericName: 'METOPROLOL TARTRATE TABLET 25',
        supplierCode: 'JAP',
        brandName: 'METOPROLOL-L TB 25MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 6.43,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79c20e',
        DIN: '2248855',
        genericName: 'METOPROLOL TARTRATE TABLET 25',
        supplierCode: 'PMS',
        brandName: 'METOPROLOL L TB 25MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 6.43,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d78f584',
        DIN: '2261898',
        genericName: 'METOPROLOL TARTRATE TABLET 25',
        supplierCode: 'TEV',
        brandName: 'METOPROLOL TB 25MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 6.43,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c2fa',
        DIN: '2246010',
        genericName: 'METOPROLOL TARTRATE TABLET 25',
        supplierCode: 'APX',
        brandName: 'METOPROLOL TB 25MG 1000 APO',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '54',
        packPrice: 64.3,
        dailyAverageDispensed: 48,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 48,
      total: 0,
    },
  },
  {
    groupCode: '15940',
    genericName: 'RAMIPRIL CAPSULE 2.5 MG ORAL',
    strength: '2.5MG',
    unitPrice: 0.977,
    MFRs: {
      RAN: {
        _id: '6201ba1ff65f11ad3d78f433',
        DIN: '2310511',
        genericName: 'RAMIPRIL CAPSULE 2.5 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'RAMIPRIL CAPS 2.5MG 100 RAN',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '67',
        packPrice: 8.17,
        dailyAverageDispensed: 35,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c57a',
        DIN: '2247945',
        genericName: 'RAMIPRIL CAPSULE 2.5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'RAMIPRIL CAPS 2.5MG BLST 30 TEVA',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '55',
        packPrice: 2.45,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c409',
        DIN: '2251531',
        genericName: 'RAMIPRIL CAPSULE 2.5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'RAMIPRIL CAPS 2.5MG UD 30 APO',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '55',
        packPrice: 2.45,
        dailyAverageDispensed: 10,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d791d03',
        DIN: '2421305',
        genericName: 'RAMIPRIL CAPSULE 2.5 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'RAMIPRIL CAPS 2.5MG 100 MINT',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '55',
        packPrice: 8.17,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d7961d4',
        DIN: '2469065',
        genericName: 'RAMIPRIL CAPSULE 2.5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'RAMIPRIL CAPS 2.5MG BLST 3X10 PHARMA',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '50',
        packPrice: 2.45,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba28f65f11ad3d79e1f9',
        DIN: '2331128',
        genericName: 'RAMIPRIL CAPSULE 2.5 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'RAMIPRIL CAPS 2.5MG UD 30 JAMP',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '40',
        packPrice: 2.45,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d791d2b',
        DIN: '2420465',
        genericName: 'RAMIPRIL CAPSULE 2.5 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'RAMIPRIL CAPS 2.5MG BLST 30 MAR',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '40',
        packPrice: 2.45,
        dailyAverageDispensed: 0,
      },
      BAU: {
        _id: '6201ba26f65f11ad3d79be8d',
        DIN: '2221837',
        genericName: 'RAMIPRIL CAPSULE 2.5 MG ORAL',
        supplierCode: 'BAU',
        brandName: 'ALTACE CAPS 2.5MG 30',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 29.3,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d793108',
        DIN: '2374846',
        genericName: 'RAMIPRIL CAPSULE 2.5 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'RAMIPRIL CAPS 2.5MG 500 SNS',
        form: 'CAPSULE',
        packSize: '500',
        rebateValue: null,
        packPrice: 40.85,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790972',
        DIN: '2387395',
        genericName: 'RAMIPRIL CAPSULE 2.5 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'RAMIPRIL CAPS 2.5MG BLST 30 AURO',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 2.45,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 45,
      total: 0,
    },
  },
  {
    groupCode: '52944',
    genericName: 'ROSUVASTATIN CALCIUM TABLET 5',
    strength: '5MG',
    unitPrice: 1.394,
    MFRs: {
      TAR: {
        _id: '6201ba24f65f11ad3d7988c4',
        DIN: '2382644',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 5',
        supplierCode: 'TAR',
        brandName: 'ROSUVASTATIN TB 5MG 100 TARO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 12.84,
        dailyAverageDispensed: 43,
      },
      JAP: {
        _id: '6201ba25f65f11ad3d799e55',
        DIN: '2498332',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 5',
        supplierCode: 'JAP',
        brandName: 'ROSUVASTATIN CALCIUM TB 5MG BLST 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '75',
        packPrice: 3.85,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba24f65f11ad3d797669',
        DIN: '2438917',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 5',
        supplierCode: 'AHC',
        brandName: 'ROSUVASTATIN TB 5MG 90 ACH',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '65',
        packPrice: 11.56,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d790166',
        DIN: '2354608',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 5',
        supplierCode: 'TEV',
        brandName: 'ROSUVASTATIN TB 5MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 3.85,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d790152',
        DIN: '2378523',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 5',
        supplierCode: 'PMS',
        brandName: 'ROSUVASTATIN TB 5MG BLS 3X10 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 3.85,
        dailyAverageDispensed: 1,
      },
      APX: {
        _id: '6201ba1ff65f11ad3d79010c',
        DIN: '2337975',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 5',
        supplierCode: 'APX',
        brandName: 'ROSUVASTATIN TB 5MG UD 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '58',
        packPrice: 3.85,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba25f65f11ad3d79a17a',
        DIN: '2338726',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 5',
        supplierCode: 'SDZ',
        brandName: 'ROSUVASTATIN TB 5MG UD BLST 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '57',
        packPrice: 3.85,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba27f65f11ad3d79c32f',
        DIN: '2265540',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 5',
        supplierCode: 'AST',
        brandName: 'CRESTOR TB 5MG BLS 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 41.84,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79aca4',
        DIN: '2405628',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 5',
        supplierCode: 'SNS',
        brandName: 'ROSUVASTATIN TB 5MG 500 SNS',
        form: 'TABLET',
        packSize: '500',
        rebateValue: null,
        packPrice: 64.2,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba24f65f11ad3d797728',
        DIN: '2442574',
        genericName: 'ROSUVASTATIN CALCIUM TABLET 5',
        supplierCode: 'AUP',
        brandName: 'ROSUVASTATIN TB 5MG BLS 3X10 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 3.85,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 44,
      total: 0,
    },
  },
  {
    groupCode: '40910',
    genericName: 'TELMISARTAN TABLET 40 MG ORAL',
    strength: '40MG',
    unitPrice: 1.316,
    MFRs: {
      AHC: {
        _id: '6201ba24f65f11ad3d797c04',
        DIN: '2407485',
        genericName: 'TELMISARTAN TABLET 40 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'TELMISARTAN TB 40MG 100 ACH',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 21.61,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba25f65f11ad3d799b41',
        DIN: '2386755',
        genericName: 'TELMISARTAN TABLET 40 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'TELMISARTAN TB 40MG UD BLST 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 6.48,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d790098',
        DIN: '2320177',
        genericName: 'TELMISARTAN TABLET 40 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'TELMISARTAN TB 40MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 6.48,
        dailyAverageDispensed: 37,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d78fe81',
        DIN: '2375958',
        genericName: 'TELMISARTAN TABLET 40 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'TELMISARTAN TB 40MG UD 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '45',
        packPrice: 6.84,
        dailyAverageDispensed: 6,
      },
      PMS: {
        _id: '6201ba25f65f11ad3d79a0f7',
        DIN: '2499622',
        genericName: 'TELMISARTAN TABLET 40 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'TELMISARTAN TB 40MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '45',
        packPrice: 21.61,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba24f65f11ad3d7989fa',
        DIN: '2486369',
        genericName: 'TELMISARTAN TABLET 40 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'TELMISARTAN TB 40MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 21.61,
        dailyAverageDispensed: 0,
      },
      BOE: {
        _id: '6201ba27f65f11ad3d79c746',
        DIN: '2240769',
        genericName: 'TELMISARTAN TABLET 40 MG ORAL',
        supplierCode: 'BOE',
        brandName: 'MICARDIS TB 40MG BLST 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 36.86,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d790625',
        DIN: '2388944',
        genericName: 'TELMISARTAN TABLET 40 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'TELMISARTAN TB 40MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 21.61,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d794082',
        DIN: '2453568',
        genericName: 'TELMISARTAN TABLET 40 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'TELMISARTAN TB 40MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 21.61,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 43,
      total: 0,
    },
  },
  {
    groupCode: '37016',
    genericName: 'CANDESARTAN CILEXETIL TABLET 8',
    strength: '8MG',
    unitPrice: 1.482,
    MFRs: {
      RAN: {
        _id: '6201ba20f65f11ad3d790fcf',
        DIN: '2380692',
        genericName: 'CANDESARTAN CILEXETIL TABLET 8',
        supplierCode: 'RAN',
        brandName: 'CANDESARTAN TB 8MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 22.81,
        dailyAverageDispensed: 31,
      },
      AHC: {
        _id: '6201ba24f65f11ad3d79730f',
        DIN: '2379279',
        genericName: 'CANDESARTAN CILEXETIL TABLET 8',
        supplierCode: 'AHC',
        brandName: 'CANDESARTAN TB 8MG 100 ACH',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba1ff65f11ad3d78f818',
        DIN: '2365359',
        genericName: 'CANDESARTAN CILEXETIL TABLET 8',
        supplierCode: 'APX',
        brandName: 'CANDESARTAN TB 8MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '63',
        packPrice: 22.81,
        dailyAverageDispensed: 10,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d79029b',
        DIN: '2366312',
        genericName: 'CANDESARTAN CILEXETIL TABLET 8',
        supplierCode: 'TEV',
        brandName: 'CANDESARTAN TB 8MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d78f7ad',
        DIN: '2326965',
        genericName: 'CANDESARTAN CILEXETIL TABLET 8',
        supplierCode: 'SDZ',
        brandName: 'CANDESARTAN TB 8MG BL 3X10 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 6.84,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba20f65f11ad3d790a04',
        DIN: '2386518',
        genericName: 'CANDESARTAN CILEXETIL TABLET 8',
        supplierCode: 'JAP',
        brandName: 'CANDESARTAN TB 8MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d7904b1',
        DIN: '2391198',
        genericName: 'CANDESARTAN CILEXETIL TABLET 8',
        supplierCode: 'PMS',
        brandName: 'CANDESARTAN TB 8MG BLS 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 6.84,
        dailyAverageDispensed: 1,
      },
      MPH: {
        _id: '6201ba23f65f11ad3d79660c',
        DIN: '2476916',
        genericName: 'CANDESARTAN CILEXETIL TABLET 8',
        supplierCode: 'MPH',
        brandName: 'CANDESARTAN TB 8MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba20f65f11ad3d790792',
        DIN: '2239091',
        genericName: 'CANDESARTAN CILEXETIL TABLET 8',
        supplierCode: 'AST',
        brandName: 'ATACAND TB 8MG BLS 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 44.45,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d790651',
        DIN: '2388928',
        genericName: 'CANDESARTAN CILEXETIL TABLET 8',
        supplierCode: 'SNS',
        brandName: 'CANDESARTAN TB 8MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d795e87',
        DIN: '2445794',
        genericName: 'CANDESARTAN CILEXETIL TABLET 8',
        supplierCode: 'AUP',
        brandName: 'CANDESARTAN TB 8MG 90 AURO',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 20.53,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 42,
      total: 0,
    },
  },
  {
    groupCode: '15941',
    genericName: 'RAMIPRIL CAPSULE 5 MG ORAL',
    strength: '5MG',
    unitPrice: 1.002,
    MFRs: {
      RAN: {
        _id: '6201ba27f65f11ad3d79d697',
        DIN: '2310538',
        genericName: 'RAMIPRIL CAPSULE 5 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'RAMIPRIL CAPS 5MG 100 RAN',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '67',
        packPrice: 8.17,
        dailyAverageDispensed: 24,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c57f',
        DIN: '2247946',
        genericName: 'RAMIPRIL CAPSULE 5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'RAMIPRIL CAPS 5MG BLST 30 TEVA',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '55',
        packPrice: 2.45,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c40a',
        DIN: '2251574',
        genericName: 'RAMIPRIL CAPSULE 5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'RAMIPRIL CAPS 5MG UD 30 APO',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '55',
        packPrice: 2.45,
        dailyAverageDispensed: 17,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d791d05',
        DIN: '2421313',
        genericName: 'RAMIPRIL CAPSULE 5 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'RAMIPRIL CAPS 5MG 100 MINT',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '55',
        packPrice: 8.17,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d7961db',
        DIN: '2469073',
        genericName: 'RAMIPRIL CAPSULE 5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'RAMIPRIL CAPS 5MG BLST 3X10 PHARMA',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '50',
        packPrice: 2.45,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba28f65f11ad3d79e104',
        DIN: '2331136',
        genericName: 'RAMIPRIL CAPSULE 5 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'RAMIPRIL CAPS 5MG UD 30 JAMP',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '40',
        packPrice: 2.45,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d791d2f',
        DIN: '2420473',
        genericName: 'RAMIPRIL CAPSULE 5 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'RAMIPRIL CAPS 5MG BLST 30 MAR',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '40',
        packPrice: 2.45,
        dailyAverageDispensed: 0,
      },
      BAU: {
        _id: '6201ba26f65f11ad3d79be8e',
        DIN: '2221845',
        genericName: 'RAMIPRIL CAPSULE 5 MG ORAL',
        supplierCode: 'BAU',
        brandName: 'ALTACE CAPS 5MG 30',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 30.07,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d793109',
        DIN: '2374854',
        genericName: 'RAMIPRIL CAPSULE 5 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'RAMIPRIL CAPS 5MG 500 SNS',
        form: 'CAPSULE',
        packSize: '500',
        rebateValue: null,
        packPrice: 40.85,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790975',
        DIN: '2387409',
        genericName: 'RAMIPRIL CAPSULE 5 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'RAMIPRIL CAPS 5MG BLST 30 AURO',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 2.45,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 41,
      total: 0,
    },
  },
  {
    groupCode: '33722',
    genericName: 'PERINDOPRIL ERBUMINE TABLET 4',
    strength: '4MG',
    unitPrice: 0.932,
    MFRs: {
      JAP: {
        _id: '6201ba23f65f11ad3d796eea',
        DIN: '2477017',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 4',
        supplierCode: 'JAP',
        brandName: 'PERINDOPRIL TB 4MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 20.42,
        dailyAverageDispensed: 20,
      },
      TEV: {
        _id: '6201ba23f65f11ad3d7956ea',
        DIN: '2464993',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 4',
        supplierCode: 'TEV',
        brandName: 'PERINDOPRIL TB 4MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 20.42,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d7957be',
        DIN: '2470683',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 4',
        supplierCode: 'PMS',
        brandName: 'PERINDOPRIL TB 4MG BLST 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 6.13,
        dailyAverageDispensed: 21,
      },
      APX: {
        _id: '6201ba23f65f11ad3d79582d',
        DIN: '2289288',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 4',
        supplierCode: 'APX',
        brandName: 'PERINDOPRIL TB 4MG BLST 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '68',
        packPrice: 6.13,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba23f65f11ad3d796e18',
        DIN: '2476770',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 4',
        supplierCode: 'MPH',
        brandName: 'PERINDOPRIL TB 4MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 20.42,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba23f65f11ad3d79686a',
        DIN: '2474832',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 4',
        supplierCode: 'MRC',
        brandName: 'PERINDOPRIL TB 4MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '58',
        packPrice: 20.42,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d7957dd',
        DIN: '2470233',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 4',
        supplierCode: 'SDZ',
        brandName: 'PERINDOPRIL ERBUMINE TB 4MG BL 1X30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '55',
        packPrice: 6.13,
        dailyAverageDispensed: 0,
      },
      SEV: {
        _id: '6201ba27f65f11ad3d79c64a',
        DIN: '2123282',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 4',
        supplierCode: 'SEV',
        brandName: 'COVERSYL TB 4MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 27.96,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d79581d',
        DIN: '2459825',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 4',
        supplierCode: 'AUP',
        brandName: 'PERINDOPRIL TB 4MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 20.42,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba24f65f11ad3d7972bc',
        DIN: '2481642',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 4',
        supplierCode: 'SNS',
        brandName: 'PERINDOPRIL TB 4MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 20.42,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 41,
      total: 0,
    },
  },
  {
    groupCode: '40941',
    genericName: 'RABEPRAZOLE SODIUM TABLET DR 2',
    strength: '20MG',
    unitPrice: 2.602,
    MFRs: {
      RAN: {
        _id: '6201ba27f65f11ad3d79ce2a',
        DIN: '2298082',
        genericName: 'RABEPRAZOLE SODIUM TABLET DR 2',
        supplierCode: 'RAN',
        brandName: 'RABEPRAZOLE TB 20MG 100 RAN',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: '52',
        packPrice: 13.38,
        dailyAverageDispensed: 40,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79d645',
        DIN: '2310813',
        genericName: 'RABEPRAZOLE SODIUM TABLET DR 2',
        supplierCode: 'PMS',
        brandName: 'RABEPRAZOLE EC TB 20MG 100 PMS',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: '45',
        packPrice: 13.38,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba20f65f11ad3d79096a',
        DIN: '2314185',
        genericName: 'RABEPRAZOLE SODIUM TABLET DR 2',
        supplierCode: 'SDZ',
        brandName: 'RABEPRAZOLE EC TB 20MG BLS 30 SDZ',
        form: 'TABLET EC',
        packSize: '30',
        rebateValue: '35',
        packPrice: 4.01,
        dailyAverageDispensed: 0,
      },
      JNO: {
        _id: '6201ba26f65f11ad3d79be71',
        DIN: '2243797',
        genericName: 'RABEPRAZOLE SODIUM TABLET DR 2',
        supplierCode: 'JNO',
        brandName: 'PARIET TB 20MG 100',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: null,
        packPrice: 260.23,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d792169',
        DIN: '2356538',
        genericName: 'RABEPRAZOLE SODIUM TABLET DR 2',
        supplierCode: 'SNS',
        brandName: 'RABEPRAZOLE EC TB 20MG 100 SNS',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: null,
        packPrice: 13.38,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 40,
      total: 0,
    },
  },
  {
    groupCode: '16031',
    genericName: 'RAMIPRIL CAPSULE 10 MG ORAL',
    strength: '10MG',
    unitPrice: 1.288,
    MFRs: {
      RAN: {
        _id: '6201ba27f65f11ad3d79c611',
        DIN: '2310546',
        genericName: 'RAMIPRIL CAPSULE 10 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'RAMIPRIL CAPS 10MG 100 RAN',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '67',
        packPrice: 10.34,
        dailyAverageDispensed: 21,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c582',
        DIN: '2247947',
        genericName: 'RAMIPRIL CAPSULE 10 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'RAMIPRIL CAPS 10MG BLST 30 TEVA',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '55',
        packPrice: 3.1,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c40b',
        DIN: '2251582',
        genericName: 'RAMIPRIL CAPSULE 10 MG ORAL',
        supplierCode: 'APX',
        brandName: 'RAMIPRIL CAPS 10MG UD 30 APO',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '55',
        packPrice: 3.1,
        dailyAverageDispensed: 18,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d791d07',
        DIN: '2421321',
        genericName: 'RAMIPRIL CAPSULE 10 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'RAMIPRIL CAPS 10MG 100 MINT',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '55',
        packPrice: 10.34,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d7961df',
        DIN: '2469081',
        genericName: 'RAMIPRIL CAPSULE 10 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'RAMIPRIL CAPS 10MG BLST 3X10 PHARMA',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '50',
        packPrice: 3.1,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba27f65f11ad3d79c9bb',
        DIN: '2331144',
        genericName: 'RAMIPRIL CAPSULE 10 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'RAMIPRIL CAPS 10MG UD 30 JAMP',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '40',
        packPrice: 3.1,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d791d2d',
        DIN: '2420481',
        genericName: 'RAMIPRIL CAPSULE 10 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'RAMIPRIL CAPS 10MG BLST 30 MAR',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '40',
        packPrice: 3.1,
        dailyAverageDispensed: 0,
      },
      BAU: {
        _id: '6201ba26f65f11ad3d79be8f',
        DIN: '2221853',
        genericName: 'RAMIPRIL CAPSULE 10 MG ORAL',
        supplierCode: 'BAU',
        brandName: 'ALTACE CAPS 10MG 30',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 38.63,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d79310a',
        DIN: '2374862',
        genericName: 'RAMIPRIL CAPSULE 10 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'RAMIPRIL CAPS 10MG 500 SNS',
        form: 'CAPSULE',
        packSize: '500',
        rebateValue: null,
        packPrice: 51.7,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d79097a',
        DIN: '2387417',
        genericName: 'RAMIPRIL CAPSULE 10 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'RAMIPRIL CAPS 10MG BLST 30 AURO',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 3.1,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 39,
      total: 0,
    },
  },
  {
    groupCode: '37017',
    genericName: 'CANDESARTAN CILEXETIL TABLET 1',
    strength: '16MG',
    unitPrice: 1.482,
    MFRs: {
      RAN: {
        _id: '6201ba20f65f11ad3d790fd0',
        DIN: '2380706',
        genericName: 'CANDESARTAN CILEXETIL TABLET 1',
        supplierCode: 'RAN',
        brandName: 'CANDESARTAN TB 16MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 22.81,
        dailyAverageDispensed: 17,
      },
      AHC: {
        _id: '6201ba26f65f11ad3d79ad33',
        DIN: '2379287',
        genericName: 'CANDESARTAN CILEXETIL TABLET 1',
        supplierCode: 'AHC',
        brandName: 'CANDESARTAN TB 16MG 100 ACH',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba1ff65f11ad3d78f816',
        DIN: '2365367',
        genericName: 'CANDESARTAN CILEXETIL TABLET 1',
        supplierCode: 'APX',
        brandName: 'CANDESARTAN TB 16MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '63',
        packPrice: 22.81,
        dailyAverageDispensed: 22,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d79029f',
        DIN: '2366320',
        genericName: 'CANDESARTAN CILEXETIL TABLET 1',
        supplierCode: 'TEV',
        brandName: 'CANDESARTAN TB 16MG UD30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 6.84,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d78f7ab',
        DIN: '2326973',
        genericName: 'CANDESARTAN CILEXETIL TABLET 1',
        supplierCode: 'SDZ',
        brandName: 'CANDESARTAN TB 16MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba20f65f11ad3d790a07',
        DIN: '2386526',
        genericName: 'CANDESARTAN CILEXETIL TABLET 1',
        supplierCode: 'JAP',
        brandName: 'CANDESARTAN TB 16MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d7904b2',
        DIN: '2391201',
        genericName: 'CANDESARTAN CILEXETIL TABLET 1',
        supplierCode: 'PMS',
        brandName: 'CANDESARTAN TB 16MG BLS 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 6.84,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba23f65f11ad3d79660b',
        DIN: '2476924',
        genericName: 'CANDESARTAN CILEXETIL TABLET 1',
        supplierCode: 'MPH',
        brandName: 'CANDESARTAN TB 16MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba20f65f11ad3d790794',
        DIN: '2239092',
        genericName: 'CANDESARTAN CILEXETIL TABLET 1',
        supplierCode: 'AST',
        brandName: 'ATACAND TB 16MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 44.45,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d790648',
        DIN: '2388936',
        genericName: 'CANDESARTAN CILEXETIL TABLET 1',
        supplierCode: 'SNS',
        brandName: 'CANDESARTAN TB 16MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d796fe7',
        DIN: '2445808',
        genericName: 'CANDESARTAN CILEXETIL TABLET 1',
        supplierCode: 'AUP',
        brandName: 'CANDESARTAN TB 16MG 90 AURO',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 20.53,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 39,
      total: 0,
    },
  },
  {
    groupCode: '20242',
    genericName: 'ACARBOSE TABLET 50 MG ORAL',
    strength: '50MG',
    unitPrice: 0.284,
    MFRs: {
      MRC: {
        _id: '6201ba24f65f11ad3d79854b',
        DIN: '2494078',
        genericName: 'ACARBOSE TABLET 50 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'ACARBOSE TB 50MG BLST 120 MAR',
        form: 'TABLET',
        packSize: '120',
        rebateValue: '1',
        packPrice: 16.18,
        dailyAverageDispensed: 38,
      },
      BAH: {
        _id: '6201ba24f65f11ad3d7978e3',
        DIN: '2190885',
        genericName: 'ACARBOSE TABLET 50 MG ORAL',
        supplierCode: 'BAH',
        brandName: 'GLUCOBAY TB 50MG UD 120',
        form: 'TABLET',
        packSize: '120',
        rebateValue: null,
        packPrice: 34.12,
        dailyAverageDispensed: 0,
      },
      SIP: {
        _id: '6201ba25f65f11ad3d7998e0',
        DIN: '2493780',
        genericName: 'ACARBOSE TABLET 50 MG ORAL',
        supplierCode: 'SIP',
        brandName: 'ACARBOSE TB 50MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 13.48,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 38,
      total: 0,
    },
  },
  {
    groupCode: '8224',
    genericName: 'INDAPAMIDE TABLET 2.5 MG ORAL',
    strength: '2.5MG',
    unitPrice: 0.236,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79c9e7',
        DIN: '2223678',
        genericName: 'INDAPAMIDE TABLET 2.5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'INDAPAMIDE TB 2.5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 23.64,
        dailyAverageDispensed: 36,
      },
      MYL: {
        _id: '6201ba27f65f11ad3d79d84c',
        DIN: '2153483',
        genericName: 'INDAPAMIDE TABLET 2.5 MG ORAL',
        supplierCode: 'MYL',
        brandName: 'INDAPAMIDE TB 2.5MG 100 MYLAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 23.64,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 37,
      total: 0,
    },
  },
  {
    groupCode: '6817',
    genericName: 'SPIRONOLACTONE TABLET 25 MG OR',
    strength: '25MG',
    unitPrice: 0.081,
    MFRs: {
      MPH: {
        _id: '6201ba24f65f11ad3d7986f3',
        DIN: '2488140',
        genericName: 'SPIRONOLACTONE TABLET 25 MG OR',
        supplierCode: 'MPH',
        brandName: 'SPIRONOLACTONE TB 25MG 500 MINT',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '40',
        packPrice: 40.5,
        dailyAverageDispensed: 10,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c858',
        DIN: '613215',
        genericName: 'SPIRONOLACTONE TABLET 25 MG OR',
        supplierCode: 'TEV',
        brandName: 'SPIRONOLACTONE TB 25MG UD 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '15',
        packPrice: 8.1,
        dailyAverageDispensed: 26,
      },
      PFC: {
        _id: '6201ba20f65f11ad3d790940',
        DIN: '28606',
        genericName: 'SPIRONOLACTONE TABLET 25 MG OR',
        supplierCode: 'PFC',
        brandName: 'ALDACTONE TB 25MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 27.59,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 36,
      total: 0,
    },
  },
  {
    groupCode: '16927',
    genericName: 'AMLODIPINE BESYLATE TABLET 10',
    strength: '10MG',
    unitPrice: 0.199,
    MFRs: {
      RAN: {
        _id: '6201ba27f65f11ad3d79d215',
        DIN: '2321866',
        genericName: 'AMLODIPINE BESYLATE TABLET 10',
        supplierCode: 'RAN',
        brandName: 'AMLODIPINE TB 10MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 19.93,
        dailyAverageDispensed: 33,
      },
      APX: {
        _id: '6201ba20f65f11ad3d791a10',
        DIN: '2273381',
        genericName: 'AMLODIPINE BESYLATE TABLET 10',
        supplierCode: 'APX',
        brandName: 'AMLODIPINE TB 10MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 19.93,
        dailyAverageDispensed: 1,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79d28c',
        DIN: '2284073',
        genericName: 'AMLODIPINE BESYLATE TABLET 10',
        supplierCode: 'PMS',
        brandName: 'AMLODIPINE TB 10MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 19.93,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d247',
        DIN: '2297493',
        genericName: 'AMLODIPINE BESYLATE TABLET 10',
        supplierCode: 'TEV',
        brandName: 'AMLODIPINE TB 10MG 100 ACT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 19.93,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba27f65f11ad3d79d070',
        DIN: '2284391',
        genericName: 'AMLODIPINE BESYLATE TABLET 10',
        supplierCode: 'SDZ',
        brandName: 'AMLODIPINE TB 10MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '74',
        packPrice: 19.93,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d78fe07',
        DIN: '2429225',
        genericName: 'AMLODIPINE BESYLATE TABLET 10',
        supplierCode: 'JAP',
        brandName: 'AMLODIPINE TB 10MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 19.93,
        dailyAverageDispensed: 0,
      },
      NRI: {
        _id: '6201ba25f65f11ad3d79928b',
        DIN: '2476479',
        genericName: 'AMLODIPINE BESYLATE TABLET 10',
        supplierCode: 'NRI',
        brandName: 'AMLODIPINE TB 10MG 250 NRA',
        form: 'TABLET',
        packSize: '250',
        rebateValue: '70',
        packPrice: 49.83,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba23f65f11ad3d7969a5',
        DIN: '2419572',
        genericName: 'AMLODIPINE BESYLATE TABLET 10',
        supplierCode: 'AHC',
        brandName: 'AMLODIPINE TB 10MG 250 ACH',
        form: 'TABLET',
        packSize: '250',
        rebateValue: '65',
        packPrice: 49.83,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba1ff65f11ad3d78f758',
        DIN: '2362678',
        genericName: 'AMLODIPINE BESYLATE TABLET 10',
        supplierCode: 'MPH',
        brandName: 'AMLODIPINE TB 10MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 19.93,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba1ff65f11ad3d790459',
        DIN: '2371723',
        genericName: 'AMLODIPINE BESYLATE TABLET 10',
        supplierCode: 'MRC',
        brandName: 'AMLODIPINE TB 10MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 19.93,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba27f65f11ad3d79d36c',
        DIN: '2272121',
        genericName: 'AMLODIPINE BESYLATE TABLET 10',
        supplierCode: 'MYL',
        brandName: 'AMLODIPINE TB 10MG 100 MYLAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 19.93,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba23f65f11ad3d796a4e',
        DIN: '2331292',
        genericName: 'AMLODIPINE BESYLATE TABLET 10',
        supplierCode: 'SNS',
        brandName: 'AMLODIPINE BESYLATE TB 10MG 500 SNS',
        form: 'TABLET',
        packSize: '500',
        rebateValue: null,
        packPrice: 99.65,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790d12',
        DIN: '2397080',
        genericName: 'AMLODIPINE BESYLATE TABLET 10',
        supplierCode: 'AUP',
        brandName: 'AMLODIPINE TB 10MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 19.93,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba27f65f11ad3d79c7c6',
        DIN: '878936',
        genericName: 'AMLODIPINE BESYLATE TABLET 10',
        supplierCode: 'UPJ',
        brandName: 'NORVASC TB 10MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 233.77,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 34,
      total: 0,
    },
  },
  {
    groupCode: '29967',
    genericName: 'ATORVASTATIN CALCIUM TABLET 10',
    strength: '10MG',
    unitPrice: 1.971,
    MFRs: {
      RAN: {
        _id: '6201ba27f65f11ad3d79c142',
        DIN: '2313707',
        genericName: 'ATORVASTATIN CALCIUM TABLET 10',
        supplierCode: 'RAN',
        brandName: 'ATORVASTATIN TB 10MG 90 RAN',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '77',
        packPrice: 15.69,
        dailyAverageDispensed: 18,
      },
      JAP: {
        _id: '6201ba26f65f11ad3d79b218',
        DIN: '2504197',
        genericName: 'ATORVASTATIN CALCIUM TABLET 10',
        supplierCode: 'JAP',
        brandName: 'ATORVASTATIN CALCIUM TB 10MG 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '75',
        packPrice: 5.23,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba24f65f11ad3d798ba7',
        DIN: '2477149',
        genericName: 'ATORVASTATIN CALCIUM TABLET 10',
        supplierCode: 'PMS',
        brandName: 'ATORVASTATIN TB 10MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 17.43,
        dailyAverageDispensed: 16,
      },
      APX: {
        _id: '6201ba21f65f11ad3d7928ac',
        DIN: '2295261',
        genericName: 'ATORVASTATIN CALCIUM TABLET 10',
        supplierCode: 'APX',
        brandName: 'ATORVASTATIN TB 10MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 17.43,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba24f65f11ad3d7970f5',
        DIN: '2310899',
        genericName: 'ATORVASTATIN CALCIUM TABLET 10',
        supplierCode: 'TEV',
        brandName: 'ATORVASTATIN TB 10MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '67',
        packPrice: 5.23,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba25f65f11ad3d79954d',
        DIN: '2324946',
        genericName: 'ATORVASTATIN CALCIUM TABLET 10',
        supplierCode: 'SDZ',
        brandName: 'ATORVASTATIN TB 10MG BLST 3X10 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '67',
        packPrice: 5.23,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba24f65f11ad3d797c01',
        DIN: '2457741',
        genericName: 'ATORVASTATIN CALCIUM TABLET 10',
        supplierCode: 'AHC',
        brandName: 'ATORVASTATIN TB 10MG 90 ACH',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '65',
        packPrice: 15.69,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba22f65f11ad3d794601',
        DIN: '2454017',
        genericName: 'ATORVASTATIN CALCIUM TABLET 10',
        supplierCode: 'MRC',
        brandName: 'ATORVASTATIN TB 10MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 17.43,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba24f65f11ad3d7985c0',
        DIN: '2479508',
        genericName: 'ATORVASTATIN CALCIUM TABLET 10',
        supplierCode: 'MPH',
        brandName: 'ATORVASTATIN TB 10MG 500 MINT',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '60',
        packPrice: 87.15,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba20f65f11ad3d7912c1',
        DIN: '2230711',
        genericName: 'ATORVASTATIN CALCIUM TABLET 10',
        supplierCode: 'UPJ',
        brandName: 'LIPITOR TB 10MG 90',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 177.37,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba25f65f11ad3d79962f',
        DIN: '2348705',
        genericName: 'ATORVASTATIN CALCIUM TABLET 10',
        supplierCode: 'SNS',
        brandName: 'ATORVASTATIN TB 10MG 500 SNS',
        form: 'TABLET',
        packSize: '500',
        rebateValue: null,
        packPrice: 87.15,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba20f65f11ad3d791d3d',
        DIN: '2392933',
        genericName: 'ATORVASTATIN CALCIUM TABLET 10',
        supplierCode: 'MYL',
        brandName: 'ATORVASTATIN TB 10MG 90 MYLAN',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 15.69,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d791e42',
        DIN: '2407256',
        genericName: 'ATORVASTATIN CALCIUM TABLET 10',
        supplierCode: 'AUP',
        brandName: 'ATORVASTATIN TB 10MG 90 AURO',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 15.69,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 34,
      total: 0,
    },
  },
  {
    groupCode: '50712',
    genericName: 'ESCITALOPRAM OXALATE TABLET 10',
    strength: '10MG',
    unitPrice: 2.052,
    MFRs: {
      JAP: {
        _id: '6201ba21f65f11ad3d792420',
        DIN: '2429780',
        genericName: 'ESCITALOPRAM OXALATE TABLET 10',
        supplierCode: 'JAP',
        brandName: 'ESCITALOPRAM TB 10MG BLST 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '82',
        packPrice: 9.33,
        dailyAverageDispensed: 15,
      },
      RAN: {
        _id: '6201ba20f65f11ad3d791bd7',
        DIN: '2385481',
        genericName: 'ESCITALOPRAM OXALATE TABLET 10',
        supplierCode: 'RAN',
        brandName: 'ESCITALOPRAM TB 10MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 31.09,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d791b07',
        DIN: '2318180',
        genericName: 'ESCITALOPRAM OXALATE TABLET 10',
        supplierCode: 'TEV',
        brandName: 'ESCITALOPRAM TB 10MG 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '75',
        packPrice: 9.33,
        dailyAverageDispensed: 17,
      },
      APX: {
        _id: '6201ba20f65f11ad3d791c82',
        DIN: '2295016',
        genericName: 'ESCITALOPRAM OXALATE TABLET 10',
        supplierCode: 'APX',
        brandName: 'ESCITALOPRAM TB 10MG UD 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 9.33,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba21f65f11ad3d79253f',
        DIN: '2407418',
        genericName: 'ESCITALOPRAM OXALATE TABLET 10',
        supplierCode: 'MPH',
        brandName: 'ESCITALOPRAM TB 10MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 31.09,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d791afb',
        DIN: '2423480',
        genericName: 'ESCITALOPRAM OXALATE TABLET 10',
        supplierCode: 'MRC',
        brandName: 'ESCITALOPRAM TB 10MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '63',
        packPrice: 31.09,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba21f65f11ad3d792bb3',
        DIN: '2434652',
        genericName: 'ESCITALOPRAM OXALATE TABLET 10',
        supplierCode: 'AHC',
        brandName: 'ESCITALOPRAM TB 10MG UD 30 ACH',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '63',
        packPrice: 9.33,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba20f65f11ad3d791c1e',
        DIN: '2364077',
        genericName: 'ESCITALOPRAM OXALATE TABLET 10',
        supplierCode: 'SDZ',
        brandName: 'ESCITALOPRAM TB 10MG UD 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '62',
        packPrice: 9.33,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d7960b5',
        DIN: '2469243',
        genericName: 'ESCITALOPRAM OXALATE TABLET 10',
        supplierCode: 'PMS',
        brandName: 'ESCITALOPRAM TB 10MG BLST 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '35',
        packPrice: 9.33,
        dailyAverageDispensed: 0,
      },
      LUN: {
        _id: '6201ba27f65f11ad3d79c399',
        DIN: '2263238',
        genericName: 'ESCITALOPRAM OXALATE TABLET 10',
        supplierCode: 'LUN',
        brandName: 'CIPRALEX TB 10MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 61.55,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba20f65f11ad3d791c38',
        DIN: '2309467',
        genericName: 'ESCITALOPRAM OXALATE TABLET 10',
        supplierCode: 'MYL',
        brandName: 'ESCITALOPRAM TB 10MG 100 MYLAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 31.09,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d792199',
        DIN: '2397358',
        genericName: 'ESCITALOPRAM OXALATE TABLET 10',
        supplierCode: 'AUP',
        brandName: 'ESCITALOPRAM TB 10MG BLST 3X10 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 9.33,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d791fdd',
        DIN: '2430118',
        genericName: 'ESCITALOPRAM OXALATE TABLET 10',
        supplierCode: 'SNS',
        brandName: 'ESCITALOPRAM TB 10MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 31.09,
        dailyAverageDispensed: 0,
      },
      NTC: {
        _id: '6201ba21f65f11ad3d792a44',
        DIN: '2440296',
        genericName: 'ESCITALOPRAM OXALATE TABLET 10',
        supplierCode: 'NTC',
        brandName: 'ESCITALOPRAM TB 10MG 100 NAT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 31.09,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 32,
      total: 0,
    },
  },
  {
    groupCode: '2535',
    genericName: 'ALLOPURINOL TABLET 100 MG ORAL',
    strength: '100MG',
    unitPrice: 0.078,
    MFRs: {
      MRC: {
        _id: '6201ba20f65f11ad3d790aec',
        DIN: '2396327',
        genericName: 'ALLOPURINOL TABLET 100 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'ALLOPURINOL TB 100MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 7.8,
        dailyAverageDispensed: 30,
      },
      APX: {
        _id: '6201ba20f65f11ad3d790c81',
        DIN: '2402769',
        genericName: 'ALLOPURINOL TABLET 100 MG ORAL',
        supplierCode: 'APX',
        brandName: 'ALLOPURINOL TB 100MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 7.8,
        dailyAverageDispensed: 1,
      },
      AAA: {
        _id: '6201ba1ff65f11ad3d78f4e9',
        DIN: '402818',
        genericName: 'ALLOPURINOL TABLET 100 MG ORAL',
        supplierCode: 'AAA',
        brandName: 'ZYLOPRIM TB 100MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 7.8,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 31,
      total: 0,
    },
  },
  {
    groupCode: '45772',
    genericName: 'ATORVASTATIN CALCIUM TABLET 80',
    strength: '80MG',
    unitPrice: 2.649,
    MFRs: {
      RAN: {
        _id: '6201ba27f65f11ad3d79c1e0',
        DIN: '2313758',
        genericName: 'ATORVASTATIN CALCIUM TABLET 80',
        supplierCode: 'RAN',
        brandName: 'ATORVASTATIN TB 80MG 90 RAN',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '77',
        packPrice: 21.08,
        dailyAverageDispensed: 20,
      },
      JAP: {
        _id: '6201ba26f65f11ad3d79b21a',
        DIN: '2504235',
        genericName: 'ATORVASTATIN CALCIUM TABLET 80',
        supplierCode: 'JAP',
        brandName: 'ATORVASTATIN CALCIUM TB 80MG 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '75',
        packPrice: 7.03,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba26f65f11ad3d79b039',
        DIN: '2507269',
        genericName: 'ATORVASTATIN CALCIUM TABLET 80',
        supplierCode: 'PMS',
        brandName: 'ATORVASTATIN TB 80MG 90 PMSC',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '75',
        packPrice: 23.42,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba21f65f11ad3d79356d',
        DIN: '2295318',
        genericName: 'ATORVASTATIN CALCIUM TABLET 80',
        supplierCode: 'APX',
        brandName: 'ATORVASTATIN TB 80MG UD 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 7.03,
        dailyAverageDispensed: 11,
      },
      TEV: {
        _id: '6201ba24f65f11ad3d7970f9',
        DIN: '2310929',
        genericName: 'ATORVASTATIN CALCIUM TABLET 80',
        supplierCode: 'TEV',
        brandName: 'ATORVASTATIN TB 80MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '67',
        packPrice: 7.03,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba25f65f11ad3d798f5e',
        DIN: '2324970',
        genericName: 'ATORVASTATIN CALCIUM TABLET 80',
        supplierCode: 'SDZ',
        brandName: 'ATORVASTATIN TB 80MG BLST 3X10 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '67',
        packPrice: 7.03,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba24f65f11ad3d797c00',
        DIN: '2457784',
        genericName: 'ATORVASTATIN CALCIUM TABLET 80',
        supplierCode: 'AHC',
        brandName: 'ATORVASTATIN TB 80MG 90 ACH',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '65',
        packPrice: 21.08,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba23f65f11ad3d796498',
        DIN: '2454041',
        genericName: 'ATORVASTATIN CALCIUM TABLET 80',
        supplierCode: 'MRC',
        brandName: 'ATORVASTATIN TB 80MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 23.42,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba27f65f11ad3d79cfe4',
        DIN: '2243097',
        genericName: 'ATORVASTATIN CALCIUM TABLET 80',
        supplierCode: 'UPJ',
        brandName: 'LIPITOR TB 80MG BLS PK 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 79.46,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba20f65f11ad3d791d45',
        DIN: '2392976',
        genericName: 'ATORVASTATIN CALCIUM TABLET 80',
        supplierCode: 'MYL',
        brandName: 'ATORVASTATIN TB 80MG 90 MYLAN',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 21.08,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d791e41',
        DIN: '2407280',
        genericName: 'ATORVASTATIN CALCIUM TABLET 80',
        supplierCode: 'AUP',
        brandName: 'ATORVASTATIN TB 80MG 90 AURO',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 21.08,
        dailyAverageDispensed: 0,
      },
      RIV: {
        _id: '6201ba24f65f11ad3d798a7f',
        DIN: '2422794',
        genericName: 'ATORVASTATIN CALCIUM TABLET 80',
        supplierCode: 'RIV',
        brandName: 'ATORVASTATIN TB 80MG 90 RIVA',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 21.08,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 31,
      total: 0,
    },
  },
  {
    groupCode: '31782',
    genericName: 'PRAMIPEXOLE DI-HCL TABLET 0.25',
    strength: '0.25MG',
    unitPrice: 1.223,
    MFRs: {
      APX: {
        _id: '6201ba25f65f11ad3d79a02e',
        DIN: '2292378',
        genericName: 'PRAMIPEXOLE DI-HCL TABLET 0.25',
        supplierCode: 'APX',
        brandName: 'PRAMIPEXOLE TB 0.25MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 19.5,
        dailyAverageDispensed: 23,
      },
      SDZ: {
        _id: '6201ba27f65f11ad3d79c808',
        DIN: '2315262',
        genericName: 'PRAMIPEXOLE DI-HCL TABLET 0.25',
        supplierCode: 'SDZ',
        brandName: 'PRAMIPEXOLE TB 0.25MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 19.5,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba28f65f11ad3d79da54',
        DIN: '2297302',
        genericName: 'PRAMIPEXOLE DI-HCL TABLET 0.25',
        supplierCode: 'TEV',
        brandName: 'PRAMIPEXOLE TB 0.25MG 100 ACT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 19.5,
        dailyAverageDispensed: 0,
      },
      BOE: {
        _id: '6201ba24f65f11ad3d79860f',
        DIN: '2237145',
        genericName: 'PRAMIPEXOLE DI-HCL TABLET 0.25',
        supplierCode: 'BOE',
        brandName: 'MIRAPEX TB 0.25MG BLST 10X10',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 122.33,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d793e67',
        DIN: '2424061',
        genericName: 'PRAMIPEXOLE DI-HCL TABLET 0.25',
        supplierCode: 'AUP',
        brandName: 'PRAMIPEXOLE TB 0.25MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 19.5,
        dailyAverageDispensed: 7,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 30,
      total: 0,
    },
  },
  {
    groupCode: '30107',
    genericName: 'LANSOPRAZOLE CAPSULE DR 30 MG',
    strength: '30MG',
    unitPrice: 2.268,
    MFRs: {
      APX: {
        _id: '6201ba28f65f11ad3d79dba9',
        DIN: '2293838',
        genericName: 'LANSOPRAZOLE CAPSULE DR 30 MG',
        supplierCode: 'APX',
        brandName: 'LANSOPRAZOLE DR CAPS 30MG 100 APO',
        form: 'CAPS DEL REL',
        packSize: '100',
        rebateValue: '70',
        packPrice: 50,
        dailyAverageDispensed: 27,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d790474',
        DIN: '2385651',
        genericName: 'LANSOPRAZOLE CAPSULE DR 30 MG',
        supplierCode: 'SDZ',
        brandName: 'LANSOPRAZOLE CAPS 30MG 100 SDZ',
        form: 'CAPS DEL REL',
        packSize: '100',
        rebateValue: '60',
        packPrice: 50,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba21f65f11ad3d79228a',
        DIN: '2433028',
        genericName: 'LANSOPRAZOLE CAPSULE DR 30 MG',
        supplierCode: 'PMS',
        brandName: 'LANSOPRAZOLE CAPS 30MG 100 PMS',
        form: 'CAPS DEL REL',
        packSize: '100',
        rebateValue: '55',
        packPrice: 50,
        dailyAverageDispensed: 0,
      },
      RAN: {
        _id: '6201ba20f65f11ad3d790fe3',
        DIN: '2402629',
        genericName: 'LANSOPRAZOLE CAPSULE DR 30 MG',
        supplierCode: 'RAN',
        brandName: 'LANSOPRAZOLE CAPS 30MG 100 RAN',
        form: 'CAPS DEL REL',
        packSize: '100',
        rebateValue: '54',
        packPrice: 50,
        dailyAverageDispensed: 2,
      },
      TEV: {
        _id: '6201ba28f65f11ad3d79dc71',
        DIN: '2280523',
        genericName: 'LANSOPRAZOLE CAPSULE DR 30 MG',
        supplierCode: 'TEV',
        brandName: 'LANSOPRAZOLE 30MG CAPS 100 TEVA',
        form: 'CAPS DEL REL',
        packSize: '100',
        rebateValue: '40',
        packPrice: 50,
        dailyAverageDispensed: 0,
      },
      BGP: {
        _id: '6201ba27f65f11ad3d79c6cb',
        DIN: '2165511',
        genericName: 'LANSOPRAZOLE CAPSULE DR 30 MG',
        supplierCode: 'BGP',
        brandName: 'PREVACID CAPS 30MG 30',
        form: 'CAPS DEL REL',
        packSize: '30',
        rebateValue: null,
        packPrice: 68.05,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba28f65f11ad3d79dd44',
        DIN: '2353849',
        genericName: 'LANSOPRAZOLE CAPSULE DR 30 MG',
        supplierCode: 'MYL',
        brandName: 'LANSOPRAZOLE CAPS 30MG 100 MYLAN',
        form: 'CAPS DEL REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 50,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba25f65f11ad3d799633',
        DIN: '2357690',
        genericName: 'LANSOPRAZOLE CAPSULE DR 30 MG',
        supplierCode: 'SNS',
        brandName: 'LANSOPRAZOLE DR CAPS 30MG 100 SNS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 50,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 29,
      total: 0,
    },
  },
  {
    groupCode: '41337',
    genericName: 'PERINDOPRIL ERBUMINE TABLET 2',
    strength: '2MG',
    unitPrice: 0.745,
    MFRs: {
      JAP: {
        _id: '6201ba24f65f11ad3d797a6b',
        DIN: '2477009',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 2',
        supplierCode: 'JAP',
        brandName: 'PERINDOPRIL TB 2MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 16.32,
        dailyAverageDispensed: 5,
      },
      TEV: {
        _id: '6201ba23f65f11ad3d795780',
        DIN: '2464985',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 2',
        supplierCode: 'TEV',
        brandName: 'PERINDOPRIL TB 2MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 16.32,
        dailyAverageDispensed: 15,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d7957bf',
        DIN: '2470675',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 2',
        supplierCode: 'PMS',
        brandName: 'PERINDOPRIL TB 2MG BLST 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 4.9,
        dailyAverageDispensed: 8,
      },
      APX: {
        _id: '6201ba23f65f11ad3d795830',
        DIN: '2289261',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 2',
        supplierCode: 'APX',
        brandName: 'PERINDOPRIL TB 2MG BLST 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '68',
        packPrice: 4.9,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba24f65f11ad3d7971a5',
        DIN: '2476762',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 2',
        supplierCode: 'MPH',
        brandName: 'PERINDOPRIL TB 2MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 16.32,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba23f65f11ad3d79686d',
        DIN: '2474824',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 2',
        supplierCode: 'MRC',
        brandName: 'PERINDOPRIL TB 2MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '58',
        packPrice: 16.32,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d7957de',
        DIN: '2470225',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 2',
        supplierCode: 'SDZ',
        brandName: 'PERINDOPRIL ERBUMINE TB 2MG BL 1X30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '55',
        packPrice: 4.9,
        dailyAverageDispensed: 0,
      },
      SEV: {
        _id: '6201ba27f65f11ad3d79c649',
        DIN: '2123274',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 2',
        supplierCode: 'SEV',
        brandName: 'COVERSYL TB 2MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 22.34,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d79581c',
        DIN: '2459817',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 2',
        supplierCode: 'AUP',
        brandName: 'PERINDOPRIL TB 2MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 16.32,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba24f65f11ad3d7972ba',
        DIN: '2481634',
        genericName: 'PERINDOPRIL ERBUMINE TABLET 2',
        supplierCode: 'SNS',
        brandName: 'PERINDOPRIL TB 2MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 16.32,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 28,
      total: 0,
    },
  },
  {
    groupCode: '40911',
    genericName: 'TELMISARTAN TABLET 80 MG ORAL',
    strength: '80MG',
    unitPrice: 1.316,
    MFRs: {
      AHC: {
        _id: '6201ba24f65f11ad3d797c03',
        DIN: '2407493',
        genericName: 'TELMISARTAN TABLET 80 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'TELMISARTAN TB 80MG 100 ACH',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 21.61,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba25f65f11ad3d799b40',
        DIN: '2386763',
        genericName: 'TELMISARTAN TABLET 80 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'TELMISARTAN TB 80MG UD BLST 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 6.48,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d790099',
        DIN: '2320185',
        genericName: 'TELMISARTAN TABLET 80 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'TELMISARTAN TB 80MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 6.48,
        dailyAverageDispensed: 23,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d78fe88',
        DIN: '2375966',
        genericName: 'TELMISARTAN TABLET 80 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'TELMISARTAN TB 80MG UD 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '45',
        packPrice: 6.84,
        dailyAverageDispensed: 4,
      },
      PMS: {
        _id: '6201ba25f65f11ad3d79a0f8',
        DIN: '2499630',
        genericName: 'TELMISARTAN TABLET 80 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'TELMISARTAN TB 80MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '45',
        packPrice: 21.61,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba24f65f11ad3d7989fe',
        DIN: '2486377',
        genericName: 'TELMISARTAN TABLET 80 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'TELMISARTAN TB 80MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 21.61,
        dailyAverageDispensed: 0,
      },
      BOE: {
        _id: '6201ba27f65f11ad3d79c769',
        DIN: '2240770',
        genericName: 'TELMISARTAN TABLET 80 MG ORAL',
        supplierCode: 'BOE',
        brandName: 'MICARDIS TB 80MG BLST 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 36.86,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d790626',
        DIN: '2388952',
        genericName: 'TELMISARTAN TABLET 80 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'TELMISARTAN TB 80MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 21.61,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d7940ad',
        DIN: '2453576',
        genericName: 'TELMISARTAN TABLET 80 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'TELMISARTAN TB 80MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 21.61,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 27,
      total: 0,
    },
  },
  {
    groupCode: '65272',
    genericName: 'PERINDOPRIL ERBUMIN/INDAPAMIDE',
    strength: '8MG/2.5MG',
    unitPrice: 1.305,
    MFRs: {
      APX: {
        _id: '6201ba26f65f11ad3d79b472',
        DIN: '2453061',
        genericName: 'PERINDOPRIL ERBUMIN/INDAPAMIDE',
        supplierCode: 'APX',
        brandName: 'PERINDOPRIL/INDAPAMIDE TB 8MG/2.5MG BLST 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 8.58,
        dailyAverageDispensed: 27,
      },
      TEV: {
        _id: '6201ba23f65f11ad3d7956fe',
        DIN: '2464039',
        genericName: 'PERINDOPRIL ERBUMIN/INDAPAMIDE',
        supplierCode: 'TEV',
        brandName: 'PERINDOPRIL/INDAPAMIDE TB 8/2.5MG 100 TEVA',
        form: 'TAB FC',
        packSize: '100',
        rebateValue: '55',
        packPrice: 28.59,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d7957e3',
        DIN: '2470446',
        genericName: 'PERINDOPRIL ERBUMIN/INDAPAMIDE',
        supplierCode: 'SDZ',
        brandName: 'PERINDOPRIL ERBUM/IND HD TB 8MG/2.5MG PLQ 2X15 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '55',
        packPrice: 8.58,
        dailyAverageDispensed: 0,
      },
      SEV: {
        _id: '6201ba28f65f11ad3d79db39',
        DIN: '2321653',
        genericName: 'PERINDOPRIL ERBUMIN/INDAPAMIDE',
        supplierCode: 'SEV',
        brandName: 'COVERSYL PLUS HD TB 8/2.5MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 39.15,
        dailyAverageDispensed: 0,
      },
      SIV: {
        _id: '6201ba26f65f11ad3d79b40e',
        DIN: '2479842',
        genericName: 'PERINDOPRIL ERBUMIN/INDAPAMIDE',
        supplierCode: 'SIV',
        brandName: 'PERINDOPRIL/INDAPAMIDE TB 8MG/2.5MG 30 SIV',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 8.58,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79b39e',
        DIN: '2519739',
        genericName: 'PERINDOPRIL ERBUMIN/INDAPAMIDE',
        supplierCode: 'SNS',
        brandName: 'PERINDOPRIL/INDAPAMIDE TB 8MG 2.5MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 28.59,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 27,
      total: 0,
    },
  },
  {
    groupCode: '40659',
    genericName: 'CANDESARTAN CILEXETIL TABLET 3',
    strength: '32MG',
    unitPrice: 1.482,
    MFRs: {
      RAN: {
        _id: '6201ba20f65f11ad3d790fd1',
        DIN: '2380714',
        genericName: 'CANDESARTAN CILEXETIL TABLET 3',
        supplierCode: 'RAN',
        brandName: 'CANDESARTAN TB 32MG UD 30 RAN',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '77',
        packPrice: 6.84,
        dailyAverageDispensed: 14,
      },
      AHC: {
        _id: '6201ba25f65f11ad3d799978',
        DIN: '2379295',
        genericName: 'CANDESARTAN CILEXETIL TABLET 3',
        supplierCode: 'AHC',
        brandName: 'CANDESARTAN TB 32MG 100 ACH',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba20f65f11ad3d790edd',
        DIN: '2399105',
        genericName: 'CANDESARTAN CILEXETIL TABLET 3',
        supplierCode: 'APX',
        brandName: 'CANDESARTAN TB 32MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '63',
        packPrice: 22.81,
        dailyAverageDispensed: 10,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d7902a0',
        DIN: '2366339',
        genericName: 'CANDESARTAN CILEXETIL TABLET 3',
        supplierCode: 'TEV',
        brandName: 'CANDESARTAN TB 32MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 6.84,
        dailyAverageDispensed: 2,
      },
      JAP: {
        _id: '6201ba20f65f11ad3d790af7',
        DIN: '2386534',
        genericName: 'CANDESARTAN CILEXETIL TABLET 3',
        supplierCode: 'JAP',
        brandName: 'CANDESARTAN TB 32MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d7904b3',
        DIN: '2391228',
        genericName: 'CANDESARTAN CILEXETIL TABLET 3',
        supplierCode: 'PMS',
        brandName: 'CANDESARTAN TB 32MG BLS 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 6.84,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba20f65f11ad3d791953',
        DIN: '2417340',
        genericName: 'CANDESARTAN CILEXETIL TABLET 3',
        supplierCode: 'SDZ',
        brandName: 'CANDESARTAN TB 32MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba20f65f11ad3d791739',
        DIN: '2311658',
        genericName: 'CANDESARTAN CILEXETIL TABLET 3',
        supplierCode: 'AST',
        brandName: 'ATACAND TB 32MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 44.45,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d7923d2',
        DIN: '2435845',
        genericName: 'CANDESARTAN CILEXETIL TABLET 3',
        supplierCode: 'SNS',
        brandName: 'CANDESARTAN TB 32MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d796fe6',
        DIN: '2445816',
        genericName: 'CANDESARTAN CILEXETIL TABLET 3',
        supplierCode: 'AUP',
        brandName: 'CANDESARTAN TB 32MG 90 AURO',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 20.53,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 26,
      total: 0,
    },
  },
  {
    groupCode: '5139',
    genericName: 'ATENOLOL TABLET 50 MG ORAL',
    strength: '50MG',
    unitPrice: 0.649,
    MFRs: {
      PMS: {
        _id: '6201ba1ff65f11ad3d78f54f',
        DIN: '2237600',
        genericName: 'ATENOLOL TABLET 50 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'ATENOLOL TB 50MG BL 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '72',
        packPrice: 3.32,
        dailyAverageDispensed: 4,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79b7a9',
        DIN: '773689',
        genericName: 'ATENOLOL TABLET 50 MG ORAL',
        supplierCode: 'APX',
        brandName: 'ATENOL TB 50MG UD 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '64',
        packPrice: 3.32,
        dailyAverageDispensed: 8,
      },
      RAN: {
        _id: '6201ba27f65f11ad3d79c960',
        DIN: '2267985',
        genericName: 'ATENOLOL TABLET 50 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'ATENOLOL TB 50MG BL 30 RAN',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '62',
        packPrice: 3.32,
        dailyAverageDispensed: 13,
      },
      TEV: {
        _id: '6201ba28f65f11ad3d79df6a',
        DIN: '2171791',
        genericName: 'ATENOLOL TABLET 50 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'ATENOLOL TB 50MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 11.07,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba1ff65f11ad3d790483',
        DIN: '2371987',
        genericName: 'ATENOLOL TABLET 50 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'ATENOLOL TB 50MG BLST 30 MAR',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '58',
        packPrice: 3.32,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba1ff65f11ad3d78fa61',
        DIN: '2368021',
        genericName: 'ATENOLOL TABLET 50 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'ATENOL TB 50MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 11.07,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d78fbc7',
        DIN: '2367564',
        genericName: 'ATENOLOL TABLET 50 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'ATENOLOL TB 50MG 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 3.32,
        dailyAverageDispensed: 0,
      },
      SGP: {
        _id: '6201ba27f65f11ad3d79d90c',
        DIN: '2039532',
        genericName: 'ATENOLOL TABLET 50 MG ORAL',
        supplierCode: 'SGP',
        brandName: 'TENORMIN TB 50MG BLST 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 19.47,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba22f65f11ad3d794f6e',
        DIN: '2466465',
        genericName: 'ATENOLOL TABLET 50 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'ATENOLOL TB 50MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 11.07,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 25,
      total: 0,
    },
  },
  {
    groupCode: '17955',
    genericName: 'BISOPROLOL FUMARATE TABLET 10',
    strength: '10MG',
    unitPrice: 0.104,
    MFRs: {
      SDZ: {
        _id: '6201ba24f65f11ad3d7985ef',
        DIN: '2494043',
        genericName: 'BISOPROLOL FUMARATE TABLET 10',
        supplierCode: 'SDZ',
        brandName: 'BISOPROLOL TB 10MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '66',
        packPrice: 10.44,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79da36',
        DIN: '2267489',
        genericName: 'BISOPROLOL FUMARATE TABLET 10',
        supplierCode: 'TEV',
        brandName: 'BISOPROLOL TB 10MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 10.44,
        dailyAverageDispensed: 15,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79d08b',
        DIN: '2256177',
        genericName: 'BISOPROLOL FUMARATE TABLET 10',
        supplierCode: 'APX',
        brandName: 'BISOPROLOL TB 10MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '41',
        packPrice: 10.44,
        dailyAverageDispensed: 10,
      },
      MPH: {
        _id: '6201ba23f65f11ad3d796e95',
        DIN: '2465620',
        genericName: 'BISOPROLOL FUMARATE TABLET 10',
        supplierCode: 'MPH',
        brandName: 'BISOPROLOL TB 10MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 10.44,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba25f65f11ad3d799311',
        DIN: '2391597',
        genericName: 'BISOPROLOL FUMARATE TABLET 10',
        supplierCode: 'SNS',
        brandName: 'BISOPROLOL TB 10MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 10.44,
        dailyAverageDispensed: 0,
      },
      SIV: {
        _id: '6201ba25f65f11ad3d798d28',
        DIN: '2495570',
        genericName: 'BISOPROLOL FUMARATE TABLET 10',
        supplierCode: 'SIV',
        brandName: 'BISOPROLOL TABLETS TB 10MG 100 SIVEM',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 10.44,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 25,
      total: 0,
    },
  },
  {
    groupCode: '34469',
    genericName: 'IRBESARTAN TABLET 300 MG ORAL',
    strength: '300MG',
    unitPrice: 1.337,
    MFRs: {
      RAN: {
        _id: '6201ba20f65f11ad3d7912f8',
        DIN: '2406837',
        genericName: 'IRBESARTAN TABLET 300 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'IRBESARTAN TB 300MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 22.81,
        dailyAverageDispensed: 15,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d78f6b8',
        DIN: '2317087',
        genericName: 'IRBESARTAN TABLET 300 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'IRBESARTAN TB 300MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d78f6a4',
        DIN: '2328496',
        genericName: 'IRBESARTAN TABLET 300 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'IRBESARTAN TB 300MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 22.81,
        dailyAverageDispensed: 10,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d78f717',
        DIN: '2316412',
        genericName: 'IRBESARTAN TABLET 300 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'IRBESARTAN TB 300MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba21f65f11ad3d792724',
        DIN: '2423006',
        genericName: 'IRBESARTAN TABLET 300 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'IRBESARTAN TB 300MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      SAC: {
        _id: '6201ba20f65f11ad3d790e2a',
        DIN: '2237925',
        genericName: 'IRBESARTAN TABLET 300 MG ORAL',
        supplierCode: 'SAC',
        brandName: 'AVAPRO TB 300MG 90',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 120.32,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d792b9e',
        DIN: '2372398',
        genericName: 'IRBESARTAN TABLET 300 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'IRBESARTAN TB 300MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d7911fa',
        DIN: '2406128',
        genericName: 'IRBESARTAN TABLET 300 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'IRBESARTAN TB 300MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 25,
      total: 0,
    },
  },
  {
    groupCode: '51246',
    genericName: 'DUTASTERIDE CAPSULE 0.5 MG ORA',
    strength: '0.5MG',
    unitPrice: 1.891,
    MFRs: {
      TEV: {
        _id: '6201ba20f65f11ad3d7919a7',
        DIN: '2408287',
        genericName: 'DUTASTERIDE CAPSULE 0.5 MG ORA',
        supplierCode: 'TEV',
        brandName: 'DUTASTERIDE CAPS 0.5MG UD 30 TEVA',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '65',
        packPrice: 9.08,
        dailyAverageDispensed: 25,
      },
      PMS: {
        _id: '6201ba20f65f11ad3d7919f0',
        DIN: '2393220',
        genericName: 'DUTASTERIDE CAPSULE 0.5 MG ORA',
        supplierCode: 'PMS',
        brandName: 'DUTASTERIDE CAPS 0.5MG UD 30 PMS',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '35',
        packPrice: 9.08,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba24f65f11ad3d7980ce',
        DIN: '2484870',
        genericName: 'DUTASTERIDE CAPSULE 0.5 MG ORA',
        supplierCode: 'JAP',
        brandName: 'DUTASTERIDE CAPS 0.5MG 30 JAMP',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '35',
        packPrice: 9.08,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba20f65f11ad3d7919e6',
        DIN: '2404206',
        genericName: 'DUTASTERIDE CAPSULE 0.5 MG ORA',
        supplierCode: 'APX',
        brandName: 'DUTASTERIDE CAPS 0.5MG UD 30 APO',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '30',
        packPrice: 9.08,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d791cca',
        DIN: '2428873',
        genericName: 'DUTASTERIDE CAPSULE 0.5 MG ORA',
        supplierCode: 'MPH',
        brandName: 'DUTASTERIDE CAPS 0.5MG BLST 30 MINT',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '25',
        packPrice: 9.08,
        dailyAverageDispensed: 0,
      },
      GSK: {
        _id: '6201ba22f65f11ad3d79523b',
        DIN: '2247813',
        genericName: 'DUTASTERIDE CAPSULE 0.5 MG ORA',
        supplierCode: 'GSK',
        brandName: 'AVODART CAPS 0.5MG BLS 3X10',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 56.74,
        dailyAverageDispensed: 0,
      },
      GMP: {
        _id: '6201ba21f65f11ad3d79200d',
        DIN: '2416298',
        genericName: 'DUTASTERIDE CAPSULE 0.5 MG ORA',
        supplierCode: 'GMP',
        brandName: 'DUTASTERIDE CAPS 0.5MG BLST 30 MED',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 9.58,
        dailyAverageDispensed: 0,
      },
      RIV: {
        _id: '6201ba24f65f11ad3d79765a',
        DIN: '2427753',
        genericName: 'DUTASTERIDE CAPSULE 0.5 MG ORA',
        supplierCode: 'RIV',
        brandName: 'DUTASTERIDE CAPS 0.5MG UD 90 RIVA',
        form: 'CAPSULE',
        packSize: '90',
        rebateValue: null,
        packPrice: 27.24,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d792e79',
        DIN: '2443058',
        genericName: 'DUTASTERIDE CAPSULE 0.5 MG ORA',
        supplierCode: 'SNS',
        brandName: 'DUTASTERIDE CAPS 0.5MG 30 SNS',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 9.08,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d79615f',
        DIN: '2469308',
        genericName: 'DUTASTERIDE CAPSULE 0.5 MG ORA',
        supplierCode: 'AUP',
        brandName: 'DUTASTERIDE CAPS 0.5MG BLST 3X10 AURO',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 9.08,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 25,
      total: 0,
    },
  },
  {
    groupCode: '2538',
    genericName: 'CARBIDOPA/LEVODOPA TABLET 25MG',
    strength: '100-25MG',
    unitPrice: 0.221,
    MFRs: {
      APX: {
        _id: '6201ba26f65f11ad3d79b7ba',
        DIN: '2195941',
        genericName: 'CARBIDOPA/LEVODOPA TABLET 25MG',
        supplierCode: 'APX',
        brandName: 'LEVOCARB TB 100-25MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 22.09,
        dailyAverageDispensed: 24,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79ce6a',
        DIN: '2244495',
        genericName: 'CARBIDOPA/LEVODOPA TABLET 25MG',
        supplierCode: 'TEV',
        brandName: 'LEVODOPA CARBIDOPA TB 100MG/25MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 22.09,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba27f65f11ad3d79d7a8',
        DIN: '513997',
        genericName: 'CARBIDOPA/LEVODOPA TABLET 25MG',
        supplierCode: 'ORN',
        brandName: 'SINEMET TB 25-100MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 96.01,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 24,
      total: 0,
    },
  },
  {
    groupCode: '286',
    genericName: 'HYDRALAZINE HCL TABLET 25 MG O',
    strength: '25MG',
    unitPrice: 0.061,
    MFRs: {
      JAP: {
        _id: '6201ba22f65f11ad3d794c42',
        DIN: '2457873',
        genericName: 'HYDRALAZINE HCL TABLET 25 MG O',
        supplierCode: 'JAP',
        brandName: 'HYDRALAZINE TB 25MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 6.09,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba23f65f11ad3d7956ab',
        DIN: '2468786',
        genericName: 'HYDRALAZINE HCL TABLET 25 MG O',
        supplierCode: 'MPH',
        brandName: 'HYDRALAZINE TB 25MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 6.09,
        dailyAverageDispensed: 10,
      },
      APX: {
        _id: '6201ba22f65f11ad3d794e23',
        DIN: '441627',
        genericName: 'HYDRALAZINE HCL TABLET 25 MG O',
        supplierCode: 'APX',
        brandName: 'HYDRALAZINE TB 25MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 6.09,
        dailyAverageDispensed: 13,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 23,
      total: 0,
    },
  },
  {
    groupCode: '34729',
    genericName: 'DOMPERIDONE MALEATE TABLET 10',
    strength: '10MG',
    unitPrice: 0.043,
    MFRs: {
      TEV: {
        _id: '6201ba27f65f11ad3d79d5bb',
        DIN: '1912070',
        genericName: 'DOMPERIDONE MALEATE TABLET 10',
        supplierCode: 'TEV',
        brandName: 'DOMPERIDONE TB 10MG 500 TEVA',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '60',
        packPrice: 21.4,
        dailyAverageDispensed: 0,
      },
      RAN: {
        _id: '6201ba28f65f11ad3d79dd55',
        DIN: '2268078',
        genericName: 'DOMPERIDONE MALEATE TABLET 10',
        supplierCode: 'RAN',
        brandName: 'DOMPERIDONE TB 10MG 500 RAN',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '52',
        packPrice: 21.4,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba20f65f11ad3d79169d',
        DIN: '2103613',
        genericName: 'DOMPERIDONE MALEATE TABLET 10',
        supplierCode: 'APX',
        brandName: 'DOMPERIDONE TB 10MG 500 APO',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '50',
        packPrice: 21.4,
        dailyAverageDispensed: 23,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d790102',
        DIN: '2369206',
        genericName: 'DOMPERIDONE MALEATE TABLET 10',
        supplierCode: 'JAP',
        brandName: 'DOMPERIDONE TB 10MG 500 JAMP',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '40',
        packPrice: 21.4,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d7912c3',
        DIN: '2403870',
        genericName: 'DOMPERIDONE MALEATE TABLET 10',
        supplierCode: 'MRC',
        brandName: 'DOMPERIDONE TB 10MG 500 MAR',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '1',
        packPrice: 21.4,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d791af9',
        DIN: '2350440',
        genericName: 'DOMPERIDONE MALEATE TABLET 10',
        supplierCode: 'SNS',
        brandName: 'DOMPERIDONE TB 10MG 500 SNS',
        form: 'TABLET',
        packSize: '500',
        rebateValue: null,
        packPrice: 21.4,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 23,
      total: 0,
    },
  },
  {
    groupCode: '57801',
    genericName: 'PREGABALIN CAPSULE 75 MG ORAL',
    strength: '75MG',
    unitPrice: 1.87,
    MFRs: {
      RAN: {
        _id: '6201ba20f65f11ad3d790bbf',
        DIN: '2392836',
        genericName: 'PREGABALIN CAPSULE 75 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'PREGABALIN CAPS 75MG 100 RAN',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '72',
        packPrice: 30.07,
        dailyAverageDispensed: 18,
      },
      PMS: {
        _id: '6201ba20f65f11ad3d7909d3',
        DIN: '2359626',
        genericName: 'PREGABALIN CAPSULE 75 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'PREGABALIN CAPS 75MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '70',
        packPrice: 30.07,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d7909d9',
        DIN: '2361183',
        genericName: 'PREGABALIN CAPSULE 75 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'PREGABALIN CAPS 75MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '70',
        packPrice: 30.07,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d792fbe',
        DIN: '2435993',
        genericName: 'PREGABALIN CAPSULE 75 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'PREGABALIN CAPS 75MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '70',
        packPrice: 30.07,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba20f65f11ad3d790a91',
        DIN: '2390833',
        genericName: 'PREGABALIN CAPSULE 75 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'PREGABALIN CAPS 75MG 100 SDZ',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 30.07,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba24f65f11ad3d7988ff',
        DIN: '2394251',
        genericName: 'PREGABALIN CAPSULE 75 MG ORAL',
        supplierCode: 'APX',
        brandName: 'PREGABALIN CAPS 75MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 30.07,
        dailyAverageDispensed: 5,
      },
      AHC: {
        _id: '6201ba26f65f11ad3d79a6bc',
        DIN: '2449854',
        genericName: 'PREGABALIN CAPSULE 75 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'PREGABALIN CAPS 75MG 100 ACH',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 30.07,
        dailyAverageDispensed: 0,
      },
      NRI: {
        _id: '6201ba25f65f11ad3d799294',
        DIN: '2479133',
        genericName: 'PREGABALIN CAPSULE 75 MG ORAL',
        supplierCode: 'NRI',
        brandName: 'PREGABALIN CAPS 75MG 100 NRA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '60',
        packPrice: 30.07,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d791dcc',
        DIN: '2424185',
        genericName: 'PREGABALIN CAPSULE 75 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'PREGABALIN CAPS 75MG 100 MINT',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '40',
        packPrice: 30.07,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba27f65f11ad3d79d9a8',
        DIN: '2268434',
        genericName: 'PREGABALIN CAPSULE 75 MG ORAL',
        supplierCode: 'UPJ',
        brandName: 'LYRICA CAPS 75MG 60',
        form: 'CAPSULE',
        packSize: '60',
        rebateValue: null,
        packPrice: 112.2,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d79335b',
        DIN: '2405555',
        genericName: 'PREGABALIN CAPSULE 75 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'PREGABALIN CAPS 75MG 500 SNS',
        form: 'CAPSULE',
        packSize: '500',
        rebateValue: null,
        packPrice: 150.35,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d7927c1',
        DIN: '2433885',
        genericName: 'PREGABALIN CAPSULE 75 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'PREGABALIN CAPS 75MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 30.07,
        dailyAverageDispensed: 0,
      },
      NTC: {
        _id: '6201ba24f65f11ad3d7989a0',
        DIN: '2494876',
        genericName: 'PREGABALIN CAPSULE 75 MG ORAL',
        supplierCode: 'NTC',
        brandName: 'PREGABALIN CAPS 75MG 100 NAT',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 30.07,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 23,
      total: 0,
    },
  },
  {
    groupCode: '37015',
    genericName: 'CANDESARTAN CILEXETIL TABLET 4',
    strength: '4MG',
    unitPrice: 0.891,
    MFRs: {
      RAN: {
        _id: '6201ba20f65f11ad3d790fce',
        DIN: '2380684',
        genericName: 'CANDESARTAN CILEXETIL TABLET 4',
        supplierCode: 'RAN',
        brandName: 'CANDESARTAN TB 4MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 17,
        dailyAverageDispensed: 12,
      },
      APX: {
        _id: '6201ba21f65f11ad3d79362f',
        DIN: '2365340',
        genericName: 'CANDESARTAN CILEXETIL TABLET 4',
        supplierCode: 'APX',
        brandName: 'CANDESARTAN TB 4MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '63',
        packPrice: 17,
        dailyAverageDispensed: 1,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d78f7ac',
        DIN: '2326957',
        genericName: 'CANDESARTAN CILEXETIL TABLET 4',
        supplierCode: 'SDZ',
        brandName: 'CANDESARTAN TB 4MG BL 3X10 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 5.1,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d7904b0',
        DIN: '2391171',
        genericName: 'CANDESARTAN CILEXETIL TABLET 4',
        supplierCode: 'PMS',
        brandName: 'CANDESARTAN TB 4MG BLS 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 5.1,
        dailyAverageDispensed: 8,
      },
      AST: {
        _id: '6201ba27f65f11ad3d79d8dc',
        DIN: '2239090',
        genericName: 'CANDESARTAN CILEXETIL TABLET 4',
        supplierCode: 'AST',
        brandName: 'ATACAND TB 4MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 26.73,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d790646',
        DIN: '2388901',
        genericName: 'CANDESARTAN CILEXETIL TABLET 4',
        supplierCode: 'SNS',
        brandName: 'CANDESARTAN TB 4MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 17,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d795e88',
        DIN: '2445786',
        genericName: 'CANDESARTAN CILEXETIL TABLET 4',
        supplierCode: 'AUP',
        brandName: 'CANDESARTAN TB 4MG 90 AURO',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 15.3,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 21,
      total: 0,
    },
  },
  {
    groupCode: '46241',
    genericName: 'TRAZODONE HCL TABLET 50 MG ORA',
    strength: '50MG',
    unitPrice: 0.055,
    MFRs: {
      TEV: {
        _id: '6201ba27f65f11ad3d79d9b0',
        DIN: '2144263',
        genericName: 'TRAZODONE HCL TABLET 50 MG ORA',
        supplierCode: 'TEV',
        brandName: 'TRAZODONE FC TB 50MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 5.54,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba28f65f11ad3d79da74',
        DIN: '1937227',
        genericName: 'TRAZODONE HCL TABLET 50 MG ORA',
        supplierCode: 'PMS',
        brandName: 'TRAZODONE TB 50MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '45',
        packPrice: 5.54,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba28f65f11ad3d79e16e',
        DIN: '2147637',
        genericName: 'TRAZODONE HCL TABLET 50 MG ORA',
        supplierCode: 'APX',
        brandName: 'TRAZODONE TB 50MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 5.54,
        dailyAverageDispensed: 21,
      },
      JAP: {
        _id: '6201ba26f65f11ad3d79b2ad',
        DIN: '2442809',
        genericName: 'TRAZODONE HCL TABLET 50 MG ORA',
        supplierCode: 'JAP',
        brandName: 'TRAZODONE TB 50MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 5.54,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f4dc',
        DIN: '2348772',
        genericName: 'TRAZODONE HCL TABLET 50 MG ORA',
        supplierCode: 'SNS',
        brandName: 'TRAZODONE TB 50MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 5.54,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 21,
      total: 0,
    },
  },
  {
    groupCode: '57800',
    genericName: 'PREGABALIN CAPSULE 50 MG ORAL',
    strength: '50MG',
    unitPrice: 1.445,
    MFRs: {
      RAN: {
        _id: '6201ba20f65f11ad3d790bbd',
        DIN: '2392828',
        genericName: 'PREGABALIN CAPSULE 50 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'PREGABALIN CAPS 50MG 100 RAN',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '72',
        packPrice: 23.24,
        dailyAverageDispensed: 12,
      },
      PMS: {
        _id: '6201ba20f65f11ad3d7909d0',
        DIN: '2359618',
        genericName: 'PREGABALIN CAPSULE 50 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'PREGABALIN CAPS 50MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '70',
        packPrice: 23.24,
        dailyAverageDispensed: 5,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d7909dc',
        DIN: '2361175',
        genericName: 'PREGABALIN CAPSULE 50 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'PREGABALIN CAPS 50MG 60 TEVA',
        form: 'CAPSULE',
        packSize: '60',
        rebateValue: '70',
        packPrice: 13.94,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d792fbd',
        DIN: '2435985',
        genericName: 'PREGABALIN CAPSULE 50 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'PREGABALIN CAPS 50MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '70',
        packPrice: 23.24,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba20f65f11ad3d790a8e',
        DIN: '2390825',
        genericName: 'PREGABALIN CAPSULE 50 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'PREGABALIN CAPS 50MG 100 SDZ',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 23.24,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba24f65f11ad3d7988fe',
        DIN: '2394243',
        genericName: 'PREGABALIN CAPSULE 50 MG ORAL',
        supplierCode: 'APX',
        brandName: 'PREGABALIN CAPS 50MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 23.24,
        dailyAverageDispensed: 4,
      },
      AHC: {
        _id: '6201ba26f65f11ad3d79a6bb',
        DIN: '2449846',
        genericName: 'PREGABALIN CAPSULE 50 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'PREGABALIN CAPS 50MG 100 ACH',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 23.24,
        dailyAverageDispensed: 0,
      },
      NRI: {
        _id: '6201ba25f65f11ad3d799292',
        DIN: '2479125',
        genericName: 'PREGABALIN CAPSULE 50 MG ORAL',
        supplierCode: 'NRI',
        brandName: 'PREGABALIN CAPS 50MG 100 NRA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '60',
        packPrice: 23.24,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d791dcb',
        DIN: '2423812',
        genericName: 'PREGABALIN CAPSULE 50 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'PREGABALIN CAPS 50MG 100 MINT',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '40',
        packPrice: 23.24,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba27f65f11ad3d79d9a7',
        DIN: '2268426',
        genericName: 'PREGABALIN CAPSULE 50 MG ORAL',
        supplierCode: 'UPJ',
        brandName: 'LYRICA CAPS 50MG 60',
        form: 'CAPSULE',
        packSize: '60',
        rebateValue: null,
        packPrice: 86.72,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d79335a',
        DIN: '2405547',
        genericName: 'PREGABALIN CAPSULE 50 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'PREGABALIN CAPS 50MG 500 SNS',
        form: 'CAPSULE',
        packSize: '500',
        rebateValue: null,
        packPrice: 116.2,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d7927c2',
        DIN: '2433877',
        genericName: 'PREGABALIN CAPSULE 50 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'PREGABALIN CAPS 50MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 23.24,
        dailyAverageDispensed: 0,
      },
      NTC: {
        _id: '6201ba24f65f11ad3d79899e',
        DIN: '2494868',
        genericName: 'PREGABALIN CAPSULE 50 MG ORAL',
        supplierCode: 'NTC',
        brandName: 'PREGABALIN CAPS 50MG 100 NAT',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 23.24,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 21,
      total: 0,
    },
  },
  {
    groupCode: '16367',
    genericName: 'PRAVASTATIN SODIUM TABLET 20 M',
    strength: '20MG',
    unitPrice: 0.344,
    MFRs: {
      APX: {
        _id: '6201ba26f65f11ad3d79bb15',
        DIN: '2243507',
        genericName: 'PRAVASTATIN SODIUM TABLET 20 M',
        supplierCode: 'APX',
        brandName: 'PRAVASTATIN TB 20MG UD 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '69',
        packPrice: 10.32,
        dailyAverageDispensed: 14,
      },
      RAN: {
        _id: '6201ba27f65f11ad3d79c95b',
        DIN: '2284448',
        genericName: 'PRAVASTATIN SODIUM TABLET 20 M',
        supplierCode: 'RAN',
        brandName: 'PRAVASTATIN TB 20MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '67',
        packPrice: 34.4,
        dailyAverageDispensed: 6,
      },
      AHC: {
        _id: '6201ba24f65f11ad3d797c07',
        DIN: '2440652',
        genericName: 'PRAVASTATIN SODIUM TABLET 20 M',
        supplierCode: 'AHC',
        brandName: 'PRAVASTATIN TB 20MG 100 ACH',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '66',
        packPrice: 34.4,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79c880',
        DIN: '2247656',
        genericName: 'PRAVASTATIN SODIUM TABLET 20 M',
        supplierCode: 'PMS',
        brandName: 'PRAVASTATIN TB 20MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '62',
        packPrice: 34.4,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d795e12',
        DIN: '2468719',
        genericName: 'PRAVASTATIN SODIUM TABLET 20 M',
        supplierCode: 'SDZ',
        brandName: 'PRAVASTATIN TB 20MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 34.4,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba28f65f11ad3d79de98',
        DIN: '2317478',
        genericName: 'PRAVASTATIN SODIUM TABLET 20 M',
        supplierCode: 'MPH',
        brandName: 'PRAVASTATIN TB 20MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 34.4,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d792c85',
        DIN: '2330962',
        genericName: 'PRAVASTATIN SODIUM TABLET 20 M',
        supplierCode: 'JAP',
        brandName: 'PRAVASTATIN TB 20MG 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 10.32,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c22e',
        DIN: '2247009',
        genericName: 'PRAVASTATIN SODIUM TABLET 20 M',
        supplierCode: 'TEV',
        brandName: 'PRAVASTATIN TB 20MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 10.32,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d790492',
        DIN: '2356554',
        genericName: 'PRAVASTATIN SODIUM TABLET 20 M',
        supplierCode: 'SNS',
        brandName: 'PRAVASTATIN SOD TB 20MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 34.4,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d795ebf',
        DIN: '2458985',
        genericName: 'PRAVASTATIN SODIUM TABLET 20 M',
        supplierCode: 'AUP',
        brandName: 'PRAVASTATIN TB 20MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 34.4,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 20,
      total: 0,
    },
  },
  {
    groupCode: '17029',
    genericName: 'ZOPICLONE TABLET 7.5 MG ORAL',
    strength: '7.5MG',
    unitPrice: 1.443,
    MFRs: {
      PMS: {
        _id: '6201ba27f65f11ad3d79c7e9',
        DIN: '2240606',
        genericName: 'ZOPICLONE TABLET 7.5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'ZOPICLONE TB 7.5MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 46.85,
        dailyAverageDispensed: 10,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d7908b1',
        DIN: '2391724',
        genericName: 'ZOPICLONE TABLET 7.5 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'ZOPICLONE TB 7.5MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 46.85,
        dailyAverageDispensed: 3,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c4de',
        DIN: '2218313',
        genericName: 'ZOPICLONE TABLET 7.5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'ZOPICLONE TB 7.5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 46.85,
        dailyAverageDispensed: 7,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79ccdf',
        DIN: '2242481',
        genericName: 'ZOPICLONE TABLET 7.5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'ZOPICLONE TB 7.5MG 100 RATIO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 46.85,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d790acc',
        DIN: '2386798',
        genericName: 'ZOPICLONE TABLET 7.5 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'ZOPICLONE TB 7.5MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 46.85,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d7925eb',
        DIN: '2406977',
        genericName: 'ZOPICLONE TABLET 7.5 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'ZOPICLONE TB 7.5MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 46.85,
        dailyAverageDispensed: 0,
      },
      SAC: {
        _id: '6201ba27f65f11ad3d79c12b',
        DIN: '1926799',
        genericName: 'ZOPICLONE TABLET 7.5 MG ORAL',
        supplierCode: 'SAC',
        brandName: 'IMOVANE TB 7.5MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 144.3,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d7910f3',
        DIN: '2282445',
        genericName: 'ZOPICLONE TABLET 7.5 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'ZOPICLONE TB 7.5MG 500 SNS',
        form: 'TABLET',
        packSize: '500',
        rebateValue: null,
        packPrice: 234.25,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 20,
      total: 0,
    },
  },
  {
    groupCode: '20741',
    genericName: 'PRAVASTATIN SODIUM TABLET 40 M',
    strength: '40MG',
    unitPrice: 0.414,
    MFRs: {
      APX: {
        _id: '6201ba26f65f11ad3d79bb20',
        DIN: '2243508',
        genericName: 'PRAVASTATIN SODIUM TABLET 40 M',
        supplierCode: 'APX',
        brandName: 'PRAVASTATIN TB 40MG UD 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '69',
        packPrice: 12.43,
        dailyAverageDispensed: 18,
      },
      RAN: {
        _id: '6201ba27f65f11ad3d79c95e',
        DIN: '2284456',
        genericName: 'PRAVASTATIN SODIUM TABLET 40 M',
        supplierCode: 'RAN',
        brandName: 'PRAVASTATIN TB 40MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '67',
        packPrice: 41.43,
        dailyAverageDispensed: 2,
      },
      AHC: {
        _id: '6201ba24f65f11ad3d797c06',
        DIN: '2440660',
        genericName: 'PRAVASTATIN SODIUM TABLET 40 M',
        supplierCode: 'AHC',
        brandName: 'PRAVASTATIN TB 40MG 100 ACH',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '66',
        packPrice: 41.43,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79c884',
        DIN: '2247657',
        genericName: 'PRAVASTATIN SODIUM TABLET 40 M',
        supplierCode: 'PMS',
        brandName: 'PRAVASTATIN TB 40MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '62',
        packPrice: 41.43,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d795e13',
        DIN: '2468727',
        genericName: 'PRAVASTATIN SODIUM TABLET 40 M',
        supplierCode: 'SDZ',
        brandName: 'PRAVASTATIN TB 40MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 41.43,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba28f65f11ad3d79de99',
        DIN: '2317486',
        genericName: 'PRAVASTATIN SODIUM TABLET 40 M',
        supplierCode: 'MPH',
        brandName: 'PRAVASTATIN TB 40MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 41.43,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d792c86',
        DIN: '2330970',
        genericName: 'PRAVASTATIN SODIUM TABLET 40 M',
        supplierCode: 'JAP',
        brandName: 'PRAVASTATIN TB 40MG 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 12.43,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c233',
        DIN: '2247010',
        genericName: 'PRAVASTATIN SODIUM TABLET 40 M',
        supplierCode: 'TEV',
        brandName: 'PRAVASTATIN TB 40MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 12.43,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d790493',
        DIN: '2356562',
        genericName: 'PRAVASTATIN SODIUM TABLET 40 M',
        supplierCode: 'SNS',
        brandName: 'PRAVASTATIN SOD TB 40MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 41.43,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 20,
      total: 0,
    },
  },
  {
    groupCode: '5098',
    genericName: 'LABETALOL HCL TABLET 100 MG OR',
    strength: '100MG',
    unitPrice: 0.427,
    MFRs: {
      APX: {
        _id: '6201ba25f65f11ad3d7990f4',
        DIN: '2243538',
        genericName: 'LABETALOL HCL TABLET 100 MG OR',
        supplierCode: 'APX',
        brandName: 'LABETALOL TB 100MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 19.83,
        dailyAverageDispensed: 19,
      },
      MPH: {
        _id: '6201ba26f65f11ad3d79aad1',
        DIN: '2503255',
        genericName: 'LABETALOL HCL TABLET 100 MG OR',
        supplierCode: 'MPH',
        brandName: 'LABETALOL TB 100MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 19.83,
        dailyAverageDispensed: 0,
      },
      PLI: {
        _id: '6201ba26f65f11ad3d79bd21',
        DIN: '2106272',
        genericName: 'LABETALOL HCL TABLET 100 MG OR',
        supplierCode: 'PLI',
        brandName: 'TRANDATE TB 100MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 42.67,
        dailyAverageDispensed: 0,
      },
      RIV: {
        _id: '6201ba24f65f11ad3d797e2f',
        DIN: '2489406',
        genericName: 'LABETALOL HCL TABLET 100 MG OR',
        supplierCode: 'RIV',
        brandName: 'LABETALOL TB 100MG 100 RIVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 19.83,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 19,
      total: 0,
    },
  },
  {
    groupCode: '43258',
    genericName: 'PERINDOPRIL ERBUMIN/INDAPAMIDE',
    strength: '4MG/1.25MG',
    unitPrice: 1.167,
    MFRs: {
      APX: {
        _id: '6201ba25f65f11ad3d799814',
        DIN: '2297574',
        genericName: 'PERINDOPRIL ERBUMIN/INDAPAMIDE',
        supplierCode: 'APX',
        brandName: 'PERINDOPRIL/INDAPAMIDE TB 4MG/1.25MG BLST 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 7.67,
        dailyAverageDispensed: 18,
      },
      TEV: {
        _id: '6201ba23f65f11ad3d7956fb',
        DIN: '2464020',
        genericName: 'PERINDOPRIL ERBUMIN/INDAPAMIDE',
        supplierCode: 'TEV',
        brandName: 'PERINDOPRIL/INDAPAMIDE TB 4/1.25MG 100 TEVA',
        form: 'TAB FC',
        packSize: '100',
        rebateValue: '55',
        packPrice: 25.56,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d7957dc',
        DIN: '2470438',
        genericName: 'PERINDOPRIL ERBUMIN/INDAPAMIDE',
        supplierCode: 'SDZ',
        brandName: 'PERINDOPRIL ERBUMINE/IND TB 4MG/1.25MG BL 1X30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '55',
        packPrice: 7.67,
        dailyAverageDispensed: 1,
      },
      SEV: {
        _id: '6201ba1ff65f11ad3d790196',
        DIN: '2246569',
        genericName: 'PERINDOPRIL ERBUMIN/INDAPAMIDE',
        supplierCode: 'SEV',
        brandName: 'COVERSYL PLUS TB 4/1.25MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 35,
        dailyAverageDispensed: 0,
      },
      SIV: {
        _id: '6201ba26f65f11ad3d79b40c',
        DIN: '2479834',
        genericName: 'PERINDOPRIL ERBUMIN/INDAPAMIDE',
        supplierCode: 'SIV',
        brandName: 'PERINDOPRIL/INDAPAMIDE TB 4MG/1.25MG 30 SIV',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 7.67,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79b396',
        DIN: '2519720',
        genericName: 'PERINDOPRIL ERBUMIN/INDAPAMIDE',
        supplierCode: 'SNS',
        brandName: 'PERINDOPRIL/INDAPAMIDE TB 4MG 1.25MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 25.56,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 19,
      total: 0,
    },
  },
  {
    groupCode: '46233',
    genericName: 'SERTRALINE HCL CAPSULE 100 MG',
    strength: '100MG',
    unitPrice: 2.054,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79c785',
        DIN: '2238282',
        genericName: 'SERTRALINE HCL CAPSULE 100 MG',
        supplierCode: 'APX',
        brandName: 'SERTRALINE CAPS 100MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '67',
        packPrice: 33.03,
        dailyAverageDispensed: 16,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c6d5',
        DIN: '2240481',
        genericName: 'SERTRALINE HCL CAPSULE 100 MG',
        supplierCode: 'TEV',
        brandName: 'SERTRALINE CAPS 100MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 33.03,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79d004',
        DIN: '2244840',
        genericName: 'SERTRALINE HCL CAPSULE 100 MG',
        supplierCode: 'PMS',
        brandName: 'SERTRALINE CAPS 100MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 33.03,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d7912dc',
        DIN: '2399431',
        genericName: 'SERTRALINE HCL CAPSULE 100 MG',
        supplierCode: 'MRC',
        brandName: 'SERTRALINE CAPS 100MG 100 MAR',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '61',
        packPrice: 33.03,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d7911bc',
        DIN: '2402408',
        genericName: 'SERTRALINE HCL CAPSULE 100 MG',
        supplierCode: 'MPH',
        brandName: 'SERTRALINE CAPS 100MG 100 MINT',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '60',
        packPrice: 33.03,
        dailyAverageDispensed: 2,
      },
      JAP: {
        _id: '6201ba22f65f11ad3d795564',
        DIN: '2469642',
        genericName: 'SERTRALINE HCL CAPSULE 100 MG',
        supplierCode: 'JAP',
        brandName: 'SERTRALINE CAPS 100MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '50',
        packPrice: 33.03,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba28f65f11ad3d79de1e',
        DIN: '1962779',
        genericName: 'SERTRALINE HCL CAPSULE 100 MG',
        supplierCode: 'UPJ',
        brandName: 'ZOLOFT CAPS 100MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 205.43,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d793534',
        DIN: '2353547',
        genericName: 'SERTRALINE HCL CAPSULE 100 MG',
        supplierCode: 'SNS',
        brandName: 'SERTRALINE HCL CAPS 100MG 100 SNS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 33.03,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790d47',
        DIN: '2390922',
        genericName: 'SERTRALINE HCL CAPSULE 100 MG',
        supplierCode: 'AUP',
        brandName: 'SERTRALINE CAPS 100MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 33.03,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 18,
      total: 0,
    },
  },
  {
    groupCode: '50760',
    genericName: 'ESCITALOPRAM OXALATE TABLET 20',
    strength: '20MG',
    unitPrice: 2.19,
    MFRs: {
      JAP: {
        _id: '6201ba21f65f11ad3d792872',
        DIN: '2429799',
        genericName: 'ESCITALOPRAM OXALATE TABLET 20',
        supplierCode: 'JAP',
        brandName: 'ESCITALOPRAM TB 20MG BLST 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '82',
        packPrice: 9.93,
        dailyAverageDispensed: 5,
      },
      RAN: {
        _id: '6201ba20f65f11ad3d791bec',
        DIN: '2385503',
        genericName: 'ESCITALOPRAM OXALATE TABLET 20',
        supplierCode: 'RAN',
        brandName: 'ESCITALOPRAM TB 20MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 33.1,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d791b05',
        DIN: '2318202',
        genericName: 'ESCITALOPRAM OXALATE TABLET 20',
        supplierCode: 'TEV',
        brandName: 'ESCITALOPRAM TB 20MG 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '75',
        packPrice: 9.93,
        dailyAverageDispensed: 12,
      },
      APX: {
        _id: '6201ba20f65f11ad3d791c9c',
        DIN: '2295024',
        genericName: 'ESCITALOPRAM OXALATE TABLET 20',
        supplierCode: 'APX',
        brandName: 'ESCITALOPRAM TB 20MG UD 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 9.93,
        dailyAverageDispensed: 1,
      },
      MPH: {
        _id: '6201ba21f65f11ad3d792541',
        DIN: '2407434',
        genericName: 'ESCITALOPRAM OXALATE TABLET 20',
        supplierCode: 'MPH',
        brandName: 'ESCITALOPRAM TB 20MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 33.1,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d791afd',
        DIN: '2423502',
        genericName: 'ESCITALOPRAM OXALATE TABLET 20',
        supplierCode: 'MRC',
        brandName: 'ESCITALOPRAM TB 20MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '63',
        packPrice: 33.1,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba21f65f11ad3d792bb4',
        DIN: '2434660',
        genericName: 'ESCITALOPRAM OXALATE TABLET 20',
        supplierCode: 'AHC',
        brandName: 'ESCITALOPRAM TB 20MG UD 30 ACH',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '63',
        packPrice: 9.93,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba20f65f11ad3d791c20',
        DIN: '2364085',
        genericName: 'ESCITALOPRAM OXALATE TABLET 20',
        supplierCode: 'SDZ',
        brandName: 'ESCITALOPRAM TB 20MG UD 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '62',
        packPrice: 9.93,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d7960b6',
        DIN: '2469251',
        genericName: 'ESCITALOPRAM OXALATE TABLET 20',
        supplierCode: 'PMS',
        brandName: 'ESCITALOPRAM TB 20MG BLST 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '35',
        packPrice: 9.93,
        dailyAverageDispensed: 0,
      },
      LUN: {
        _id: '6201ba27f65f11ad3d79c3a5',
        DIN: '2263254',
        genericName: 'ESCITALOPRAM OXALATE TABLET 20',
        supplierCode: 'LUN',
        brandName: 'CIPRALEX TB 20MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 65.71,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba20f65f11ad3d791c3a',
        DIN: '2309475',
        genericName: 'ESCITALOPRAM OXALATE TABLET 20',
        supplierCode: 'MYL',
        brandName: 'ESCITALOPRAM TB 20MG 100 MYLAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 33.1,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d792197',
        DIN: '2397374',
        genericName: 'ESCITALOPRAM OXALATE TABLET 20',
        supplierCode: 'AUP',
        brandName: 'ESCITALOPRAM TB 20MG BLST 3X10 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 9.93,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d791fde',
        DIN: '2430126',
        genericName: 'ESCITALOPRAM OXALATE TABLET 20',
        supplierCode: 'SNS',
        brandName: 'ESCITALOPRAM TB 20MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 33.1,
        dailyAverageDispensed: 0,
      },
      NTC: {
        _id: '6201ba21f65f11ad3d792a45',
        DIN: '2440318',
        genericName: 'ESCITALOPRAM OXALATE TABLET 20',
        supplierCode: 'NTC',
        brandName: 'ESCITALOPRAM TB 20MG 100 NAT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 33.1,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 18,
      total: 0,
    },
  },
  {
    groupCode: '13173',
    genericName: 'ALLOPURINOL TABLET 200 MG ORAL',
    strength: '200MG',
    unitPrice: 0.13,
    MFRs: {
      MRC: {
        _id: '6201ba20f65f11ad3d790aef',
        DIN: '2396335',
        genericName: 'ALLOPURINOL TABLET 200 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'ALLOPURINOL TB 200MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 13,
        dailyAverageDispensed: 17,
      },
      APX: {
        _id: '6201ba20f65f11ad3d790c83',
        DIN: '2402777',
        genericName: 'ALLOPURINOL TABLET 200 MG ORAL',
        supplierCode: 'APX',
        brandName: 'ALLOPURINOL TB 200MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 13,
        dailyAverageDispensed: 0,
      },
      AAA: {
        _id: '6201ba1ff65f11ad3d78f4eb',
        DIN: '479799',
        genericName: 'ALLOPURINOL TABLET 200 MG ORAL',
        supplierCode: 'AAA',
        brandName: 'ZYLOPRIM TB 200MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 13,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 17,
      total: 0,
    },
  },
  {
    groupCode: '5099',
    genericName: 'LABETALOL HCL TABLET 200 MG OR',
    strength: '200MG',
    unitPrice: 0.754,
    MFRs: {
      APX: {
        _id: '6201ba25f65f11ad3d7990f3',
        DIN: '2243539',
        genericName: 'LABETALOL HCL TABLET 200 MG OR',
        supplierCode: 'APX',
        brandName: 'LABETALOL TB 200MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 35.04,
        dailyAverageDispensed: 16,
      },
      MPH: {
        _id: '6201ba26f65f11ad3d79aad0',
        DIN: '2503263',
        genericName: 'LABETALOL HCL TABLET 200 MG OR',
        supplierCode: 'MPH',
        brandName: 'LABETALOL TB 200MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 35.04,
        dailyAverageDispensed: 0,
      },
      PLI: {
        _id: '6201ba26f65f11ad3d79bd22',
        DIN: '2106280',
        genericName: 'LABETALOL HCL TABLET 200 MG OR',
        supplierCode: 'PLI',
        brandName: 'TRANDATE TB 200MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 75.42,
        dailyAverageDispensed: 0,
      },
      RIV: {
        _id: '6201ba24f65f11ad3d797e30',
        DIN: '2489414',
        genericName: 'LABETALOL HCL TABLET 200 MG OR',
        supplierCode: 'RIV',
        brandName: 'LABETALOL TB 200MG 100 RIVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 35.04,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 16,
      total: 0,
    },
  },
  {
    groupCode: '41440',
    genericName: 'FINASTERIDE TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 2.498,
    MFRs: {
      TEV: {
        _id: '6201ba27f65f11ad3d79c674',
        DIN: '2348500',
        genericName: 'FINASTERIDE TABLET 5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'FINASTERIDE TB 5MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 12.41,
        dailyAverageDispensed: 16,
      },
      AHC: {
        _id: '6201ba1ff65f11ad3d78f5dc',
        DIN: '2355043',
        genericName: 'FINASTERIDE TABLET 5 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'FINASTERIDE TB 5MG UD 30 ACH',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '66',
        packPrice: 12.41,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba28f65f11ad3d79dfca',
        DIN: '2310112',
        genericName: 'FINASTERIDE TABLET 5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'FINASTERIDE TB 5MG BLS 2X15 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 12.41,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba1ff65f11ad3d790524',
        DIN: '2389878',
        genericName: 'FINASTERIDE TABLET 5 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'FINASTERIDE TB 5MG UD 30 MINT',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 12.41,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba28f65f11ad3d79deff',
        DIN: '2322579',
        genericName: 'FINASTERIDE TABLET 5 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'FINASTERIDE TB 5MG BL 2X15 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 12.41,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba1ff65f11ad3d78fd90',
        DIN: '2365383',
        genericName: 'FINASTERIDE TABLET 5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'FINASTERIDE TB 5MG UD 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '41',
        packPrice: 12.41,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d78f7b2',
        DIN: '2357224',
        genericName: 'FINASTERIDE TABLET 5 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'FINASTERIDE TB 5MG UD 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '40',
        packPrice: 12.41,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba28f65f11ad3d79de5d',
        DIN: '2010909',
        genericName: 'FINASTERIDE TABLET 5 MG ORAL',
        supplierCode: 'ORN',
        brandName: 'PROSCAR TB 5MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 74.95,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d7913d6',
        DIN: '2405814',
        genericName: 'FINASTERIDE TABLET 5 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'FINASTERIDE TB 5MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 12.41,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d7938a0',
        DIN: '2445077',
        genericName: 'FINASTERIDE TABLET 5 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'FINASTERIDE TB 5MG 30 SNS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 12.41,
        dailyAverageDispensed: 0,
      },
      RIV: {
        _id: '6201ba22f65f11ad3d794791',
        DIN: '2455013',
        genericName: 'FINASTERIDE TABLET 5 MG ORAL',
        supplierCode: 'RIV',
        brandName: 'FINASTERIDE TB 5MG 30 RIVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 12.41,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 16,
      total: 0,
    },
  },
  {
    groupCode: '57892',
    genericName: 'DULOXETINE HCL CAPSULE DR 30 M',
    strength: '30MG',
    unitPrice: 2.279,
    MFRs: {
      MPH: {
        _id: '6201ba21f65f11ad3d7932b9',
        DIN: '2438984',
        genericName: 'DULOXETINE HCL CAPSULE DR 30 M',
        supplierCode: 'MPH',
        brandName: 'DULOXETINE CAPS 30MG 100 MINT',
        form: 'CAPS DEL REL',
        packSize: '100',
        rebateValue: '70',
        packPrice: 48.14,
        dailyAverageDispensed: 4,
      },
      TEV: {
        _id: '6201ba25f65f11ad3d798c87',
        DIN: '2456753',
        genericName: 'DULOXETINE HCL CAPSULE DR 30 M',
        supplierCode: 'TEV',
        brandName: 'DULOXETINE CAPS 30MG BLST 30 TEVA',
        form: 'CAPS DEL REL',
        packSize: '30',
        rebateValue: '70',
        packPrice: 14.44,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba21f65f11ad3d793286',
        DIN: '2429446',
        genericName: 'DULOXETINE HCL CAPSULE DR 30 M',
        supplierCode: 'PMS',
        brandName: 'DULOXETINE CAPS 30MG BLST 30 PMS',
        form: 'CAPS DEL REL',
        packSize: '30',
        rebateValue: '65',
        packPrice: 14.44,
        dailyAverageDispensed: 6,
      },
      APX: {
        _id: '6201ba21f65f11ad3d79329b',
        DIN: '2440423',
        genericName: 'DULOXETINE HCL CAPSULE DR 30 M',
        supplierCode: 'APX',
        brandName: 'DULOXETINE CAPS 30MG BLST 30 APO',
        form: 'CAPS DEL REL',
        packSize: '30',
        rebateValue: '65',
        packPrice: 14.44,
        dailyAverageDispensed: 6,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d793865',
        DIN: '2451913',
        genericName: 'DULOXETINE HCL CAPSULE DR 30 M',
        supplierCode: 'JAP',
        brandName: 'DULOXETINE CAPS 30MG BLST 30 JAMP',
        form: 'CAPS DEL REL',
        packSize: '30',
        rebateValue: '65',
        packPrice: 14.44,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba21f65f11ad3d793261',
        DIN: '2439948',
        genericName: 'DULOXETINE HCL CAPSULE DR 30 M',
        supplierCode: 'SDZ',
        brandName: 'DULOXETINE CAPS 30MG BLST 30 SDZ',
        form: 'CAPS DEL REL',
        packSize: '30',
        rebateValue: '61',
        packPrice: 14.44,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba21f65f11ad3d7932ef',
        DIN: '2446081',
        genericName: 'DULOXETINE HCL CAPSULE DR 30 M',
        supplierCode: 'MRC',
        brandName: 'DULOXETINE CAPS 30MG 100 MAR',
        form: 'CAPS DEL REL',
        packSize: '100',
        rebateValue: '60',
        packPrice: 48.14,
        dailyAverageDispensed: 0,
      },
      LIL: {
        _id: '6201ba27f65f11ad3d79d05b',
        DIN: '2301482',
        genericName: 'DULOXETINE HCL CAPSULE DR 30 M',
        supplierCode: 'LIL',
        brandName: 'CYMBALTA CAPS 30MG 28',
        form: 'CAPS DEL REL',
        packSize: '28',
        rebateValue: null,
        packPrice: 63.8,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d793497',
        DIN: '2436647',
        genericName: 'DULOXETINE HCL CAPSULE DR 30 M',
        supplierCode: 'AUP',
        brandName: 'DULOXETINE CAPS 30MG 30 AURO',
        form: 'CAPS DEL REL',
        packSize: '30',
        rebateValue: null,
        packPrice: 14.44,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba24f65f11ad3d797b27',
        DIN: '2490889',
        genericName: 'DULOXETINE HCL CAPSULE DR 30 M',
        supplierCode: 'SNS',
        brandName: 'DULOXETINE HCL CAPS 30MG 100 SNS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 48.14,
        dailyAverageDispensed: 0,
      },
      RIV: {
        _id: '6201ba24f65f11ad3d798b23',
        DIN: '2495082',
        genericName: 'DULOXETINE HCL CAPSULE DR 30 M',
        supplierCode: 'RIV',
        brandName: 'DULOXETINE CAPS 30MG BLST 30',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 29.31,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 16,
      total: 0,
    },
  },
  {
    groupCode: '266',
    genericName: 'AMIODARONE HCL TABLET 200 MG O',
    strength: '200MG',
    unitPrice: 0.371,
    MFRs: {
      TEV: {
        _id: '6201ba26f65f11ad3d79baca',
        DIN: '2239835',
        genericName: 'AMIODARONE HCL TABLET 200 MG O',
        supplierCode: 'TEV',
        brandName: 'AMIODARONE TB 200MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 37.06,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba20f65f11ad3d790feb',
        DIN: '2246194',
        genericName: 'AMIODARONE HCL TABLET 200 MG O',
        supplierCode: 'APX',
        brandName: 'AMIODARONE TB 200MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 37.06,
        dailyAverageDispensed: 15,
      },
      PMS: {
        _id: '6201ba28f65f11ad3d79df20',
        DIN: '2242472',
        genericName: 'AMIODARONE HCL TABLET 200 MG O',
        supplierCode: 'PMS',
        brandName: 'AMIODARONE TB 200MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '45',
        packPrice: 37.06,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba26f65f11ad3d79b49b',
        DIN: '2243836',
        genericName: 'AMIODARONE HCL TABLET 200 MG O',
        supplierCode: 'SDZ',
        brandName: 'AMIODARONE TB 200MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 37.06,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78fa71',
        DIN: '2364336',
        genericName: 'AMIODARONE HCL TABLET 200 MG O',
        supplierCode: 'SNS',
        brandName: 'AMIODARONE TB 200MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 37.06,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 15,
      total: 0,
    },
  },
  {
    groupCode: '15939',
    genericName: 'RAMIPRIL CAPSULE 1.25 MG ORAL',
    strength: '1.25MG',
    unitPrice: 0.868,
    MFRs: {
      RAN: {
        _id: '6201ba21f65f11ad3d792321',
        DIN: '2310503',
        genericName: 'RAMIPRIL CAPSULE 1.25 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'RAMIPRIL CAPS 1.25MG BLST 30 RAN',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '67',
        packPrice: 2.12,
        dailyAverageDispensed: 7,
      },
      APX: {
        _id: '6201ba20f65f11ad3d7911c9',
        DIN: '2251515',
        genericName: 'RAMIPRIL CAPSULE 1.25 MG ORAL',
        supplierCode: 'APX',
        brandName: 'RAMIPRIL CAPS 1.25MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '55',
        packPrice: 7.08,
        dailyAverageDispensed: 8,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d7961cd',
        DIN: '2469057',
        genericName: 'RAMIPRIL CAPSULE 1.25 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'RAMIPRIL CAPS 1.25MG BLST 3X10 PHARMA',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '50',
        packPrice: 2.12,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d792fae',
        DIN: '2331101',
        genericName: 'RAMIPRIL CAPSULE 1.25 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'RAMIPRIL CAPS 1.25MG BLST 30 JAMP',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '40',
        packPrice: 2.12,
        dailyAverageDispensed: 0,
      },
      BAU: {
        _id: '6201ba26f65f11ad3d79be89',
        DIN: '2221829',
        genericName: 'RAMIPRIL CAPSULE 1.25 MG ORAL',
        supplierCode: 'BAU',
        brandName: 'ALTACE CAPS 1.25MG 30',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 26.05,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790970',
        DIN: '2387387',
        genericName: 'RAMIPRIL CAPSULE 1.25 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'RAMIPRIL CAPS 1.25MG BLST 30 AURO',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 2.12,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 15,
      total: 0,
    },
  },
  {
    groupCode: '17872',
    genericName: 'LAMOTRIGINE TABLET 25 MG ORAL',
    strength: '25MG',
    unitPrice: 0.458,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79d10a',
        DIN: '2245208',
        genericName: 'LAMOTRIGINE TABLET 25 MG ORAL',
        supplierCode: 'APX',
        brandName: 'LAMOTRIGINE TB 25MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '52',
        packPrice: 6.98,
        dailyAverageDispensed: 15,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79c9d2',
        DIN: '2246897',
        genericName: 'LAMOTRIGINE TABLET 25 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'LAMOTRIGINE TB 25MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 6.98,
        dailyAverageDispensed: 0,
      },
      GSK: {
        _id: '6201ba27f65f11ad3d79cd36',
        DIN: '2142082',
        genericName: 'LAMOTRIGINE TABLET 25 MG ORAL',
        supplierCode: 'GSK',
        brandName: 'LAMICTAL TB 25MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 45.84,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba27f65f11ad3d79d714',
        DIN: '2265494',
        genericName: 'LAMOTRIGINE TABLET 25 MG ORAL',
        supplierCode: 'MYL',
        brandName: 'LAMOTRIGINE TB 25MG 100 MYLAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 6.98,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba27f65f11ad3d79d767',
        DIN: '2343010',
        genericName: 'LAMOTRIGINE TABLET 25 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'LAMOTRIGINE TB 25MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 6.98,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba1ff65f11ad3d7905ac',
        DIN: '2381354',
        genericName: 'LAMOTRIGINE TABLET 25 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'LAMOTRIGINE TB 25MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 6.98,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 15,
      total: 0,
    },
  },
  {
    groupCode: '34468',
    genericName: 'IRBESARTAN TABLET 150 MG ORAL',
    strength: '150MG',
    unitPrice: 1.337,
    MFRs: {
      RAN: {
        _id: '6201ba20f65f11ad3d7912f4',
        DIN: '2406829',
        genericName: 'IRBESARTAN TABLET 150 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'IRBESARTAN TB 150MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 22.81,
        dailyAverageDispensed: 12,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d78f6b6',
        DIN: '2317079',
        genericName: 'IRBESARTAN TABLET 150 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'IRBESARTAN TB 150MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 22.81,
        dailyAverageDispensed: 3,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d78f6a2',
        DIN: '2328488',
        genericName: 'IRBESARTAN TABLET 150 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'IRBESARTAN TB 150MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d78f716',
        DIN: '2316404',
        genericName: 'IRBESARTAN TABLET 150 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'IRBESARTAN TB 150MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba21f65f11ad3d792725',
        DIN: '2422999',
        genericName: 'IRBESARTAN TABLET 150 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'IRBESARTAN TB 150MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      SAC: {
        _id: '6201ba20f65f11ad3d790e19',
        DIN: '2237924',
        genericName: 'IRBESARTAN TABLET 150 MG ORAL',
        supplierCode: 'SAC',
        brandName: 'AVAPRO TB 150MG 90',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 120.32,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d792e41',
        DIN: '2372371',
        genericName: 'IRBESARTAN TABLET 150 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'IRBESARTAN TB 150MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d7911f9',
        DIN: '2406101',
        genericName: 'IRBESARTAN TABLET 150 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'IRBESARTAN TB 150MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 15,
      total: 0,
    },
  },
  {
    groupCode: '57893',
    genericName: 'DULOXETINE HCL CAPSULE DR 60 M',
    strength: '60MG',
    unitPrice: 4.625,
    MFRs: {
      MPH: {
        _id: '6201ba21f65f11ad3d7932ba',
        DIN: '2438992',
        genericName: 'DULOXETINE HCL CAPSULE DR 60 M',
        supplierCode: 'MPH',
        brandName: 'DULOXETINE CAPS 60MG 100 MINT',
        form: 'CAPS DEL REL',
        packSize: '100',
        rebateValue: '70',
        packPrice: 97.69,
        dailyAverageDispensed: 8,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d793867',
        DIN: '2451921',
        genericName: 'DULOXETINE HCL CAPSULE DR 60 M',
        supplierCode: 'JAP',
        brandName: 'DULOXETINE CAPS 60MG BLST 30 JAMP',
        form: 'CAPS DEL REL',
        packSize: '30',
        rebateValue: '70',
        packPrice: 29.31,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba25f65f11ad3d798c8d',
        DIN: '2456761',
        genericName: 'DULOXETINE HCL CAPSULE DR 60 M',
        supplierCode: 'TEV',
        brandName: 'DULOXETINE CAPS 60MG BLST 30 TEVA',
        form: 'CAPS DEL REL',
        packSize: '30',
        rebateValue: '70',
        packPrice: 29.31,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba21f65f11ad3d793284',
        DIN: '2429454',
        genericName: 'DULOXETINE HCL CAPSULE DR 60 M',
        supplierCode: 'PMS',
        brandName: 'DULOXETINE CAPS 60MG BLST 30 PMS',
        form: 'CAPS DEL REL',
        packSize: '30',
        rebateValue: '65',
        packPrice: 29.31,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba21f65f11ad3d7932a6',
        DIN: '2440431',
        genericName: 'DULOXETINE HCL CAPSULE DR 60 M',
        supplierCode: 'APX',
        brandName: 'DULOXETINE CAPS 60MG BLST 30 APO',
        form: 'CAPS DEL REL',
        packSize: '30',
        rebateValue: '65',
        packPrice: 29.31,
        dailyAverageDispensed: 7,
      },
      SDZ: {
        _id: '6201ba21f65f11ad3d793263',
        DIN: '2439956',
        genericName: 'DULOXETINE HCL CAPSULE DR 60 M',
        supplierCode: 'SDZ',
        brandName: 'DULOXETINE CAPS 60MG BLST 30 SDZ',
        form: 'CAPS DEL REL',
        packSize: '30',
        rebateValue: '61',
        packPrice: 29.31,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba21f65f11ad3d7932f0',
        DIN: '2446103',
        genericName: 'DULOXETINE HCL CAPSULE DR 60 M',
        supplierCode: 'MRC',
        brandName: 'DULOXETINE CAPS 60MG 100 MAR',
        form: 'CAPS DEL REL',
        packSize: '100',
        rebateValue: '60',
        packPrice: 97.69,
        dailyAverageDispensed: 0,
      },
      LIL: {
        _id: '6201ba27f65f11ad3d79d05c',
        DIN: '2301490',
        genericName: 'DULOXETINE HCL CAPSULE DR 60 M',
        supplierCode: 'LIL',
        brandName: 'CYMBALTA CAPS 60MG 28',
        form: 'CAPS DEL REL',
        packSize: '28',
        rebateValue: null,
        packPrice: 129.49,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d793498',
        DIN: '2436655',
        genericName: 'DULOXETINE HCL CAPSULE DR 60 M',
        supplierCode: 'AUP',
        brandName: 'DULOXETINE CAPS 60MG 30 AURO',
        form: 'CAPS DEL REL',
        packSize: '30',
        rebateValue: null,
        packPrice: 29.31,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba24f65f11ad3d797b26',
        DIN: '2490897',
        genericName: 'DULOXETINE HCL CAPSULE DR 60 M',
        supplierCode: 'SNS',
        brandName: 'DULOXETINE HCL CAPS 60MG 100 SNS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 97.69,
        dailyAverageDispensed: 0,
      },
      RIV: {
        _id: '6201ba26f65f11ad3d79b244',
        DIN: '2495090',
        genericName: 'DULOXETINE HCL CAPSULE DR 60 M',
        supplierCode: 'RIV',
        brandName: 'DULOXETINE CAPS 60MG BLST 30',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 29.31,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 15,
      total: 0,
    },
  },
  {
    groupCode: '57799',
    genericName: 'PREGABALIN CAPSULE 25 MG ORAL',
    strength: '25MG',
    unitPrice: 0.922,
    MFRs: {
      RAN: {
        _id: '6201ba20f65f11ad3d790bbc',
        DIN: '2392801',
        genericName: 'PREGABALIN CAPSULE 25 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'PREGABALIN CAPS 25MG 500 RAN',
        form: 'CAPSULE',
        packSize: '500',
        rebateValue: '72',
        packPrice: 74.05,
        dailyAverageDispensed: 14,
      },
      PMS: {
        _id: '6201ba20f65f11ad3d7909ce',
        DIN: '2359596',
        genericName: 'PREGABALIN CAPSULE 25 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'PREGABALIN CAPS 25MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '70',
        packPrice: 14.81,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d7909db',
        DIN: '2361159',
        genericName: 'PREGABALIN CAPSULE 25 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'PREGABALIN CAPS 25MG 60 TEVA',
        form: 'CAPSULE',
        packSize: '60',
        rebateValue: '70',
        packPrice: 8.89,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d792fbc',
        DIN: '2435977',
        genericName: 'PREGABALIN CAPSULE 25 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'PREGABLIN CAPS 25MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '70',
        packPrice: 14.81,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba20f65f11ad3d790a8d',
        DIN: '2390817',
        genericName: 'PREGABALIN CAPSULE 25 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'PREGABALIN CAPS 25MG 100 SDZ',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 14.81,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba24f65f11ad3d7988f9',
        DIN: '2394235',
        genericName: 'PREGABALIN CAPSULE 25 MG ORAL',
        supplierCode: 'APX',
        brandName: 'PREGABALIN CAPS 25MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 14.81,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba26f65f11ad3d79a6ba',
        DIN: '2449838',
        genericName: 'PREGABALIN CAPSULE 25 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'PREGABALIN CAPS 25MG 100 ACH',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 14.81,
        dailyAverageDispensed: 0,
      },
      NRI: {
        _id: '6201ba25f65f11ad3d79928c',
        DIN: '2479117',
        genericName: 'PREGABALIN CAPSULE 25 MG ORAL',
        supplierCode: 'NRI',
        brandName: 'PREGABALIN CAPS 25MG 100 NRA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '60',
        packPrice: 14.81,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d791dca',
        DIN: '2423804',
        genericName: 'PREGABALIN CAPSULE 25 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'PREGABALIN CAPS 25MG 100 MINT',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '40',
        packPrice: 14.81,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba27f65f11ad3d79d9a4',
        DIN: '2268418',
        genericName: 'PREGABALIN CAPSULE 25 MG ORAL',
        supplierCode: 'UPJ',
        brandName: 'LYRICA CAPS 25MG 60',
        form: 'CAPSULE',
        packSize: '60',
        rebateValue: null,
        packPrice: 55.29,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d793359',
        DIN: '2405539',
        genericName: 'PREGABALIN CAPSULE 25 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'PREGABALIN CAPS 25MG 100 SNS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 14.81,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d7927c3',
        DIN: '2433869',
        genericName: 'PREGABALIN CAPSULE 25 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'PREGABALIN CAPS 25MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 14.81,
        dailyAverageDispensed: 0,
      },
      NTC: {
        _id: '6201ba24f65f11ad3d79899a',
        DIN: '2494841',
        genericName: 'PREGABALIN CAPSULE 25 MG ORAL',
        supplierCode: 'NTC',
        brandName: 'PREGABALIN CAPS 25MG 100 NAT',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 14.81,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 14,
      total: 0,
    },
  },
  {
    groupCode: '265',
    genericName: 'FLECAINIDE ACETATE TABLET 50 M',
    strength: '50MG',
    unitPrice: 0.139,
    MFRs: {
      APX: {
        _id: '6201ba22f65f11ad3d795316',
        DIN: '2275538',
        genericName: 'FLECAINIDE ACETATE TABLET 50 M',
        supplierCode: 'APX',
        brandName: 'FLECAINIDE TB 50MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '30',
        packPrice: 13.89,
        dailyAverageDispensed: 13,
      },
      JAP: {
        _id: '6201ba25f65f11ad3d7996f1',
        DIN: '2493705',
        genericName: 'FLECAINIDE ACETATE TABLET 50 M',
        supplierCode: 'JAP',
        brandName: 'FLECAINIDE TB 50MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '30',
        packPrice: 13.89,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d794cc4',
        DIN: '2459957',
        genericName: 'FLECAINIDE ACETATE TABLET 50 M',
        supplierCode: 'AUP',
        brandName: 'FLECAINIDE TB 50MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 13.89,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 13,
      total: 0,
    },
  },
  {
    groupCode: '1775',
    genericName: 'GLYBURIDE TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 0.057,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79c3ef',
        DIN: '1913662',
        genericName: 'GLYBURIDE TABLET 5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'GLYBURIDE TB 5MG 500 APO',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '20',
        packPrice: 28.7,
        dailyAverageDispensed: 13,
      },
      TEV: {
        _id: '6201ba28f65f11ad3d79db28',
        DIN: '1913689',
        genericName: 'GLYBURIDE TABLET 5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'GLYBURIDE TB 5MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 5.74,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f4c7',
        DIN: '2350467',
        genericName: 'GLYBURIDE TABLET 5 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'GLYBURIDE TB 5MG 500 SNS',
        form: 'TABLET',
        packSize: '500',
        rebateValue: null,
        packPrice: 28.7,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 13,
      total: 0,
    },
  },
  {
    groupCode: '4679',
    genericName: 'BACLOFEN TABLET 10 MG ORAL',
    strength: '10MG',
    unitPrice: 0.16,
    MFRs: {
      PMS: {
        _id: '6201ba21f65f11ad3d79284f',
        DIN: '2063735',
        genericName: 'BACLOFEN TABLET 10 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'BACLOFEN TB 10MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 15.95,
        dailyAverageDispensed: 7,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79d270',
        DIN: '2139332',
        genericName: 'BACLOFEN TABLET 10 MG ORAL',
        supplierCode: 'APX',
        brandName: 'BACLOFEN TB 10MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 15.95,
        dailyAverageDispensed: 6,
      },
      MYL: {
        _id: '6201ba27f65f11ad3d79d533',
        DIN: '2088398',
        genericName: 'BACLOFEN TABLET 10 MG ORAL',
        supplierCode: 'MYL',
        brandName: 'BACLOFEN TB 10MG 100 MYLAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 15.95,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 13,
      total: 0,
    },
  },
  {
    groupCode: '27546',
    genericName: 'TAMSULOSIN HCL CAP ER 24H 0.4',
    strength: '0.4MG',
    unitPrice: 0.15,
    MFRs: {
      SDZ: {
        _id: '6201ba20f65f11ad3d791954',
        DIN: '2319217',
        genericName: 'TAMSULOSIN HCL CAP ER 24H 0.4',
        supplierCode: 'SDZ',
        brandName: 'TAMSULOSIN SR CAPS 0.4MG 100 SDZ',
        form: 'CAPS SUST REL',
        packSize: '100',
        rebateValue: '30',
        packPrice: 15,
        dailyAverageDispensed: 13,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 13,
      total: 0,
    },
  },
  {
    groupCode: '29837',
    genericName: 'TOPIRAMATE TABLET 25 MG ORAL',
    strength: '25MG',
    unitPrice: 1.847,
    MFRs: {
      TEV: {
        _id: '6201ba28f65f11ad3d79db7c',
        DIN: '2248860',
        genericName: 'TOPIRAMATE TABLET 25 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'TOPIRAMATE TB 25MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 24.33,
        dailyAverageDispensed: 6,
      },
      PMS: {
        _id: '6201ba28f65f11ad3d79db6c',
        DIN: '2262991',
        genericName: 'TOPIRAMATE TABLET 25 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'TOPIRAMATE TB 25MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '68',
        packPrice: 24.33,
        dailyAverageDispensed: 1,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79b8ce',
        DIN: '2279614',
        genericName: 'TOPIRAMATE TABLET 25 MG ORAL',
        supplierCode: 'APX',
        brandName: 'TOPIRAMATE TB 25MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 24.33,
        dailyAverageDispensed: 6,
      },
      MPH: {
        _id: '6201ba27f65f11ad3d79d8d1',
        DIN: '2315645',
        genericName: 'TOPIRAMATE TABLET 25 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'TOPIRAMATE TB 25MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 24.33,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba21f65f11ad3d7926e4',
        DIN: '2431807',
        genericName: 'TOPIRAMATE TABLET 25 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'TOPIRAMATE TB 25MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 24.33,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba22f65f11ad3d793f67',
        DIN: '2435608',
        genericName: 'TOPIRAMATE TABLET 25 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'TOPIRAMATE TB 25MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 24.33,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba20f65f11ad3d79093a',
        DIN: '2395738',
        genericName: 'TOPIRAMATE TABLET 25 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'TOPIRAMATE TB 25MG 100 ACH',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 24.33,
        dailyAverageDispensed: 0,
      },
      JNO: {
        _id: '6201ba26f65f11ad3d79ba60',
        DIN: '2230893',
        genericName: 'TOPIRAMATE TABLET 25 MG ORAL',
        supplierCode: 'JNO',
        brandName: 'TOPAMAX TB 25MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 184.68,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba28f65f11ad3d79db27',
        DIN: '2263351',
        genericName: 'TOPIRAMATE TABLET 25 MG ORAL',
        supplierCode: 'MYL',
        brandName: 'TOPIRAMATE TB 25MG 100 MYLAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 24.33,
        dailyAverageDispensed: 0,
      },
      GLN: {
        _id: '6201ba26f65f11ad3d79ae57',
        DIN: '2287765',
        genericName: 'TOPIRAMATE TABLET 25 MG ORAL',
        supplierCode: 'GLN',
        brandName: 'TOPIRAMATE TB 25MG 100 GLN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 24.33,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d791cf3',
        DIN: '2345803',
        genericName: 'TOPIRAMATE TABLET 25 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'TOPIRAMATE TB 25MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 24.33,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78fa11',
        DIN: '2356856',
        genericName: 'TOPIRAMATE TABLET 25 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'TOPIRAMATE TB 25MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 24.33,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 13,
      total: 0,
    },
  },
  {
    groupCode: '46043',
    genericName: 'AMITRIPTYLINE HCL TABLET 10 MG',
    strength: '10MG',
    unitPrice: 0.044,
    MFRs: {
      APX: {
        _id: '6201ba20f65f11ad3d790e12',
        DIN: '2403137',
        genericName: 'AMITRIPTYLINE HCL TABLET 10 MG',
        supplierCode: 'APX',
        brandName: 'AMITRIPTYLINE TB 10MG 1000 APO',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '40',
        packPrice: 43.5,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba21f65f11ad3d792158',
        DIN: '2429861',
        genericName: 'AMITRIPTYLINE HCL TABLET 10 MG',
        supplierCode: 'MRC',
        brandName: 'AMITRIPTYLINE TB 10MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 4.35,
        dailyAverageDispensed: 13,
      },
      PMS: {
        _id: '6201ba20f65f11ad3d7910ab',
        DIN: '654523',
        genericName: 'AMITRIPTYLINE HCL TABLET 10 MG',
        supplierCode: 'PMS',
        brandName: 'AMITRIPTYLINE TB 10MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 4.35,
        dailyAverageDispensed: 0,
      },
      AAA: {
        _id: '6201ba28f65f11ad3d79e142',
        DIN: '335053',
        genericName: 'AMITRIPTYLINE HCL TABLET 10 MG',
        supplierCode: 'AAA',
        brandName: 'ELAVIL FCT TB 10MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 6.64,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 13,
      total: 0,
    },
  },
  {
    groupCode: '346',
    genericName: 'CLONIDINE HCL TABLET 0.1 MG OR',
    strength: '0.1MG',
    unitPrice: 0.136,
    MFRs: {
      TEV: {
        _id: '6201ba26f65f11ad3d79bc1a',
        DIN: '2046121',
        genericName: 'CLONIDINE HCL TABLET 0.1 MG OR',
        supplierCode: 'TEV',
        brandName: 'CLONIDINE TB 0.1MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 13.58,
        dailyAverageDispensed: 2,
      },
      MPH: {
        _id: '6201ba22f65f11ad3d7948a1',
        DIN: '2462192',
        genericName: 'CLONIDINE HCL TABLET 0.1 MG OR',
        supplierCode: 'MPH',
        brandName: 'CLONIDINE TB 0.1MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 13.58,
        dailyAverageDispensed: 10,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 12,
      total: 0,
    },
  },
  {
    groupCode: '2536',
    genericName: 'ALLOPURINOL TABLET 300 MG ORAL',
    strength: '300MG',
    unitPrice: 0.213,
    MFRs: {
      MRC: {
        _id: '6201ba20f65f11ad3d790af1',
        DIN: '2396343',
        genericName: 'ALLOPURINOL TABLET 300 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'ALLOPURINOL TB 300MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 21.25,
        dailyAverageDispensed: 12,
      },
      APX: {
        _id: '6201ba20f65f11ad3d790c86',
        DIN: '2402785',
        genericName: 'ALLOPURINOL TABLET 300 MG ORAL',
        supplierCode: 'APX',
        brandName: 'ALLOPURINOL TB 300MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 21.25,
        dailyAverageDispensed: 0,
      },
      AAA: {
        _id: '6201ba1ff65f11ad3d78f463',
        DIN: '402796',
        genericName: 'ALLOPURINOL TABLET 300 MG ORAL',
        supplierCode: 'AAA',
        brandName: 'ZYLOPRIM TB 300MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 21.25,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 12,
      total: 0,
    },
  },
  {
    groupCode: '4222',
    genericName: 'OXYCODONE HCL/ACETAMINOPHEN TA',
    strength: '5MG/325MG',
    unitPrice: 0.129,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79d2ce',
        DIN: '2324628',
        genericName: 'OXYCODONE HCL/ACETAMINOPHEN TA',
        supplierCode: 'APX',
        brandName: 'OXYCODONE-ACETAMINOPHEN TB 5MG/325MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '51',
        packPrice: 12.85,
        dailyAverageDispensed: 4,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d7901f9',
        DIN: '2307898',
        genericName: 'OXYCODONE HCL/ACETAMINOPHEN TA',
        supplierCode: 'SDZ',
        brandName: 'OXYCODONE-ACETAMINOPHEN TB 5MG/325MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 12.85,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba22f65f11ad3d7943d3',
        DIN: '608165',
        genericName: 'OXYCODONE HCL/ACETAMINOPHEN TA',
        supplierCode: 'TEV',
        brandName: 'OXYCOCET TB 5MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 12.85,
        dailyAverageDispensed: 8,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 12,
      total: 0,
    },
  },
  {
    groupCode: '19293',
    genericName: 'CARVEDILOL TABLET 25 MG ORAL',
    strength: '25MG',
    unitPrice: 0.243,
    MFRs: {
      PMS: {
        _id: '6201ba27f65f11ad3d79c91b',
        DIN: '2245917',
        genericName: 'CARVEDILOL TABLET 25 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'CARVEDILOL TB 25MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 24.31,
        dailyAverageDispensed: 12,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79caf0',
        DIN: '2247936',
        genericName: 'CARVEDILOL TABLET 25 MG ORAL',
        supplierCode: 'APX',
        brandName: 'CARVEDILOL TB 25MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '54',
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79cfa6',
        DIN: '2252333',
        genericName: 'CARVEDILOL TABLET 25 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'CARVEDILOL TB 25MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '15',
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d791684',
        DIN: '2364956',
        genericName: 'CARVEDILOL TABLET 25 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'CARVEDILOL TB 25MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d790044',
        DIN: '2368927',
        genericName: 'CARVEDILOL TABLET 25 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'CARVEDILOL TB 25MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '0',
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d791b2a',
        DIN: '2418525',
        genericName: 'CARVEDILOL TABLET 25 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'CARVEDILOL TB 25MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 12,
      total: 0,
    },
  },
  {
    groupCode: '46403',
    genericName: 'VENLAFAXINE HCL CAP ER 24H 37.',
    strength: '37.5MG',
    unitPrice: 1.047,
    MFRs: {
      RAN: {
        _id: '6201ba1ff65f11ad3d790178',
        DIN: '2380072',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 37.',
        supplierCode: 'RAN',
        brandName: 'VENLAFAXINE XR CAPS 37.5MG 100 RAN',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '52',
        packPrice: 9.13,
        dailyAverageDispensed: 3,
      },
      APX: {
        _id: '6201ba20f65f11ad3d790c1a',
        DIN: '2331683',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 37.',
        supplierCode: 'APX',
        brandName: 'VENLAFAXINE XR CAPS 37.5MG 100 APO',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '45',
        packPrice: 9.13,
        dailyAverageDispensed: 9,
      },
      SDZ: {
        _id: '6201ba26f65f11ad3d79a550',
        DIN: '2310317',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 37.',
        supplierCode: 'SDZ',
        brandName: 'VENLAFAXINE XR CAPS EXTENDED RELEAS 37.5MG 100 SDZ',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '40',
        packPrice: 9.13,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79cccc',
        DIN: '2278545',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 37.',
        supplierCode: 'PMS',
        brandName: 'VENLAFAXINE XR CAPS 37.5MG 100 PMS',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 9.13,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d790d1a',
        DIN: '2304317',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 37.',
        supplierCode: 'TEV',
        brandName: 'VENLAFAXINE XR CAPS 37.5MG 100 ACT',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 9.13,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba26f65f11ad3d79b3cb',
        DIN: '2516535',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 37.',
        supplierCode: 'JAP',
        brandName: 'VENLAFAXINE XR CAPS 37.5MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 9.13,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba27f65f11ad3d79c3ed',
        DIN: '2237279',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 37.',
        supplierCode: 'UPJ',
        brandName: 'EFFEXOR XR CAPS 37.5MG 90',
        form: 'CAPSULE EXT REL',
        packSize: '90',
        rebateValue: null,
        packPrice: 94.26,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba25f65f11ad3d799b06',
        DIN: '2354713',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 37.',
        supplierCode: 'SNS',
        brandName: 'VENLAFAXINE XR CAPS 37.5MG 100 SNS',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 9.13,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d793a5e',
        DIN: '2452839',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 37.',
        supplierCode: 'AUP',
        brandName: 'VENLAFAXINE XR CAPS 37.5MG 100 AURO',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 9.13,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 12,
      total: 0,
    },
  },
  {
    groupCode: '465',
    genericName: 'NITROGLYCERIN PATCH TD24 0.4MG',
    strength: '0.4MG/HR',
    unitPrice: 0.504,
    MFRs: {
      DRD: {
        _id: '6201ba23f65f11ad3d795fdb',
        DIN: '1911902',
        genericName: 'NITROGLYCERIN PATCH TD24 0.4MG',
        supplierCode: 'DRD',
        brandName: 'NITRO DUR 0.4MG 30',
        form: 'PATCH MED.',
        packSize: '30',
        rebateValue: null,
        packPrice: 15.12,
        dailyAverageDispensed: 0,
      },
      BAU: {
        _id: '6201ba27f65f11ad3d79ce7a',
        DIN: '2163527',
        genericName: 'NITROGLYCERIN PATCH TD24 0.4MG',
        supplierCode: 'BAU',
        brandName: 'MINITRAN TRANSDERMAL SYSTEM 0.4MG/HR 30',
        form: 'PATCH MED.',
        packSize: '30',
        rebateValue: null,
        packPrice: 24.73,
        dailyAverageDispensed: 0,
      },
      PLI: {
        _id: '6201ba23f65f11ad3d796611',
        DIN: '2230733',
        genericName: 'NITROGLYCERIN PATCH TD24 0.4MG',
        supplierCode: 'PLI',
        brandName: 'TRINIPATCH 0.4MG 30',
        form: 'PATCH MED.',
        packSize: '30',
        rebateValue: null,
        packPrice: 24.43,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba20f65f11ad3d7911be',
        DIN: '2407450',
        genericName: 'NITROGLYCERIN PATCH TD24 0.4MG',
        supplierCode: 'MYL',
        brandName: 'NITRO PATCH 0.4MG/HR30 MYLAN',
        form: 'PATCH MED.',
        packSize: '30',
        rebateValue: null,
        packPrice: 15.12,
        dailyAverageDispensed: 11,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 11,
      total: 0,
    },
  },
  {
    groupCode: '46405',
    genericName: 'VENLAFAXINE HCL CAP ER 24H 150',
    strength: '150MG',
    unitPrice: 2.211,
    MFRs: {
      RAN: {
        _id: '6201ba1ff65f11ad3d79017c',
        DIN: '2380099',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 150',
        supplierCode: 'RAN',
        brandName: 'VENLAFAXINE XR CAPS 150MG 100 RAN',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '52',
        packPrice: 19.27,
        dailyAverageDispensed: 1,
      },
      APX: {
        _id: '6201ba20f65f11ad3d790c1e',
        DIN: '2331705',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 150',
        supplierCode: 'APX',
        brandName: 'VENLAFAXINE XR CAPS 150MG 100 APO',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '45',
        packPrice: 19.27,
        dailyAverageDispensed: 10,
      },
      SDZ: {
        _id: '6201ba25f65f11ad3d799e92',
        DIN: '2310333',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 150',
        supplierCode: 'SDZ',
        brandName: 'VENLAFAXINE XR CAPS 150MG 100 SDZ',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '40',
        packPrice: 19.27,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79cccf',
        DIN: '2278561',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 150',
        supplierCode: 'PMS',
        brandName: 'VENLAFAXINE XR CAPS 150MG 100 PMS',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 19.27,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d790e79',
        DIN: '2304333',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 150',
        supplierCode: 'TEV',
        brandName: 'VENLAFAXINE XR CAPS 150MG 100 ACT',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 19.27,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba26f65f11ad3d79b3d0',
        DIN: '2516551',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 150',
        supplierCode: 'JAP',
        brandName: 'VENLAFAXINE XR CAPS 150MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 19.27,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba28f65f11ad3d79dd23',
        DIN: '2237282',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 150',
        supplierCode: 'UPJ',
        brandName: 'EFFEXOR XR CAPS 150MG 90',
        form: 'CAPSULE EXT REL',
        packSize: '90',
        rebateValue: null,
        packPrice: 199.03,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba25f65f11ad3d799b07',
        DIN: '2354748',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 150',
        supplierCode: 'SNS',
        brandName: 'VENLAFAXINE XR CAPS 150MG 100 SNS',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 19.27,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d793a5c',
        DIN: '2452855',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 150',
        supplierCode: 'AUP',
        brandName: 'VENLAFAXINE XR CAPS 150MG 100 AURO',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 19.27,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 11,
      total: 0,
    },
  },
  {
    groupCode: '51036',
    genericName: 'EPLERENONE TABLET 25 MG ORAL',
    strength: '25MG',
    unitPrice: 3.026,
    MFRs: {
      MPH: {
        _id: '6201ba23f65f11ad3d795a0e',
        DIN: '2471442',
        genericName: 'EPLERENONE TABLET 25 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'EPLERENONE TB 25MG 90 MINT',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '10',
        packPrice: 185.36,
        dailyAverageDispensed: 11,
      },
      UPJ: {
        _id: '6201ba26f65f11ad3d79bb16',
        DIN: '2323052',
        genericName: 'EPLERENONE TABLET 25 MG ORAL',
        supplierCode: 'UPJ',
        brandName: 'INSPRA TB 25MG UD 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 90.79,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 11,
      total: 0,
    },
  },
  {
    groupCode: '51037',
    genericName: 'EPLERENONE TABLET 50 MG ORAL',
    strength: '50MG',
    unitPrice: 3.026,
    MFRs: {
      MPH: {
        _id: '6201ba23f65f11ad3d795a0c',
        DIN: '2471450',
        genericName: 'EPLERENONE TABLET 50 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'EPLERENONE TB 50MG 90 MINT',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '10',
        packPrice: 185.36,
        dailyAverageDispensed: 11,
      },
      UPJ: {
        _id: '6201ba26f65f11ad3d79bb35',
        DIN: '2323060',
        genericName: 'EPLERENONE TABLET 50 MG ORAL',
        supplierCode: 'UPJ',
        brandName: 'INSPRA TB 50MG UD 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 90.79,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 11,
      total: 0,
    },
  },
  {
    groupCode: '11922',
    genericName: 'BETAMETHASONE VALERATE CREAM (',
    strength: '0.05%',
    unitPrice: 0.06,
    MFRs: {
      TAR: {
        _id: '6201ba26f65f11ad3d79bce2',
        DIN: '716618',
        genericName: 'BETAMETHASONE VALERATE CREAM (',
        supplierCode: 'TAR',
        brandName: 'BETADERM CR 0.05% 454G',
        form: 'CREAM',
        packSize: '454',
        rebateValue: '52',
        packPrice: 27.06,
        dailyAverageDispensed: 10,
      },
      TEV: {
        _id: '6201ba22f65f11ad3d79422b',
        DIN: '535427',
        genericName: 'BETAMETHASONE VALERATE CREAM (',
        supplierCode: 'TEV',
        brandName: 'ECTOSONE TOPICAL CR 0.05% 450G RATIO',
        form: 'CREAM',
        packSize: '450',
        rebateValue: '1',
        packPrice: 26.82,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 10,
      total: 0,
    },
  },
  {
    groupCode: '46203',
    genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
    strength: '20MG',
    unitPrice: 1.617,
    MFRs: {
      JAP: {
        _id: '6201ba21f65f11ad3d7929d5',
        DIN: '2430541',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'JAP',
        brandName: 'CITALOPRAM TB 20MG BLST 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '77',
        packPrice: 4,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba21f65f11ad3d792768',
        DIN: '2246056',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'APX',
        brandName: 'CITALOPRAM TB 20MG UD 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '68',
        packPrice: 4,
        dailyAverageDispensed: 10,
      },
      PMS: {
        _id: '6201ba21f65f11ad3d7939f3',
        DIN: '2248010',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'PMS',
        brandName: 'CITALOPRAM TB 20MG UD 3X10 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 4,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba26f65f11ad3d79bb25',
        DIN: '2293218',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'TEV',
        brandName: 'CITALOPRAM TB 20MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 4,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba1ff65f11ad3d79040c',
        DIN: '2371898',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'MRC',
        brandName: 'CITALOPRAM TB 20MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '53',
        packPrice: 13.32,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba21f65f11ad3d7932d8',
        DIN: '2429705',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'MPH',
        brandName: 'CITALOPRAM TB 20MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 13.32,
        dailyAverageDispensed: 0,
      },
      LUN: {
        _id: '6201ba26f65f11ad3d79b70d',
        DIN: '2239607',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'LUN',
        brandName: 'CELEXA TB 20MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 48.5,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba1ff65f11ad3d78fed5',
        DIN: '2275562',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'AUP',
        brandName: 'CITALOPRAM TB 20MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 4,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d79311c',
        DIN: '2353660',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'SNS',
        brandName: 'CITALOPRAM HBR TB 20MG 500 SNS',
        form: 'TABLET',
        packSize: '500',
        rebateValue: null,
        packPrice: 66.6,
        dailyAverageDispensed: 0,
      },
      NTC: {
        _id: '6201ba21f65f11ad3d7924a3',
        DIN: '2409011',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'NTC',
        brandName: 'CITALOPRAM TB 20MG BLST 3X10 NAT',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 4,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 10,
      total: 0,
    },
  },
  {
    groupCode: '46232',
    genericName: 'SERTRALINE HCL CAPSULE 50 MG O',
    strength: '50MG',
    unitPrice: 1.886,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79c783',
        DIN: '2238281',
        genericName: 'SERTRALINE HCL CAPSULE 50 MG O',
        supplierCode: 'APX',
        brandName: 'SERTRALINE CAPS 50MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '67',
        packPrice: 30.32,
        dailyAverageDispensed: 10,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c6d4',
        DIN: '2240484',
        genericName: 'SERTRALINE HCL CAPSULE 50 MG O',
        supplierCode: 'TEV',
        brandName: 'SERTRALINE CAPS 50MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 30.32,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79cffe',
        DIN: '2244839',
        genericName: 'SERTRALINE HCL CAPSULE 50 MG O',
        supplierCode: 'PMS',
        brandName: 'SERTRALINE CAPS 50MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 30.32,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d7912d9',
        DIN: '2399423',
        genericName: 'SERTRALINE HCL CAPSULE 50 MG O',
        supplierCode: 'MRC',
        brandName: 'SERTRALINE CAPS 50MG 100 MAR',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '61',
        packPrice: 30.32,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d7911b0',
        DIN: '2402394',
        genericName: 'SERTRALINE HCL CAPSULE 50 MG O',
        supplierCode: 'MPH',
        brandName: 'SERTRALINE CAPS 50MG 100 MINT',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '60',
        packPrice: 30.32,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba22f65f11ad3d795563',
        DIN: '2469634',
        genericName: 'SERTRALINE HCL CAPSULE 50 MG O',
        supplierCode: 'JAP',
        brandName: 'SERTRALINE CAPS 50MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '50',
        packPrice: 30.32,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba23f65f11ad3d7958ec',
        DIN: '1962817',
        genericName: 'SERTRALINE HCL CAPSULE 50 MG O',
        supplierCode: 'UPJ',
        brandName: 'ZOLOFT CAPS 50MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 188.55,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d793395',
        DIN: '2353539',
        genericName: 'SERTRALINE HCL CAPSULE 50 MG O',
        supplierCode: 'SNS',
        brandName: 'SERTRALINE HCL CAPS 50MG 100 SNS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 30.32,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790d46',
        DIN: '2390914',
        genericName: 'SERTRALINE HCL CAPSULE 50 MG O',
        supplierCode: 'AUP',
        brandName: 'SERTRALINE CAPS 50MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 30.32,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 10,
      total: 0,
    },
  },
  {
    groupCode: '49856',
    genericName: 'CETIRIZINE HCL TABLET 20 MG OR',
    strength: '20MG',
    unitPrice: 0.907,
    MFRs: {
      APX: {
        _id: '6201ba21f65f11ad3d793257',
        DIN: '2453363',
        genericName: 'CETIRIZINE HCL TABLET 20 MG OR',
        supplierCode: 'APX',
        brandName: 'CETIRIZINE TB 20MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 75.35,
        dailyAverageDispensed: 5,
      },
      MPH: {
        _id: '6201ba24f65f11ad3d79832f',
        DIN: '2491125',
        genericName: 'CETIRIZINE HCL TABLET 20 MG OR',
        supplierCode: 'MPH',
        brandName: 'CETIRIZINE TB 20MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 75.35,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba22f65f11ad3d794ed7',
        DIN: '2466171',
        genericName: 'CETIRIZINE HCL TABLET 20 MG OR',
        supplierCode: 'JAP',
        brandName: 'CETIRIZINE TB 20MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 75.35,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba21f65f11ad3d7922cb',
        DIN: '2427141',
        genericName: 'CETIRIZINE HCL TABLET 20 MG OR',
        supplierCode: 'MRC',
        brandName: 'CETIRIZINE TB 20MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 75.35,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79d838',
        DIN: '2315963',
        genericName: 'CETIRIZINE HCL TABLET 20 MG OR',
        supplierCode: 'PMS',
        brandName: 'CETIRIZINE TB 20MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 75.35,
        dailyAverageDispensed: 5,
      },
      JJC: {
        _id: '6201ba26f65f11ad3d79bdde',
        DIN: '1900978',
        genericName: 'CETIRIZINE HCL TABLET 20 MG OR',
        supplierCode: 'JJC',
        brandName: 'REACTINE TB 20MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 90.68,
        dailyAverageDispensed: 0,
      },
      NTC: {
        _id: '6201ba25f65f11ad3d7991a6',
        DIN: '2496488',
        genericName: 'CETIRIZINE HCL TABLET 20 MG OR',
        supplierCode: 'NTC',
        brandName: 'CETIRIZINE TB 20MG 100 NAT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 75.35,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79b20b',
        DIN: '2515695',
        genericName: 'CETIRIZINE HCL TABLET 20 MG OR',
        supplierCode: 'SNS',
        brandName: 'CETIRIZINE TB 20MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 75.35,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 10,
      total: 0,
    },
  },
  {
    groupCode: '64847',
    genericName: 'SILODOSIN CAPSULE 8 MG ORAL',
    strength: '8MG',
    unitPrice: 2.001,
    MFRs: {
      PMS: {
        _id: '6201ba26f65f11ad3d79af91',
        DIN: '2517787',
        genericName: 'SILODOSIN CAPSULE 8 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'SILODOSIN CAPS 8MG 90 PMS',
        form: 'CAPSULE',
        packSize: '90',
        rebateValue: '50',
        packPrice: 85.36,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba25f65f11ad3d799c15',
        DIN: '2475448',
        genericName: 'SILODOSIN CAPSULE 8 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'SILODOSIN CAPS 8MG 100 SDZ',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 94.84,
        dailyAverageDispensed: 10,
      },
      ALL: {
        _id: '6201ba1ff65f11ad3d78fe90',
        DIN: '2361671',
        genericName: 'SILODOSIN CAPSULE 8 MG ORAL',
        supplierCode: 'ALL',
        brandName: 'RAPAFLO CAP 8MG 90',
        form: 'CAPSULE',
        packSize: '90',
        rebateValue: null,
        packPrice: 180.1,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 10,
      total: 0,
    },
  },
  {
    groupCode: '6753',
    genericName: 'PREDNISONE TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 0.022,
    MFRs: {
      TEV: {
        _id: '6201ba20f65f11ad3d791918',
        DIN: '21695',
        genericName: 'PREDNISONE TABLET 5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'PREDNISONE TB 5MG 1000 TEVA',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '1',
        packPrice: 22,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79da2e',
        DIN: '312770',
        genericName: 'PREDNISONE TABLET 5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'PREDNISONE TB 5MG 1000 APO',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '1',
        packPrice: 22,
        dailyAverageDispensed: 9,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 9,
      total: 0,
    },
  },
  {
    groupCode: '8214',
    genericName: 'CHLORTHALIDONE TABLET 50 MG OR',
    strength: '50MG',
    unitPrice: 0.15,
    MFRs: {
      AAA: {
        _id: '6201ba1ff65f11ad3d78f74a',
        DIN: '360279',
        genericName: 'CHLORTHALIDONE TABLET 50 MG OR',
        supplierCode: 'AAA',
        brandName: 'CHLORTHALIDONE TB 50MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 14.98,
        dailyAverageDispensed: 9,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 9,
      total: 0,
    },
  },
  {
    groupCode: '9403',
    genericName: 'SULFASALAZINE TABLET DR 500 MG',
    strength: '500MG',
    unitPrice: 0.563,
    MFRs: {
      PMS: {
        _id: '6201ba26f65f11ad3d79b903',
        DIN: '598488',
        genericName: 'SULFASALAZINE TABLET DR 500 MG',
        supplierCode: 'PMS',
        brandName: 'SULFASALAZINE EC TB 500MG 100 PMS',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: '50',
        packPrice: 40.76,
        dailyAverageDispensed: 9,
      },
      PFC: {
        _id: '6201ba24f65f11ad3d7980fb',
        DIN: '2064472',
        genericName: 'SULFASALAZINE TABLET DR 500 MG',
        supplierCode: 'PFC',
        brandName: 'SALAZOPYRIN EN-TABS TB EC 500MG 100',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: null,
        packPrice: 56.25,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 9,
      total: 0,
    },
  },
  {
    groupCode: '11840',
    genericName: 'FOLIC ACID TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 0.02,
    MFRs: {
      SDZ: {
        _id: '6201ba23f65f11ad3d7960b3',
        DIN: '2285673',
        genericName: 'FOLIC ACID TABLET 5 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'FOLIC TB 5MG 1000 SDZ',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '1',
        packPrice: 19.8,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d78fa54',
        DIN: '2366061',
        genericName: 'FOLIC ACID TABLET 5 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'FOLIC ACID TB 5MG 1000 JAMP',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '1',
        packPrice: 38.38,
        dailyAverageDispensed: 0,
      },
      AAA: {
        _id: '6201ba23f65f11ad3d796cde',
        DIN: '426849',
        genericName: 'FOLIC ACID TABLET 5 MG ORAL',
        supplierCode: 'AAA',
        brandName: 'FOLIC ACID TB 5MG 1000',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '1',
        packPrice: 42.63,
        dailyAverageDispensed: 9,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 9,
      total: 0,
    },
  },
  {
    groupCode: '14504',
    genericName: 'CODEINE/ACETAMINOPHEN/CAFFEINE',
    strength: '300/30/15',
    unitPrice: 0.093,
    MFRs: {
      TEV: {
        _id: '6201ba22f65f11ad3d7943e5',
        DIN: '653276',
        genericName: 'CODEINE/ACETAMINOPHEN/CAFFEINE',
        supplierCode: 'TEV',
        brandName: 'LENOLTEC #3 CODEINE TB 300/30/15MG 500 TEVA',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '1',
        packPrice: 46.65,
        dailyAverageDispensed: 9,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 9,
      total: 0,
    },
  },
  {
    groupCode: '17302',
    genericName: 'BETAHISTINE HCL TABLET 16 MG O',
    strength: '16MG',
    unitPrice: 0.529,
    MFRs: {
      TEV: {
        _id: '6201ba21f65f11ad3d7922ea',
        DIN: '2280191',
        genericName: 'BETAHISTINE HCL TABLET 16 MG O',
        supplierCode: 'TEV',
        brandName: 'BETAHISTINE TB 16MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 35.57,
        dailyAverageDispensed: 7,
      },
      PMS: {
        _id: '6201ba20f65f11ad3d791435',
        DIN: '2330210',
        genericName: 'BETAHISTINE HCL TABLET 16 MG O',
        supplierCode: 'PMS',
        brandName: 'BETAHISTINE TB 16MG BLS 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '54',
        packPrice: 35.57,
        dailyAverageDispensed: 2,
      },
      BGP: {
        _id: '6201ba27f65f11ad3d79d3bf',
        DIN: '2243878',
        genericName: 'BETAHISTINE HCL TABLET 16 MG O',
        supplierCode: 'BGP',
        brandName: 'SERC TB 16MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 52.94,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d793468',
        DIN: '2449153',
        genericName: 'BETAHISTINE HCL TABLET 16 MG O',
        supplierCode: 'AUP',
        brandName: 'BETAHISTINE TB 16MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 35.57,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba23f65f11ad3d795950',
        DIN: '2466449',
        genericName: 'BETAHISTINE HCL TABLET 16 MG O',
        supplierCode: 'SNS',
        brandName: 'BETAHISTINE TB 16MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 35.57,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 9,
      total: 0,
    },
  },
  {
    groupCode: '17871',
    genericName: 'LAMOTRIGINE TABLET 100 MG ORAL',
    strength: '100MG',
    unitPrice: 1.831,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79d10d',
        DIN: '2245209',
        genericName: 'LAMOTRIGINE TABLET 100 MG ORAL',
        supplierCode: 'APX',
        brandName: 'LAMOTRIGINE TB 100MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '52',
        packPrice: 27.87,
        dailyAverageDispensed: 9,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79c9d4',
        DIN: '2246898',
        genericName: 'LAMOTRIGINE TABLET 100 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'LAMOTRIGINE TB 100MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 27.87,
        dailyAverageDispensed: 0,
      },
      GSK: {
        _id: '6201ba27f65f11ad3d79cd38',
        DIN: '2142104',
        genericName: 'LAMOTRIGINE TABLET 100 MG ORAL',
        supplierCode: 'GSK',
        brandName: 'LAMICTAL TB 100MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 183.11,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba27f65f11ad3d79d715',
        DIN: '2265508',
        genericName: 'LAMOTRIGINE TABLET 100 MG ORAL',
        supplierCode: 'MYL',
        brandName: 'LAMOTRIGINE TB 100MG 100 MYLAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 27.87,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba27f65f11ad3d79d762',
        DIN: '2343029',
        genericName: 'LAMOTRIGINE TABLET 100 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'LAMOTRIGINE TB 100MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 27.87,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba1ff65f11ad3d7905ab',
        DIN: '2381362',
        genericName: 'LAMOTRIGINE TABLET 100 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'LAMOTRIGINE TB 100MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 27.87,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 9,
      total: 0,
    },
  },
  {
    groupCode: '44632',
    genericName: 'LEVETIRACETAM TABLET 250 MG OR',
    strength: '250MG',
    unitPrice: 1.882,
    MFRs: {
      AHC: {
        _id: '6201ba26f65f11ad3d79a3ce',
        DIN: '2399776',
        genericName: 'LEVETIRACETAM TABLET 250 MG OR',
        supplierCode: 'AHC',
        brandName: 'LEVETIRACETAM TB 250MG 120 ACH',
        form: 'TABLET',
        packSize: '120',
        rebateValue: '70',
        packPrice: 38.52,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79bd92',
        DIN: '2285924',
        genericName: 'LEVETIRACETAM TABLET 250 MG OR',
        supplierCode: 'APX',
        brandName: 'LEVETIRACET TB 250MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '51',
        packPrice: 32.1,
        dailyAverageDispensed: 9,
      },
      SDZ: {
        _id: '6201ba22f65f11ad3d794aa5',
        DIN: '2461986',
        genericName: 'LEVETIRACETAM TABLET 250 MG OR',
        supplierCode: 'SDZ',
        brandName: 'LEVETIRACETAM TB 250MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '48',
        packPrice: 32.1,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba20f65f11ad3d791360',
        DIN: '2403005',
        genericName: 'LEVETIRACETAM TABLET 250 MG OR',
        supplierCode: 'JAP',
        brandName: 'LEVETIRACETAM TB 250MG 120 JAMP',
        form: 'TABLET',
        packSize: '120',
        rebateValue: '40',
        packPrice: 38.52,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba26f65f11ad3d79ae77',
        DIN: '2296101',
        genericName: 'LEVETIRACETAM TABLET 250 MG OR',
        supplierCode: 'PMS',
        brandName: 'LEVETIRACETAM TB 250MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '35',
        packPrice: 32.1,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d7930eb',
        DIN: '2274183',
        genericName: 'LEVETIRACETAM TABLET 250 MG OR',
        supplierCode: 'TEV',
        brandName: 'LEVETIRACETAM TB 250MG 100 ACT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 32.1,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba26f65f11ad3d79a76e',
        DIN: '2442388',
        genericName: 'LEVETIRACETAM TABLET 250 MG OR',
        supplierCode: 'MPH',
        brandName: 'LEVETIRACETAM TB 250MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 32.1,
        dailyAverageDispensed: 0,
      },
      UCB: {
        _id: '6201ba27f65f11ad3d79c237',
        DIN: '2247027',
        genericName: 'LEVETIRACETAM TABLET 250 MG OR',
        supplierCode: 'UCB',
        brandName: 'KEPPRA TB 250MG 120',
        form: 'TABLET',
        packSize: '120',
        rebateValue: null,
        packPrice: 225.81,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f3cc',
        DIN: '2353342',
        genericName: 'LEVETIRACETAM TABLET 250 MG OR',
        supplierCode: 'SNS',
        brandName: 'LEVETIRACETAM TB 250MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 32.1,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba1ff65f11ad3d790238',
        DIN: '2375249',
        genericName: 'LEVETIRACETAM TABLET 250 MG OR',
        supplierCode: 'AUP',
        brandName: 'LEVETIRACETAM TB 250MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 32.1,
        dailyAverageDispensed: 0,
      },
      NTC: {
        _id: '6201ba21f65f11ad3d792a47',
        DIN: '2440202',
        genericName: 'LEVETIRACETAM TABLET 250 MG OR',
        supplierCode: 'NTC',
        brandName: 'LEVETIRACETAM TB 250MG 120 NAT',
        form: 'TABLET',
        packSize: '120',
        rebateValue: null,
        packPrice: 38.52,
        dailyAverageDispensed: 0,
      },
      RIV: {
        _id: '6201ba24f65f11ad3d797fa6',
        DIN: '2482274',
        genericName: 'LEVETIRACETAM TABLET 250 MG OR',
        supplierCode: 'RIV',
        brandName: 'LEVETIRACETAM TB 250MG 100 RIVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 32.1,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 9,
      total: 0,
    },
  },
  {
    groupCode: '46214',
    genericName: 'FLUOXETINE HCL CAPSULE 20 MG O',
    strength: '20MG',
    unitPrice: 0.331,
    MFRs: {
      TEV: {
        _id: '6201ba20f65f11ad3d791117',
        DIN: '2216590',
        genericName: 'FLUOXETINE HCL CAPSULE 20 MG O',
        supplierCode: 'TEV',
        brandName: 'FLUOXETINE HCL CAPS 20MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '75',
        packPrice: 33.11,
        dailyAverageDispensed: 8,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d78fec8',
        DIN: '2177587',
        genericName: 'FLUOXETINE HCL CAPSULE 20 MG O',
        supplierCode: 'PMS',
        brandName: 'FLUOXETINE CAPS 20MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '72',
        packPrice: 33.11,
        dailyAverageDispensed: 1,
      },
      APX: {
        _id: '6201ba20f65f11ad3d791b2c',
        DIN: '2216361',
        genericName: 'FLUOXETINE HCL CAPSULE 20 MG O',
        supplierCode: 'APX',
        brandName: 'FLUOXETINE CAPS 20MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '70',
        packPrice: 33.11,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba1ff65f11ad3d79032c',
        DIN: '2380579',
        genericName: 'FLUOXETINE HCL CAPSULE 20 MG O',
        supplierCode: 'MPH',
        brandName: 'FLUOXETINE CAPS 20MG 100 MINT',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '70',
        packPrice: 33.11,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d796c8b',
        DIN: '2479494',
        genericName: 'FLUOXETINE HCL CAPSULE 20 MG O',
        supplierCode: 'SDZ',
        brandName: 'FLUOXETINE CAPS 20MG 100 SDZ',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 33.11,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d7904f7',
        DIN: '2386402',
        genericName: 'FLUOXETINE HCL CAPSULE 20 MG O',
        supplierCode: 'JAP',
        brandName: 'FLUOXETINE CAPS 20MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '60',
        packPrice: 33.11,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba1ff65f11ad3d790424',
        DIN: '2383241',
        genericName: 'FLUOXETINE HCL CAPSULE 20 MG O',
        supplierCode: 'AHC',
        brandName: 'FLUOXETINE CAPS 20MG 100 ACH',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 33.11,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d793106',
        DIN: '2286076',
        genericName: 'FLUOXETINE HCL CAPSULE 20 MG O',
        supplierCode: 'SNS',
        brandName: 'FLUOXETINE CAPS 20MG 100 SNS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 33.11,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d79093f',
        DIN: '2385635',
        genericName: 'FLUOXETINE HCL CAPSULE 20 MG O',
        supplierCode: 'AUP',
        brandName: 'FLUOXETINE CAPS 20MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 33.11,
        dailyAverageDispensed: 0,
      },
      LIL: {
        _id: '6201ba27f65f11ad3d79ce31',
        DIN: '636622',
        genericName: 'FLUOXETINE HCL CAPSULE 20 MG O',
        supplierCode: 'LIL',
        brandName: 'PROZAC CAPS 20MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 217.85,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 9,
      total: 0,
    },
  },
  {
    groupCode: '46231',
    genericName: 'SERTRALINE HCL CAPSULE 25 MG O',
    strength: '25MG',
    unitPrice: 0.943,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79c782',
        DIN: '2238280',
        genericName: 'SERTRALINE HCL CAPSULE 25 MG O',
        supplierCode: 'APX',
        brandName: 'SERTRALINE CAPS 25MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '67',
        packPrice: 15.16,
        dailyAverageDispensed: 7,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c6c8',
        DIN: '2240485',
        genericName: 'SERTRALINE HCL CAPSULE 25 MG O',
        supplierCode: 'TEV',
        brandName: 'SERTRALINE CAPS 25MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 15.16,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79cffd',
        DIN: '2244838',
        genericName: 'SERTRALINE HCL CAPSULE 25 MG O',
        supplierCode: 'PMS',
        brandName: 'SERTRALINE CAPS 25MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 15.16,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d7912d8',
        DIN: '2399415',
        genericName: 'SERTRALINE HCL CAPSULE 25 MG O',
        supplierCode: 'MRC',
        brandName: 'SERTRALINE CAPS 25MG 100 MAR',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '61',
        packPrice: 15.16,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d7911af',
        DIN: '2402378',
        genericName: 'SERTRALINE HCL CAPSULE 25 MG O',
        supplierCode: 'MPH',
        brandName: 'SERTRALINE CAPS 25MG 100 MINT',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '60',
        packPrice: 15.16,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba22f65f11ad3d795562',
        DIN: '2469626',
        genericName: 'SERTRALINE HCL CAPSULE 25 MG O',
        supplierCode: 'JAP',
        brandName: 'SERTRALINE CAPS 25MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '50',
        packPrice: 15.16,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba28f65f11ad3d79da8f',
        DIN: '2132702',
        genericName: 'SERTRALINE HCL CAPSULE 25 MG O',
        supplierCode: 'UPJ',
        brandName: 'ZOLOFT CAPS 25MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 94.29,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d79370f',
        DIN: '2353520',
        genericName: 'SERTRALINE HCL CAPSULE 25 MG O',
        supplierCode: 'SNS',
        brandName: 'SERTRALINE HCL CAPS 25MG 100 SNS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 15.16,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790d44',
        DIN: '2390906',
        genericName: 'SERTRALINE HCL CAPSULE 25 MG O',
        supplierCode: 'AUP',
        brandName: 'SERTRALINE CAPS 25MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 15.16,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 9,
      total: 0,
    },
  },
  {
    groupCode: '303',
    genericName: 'TERAZOSIN HCL TABLET 2 MG ORAL',
    strength: '2MG',
    unitPrice: 0.233,
    MFRs: {
      TEV: {
        _id: '6201ba20f65f11ad3d7909af',
        DIN: '2230806',
        genericName: 'TERAZOSIN HCL TABLET 2 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'TERAZOSIN TB 2MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 23.33,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba26f65f11ad3d79bcb2',
        DIN: '2243519',
        genericName: 'TERAZOSIN HCL TABLET 2 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'TERAZOSIN TB 2MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '45',
        packPrice: 23.33,
        dailyAverageDispensed: 3,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79bd89',
        DIN: '2234503',
        genericName: 'TERAZOSIN HCL TABLET 2 MG ORAL',
        supplierCode: 'APX',
        brandName: 'TERAZOSIN TB 2MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 23.33,
        dailyAverageDispensed: 5,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 8,
      total: 0,
    },
  },
  {
    groupCode: '3758',
    genericName: 'LORAZEPAM TABLET 1 MG ORAL',
    strength: '1MG',
    unitPrice: 0.055,
    MFRs: {
      APX: {
        _id: '6201ba20f65f11ad3d790988',
        DIN: '655759',
        genericName: 'LORAZEPAM TABLET 1 MG ORAL',
        supplierCode: 'APX',
        brandName: 'LORAZEPAM TB 1MG 1000 APO',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '30',
        packPrice: 44.7,
        dailyAverageDispensed: 8,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79bf9d',
        DIN: '637742',
        genericName: 'LORAZEPAM TABLET 1 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'LORAZEPAM TB 1MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 4.47,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79cb51',
        DIN: '728195',
        genericName: 'LORAZEPAM TABLET 1 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'LORAZEPAM TB 1MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 4.47,
        dailyAverageDispensed: 0,
      },
      PFC: {
        _id: '6201ba27f65f11ad3d79bf0b',
        DIN: '2041421',
        genericName: 'LORAZEPAM TABLET 1 MG ORAL',
        supplierCode: 'PFC',
        brandName: 'ATIVAN TB 1MG 1000',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: null,
        packPrice: 54.97,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 8,
      total: 0,
    },
  },
  {
    groupCode: '46404',
    genericName: 'VENLAFAXINE HCL CAP ER 24H 75',
    strength: '75MG',
    unitPrice: 2.094,
    MFRs: {
      RAN: {
        _id: '6201ba1ff65f11ad3d79017a',
        DIN: '2380080',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 75',
        supplierCode: 'RAN',
        brandName: 'VENLAFAXINE XR CAPS 75MG 100 RAN',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '52',
        packPrice: 18.25,
        dailyAverageDispensed: 4,
      },
      APX: {
        _id: '6201ba20f65f11ad3d790c1c',
        DIN: '2331691',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 75',
        supplierCode: 'APX',
        brandName: 'VENLAFAXINE XR CAPS 75MG 100 APO',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '45',
        packPrice: 18.25,
        dailyAverageDispensed: 3,
      },
      SDZ: {
        _id: '6201ba25f65f11ad3d799a8c',
        DIN: '2310325',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 75',
        supplierCode: 'SDZ',
        brandName: 'VENLAFAXINE XR CAPS 75MG 100 SDZ',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '40',
        packPrice: 18.25,
        dailyAverageDispensed: 1,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79cccd',
        DIN: '2278553',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 75',
        supplierCode: 'PMS',
        brandName: 'VENLAFAXINE XR CAPS 75MG 100 PMS',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 18.25,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d790ddc',
        DIN: '2304325',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 75',
        supplierCode: 'TEV',
        brandName: 'VENLAFAXINE XR CAPS 75MG 100 ACT',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 18.25,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba26f65f11ad3d79b3cd',
        DIN: '2516543',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 75',
        supplierCode: 'JAP',
        brandName: 'VENLAFAXINE XR CAPS 75MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 18.25,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba27f65f11ad3d79c180',
        DIN: '2237280',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 75',
        supplierCode: 'UPJ',
        brandName: 'EFFEXOR XR CAPS 75MG 90',
        form: 'CAPSULE EXT REL',
        packSize: '90',
        rebateValue: null,
        packPrice: 188.45,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba25f65f11ad3d799cab',
        DIN: '2354721',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 75',
        supplierCode: 'SNS',
        brandName: 'VENLAFAXINE XR CAPS 75MG 100',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 18.25,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d793a5d',
        DIN: '2452847',
        genericName: 'VENLAFAXINE HCL CAP ER 24H 75',
        supplierCode: 'AUP',
        brandName: 'VENLAFAXINE XR CAPS 75MG 100 AURO',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 18.25,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 8,
      total: 0,
    },
  },
  {
    groupCode: '61199',
    genericName: 'FENOFIBRATE NANOCRYSTALLIZED T',
    strength: '48MG',
    unitPrice: 0.487,
    MFRs: {
      SDZ: {
        _id: '6201ba21f65f11ad3d792a9b',
        DIN: '2390698',
        genericName: 'FENOFIBRATE NANOCRYSTALLIZED T',
        supplierCode: 'SDZ',
        brandName: 'FENOFIBRATE E TB 48MG 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '25',
        packPrice: 10.68,
        dailyAverageDispensed: 8,
      },
      BGP: {
        _id: '6201ba27f65f11ad3d79d99f',
        DIN: '2269074',
        genericName: 'FENOFIBRATE NANOCRYSTALLIZED T',
        supplierCode: 'BGP',
        brandName: 'LIPIDIL EZ TB 48MG BLST 3X10',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 14.6,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 8,
      total: 0,
    },
  },
  {
    groupCode: '63370',
    genericName: 'ENALAPRIL SODIUM TABLET 8MG(10',
    strength: '10MG',
    unitPrice: 0.265,
    MFRs: {
      RAN: {
        _id: '6201ba1ff65f11ad3d78f664',
        DIN: '2352257',
        genericName: 'ENALAPRIL SODIUM TABLET 8MG(10',
        supplierCode: 'RAN',
        brandName: 'ENALAPRIL TB 10MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 26.47,
        dailyAverageDispensed: 1,
      },
      JAP: {
        _id: '6201ba23f65f11ad3d79709c',
        DIN: '2474808',
        genericName: 'ENALAPRIL SODIUM TABLET 8MG(10',
        supplierCode: 'JAP',
        brandName: 'ENALAPRIL TB 10MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 26.47,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c4e8',
        DIN: '2019892',
        genericName: 'ENALAPRIL SODIUM TABLET 8MG(10',
        supplierCode: 'APX',
        brandName: 'ENALAPRIL TB 10MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '64',
        packPrice: 26.47,
        dailyAverageDispensed: 7,
      },
      MRC: {
        _id: '6201ba24f65f11ad3d797148',
        DIN: '2444771',
        genericName: 'ENALAPRIL SODIUM TABLET 8MG(10',
        supplierCode: 'MRC',
        brandName: 'ENALAPRIL TB 10MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '63',
        packPrice: 26.47,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79cc33',
        DIN: '2291894',
        genericName: 'ENALAPRIL SODIUM TABLET 8MG(10',
        supplierCode: 'TEV',
        brandName: 'ENALAPRIL TB 10MG 100 ACT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 26.47,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba27f65f11ad3d79cb82',
        DIN: '2299968',
        genericName: 'ENALAPRIL SODIUM TABLET 8MG(10',
        supplierCode: 'SDZ',
        brandName: 'ENALAPRIL TB 10MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 26.47,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79b51e',
        DIN: '2400677',
        genericName: 'ENALAPRIL SODIUM TABLET 8MG(10',
        supplierCode: 'SNS',
        brandName: 'ENALAPRIL TB 10MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 26.47,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba27f65f11ad3d79c23f',
        DIN: '670901',
        genericName: 'ENALAPRIL SODIUM TABLET 8MG(10',
        supplierCode: 'ORN',
        brandName: 'VASOTEC TB 10MG 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 43.25,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 8,
      total: 0,
    },
  },
  {
    groupCode: '287',
    genericName: 'HYDRALAZINE HCL TABLET 50 MG O',
    strength: '50MG',
    unitPrice: 0.096,
    MFRs: {
      JAP: {
        _id: '6201ba22f65f11ad3d794c44',
        DIN: '2457881',
        genericName: 'HYDRALAZINE HCL TABLET 50 MG O',
        supplierCode: 'JAP',
        brandName: 'HYDRALAZINE TB 50MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 9.56,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba23f65f11ad3d7956a7',
        DIN: '2468794',
        genericName: 'HYDRALAZINE HCL TABLET 50 MG O',
        supplierCode: 'MPH',
        brandName: 'HYDRALAZINE TB 50MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 9.56,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba22f65f11ad3d794e24',
        DIN: '441635',
        genericName: 'HYDRALAZINE HCL TABLET 50 MG O',
        supplierCode: 'APX',
        brandName: 'HYDRALAZINE TB 50MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 9.56,
        dailyAverageDispensed: 7,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 7,
      total: 0,
    },
  },
  {
    groupCode: '5131',
    genericName: 'METOPROLOL TARTRATE TABLET 100',
    strength: '100MG',
    unitPrice: 0.136,
    MFRs: {
      JAP: {
        _id: '6201ba1ff65f11ad3d78fb32',
        DIN: '2356848',
        genericName: 'METOPROLOL TARTRATE TABLET 100',
        supplierCode: 'JAP',
        brandName: 'METOPROLOL-L TB 100MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 13.61,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba28f65f11ad3d79e244',
        DIN: '2230804',
        genericName: 'METOPROLOL TARTRATE TABLET 100',
        supplierCode: 'PMS',
        brandName: 'METOPROLOL L TB 100MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 13.61,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d86e',
        DIN: '842656',
        genericName: 'METOPROLOL TARTRATE TABLET 100',
        supplierCode: 'TEV',
        brandName: 'METOPROLOL TB 100MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 13.61,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79bf0c',
        DIN: '618640',
        genericName: 'METOPROLOL TARTRATE TABLET 100',
        supplierCode: 'APX',
        brandName: 'METOPROLOL TB 100MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '54',
        packPrice: 13.61,
        dailyAverageDispensed: 7,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f4ca',
        DIN: '2350408',
        genericName: 'METOPROLOL TARTRATE TABLET 100',
        supplierCode: 'SNS',
        brandName: 'METOPROLOL FC TB 100MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 13.61,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 7,
      total: 0,
    },
  },
  {
    groupCode: '5138',
    genericName: 'ATENOLOL TABLET 100 MG ORAL',
    strength: '100MG',
    unitPrice: 0.236,
    MFRs: {
      PMS: {
        _id: '6201ba1ff65f11ad3d78f555',
        DIN: '2237601',
        genericName: 'ATENOLOL TABLET 100 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'ATENOLOL TB 100MG 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 5.46,
        dailyAverageDispensed: 1,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79b7ab',
        DIN: '773697',
        genericName: 'ATENOLOL TABLET 100 MG ORAL',
        supplierCode: 'APX',
        brandName: 'ATENOL TB 100MG UD 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '64',
        packPrice: 5.46,
        dailyAverageDispensed: 6,
      },
      RAN: {
        _id: '6201ba27f65f11ad3d79c1da',
        DIN: '2267993',
        genericName: 'ATENOLOL TABLET 100 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'ATENOLOL TB 100MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '62',
        packPrice: 18.21,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba26f65f11ad3d79b176',
        DIN: '1912054',
        genericName: 'ATENOLOL TABLET 100 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'ATENOLOL TB 100MG 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 7.09,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba1ff65f11ad3d790486',
        DIN: '2371995',
        genericName: 'ATENOLOL TABLET 100 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'ATENOLOL TB 100MG 30 BLST MAR',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '58',
        packPrice: 5.46,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba1ff65f11ad3d78fa63',
        DIN: '2368048',
        genericName: 'ATENOLOL TABLET 100 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'ATENOL TB 100MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 18.21,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d78fbcf',
        DIN: '2367572',
        genericName: 'ATENOLOL TABLET 100 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'ATENOLOL TB 100MG 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 5.46,
        dailyAverageDispensed: 0,
      },
      SGP: {
        _id: '6201ba27f65f11ad3d79da1a',
        DIN: '2039540',
        genericName: 'ATENOLOL TABLET 100 MG ORAL',
        supplierCode: 'SGP',
        brandName: 'TENORMIN TB 100MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 32.02,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba22f65f11ad3d794f6f',
        DIN: '2466473',
        genericName: 'ATENOLOL TABLET 100 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'ATENOLOL TB 100MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 18.21,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 7,
      total: 0,
    },
  },
  {
    groupCode: '8182',
    genericName: 'HYDROCHLOROTHIAZIDE TABLET 25',
    strength: '25MG',
    unitPrice: 0.016,
    MFRs: {
      MPH: {
        _id: '6201ba23f65f11ad3d796332',
        DIN: '2426196',
        genericName: 'HYDROCHLOROTHIAZIDE TABLET 25',
        supplierCode: 'MPH',
        brandName: 'HYDROCHLOROTHIAZIDE TB 25MG 1000 MINT',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '40',
        packPrice: 15.7,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba24f65f11ad3d7989d7',
        DIN: '2247386',
        genericName: 'HYDROCHLOROTHIAZIDE TABLET 25',
        supplierCode: 'PMS',
        brandName: 'HYDROCHLOROTHIAZIDE TB 25MG 1000 PMS',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '35',
        packPrice: 15.7,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79d786',
        DIN: '326844',
        genericName: 'HYDROCHLOROTHIAZIDE TABLET 25',
        supplierCode: 'APX',
        brandName: 'HYDRO TB 25MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 1.57,
        dailyAverageDispensed: 7,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d78fc78',
        DIN: '21474',
        genericName: 'HYDROCHLOROTHIAZIDE TABLET 25',
        supplierCode: 'TEV',
        brandName: 'HYDROCHLOROTHIAZIDE TB 25MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '0',
        packPrice: 1.57,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 7,
      total: 0,
    },
  },
  {
    groupCode: '8334',
    genericName: 'COLCHICINE TABLET 0.6 MG ORAL',
    strength: '0.6MG',
    unitPrice: 0.257,
    MFRs: {
      PMS: {
        _id: '6201ba21f65f11ad3d792c0f',
        DIN: '2402181',
        genericName: 'COLCHICINE TABLET 0.6 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'COLCHICINE TB 0.6MG 30 BLST PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 7.7,
        dailyAverageDispensed: 7,
      },
      SDZ: {
        _id: '6201ba28f65f11ad3d79df00',
        DIN: '287873',
        genericName: 'COLCHICINE TABLET 0.6 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'COLCHICINE TB 0.6MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '45',
        packPrice: 25.65,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d790286',
        DIN: '2373823',
        genericName: 'COLCHICINE TABLET 0.6 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'COLCHICINE TB 0.6MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 25.65,
        dailyAverageDispensed: 0,
      },
      ODN: {
        _id: '6201ba22f65f11ad3d794a1d',
        DIN: '572349',
        genericName: 'COLCHICINE TABLET 0.6 MG ORAL',
        supplierCode: 'ODN',
        brandName: 'COLCHICINE TB 0.6MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 25.65,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 7,
      total: 0,
    },
  },
  {
    groupCode: '13089',
    genericName: 'CLONIDINE HCL TABLET 0.025 MG',
    strength: '0.025MG',
    unitPrice: 0.285,
    MFRs: {
      TEV: {
        _id: '6201ba27f65f11ad3d79c4da',
        DIN: '2304163',
        genericName: 'CLONIDINE HCL TABLET 0.025 MG',
        supplierCode: 'TEV',
        brandName: 'CLONIDINE TB 0.025MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 28.49,
        dailyAverageDispensed: 7,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 7,
      total: 0,
    },
  },
  {
    groupCode: '15584',
    genericName: 'DOXAZOSIN MESYLATE TABLET 1 MG',
    strength: '1MG',
    unitPrice: 0.172,
    MFRs: {
      JAP: {
        _id: '6201ba25f65f11ad3d798d3b',
        DIN: '2489937',
        genericName: 'DOXAZOSIN MESYLATE TABLET 1 MG',
        supplierCode: 'JAP',
        brandName: 'DOXAZOSIN TB 1MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 17.19,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c5c9',
        DIN: '2240588',
        genericName: 'DOXAZOSIN MESYLATE TABLET 1 MG',
        supplierCode: 'APX',
        brandName: 'DOXAZOSIN TB 1MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 17.19,
        dailyAverageDispensed: 7,
      },
      TEV: {
        _id: '6201ba28f65f11ad3d79df52',
        DIN: '2242728',
        genericName: 'DOXAZOSIN MESYLATE TABLET 1 MG',
        supplierCode: 'TEV',
        brandName: 'DOXAZOSIN TB 1MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 17.19,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 7,
      total: 0,
    },
  },
  {
    groupCode: '15585',
    genericName: 'DOXAZOSIN MESYLATE TABLET 2 MG',
    strength: '2MG',
    unitPrice: 0.206,
    MFRs: {
      JAP: {
        _id: '6201ba25f65f11ad3d798d3a',
        DIN: '2489945',
        genericName: 'DOXAZOSIN MESYLATE TABLET 2 MG',
        supplierCode: 'JAP',
        brandName: 'DOXAZOSIN TB 2MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 20.62,
        dailyAverageDispensed: 2,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c5ca',
        DIN: '2240589',
        genericName: 'DOXAZOSIN MESYLATE TABLET 2 MG',
        supplierCode: 'APX',
        brandName: 'DOXAZOSIN TB 2MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 20.62,
        dailyAverageDispensed: 5,
      },
      TEV: {
        _id: '6201ba28f65f11ad3d79df53',
        DIN: '2242729',
        genericName: 'DOXAZOSIN MESYLATE TABLET 2 MG',
        supplierCode: 'TEV',
        brandName: 'DOXAZOSIN TB 2MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 20.62,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 7,
      total: 0,
    },
  },
  {
    groupCode: '15586',
    genericName: 'DOXAZOSIN MESYLATE TABLET 4 MG',
    strength: '4MG',
    unitPrice: 0.268,
    MFRs: {
      JAP: {
        _id: '6201ba25f65f11ad3d798d39',
        DIN: '2489953',
        genericName: 'DOXAZOSIN MESYLATE TABLET 4 MG',
        supplierCode: 'JAP',
        brandName: 'DOXAZOSIN TB 4MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 26.81,
        dailyAverageDispensed: 2,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c5cb',
        DIN: '2240590',
        genericName: 'DOXAZOSIN MESYLATE TABLET 4 MG',
        supplierCode: 'APX',
        brandName: 'DOXAZOSIN TB 4MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 26.81,
        dailyAverageDispensed: 5,
      },
      TEV: {
        _id: '6201ba28f65f11ad3d79df55',
        DIN: '2242730',
        genericName: 'DOXAZOSIN MESYLATE TABLET 4 MG',
        supplierCode: 'TEV',
        brandName: 'DOXAZOSIN TB 4MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 26.81,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 7,
      total: 0,
    },
  },
  {
    groupCode: '25703',
    genericName: 'OMEPRAZOLE MAGNESIUM TABLET DR',
    strength: '20MG',
    unitPrice: 0.229,
    MFRs: {
      AHC: {
        _id: '6201ba20f65f11ad3d791c0d',
        DIN: '2416549',
        genericName: 'OMEPRAZOLE MAGNESIUM TABLET DR',
        supplierCode: 'AHC',
        brandName: 'OMEPRAZOLE TB 20MG 100 ACH',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: '66',
        packPrice: 22.87,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d7920bd',
        DIN: '2420198',
        genericName: 'OMEPRAZOLE MAGNESIUM TABLET DR',
        supplierCode: 'JAP',
        brandName: 'OMEPRAZOLE DR TB 20MG UD 28 JAMP',
        form: 'TAB DEL RELEASE',
        packSize: '28',
        rebateValue: '50',
        packPrice: 6.4,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba28f65f11ad3d79e24e',
        DIN: '2295415',
        genericName: 'OMEPRAZOLE MAGNESIUM TABLET DR',
        supplierCode: 'TEV',
        brandName: 'OMEPRAZOLE TB 20MG 100 TEVA',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 22.87,
        dailyAverageDispensed: 6,
      },
      PMS: {
        _id: '6201ba28f65f11ad3d79e03c',
        DIN: '2310260',
        genericName: 'OMEPRAZOLE MAGNESIUM TABLET DR',
        supplierCode: 'PMS',
        brandName: 'OMEPRAZOLE TB 20MG 100 PMS',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 22.87,
        dailyAverageDispensed: 0,
      },
      NTC: {
        _id: '6201ba21f65f11ad3d792a4b',
        DIN: '2439549',
        genericName: 'OMEPRAZOLE MAGNESIUM TABLET DR',
        supplierCode: 'NTC',
        brandName: 'OMEPRAZOLE TB DR 20MG 100 NAT',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: null,
        packPrice: 22.87,
        dailyAverageDispensed: 1,
      },
      SNS: {
        _id: '6201ba25f65f11ad3d79a0d1',
        DIN: '2504294',
        genericName: 'OMEPRAZOLE MAGNESIUM TABLET DR',
        supplierCode: 'SNS',
        brandName: 'OMEPRAZOLE MAGNESIUM DELAYED RELEASE TB 20MG 100',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: null,
        packPrice: 22.87,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 7,
      total: 0,
    },
  },
  {
    groupCode: '34187',
    genericName: 'QUETIAPINE FUMARATE TABLET 25',
    strength: '25MG',
    unitPrice: 0.548,
    MFRs: {
      JAP: {
        _id: '6201ba27f65f11ad3d79c522',
        DIN: '2330415',
        genericName: 'QUETIAPINE FUMARATE TABLET 25',
        supplierCode: 'JAP',
        brandName: 'QUETIAPINE TB 25MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 4.94,
        dailyAverageDispensed: 1,
      },
      AHC: {
        _id: '6201ba25f65f11ad3d79979c',
        DIN: '2387794',
        genericName: 'QUETIAPINE FUMARATE TABLET 25',
        supplierCode: 'AHC',
        brandName: 'QUETIAPINE TB 25MG 100 ACH',
        form: 'TAB IR',
        packSize: '100',
        rebateValue: '66',
        packPrice: 4.94,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba21f65f11ad3d792622',
        DIN: '2438003',
        genericName: 'QUETIAPINE FUMARATE TABLET 25',
        supplierCode: 'MPH',
        brandName: 'QUETIAPINE TB 25MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 4.94,
        dailyAverageDispensed: 3,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79a8d7',
        DIN: '2501635',
        genericName: 'QUETIAPINE FUMARATE TABLET 25',
        supplierCode: 'APX',
        brandName: 'QUETIAPINE FUMARATE TB 25MG 100 APO',
        form: 'TAB IR',
        packSize: '100',
        rebateValue: '50',
        packPrice: 4.94,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79c94d',
        DIN: '2296551',
        genericName: 'QUETIAPINE FUMARATE TABLET 25',
        supplierCode: 'PMS',
        brandName: 'QUETIAPINE TB 25MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '42',
        packPrice: 4.94,
        dailyAverageDispensed: 3,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c677',
        DIN: '2316080',
        genericName: 'QUETIAPINE FUMARATE TABLET 25',
        supplierCode: 'TEV',
        brandName: 'QUETIAPINE TB 25MG 100 CO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 4.94,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba27f65f11ad3d79c902',
        DIN: '2236951',
        genericName: 'QUETIAPINE FUMARATE TABLET 25',
        supplierCode: 'AST',
        brandName: 'SEROQUEL TB 25MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 54.81,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f3da',
        DIN: '2353164',
        genericName: 'QUETIAPINE FUMARATE TABLET 25',
        supplierCode: 'SNS',
        brandName: 'QUETIAPINE TB 25MG 500 SNS',
        form: 'TABLET',
        packSize: '500',
        rebateValue: null,
        packPrice: 24.7,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790bdc',
        DIN: '2390205',
        genericName: 'QUETIAPINE FUMARATE TABLET 25',
        supplierCode: 'AUP',
        brandName: 'QUETIAPINE TB 25MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 4.94,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 7,
      total: 0,
    },
  },
  {
    groupCode: '45052',
    genericName: 'ALFUZOSIN HCL TAB ER 24H 10 MG',
    strength: '10MG',
    unitPrice: 1.098,
    MFRs: {
      APX: {
        _id: '6201ba21f65f11ad3d79317c',
        DIN: '2315866',
        genericName: 'ALFUZOSIN HCL TAB ER 24H 10 MG',
        supplierCode: 'APX',
        brandName: 'ALFUZOSIN XR TB 10MG 100 APO',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '20',
        packPrice: 26.01,
        dailyAverageDispensed: 7,
      },
      SDZ: {
        _id: '6201ba26f65f11ad3d79b81e',
        DIN: '2304678',
        genericName: 'ALFUZOSIN HCL TAB ER 24H 10 MG',
        supplierCode: 'SDZ',
        brandName: 'ALFUZOSIN TB 10MG 100 SDZ',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 26.01,
        dailyAverageDispensed: 0,
      },
      SAC: {
        _id: '6201ba26f65f11ad3d79b661',
        DIN: '2245565',
        genericName: 'ALFUZOSIN HCL TAB ER 24H 10 MG',
        supplierCode: 'SAC',
        brandName: 'XATRAL TB 10MG 100',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 109.77,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d792e74',
        DIN: '2443201',
        genericName: 'ALFUZOSIN HCL TAB ER 24H 10 MG',
        supplierCode: 'AUP',
        brandName: 'ALFUZOSIN TB 10MG 100 AURO',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 26.01,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 7,
      total: 0,
    },
  },
  {
    groupCode: '57803',
    genericName: 'PREGABALIN CAPSULE 150 MG ORAL',
    strength: '150MG',
    unitPrice: 2.578,
    MFRs: {
      RAN: {
        _id: '6201ba20f65f11ad3d790bc1',
        DIN: '2392844',
        genericName: 'PREGABALIN CAPSULE 150 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'PREGABALIN CAPS 150MG 100 RAN',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '72',
        packPrice: 41.45,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba20f65f11ad3d7909d5',
        DIN: '2359634',
        genericName: 'PREGABALIN CAPSULE 150 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'PREGABALIN CAPS 150MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '70',
        packPrice: 41.45,
        dailyAverageDispensed: 7,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d7909de',
        DIN: '2361205',
        genericName: 'PREGABALIN CAPSULE 150 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'PREGABALIN CAPS 150MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '70',
        packPrice: 41.45,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d792fbf',
        DIN: '2436000',
        genericName: 'PREGABALIN CAPSULE 150 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'PREGABALIN CAPS 150MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '70',
        packPrice: 41.45,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba20f65f11ad3d790a94',
        DIN: '2390841',
        genericName: 'PREGABALIN CAPSULE 150 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'PREGABALIN CAPS 150MG 100 SDZ',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 41.45,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba24f65f11ad3d79890c',
        DIN: '2394278',
        genericName: 'PREGABALIN CAPSULE 150 MG ORAL',
        supplierCode: 'APX',
        brandName: 'PREGABALIN CAPS 150MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 41.45,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba26f65f11ad3d79a6c0',
        DIN: '2449870',
        genericName: 'PREGABALIN CAPSULE 150 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'PREGABALIN CAPS 150MG 100 ACH',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 41.45,
        dailyAverageDispensed: 0,
      },
      NRI: {
        _id: '6201ba25f65f11ad3d799296',
        DIN: '2479168',
        genericName: 'PREGABALIN CAPSULE 150 MG ORAL',
        supplierCode: 'NRI',
        brandName: 'PREGABALIN CAPS 150MG 100 NRA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '60',
        packPrice: 41.45,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d791dcd',
        DIN: '2424207',
        genericName: 'PREGABALIN CAPSULE 150 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'PREGABALIN CAPS 150MG 100 MINT',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '40',
        packPrice: 41.45,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba27f65f11ad3d79d9a5',
        DIN: '2268450',
        genericName: 'PREGABALIN CAPSULE 150 MG ORAL',
        supplierCode: 'UPJ',
        brandName: 'LYRICA CAPS 150MG 60',
        form: 'CAPSULE',
        packSize: '60',
        rebateValue: null,
        packPrice: 154.66,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d79335e',
        DIN: '2405563',
        genericName: 'PREGABALIN CAPSULE 150 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'PREGABALIN CAPS 150MG 100 SNS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 41.45,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d792a4f',
        DIN: '2433907',
        genericName: 'PREGABALIN CAPSULE 150 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'PREGABALIN CAPS 150MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 41.45,
        dailyAverageDispensed: 0,
      },
      NTC: {
        _id: '6201ba24f65f11ad3d7989a2',
        DIN: '2494884',
        genericName: 'PREGABALIN CAPSULE 150 MG ORAL',
        supplierCode: 'NTC',
        brandName: 'PREGABALIN CAPS 150MG 100 NAT',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 41.45,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 7,
      total: 0,
    },
  },
  {
    groupCode: '61200',
    genericName: 'FENOFIBRATE NANOCRYSTALLIZED T',
    strength: '145MG',
    unitPrice: 1.245,
    MFRs: {
      SDZ: {
        _id: '6201ba20f65f11ad3d791ce1',
        DIN: '2390701',
        genericName: 'FENOFIBRATE NANOCRYSTALLIZED T',
        supplierCode: 'SDZ',
        brandName: 'FENOFIBRATE E TB 145MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 54.89,
        dailyAverageDispensed: 7,
      },
      TAR: {
        _id: '6201ba25f65f11ad3d7995f4',
        DIN: '2454696',
        genericName: 'FENOFIBRATE NANOCRYSTALLIZED T',
        supplierCode: 'TAR',
        brandName: 'FENOFIBRATE E TB 145MG 100 TARO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 54.89,
        dailyAverageDispensed: 0,
      },
      BGP: {
        _id: '6201ba27f65f11ad3d79d9a0',
        DIN: '2269082',
        genericName: 'FENOFIBRATE NANOCRYSTALLIZED T',
        supplierCode: 'BGP',
        brandName: 'LIPIDIL EZ TB 145MG BLST 3X10',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 37.35,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 7,
      total: 0,
    },
  },
  {
    groupCode: '2184',
    genericName: 'CALCITRIOL CAPSULE 0.25 MCG OR',
    strength: '0.25MCG',
    unitPrice: 0.234,
    MFRs: {
      TAR: {
        _id: '6201ba24f65f11ad3d797700',
        DIN: '2485710',
        genericName: 'CALCITRIOL CAPSULE 0.25 MCG OR',
        supplierCode: 'TAR',
        brandName: 'CALCITRIOL CAPS 0.25MCG 90 TARO',
        form: 'CAPSULE',
        packSize: '90',
        rebateValue: '42',
        packPrice: 21.07,
        dailyAverageDispensed: 0,
      },
      ODN: {
        _id: '6201ba21f65f11ad3d79236f',
        DIN: '2431637',
        genericName: 'CALCITRIOL CAPSULE 0.25 MCG OR',
        supplierCode: 'ODN',
        brandName: 'CALCITRIOL-ODAN CAPS 0.25MCG BTL 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 23.41,
        dailyAverageDispensed: 6,
      },
      SIP: {
        _id: '6201ba25f65f11ad3d7998e6',
        DIN: '2495899',
        genericName: 'CALCITRIOL CAPSULE 0.25 MCG OR',
        supplierCode: 'SIP',
        brandName: 'CALCITRIOL CAPS 0.25MCG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 23.41,
        dailyAverageDispensed: 0,
      },
      SGP: {
        _id: '6201ba22f65f11ad3d7941fd',
        DIN: '481823',
        genericName: 'CALCITRIOL CAPSULE 0.25 MCG OR',
        supplierCode: 'SGP',
        brandName: 'ROCALTROL CAPS 0.25MCG BLS 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 76.84,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '2766',
    genericName: 'SUCRALFATE TABLET 1 G ORAL',
    strength: '1G',
    unitPrice: 0.309,
    MFRs: {
      APX: {
        _id: '6201ba26f65f11ad3d79be20',
        DIN: '2125250',
        genericName: 'SUCRALFATE TABLET 1 G ORAL',
        supplierCode: 'APX',
        brandName: 'SUCRALFATE TB 1G 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 30.89,
        dailyAverageDispensed: 5,
      },
      TEV: {
        _id: '6201ba26f65f11ad3d79ba26',
        DIN: '2045702',
        genericName: 'SUCRALFATE TABLET 1 G ORAL',
        supplierCode: 'TEV',
        brandName: 'SUCRALFATE TB 1G 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 30.89,
        dailyAverageDispensed: 1,
      },
      ALL: {
        _id: '6201ba27f65f11ad3d79d5df',
        DIN: '2100622',
        genericName: 'SUCRALFATE TABLET 1 G ORAL',
        supplierCode: 'ALL',
        brandName: 'SULCRATE TB 1G 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 73.59,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '4540',
    genericName: 'DIVALPROEX SODIUM TABLET DR 50',
    strength: '500MG',
    unitPrice: 0.554,
    MFRs: {
      APX: {
        _id: '6201ba26f65f11ad3d79ba97',
        DIN: '2239700',
        genericName: 'DIVALPROEX SODIUM TABLET DR 50',
        supplierCode: 'APX',
        brandName: 'DIVALPROEX TB 500MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 55.37,
        dailyAverageDispensed: 6,
      },
      MYL: {
        _id: '6201ba22f65f11ad3d7945a7',
        DIN: '2459019',
        genericName: 'DIVALPROEX SODIUM TABLET DR 50',
        supplierCode: 'MYL',
        brandName: 'DIVALPROEX TB 500MG 100 MYLAN',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: null,
        packPrice: 55.37,
        dailyAverageDispensed: 0,
      },
      BGP: {
        _id: '6201ba26f65f11ad3d79be18',
        DIN: '596434',
        genericName: 'DIVALPROEX SODIUM TABLET DR 50',
        supplierCode: 'BGP',
        brandName: 'EPIVAL TB 500MG 100',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: null,
        packPrice: 125.6,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '7572',
    genericName: 'BETAMETHASONE VALERATE CREAM (',
    strength: '0.10%',
    unitPrice: 0.089,
    MFRs: {
      TAR: {
        _id: '6201ba27f65f11ad3d79d9ed',
        DIN: '716626',
        genericName: 'BETAMETHASONE VALERATE CREAM (',
        supplierCode: 'TAR',
        brandName: 'BETADERM CR 0.1% 15G',
        form: 'CREAM',
        packSize: '15',
        rebateValue: '52',
        packPrice: 8,
        dailyAverageDispensed: 5,
      },
      TEV: {
        _id: '6201ba22f65f11ad3d79422d',
        DIN: '535435',
        genericName: 'BETAMETHASONE VALERATE CREAM (',
        supplierCode: 'TEV',
        brandName: 'ECTOSONE TOPICAL CR 0.1% 450G RATIO',
        form: 'CREAM',
        packSize: '450',
        rebateValue: '1',
        packPrice: 40.01,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '7574',
    genericName: 'BETAMETHASONE VALERATE LOTION',
    strength: '0.10%',
    unitPrice: 0.085,
    MFRs: {
      TAR: {
        _id: '6201ba22f65f11ad3d794a8d',
        DIN: '716634',
        genericName: 'BETAMETHASONE VALERATE LOTION',
        supplierCode: 'TAR',
        brandName: 'BETADERM SCALP LOT 0.1% 75ML',
        form: 'LOTION',
        packSize: '75',
        rebateValue: '52',
        packPrice: 6.4,
        dailyAverageDispensed: 6,
      },
      TEV: {
        _id: '6201ba22f65f11ad3d79423b',
        DIN: '750050',
        genericName: 'BETAMETHASONE VALERATE LOTION',
        supplierCode: 'TEV',
        brandName: 'ECTOSONE TOPICAL LOT 0.1% 60ML RATIO',
        form: 'LOTION',
        packSize: '60',
        rebateValue: '1',
        packPrice: 18.75,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '7634',
    genericName: 'CLOBETASOL PROPIONATE CREAM (G',
    strength: '0.05%',
    unitPrice: 0.228,
    MFRs: {
      TEV: {
        _id: '6201ba28f65f11ad3d79de69',
        DIN: '1910272',
        genericName: 'CLOBETASOL PROPIONATE CREAM (G',
        supplierCode: 'TEV',
        brandName: 'CLOBETASOL TOPICAL CR 0.05% 15G RATIO',
        form: 'CREAM',
        packSize: '15',
        rebateValue: '1',
        packPrice: 3.42,
        dailyAverageDispensed: 0,
      },
      TAR: {
        _id: '6201ba27f65f11ad3d79c6f6',
        DIN: '2245523',
        genericName: 'CLOBETASOL PROPIONATE CREAM (G',
        supplierCode: 'TAR',
        brandName: 'CLOBETASOL CR 0.05% 15G TARO',
        form: 'CREAM',
        packSize: '15',
        rebateValue: '1',
        packPrice: 3.42,
        dailyAverageDispensed: 3,
      },
      MYL: {
        _id: '6201ba26f65f11ad3d79bd7d',
        DIN: '2024187',
        genericName: 'CLOBETASOL PROPIONATE CREAM (G',
        supplierCode: 'MYL',
        brandName: 'CLOBETASOL CR 0.05% 50G MYLAN',
        form: 'CREAM',
        packSize: '50',
        rebateValue: null,
        packPrice: 11.4,
        dailyAverageDispensed: 3,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '8799',
    genericName: 'METHOTREXATE TABLET 2.5 MG ORA',
    strength: '2.5MG',
    unitPrice: 0.503,
    MFRs: {
      PMS: {
        _id: '6201ba25f65f11ad3d79a327',
        DIN: '2170698',
        genericName: 'METHOTREXATE TABLET 2.5 MG ORA',
        supplierCode: 'PMS',
        brandName: 'METHOTREXATE TB 2.5MG UD BLST 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '20',
        packPrice: 15.08,
        dailyAverageDispensed: 1,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79aa95',
        DIN: '2182963',
        genericName: 'METHOTREXATE TABLET 2.5 MG ORA',
        supplierCode: 'APX',
        brandName: 'METHOTREXATE TB 2.5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 50.27,
        dailyAverageDispensed: 5,
      },
      AHC: {
        _id: '6201ba26f65f11ad3d79af87',
        DIN: '2509067',
        genericName: 'METHOTREXATE TABLET 2.5 MG ORA',
        supplierCode: 'AHC',
        brandName: 'METHOTREXATE TB 2.5MG 100 ACH',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 50.27,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '9223',
    genericName: 'DOXYCYCLINE HYCLATE TABLET 100',
    strength: '100MG',
    unitPrice: 0.586,
    MFRs: {
      APX: {
        _id: '6201ba20f65f11ad3d791add',
        DIN: '874256',
        genericName: 'DOXYCYCLINE HYCLATE TABLET 100',
        supplierCode: 'APX',
        brandName: 'DOXY TB 100MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 58.6,
        dailyAverageDispensed: 6,
      },
      TEV: {
        _id: '6201ba25f65f11ad3d798ca2',
        DIN: '2158574',
        genericName: 'DOXYCYCLINE HYCLATE TABLET 100',
        supplierCode: 'TEV',
        brandName: 'DOXYCYCLIN FC TB 100MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 58.6,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f4be',
        DIN: '2351242',
        genericName: 'DOXYCYCLINE HYCLATE TABLET 100',
        supplierCode: 'SNS',
        brandName: 'DOXYCYCLINE TB 100MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 58.6,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '22233',
    genericName: 'CARVEDILOL TABLET 12.5 MG ORAL',
    strength: '12.5MG',
    unitPrice: 0.243,
    MFRs: {
      PMS: {
        _id: '6201ba27f65f11ad3d79c915',
        DIN: '2245916',
        genericName: 'CARVEDILOL TABLET 12.5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'CARVEDILOL TB 12.5MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 24.31,
        dailyAverageDispensed: 6,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79caec',
        DIN: '2247935',
        genericName: 'CARVEDILOL TABLET 12.5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'CARVEDILOL TB 12.5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '54',
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79cfa9',
        DIN: '2252325',
        genericName: 'CARVEDILOL TABLET 12.5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'CARVEDILOL TB 12.5MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '15',
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d791683',
        DIN: '2364948',
        genericName: 'CARVEDILOL TABLET 12.5 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'CARVEDILOL TB 12.5MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d790043',
        DIN: '2368919',
        genericName: 'CARVEDILOL TABLET 12.5 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'CARVEDILOL TB 12.5MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '0',
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d791b2b',
        DIN: '2418517',
        genericName: 'CARVEDILOL TABLET 12.5 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'CARVEDILOL TB 12.5MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '26170',
    genericName: 'TOPIRAMATE TABLET 100 MG ORAL',
    strength: '100MG',
    unitPrice: 3.471,
    MFRs: {
      TEV: {
        _id: '6201ba28f65f11ad3d79db7d',
        DIN: '2248861',
        genericName: 'TOPIRAMATE TABLET 100 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'TOPIRAMATE TB 100MG 60 TEVA',
        form: 'TABLET',
        packSize: '60',
        rebateValue: '70',
        packPrice: 27.5,
        dailyAverageDispensed: 5,
      },
      PMS: {
        _id: '6201ba28f65f11ad3d79db6e',
        DIN: '2263009',
        genericName: 'TOPIRAMATE TABLET 100 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'TOPIRAMATE TB 100MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '68',
        packPrice: 45.83,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79b8cc',
        DIN: '2279630',
        genericName: 'TOPIRAMATE TABLET 100 MG ORAL',
        supplierCode: 'APX',
        brandName: 'TOPIRAMATE TB 100MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 45.83,
        dailyAverageDispensed: 1,
      },
      MPH: {
        _id: '6201ba27f65f11ad3d79d8d2',
        DIN: '2315653',
        genericName: 'TOPIRAMATE TABLET 100 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'TOPIRAMATE TB 100MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 45.83,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba21f65f11ad3d7922e3',
        DIN: '2431815',
        genericName: 'TOPIRAMATE TABLET 100 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'TOPIRAMATE TB 100MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 45.83,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba22f65f11ad3d793f68',
        DIN: '2435616',
        genericName: 'TOPIRAMATE TABLET 100 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'TOPIRAMATE TB 100MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 45.83,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba20f65f11ad3d79093b',
        DIN: '2395746',
        genericName: 'TOPIRAMATE TABLET 100 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'TOPIRAMATE TB 100MG 100 ACH',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 45.83,
        dailyAverageDispensed: 0,
      },
      JNO: {
        _id: '6201ba27f65f11ad3d79c11c',
        DIN: '2230894',
        genericName: 'TOPIRAMATE TABLET 100 MG ORAL',
        supplierCode: 'JNO',
        brandName: 'TOPAMAX TB 100MG 60',
        form: 'TABLET',
        packSize: '60',
        rebateValue: null,
        packPrice: 208.26,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba28f65f11ad3d79db2b',
        DIN: '2263378',
        genericName: 'TOPIRAMATE TABLET 100 MG ORAL',
        supplierCode: 'MYL',
        brandName: 'TOPIRAMATE TB 100MG 100 MYLAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 45.83,
        dailyAverageDispensed: 0,
      },
      GLN: {
        _id: '6201ba26f65f11ad3d79ae59',
        DIN: '2287773',
        genericName: 'TOPIRAMATE TABLET 100 MG ORAL',
        supplierCode: 'GLN',
        brandName: 'TOPIRAMATE TB 100MG 100 GLN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 45.83,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d791f5d',
        DIN: '2345838',
        genericName: 'TOPIRAMATE TABLET 100 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'TOPIRAMATE TB 100MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 45.83,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78fa0f',
        DIN: '2356864',
        genericName: 'TOPIRAMATE TABLET 100 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'TOPIRAMATE TB 100MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 45.83,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '34470',
    genericName: 'IRBESARTAN TABLET 75 MG ORAL',
    strength: '75MG',
    unitPrice: 1.337,
    MFRs: {
      RAN: {
        _id: '6201ba20f65f11ad3d7912f3',
        DIN: '2406810',
        genericName: 'IRBESARTAN TABLET 75 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'IRBESARTAN TB 75MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 22.81,
        dailyAverageDispensed: 4,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d78f6b5',
        DIN: '2317060',
        genericName: 'IRBESARTAN TABLET 75 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'IRBESARTAN TB 75MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 22.81,
        dailyAverageDispensed: 1,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d78f6a3',
        DIN: '2328461',
        genericName: 'IRBESARTAN TABLET 75 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'IRBESARTAN TB 75MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 22.81,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d78f715',
        DIN: '2316390',
        genericName: 'IRBESARTAN TABLET 75 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'IRBESARTAN TB 75MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba21f65f11ad3d792726',
        DIN: '2422980',
        genericName: 'IRBESARTAN TABLET 75 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'IRBESARTAN TB 75MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      SAC: {
        _id: '6201ba20f65f11ad3d791075',
        DIN: '2237923',
        genericName: 'IRBESARTAN TABLET 75 MG ORAL',
        supplierCode: 'SAC',
        brandName: 'AVAPRO TB 75MG 90',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 120.32,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d792712',
        DIN: '2372347',
        genericName: 'IRBESARTAN TABLET 75 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'IRBESARTAN TB 75MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 24.07,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d7911f8',
        DIN: '2406098',
        genericName: 'IRBESARTAN TABLET 75 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'IRBESARTAN TB 75MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '41048',
    genericName: 'HYDROCODONE BITARTRATE LIQUID',
    strength: '1MG/ML',
    unitPrice: 0.06,
    MFRs: {
      PPI: {
        _id: '6201ba27f65f11ad3d79c372',
        DIN: '2324253',
        genericName: 'HYDROCODONE BITARTRATE LIQUID',
        supplierCode: 'PPI',
        brandName: 'HYDROCODONE BITAR SYR 1MG/ML 500ML PDP',
        form: 'SYRUP',
        packSize: '500',
        rebateValue: null,
        packPrice: 30.07,
        dailyAverageDispensed: 6,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '44633',
    genericName: 'LEVETIRACETAM TABLET 500 MG OR',
    strength: '500MG',
    unitPrice: 2.292,
    MFRs: {
      AHC: {
        _id: '6201ba26f65f11ad3d79a3d0',
        DIN: '2399784',
        genericName: 'LEVETIRACETAM TABLET 500 MG OR',
        supplierCode: 'AHC',
        brandName: 'LEVETIRACETAM TB 500MG 120 ACH',
        form: 'TABLET',
        packSize: '120',
        rebateValue: '70',
        packPrice: 46.93,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79bd90',
        DIN: '2285932',
        genericName: 'LEVETIRACETAM TABLET 500 MG OR',
        supplierCode: 'APX',
        brandName: 'LEVETIRACET TB 500MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '51',
        packPrice: 39.11,
        dailyAverageDispensed: 5,
      },
      SDZ: {
        _id: '6201ba22f65f11ad3d794aa6',
        DIN: '2461994',
        genericName: 'LEVETIRACETAM TABLET 500 MG OR',
        supplierCode: 'SDZ',
        brandName: 'LEVETIRACETAM TB 500MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '48',
        packPrice: 39.11,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba20f65f11ad3d791361',
        DIN: '2403021',
        genericName: 'LEVETIRACETAM TABLET 500 MG OR',
        supplierCode: 'JAP',
        brandName: 'LEVETIRACETAM TB 500MG 120 JAMP',
        form: 'TABLET',
        packSize: '120',
        rebateValue: '40',
        packPrice: 46.93,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba26f65f11ad3d79ae74',
        DIN: '2296128',
        genericName: 'LEVETIRACETAM TABLET 500 MG OR',
        supplierCode: 'PMS',
        brandName: 'LEVETIRACETAM TB 500MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '35',
        packPrice: 39.11,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d7930f1',
        DIN: '2274191',
        genericName: 'LEVETIRACETAM TABLET 500 MG OR',
        supplierCode: 'TEV',
        brandName: 'LEVETIRACETAM TB 500MG 100 ACT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 39.11,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba26f65f11ad3d79a76d',
        DIN: '2442396',
        genericName: 'LEVETIRACETAM TABLET 500 MG OR',
        supplierCode: 'MPH',
        brandName: 'LEVETIRACETAM TB 500MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 39.11,
        dailyAverageDispensed: 0,
      },
      UCB: {
        _id: '6201ba27f65f11ad3d79c238',
        DIN: '2247028',
        genericName: 'LEVETIRACETAM TABLET 500 MG OR',
        supplierCode: 'UCB',
        brandName: 'KEPPRA TB 500MG 120',
        form: 'TABLET',
        packSize: '120',
        rebateValue: null,
        packPrice: 275.04,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f3cd',
        DIN: '2353350',
        genericName: 'LEVETIRACETAM TABLET 500 MG OR',
        supplierCode: 'SNS',
        brandName: 'LEVETIRACETAM TB 500MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 39.11,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba1ff65f11ad3d790239',
        DIN: '2375257',
        genericName: 'LEVETIRACETAM TABLET 500 MG OR',
        supplierCode: 'AUP',
        brandName: 'LEVETIRACETAM TB 500MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 39.11,
        dailyAverageDispensed: 0,
      },
      NTC: {
        _id: '6201ba21f65f11ad3d792a48',
        DIN: '2440210',
        genericName: 'LEVETIRACETAM TABLET 500 MG OR',
        supplierCode: 'NTC',
        brandName: 'LEVETIRACETAM TB 500MG 120 NAT',
        form: 'TABLET',
        packSize: '120',
        rebateValue: null,
        packPrice: 46.93,
        dailyAverageDispensed: 1,
      },
      RIV: {
        _id: '6201ba24f65f11ad3d797fa7',
        DIN: '2482282',
        genericName: 'LEVETIRACETAM TABLET 500 MG OR',
        supplierCode: 'RIV',
        brandName: 'LEVETIRACETAM TB 500MG 100 RIVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 39.11,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '46046',
    genericName: 'AMITRIPTYLINE HCL TABLET 25 MG',
    strength: '25MG',
    unitPrice: 0.083,
    MFRs: {
      PMS: {
        _id: '6201ba20f65f11ad3d7910ac',
        DIN: '654515',
        genericName: 'AMITRIPTYLINE HCL TABLET 25 MG',
        supplierCode: 'PMS',
        brandName: 'AMITRIPTYLINE TB 25MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 8.29,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba20f65f11ad3d790e13',
        DIN: '2403145',
        genericName: 'AMITRIPTYLINE HCL TABLET 25 MG',
        supplierCode: 'APX',
        brandName: 'AMITRIPTYLINE TB 25MG 1000 APO',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '40',
        packPrice: 82.9,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba21f65f11ad3d792152',
        DIN: '2429888',
        genericName: 'AMITRIPTYLINE HCL TABLET 25 MG',
        supplierCode: 'MRC',
        brandName: 'AMITRIPTYLINE TB 25MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 8.29,
        dailyAverageDispensed: 6,
      },
      AAA: {
        _id: '6201ba28f65f11ad3d79e13b',
        DIN: '335061',
        genericName: 'AMITRIPTYLINE HCL TABLET 25 MG',
        supplierCode: 'AAA',
        brandName: 'ELAVIL FCT TB 25MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 12.11,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d79279b',
        DIN: '2435535',
        genericName: 'AMITRIPTYLINE HCL TABLET 25 MG',
        supplierCode: 'JAP',
        brandName: 'AMITRIPTYLINE TB 25MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '0',
        packPrice: 8.29,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '46223',
    genericName: 'PAROXETINE HCL TABLET 20 MG OR',
    strength: '20MG',
    unitPrice: 2.123,
    MFRs: {
      JAP: {
        _id: '6201ba1ff65f11ad3d79023c',
        DIN: '2368870',
        genericName: 'PAROXETINE HCL TABLET 20 MG OR',
        supplierCode: 'JAP',
        brandName: 'PAROXETINE TB 20MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 32.5,
        dailyAverageDispensed: 1,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79b779',
        DIN: '2240908',
        genericName: 'PAROXETINE HCL TABLET 20 MG OR',
        supplierCode: 'APX',
        brandName: 'PAROXETINE TB 20MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '67',
        packPrice: 32.5,
        dailyAverageDispensed: 5,
      },
      PMS: {
        _id: '6201ba26f65f11ad3d79ba59',
        DIN: '2247751',
        genericName: 'PAROXETINE HCL TABLET 20 MG OR',
        supplierCode: 'PMS',
        brandName: 'PAROXETINE TB 20MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 32.5,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d791d59',
        DIN: '2421380',
        genericName: 'PAROXETINE HCL TABLET 20 MG OR',
        supplierCode: 'MPH',
        brandName: 'PAROXETINE TB 20MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 32.5,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d79189e',
        DIN: '2411954',
        genericName: 'PAROXETINE HCL TABLET 20 MG OR',
        supplierCode: 'MRC',
        brandName: 'PAROXETINE TB 20MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 32.5,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c7df',
        DIN: '2248557',
        genericName: 'PAROXETINE HCL TABLET 20 MG OR',
        supplierCode: 'TEV',
        brandName: 'PAROXETINE TB 20MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 32.5,
        dailyAverageDispensed: 0,
      },
      GSK: {
        _id: '6201ba26f65f11ad3d79bdca',
        DIN: '1940481',
        genericName: 'PAROXETINE HCL TABLET 20 MG OR',
        supplierCode: 'GSK',
        brandName: 'PAXIL TB 20MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 212.3,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79ad0d',
        DIN: '2282852',
        genericName: 'PAROXETINE HCL TABLET 20 MG OR',
        supplierCode: 'SNS',
        brandName: 'PAROXETINE HCL TB 20MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 32.5,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790799',
        DIN: '2383284',
        genericName: 'PAROXETINE HCL TABLET 20 MG OR',
        supplierCode: 'AUP',
        brandName: 'PAROXETINE TB 20MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 32.5,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '46451',
    genericName: 'MIRTAZAPINE TABLET 30 MG ORAL',
    strength: '30MG',
    unitPrice: 2.003,
    MFRs: {
      PMS: {
        _id: '6201ba22f65f11ad3d793b30',
        DIN: '2248762',
        genericName: 'MIRTAZAPINE TABLET 30 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'MIRTAZAPINE TB 30MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 31,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79bc08',
        DIN: '2286629',
        genericName: 'MIRTAZAPINE TABLET 30 MG ORAL',
        supplierCode: 'APX',
        brandName: 'MIRTAZAPINE TB 30MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '54',
        packPrice: 31,
        dailyAverageDispensed: 6,
      },
      SDZ: {
        _id: '6201ba26f65f11ad3d79a465',
        DIN: '2250608',
        genericName: 'MIRTAZAPINE TABLET 30 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'MIRTAZAPINE TB 30MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 31,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d185',
        DIN: '2259354',
        genericName: 'MIRTAZAPINE TABLET 30 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'MIRTAZAPINE TB 30MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 31,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba26f65f11ad3d79bea0',
        DIN: '2243910',
        genericName: 'MIRTAZAPINE TABLET 30 MG ORAL',
        supplierCode: 'ORN',
        brandName: 'REMERON TB 30MG UD 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 60.1,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba27f65f11ad3d79d072',
        DIN: '2256118',
        genericName: 'MIRTAZAPINE TABLET 30 MG ORAL',
        supplierCode: 'MYL',
        brandName: 'MIRTAZAPINE TB 30MG 100 MYLAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 31,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d7900b3',
        DIN: '2370689',
        genericName: 'MIRTAZAPINE TABLET 30 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'MIRTAZAPINE TB 30MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 31,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d7915fa',
        DIN: '2411709',
        genericName: 'MIRTAZAPINE TABLET 30 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'MIRTAZAPINE TB 30MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 9.3,
        dailyAverageDispensed: 0,
      },
      SIV: {
        _id: '6201ba25f65f11ad3d7991d6',
        DIN: '2496674',
        genericName: 'MIRTAZAPINE TABLET 30 MG ORAL',
        supplierCode: 'SIV',
        brandName: 'MIRTAZAPINE TB 30MG 30 SIVEM',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 9.3,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '46650',
    genericName: 'ESOMEPRAZOLE MAGNESIUM TABLET',
    strength: '40MG',
    unitPrice: 2.512,
    MFRs: {
      TEV: {
        _id: '6201ba21f65f11ad3d792990',
        DIN: '2423863',
        genericName: 'ESOMEPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'TEV',
        brandName: 'ESOMEPRAZOLE TB 40MG UD 30 ACT',
        form: 'TAB DEL RELEASE',
        packSize: '30',
        rebateValue: '80',
        packPrice: 56.07,
        dailyAverageDispensed: 5,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79ad6b',
        DIN: '2339102',
        genericName: 'ESOMEPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'APX',
        brandName: 'ESOMEPRAZOLE TB 40MG BLST 30 APO',
        form: 'TAB DEL RELEASE',
        packSize: '30',
        rebateValue: '40',
        packPrice: 56.07,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d79649b',
        DIN: '2460939',
        genericName: 'ESOMEPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'SDZ',
        brandName: 'ESOMEPRAZOLE TB 40MG 100 SDZ',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: '40',
        packPrice: 186.9,
        dailyAverageDispensed: 1,
      },
      RAN: {
        _id: '6201ba21f65f11ad3d7922b4',
        DIN: '2423987',
        genericName: 'ESOMEPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'RAN',
        brandName: 'ESOMEPRAZOLE TB 40MG 100 RAN',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 186.9,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba21f65f11ad3d792450',
        DIN: '2244522',
        genericName: 'ESOMEPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'AST',
        brandName: 'NEXIUM TB 40MG BLST 4X7',
        form: 'TAB DEL RELEASE',
        packSize: '28',
        rebateValue: null,
        packPrice: 70.25,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79aab9',
        DIN: '2431173',
        genericName: 'ESOMEPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'SNS',
        brandName: 'ESOMEPRAZOLE TB 40MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 186.9,
        dailyAverageDispensed: 0,
      },
      SIV: {
        _id: '6201ba26f65f11ad3d79b0a3',
        DIN: '2442507',
        genericName: 'ESOMEPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'SIV',
        brandName: 'ESOMEPRAZOLE TB 40MG 30 SIVEM',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 59.16,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba24f65f11ad3d79886c',
        DIN: '2479427',
        genericName: 'ESOMEPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'MYL',
        brandName: 'ESOMEPRAZOLE TB 40MG 100 MYLAN',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: null,
        packPrice: 186.9,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '52898',
    genericName: 'ARIPIPRAZOLE TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 3.909,
    MFRs: {
      MPH: {
        _id: '6201ba26f65f11ad3d79ab09',
        DIN: '2483564',
        genericName: 'ARIPIPRAZOLE TABLET 5 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'ARIPIPRAZOLE TB 5MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 90.46,
        dailyAverageDispensed: 1,
      },
      APX: {
        _id: '6201ba23f65f11ad3d795a65',
        DIN: '2471094',
        genericName: 'ARIPIPRAZOLE TABLET 5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'ARIPIPRAZOLE TB 5MG 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 27.14,
        dailyAverageDispensed: 5,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d795eaf',
        DIN: '2466643',
        genericName: 'ARIPIPRAZOLE TABLET 5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'ARIPIPRAZOLE TB 5MG 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 27.14,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d795ea1',
        DIN: '2473666',
        genericName: 'ARIPIPRAZOLE TABLET 5 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'ARIPIPRAZOLE TB 5MG 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '63',
        packPrice: 27.14,
        dailyAverageDispensed: 0,
      },
      OTS: {
        _id: '6201ba27f65f11ad3d79cfbe',
        DIN: '2322382',
        genericName: 'ARIPIPRAZOLE TABLET 5 MG ORAL',
        supplierCode: 'OTS',
        brandName: 'ABILIFY TB 5MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 117.28,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d795e8b',
        DIN: '2460033',
        genericName: 'ARIPIPRAZOLE TABLET 5 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'ARIPIPRAZOLE TB 5MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 27.14,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79a40d',
        DIN: '2506718',
        genericName: 'ARIPIPRAZOLE TABLET 5 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'ARIPIPRAZOLE TB 5MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 90.46,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '53007',
    genericName: 'BUPROPION HCL TAB ER 24H 300 M',
    strength: '300MG',
    unitPrice: 1.248,
    MFRs: {
      RAN: {
        _id: '6201ba24f65f11ad3d797472',
        DIN: '2475812',
        genericName: 'BUPROPION HCL TAB ER 24H 300 M',
        supplierCode: 'RAN',
        brandName: 'BUPROPION XL TB 300MG 90 TARO',
        form: 'TABLET EXT REL',
        packSize: '90',
        rebateValue: '57',
        packPrice: 26.34,
        dailyAverageDispensed: 2,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d79348d',
        DIN: '2439662',
        genericName: 'BUPROPION HCL TAB ER 24H 300 M',
        supplierCode: 'TEV',
        brandName: 'BUPROPION XL TB 300MG 90 ACT',
        form: 'TABLET EXT REL',
        packSize: '90',
        rebateValue: '1',
        packPrice: 26.34,
        dailyAverageDispensed: 4,
      },
      BAU: {
        _id: '6201ba1ff65f11ad3d790195',
        DIN: '2275104',
        genericName: 'BUPROPION HCL TAB ER 24H 300 M',
        supplierCode: 'BAU',
        brandName: 'WELLBUTRIN XL TB 300MG 90',
        form: 'TABLET EXT REL',
        packSize: '90',
        rebateValue: null,
        packPrice: 112.36,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '60225',
    genericName: 'ARIPIPRAZOLE TABLET 2 MG ORAL',
    strength: '2MG',
    unitPrice: 3.497,
    MFRs: {
      MPH: {
        _id: '6201ba26f65f11ad3d79af7f',
        DIN: '2483556',
        genericName: 'ARIPIPRAZOLE TABLET 2 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'ARIPIPRAZOLE TB 2MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 80.92,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba23f65f11ad3d795a54',
        DIN: '2471086',
        genericName: 'ARIPIPRAZOLE TABLET 2 MG ORAL',
        supplierCode: 'APX',
        brandName: 'ARIPIPRAZOLE TB 2MG 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 24.28,
        dailyAverageDispensed: 6,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d795eae',
        DIN: '2466635',
        genericName: 'ARIPIPRAZOLE TABLET 2 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'ARIPIPRAZOLE TB 2MG 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 24.28,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d795ea0',
        DIN: '2473658',
        genericName: 'ARIPIPRAZOLE TABLET 2 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'ARIPIPRAZOLE TB 2MG 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '63',
        packPrice: 24.28,
        dailyAverageDispensed: 0,
      },
      OTS: {
        _id: '6201ba27f65f11ad3d79cfaa',
        DIN: '2322374',
        genericName: 'ARIPIPRAZOLE TABLET 2 MG ORAL',
        supplierCode: 'OTS',
        brandName: 'ABILIFY TB 2MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 104.9,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d795e94',
        DIN: '2460025',
        genericName: 'ARIPIPRAZOLE TABLET 2 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'ARIPIPRAZOLE TB 2MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 24.28,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79a40c',
        DIN: '2506688',
        genericName: 'ARIPIPRAZOLE TABLET 2 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'ARIPIPRAZOLE TB 2MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 80.92,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '64830',
    genericName: 'FEBUXOSTAT TABLET 80 MG ORAL',
    strength: '80MG',
    unitPrice: 1.352,
    MFRs: {
      TEV: {
        _id: '6201ba24f65f11ad3d797486',
        DIN: '2466198',
        genericName: 'FEBUXOSTAT TABLET 80 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'FEBUXOSTAT FC TB 80MG 100 TEVA',
        form: 'TAB FC',
        packSize: '100',
        rebateValue: '65',
        packPrice: 135.15,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba24f65f11ad3d798862',
        DIN: '2490870',
        genericName: 'FEBUXOSTAT TABLET 80 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'FEBUXOSTAT TB 80MG 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '30',
        packPrice: 40.55,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba24f65f11ad3d79749e',
        DIN: '2473607',
        genericName: 'FEBUXOSTAT TABLET 80 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'FEBUXOSTAT TB 80MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 135.15,
        dailyAverageDispensed: 6,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 6,
      total: 0,
    },
  },
  {
    groupCode: '301',
    genericName: 'TERAZOSIN HCL TABLET 1 MG ORAL',
    strength: '1MG',
    unitPrice: 0.184,
    MFRs: {
      TEV: {
        _id: '6201ba20f65f11ad3d790968',
        DIN: '2230805',
        genericName: 'TERAZOSIN HCL TABLET 1 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'TERAZOSIN TB 1MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 18.35,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba26f65f11ad3d79bcb1',
        DIN: '2243518',
        genericName: 'TERAZOSIN HCL TABLET 1 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'TERAZOSIN TB 1MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '45',
        packPrice: 18.35,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79ca0d',
        DIN: '2234502',
        genericName: 'TERAZOSIN HCL TABLET 1 MG ORAL',
        supplierCode: 'APX',
        brandName: 'TERAZOSIN TB 1MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 18.35,
        dailyAverageDispensed: 5,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 5,
      total: 0,
    },
  },
  {
    groupCode: '3757',
    genericName: 'LORAZEPAM TABLET 0.5 MG ORAL',
    strength: '0.5MG',
    unitPrice: 0.047,
    MFRs: {
      APX: {
        _id: '6201ba20f65f11ad3d790987',
        DIN: '655740',
        genericName: 'LORAZEPAM TABLET 0.5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'LORAZEPAM TB 0.5MG 500 APO',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '30',
        packPrice: 17.95,
        dailyAverageDispensed: 5,
      },
      TEV: {
        _id: '6201ba26f65f11ad3d79bbde',
        DIN: '711101',
        genericName: 'LORAZEPAM TABLET 0.5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'LORAZEPAM TB 0.5MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 3.59,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79cb4f',
        DIN: '728187',
        genericName: 'LORAZEPAM TABLET 0.5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'LORAZEPAM TB 0.5MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 3.59,
        dailyAverageDispensed: 0,
      },
      PFC: {
        _id: '6201ba26f65f11ad3d79be70',
        DIN: '2041413',
        genericName: 'LORAZEPAM TABLET 0.5 MG ORAL',
        supplierCode: 'PFC',
        brandName: 'ATIVAN TB 0.5MG 500',
        form: 'TABLET',
        packSize: '500',
        rebateValue: null,
        packPrice: 23.27,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 5,
      total: 0,
    },
  },
  {
    groupCode: '8362',
    genericName: 'NAPROXEN TABLET 500 MG ORAL',
    strength: '500MG',
    unitPrice: 0.224,
    MFRs: {
      APX: {
        _id: '6201ba28f65f11ad3d79dbd9',
        DIN: '592277',
        genericName: 'NAPROXEN TABLET 500 MG ORAL',
        supplierCode: 'APX',
        brandName: 'NAPROXEN TB 500MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 22.44,
        dailyAverageDispensed: 5,
      },
      TEV: {
        _id: '6201ba23f65f11ad3d7965a2',
        DIN: '589861',
        genericName: 'NAPROXEN TABLET 500 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'NAPROXEN TB 500MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 22.44,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f4cf',
        DIN: '2350777',
        genericName: 'NAPROXEN TABLET 500 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'NAPROXEN TB 500MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 22.44,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 5,
      total: 0,
    },
  },
  {
    groupCode: '11677',
    genericName: 'FAMOTIDINE TABLET 20 MG ORAL',
    strength: '20MG',
    unitPrice: 0.266,
    MFRs: {
      JAP: {
        _id: '6201ba26f65f11ad3d79add6',
        DIN: '2507749',
        genericName: 'FAMOTIDINE TABLET 20 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'FAMOTIDINE TB 20MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '30',
        packPrice: 26.58,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d792d19',
        DIN: '2022133',
        genericName: 'FAMOTIDINE TABLET 20 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'FAMOTIDINE TB 20MG 100 NOVO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 26.58,
        dailyAverageDispensed: 5,
      },
      JJC: {
        _id: '6201ba25f65f11ad3d798fc8',
        DIN: '2273357',
        genericName: 'FAMOTIDINE TABLET 20 MG ORAL',
        supplierCode: 'JJC',
        brandName: 'PEPCID AC MAXIMUM STRENGTH TB 20MG 10',
        form: 'TABLET',
        packSize: '10',
        rebateValue: null,
        packPrice: 6.2,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 5,
      total: 0,
    },
  },
  {
    groupCode: '11682',
    genericName: 'AZATHIOPRINE TABLET 50 MG ORAL',
    strength: '50MG',
    unitPrice: 0.241,
    MFRs: {
      APX: {
        _id: '6201ba20f65f11ad3d790c72',
        DIN: '2242907',
        genericName: 'AZATHIOPRINE TABLET 50 MG ORAL',
        supplierCode: 'APX',
        brandName: 'AZATHIOPRINE TB 50MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 24.05,
        dailyAverageDispensed: 5,
      },
      TEV: {
        _id: '6201ba26f65f11ad3d79b8de',
        DIN: '2236819',
        genericName: 'AZATHIOPRINE TABLET 50 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'AZATHIOPRINE TB 50MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 24.05,
        dailyAverageDispensed: 0,
      },
      APE: {
        _id: '6201ba1ff65f11ad3d78f539',
        DIN: '4596',
        genericName: 'AZATHIOPRINE TABLET 50 MG ORAL',
        supplierCode: 'APE',
        brandName: 'IMURAN TB 50MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 122.28,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 5,
      total: 0,
    },
  },
  {
    groupCode: '16366',
    genericName: 'PRAVASTATIN SODIUM TABLET 10 M',
    strength: '10MG',
    unitPrice: 0.292,
    MFRs: {
      APX: {
        _id: '6201ba26f65f11ad3d79bb13',
        DIN: '2243506',
        genericName: 'PRAVASTATIN SODIUM TABLET 10 M',
        supplierCode: 'APX',
        brandName: 'PRAVASTATIN TB 10MG UD 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '69',
        packPrice: 8.75,
        dailyAverageDispensed: 5,
      },
      RAN: {
        _id: '6201ba27f65f11ad3d79c959',
        DIN: '2284421',
        genericName: 'PRAVASTATIN SODIUM TABLET 10 M',
        supplierCode: 'RAN',
        brandName: 'PRAVASTATIN TB 10MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '67',
        packPrice: 29.16,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba24f65f11ad3d797c0a',
        DIN: '2440644',
        genericName: 'PRAVASTATIN SODIUM TABLET 10 M',
        supplierCode: 'AHC',
        brandName: 'PRAVASTATIN TB 10MG 100 ACH',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '66',
        packPrice: 29.16,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d795f67',
        DIN: '2247655',
        genericName: 'PRAVASTATIN SODIUM TABLET 10 M',
        supplierCode: 'PMS',
        brandName: 'PRAVASTATIN TB 10MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 29.16,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba28f65f11ad3d79de97',
        DIN: '2317451',
        genericName: 'PRAVASTATIN SODIUM TABLET 10 M',
        supplierCode: 'MPH',
        brandName: 'PRAVASTATIN TB 10MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 29.16,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d79397d',
        DIN: '2330954',
        genericName: 'PRAVASTATIN SODIUM TABLET 10 M',
        supplierCode: 'JAP',
        brandName: 'PRAVASTATIN TB 10MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 29.16,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d795e0a',
        DIN: '2468700',
        genericName: 'PRAVASTATIN SODIUM TABLET 10 M',
        supplierCode: 'SDZ',
        brandName: 'PRAVASTATIN TB 10MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 29.16,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c22a',
        DIN: '2247008',
        genericName: 'PRAVASTATIN SODIUM TABLET 10 M',
        supplierCode: 'TEV',
        brandName: 'PRAVASTATIN TB 10MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 8.75,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d790491',
        DIN: '2356546',
        genericName: 'PRAVASTATIN SODIUM TABLET 10 M',
        supplierCode: 'SNS',
        brandName: 'PRAVASTATIN SOD TB 10MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 29.16,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d795ec0',
        DIN: '2458977',
        genericName: 'PRAVASTATIN SODIUM TABLET 10 M',
        supplierCode: 'AUP',
        brandName: 'PRAVASTATIN TB 10MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 29.16,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 5,
      total: 0,
    },
  },
  {
    groupCode: '16570',
    genericName: 'DILTIAZEM HCL CAP ER 24H 180 M',
    strength: '180MG',
    unitPrice: 0.482,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79c969',
        DIN: '2230998',
        genericName: 'DILTIAZEM HCL CAP ER 24H 180 M',
        supplierCode: 'APX',
        brandName: 'DILTIAZ CD CAPS 180MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 48.24,
        dailyAverageDispensed: 5,
      },
      TEV: {
        _id: '6201ba28f65f11ad3d79e06e',
        DIN: '2242539',
        genericName: 'DILTIAZEM HCL CAP ER 24H 180 M',
        supplierCode: 'TEV',
        brandName: 'DILTIAZEM HCL CD CAPS 180MG 100 TEVA',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 48.24,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba24f65f11ad3d798987',
        DIN: '2484072',
        genericName: 'DILTIAZEM HCL CAP ER 24H 180 M',
        supplierCode: 'MRC',
        brandName: 'DILTIAZEM CD CAPS 180MG 100 MAR',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 48.24,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 5,
      total: 0,
    },
  },
  {
    groupCode: '21415',
    genericName: 'GABAPENTIN CAPSULE 400 MG ORAL',
    strength: '400MG',
    unitPrice: 1.443,
    MFRs: {
      AHC: {
        _id: '6201ba24f65f11ad3d797309',
        DIN: '2416867',
        genericName: 'GABAPENTIN CAPSULE 400 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'GABAPENTIN CAPS 400MG 100 ACH',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '68',
        packPrice: 12.06,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79cc19',
        DIN: '2244515',
        genericName: 'GABAPENTIN CAPSULE 400 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'GABAPENTIN CAPS 400MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '60',
        packPrice: 12.06,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba26f65f11ad3d79b624',
        DIN: '2243448',
        genericName: 'GABAPENTIN CAPSULE 400 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'GABAPENTIN CAPS 400MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '45',
        packPrice: 12.06,
        dailyAverageDispensed: 5,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c10a',
        DIN: '2244306',
        genericName: 'GABAPENTIN CAPSULE 400 MG ORAL',
        supplierCode: 'APX',
        brandName: 'GABAPENTIN CAPS 400MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '45',
        packPrice: 12.06,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d78febf',
        DIN: '2361493',
        genericName: 'GABAPENTIN CAPSULE 400 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'GABAPENTIN CAPS 400MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '40',
        packPrice: 12.06,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba27f65f11ad3d79d4a8',
        DIN: '2084287',
        genericName: 'GABAPENTIN CAPSULE 400 MG ORAL',
        supplierCode: 'UPJ',
        brandName: 'NEURONTIN CAPS 400MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 144.27,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba1ff65f11ad3d78fb3b',
        DIN: '2321238',
        genericName: 'GABAPENTIN CAPSULE 400 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'GABAPENTIN CAPS 400MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 12.06,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d791822',
        DIN: '2353261',
        genericName: 'GABAPENTIN CAPSULE 400 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'GABAPENTIN CAPS 400MG 500 SNS',
        form: 'CAPSULE',
        packSize: '500',
        rebateValue: null,
        packPrice: 60.3,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 5,
      total: 0,
    },
  },
  {
    groupCode: '23989',
    genericName: 'VALACYCLOVIR HCL TABLET 500 MG',
    strength: '500MG',
    unitPrice: 3.873,
    MFRs: {
      PMS: {
        _id: '6201ba27f65f11ad3d79c9ae',
        DIN: '2298457',
        genericName: 'VALACYCLOVIR HCL TABLET 500 MG',
        supplierCode: 'PMS',
        brandName: 'VALACYCLOVIR TB 500MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '52',
        packPrice: 61.98,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79d3ca',
        DIN: '2295822',
        genericName: 'VALACYCLOVIR HCL TABLET 500 MG',
        supplierCode: 'APX',
        brandName: 'VALACYCLOVIR TB 500MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 61.98,
        dailyAverageDispensed: 5,
      },
      SDZ: {
        _id: '6201ba21f65f11ad3d792a1c',
        DIN: '2347091',
        genericName: 'VALACYCLOVIR HCL TABLET 500 MG',
        supplierCode: 'SDZ',
        brandName: 'VALACYCLOVIR TB 500MG 90 SDZ',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '42',
        packPrice: 55.78,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d792a2b',
        DIN: '2357534',
        genericName: 'VALACYCLOVIR HCL TABLET 500 MG',
        supplierCode: 'TEV',
        brandName: 'VALACYCLOVIR TB 500MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 61.98,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba26f65f11ad3d79a4fc',
        DIN: '2440598',
        genericName: 'VALACYCLOVIR HCL TABLET 500 MG',
        supplierCode: 'JAP',
        brandName: 'VALACYCLOVIR TB 500MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 61.98,
        dailyAverageDispensed: 0,
      },
      GSK: {
        _id: '6201ba28f65f11ad3d79dd8d',
        DIN: '2219492',
        genericName: 'VALACYCLOVIR HCL TABLET 500 MG',
        supplierCode: 'GSK',
        brandName: 'VALTREX CPLT 500MG BLS 3X10',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 116.19,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba28f65f11ad3d79e0a9',
        DIN: '2351579',
        genericName: 'VALACYCLOVIR HCL TABLET 500 MG',
        supplierCode: 'MYL',
        brandName: 'VALACYCLOVIR TB 500MG 100 MYLAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 61.98,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d792a3f',
        DIN: '2405040',
        genericName: 'VALACYCLOVIR HCL TABLET 500 MG',
        supplierCode: 'AUP',
        brandName: 'VALACYCLOVIR TB 500MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 61.98,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba22f65f11ad3d793a24',
        DIN: '2454645',
        genericName: 'VALACYCLOVIR HCL TABLET 500 MG',
        supplierCode: 'SNS',
        brandName: 'VALACYCLOVIR HCL CAPS 500MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 61.98,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 5,
      total: 0,
    },
  },
  {
    groupCode: '28108',
    genericName: 'CARVEDILOL TABLET 3.125 MG ORA',
    strength: '3.125MG',
    unitPrice: 0.243,
    MFRs: {
      PMS: {
        _id: '6201ba27f65f11ad3d79c908',
        DIN: '2245914',
        genericName: 'CARVEDILOL TABLET 3.125 MG ORA',
        supplierCode: 'PMS',
        brandName: 'CARVEDILOL TB 3.125MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 24.31,
        dailyAverageDispensed: 5,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79caea',
        DIN: '2247933',
        genericName: 'CARVEDILOL TABLET 3.125 MG ORA',
        supplierCode: 'APX',
        brandName: 'CARVEDILOL TB 3.125MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '54',
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79cfa7',
        DIN: '2252309',
        genericName: 'CARVEDILOL TABLET 3.125 MG ORA',
        supplierCode: 'TEV',
        brandName: 'CARVEDILOL TB 3.125MG 100 RATIO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '15',
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d791681',
        DIN: '2364913',
        genericName: 'CARVEDILOL TABLET 3.125 MG ORA',
        supplierCode: 'SNS',
        brandName: 'CARVEDILOL TB 3.125MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d790101',
        DIN: '2368897',
        genericName: 'CARVEDILOL TABLET 3.125 MG ORA',
        supplierCode: 'JAP',
        brandName: 'CARVEDILOL TB 3.125MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '0',
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d791b29',
        DIN: '2418495',
        genericName: 'CARVEDILOL TABLET 3.125 MG ORA',
        supplierCode: 'AUP',
        brandName: 'CARVEDILOL TB 3.125MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 5,
      total: 0,
    },
  },
  {
    groupCode: '29821',
    genericName: 'LETROZOLE TABLET 2.5 MG ORAL',
    strength: '2.5MG',
    unitPrice: 8.055,
    MFRs: {
      APX: {
        _id: '6201ba25f65f11ad3d7999a3',
        DIN: '2358514',
        genericName: 'LETROZOLE TABLET 2.5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'LETROZOLE TB 2.5MG BLST 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '69',
        packPrice: 41.34,
        dailyAverageDispensed: 5,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79c3bd',
        DIN: '2309114',
        genericName: 'LETROZOLE TABLET 2.5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'LETROZOLE TB 2.5MG BLS 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '66',
        packPrice: 41.34,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba27f65f11ad3d79d5a7',
        DIN: '2338459',
        genericName: 'LETROZOLE TABLET 2.5 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'LETROZOLE TB USP 2.5MG BLS 30 ACH',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '66',
        packPrice: 41.34,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba27f65f11ad3d79bef4',
        DIN: '2344815',
        genericName: 'LETROZOLE TABLET 2.5 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'LETROZOLE TB 2.5MG PLQ 3X10 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 41.34,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba1ff65f11ad3d79048a',
        DIN: '2373424',
        genericName: 'LETROZOLE TABLET 2.5 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'LETROZOLE TB 2.5MG BLST 30 MAR',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 41.34,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d78feac',
        DIN: '2373009',
        genericName: 'LETROZOLE TABLET 2.5 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'LETROZOLE TB 2.5MG UD 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 41.34,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d790ee3',
        DIN: '2343657',
        genericName: 'LETROZOLE TABLET 2.5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'LETROZOLE TB 2.5MG BLS 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 41.34,
        dailyAverageDispensed: 0,
      },
      NPC: {
        _id: '6201ba1ff65f11ad3d790657',
        DIN: '2231384',
        genericName: 'LETROZOLE TABLET 2.5 MG ORAL',
        supplierCode: 'NPC',
        brandName: 'FEMARA TB 2.5MG BLS 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 241.65,
        dailyAverageDispensed: 0,
      },
      NTC: {
        _id: '6201ba21f65f11ad3d7924b7',
        DIN: '2421585',
        genericName: 'LETROZOLE TABLET 2.5 MG ORAL',
        supplierCode: 'NTC',
        brandName: 'LETROZOLE TB 2.5MG 30 NAT',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 41.34,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba25f65f11ad3d79a0d2',
        DIN: '2504472',
        genericName: 'LETROZOLE TABLET 2.5 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'LETROZOLE TB 2.5MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 41.34,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba26f65f11ad3d79a8d0',
        DIN: '2508109',
        genericName: 'LETROZOLE TABLET 2.5 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'LETROZOLE TB 2.5MGMG BLST 30 MINT',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '0',
        packPrice: 41.34,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 5,
      total: 0,
    },
  },
  {
    groupCode: '41832',
    genericName: 'TACROLIMUS CAPSULE 0.5 MG ORAL',
    strength: '0.5MG',
    unitPrice: 2.141,
    MFRs: {
      SDZ: {
        _id: '6201ba20f65f11ad3d791398',
        DIN: '2416816',
        genericName: 'TACROLIMUS CAPSULE 0.5 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'TACROLIMUS CAPS 0.5MG 100 SDZ',
        form: 'CAPS IR',
        packSize: '100',
        rebateValue: '1',
        packPrice: 147.75,
        dailyAverageDispensed: 5,
      },
      AHC: {
        _id: '6201ba23f65f11ad3d796e97',
        DIN: '2454068',
        genericName: 'TACROLIMUS CAPSULE 0.5 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'TACROLIMUS CAPS 0.5MG 100 ACH',
        form: 'CAPS IR',
        packSize: '100',
        rebateValue: '1',
        packPrice: 101.46,
        dailyAverageDispensed: 0,
      },
      ASP: {
        _id: '6201ba27f65f11ad3d79c9b4',
        DIN: '2243144',
        genericName: 'TACROLIMUS CAPSULE 0.5 MG ORAL',
        supplierCode: 'ASP',
        brandName: 'PROGRAF CAPS 0.5MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 214.07,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 5,
      total: 0,
    },
  },
  {
    groupCode: '45425',
    genericName: 'CANDESARTAN/HYDROCHLOROTHIAZID',
    strength: '16MG+12.5M',
    unitPrice: 1.482,
    MFRs: {
      SDZ: {
        _id: '6201ba1ff65f11ad3d79053d',
        DIN: '2327902',
        genericName: 'CANDESARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'SDZ',
        brandName: 'CANDESARTAN PLUS TB 16/12.5MG BLS 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '55',
        packPrice: 6.47,
        dailyAverageDispensed: 1,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d79054e',
        DIN: '2391295',
        genericName: 'CANDESARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'PMS',
        brandName: 'CANDESARTAN HCTZ TB 16/12.5MG BLS 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '35',
        packPrice: 6.47,
        dailyAverageDispensed: 3,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d790e14',
        DIN: '2395541',
        genericName: 'CANDESARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'TEV',
        brandName: 'CANDESARTAN HCTZ TB 16/12.5MG 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '25',
        packPrice: 6.47,
        dailyAverageDispensed: 1,
      },
      JAP: {
        _id: '6201ba24f65f11ad3d79744d',
        DIN: '2473240',
        genericName: 'CANDESARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'JAP',
        brandName: 'CANDESARTAN HCT TB 16/12.5MG BLST 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 6.47,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba27f65f11ad3d79c8b8',
        DIN: '2244021',
        genericName: 'CANDESARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'AST',
        brandName: 'ATACAND PLUS TB 16MG+12.5MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 44.45,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d790841',
        DIN: '2394804',
        genericName: 'CANDESARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'SNS',
        brandName: 'CANDESARTAN/HCT TB 16/12.5MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 21.56,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d7931ac',
        DIN: '2421038',
        genericName: 'CANDESARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'AUP',
        brandName: 'CANDESARTAN HCT TB 16/12.5MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 21.56,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 5,
      total: 0,
    },
  },
  {
    groupCode: '46525',
    genericName: 'BUDESONIDE AMPUL-NEB 0.25MG/2M',
    strength: '0.125MG/ML',
    unitPrice: 5.045,
    MFRs: {
      TEV: {
        _id: '6201ba22f65f11ad3d795410',
        DIN: '2465949',
        genericName: 'BUDESONIDE AMPUL-NEB 0.25MG/2M',
        supplierCode: 'TEV',
        brandName: 'BUDESONIDE 0.125MG/ML 20X2ML TEVA',
        form: 'SUSPENSION',
        packSize: '2',
        rebateValue: '1',
        packPrice: 4.57,
        dailyAverageDispensed: 5,
      },
      TAR: {
        _id: '6201ba25f65f11ad3d79929f',
        DIN: '2494264',
        genericName: 'BUDESONIDE AMPUL-NEB 0.25MG/2M',
        supplierCode: 'TAR',
        brandName: 'BUDESONIDE 0.125MG/ML 20X2ML TARO',
        form: 'SUSPENSION',
        packSize: '2',
        rebateValue: '1',
        packPrice: 4.57,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba27f65f11ad3d79ce94',
        DIN: '2229099',
        genericName: 'BUDESONIDE AMPUL-NEB 0.25MG/2M',
        supplierCode: 'AST',
        brandName: 'PULMICORT NEBUAMP 0.125MG/ML 20X2ML',
        form: 'SUSPENSION',
        packSize: '2',
        rebateValue: null,
        packPrice: 10.09,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 5,
      total: 0,
    },
  },
  {
    groupCode: '47324',
    genericName: 'TELMISARTAN/HYDROCHLOROTHIAZID',
    strength: '80/12.5MG',
    unitPrice: 1.316,
    MFRs: {
      AHC: {
        _id: '6201ba22f65f11ad3d7953e7',
        DIN: '2419114',
        genericName: 'TELMISARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'AHC',
        brandName: 'TELMISARTAN HCTZ TB 80/12.5MG 100 ACH',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '74',
        packPrice: 20.98,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba25f65f11ad3d799b4a',
        DIN: '2389940',
        genericName: 'TELMISARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'JAP',
        brandName: 'TELMISARTAN HCT TB 80/12.5MG UD BLST 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '55',
        packPrice: 6.29,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d79061d',
        DIN: '2393557',
        genericName: 'TELMISARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'SDZ',
        brandName: 'TELMISARTAN HCTZ TB 80/12.5MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '35',
        packPrice: 20.98,
        dailyAverageDispensed: 5,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d79009b',
        DIN: '2330288',
        genericName: 'TELMISARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'TEV',
        brandName: 'TELMISARTAN HCTZ TB 80/12.5MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '25',
        packPrice: 6.29,
        dailyAverageDispensed: 0,
      },
      BOE: {
        _id: '6201ba27f65f11ad3d79d54c',
        DIN: '2244344',
        genericName: 'TELMISARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'BOE',
        brandName: 'MICARDIS PLUS TB 80MG/12.5MG BLST 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 36.86,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d790842',
        DIN: '2395355',
        genericName: 'TELMISARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'SNS',
        brandName: 'TELMISARTAN HCT TB 80/12.5MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 20.98,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d794589',
        DIN: '2456389',
        genericName: 'TELMISARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'AUP',
        brandName: 'TELMISARTAN HCT TB 80/12.5MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 20.98,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 5,
      total: 0,
    },
  },
  {
    groupCode: '304',
    genericName: 'TERAZOSIN HCL TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 0.317,
    MFRs: {
      TEV: {
        _id: '6201ba20f65f11ad3d790a0d',
        DIN: '2230807',
        genericName: 'TERAZOSIN HCL TABLET 5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'TERAZOSIN TB 5MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 31.68,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba26f65f11ad3d79bcb4',
        DIN: '2243520',
        genericName: 'TERAZOSIN HCL TABLET 5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'TERAZOSIN TB 5MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '45',
        packPrice: 31.68,
        dailyAverageDispensed: 2,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c278',
        DIN: '2234504',
        genericName: 'TERAZOSIN HCL TABLET 5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'TERAZOSIN TB 5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 31.68,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '391',
    genericName: 'LISINOPRIL TABLET 20 MG ORAL',
    strength: '20MG',
    unitPrice: 0.879,
    MFRs: {
      TEV: {
        _id: '6201ba27f65f11ad3d79cc5c',
        DIN: '2285134',
        genericName: 'LISINOPRIL TABLET 20 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'LISINOPRIL Z TB 20MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 19.45,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c4c0',
        DIN: '2217511',
        genericName: 'LISINOPRIL TABLET 20 MG ORAL',
        supplierCode: 'APX',
        brandName: 'LISINOPRIL TB 20MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 19.45,
        dailyAverageDispensed: 4,
      },
      SGP: {
        _id: '6201ba27f65f11ad3d79c056',
        DIN: '2049384',
        genericName: 'LISINOPRIL TABLET 20 MG ORAL',
        supplierCode: 'SGP',
        brandName: 'ZESTRIL TB 20MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 87.89,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790d16',
        DIN: '2394499',
        genericName: 'LISINOPRIL TABLET 20 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'LISINOPRIL TB 20MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 19.45,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '4536',
    genericName: 'VALPROIC ACID CAPSULE 250 MG O',
    strength: '250MG',
    unitPrice: 0.291,
    MFRs: {
      PMS: {
        _id: '6201ba27f65f11ad3d79cff8',
        DIN: '2230768',
        genericName: 'VALPROIC ACID CAPSULE 250 MG O',
        supplierCode: 'PMS',
        brandName: 'VALPROIC ACID CAPS 250MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '15',
        packPrice: 29.05,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79b720',
        DIN: '2238048',
        genericName: 'VALPROIC ACID CAPSULE 250 MG O',
        supplierCode: 'APX',
        brandName: 'VALPROIC CAPS 250MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '10',
        packPrice: 29.05,
        dailyAverageDispensed: 4,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '4558',
    genericName: 'CARBAMAZEPINE TABLET 200 MG OR',
    strength: '200MG',
    unitPrice: 0.552,
    MFRs: {
      TAR: {
        _id: '6201ba20f65f11ad3d791d58',
        DIN: '2407515',
        genericName: 'CARBAMAZEPINE TABLET 200 MG OR',
        supplierCode: 'TAR',
        brandName: 'CARBAMAZEPINE IR TB 200MG 100 TARO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '52',
        packPrice: 15.4,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79cd86',
        DIN: '782718',
        genericName: 'CARBAMAZEPINE TABLET 200 MG OR',
        supplierCode: 'TEV',
        brandName: 'CARBAMAZEPINE TB 200MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 15.4,
        dailyAverageDispensed: 4,
      },
      NPC: {
        _id: '6201ba27f65f11ad3d79d7a7',
        DIN: '10405',
        genericName: 'CARBAMAZEPINE TABLET 200 MG OR',
        supplierCode: 'NPC',
        brandName: 'TEGRETOL TB 200MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 55.15,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '8227',
    genericName: 'AMILORIDE HCL TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 0.327,
    MFRs: {
      AAA: {
        _id: '6201ba1ff65f11ad3d78f74b',
        DIN: '2249510',
        genericName: 'AMILORIDE HCL TABLET 5 MG ORAL',
        supplierCode: 'AAA',
        brandName: 'MIDAMOR TB 5MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 32.74,
        dailyAverageDispensed: 4,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '8996',
    genericName: 'AMOXICILLIN CAPSULE 500 MG ORA',
    strength: '500MG',
    unitPrice: 0.342,
    MFRs: {
      JAP: {
        _id: '6201ba21f65f11ad3d79292e',
        DIN: '2433079',
        genericName: 'AMOXICILLIN CAPSULE 500 MG ORA',
        supplierCode: 'JAP',
        brandName: 'AMOXICILLIN CAPS 500MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '55',
        packPrice: 13.08,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba26f65f11ad3d79bbb9',
        DIN: '2230244',
        genericName: 'AMOXICILLIN CAPSULE 500 MG ORA',
        supplierCode: 'PMS',
        brandName: 'AMOXICILLIN CAPS 500MG 500 PMS',
        form: 'CAPSULE',
        packSize: '500',
        rebateValue: '50',
        packPrice: 170.85,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79bf60',
        DIN: '628123',
        genericName: 'AMOXICILLIN CAPSULE 500 MG ORA',
        supplierCode: 'APX',
        brandName: 'AMOXI CAPS 500MG 500 APO',
        form: 'CAPSULE',
        packSize: '500',
        rebateValue: '40',
        packPrice: 65.4,
        dailyAverageDispensed: 4,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79ce3b',
        DIN: '406716',
        genericName: 'AMOXICILLIN CAPSULE 500 MG ORA',
        supplierCode: 'TEV',
        brandName: 'AMOXICILLIN CAPS 500MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 13.08,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f4b9',
        DIN: '2352729',
        genericName: 'AMOXICILLIN CAPSULE 500 MG ORA',
        supplierCode: 'SNS',
        brandName: 'AMOXICILLIN CAPS 500MG 100 SNS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 13.08,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790b34',
        DIN: '2388081',
        genericName: 'AMOXICILLIN CAPSULE 500 MG ORA',
        supplierCode: 'AUP',
        brandName: 'AMOXICILLIN CAPS 500MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 13.08,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '16579',
    genericName: 'SIMVASTATIN TABLET 40 MG ORAL',
    strength: '40MG',
    unitPrice: 0.25,
    MFRs: {
      RAN: {
        _id: '6201ba27f65f11ad3d79c02d',
        DIN: '2329174',
        genericName: 'SIMVASTATIN TABLET 40 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'SIMVASTATIN TB 40MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 25.01,
        dailyAverageDispensed: 3,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c3d8',
        DIN: '2247014',
        genericName: 'SIMVASTATIN TABLET 40 MG ORAL',
        supplierCode: 'APX',
        brandName: 'SIMVASTATIN TB 40MG BLS 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '67',
        packPrice: 7.5,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba1ff65f11ad3d78fc73',
        DIN: '2372967',
        genericName: 'SIMVASTATIN TABLET 40 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'SIMVASTATIN TB 40MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 25.01,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba27f65f11ad3d79cbd2',
        DIN: '2375621',
        genericName: 'SIMVASTATIN TABLET 40 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'SIMVASTATIN TB 40MG 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 7.5,
        dailyAverageDispensed: 1,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d7956a8',
        DIN: '2470004',
        genericName: 'SIMVASTATIN TABLET 40 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'SIMVASTATIN TB 40MG 100 PHARMA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 25.01,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d314',
        DIN: '2250179',
        genericName: 'SIMVASTATIN TABLET 40 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'SIMVASTATIN TB 40MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '35',
        packPrice: 7.5,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba25f65f11ad3d79a030',
        DIN: '2284766',
        genericName: 'SIMVASTATIN TABLET 40 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'SIMVASTATIN TB 40MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 25.01,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d79193c',
        DIN: '2405172',
        genericName: 'SIMVASTATIN TABLET 40 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'SIMVASTATIN TB 40MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 25.01,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba27f65f11ad3d79c097',
        DIN: '884359',
        genericName: 'SIMVASTATIN TABLET 40 MG ORAL',
        supplierCode: 'ORN',
        brandName: 'ZOCOR TB 40MG BLST PK28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 98.02,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '17026',
    genericName: 'CLOBAZAM TABLET 10 MG ORAL',
    strength: '10MG',
    unitPrice: 0.22,
    MFRs: {
      TEV: {
        _id: '6201ba26f65f11ad3d79bd16',
        DIN: '2238334',
        genericName: 'CLOBAZAM TABLET 10 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'CLOBAZAM TB 10MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 6.59,
        dailyAverageDispensed: 4,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79cb9a',
        DIN: '2244638',
        genericName: 'CLOBAZAM TABLET 10 MG ORAL',
        supplierCode: 'APX',
        brandName: 'CLOBAZAM TB 10MG BLS 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 6.59,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '17027',
    genericName: 'GLICLAZIDE TABLET 80 MG ORAL',
    strength: '80MG',
    unitPrice: 0.093,
    MFRs: {
      TEV: {
        _id: '6201ba26f65f11ad3d79b93c',
        DIN: '2238103',
        genericName: 'GLICLAZIDE TABLET 80 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'GLICLAZIDE TB 80MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 9.31,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79cf38',
        DIN: '2245247',
        genericName: 'GLICLAZIDE TABLET 80 MG ORAL',
        supplierCode: 'APX',
        brandName: 'GLICLAZIDE TB 80MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 9.31,
        dailyAverageDispensed: 4,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d79198b',
        DIN: '2287072',
        genericName: 'GLICLAZIDE TABLET 80 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'GLICLAZIDE TB 80MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 9.31,
        dailyAverageDispensed: 0,
      },
      SEV: {
        _id: '6201ba21f65f11ad3d793907',
        DIN: '765996',
        genericName: 'GLICLAZIDE TABLET 80 MG ORAL',
        supplierCode: 'SEV',
        brandName: 'DIAMICRON TB 80MG 60',
        form: 'TABLET',
        packSize: '60',
        rebateValue: null,
        packPrice: 24.87,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '17196',
    genericName: 'SOTALOL HCL TABLET 80 MG ORAL',
    strength: '80MG',
    unitPrice: 0.297,
    MFRs: {
      PMS: {
        _id: '6201ba28f65f11ad3d79e007',
        DIN: '2238326',
        genericName: 'SOTALOL HCL TABLET 80 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'SOTALOL TB 80MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '35',
        packPrice: 29.66,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba1ff65f11ad3d78fe47',
        DIN: '2210428',
        genericName: 'SOTALOL HCL TABLET 80 MG ORAL',
        supplierCode: 'APX',
        brandName: 'SOTALOL TB 80MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 29.66,
        dailyAverageDispensed: 4,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '17273',
    genericName: 'CILAZAPRIL TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 1.053,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79c172',
        DIN: '2291150',
        genericName: 'CILAZAPRIL TABLET 5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'CILAZAPRIL TB 5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 49.89,
        dailyAverageDispensed: 3,
      },
      XPT: {
        _id: '6201ba26f65f11ad3d79ac71',
        DIN: '1911481',
        genericName: 'CILAZAPRIL TABLET 5 MG ORAL',
        supplierCode: 'XPT',
        brandName: 'INHIBACE TB 5MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 105.27,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba26f65f11ad3d79b9c3',
        DIN: '2283794',
        genericName: 'CILAZAPRIL TABLET 5 MG ORAL',
        supplierCode: 'MYL',
        brandName: 'CILAZAPRIL TB 5MG 100 MYLAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 49.89,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '20279',
    genericName: 'DICLOFENAC SOD/MISOPROSTOL TAB',
    strength: '50MG',
    unitPrice: 0.748,
    MFRs: {
      PMS: {
        _id: '6201ba23f65f11ad3d796b2a',
        DIN: '2413469',
        genericName: 'DICLOFENAC SOD/MISOPROSTOL TAB',
        supplierCode: 'PMS',
        brandName: 'DICLOFENAC MISOPROSTOL TB 50MG/200MCG 250 PMS',
        form: 'TABLET',
        packSize: '250',
        rebateValue: '50',
        packPrice: 78.73,
        dailyAverageDispensed: 2,
      },
      PFC: {
        _id: '6201ba28f65f11ad3d79def0',
        DIN: '1917056',
        genericName: 'DICLOFENAC SOD/MISOPROSTOL TAB',
        supplierCode: 'PFC',
        brandName: 'ARTHROTEC TB 50MG 250',
        form: 'TAB DEL RELEASE',
        packSize: '250',
        rebateValue: null,
        packPrice: 187.11,
        dailyAverageDispensed: 0,
      },
      PFI: {
        _id: '6201ba20f65f11ad3d7919f2',
        DIN: '2341689',
        genericName: 'DICLOFENAC SOD/MISOPROSTOL TAB',
        supplierCode: 'PFI',
        brandName: 'DICLOFENAC MISOPROSTA TB 50MG 250 GD',
        form: 'TAB DEL RELEASE',
        packSize: '250',
        rebateValue: null,
        packPrice: 78.73,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '21694',
    genericName: 'FLUVASTATIN SODIUM CAPSULE 20',
    strength: '20MG',
    unitPrice: 0.688,
    MFRs: {
      TEV: {
        _id: '6201ba20f65f11ad3d790846',
        DIN: '2299224',
        genericName: 'FLUVASTATIN SODIUM CAPSULE 20',
        supplierCode: 'TEV',
        brandName: 'FLUVASTATIN CAPS 20MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 68.82,
        dailyAverageDispensed: 4,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '21731',
    genericName: 'PILOCARPINE HCL TABLET 5 MG OR',
    strength: '5MG',
    unitPrice: 1.235,
    MFRs: {
      JAP: {
        _id: '6201ba26f65f11ad3d79a53a',
        DIN: '2509571',
        genericName: 'PILOCARPINE HCL TABLET 5 MG OR',
        supplierCode: 'JAP',
        brandName: 'PILOCARPINE TB 5MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 117.08,
        dailyAverageDispensed: 0,
      },
      MPA: {
        _id: '6201ba25f65f11ad3d7992fd',
        DIN: '2216345',
        genericName: 'PILOCARPINE HCL TABLET 5 MG OR',
        supplierCode: 'MPA',
        brandName: 'SALAGEN TB 5MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 123.52,
        dailyAverageDispensed: 0,
      },
      MTP: {
        _id: '6201ba26f65f11ad3d79a761',
        DIN: '2496119',
        genericName: 'PILOCARPINE HCL TABLET 5 MG OR',
        supplierCode: 'MTP',
        brandName: 'M-PILOCARPINE TB 5MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 117.08,
        dailyAverageDispensed: 4,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '24505',
    genericName: 'OXYCODONE HCL TAB ER 12H 20 MG',
    strength: '20MG',
    unitPrice: 0.711,
    MFRs: {
      PMS: {
        _id: '6201ba20f65f11ad3d7907dc',
        DIN: '2309890',
        genericName: 'OXYCODONE HCL TAB ER 12H 20 MG',
        supplierCode: 'PMS',
        brandName: 'OXYCODONE CR TB 20MG 100 PMS',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '55',
        packPrice: 71.12,
        dailyAverageDispensed: 4,
      },
      APX: {
        _id: '6201ba20f65f11ad3d7907b5',
        DIN: '2366762',
        genericName: 'OXYCODONE HCL TAB ER 12H 20 MG',
        supplierCode: 'APX',
        brandName: 'OXYCODONE CR TB 20MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '51',
        packPrice: 71.12,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '29334',
    genericName: 'DONEPEZIL HCL TABLET 10 MG ORA',
    strength: '10MG',
    unitPrice: 5.471,
    MFRs: {
      APX: {
        _id: '6201ba20f65f11ad3d791376',
        DIN: '2362279',
        genericName: 'DONEPEZIL HCL TABLET 10 MG ORA',
        supplierCode: 'APX',
        brandName: 'DONEPEZIL TB 10MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '72',
        packPrice: 45.86,
        dailyAverageDispensed: 2,
      },
      MPH: {
        _id: '6201ba24f65f11ad3d797729',
        DIN: '2408619',
        genericName: 'DONEPEZIL HCL TABLET 10 MG ORA',
        supplierCode: 'MPH',
        brandName: 'DONEPEZIL TB 10MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 45.86,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba20f65f11ad3d7913ae',
        DIN: '2416956',
        genericName: 'DONEPEZIL HCL TABLET 10 MG ORA',
        supplierCode: 'JAP',
        brandName: 'DONEPEZIL TB 10MG UD 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 13.76,
        dailyAverageDispensed: 2,
      },
      AHC: {
        _id: '6201ba20f65f11ad3d7913dc',
        DIN: '2402653',
        genericName: 'DONEPEZIL HCL TABLET 10 MG ORA',
        supplierCode: 'AHC',
        brandName: 'DONEPEZIL HYD TB 10MG 100 ACH',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '66',
        packPrice: 45.86,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba20f65f11ad3d79138f',
        DIN: '2322358',
        genericName: 'DONEPEZIL HCL TABLET 10 MG ORA',
        supplierCode: 'PMS',
        brandName: 'DONEPEZIL TB 10MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 45.86,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d791347',
        DIN: '2340615',
        genericName: 'DONEPEZIL HCL TABLET 10 MG ORA',
        supplierCode: 'TEV',
        brandName: 'DONEPEZIL TB 10MG 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 13.76,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d79133e',
        DIN: '2402106',
        genericName: 'DONEPEZIL HCL TABLET 10 MG ORA',
        supplierCode: 'MRC',
        brandName: 'DONEPEZIL TB 10MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '63',
        packPrice: 45.86,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba20f65f11ad3d79138a',
        DIN: '2328682',
        genericName: 'DONEPEZIL HCL TABLET 10 MG ORA',
        supplierCode: 'SDZ',
        brandName: 'DONEPEZIL TB 10MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 45.86,
        dailyAverageDispensed: 0,
      },
      RAN: {
        _id: '6201ba20f65f11ad3d79135d',
        DIN: '2381516',
        genericName: 'DONEPEZIL HCL TABLET 10 MG ORA',
        supplierCode: 'RAN',
        brandName: 'DONEPEZIL TB 10MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 45.86,
        dailyAverageDispensed: 0,
      },
      TAR: {
        _id: '6201ba26f65f11ad3d79aa06',
        DIN: '2381516',
        genericName: 'DONEPEZIL HCL TABLET 10 MG ORA',
        supplierCode: 'TAR',
        brandName: 'DONEPEZIL TB 10MG 100 TARO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 45.86,
        dailyAverageDispensed: 0,
      },
      PFC: {
        _id: '6201ba28f65f11ad3d79da96',
        DIN: '2232044',
        genericName: 'DONEPEZIL HCL TABLET 10 MG ORA',
        supplierCode: 'PFC',
        brandName: 'ARICEPT TB 10MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 164.13,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d791384',
        DIN: '2400588',
        genericName: 'DONEPEZIL HCL TABLET 10 MG ORA',
        supplierCode: 'AUP',
        brandName: 'DONEPEZIL TB 10MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 13.76,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba23f65f11ad3d795951',
        DIN: '2426854',
        genericName: 'DONEPEZIL HCL TABLET 10 MG ORA',
        supplierCode: 'SNS',
        brandName: 'DONEPEZIL TB 10MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 45.86,
        dailyAverageDispensed: 0,
      },
      NTC: {
        _id: '6201ba21f65f11ad3d792a43',
        DIN: '2439565',
        genericName: 'DONEPEZIL HCL TABLET 10 MG ORA',
        supplierCode: 'NTC',
        brandName: 'DONEPEZIL TB 10MG 100 NAT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 45.86,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '32492',
    genericName: 'MEMANTINE HCL TABLET 10 MG ORA',
    strength: '10MG',
    unitPrice: 2.815,
    MFRs: {
      SDZ: {
        _id: '6201ba21f65f11ad3d7922b8',
        DIN: '2375532',
        genericName: 'MEMANTINE HCL TABLET 10 MG ORA',
        supplierCode: 'SDZ',
        brandName: 'MEMANTINE FCT TB 10MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 163.57,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba1ff65f11ad3d78f904',
        DIN: '2366487',
        genericName: 'MEMANTINE HCL TABLET 10 MG ORA',
        supplierCode: 'APX',
        brandName: 'MEMANTINE TB 10MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 163.57,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba28f65f11ad3d79dd03',
        DIN: '2321130',
        genericName: 'MEMANTINE HCL TABLET 10 MG ORA',
        supplierCode: 'PMS',
        brandName: 'MEMANTINE TB 10MG BL 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 49.07,
        dailyAverageDispensed: 4,
      },
      TEV: {
        _id: '6201ba28f65f11ad3d79dd36',
        DIN: '2324067',
        genericName: 'MEMANTINE HCL TABLET 10 MG ORA',
        supplierCode: 'TEV',
        brandName: 'MEMANTINE TB 10MG UD 30 ACT',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 49.07,
        dailyAverageDispensed: 0,
      },
      LUN: {
        _id: '6201ba27f65f11ad3d79d372',
        DIN: '2260638',
        genericName: 'MEMANTINE HCL TABLET 10 MG ORA',
        supplierCode: 'LUN',
        brandName: 'EBIXA TB 10MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 84.46,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d792b1f',
        DIN: '2443082',
        genericName: 'MEMANTINE HCL TABLET 10 MG ORA',
        supplierCode: 'SNS',
        brandName: 'MEMANTINE TB 10MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 163.57,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '40952',
    genericName: 'RABEPRAZOLE SODIUM TABLET DR 1',
    strength: '10MG',
    unitPrice: 1.301,
    MFRs: {
      RAN: {
        _id: '6201ba27f65f11ad3d79ce29',
        DIN: '2298074',
        genericName: 'RABEPRAZOLE SODIUM TABLET DR 1',
        supplierCode: 'RAN',
        brandName: 'RABEPRAZOLE TB 10MG 100 RAN',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: '52',
        packPrice: 6.69,
        dailyAverageDispensed: 4,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79d640',
        DIN: '2310805',
        genericName: 'RABEPRAZOLE SODIUM TABLET DR 1',
        supplierCode: 'PMS',
        brandName: 'RABEPRAZOLE EC TB 10MG 100 PMS',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: '45',
        packPrice: 6.69,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d7900ec',
        DIN: '2314177',
        genericName: 'RABEPRAZOLE SODIUM TABLET DR 1',
        supplierCode: 'SDZ',
        brandName: 'RABEPRAZOLE EC TB 10MG 100 SDZ',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: '35',
        packPrice: 6.69,
        dailyAverageDispensed: 0,
      },
      JNO: {
        _id: '6201ba26f65f11ad3d79be6f',
        DIN: '2243796',
        genericName: 'RABEPRAZOLE SODIUM TABLET DR 1',
        supplierCode: 'JNO',
        brandName: 'PARIET TB 10MG 100',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: null,
        packPrice: 130.1,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d7925d5',
        DIN: '2356511',
        genericName: 'RABEPRAZOLE SODIUM TABLET DR 1',
        supplierCode: 'SNS',
        brandName: 'RABEPRAZOLE EC TB 10MG 100 SNS',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: null,
        packPrice: 6.69,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '43060',
    genericName: 'FENOFIBRATE,MICRONIZED CAPSULE',
    strength: '200MG',
    unitPrice: 0.287,
    MFRs: {
      AAA: {
        _id: '6201ba24f65f11ad3d7985f1',
        DIN: '2239864',
        genericName: 'FENOFIBRATE,MICRONIZED CAPSULE',
        supplierCode: 'AAA',
        brandName: 'FENO MICRO CAPS 200MG 100 AA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 28.73,
        dailyAverageDispensed: 4,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '44359',
    genericName: 'NITROGLYCERIN SPRAY 400MCG/SPR',
    strength: '0.4MG/DOSE',
    unitPrice: 0.08,
    MFRs: {
      SDZ: {
        _id: '6201ba20f65f11ad3d790b07',
        DIN: '2238998',
        genericName: 'NITROGLYCERIN SPRAY 400MCG/SPR',
        supplierCode: 'SDZ',
        brandName: 'RHO NITRO SPRAY PUMP 0.4MG 200DS SDZ',
        form: 'SPRAY',
        packSize: '200',
        rebateValue: '1',
        packPrice: 8.42,
        dailyAverageDispensed: 4,
      },
      SAC: {
        _id: '6201ba28f65f11ad3d79db17',
        DIN: '2231441',
        genericName: 'NITROGLYCERIN SPRAY 400MCG/SPR',
        supplierCode: 'SAC',
        brandName: 'NITROLINGUAL PUMP 0.4MG 200DS',
        form: 'AEROSOL MET DS',
        packSize: '200',
        rebateValue: null,
        packPrice: 16.08,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '45216',
    genericName: 'TESTOSTERONE GEL PACKET 50 MG',
    strength: '1%',
    unitPrice: 26.836,
    MFRs: {
      TAR: {
        _id: '6201ba22f65f11ad3d795024',
        DIN: '2463806',
        genericName: 'TESTOSTERONE GEL PACKET 50 MG',
        supplierCode: 'TAR',
        brandName: 'TESTOSTERONE GEL 1% 30X5G TARO',
        form: 'GEL',
        packSize: '5',
        rebateValue: '17',
        packPrice: 88.73,
        dailyAverageDispensed: 4,
      },
      ABB: {
        _id: '6201ba27f65f11ad3d79c523',
        DIN: '2245346',
        genericName: 'TESTOSTERONE GEL PACKET 50 MG',
        supplierCode: 'ABB',
        brandName: 'ANDROGEL SACHET 1% 30X5G',
        form: 'GEL',
        packSize: '5',
        rebateValue: null,
        packPrice: 134.18,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '46238',
    genericName: 'BUPROPION HCL TABLET ER 150 MG',
    strength: '150MG',
    unitPrice: 0.243,
    MFRs: {
      ODN: {
        _id: '6201ba26f65f11ad3d79b263',
        DIN: '2275082',
        genericName: 'BUPROPION HCL TABLET ER 150 MG',
        supplierCode: 'ODN',
        brandName: 'ODAN BUPROPION SR TB 150MG 30',
        form: 'TABLET EXT REL',
        packSize: '30',
        rebateValue: null,
        packPrice: 7.27,
        dailyAverageDispensed: 4,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '48456',
    genericName: 'TRAMADOL HCL/ACETAMINOPHEN TAB',
    strength: '37.5/325MG',
    unitPrice: 0.626,
    MFRs: {
      RAN: {
        _id: '6201ba1ff65f11ad3d7905a7',
        DIN: '2388197',
        genericName: 'TRAMADOL HCL/ACETAMINOPHEN TAB',
        supplierCode: 'RAN',
        brandName: 'TRAMADOL/ACET TB 37.5/325MG 100 TARO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 62.64,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d7906ba',
        DIN: '2389800',
        genericName: 'TRAMADOL HCL/ACETAMINOPHEN TAB',
        supplierCode: 'MPH',
        brandName: 'TRAMADOL/ACET TB 37.5/325MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 62.64,
        dailyAverageDispensed: 2,
      },
      APX: {
        _id: '6201ba1ff65f11ad3d78fe45',
        DIN: '2336790',
        genericName: 'TRAMADOL HCL/ACETAMINOPHEN TAB',
        supplierCode: 'APX',
        brandName: 'TRAMADOL/ACET TB 37.5/325MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 62.64,
        dailyAverageDispensed: 2,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d79051b',
        DIN: '2388308',
        genericName: 'TRAMADOL HCL/ACETAMINOPHEN TAB',
        supplierCode: 'JAP',
        brandName: 'ACET TRAMADOL TB 325/37.5MG 60 JAMP',
        form: 'TABLET',
        packSize: '60',
        rebateValue: '65',
        packPrice: 37.58,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba1ff65f11ad3d7904bb',
        DIN: '2388324',
        genericName: 'TRAMADOL HCL/ACETAMINOPHEN TAB',
        supplierCode: 'MRC',
        brandName: 'TRAMADOL/ACET TB 37.5/325MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '45',
        packPrice: 62.64,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d7904fb',
        DIN: '2347180',
        genericName: 'TRAMADOL HCL/ACETAMINOPHEN TAB',
        supplierCode: 'TEV',
        brandName: 'TRAMADOL/ACET 37.5MG/325MG TB UD 60 TEVA',
        form: 'TABLET',
        packSize: '60',
        rebateValue: '1',
        packPrice: 37.58,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d792f9e',
        DIN: '2439050',
        genericName: 'TRAMADOL HCL/ACETAMINOPHEN TAB',
        supplierCode: 'AUP',
        brandName: 'TRAMADOL/ACET TB 37.5/325MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 62.64,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '50628',
    genericName: 'URSODIOL TABLET 500 MG ORAL',
    strength: '500MG',
    unitPrice: 3.35,
    MFRs: {
      PMS: {
        _id: '6201ba26f65f11ad3d79baac',
        DIN: '2273500',
        genericName: 'URSODIOL TABLET 500 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'URSODIOL-C TB 500MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 72.42,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba23f65f11ad3d79655a',
        DIN: '2472406',
        genericName: 'URSODIOL TABLET 500 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'URSODIOL TB 500MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '35',
        packPrice: 72.42,
        dailyAverageDispensed: 0,
      },
      ALL: {
        _id: '6201ba27f65f11ad3d79c373',
        DIN: '2245894',
        genericName: 'URSODIOL TABLET 500 MG ORAL',
        supplierCode: 'ALL',
        brandName: 'URSO DS TB 500MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 334.98,
        dailyAverageDispensed: 0,
      },
      GLN: {
        _id: '6201ba21f65f11ad3d7933e3',
        DIN: '2426919',
        genericName: 'URSODIOL TABLET 500 MG ORAL',
        supplierCode: 'GLN',
        brandName: 'URSODIOL TB 500MG 100 GLN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 72.42,
        dailyAverageDispensed: 4,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79af85',
        DIN: '2515539',
        genericName: 'URSODIOL TABLET 500 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'URSODIOL TB 500MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 72.42,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '62313',
    genericName: 'RAMIPRIL/HYDROCHLOROTHIAZIDE T',
    strength: '10/12.5MG',
    unitPrice: 0.59,
    MFRs: {
      PMS: {
        _id: '6201ba28f65f11ad3d79e190',
        DIN: '2342154',
        genericName: 'RAMIPRIL/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'PMS',
        brandName: 'RAMIPRIL HCTZ TB 10/12.5MG BL 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '25',
        packPrice: 7.9,
        dailyAverageDispensed: 4,
      },
      RAN: {
        _id: '6201ba22f65f11ad3d795435',
        DIN: '2449455',
        genericName: 'RAMIPRIL/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'RAN',
        brandName: 'RAMIPRIL HCTZ TB 10/12.5MG 100 TARO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 26.34,
        dailyAverageDispensed: 0,
      },
      BAU: {
        _id: '6201ba26f65f11ad3d79be38',
        DIN: '2283166',
        genericName: 'RAMIPRIL/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'BAU',
        brandName: 'ALTACE HCT TB 10/12.5MG BLS 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 16.52,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '63369',
    genericName: 'ENALAPRIL SODIUM TABLET 4MG(5M',
    strength: '5MG',
    unitPrice: 0.22,
    MFRs: {
      RAN: {
        _id: '6201ba1ff65f11ad3d78f666',
        DIN: '2352249',
        genericName: 'ENALAPRIL SODIUM TABLET 4MG(5M',
        supplierCode: 'RAN',
        brandName: 'ENALAPRIL TB 5MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 22.03,
        dailyAverageDispensed: 1,
      },
      JAP: {
        _id: '6201ba23f65f11ad3d79709a',
        DIN: '2474794',
        genericName: 'ENALAPRIL SODIUM TABLET 4MG(5M',
        supplierCode: 'JAP',
        brandName: 'ENALAPRIL TB 5MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 22.03,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c4d9',
        DIN: '2019884',
        genericName: 'ENALAPRIL SODIUM TABLET 4MG(5M',
        supplierCode: 'APX',
        brandName: 'ENALAPRIL TB 5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '64',
        packPrice: 22.03,
        dailyAverageDispensed: 3,
      },
      MRC: {
        _id: '6201ba24f65f11ad3d79714d',
        DIN: '2459469',
        genericName: 'ENALAPRIL SODIUM TABLET 4MG(5M',
        supplierCode: 'MRC',
        brandName: 'ENALAPRIL TB 5MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '63',
        packPrice: 22.03,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79cc32',
        DIN: '2291886',
        genericName: 'ENALAPRIL SODIUM TABLET 4MG(5M',
        supplierCode: 'TEV',
        brandName: 'ENALAPRIL TB 5MG 100 ACT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 22.03,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba27f65f11ad3d79cb81',
        DIN: '2299941',
        genericName: 'ENALAPRIL SODIUM TABLET 4MG(5M',
        supplierCode: 'SDZ',
        brandName: 'ENALAPRIL TB 5MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 22.03,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79b51f',
        DIN: '2400669',
        genericName: 'ENALAPRIL SODIUM TABLET 4MG(5M',
        supplierCode: 'SNS',
        brandName: 'ENALAPRIL TB 5MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 22.03,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba27f65f11ad3d79c38c',
        DIN: '708879',
        genericName: 'ENALAPRIL SODIUM TABLET 4MG(5M',
        supplierCode: 'ORN',
        brandName: 'VASOTEC TB 5MG 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 35.99,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '64051',
    genericName: 'FENOFIBRATE, MICROCOATED TABLE',
    strength: '160MG',
    unitPrice: 1.41,
    MFRs: {
      AAA: {
        _id: '6201ba26f65f11ad3d79addf',
        DIN: '2246860',
        genericName: 'FENOFIBRATE, MICROCOATED TABLE',
        supplierCode: 'AAA',
        brandName: 'FENO SUPER TB 160MG 100 AA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 32.88,
        dailyAverageDispensed: 4,
      },
      BGP: {
        _id: '6201ba27f65f11ad3d79d9c4',
        DIN: '2241602',
        genericName: 'FENOFIBRATE, MICROCOATED TABLE',
        supplierCode: 'BGP',
        brandName: 'LIPIDIL SUPRA TB 160MG BLST 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 42.3,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 4,
      total: 0,
    },
  },
  {
    groupCode: '284',
    genericName: 'HYDRALAZINE HCL TABLET 10 MG O',
    strength: '10MG',
    unitPrice: 0.036,
    MFRs: {
      JAP: {
        _id: '6201ba22f65f11ad3d794c40',
        DIN: '2457865',
        genericName: 'HYDRALAZINE HCL TABLET 10 MG O',
        supplierCode: 'JAP',
        brandName: 'HYDRALAZINE TB 10MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 3.55,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba23f65f11ad3d7956aa',
        DIN: '2468778',
        genericName: 'HYDRALAZINE HCL TABLET 10 MG O',
        supplierCode: 'MPH',
        brandName: 'HYDRALAZINE TB 10MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 3.55,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba22f65f11ad3d794e20',
        DIN: '441619',
        genericName: 'HYDRALAZINE HCL TABLET 10 MG O',
        supplierCode: 'APX',
        brandName: 'HYDRALAZINE TB 10MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 3.55,
        dailyAverageDispensed: 3,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '3831',
    genericName: 'PERPHENAZINE TABLET 2 MG ORAL',
    strength: '2MG',
    unitPrice: 0.071,
    MFRs: {
      AAA: {
        _id: '6201ba28f65f11ad3d79e215',
        DIN: '335134',
        genericName: 'PERPHENAZINE TABLET 2 MG ORAL',
        supplierCode: 'AAA',
        brandName: 'PERPHENAZINE TB 2MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 7.05,
        dailyAverageDispensed: 3,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '4001',
    genericName: 'LITHIUM CARBONATE CAPSULE 300',
    strength: '300MG',
    unitPrice: 0.066,
    MFRs: {
      PMS: {
        _id: '6201ba26f65f11ad3d79b895',
        DIN: '2216140',
        genericName: 'LITHIUM CARBONATE CAPSULE 300',
        supplierCode: 'PMS',
        brandName: 'LITHIUM CARBONATE CAPS 300MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '50',
        packPrice: 6.57,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba25f65f11ad3d79a295',
        DIN: '2242838',
        genericName: 'LITHIUM CARBONATE CAPSULE 300',
        supplierCode: 'APX',
        brandName: 'LITHIUM CARBONATE CAPS 300MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '10',
        packPrice: 6.57,
        dailyAverageDispensed: 3,
      },
      BAU: {
        _id: '6201ba23f65f11ad3d796460',
        DIN: '236683',
        genericName: 'LITHIUM CARBONATE CAPSULE 300',
        supplierCode: 'BAU',
        brandName: 'CARBOLITH CAPS 300MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 11.22,
        dailyAverageDispensed: 0,
      },
      ERF: {
        _id: '6201ba27f65f11ad3d79d124',
        DIN: '406775',
        genericName: 'LITHIUM CARBONATE CAPSULE 300',
        supplierCode: 'ERF',
        brandName: 'LITHANE CAPS 300MG 1000',
        form: 'CAPSULE',
        packSize: '1000',
        rebateValue: null,
        packPrice: 189.9,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '4026',
    genericName: 'METHYLPHENIDATE HCL TABLET 10',
    strength: '10MG',
    unitPrice: 0.222,
    MFRs: {
      PMS: {
        _id: '6201ba1ff65f11ad3d7902b5',
        DIN: '584991',
        genericName: 'METHYLPHENIDATE HCL TABLET 10',
        supplierCode: 'PMS',
        brandName: 'METHYLPHENIDATE TB 10MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 22.16,
        dailyAverageDispensed: 3,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79d259',
        DIN: '2249324',
        genericName: 'METHYLPHENIDATE HCL TABLET 10',
        supplierCode: 'APX',
        brandName: 'METHYLPHENIDATE TB 10MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 22.16,
        dailyAverageDispensed: 0,
      },
      NPC: {
        _id: '6201ba20f65f11ad3d7915b9',
        DIN: '5606',
        genericName: 'METHYLPHENIDATE HCL TABLET 10',
        supplierCode: 'NPC',
        brandName: 'RITALIN TB 10MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 48.07,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '4109',
    genericName: 'HYDROMORPHONE HCL TABLET 1 MG',
    strength: '1MG',
    unitPrice: 0.096,
    MFRs: {
      PMS: {
        _id: '6201ba24f65f11ad3d7981a1',
        DIN: '885444',
        genericName: 'HYDROMORPHONE HCL TABLET 1 MG',
        supplierCode: 'PMS',
        brandName: 'HYDROMORPHONE TB 1MG BLST 6X15 PMS',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '35',
        packPrice: 17.03,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba20f65f11ad3d790869',
        DIN: '2364115',
        genericName: 'HYDROMORPHONE HCL TABLET 1 MG',
        supplierCode: 'APX',
        brandName: 'HYDROMORPHONE TB 1MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 9.59,
        dailyAverageDispensed: 3,
      },
      PFR: {
        _id: '6201ba26f65f11ad3d79b6f9',
        DIN: '705438',
        genericName: 'HYDROMORPHONE HCL TABLET 1 MG',
        supplierCode: 'PFR',
        brandName: 'DILAUDID TB 1MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 10.12,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '4681',
    genericName: 'CYCLOBENZAPRINE HCL TABLET 10',
    strength: '10MG',
    unitPrice: 0.377,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79d618',
        DIN: '2177145',
        genericName: 'CYCLOBENZAPRINE HCL TABLET 10',
        supplierCode: 'APX',
        brandName: 'CYCLOBENZAPRINE TB 10MG 500 APO',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '65',
        packPrice: 188.25,
        dailyAverageDispensed: 3,
      },
      PMS: {
        _id: '6201ba26f65f11ad3d79b88f',
        DIN: '2212048',
        genericName: 'CYCLOBENZAPRINE HCL TABLET 10',
        supplierCode: 'PMS',
        brandName: 'CYCLOBENZAPRINE TB 10MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 37.65,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d43d',
        DIN: '2080052',
        genericName: 'CYCLOBENZAPRINE HCL TABLET 10',
        supplierCode: 'TEV',
        brandName: 'CYCLOBENZAPRINE HCL TB 10MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 37.65,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d78feaa',
        DIN: '2357127',
        genericName: 'CYCLOBENZAPRINE HCL TABLET 10',
        supplierCode: 'JAP',
        brandName: 'CYCLOBENZAPRINE TB 10MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 37.65,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba27f65f11ad3d79d765',
        DIN: '2287064',
        genericName: 'CYCLOBENZAPRINE HCL TABLET 10',
        supplierCode: 'SNS',
        brandName: 'CYCLOBENZAPRINE TB 10MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 37.65,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba1ff65f11ad3d78fa01',
        DIN: '2348853',
        genericName: 'CYCLOBENZAPRINE HCL TABLET 10',
        supplierCode: 'AUP',
        brandName: 'CYCLOBENZAPRINE TB 10MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 37.65,
        dailyAverageDispensed: 0,
      },
      OPH: {
        _id: '6201ba24f65f11ad3d7989d4',
        DIN: '2495422',
        genericName: 'CYCLOBENZAPRINE HCL TABLET 10',
        supplierCode: 'OPH',
        brandName: 'FLEXERIL 10MG TB 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 39.73,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '4929',
    genericName: 'OXYBUTYNIN CHLORIDE TABLET 5 M',
    strength: '5MG',
    unitPrice: 0.099,
    MFRs: {
      PMS: {
        _id: '6201ba27f65f11ad3d79d00d',
        DIN: '2240550',
        genericName: 'OXYBUTYNIN CHLORIDE TABLET 5 M',
        supplierCode: 'PMS',
        brandName: 'OXYBUTYNIN TB 5MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 9.86,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c3db',
        DIN: '2163543',
        genericName: 'OXYBUTYNIN CHLORIDE TABLET 5 M',
        supplierCode: 'APX',
        brandName: 'OXYBUTYNIN TB 5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 9.86,
        dailyAverageDispensed: 3,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79cb48',
        DIN: '2230394',
        genericName: 'OXYBUTYNIN CHLORIDE TABLET 5 M',
        supplierCode: 'TEV',
        brandName: 'OXYBUTYNIN TB 5MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 9.86,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f4d7',
        DIN: '2350238',
        genericName: 'OXYBUTYNIN CHLORIDE TABLET 5 M',
        supplierCode: 'SNS',
        brandName: 'OXYBUTYNIN TB 5MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 9.86,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '5125',
    genericName: 'PROPRANOLOL HCL TABLET 40 MG O',
    strength: '40MG',
    unitPrice: 0.123,
    MFRs: {
      TEV: {
        _id: '6201ba28f65f11ad3d79dcaa',
        DIN: '496499',
        genericName: 'PROPRANOLOL HCL TABLET 40 MG O',
        supplierCode: 'TEV',
        brandName: 'PROPRANOLOL TB 40MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 12.25,
        dailyAverageDispensed: 3,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '5137',
    genericName: 'NADOLOL TABLET 80 MG ORAL',
    strength: '80MG',
    unitPrice: 0.341,
    MFRs: {
      MPH: {
        _id: '6201ba25f65f11ad3d7991de',
        DIN: '2496399',
        genericName: 'NADOLOL TABLET 80 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'NADOLOL TB 80MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 34.1,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba25f65f11ad3d79a30c',
        DIN: '782467',
        genericName: 'NADOLOL TABLET 80 MG ORAL',
        supplierCode: 'APX',
        brandName: 'NADOLOL TB 80MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 34.1,
        dailyAverageDispensed: 3,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '6561',
    genericName: 'WARFARIN SODIUM TABLET 2 MG OR',
    strength: '2MG',
    unitPrice: 0.084,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79bfc1',
        DIN: '2242925',
        genericName: 'WARFARIN SODIUM TABLET 2 MG OR',
        supplierCode: 'APX',
        brandName: 'WARFARIN TB 2MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 8.41,
        dailyAverageDispensed: 3,
      },
      TAR: {
        _id: '6201ba28f65f11ad3d79e0dd',
        DIN: '2242681',
        genericName: 'WARFARIN SODIUM TABLET 2 MG OR',
        supplierCode: 'TAR',
        brandName: 'WARFARIN TB 2MG 100 TARO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 8.41,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '8336',
    genericName: 'INDOMETHACIN CAPSULE 25 MG ORA',
    strength: '25MG',
    unitPrice: 0.152,
    MFRs: {
      TEV: {
        _id: '6201ba27f65f11ad3d79c5e0',
        DIN: '337420',
        genericName: 'INDOMETHACIN CAPSULE 25 MG ORA',
        supplierCode: 'TEV',
        brandName: 'INDOMETHACIN CAPS 25MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '40',
        packPrice: 15.19,
        dailyAverageDispensed: 3,
      },
      MPH: {
        _id: '6201ba22f65f11ad3d7945af',
        DIN: '2461811',
        genericName: 'INDOMETHACIN CAPSULE 25 MG ORA',
        supplierCode: 'MPH',
        brandName: 'INDOMETHACIN CAPS 25MG 100 MINT',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '20',
        packPrice: 15.19,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '9189',
    genericName: 'TETRACYCLINE HCL CAPSULE 250 M',
    strength: '250MG',
    unitPrice: 0.071,
    MFRs: {
      AAA: {
        _id: '6201ba1ff65f11ad3d78f9cc',
        DIN: '580929',
        genericName: 'TETRACYCLINE HCL CAPSULE 250 M',
        supplierCode: 'AAA',
        brandName: 'TETRACYCLINE CAPS 250MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 7.07,
        dailyAverageDispensed: 3,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '9339',
    genericName: 'CLINDAMYCIN HCL CAPSULE 150 MG',
    strength: '150MG',
    unitPrice: 0.222,
    MFRs: {
      NRI: {
        _id: '6201ba25f65f11ad3d79928d',
        DIN: '2493748',
        genericName: 'CLINDAMYCIN HCL CAPSULE 150 MG',
        supplierCode: 'NRI',
        brandName: 'CLINDAMYCIN CAPS 150MG 100 NRA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '62',
        packPrice: 22.17,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba24f65f11ad3d797afd',
        DIN: '2483734',
        genericName: 'CLINDAMYCIN HCL CAPSULE 150 MG',
        supplierCode: 'JAP',
        brandName: 'CLINDAMYCIN CAPS 150MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '40',
        packPrice: 22.17,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d11a',
        DIN: '2241709',
        genericName: 'CLINDAMYCIN HCL CAPSULE 150 MG',
        supplierCode: 'TEV',
        brandName: 'CLINDAMYCIN CAPS 150MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 22.17,
        dailyAverageDispensed: 3,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d794fc2',
        DIN: '2436906',
        genericName: 'CLINDAMYCIN HCL CAPSULE 150 MG',
        supplierCode: 'AUP',
        brandName: 'CLINDAMYCIN CAPS 150MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 22.17,
        dailyAverageDispensed: 0,
      },
      PFC: {
        _id: '6201ba26f65f11ad3d79bca6',
        DIN: '30570',
        genericName: 'CLINDAMYCIN HCL CAPSULE 150 MG',
        supplierCode: 'PFC',
        brandName: 'DALACIN C CAPS 150MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 153.02,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '11678',
    genericName: 'FAMOTIDINE TABLET 40 MG ORAL',
    strength: '40MG',
    unitPrice: 0.483,
    MFRs: {
      JAP: {
        _id: '6201ba26f65f11ad3d79add7',
        DIN: '2507757',
        genericName: 'FAMOTIDINE TABLET 40 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'FAMOTIDINE TB 40MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '30',
        packPrice: 48.34,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d792de9',
        DIN: '2022141',
        genericName: 'FAMOTIDINE TABLET 40 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'FAMOTIDINE TB 40MG 100 NOVO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 48.34,
        dailyAverageDispensed: 3,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '11858',
    genericName: 'HYDROXYZINE HCL CAPSULE 10 MG',
    strength: '10MG',
    unitPrice: 0.163,
    MFRs: {
      AAA: {
        _id: '6201ba23f65f11ad3d796ef0',
        DIN: '646059',
        genericName: 'HYDROXYZINE HCL CAPSULE 10 MG',
        supplierCode: 'AAA',
        brandName: 'HYDROXYZINE CAPS 10MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 16.33,
        dailyAverageDispensed: 3,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c295',
        DIN: '738824',
        genericName: 'HYDROXYZINE HCL CAPSULE 10 MG',
        supplierCode: 'TEV',
        brandName: 'HYDROXYZINE HCL CAPS 10MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 14.44,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '13574',
    genericName: 'TAMOXIFEN CITRATE TABLET 20 MG',
    strength: '20MG',
    unitPrice: 0.408,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79d599',
        DIN: '812390',
        genericName: 'TAMOXIFEN CITRATE TABLET 20 MG',
        supplierCode: 'APX',
        brandName: 'TAMOX TB 20MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 35,
        dailyAverageDispensed: 3,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79da34',
        DIN: '851973',
        genericName: 'TAMOXIFEN CITRATE TABLET 20 MG',
        supplierCode: 'TEV',
        brandName: 'TAMOXIFEN TB 20MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 10.5,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba1ff65f11ad3d78f739',
        DIN: '2048485',
        genericName: 'TAMOXIFEN CITRATE TABLET 20 MG',
        supplierCode: 'AST',
        brandName: 'NOLVADEX-D TB 20MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 12.24,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '15864',
    genericName: 'ATENOLOL TABLET 25 MG ORAL',
    strength: '25MG',
    unitPrice: 0.176,
    MFRs: {
      PMS: {
        _id: '6201ba26f65f11ad3d79b8e0',
        DIN: '2246581',
        genericName: 'ATENOLOL TABLET 25 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'ATENOLOL TB 25MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '72',
        packPrice: 17.58,
        dailyAverageDispensed: 3,
      },
      RAN: {
        _id: '6201ba1ff65f11ad3d7900a7',
        DIN: '2373963',
        genericName: 'ATENOLOL TABLET 25 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'ATENOLOL TB 25MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '62',
        packPrice: 17.58,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba28f65f11ad3d79dd2e',
        DIN: '2266660',
        genericName: 'ATENOLOL TABLET 25 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'ATENOLOL TB 25MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 17.58,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba1ff65f11ad3d790482',
        DIN: '2371979',
        genericName: 'ATENOLOL TABLET 25 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'ATENOLOL TB 25MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '58',
        packPrice: 17.58,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba1ff65f11ad3d78fa60',
        DIN: '2368013',
        genericName: 'ATENOLOL TABLET 25 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'ATENOL TB 25MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 17.58,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d78fbbd',
        DIN: '2367556',
        genericName: 'ATENOLOL TABLET 25 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'ATENOLOL TB 25MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 17.58,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '16018',
    genericName: 'FOSINOPRIL SODIUM TABLET 20 MG',
    strength: '20MG',
    unitPrice: 0.262,
    MFRs: {
      TEV: {
        _id: '6201ba27f65f11ad3d79c090',
        DIN: '2247803',
        genericName: 'FOSINOPRIL SODIUM TABLET 20 MG',
        supplierCode: 'TEV',
        brandName: 'FOSINOPRIL TB 20MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '35',
        packPrice: 26.19,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79d761',
        DIN: '2266016',
        genericName: 'FOSINOPRIL SODIUM TABLET 20 MG',
        supplierCode: 'APX',
        brandName: 'FOSINOPRIL TB 20MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 26.19,
        dailyAverageDispensed: 3,
      },
      SNS: {
        _id: '6201ba22f65f11ad3d7944ad',
        DIN: '2459396',
        genericName: 'FOSINOPRIL SODIUM TABLET 20 MG',
        supplierCode: 'SNS',
        brandName: 'FOSINOPRIL TB 20MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 26.19,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '16773',
    genericName: 'CARBAMAZEPINE TAB ER 12H 200 M',
    strength: '200MG',
    unitPrice: 0.256,
    MFRs: {
      SDZ: {
        _id: '6201ba21f65f11ad3d791f69',
        DIN: '2261839',
        genericName: 'CARBAMAZEPINE TAB ER 12H 200 M',
        supplierCode: 'SDZ',
        brandName: 'CARBAMAZEPINE CR TB 200MG 100 SDZ',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '15',
        packPrice: 25.63,
        dailyAverageDispensed: 3,
      },
      NPC: {
        _id: '6201ba26f65f11ad3d79bcd5',
        DIN: '773611',
        genericName: 'CARBAMAZEPINE TAB ER 12H 200 M',
        supplierCode: 'NPC',
        brandName: 'TEGRETOL CR TB 200MG 100',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 55.59,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '21282',
    genericName: 'DILTIAZEM HCL CAP ER 24H 120 M',
    strength: '120MG',
    unitPrice: 0.363,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79c966',
        DIN: '2230997',
        genericName: 'DILTIAZEM HCL CAP ER 24H 120 M',
        supplierCode: 'APX',
        brandName: 'DILTIAZ CD CAPS 120MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 36.34,
        dailyAverageDispensed: 3,
      },
      TEV: {
        _id: '6201ba28f65f11ad3d79e068',
        DIN: '2242538',
        genericName: 'DILTIAZEM HCL CAP ER 24H 120 M',
        supplierCode: 'TEV',
        brandName: 'DILTIAZEM HCL CD CAPS 120MG 100 TEVA',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 36.34,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba24f65f11ad3d798986',
        DIN: '2484064',
        genericName: 'DILTIAZEM HCL CAP ER 24H 120 M',
        supplierCode: 'MRC',
        brandName: 'DILTIAZEM CD CAPS 120MG 100 MAR',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 36.34,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '25848',
    genericName: 'MODAFINIL TABLET 100 MG ORAL',
    strength: '100MG',
    unitPrice: 1.586,
    MFRs: {
      MRC: {
        _id: '6201ba21f65f11ad3d7920ba',
        DIN: '2432560',
        genericName: 'MODAFINIL TABLET 100 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'MODAFINIL TB 100MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 92.93,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba1ff65f11ad3d78fa53',
        DIN: '2285398',
        genericName: 'MODAFINIL TABLET 100 MG ORAL',
        supplierCode: 'APX',
        brandName: 'MODAFINIL TB 100MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 92.93,
        dailyAverageDispensed: 3,
      },
      JAP: {
        _id: '6201ba25f65f11ad3d79a1d3',
        DIN: '2503727',
        genericName: 'MODAFINIL TABLET 100 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'MODAFINIL TB 100MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 92.93,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d7935ee',
        DIN: '2239665',
        genericName: 'MODAFINIL TABLET 100 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'ALERTEC MODAFINIL TB 100MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 47.59,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d7924b4',
        DIN: '2430487',
        genericName: 'MODAFINIL TABLET 100 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'MODAFINIL TB 100MG UD 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 27.88,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '28109',
    genericName: 'CARVEDILOL TABLET 6.25 MG ORAL',
    strength: '6.25MG',
    unitPrice: 0.243,
    MFRs: {
      PMS: {
        _id: '6201ba27f65f11ad3d79c90e',
        DIN: '2245915',
        genericName: 'CARVEDILOL TABLET 6.25 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'CARVEDILOL TB 6.25MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 24.31,
        dailyAverageDispensed: 3,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79caeb',
        DIN: '2247934',
        genericName: 'CARVEDILOL TABLET 6.25 MG ORAL',
        supplierCode: 'APX',
        brandName: 'CARVEDILOL TB 6.25MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '54',
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79cfa8',
        DIN: '2252317',
        genericName: 'CARVEDILOL TABLET 6.25 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'CARVEDILOL TB 6.25MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '15',
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d791682',
        DIN: '2364921',
        genericName: 'CARVEDILOL TABLET 6.25 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'CARVEDILOL TB 6.25MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d790042',
        DIN: '2368900',
        genericName: 'CARVEDILOL TABLET 6.25 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'CARVEDILOL TB 6.25MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '0',
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d791b28',
        DIN: '2418509',
        genericName: 'CARVEDILOL TABLET 6.25 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'CARVEDILOL TB 6.25MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 24.31,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '29335',
    genericName: 'DONEPEZIL HCL TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 5.471,
    MFRs: {
      APX: {
        _id: '6201ba20f65f11ad3d791373',
        DIN: '2362260',
        genericName: 'DONEPEZIL HCL TABLET 5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'DONEPEZIL TB 5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '72',
        packPrice: 45.86,
        dailyAverageDispensed: 2,
      },
      MPH: {
        _id: '6201ba24f65f11ad3d79772c',
        DIN: '2408600',
        genericName: 'DONEPEZIL HCL TABLET 5 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'DONEPEZIL TB 5MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 45.86,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d7929b7',
        DIN: '2416948',
        genericName: 'DONEPEZIL HCL TABLET 5 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'DONEPEZIL TB 5MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 45.86,
        dailyAverageDispensed: 1,
      },
      AHC: {
        _id: '6201ba20f65f11ad3d7913db',
        DIN: '2402645',
        genericName: 'DONEPEZIL HCL TABLET 5 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'DONEPEZIL HYD TB 5MG 100 ACH',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '66',
        packPrice: 45.86,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba20f65f11ad3d79138e',
        DIN: '2322331',
        genericName: 'DONEPEZIL HCL TABLET 5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'DONEPEZIL TB 5MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 45.86,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d7916d3',
        DIN: '2340607',
        genericName: 'DONEPEZIL HCL TABLET 5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'DONEPEZIL TB 5MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 45.86,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d79133b',
        DIN: '2402092',
        genericName: 'DONEPEZIL HCL TABLET 5 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'DONEPEZIL TB 5MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '63',
        packPrice: 45.86,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba20f65f11ad3d79138b',
        DIN: '2328666',
        genericName: 'DONEPEZIL HCL TABLET 5 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'DONEPEZIL TB 5MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 45.86,
        dailyAverageDispensed: 0,
      },
      TAR: {
        _id: '6201ba26f65f11ad3d79aa07',
        DIN: '2381508',
        genericName: 'DONEPEZIL HCL TABLET 5 MG ORAL',
        supplierCode: 'TAR',
        brandName: 'DONEPEZIL TB 5MG 100 TARO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 45.86,
        dailyAverageDispensed: 0,
      },
      PFC: {
        _id: '6201ba27f65f11ad3d79d88b',
        DIN: '2232043',
        genericName: 'DONEPEZIL HCL TABLET 5 MG ORAL',
        supplierCode: 'PFC',
        brandName: 'ARICEPT TB 5MG UD 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 153.19,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d791382',
        DIN: '2400561',
        genericName: 'DONEPEZIL HCL TABLET 5 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'DONEPEZIL TB 5MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 13.76,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba23f65f11ad3d795952',
        DIN: '2426846',
        genericName: 'DONEPEZIL HCL TABLET 5 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'DONEPEZIL TB 5MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 45.86,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '35527',
    genericName: 'MOMETASONE FUROATE SOLUTION 0.',
    strength: '0.10%',
    unitPrice: 0.336,
    MFRs: {
      TAR: {
        _id: '6201ba28f65f11ad3d79dcbc',
        DIN: '2266385',
        genericName: 'MOMETASONE FUROATE SOLUTION 0.',
        supplierCode: 'TAR',
        brandName: 'MOMETASONE LOTION TOPICAL SOLUTION 0.1% 30ML TARO',
        form: 'LOTION',
        packSize: '30',
        rebateValue: '1',
        packPrice: 10.07,
        dailyAverageDispensed: 3,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '40549',
    genericName: 'LEFLUNOMIDE TABLET 10 MG ORAL',
    strength: '10MG',
    unitPrice: 11.677,
    MFRs: {
      TEV: {
        _id: '6201ba26f65f11ad3d79b66d',
        DIN: '2261251',
        genericName: 'LEFLUNOMIDE TABLET 10 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'LEFLUNOMIDE TB 10MG 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 79.3,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79d136',
        DIN: '2256495',
        genericName: 'LEFLUNOMIDE TABLET 10 MG ORAL',
        supplierCode: 'APX',
        brandName: 'LEFLUNOMIDE TB 10MG 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 79.3,
        dailyAverageDispensed: 3,
      },
      SDZ: {
        _id: '6201ba26f65f11ad3d79b99a',
        DIN: '2283964',
        genericName: 'LEFLUNOMIDE TABLET 10 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'LEFLUNOMIDE TB 10MG 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 79.3,
        dailyAverageDispensed: 0,
      },
      SAC: {
        _id: '6201ba27f65f11ad3d79d844',
        DIN: '2241888',
        genericName: 'LEFLUNOMIDE TABLET 10 MG ORAL',
        supplierCode: 'SAC',
        brandName: 'ARAVA TB 10MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 350.3,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f4c8',
        DIN: '2351668',
        genericName: 'LEFLUNOMIDE TABLET 10 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'LEFLUNOMIDE TB 10MG 30 SNS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 79.3,
        dailyAverageDispensed: 0,
      },
      ACE: {
        _id: '6201ba24f65f11ad3d797d8f',
        DIN: '2478862',
        genericName: 'LEFLUNOMIDE TABLET 10 MG ORAL',
        supplierCode: 'ACE',
        brandName: 'LEFLUNOMIDE TB 10MG 100 ACCEL',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 200,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '41363',
    genericName: 'TRANDOLAPRIL CAPSULE 4 MG ORAL',
    strength: '4MG',
    unitPrice: 1.076,
    MFRs: {
      PMS: {
        _id: '6201ba23f65f11ad3d795d42',
        DIN: '2357798',
        genericName: 'TRANDOLAPRIL CAPSULE 4 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'TRANDOLAPRIL CAPS 4MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '45',
        packPrice: 24.98,
        dailyAverageDispensed: 3,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d795eac',
        DIN: '2325764',
        genericName: 'TRANDOLAPRIL CAPSULE 4 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'TRANDOLAPRIL CAPS 4MG 100 SDZ',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '30',
        packPrice: 24.98,
        dailyAverageDispensed: 0,
      },
      BGP: {
        _id: '6201ba27f65f11ad3d79c017',
        DIN: '2239267',
        genericName: 'TRANDOLAPRIL CAPSULE 4 MG ORAL',
        supplierCode: 'BGP',
        brandName: 'MAVIK TRANDOLAPRIL CAPS 4MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 107.57,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d7964d4',
        DIN: '2471892',
        genericName: 'TRANDOLAPRIL CAPSULE 4 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'TRANDOLAPRIL CAPS 4MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 24.98,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '42922',
    genericName: 'RISPERIDONE TABLET 0.25 MG ORA',
    strength: '0.25MG',
    unitPrice: 0.104,
    MFRs: {
      PMS: {
        _id: '6201ba24f65f11ad3d798a37',
        DIN: '2252007',
        genericName: 'RISPERIDONE TABLET 0.25 MG ORA',
        supplierCode: 'PMS',
        brandName: 'RISPERIDONE TB 0.25MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '72',
        packPrice: 10.36,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79b6e0',
        DIN: '2282119',
        genericName: 'RISPERIDONE TABLET 0.25 MG ORA',
        supplierCode: 'APX',
        brandName: 'RISPERIDONE TB 0.25MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '64',
        packPrice: 10.36,
        dailyAverageDispensed: 3,
      },
      RAN: {
        _id: '6201ba1ff65f11ad3d78f491',
        DIN: '2328305',
        genericName: 'RISPERIDONE TABLET 0.25 MG ORA',
        supplierCode: 'RAN',
        brandName: 'RISPERIDONE TB 0.25MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '62',
        packPrice: 10.36,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba27f65f11ad3d79ca69',
        DIN: '2303655',
        genericName: 'RISPERIDONE TABLET 0.25 MG ORA',
        supplierCode: 'SDZ',
        brandName: 'RISPERIDONE TB 0.25MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 10.36,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d78f972',
        DIN: '2359529',
        genericName: 'RISPERIDONE TABLET 0.25 MG ORA',
        supplierCode: 'JAP',
        brandName: 'RISPERIDONE TB 0.25MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '57',
        packPrice: 10.36,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba1ff65f11ad3d78f83a',
        DIN: '2359790',
        genericName: 'RISPERIDONE TABLET 0.25 MG ORA',
        supplierCode: 'MPH',
        brandName: 'RISPERIDONE TB 0.25MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 10.36,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d790b71',
        DIN: '2371766',
        genericName: 'RISPERIDONE TABLET 0.25 MG ORA',
        supplierCode: 'MRC',
        brandName: 'RISPERIDONE TB 0.25MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 10.36,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba26f65f11ad3d79b75a',
        DIN: '2282690',
        genericName: 'RISPERIDONE TABLET 0.25 MG ORA',
        supplierCode: 'TEV',
        brandName: 'RISPERIDONE TB 0.25MG UD 60 TEVA',
        form: 'TABLET',
        packSize: '60',
        rebateValue: '1',
        packPrice: 15.69,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d79167a',
        DIN: '2356880',
        genericName: 'RISPERIDONE TABLET 0.25 MG ORA',
        supplierCode: 'SNS',
        brandName: 'RISPERIDONE TB 0.25MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 10.36,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '43714',
    genericName: 'PERINDOPRIL ERBUMIN/INDAPAMIDE',
    strength: '2/0.625MG',
    unitPrice: 0.964,
    MFRs: {
      SDZ: {
        _id: '6201ba23f65f11ad3d7957e2',
        DIN: '2470411',
        genericName: 'PERINDOPRIL ERBUMIN/INDAPAMIDE',
        supplierCode: 'SDZ',
        brandName: 'PERINDOPRIL ERBUM/INDAP TB 2MG/.625MG PLQ 1X30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '55',
        packPrice: 19.01,
        dailyAverageDispensed: 3,
      },
      SEV: {
        _id: '6201ba27f65f11ad3d79c26c',
        DIN: '2246568',
        genericName: 'PERINDOPRIL ERBUMIN/INDAPAMIDE',
        supplierCode: 'SEV',
        brandName: 'COVERSYL PLUS LD TB 2/0.625MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 28.93,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '43801',
    genericName: 'PROGESTERONE,MICRONIZED CAPSUL',
    strength: '100MG',
    unitPrice: 1.683,
    MFRs: {
      PMS: {
        _id: '6201ba23f65f11ad3d79673b',
        DIN: '2476576',
        genericName: 'PROGESTERONE,MICRONIZED CAPSUL',
        supplierCode: 'PMS',
        brandName: 'PROGESTERONE GELCAPS 100MGG BLST 2X15 PMS',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '45',
        packPrice: 43.07,
        dailyAverageDispensed: 3,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d792d32',
        DIN: '2439913',
        genericName: 'PROGESTERONE,MICRONIZED CAPSUL',
        supplierCode: 'TEV',
        brandName: 'PROGESTERONE CAPS 100MG UD BLST 30 TEVA',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '1',
        packPrice: 43.07,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba20f65f11ad3d790ff3',
        DIN: '2166704',
        genericName: 'PROGESTERONE,MICRONIZED CAPSUL',
        supplierCode: 'ORN',
        brandName: 'PROMETRIUM SF CAPS 100MG BLST 30',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 50.5,
        dailyAverageDispensed: 0,
      },
      DRD: {
        _id: '6201ba24f65f11ad3d798741',
        DIN: '2463113',
        genericName: 'PROGESTERONE,MICRONIZED CAPSUL',
        supplierCode: 'DRD',
        brandName: 'PROGESTERONE CAPS 100MG BLS 2X15 REDDY',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 43.07,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba25f65f11ad3d7991dd',
        DIN: '2493578',
        genericName: 'PROGESTERONE,MICRONIZED CAPSUL',
        supplierCode: 'AUP',
        brandName: 'PROGESTERONE CAPS 100MG BLST 3X10 AURO',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 43.07,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '46242',
    genericName: 'TRAZODONE HCL TABLET 100 MG OR',
    strength: '100MG',
    unitPrice: 0.099,
    MFRs: {
      TEV: {
        _id: '6201ba27f65f11ad3d79d9b5',
        DIN: '2144271',
        genericName: 'TRAZODONE HCL TABLET 100 MG OR',
        supplierCode: 'TEV',
        brandName: 'TRAZODONE HCL FC TB 100MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 9.89,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba28f65f11ad3d79dac0',
        DIN: '1937235',
        genericName: 'TRAZODONE HCL TABLET 100 MG OR',
        supplierCode: 'PMS',
        brandName: 'TRAZODONE TB 100MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '45',
        packPrice: 9.89,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba28f65f11ad3d79e170',
        DIN: '2147645',
        genericName: 'TRAZODONE HCL TABLET 100 MG OR',
        supplierCode: 'APX',
        brandName: 'TRAZODONE TB 100MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 9.89,
        dailyAverageDispensed: 3,
      },
      JAP: {
        _id: '6201ba26f65f11ad3d79b2af',
        DIN: '2442817',
        genericName: 'TRAZODONE HCL TABLET 100 MG OR',
        supplierCode: 'JAP',
        brandName: 'TRAZODONE TB 100MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 9.89,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f4d9',
        DIN: '2348780',
        genericName: 'TRAZODONE HCL TABLET 100 MG OR',
        supplierCode: 'SNS',
        brandName: 'TRAZODONE TB 100MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 9.89,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '47453',
    genericName: 'MIRTAZAPINE TAB RAPDIS 15 MG O',
    strength: '15MG',
    unitPrice: 0.584,
    MFRs: {
      ORN: {
        _id: '6201ba26f65f11ad3d79b851',
        DIN: '2248542',
        genericName: 'MIRTAZAPINE TAB RAPDIS 15 MG O',
        supplierCode: 'ORN',
        brandName: 'REMERON RD TB 15MG UD 30',
        form: 'TAB ORAL DISINT',
        packSize: '30',
        rebateValue: null,
        packPrice: 17.51,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba1ff65f11ad3d78f73c',
        DIN: '2299801',
        genericName: 'MIRTAZAPINE TAB RAPDIS 15 MG O',
        supplierCode: 'AUP',
        brandName: 'MIRTAZAPINE OD TB 15MG UD 30 AURO',
        form: 'TAB ORAL DISINT',
        packSize: '30',
        rebateValue: null,
        packPrice: 12.14,
        dailyAverageDispensed: 3,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '53006',
    genericName: 'BUPROPION HCL TAB ER 24H 150 M',
    strength: '150MG',
    unitPrice: 0.624,
    MFRs: {
      RAN: {
        _id: '6201ba24f65f11ad3d79746a',
        DIN: '2475804',
        genericName: 'BUPROPION HCL TAB ER 24H 150 M',
        supplierCode: 'RAN',
        brandName: 'BUPROPION XL TB 150MG 90 TARO',
        form: 'TABLET EXT REL',
        packSize: '90',
        rebateValue: '57',
        packPrice: 13.17,
        dailyAverageDispensed: 2,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d79348b',
        DIN: '2439654',
        genericName: 'BUPROPION HCL TAB ER 24H 150 M',
        supplierCode: 'TEV',
        brandName: 'BUPROPION XL TB 150MG 90 ACT',
        form: 'TABLET EXT REL',
        packSize: '90',
        rebateValue: '1',
        packPrice: 13.17,
        dailyAverageDispensed: 1,
      },
      BAU: {
        _id: '6201ba1ff65f11ad3d790194',
        DIN: '2275090',
        genericName: 'BUPROPION HCL TAB ER 24H 150 M',
        supplierCode: 'BAU',
        brandName: 'WELLBUTRIN XL TB 150MG 90',
        form: 'TABLET EXT REL',
        packSize: '90',
        rebateValue: null,
        packPrice: 56.17,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '53518',
    genericName: 'BETAHISTINE HCL TABLET 24 MG O',
    strength: '24MG',
    unitPrice: 0.794,
    MFRs: {
      TEV: {
        _id: '6201ba21f65f11ad3d79260b',
        DIN: '2280205',
        genericName: 'BETAHISTINE HCL TABLET 24 MG O',
        supplierCode: 'TEV',
        brandName: 'BETAHISTINE TB 24MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 49.83,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba20f65f11ad3d791438',
        DIN: '2330237',
        genericName: 'BETAHISTINE HCL TABLET 24 MG O',
        supplierCode: 'PMS',
        brandName: 'BETAHISTINE TB 24MG BLS 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '54',
        packPrice: 49.83,
        dailyAverageDispensed: 0,
      },
      BGP: {
        _id: '6201ba27f65f11ad3d79bee9',
        DIN: '2247998',
        genericName: 'BETAHISTINE HCL TABLET 24 MG O',
        supplierCode: 'BGP',
        brandName: 'SERC TB 24MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 79.39,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d79346c',
        DIN: '2449161',
        genericName: 'BETAHISTINE HCL TABLET 24 MG O',
        supplierCode: 'AUP',
        brandName: 'BETAHISTINE TB 24MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 49.83,
        dailyAverageDispensed: 3,
      },
      SNS: {
        _id: '6201ba23f65f11ad3d79594f',
        DIN: '2466457',
        genericName: 'BETAHISTINE HCL TABLET 24 MG O',
        supplierCode: 'SNS',
        brandName: 'BETAHISTINE TB 24MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 49.83,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '58238',
    genericName: 'GALANTAMINE HBR CAP24H PEL 8 M',
    strength: '8MG',
    unitPrice: 1.246,
    MFRs: {
      JNO: {
        _id: '6201ba1ff65f11ad3d78f662',
        DIN: '2316943',
        genericName: 'GALANTAMINE HBR CAP24H PEL 8 M',
        supplierCode: 'JNO',
        brandName: 'GALANTAMINE ER CAPS 8MG 30 PAT',
        form: 'CAPSULE EXT REL',
        packSize: '30',
        rebateValue: null,
        packPrice: 37.39,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba1ff65f11ad3d78f4f5',
        DIN: '2339439',
        genericName: 'GALANTAMINE HBR CAP24H PEL 8 M',
        supplierCode: 'MYL',
        brandName: 'GALANTAMINE ER CAPS 8MG 30 MYLAN',
        form: 'CAPSULE EXT REL',
        packSize: '30',
        rebateValue: null,
        packPrice: 37.4,
        dailyAverageDispensed: 3,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d7931aa',
        DIN: '2425157',
        genericName: 'GALANTAMINE HBR CAP24H PEL 8 M',
        supplierCode: 'AUP',
        brandName: 'GALANTAMINE ER CAPS 8MG 30 AURO',
        form: 'CAPSULE EXT REL',
        packSize: '30',
        rebateValue: null,
        packPrice: 37.4,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d79378d',
        DIN: '2443015',
        genericName: 'GALANTAMINE HBR CAP24H PEL 8 M',
        supplierCode: 'SNS',
        brandName: 'GALANTAMINE ER CAPS 8MG 100 SNS',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 124.67,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '63371',
    genericName: 'ENALAPRIL SODIUM TABLET 16MG(2',
    strength: '20MG',
    unitPrice: 0.32,
    MFRs: {
      RAN: {
        _id: '6201ba1ff65f11ad3d78f667',
        DIN: '2352265',
        genericName: 'ENALAPRIL SODIUM TABLET 16MG(2',
        supplierCode: 'RAN',
        brandName: 'ENALAPRIL TB 20MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 31.95,
        dailyAverageDispensed: 3,
      },
      JAP: {
        _id: '6201ba23f65f11ad3d79709e',
        DIN: '2474816',
        genericName: 'ENALAPRIL SODIUM TABLET 16MG(2',
        supplierCode: 'JAP',
        brandName: 'ENALAPRIL TB 20MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 31.95,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c4fb',
        DIN: '2019906',
        genericName: 'ENALAPRIL SODIUM TABLET 16MG(2',
        supplierCode: 'APX',
        brandName: 'ENALAPRIL TB 20MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '64',
        packPrice: 31.95,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba24f65f11ad3d79714a',
        DIN: '2444798',
        genericName: 'ENALAPRIL SODIUM TABLET 16MG(2',
        supplierCode: 'MRC',
        brandName: 'ENALAPRIL TB 20MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '63',
        packPrice: 31.95,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba27f65f11ad3d79cb84',
        DIN: '2299976',
        genericName: 'ENALAPRIL SODIUM TABLET 16MG(2',
        supplierCode: 'SDZ',
        brandName: 'ENALAPRIL TB 20MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 31.95,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79cc37',
        DIN: '2291908',
        genericName: 'ENALAPRIL SODIUM TABLET 16MG(2',
        supplierCode: 'TEV',
        brandName: 'ENALAPRIL TB 20MG 100 ACT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 31.95,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79b522',
        DIN: '2400685',
        genericName: 'ENALAPRIL SODIUM TABLET 16MG(2',
        supplierCode: 'SNS',
        brandName: 'ENALAPRIL TB 20MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 31.95,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba21f65f11ad3d7921a3',
        DIN: '670928',
        genericName: 'ENALAPRIL SODIUM TABLET 16MG(2',
        supplierCode: 'ORN',
        brandName: 'VASOTEC TB 20MG 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 52.19,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '64846',
    genericName: 'SILODOSIN CAPSULE 4 MG ORAL',
    strength: '4MG',
    unitPrice: 0.948,
    MFRs: {
      PMS: {
        _id: '6201ba26f65f11ad3d79af8e',
        DIN: '2517779',
        genericName: 'SILODOSIN CAPSULE 4 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'SILODOSIN CAPS 4MG 30 PMS',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '50',
        packPrice: 28.45,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba25f65f11ad3d799c16',
        DIN: '2475421',
        genericName: 'SILODOSIN CAPSULE 4 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'SILODOSIN CAPS 4MG 30 SDZ',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '1',
        packPrice: 28.45,
        dailyAverageDispensed: 3,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '65334',
    genericName: 'ZOLPIDEM TARTRATE TAB SUBL 10',
    strength: '10MG',
    unitPrice: 1.489,
    MFRs: {
      PMS: {
        _id: '6201ba22f65f11ad3d793eeb',
        DIN: '2436183',
        genericName: 'ZOLPIDEM TARTRATE TAB SUBL 10',
        supplierCode: 'PMS',
        brandName: 'ZOLPIDEM ODT 10MG BLST 30 PMS',
        form: 'TAB ORAL DISINT',
        packSize: '30',
        rebateValue: '50',
        packPrice: 35.65,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba21f65f11ad3d792255',
        DIN: '2434946',
        genericName: 'ZOLPIDEM TARTRATE TAB SUBL 10',
        supplierCode: 'APX',
        brandName: 'ZOLPIDEM ODT 10MG BLST 30 APO',
        form: 'TAB ORAL DISINT',
        packSize: '30',
        rebateValue: '1',
        packPrice: 35.65,
        dailyAverageDispensed: 3,
      },
      MPH: {
        _id: '6201ba25f65f11ad3d79a0b9',
        DIN: '2472848',
        genericName: 'ZOLPIDEM TARTRATE TAB SUBL 10',
        supplierCode: 'MPH',
        brandName: 'ZOLPIDEM ODT TB SUBL 10MG UD BLST 30 MINT',
        form: 'TAB SUBL',
        packSize: '30',
        rebateValue: '1',
        packPrice: 35.65,
        dailyAverageDispensed: 0,
      },
      BAU: {
        _id: '6201ba1ff65f11ad3d78fd87',
        DIN: '2370433',
        genericName: 'ZOLPIDEM TARTRATE TAB SUBL 10',
        supplierCode: 'BAU',
        brandName: 'SUBLINOX TB 10MG 30',
        form: 'TAB ORAL DISINT',
        packSize: '30',
        rebateValue: null,
        packPrice: 44.66,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '65431',
    genericName: 'SAXAGLIPTIN HCL TABLET 5 MG OR',
    strength: '5MG',
    unitPrice: 3.206,
    MFRs: {
      SDZ: {
        _id: '6201ba25f65f11ad3d79a0d8',
        DIN: '2468611',
        genericName: 'SAXAGLIPTIN HCL TABLET 5 MG OR',
        supplierCode: 'SDZ',
        brandName: 'SAXAGLIPTIN TB 5MG UD BLST 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 45.59,
        dailyAverageDispensed: 2,
      },
      APX: {
        _id: '6201ba25f65f11ad3d79a1b3',
        DIN: '2507498',
        genericName: 'SAXAGLIPTIN HCL TABLET 5 MG OR',
        supplierCode: 'APX',
        brandName: 'SAXAGLIPTIN TB 5MG UD BLST 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 45.59,
        dailyAverageDispensed: 1,
      },
      AST: {
        _id: '6201ba21f65f11ad3d791fcd',
        DIN: '2333554',
        genericName: 'SAXAGLIPTIN HCL TABLET 5 MG OR',
        supplierCode: 'AST',
        brandName: 'ONGLYZA TB 5MG 3X10',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 96.19,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '66815',
    genericName: 'RISEDRONATE SODIUM TABLET DR 3',
    strength: '35MG',
    unitPrice: 12.52,
    MFRs: {
      APX: {
        _id: '6201ba26f65f11ad3d79a585',
        DIN: '2493268',
        genericName: 'RISEDRONATE SODIUM TABLET DR 3',
        supplierCode: 'APX',
        brandName: 'RISEDRONATE DR TB 35MG UD BLST 4 APO',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '1',
        packPrice: 18.98,
        dailyAverageDispensed: 3,
      },
      ALL: {
        _id: '6201ba24f65f11ad3d797f09',
        DIN: '2370417',
        genericName: 'RISEDRONATE SODIUM TABLET DR 3',
        supplierCode: 'ALL',
        brandName: 'ACTONEL DR TB 35MG BLS 4',
        form: 'TAB DEL RELEASE',
        packSize: '4',
        rebateValue: null,
        packPrice: 50.08,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '73369',
    genericName: 'PERINDOPRIL ARG/AMLODIPINE BES',
    strength: '3.5/2.5MG',
    unitPrice: 1.056,
    MFRs: {
      APX: {
        _id: '6201ba24f65f11ad3d79755e',
        DIN: '2468565',
        genericName: 'PERINDOPRIL ARG/AMLODIPINE BES',
        supplierCode: 'APX',
        brandName: 'PERINDOPRIL/AMLODIPINE TB 3.5/2.5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 80.75,
        dailyAverageDispensed: 3,
      },
      SEV: {
        _id: '6201ba21f65f11ad3d79318d',
        DIN: '2451530',
        genericName: 'PERINDOPRIL ARG/AMLODIPINE BES',
        supplierCode: 'SEV',
        brandName: 'VIACORAM TB 3.5MG/2.5MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 31.69,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '73371',
    genericName: 'PERINDOPRIL ARG/AMLODIPINE BES',
    strength: '7/5MG',
    unitPrice: 1.167,
    MFRs: {
      APX: {
        _id: '6201ba24f65f11ad3d79756d',
        DIN: '2468573',
        genericName: 'PERINDOPRIL ARG/AMLODIPINE BES',
        supplierCode: 'APX',
        brandName: 'PERINDOPRIL/AMLODIPINE TB 7MG/5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 89.25,
        dailyAverageDispensed: 3,
      },
      SEV: {
        _id: '6201ba21f65f11ad3d793185',
        DIN: '2451549',
        genericName: 'PERINDOPRIL ARG/AMLODIPINE BES',
        supplierCode: 'SEV',
        brandName: 'VIACORAM TB 7MG/5MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 35.01,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 3,
      total: 0,
    },
  },
  {
    groupCode: '263',
    genericName: 'FLECAINIDE ACETATE TABLET 100',
    strength: '100MG',
    unitPrice: 0.278,
    MFRs: {
      APX: {
        _id: '6201ba22f65f11ad3d795315',
        DIN: '2275546',
        genericName: 'FLECAINIDE ACETATE TABLET 100',
        supplierCode: 'APX',
        brandName: 'FLECAINIDE TB 100MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '30',
        packPrice: 27.79,
        dailyAverageDispensed: 2,
      },
      JAP: {
        _id: '6201ba25f65f11ad3d79981f',
        DIN: '2493713',
        genericName: 'FLECAINIDE ACETATE TABLET 100',
        supplierCode: 'JAP',
        brandName: 'FLECAINIDE TB 100MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '30',
        packPrice: 27.79,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d794cc3',
        DIN: '2459965',
        genericName: 'FLECAINIDE ACETATE TABLET 100',
        supplierCode: 'AUP',
        brandName: 'FLECAINIDE TB 100MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 27.79,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '302',
    genericName: 'TERAZOSIN HCL TABLET 10 MG ORA',
    strength: '10MG',
    unitPrice: 0.464,
    MFRs: {
      TEV: {
        _id: '6201ba20f65f11ad3d790a27',
        DIN: '2230808',
        genericName: 'TERAZOSIN HCL TABLET 10 MG ORA',
        supplierCode: 'TEV',
        brandName: 'TERAZOSIN TB 10MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 46.37,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba26f65f11ad3d79bcb5',
        DIN: '2243521',
        genericName: 'TERAZOSIN HCL TABLET 10 MG ORA',
        supplierCode: 'PMS',
        brandName: 'TERAZOSIN TB 10MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '45',
        packPrice: 46.37,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c991',
        DIN: '2234505',
        genericName: 'TERAZOSIN HCL TABLET 10 MG ORA',
        supplierCode: 'APX',
        brandName: 'TERAZOSIN TB 10MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 46.37,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '393',
    genericName: 'LISINOPRIL TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 0.609,
    MFRs: {
      TEV: {
        _id: '6201ba27f65f11ad3d79cc57',
        DIN: '2285118',
        genericName: 'LISINOPRIL TABLET 5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'LISINOPRIL Z TB 5MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 13.47,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79b6f6',
        DIN: '2217481',
        genericName: 'LISINOPRIL TABLET 5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'LISINOPRIL TB 5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 13.47,
        dailyAverageDispensed: 2,
      },
      SGP: {
        _id: '6201ba27f65f11ad3d79c04f',
        DIN: '2049333',
        genericName: 'LISINOPRIL TABLET 5 MG ORAL',
        supplierCode: 'SGP',
        brandName: 'ZESTRIL TB 5MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 60.88,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790d14',
        DIN: '2394472',
        genericName: 'LISINOPRIL TABLET 5 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'LISINOPRIL TB 5MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 13.47,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '454',
    genericName: 'NITROGLYCERIN AEROSOL 0.4MG/DO',
    strength: '0.4MG/DOSE',
    unitPrice: 0.042,
    MFRs: {
      MYL: {
        _id: '6201ba25f65f11ad3d799319',
        DIN: '2243588',
        genericName: 'NITROGLYCERIN AEROSOL 0.4MG/DO',
        supplierCode: 'MYL',
        brandName: 'NITRO SUBLINGUAL SP 200DS MYLAN',
        form: 'AEROSOL MET DS',
        packSize: '200',
        rebateValue: null,
        packPrice: 8.46,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '466',
    genericName: 'NITROGLYCERIN PATCH TD24 0.6MG',
    strength: '0.6MG/HR',
    unitPrice: 0.504,
    MFRs: {
      DRD: {
        _id: '6201ba23f65f11ad3d795fdc',
        DIN: '1911929',
        genericName: 'NITROGLYCERIN PATCH TD24 0.6MG',
        supplierCode: 'DRD',
        brandName: 'NITRO DUR 0.6MG 30',
        form: 'PATCH MED.',
        packSize: '30',
        rebateValue: null,
        packPrice: 15.12,
        dailyAverageDispensed: 0,
      },
      BAU: {
        _id: '6201ba27f65f11ad3d79ce7c',
        DIN: '2163535',
        genericName: 'NITROGLYCERIN PATCH TD24 0.6MG',
        supplierCode: 'BAU',
        brandName: 'MINITRAN PATCH 0.6MG/HR 30',
        form: 'PATCH MED.',
        packSize: '30',
        rebateValue: null,
        packPrice: 24.74,
        dailyAverageDispensed: 0,
      },
      PLI: {
        _id: '6201ba23f65f11ad3d7965f4',
        DIN: '2230734',
        genericName: 'NITROGLYCERIN PATCH TD24 0.6MG',
        supplierCode: 'PLI',
        brandName: 'TRINIPATCH 0.6MG 30',
        form: 'PATCH MED.',
        packSize: '30',
        rebateValue: null,
        packPrice: 24.43,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba20f65f11ad3d7911bf',
        DIN: '2407469',
        genericName: 'NITROGLYCERIN PATCH TD24 0.6MG',
        supplierCode: 'MYL',
        brandName: 'NITRO PATCH 0.6MG/HR 30 MYLAN',
        form: 'PATCH MED.',
        packSize: '30',
        rebateValue: null,
        packPrice: 15.12,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '564',
    genericName: 'VERAPAMIL HCL TABLET 120 MG OR',
    strength: '120MG',
    unitPrice: 0.425,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79d3f7',
        DIN: '782491',
        genericName: 'VERAPAMIL HCL TABLET 120 MG OR',
        supplierCode: 'APX',
        brandName: 'VERAP TB 120MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 42.5,
        dailyAverageDispensed: 2,
      },
      MYL: {
        _id: '6201ba23f65f11ad3d795ac3',
        DIN: '2237922',
        genericName: 'VERAPAMIL HCL TABLET 120 MG OR',
        supplierCode: 'MYL',
        brandName: 'VERAPAMIL TB 120MG 100 MYLAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 42.5,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '568',
    genericName: 'NIFEDIPINE CAPSULE 10 MG ORAL',
    strength: '10MG',
    unitPrice: 0.515,
    MFRs: {
      AAA: {
        _id: '6201ba1ff65f11ad3d78f558',
        DIN: '755907',
        genericName: 'NIFEDIPINE CAPSULE 10 MG ORAL',
        supplierCode: 'AAA',
        brandName: 'NIFEDIPINE CAPS 10MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 51.46,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '1774',
    genericName: 'GLYBURIDE TABLET 2.5 MG ORAL',
    strength: '2.5MG',
    unitPrice: 0.032,
    MFRs: {
      APX: {
        _id: '6201ba25f65f11ad3d79a28f',
        DIN: '1913654',
        genericName: 'GLYBURIDE TABLET 2.5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'GLYBURIDE TB 2.5MG 500 APO',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '20',
        packPrice: 16.05,
        dailyAverageDispensed: 2,
      },
      TEV: {
        _id: '6201ba22f65f11ad3d794896',
        DIN: '1913670',
        genericName: 'GLYBURIDE TABLET 2.5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'GLYBURIDE TB 2.5MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 3.21,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f4c6',
        DIN: '2350459',
        genericName: 'GLYBURIDE TABLET 2.5 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'GLYBURIDE TB 2.5MG 500 SNS',
        form: 'TABLET',
        packSize: '500',
        rebateValue: null,
        packPrice: 16.05,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '4000',
    genericName: 'LITHIUM CARBONATE CAPSULE 150',
    strength: '150MG',
    unitPrice: 0.19,
    MFRs: {
      PMS: {
        _id: '6201ba26f65f11ad3d79b892',
        DIN: '2216132',
        genericName: 'LITHIUM CARBONATE CAPSULE 150',
        supplierCode: 'PMS',
        brandName: 'LITHIUM CARBONATE CAPS 150MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '50',
        packPrice: 6.67,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba25f65f11ad3d79a290',
        DIN: '2242837',
        genericName: 'LITHIUM CARBONATE CAPSULE 150',
        supplierCode: 'APX',
        brandName: 'LITHIUM CARBONATE CAPS 150MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '10',
        packPrice: 6.67,
        dailyAverageDispensed: 2,
      },
      ERF: {
        _id: '6201ba28f65f11ad3d79df34',
        DIN: '2013231',
        genericName: 'LITHIUM CARBONATE CAPSULE 150',
        supplierCode: 'ERF',
        brandName: 'LITHANE CAPS 150MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 18.99,
        dailyAverageDispensed: 0,
      },
      BAU: {
        _id: '6201ba27f65f11ad3d79d2b3',
        DIN: '461733',
        genericName: 'LITHIUM CARBONATE CAPSULE 150',
        supplierCode: 'BAU',
        brandName: 'CARBOLITH CAPS 150MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 14.45,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '4518',
    genericName: 'NALTREXONE HCL TABLET 50 MG OR',
    strength: '50MG',
    unitPrice: 2.808,
    MFRs: {
      APX: {
        _id: '6201ba21f65f11ad3d792bc0',
        DIN: '2444275',
        genericName: 'NALTREXONE HCL TABLET 50 MG OR',
        supplierCode: 'APX',
        brandName: 'NALTREXONE TB 50MG 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '40',
        packPrice: 84.23,
        dailyAverageDispensed: 2,
      },
      JAP: {
        _id: '6201ba22f65f11ad3d7948c9',
        DIN: '2451883',
        genericName: 'NALTREXONE HCL TABLET 50 MG OR',
        supplierCode: 'JAP',
        brandName: 'NALTREXONE HYDROCHLORIDE USP TB 50MG 28 JAMP',
        form: 'TABLET',
        packSize: '28',
        rebateValue: '25',
        packPrice: 78.61,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d791996',
        DIN: '2213826',
        genericName: 'NALTREXONE HCL TABLET 50 MG OR',
        supplierCode: 'TEV',
        brandName: 'REVIA TB 50MG 50',
        form: 'TABLET',
        packSize: '50',
        rebateValue: '1',
        packPrice: 140.38,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '4539',
    genericName: 'DIVALPROEX SODIUM TABLET DR 25',
    strength: '250MG',
    unitPrice: 0.277,
    MFRs: {
      APX: {
        _id: '6201ba26f65f11ad3d79ba8f',
        DIN: '2239699',
        genericName: 'DIVALPROEX SODIUM TABLET DR 25',
        supplierCode: 'APX',
        brandName: 'DIVALPROEX TB 250MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 27.67,
        dailyAverageDispensed: 2,
      },
      MYL: {
        _id: '6201ba22f65f11ad3d7945a5',
        DIN: '2458934',
        genericName: 'DIVALPROEX SODIUM TABLET DR 25',
        supplierCode: 'MYL',
        brandName: 'DIVALPROEX TB 250MG 100 MYLAN',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: null,
        packPrice: 27.67,
        dailyAverageDispensed: 0,
      },
      BGP: {
        _id: '6201ba26f65f11ad3d79be17',
        DIN: '596426',
        genericName: 'DIVALPROEX SODIUM TABLET DR 25',
        supplierCode: 'BGP',
        brandName: 'EPIVAL TB 250MG 100',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: null,
        packPrice: 62.78,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '4575',
    genericName: 'AMANTADINE HCL CAPSULE 100 MG',
    strength: '100MG',
    unitPrice: 0.554,
    MFRs: {
      PPI: {
        _id: '6201ba27f65f11ad3d79cdd9',
        DIN: '1990403',
        genericName: 'AMANTADINE HCL CAPSULE 100 MG',
        supplierCode: 'PPI',
        brandName: 'AMANTADINE CAPS 100MG 100 PDP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 55.41,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '4696',
    genericName: 'NABILONE CAPSULE 1 MG ORAL',
    strength: '1MG',
    unitPrice: 3.667,
    MFRs: {
      PMS: {
        _id: '6201ba1ff65f11ad3d7900cd',
        DIN: '2380919',
        genericName: 'NABILONE CAPSULE 1 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'NABILONE CAPS 1MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '50',
        packPrice: 366.69,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d79028e',
        DIN: '2384892',
        genericName: 'NABILONE CAPSULE 1 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'NABILONE CAPS 1MG 50 TEVA',
        form: 'CAPSULE',
        packSize: '50',
        rebateValue: '1',
        packPrice: 183.35,
        dailyAverageDispensed: 2,
      },
      BAU: {
        _id: '6201ba27f65f11ad3d79c88a',
        DIN: '548375',
        genericName: 'NABILONE CAPSULE 1 MG ORAL',
        supplierCode: 'BAU',
        brandName: 'CESAMET CAPS 1MG 50',
        form: 'CAPSULE',
        packSize: '50',
        rebateValue: null,
        packPrice: 386.86,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '5136',
    genericName: 'NADOLOL TABLET 40 MG ORAL',
    strength: '40MG',
    unitPrice: 0.238,
    MFRs: {
      MPH: {
        _id: '6201ba25f65f11ad3d7990a3',
        DIN: '2496380',
        genericName: 'NADOLOL TABLET 40 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'NADOLOL TB 40MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 23.75,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c90d',
        DIN: '782505',
        genericName: 'NADOLOL TABLET 40 MG ORAL',
        supplierCode: 'APX',
        brandName: 'NADOLOL TB 40MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 23.75,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '6675',
    genericName: 'METHIMAZOLE TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 0.337,
    MFRs: {
      JAP: {
        _id: '6201ba25f65f11ad3d79936d',
        DIN: '2490625',
        genericName: 'METHIMAZOLE TABLET 5 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'METHIMAZOLE TB 5MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 15.31,
        dailyAverageDispensed: 0,
      },
      PLI: {
        _id: '6201ba22f65f11ad3d7949dc',
        DIN: '15741',
        genericName: 'METHIMAZOLE TABLET 5 MG ORAL',
        supplierCode: 'PLI',
        brandName: 'TAPAZOLE TB 5MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 33.74,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba23f65f11ad3d796424',
        DIN: '2480107',
        genericName: 'METHIMAZOLE TABLET 5 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'METHIMAZOLE TB 5MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '0',
        packPrice: 15.31,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '6814',
    genericName: 'SPIRONOLACT/HYDROCHLOROTHIAZID',
    strength: '50-50MG',
    unitPrice: 0.228,
    MFRs: {
      TEV: {
        _id: '6201ba26f65f11ad3d79b930',
        DIN: '657182',
        genericName: 'SPIRONOLACT/HYDROCHLOROTHIAZID',
        supplierCode: 'TEV',
        brandName: 'SPIRONOLACTONE HCTZ TB 50MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 22.76,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '6816',
    genericName: 'SPIRONOLACTONE TABLET 100 MG O',
    strength: '100MG',
    unitPrice: 0.191,
    MFRs: {
      MPH: {
        _id: '6201ba24f65f11ad3d7986f0',
        DIN: '2488159',
        genericName: 'SPIRONOLACTONE TABLET 100 MG O',
        supplierCode: 'MPH',
        brandName: 'SPIRONOLACTONE TB 100MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 19.1,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba26f65f11ad3d79be03',
        DIN: '613223',
        genericName: 'SPIRONOLACTONE TABLET 100 MG O',
        supplierCode: 'TEV',
        brandName: 'SPIRONOLACTONE TB 100MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '15',
        packPrice: 19.1,
        dailyAverageDispensed: 2,
      },
      PFC: {
        _id: '6201ba20f65f11ad3d79195a',
        DIN: '285455',
        genericName: 'SPIRONOLACTONE TABLET 100 MG O',
        supplierCode: 'PFC',
        brandName: 'ALDACTONE TB 100MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 64.41,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '7361',
    genericName: 'CLOTRIMAZOLE CREAM (G) 1% TOPI',
    strength: '1%',
    unitPrice: 0.345,
    MFRs: {
      TAR: {
        _id: '6201ba26f65f11ad3d79be46',
        DIN: '812382',
        genericName: 'CLOTRIMAZOLE CREAM (G) 1% TOPI',
        supplierCode: 'TAR',
        brandName: 'CLOTRIMADERM CR 1% 20G',
        form: 'CREAM',
        packSize: '20',
        rebateValue: '1',
        packPrice: 6.13,
        dailyAverageDispensed: 2,
      },
      BAY: {
        _id: '6201ba27f65f11ad3d79d26b',
        DIN: '2150867',
        genericName: 'CLOTRIMAZOLE CREAM (G) 1% TOPI',
        supplierCode: 'BAY',
        brandName: 'CANESTEN CR TOPICAL ANTI FUNGAL 15G',
        form: 'CREAM',
        packSize: '15',
        rebateValue: null,
        packPrice: 6.49,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '7638',
    genericName: 'MOMETASONE FUROATE CREAM (G) 0',
    strength: '0.1%/W/W',
    unitPrice: 0.554,
    MFRs: {
      TAR: {
        _id: '6201ba1ff65f11ad3d78f8c1',
        DIN: '2367157',
        genericName: 'MOMETASONE FUROATE CREAM (G) 0',
        supplierCode: 'TAR',
        brandName: 'MOMETASONE CR 0.1% 15G TARO',
        form: 'CREAM',
        packSize: '15',
        rebateValue: '1',
        packPrice: 8.31,
        dailyAverageDispensed: 2,
      },
      ORN: {
        _id: '6201ba22f65f11ad3d794047',
        DIN: '851744',
        genericName: 'MOMETASONE FUROATE CREAM (G) 0',
        supplierCode: 'ORN',
        brandName: 'ELOCOM CR 0.1% 15G',
        form: 'CREAM',
        packSize: '15',
        rebateValue: null,
        packPrice: 11.7,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '7732',
    genericName: 'MUPIROCIN OINT. (G) 2% TOPICAL',
    strength: '2%',
    unitPrice: 0.413,
    MFRs: {
      TAR: {
        _id: '6201ba26f65f11ad3d79b8f8',
        DIN: '2279983',
        genericName: 'MUPIROCIN OINT. (G) 2% TOPICAL',
        supplierCode: 'TAR',
        brandName: 'MUPIROCIN OINT 2% 15G TARO',
        form: 'OINTMENT',
        packSize: '15',
        rebateValue: '1',
        packPrice: 6.19,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '8166',
    genericName: 'ACETAZOLAMIDE TABLET 250 MG OR',
    strength: '250MG',
    unitPrice: 0.149,
    MFRs: {
      AAA: {
        _id: '6201ba1ff65f11ad3d78f795',
        DIN: '545015',
        genericName: 'ACETAZOLAMIDE TABLET 250 MG OR',
        supplierCode: 'AAA',
        brandName: 'ACETAZOLAMIDE TB 250MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 14.92,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '8373',
    genericName: 'DICLOFENAC SODIUM TABLET DR 50',
    strength: '50MG',
    unitPrice: 0.206,
    MFRs: {
      PMS: {
        _id: '6201ba26f65f11ad3d79b857',
        DIN: '2302624',
        genericName: 'DICLOFENAC SODIUM TABLET DR 50',
        supplierCode: 'PMS',
        brandName: 'DICLOFENAC TB 50MG 100 PMS',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: '50',
        packPrice: 20.64,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d31a',
        DIN: '808547',
        genericName: 'DICLOFENAC SODIUM TABLET DR 50',
        supplierCode: 'TEV',
        brandName: 'DICLOFENAC SOD TB 50MG 100 TEVA',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: '1',
        packPrice: 20.64,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79d4ad',
        DIN: '839183',
        genericName: 'DICLOFENAC SODIUM TABLET DR 50',
        supplierCode: 'APX',
        brandName: 'DICLO TB 50MG 100 APO',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: '1',
        packPrice: 20.64,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '9049',
    genericName: 'CEPHALEXIN MONOHYDRATE TABLET',
    strength: '500MG',
    unitPrice: 0.173,
    MFRs: {
      TEV: {
        _id: '6201ba28f65f11ad3d79dbda',
        DIN: '583421',
        genericName: 'CEPHALEXIN MONOHYDRATE TABLET',
        supplierCode: 'TEV',
        brandName: 'CEPHALEXIN TB 500MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 17.31,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d79600c',
        DIN: '2470586',
        genericName: 'CEPHALEXIN MONOHYDRATE TABLET',
        supplierCode: 'AUP',
        brandName: 'CEPHALEXIN TB 500MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 17.31,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79af35',
        DIN: '768715',
        genericName: 'CEPHALEXIN MONOHYDRATE TABLET',
        supplierCode: 'APX',
        brandName: 'CEPHALEX TB 500MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '0',
        packPrice: 17.31,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '9537',
    genericName: 'NYSTATIN ORAL SUSP 100000/ML O',
    strength: '100000U/ML',
    unitPrice: 0.052,
    MFRs: {
      PMS: {
        _id: '6201ba27f65f11ad3d79d281',
        DIN: '792667',
        genericName: 'NYSTATIN ORAL SUSP 100000/ML O',
        supplierCode: 'PMS',
        brandName: 'NYSTATIN ORAL SUSP 100ML PMS',
        form: 'SUSPENSION',
        packSize: '100',
        rebateValue: '50',
        packPrice: 7.4,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d7924c3',
        DIN: '2433443',
        genericName: 'NYSTATIN ORAL SUSP 100000/ML O',
        supplierCode: 'JAP',
        brandName: 'NYSTATIN ORAL SUSP 100000U/ML 100ML JAMP',
        form: 'SUSPENSION',
        packSize: '100',
        rebateValue: '20',
        packPrice: 5.18,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba28f65f11ad3d79dd15',
        DIN: '2194201',
        genericName: 'NYSTATIN ORAL SUSP 100000/ML O',
        supplierCode: 'TEV',
        brandName: 'NYSTATIN ORAL SUSP 100000U/ML 100ML TEVA',
        form: 'SUSPENSION',
        packSize: '100',
        rebateValue: '1',
        packPrice: 5.18,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '11673',
    genericName: 'RANITIDINE HCL TABLET 150 MG O',
    strength: '150MG',
    unitPrice: 0.12,
    MFRs: {
      PMS: {
        _id: '6201ba28f65f11ad3d79e156',
        DIN: '2242453',
        genericName: 'RANITIDINE HCL TABLET 150 MG O',
        supplierCode: 'PMS',
        brandName: 'RANITIDINE TB 150MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 11.97,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba23f65f11ad3d7967cf',
        DIN: '2443708',
        genericName: 'RANITIDINE HCL TABLET 150 MG O',
        supplierCode: 'MRC',
        brandName: 'RANITIDINE TB 150MG 500 MAR',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '1',
        packPrice: 59.85,
        dailyAverageDispensed: 2,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c041',
        DIN: '733059',
        genericName: 'RANITIDINE HCL TABLET 150 MG O',
        supplierCode: 'APX',
        brandName: 'RANITIDINE TB 150MG 500 APO',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '1',
        packPrice: 59.85,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '11822',
    genericName: 'MEXILETINE HCL CAPSULE 100 MG',
    strength: '100MG',
    unitPrice: 0.816,
    MFRs: {
      TEV: {
        _id: '6201ba27f65f11ad3d79cb41',
        DIN: '2230359',
        genericName: 'MEXILETINE HCL CAPSULE 100 MG',
        supplierCode: 'TEV',
        brandName: 'MEXILETINE HCL CAPS 100MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 81.62,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '11824',
    genericName: 'PRAZOSIN HCL TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 0.512,
    MFRs: {
      TEV: {
        _id: '6201ba22f65f11ad3d794e31',
        DIN: '1934228',
        genericName: 'PRAZOSIN HCL TABLET 5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'PRAZOSIN HCL TB 5MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 51.21,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79b71f',
        DIN: '882836',
        genericName: 'PRAZOSIN HCL TABLET 5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'PRAZO TB 5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 51.21,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '13646',
    genericName: 'PROPAFENONE HCL TABLET 150 MG',
    strength: '150MG',
    unitPrice: 0.297,
    MFRs: {
      APX: {
        _id: '6201ba26f65f11ad3d79bb33',
        DIN: '2243324',
        genericName: 'PROPAFENONE HCL TABLET 150 MG',
        supplierCode: 'APX',
        brandName: 'PROPAFENONE TB 150MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 29.66,
        dailyAverageDispensed: 2,
      },
      SNS: {
        _id: '6201ba23f65f11ad3d7956c3',
        DIN: '2343053',
        genericName: 'PROPAFENONE HCL TABLET 150 MG',
        supplierCode: 'SNS',
        brandName: 'PROPAFENONE TB 150MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 29.66,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba22f65f11ad3d7950ae',
        DIN: '2457172',
        genericName: 'PROPAFENONE HCL TABLET 150 MG',
        supplierCode: 'MYL',
        brandName: 'PROPAFENONE TB 150MG 100 MYLAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 29.66,
        dailyAverageDispensed: 0,
      },
      BGP: {
        _id: '6201ba26f65f11ad3d79bcc0',
        DIN: '603708',
        genericName: 'PROPAFENONE HCL TABLET 150 MG',
        supplierCode: 'BGP',
        brandName: 'RYTHMOL TB 150MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 138.98,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '14097',
    genericName: 'TRIAMTERENE/HYDROCHLOROTHIAZID',
    strength: '50-25MG',
    unitPrice: 0.061,
    MFRs: {
      APX: {
        _id: '6201ba26f65f11ad3d79be64',
        DIN: '441775',
        genericName: 'TRIAMTERENE/HYDROCHLOROTHIAZID',
        supplierCode: 'APX',
        brandName: 'TRIAZIDE TB 50-25MG 1000 APO',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '1',
        packPrice: 60.8,
        dailyAverageDispensed: 2,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d8e3',
        DIN: '532657',
        genericName: 'TRIAMTERENE/HYDROCHLOROTHIAZID',
        supplierCode: 'TEV',
        brandName: 'TRIAMTERENE/HCTZ TB 50/25MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 6.08,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '16017',
    genericName: 'FOSINOPRIL SODIUM TABLET 10 MG',
    strength: '10MG',
    unitPrice: 0.218,
    MFRs: {
      TEV: {
        _id: '6201ba27f65f11ad3d79c08f',
        DIN: '2247802',
        genericName: 'FOSINOPRIL SODIUM TABLET 10 MG',
        supplierCode: 'TEV',
        brandName: 'FOSINOPRIL TB 10MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 21.78,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79d760',
        DIN: '2266008',
        genericName: 'FOSINOPRIL SODIUM TABLET 10 MG',
        supplierCode: 'APX',
        brandName: 'FOSINOPRIL TB 10MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 21.78,
        dailyAverageDispensed: 2,
      },
      SNS: {
        _id: '6201ba22f65f11ad3d7944ac',
        DIN: '2459388',
        genericName: 'FOSINOPRIL SODIUM TABLET 10 MG',
        supplierCode: 'SNS',
        brandName: 'FOSINOPRIL TB 10MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 21.77,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '16043',
    genericName: 'CARBIDOPA/LEVODOPA TABLET ER 5',
    strength: '200-50MG',
    unitPrice: 0.751,
    MFRs: {
      AAA: {
        _id: '6201ba25f65f11ad3d798f12',
        DIN: '2245211',
        genericName: 'CARBIDOPA/LEVODOPA TABLET ER 5',
        supplierCode: 'AAA',
        brandName: 'LEVOCARB CR TB 200-50MG 100 AA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 75.07,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '16441',
    genericName: 'METFORMIN HCL TABLET 850 MG OR',
    strength: '850MG',
    unitPrice: 0.388,
    MFRs: {
      SDZ: {
        _id: '6201ba22f65f11ad3d7948bc',
        DIN: '2246821',
        genericName: 'METFORMIN HCL TABLET 850 MG OR',
        supplierCode: 'SDZ',
        brandName: 'METFORMIN FC TB 850MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 20.9,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d790495',
        DIN: '2380218',
        genericName: 'METFORMIN HCL TABLET 850 MG OR',
        supplierCode: 'JAP',
        brandName: 'METFORMIN TB 850MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 20.9,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba28f65f11ad3d79e05e',
        DIN: '2242589',
        genericName: 'METFORMIN HCL TABLET 850 MG OR',
        supplierCode: 'PMS',
        brandName: 'METFORMIN TB 850MG 500 PMS',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '25',
        packPrice: 104.5,
        dailyAverageDispensed: 2,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d175',
        DIN: '2257734',
        genericName: 'METFORMIN HCL TABLET 850 MG OR',
        supplierCode: 'TEV',
        brandName: 'METFORMIN TB 850MG 100 ACT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 20.9,
        dailyAverageDispensed: 0,
      },
      SAC: {
        _id: '6201ba26f65f11ad3d79ba8e',
        DIN: '2162849',
        genericName: 'METFORMIN HCL TABLET 850 MG OR',
        supplierCode: 'SAC',
        brandName: 'GLUCOPHAGE TB 850MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 38.76,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba22f65f11ad3d794740',
        DIN: '2353385',
        genericName: 'METFORMIN HCL TABLET 850 MG OR',
        supplierCode: 'SNS',
        brandName: 'METFORMIN TB 850MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 20.9,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d794596',
        DIN: '2438283',
        genericName: 'METFORMIN HCL TABLET 850 MG OR',
        supplierCode: 'AUP',
        brandName: 'METFORMIN TB 850MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 20.9,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '16571',
    genericName: 'DILTIAZEM HCL CAP ER 24H 240 M',
    strength: '240MG',
    unitPrice: 0.64,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79c96b',
        DIN: '2230999',
        genericName: 'DILTIAZEM HCL CAP ER 24H 240 M',
        supplierCode: 'APX',
        brandName: 'DILTIAZ CD CAPS 240MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 63.99,
        dailyAverageDispensed: 2,
      },
      TEV: {
        _id: '6201ba28f65f11ad3d79e075',
        DIN: '2242540',
        genericName: 'DILTIAZEM HCL CAP ER 24H 240 M',
        supplierCode: 'TEV',
        brandName: 'DILTIAZEM HCL CD CAPS 240MG 100 TEVA',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 63.99,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba24f65f11ad3d798988',
        DIN: '2484080',
        genericName: 'DILTIAZEM HCL CAP ER 24H 240 M',
        supplierCode: 'MRC',
        brandName: 'DILTIAZEM CD CAPS 240MG 100 MAR',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 63.99,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '16598',
    genericName: 'NITROFURANTOIN MONOHYD/M-CRYST',
    strength: '100MG',
    unitPrice: 0.597,
    MFRs: {
      PMS: {
        _id: '6201ba23f65f11ad3d795b3c',
        DIN: '2455676',
        genericName: 'NITROFURANTOIN MONOHYD/M-CRYST',
        supplierCode: 'PMS',
        brandName: 'NITROFURANTOIN BID CAPS 100MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '50',
        packPrice: 59.74,
        dailyAverageDispensed: 2,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d79551e',
        DIN: '2466392',
        genericName: 'NITROFURANTOIN MONOHYD/M-CRYST',
        supplierCode: 'AUP',
        brandName: 'AURO NITROFURANTOIN BID CAPS 100MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 59.74,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '18638',
    genericName: 'TERBINAFINE HCL TABLET 250 MG',
    strength: '250MG',
    unitPrice: 4.859,
    MFRs: {
      PMS: {
        _id: '6201ba27f65f11ad3d79c7d3',
        DIN: '2294273',
        genericName: 'TERBINAFINE HCL TABLET 250 MG',
        supplierCode: 'PMS',
        brandName: 'TERBINAFINE TB 250MG UD 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 23.14,
        dailyAverageDispensed: 2,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c796',
        DIN: '2239893',
        genericName: 'TERBINAFINE HCL TABLET 250 MG',
        supplierCode: 'APX',
        brandName: 'TERBINAFINE TB 250MG UD 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 23.14,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba26f65f11ad3d79b74a',
        DIN: '2240346',
        genericName: 'TERBINAFINE HCL TABLET 250 MG',
        supplierCode: 'TEV',
        brandName: 'TERBINAFINE TB 250MG UD 28 TEVA',
        form: 'TABLET',
        packSize: '28',
        rebateValue: '1',
        packPrice: 21.6,
        dailyAverageDispensed: 0,
      },
      NPC: {
        _id: '6201ba27f65f11ad3d79c28a',
        DIN: '2031116',
        genericName: 'TERBINAFINE HCL TABLET 250 MG',
        supplierCode: 'NPC',
        brandName: 'LAMISIL TB 250MG 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 136.04,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba1ff65f11ad3d78fa36',
        DIN: '2320134',
        genericName: 'TERBINAFINE HCL TABLET 250 MG',
        supplierCode: 'AUP',
        brandName: 'TERBINAFINE TB 250MG UD 28 AURO',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 21.6,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f3de',
        DIN: '2353121',
        genericName: 'TERBINAFINE HCL TABLET 250 MG',
        supplierCode: 'SNS',
        brandName: 'TERBINAFINE TB 250MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 77.14,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '21411',
    genericName: 'ESTRADIOL TABLET 0.5 MG ORAL',
    strength: '0.5MG',
    unitPrice: 0.213,
    MFRs: {
      ACU: {
        _id: '6201ba24f65f11ad3d798c0a',
        DIN: '2225190',
        genericName: 'ESTRADIOL TABLET 0.5 MG ORAL',
        supplierCode: 'ACU',
        brandName: 'ESTRACE TB 0.5MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 21.27,
        dailyAverageDispensed: 0,
      },
      RIV: {
        _id: '6201ba21f65f11ad3d793377',
        DIN: '2449048',
        genericName: 'ESTRADIOL TABLET 0.5 MG ORAL',
        supplierCode: 'RIV',
        brandName: 'LUPIN ESTRADIOL TB 0.5MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 11.99,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '23885',
    genericName: 'CYCLOSPORINE, MODIFIED CAPSULE',
    strength: '50MG',
    unitPrice: 3.204,
    MFRs: {
      SDZ: {
        _id: '6201ba1ff65f11ad3d7902e9',
        DIN: '2247074',
        genericName: 'CYCLOSPORINE, MODIFIED CAPSULE',
        supplierCode: 'SDZ',
        brandName: 'CYCLOSPORINE CAPS 50MG BLS 30 SDZ',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '1',
        packPrice: 58.2,
        dailyAverageDispensed: 2,
      },
      NPC: {
        _id: '6201ba27f65f11ad3d79c9a8',
        DIN: '2150662',
        genericName: 'CYCLOSPORINE, MODIFIED CAPSULE',
        supplierCode: 'NPC',
        brandName: 'NEORAL CAPS 50MG 30',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 96.13,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '24536',
    genericName: 'DILTIAZEM HCL CAPSULE ER 120 M',
    strength: '120MG',
    unitPrice: 1.062,
    MFRs: {
      JAP: {
        _id: '6201ba25f65f11ad3d799062',
        DIN: '2495376',
        genericName: 'DILTIAZEM HCL CAPSULE ER 120 M',
        supplierCode: 'JAP',
        brandName: 'DILTIAZEM T CAPS 120MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '50',
        packPrice: 21.33,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d94e',
        DIN: '2271605',
        genericName: 'DILTIAZEM HCL CAPSULE ER 120 M',
        supplierCode: 'TEV',
        brandName: 'DILTIAZEM ER CAPS 120MG 100 TEVA',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 21.33,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba23f65f11ad3d795581',
        DIN: '2465353',
        genericName: 'DILTIAZEM HCL CAPSULE ER 120 M',
        supplierCode: 'MRC',
        brandName: 'DILTIAZEM T CAPS 120MG 100 MAR',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 21.33,
        dailyAverageDispensed: 2,
      },
      BAU: {
        _id: '6201ba27f65f11ad3d79bf27',
        DIN: '2231150',
        genericName: 'DILTIAZEM HCL CAPSULE ER 120 M',
        supplierCode: 'BAU',
        brandName: 'TIAZAC CAPS 120MG 100',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 106.2,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79ae89',
        DIN: '2516101',
        genericName: 'DILTIAZEM HCL CAPSULE ER 120 M',
        supplierCode: 'SNS',
        brandName: 'DILTIAZEM T CAPS EXTENDED RELEASE 120MG 100',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 21.33,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '24537',
    genericName: 'DILTIAZEM HCL CAPSULE ER 180 M',
    strength: '180MG',
    unitPrice: 1.419,
    MFRs: {
      JAP: {
        _id: '6201ba25f65f11ad3d799064',
        DIN: '2495384',
        genericName: 'DILTIAZEM HCL CAPSULE ER 180 M',
        supplierCode: 'JAP',
        brandName: 'DILTIAZEM T CAPS 180MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '50',
        packPrice: 28.89,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d950',
        DIN: '2271613',
        genericName: 'DILTIAZEM HCL CAPSULE ER 180 M',
        supplierCode: 'TEV',
        brandName: 'DILTIAZEM ER CAPS 180MG 100 TEVA',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 28.89,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba23f65f11ad3d79557f',
        DIN: '2465361',
        genericName: 'DILTIAZEM HCL CAPSULE ER 180 M',
        supplierCode: 'MRC',
        brandName: 'DILTIAZEM T CAPS 180MG 100 MAR',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 28.89,
        dailyAverageDispensed: 2,
      },
      BAU: {
        _id: '6201ba27f65f11ad3d79bf28',
        DIN: '2231151',
        genericName: 'DILTIAZEM HCL CAPSULE ER 180 M',
        supplierCode: 'BAU',
        brandName: 'TIAZAC CAPS 180MG 100',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 141.87,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79ae8f',
        DIN: '2516128',
        genericName: 'DILTIAZEM HCL CAPSULE ER 180 M',
        supplierCode: 'SNS',
        brandName: 'DILTIAZEM T CAPS EXTENDED RELEASE 180MG 100',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 28.89,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '24538',
    genericName: 'DILTIAZEM HCL CAPSULE ER 240 M',
    strength: '240MG',
    unitPrice: 1.882,
    MFRs: {
      JAP: {
        _id: '6201ba25f65f11ad3d799065',
        DIN: '2495392',
        genericName: 'DILTIAZEM HCL CAPSULE ER 240 M',
        supplierCode: 'JAP',
        brandName: 'DILTIAZEM T CAPS 240MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '50',
        packPrice: 38.32,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d951',
        DIN: '2271621',
        genericName: 'DILTIAZEM HCL CAPSULE ER 240 M',
        supplierCode: 'TEV',
        brandName: 'DILTIAZEM ER CAPS 240MG 100 TEVA',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 38.32,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba23f65f11ad3d79556b',
        DIN: '2465388',
        genericName: 'DILTIAZEM HCL CAPSULE ER 240 M',
        supplierCode: 'MRC',
        brandName: 'DILTIAZEM T CAPS 240MG 100 MAR',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 38.32,
        dailyAverageDispensed: 1,
      },
      BAU: {
        _id: '6201ba27f65f11ad3d79bf2a',
        DIN: '2231152',
        genericName: 'DILTIAZEM HCL CAPSULE ER 240 M',
        supplierCode: 'BAU',
        brandName: 'TIAZAC CAPS 240MG 100',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 188.17,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79ae90',
        DIN: '2516136',
        genericName: 'DILTIAZEM HCL CAPSULE ER 240 M',
        supplierCode: 'SNS',
        brandName: 'DILTIAZEM T CAPS EXTENDED RELEASE 240MG 100',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 38.32,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '26171',
    genericName: 'TOPIRAMATE TABLET 200 MG ORAL',
    strength: '200MG',
    unitPrice: 5.114,
    MFRs: {
      TEV: {
        _id: '6201ba28f65f11ad3d79db7e',
        DIN: '2248862',
        genericName: 'TOPIRAMATE TABLET 200 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'TOPIRAMATE TB 200MG 60 TEVA',
        form: 'TABLET',
        packSize: '60',
        rebateValue: '70',
        packPrice: 40.49,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba28f65f11ad3d79db6f',
        DIN: '2263017',
        genericName: 'TOPIRAMATE TABLET 200 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'TOPIRAMATE TB 200MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '68',
        packPrice: 67.48,
        dailyAverageDispensed: 1,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79b8cb',
        DIN: '2279649',
        genericName: 'TOPIRAMATE TABLET 200 MG ORAL',
        supplierCode: 'APX',
        brandName: 'TOPIRAMATE TB 200MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 67.48,
        dailyAverageDispensed: 1,
      },
      SDZ: {
        _id: '6201ba21f65f11ad3d79217b',
        DIN: '2431823',
        genericName: 'TOPIRAMATE TABLET 200 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'TOPIRAMATE TB 200MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 67.48,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba22f65f11ad3d793f69',
        DIN: '2435624',
        genericName: 'TOPIRAMATE TABLET 200 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'TOPIRAMATE TB 200MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 67.48,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba20f65f11ad3d79093c',
        DIN: '2395754',
        genericName: 'TOPIRAMATE TABLET 200 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'TOPIRAMATE TB 200MG 100 ACH',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 67.48,
        dailyAverageDispensed: 0,
      },
      JNO: {
        _id: '6201ba27f65f11ad3d79c119',
        DIN: '2230896',
        genericName: 'TOPIRAMATE TABLET 200 MG ORAL',
        supplierCode: 'JNO',
        brandName: 'TOPAMAX TB 200MG 60',
        form: 'TABLET',
        packSize: '60',
        rebateValue: null,
        packPrice: 306.82,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba28f65f11ad3d79db2d',
        DIN: '2263386',
        genericName: 'TOPIRAMATE TABLET 200 MG ORAL',
        supplierCode: 'MYL',
        brandName: 'TOPIRAMATE TB 200MG 100 MYLAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 67.48,
        dailyAverageDispensed: 0,
      },
      GLN: {
        _id: '6201ba26f65f11ad3d79ae58',
        DIN: '2287781',
        genericName: 'TOPIRAMATE TABLET 200 MG ORAL',
        supplierCode: 'GLN',
        brandName: 'TOPIRAMATE TB 200MG 100 GLN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 67.48,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d791dad',
        DIN: '2345846',
        genericName: 'TOPIRAMATE TABLET 200 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'TOPIRAMATE TB 200MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 67.48,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78fa10',
        DIN: '2356872',
        genericName: 'TOPIRAMATE TABLET 200 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'TOPIRAMATE TB 200MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 67.48,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '27961',
    genericName: 'OLANZAPINE TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 4.366,
    MFRs: {
      TEV: {
        _id: '6201ba21f65f11ad3d79317d',
        DIN: '2276720',
        genericName: 'OLANZAPINE TABLET 5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'OLANZAPINE TB 5MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 35.44,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba28f65f11ad3d79ddab',
        DIN: '2281805',
        genericName: 'OLANZAPINE TABLET 5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'OLANZAPINE TB 5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 35.44,
        dailyAverageDispensed: 1,
      },
      MPH: {
        _id: '6201ba24f65f11ad3d797b13',
        DIN: '2410168',
        genericName: 'OLANZAPINE TABLET 5 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'OLANZAPINE TB 5MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 35.44,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79cf31',
        DIN: '2303159',
        genericName: 'OLANZAPINE TABLET 5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'OLANZAPINE TB 5MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 35.44,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d792db0',
        DIN: '2417251',
        genericName: 'OLANZAPINE TABLET 5 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'OLANZAPINE FC TB 5MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 35.44,
        dailyAverageDispensed: 1,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d78f82d',
        DIN: '2310368',
        genericName: 'OLANZAPINE TABLET 5 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'OLANZAPINE TB 5MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 35.44,
        dailyAverageDispensed: 0,
      },
      LIL: {
        _id: '6201ba1ff65f11ad3d78f980',
        DIN: '2229269',
        genericName: 'OLANZAPINE TABLET 5 MG ORAL',
        supplierCode: 'LIL',
        brandName: 'ZYPREXA TB 5MG 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 122.25,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78fdd4',
        DIN: '2372827',
        genericName: 'OLANZAPINE TABLET 5 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'OLANZAPINE TB 5MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 35.44,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '28754',
    genericName: 'FLUTICASONE PROPIONATE HFA AER',
    strength: '250MCG/ACT',
    unitPrice: 0.823,
    MFRs: {
      APX: {
        _id: '6201ba25f65f11ad3d79a328',
        DIN: '2510987',
        genericName: 'FLUTICASONE PROPIONATE HFA AER',
        supplierCode: 'APX',
        brandName: 'FLUTICASONE HFA INHALER 250MCG/ACT 120DS APO',
        form: 'INHALER',
        packSize: '120',
        rebateValue: '35',
        packPrice: 45.02,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba25f65f11ad3d799ca6',
        DIN: '2503131',
        genericName: 'FLUTICASONE PROPIONATE HFA AER',
        supplierCode: 'PMS',
        brandName: 'FLUTICASONE HFA INHALER 250MCG/ACT 120DS PMS',
        form: 'AEROSOL MET DS',
        packSize: '120',
        rebateValue: '25',
        packPrice: 45.02,
        dailyAverageDispensed: 2,
      },
      GSK: {
        _id: '6201ba20f65f11ad3d7909b6',
        DIN: '2244293',
        genericName: 'FLUTICASONE PROPIONATE HFA AER',
        supplierCode: 'GSK',
        brandName: 'FLOVENT HFA INHALER 250MCG/DS 120DS',
        form: 'AEROSOL MET DS',
        packSize: '120',
        rebateValue: null,
        packPrice: 98.8,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '29156',
    genericName: 'MELOXICAM TABLET 7.5 MG ORAL',
    strength: '7.5MG',
    unitPrice: 0.2,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79c604',
        DIN: '2248973',
        genericName: 'MELOXICAM TABLET 7.5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'MELOXICAM TB 7.5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 20.03,
        dailyAverageDispensed: 2,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d0b7',
        DIN: '2258315',
        genericName: 'MELOXICAM TABLET 7.5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'MELOXICAM TB 7.5MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 6.01,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba26f65f11ad3d79bd5d',
        DIN: '2248267',
        genericName: 'MELOXICAM TABLET 7.5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'MELOXICAM TB 7.5MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '68',
        packPrice: 20.03,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79b248',
        DIN: '2353148',
        genericName: 'MELOXICAM TABLET 7.5 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'MELOXICAM TB 7.5MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 20.03,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790bdb',
        DIN: '2390884',
        genericName: 'MELOXICAM TABLET 7.5 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'MELOXICAM TB BLST 7.5MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 6.01,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '30264',
    genericName: 'ZOPICLONE TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 1.143,
    MFRs: {
      PMS: {
        _id: '6201ba27f65f11ad3d79c06e',
        DIN: '2243426',
        genericName: 'ZOPICLONE TABLET 5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'ZOPICLONE TB 5MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 22.31,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d7908b0',
        DIN: '2391716',
        genericName: 'ZOPICLONE TABLET 5 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'ZOPICLONE TB 5MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 22.31,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79cbfb',
        DIN: '2245077',
        genericName: 'ZOPICLONE TABLET 5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'ZOPICLONE TB 5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 22.31,
        dailyAverageDispensed: 2,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c5f5',
        DIN: '2246534',
        genericName: 'ZOPICLONE TABLET 5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'ZOPICLONE TB 5MG 100 RATIO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 22.31,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d790acb',
        DIN: '2386771',
        genericName: 'ZOPICLONE TABLET 5 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'ZOPICLONE TB 5MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 22.31,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba20f65f11ad3d791153',
        DIN: '2406969',
        genericName: 'ZOPICLONE TABLET 5 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'ZOPICLONE TB 5MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 22.31,
        dailyAverageDispensed: 0,
      },
      SAC: {
        _id: '6201ba20f65f11ad3d791a4f',
        DIN: '2216167',
        genericName: 'ZOPICLONE TABLET 5 MG ORAL',
        supplierCode: 'SAC',
        brandName: 'IMOVANE TB 5MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 114.28,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d791372',
        DIN: '2344122',
        genericName: 'ZOPICLONE TABLET 5 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'ZOPICLONE TB 5MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 22.31,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '38451',
    genericName: 'MONTELUKAST SODIUM TABLET 10 M',
    strength: '10MG',
    unitPrice: 2.642,
    MFRs: {
      RAN: {
        _id: '6201ba20f65f11ad3d790f5a',
        DIN: '2389517',
        genericName: 'MONTELUKAST SODIUM TABLET 10 M',
        supplierCode: 'RAN',
        brandName: 'MONTELUKAST TB 10MG UD 30 RAN',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '82',
        packPrice: 53.21,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d78fbf9',
        DIN: '2355523',
        genericName: 'MONTELUKAST SODIUM TABLET 10 M',
        supplierCode: 'TEV',
        brandName: 'MONTELUKAST TB 10MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '80',
        packPrice: 53.21,
        dailyAverageDispensed: 1,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d78fba2',
        DIN: '2373947',
        genericName: 'MONTELUKAST SODIUM TABLET 10 M',
        supplierCode: 'PMS',
        brandName: 'MONTELUKAST FC TB 10MG UD 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 53.21,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba24f65f11ad3d7988a2',
        DIN: '2374609',
        genericName: 'MONTELUKAST SODIUM TABLET 10 M',
        supplierCode: 'APX',
        brandName: 'MONTELUKAST TB 10MG UD 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '67',
        packPrice: 53.21,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d78fc25',
        DIN: '2328593',
        genericName: 'MONTELUKAST SODIUM TABLET 10 M',
        supplierCode: 'SDZ',
        brandName: 'MONTELUKAST FC TB 10MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 177.35,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba20f65f11ad3d790a03',
        DIN: '2391422',
        genericName: 'MONTELUKAST SODIUM TABLET 10 M',
        supplierCode: 'JAP',
        brandName: 'MONTELUKAST TB 10MG UD 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 53.21,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba1ff65f11ad3d790425',
        DIN: '2379236',
        genericName: 'MONTELUKAST SODIUM TABLET 10 M',
        supplierCode: 'AHC',
        brandName: 'MONTELUKAST SODIUM TB 10MG BLST 30 ACH',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '63',
        packPrice: 53.21,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d790f39',
        DIN: '2399997',
        genericName: 'MONTELUKAST SODIUM TABLET 10 M',
        supplierCode: 'MRC',
        brandName: 'MONTELUKAST TB 10MG UD 30 MAR',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 53.21,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d7913ba',
        DIN: '2408643',
        genericName: 'MONTELUKAST SODIUM TABLET 10 M',
        supplierCode: 'MPH',
        brandName: 'MONTELUKAST TB 10MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 177.35,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba27f65f11ad3d79d18d',
        DIN: '2238217',
        genericName: 'MONTELUKAST SODIUM TABLET 10 M',
        supplierCode: 'ORN',
        brandName: 'SINGULAIR TB 10MG UD 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 79.27,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d790408',
        DIN: '2379333',
        genericName: 'MONTELUKAST SODIUM TABLET 10 M',
        supplierCode: 'SNS',
        brandName: 'MONTELUKAST TB 10MG UD 30 SNS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 53.21,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790e18',
        DIN: '2401274',
        genericName: 'MONTELUKAST SODIUM TABLET 10 M',
        supplierCode: 'AUP',
        brandName: 'MONTELUKAST TB 10MG UD 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 53.21,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '40550',
    genericName: 'LEFLUNOMIDE TABLET 20 MG ORAL',
    strength: '20MG',
    unitPrice: 11.677,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79d137',
        DIN: '2256509',
        genericName: 'LEFLUNOMIDE TABLET 20 MG ORAL',
        supplierCode: 'APX',
        brandName: 'LEFLUNOMIDE TB 20MG 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 79.3,
        dailyAverageDispensed: 2,
      },
      SDZ: {
        _id: '6201ba26f65f11ad3d79b99b',
        DIN: '2283972',
        genericName: 'LEFLUNOMIDE TABLET 20 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'LEFLUNOMIDE TB 20MG 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 79.3,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba26f65f11ad3d79b674',
        DIN: '2261278',
        genericName: 'LEFLUNOMIDE TABLET 20 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'LEFLUNOMIDE TB 20MG 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 79.3,
        dailyAverageDispensed: 0,
      },
      SAC: {
        _id: '6201ba27f65f11ad3d79d847',
        DIN: '2241889',
        genericName: 'LEFLUNOMIDE TABLET 20 MG ORAL',
        supplierCode: 'SAC',
        brandName: 'ARAVA TB 20MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 350.31,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f4c9',
        DIN: '2351676',
        genericName: 'LEFLUNOMIDE TABLET 20 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'LEFLUNOMIDE TB 20MG 30 SNS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 79.3,
        dailyAverageDispensed: 0,
      },
      ACE: {
        _id: '6201ba26f65f11ad3d79b2f8',
        DIN: '2478870',
        genericName: 'LEFLUNOMIDE TABLET 20 MG ORAL',
        supplierCode: 'ACE',
        brandName: 'LEFLUNOMIDE TB 20MG 100 ACCEL',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 200,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '41234',
    genericName: 'IRBESARTAN/HYDROCHLOROTHIAZIDE',
    strength: '150/12.5MG',
    unitPrice: 1.337,
    MFRs: {
      SDZ: {
        _id: '6201ba1ff65f11ad3d78f6a8',
        DIN: '2337428',
        genericName: 'IRBESARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'SDZ',
        brandName: 'IRBESARTAN HCT TB 150/12.5MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '45',
        packPrice: 22.81,
        dailyAverageDispensed: 2,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d78f6ba',
        DIN: '2328518',
        genericName: 'IRBESARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'PMS',
        brandName: 'IRBESARTAN HCTZ TB 150/12.5MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '35',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d78f728',
        DIN: '2330512',
        genericName: 'IRBESARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'TEV',
        brandName: 'IRBESARTAN HCTZ TB 150/12.5MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      SAC: {
        _id: '6201ba27f65f11ad3d79d5c9',
        DIN: '2241818',
        genericName: 'IRBESARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'SAC',
        brandName: 'AVALIDE TB 150MG/12.5MG 90',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 120.32,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d792de7',
        DIN: '2372886',
        genericName: 'IRBESARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'SNS',
        brandName: 'IRBESARTAN/HCTZ TB 150/12.5MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '44186',
    genericName: 'EXEMESTANE TABLET 25 MG ORAL',
    strength: '25MG',
    unitPrice: 6.07,
    MFRs: {
      JAP: {
        _id: '6201ba24f65f11ad3d79768d',
        DIN: '2407841',
        genericName: 'EXEMESTANE TABLET 25 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'EXEMESTANE TB 25MG BLST 3X10 MED',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '40',
        packPrice: 39.79,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d790448',
        DIN: '2390183',
        genericName: 'EXEMESTANE TABLET 25 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'EXEMESTANE TB 25MG UD 30 ACT',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 39.79,
        dailyAverageDispensed: 2,
      },
      PFC: {
        _id: '6201ba28f65f11ad3d79dece',
        DIN: '2242705',
        genericName: 'EXEMESTANE TABLET 25 MG ORAL',
        supplierCode: 'PFC',
        brandName: 'AROMASIN TB 25MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 182.1,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '44522',
    genericName: 'BENZYDAMINE HCL MOUTHWASH 0.15',
    strength: '0.15%/W/V',
    unitPrice: 0.101,
    MFRs: {
      PMS: {
        _id: '6201ba23f65f11ad3d7955df',
        DIN: '2239537',
        genericName: 'BENZYDAMINE HCL MOUTHWASH 0.15',
        supplierCode: 'PMS',
        brandName: 'BENZYDAMINE ORAL RINSE 0.15% 1.5MG/ML 250ML PMS',
        form: 'SOLUTION',
        packSize: '250',
        rebateValue: '50',
        packPrice: 24,
        dailyAverageDispensed: 1,
      },
      PPI: {
        _id: '6201ba20f65f11ad3d79161e',
        DIN: '2229777',
        genericName: 'BENZYDAMINE HCL MOUTHWASH 0.15',
        supplierCode: 'PPI',
        brandName: 'PHARIXIA 0.15% ORAL RINSE 250ML',
        form: 'LIQUID',
        packSize: '250',
        rebateValue: null,
        packPrice: 25.32,
        dailyAverageDispensed: 0,
      },
      ODN: {
        _id: '6201ba22f65f11ad3d794b84',
        DIN: '2463105',
        genericName: 'BENZYDAMINE HCL MOUTHWASH 0.15',
        supplierCode: 'ODN',
        brandName: 'BENZYDAMINE MOUTHWASH 1.5MG/ML 250ML ODAN',
        form: 'MOUTHWASH',
        packSize: '250',
        rebateValue: null,
        packPrice: 22.75,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '45215',
    genericName: 'TESTOSTERONE GEL PACKET 25MG(1',
    strength: '1%',
    unitPrice: 30.352,
    MFRs: {
      TAR: {
        _id: '6201ba22f65f11ad3d795023',
        DIN: '2463792',
        genericName: 'TESTOSTERONE GEL PACKET 25MG(1',
        supplierCode: 'TAR',
        brandName: 'TESTOSTERONE GEL 1% 30X2.5G TARO',
        form: 'GEL',
        packSize: '2.5',
        rebateValue: '17',
        packPrice: 50.18,
        dailyAverageDispensed: 2,
      },
      ABB: {
        _id: '6201ba27f65f11ad3d79c521',
        DIN: '2245345',
        genericName: 'TESTOSTERONE GEL PACKET 25MG(1',
        supplierCode: 'ABB',
        brandName: 'ANDROGEL SACHET 1% 30X2.5G',
        form: 'GEL',
        packSize: '2.5',
        rebateValue: null,
        packPrice: 75.88,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '46047',
    genericName: 'AMITRIPTYLINE HCL TABLET 50 MG',
    strength: '50MG',
    unitPrice: 0.154,
    MFRs: {
      PMS: {
        _id: '6201ba20f65f11ad3d7910ad',
        DIN: '654507',
        genericName: 'AMITRIPTYLINE HCL TABLET 50 MG',
        supplierCode: 'PMS',
        brandName: 'AMITRIPTYLINE TB 50MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 15.4,
        dailyAverageDispensed: 2,
      },
      APX: {
        _id: '6201ba20f65f11ad3d790e15',
        DIN: '2403153',
        genericName: 'AMITRIPTYLINE HCL TABLET 50 MG',
        supplierCode: 'APX',
        brandName: 'AMITRIPTYLINE TB 50MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 15.4,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba21f65f11ad3d792147',
        DIN: '2429896',
        genericName: 'AMITRIPTYLINE HCL TABLET 50 MG',
        supplierCode: 'MRC',
        brandName: 'AMITRIPTYLINE TB 50MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 15.4,
        dailyAverageDispensed: 0,
      },
      AAA: {
        _id: '6201ba28f65f11ad3d79e13c',
        DIN: '335088',
        genericName: 'AMITRIPTYLINE HCL TABLET 50 MG',
        supplierCode: 'AAA',
        brandName: 'ELAVIL FCT TB 50MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 23.47,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '46172',
    genericName: 'COLESEVELAM HCL TABLET 625 MG',
    strength: '625MG',
    unitPrice: 1.251,
    MFRs: {
      APX: {
        _id: '6201ba25f65f11ad3d79911a',
        DIN: '2494051',
        genericName: 'COLESEVELAM HCL TABLET 625 MG',
        supplierCode: 'APX',
        brandName: 'COLESEVELAM TB 625MG 180 APO',
        form: 'TABLET',
        packSize: '180',
        rebateValue: '10',
        packPrice: 160.13,
        dailyAverageDispensed: 2,
      },
      BAU: {
        _id: '6201ba1ff65f11ad3d78ffa5',
        DIN: '2373955',
        genericName: 'COLESEVELAM HCL TABLET 625 MG',
        supplierCode: 'BAU',
        brandName: 'LODALIS TB 625MG 180',
        form: 'TABLET',
        packSize: '180',
        rebateValue: null,
        packPrice: 225.25,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '46209',
    genericName: 'FLUVOXAMINE MALEATE TABLET 50',
    strength: '50MG',
    unitPrice: 1.046,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79d399',
        DIN: '2231329',
        genericName: 'FLUVOXAMINE MALEATE TABLET 50',
        supplierCode: 'APX',
        brandName: 'FLUVOXAMINE TB 50MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 21.05,
        dailyAverageDispensed: 2,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d791a4e',
        DIN: '2255529',
        genericName: 'FLUVOXAMINE MALEATE TABLET 50',
        supplierCode: 'TEV',
        brandName: 'FLUVOXAMINE TB 50MG 100 ACT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 21.05,
        dailyAverageDispensed: 0,
      },
      BGP: {
        _id: '6201ba26f65f11ad3d79a906',
        DIN: '1919342',
        genericName: 'FLUVOXAMINE MALEATE TABLET 50',
        supplierCode: 'BGP',
        brandName: 'LUVOX TB 50MG BLST 2X15',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 31.37,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '46213',
    genericName: 'FLUOXETINE HCL CAPSULE 10 MG O',
    strength: '10MG',
    unitPrice: 2.179,
    MFRs: {
      TEV: {
        _id: '6201ba20f65f11ad3d791109',
        DIN: '2216582',
        genericName: 'FLUOXETINE HCL CAPSULE 10 MG O',
        supplierCode: 'TEV',
        brandName: 'FLUOXETINE HCL CAPS 10MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '75',
        packPrice: 117.73,
        dailyAverageDispensed: 2,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d78feba',
        DIN: '2177579',
        genericName: 'FLUOXETINE HCL CAPSULE 10 MG O',
        supplierCode: 'PMS',
        brandName: 'FLUOXETINE CAPS 10MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '72',
        packPrice: 117.73,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba20f65f11ad3d791b17',
        DIN: '2216353',
        genericName: 'FLUOXETINE HCL CAPSULE 10 MG O',
        supplierCode: 'APX',
        brandName: 'FLUOXETINE CAPS 10MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '70',
        packPrice: 117.73,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba1ff65f11ad3d79032e',
        DIN: '2380560',
        genericName: 'FLUOXETINE HCL CAPSULE 10 MG O',
        supplierCode: 'MPH',
        brandName: 'FLUOXETINE CAPS 10MG 100 MINT',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '70',
        packPrice: 117.73,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d796c86',
        DIN: '2479486',
        genericName: 'FLUOXETINE HCL CAPSULE 10 MG O',
        supplierCode: 'SDZ',
        brandName: 'FLUOXETINE CAPS 10MG 100 SDZ',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 117.73,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba20f65f11ad3d791200',
        DIN: '2401894',
        genericName: 'FLUOXETINE HCL CAPSULE 10 MG O',
        supplierCode: 'JAP',
        brandName: 'FLUOXETINE CAPS 10MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '60',
        packPrice: 117.73,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba1ff65f11ad3d790599',
        DIN: '2393441',
        genericName: 'FLUOXETINE HCL CAPSULE 10 MG O',
        supplierCode: 'AHC',
        brandName: 'FLUOXETINE CAPS 10MG 100 ACH',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 117.73,
        dailyAverageDispensed: 0,
      },
      LIL: {
        _id: '6201ba27f65f11ad3d79c321',
        DIN: '2018985',
        genericName: 'FLUOXETINE HCL CAPSULE 10 MG O',
        supplierCode: 'LIL',
        brandName: 'PROZAC CAPS 10MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 217.85,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba22f65f11ad3d7942cf',
        DIN: '2286068',
        genericName: 'FLUOXETINE HCL CAPSULE 10 MG O',
        supplierCode: 'SNS',
        brandName: 'FLUOXETINE CAPS 10MG 100 SNS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 117.73,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d79093e',
        DIN: '2385627',
        genericName: 'FLUOXETINE HCL CAPSULE 10 MG O',
        supplierCode: 'AUP',
        brandName: 'FLUOXETINE CAPS 10MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 117.73,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '46941',
    genericName: 'ALENDRONATE SODIUM TABLET 70 M',
    strength: '70MG',
    unitPrice: 12.688,
    MFRs: {
      AHC: {
        _id: '6201ba1ff65f11ad3d790422',
        DIN: '2381494',
        genericName: 'ALENDRONATE SODIUM TABLET 70 M',
        supplierCode: 'AHC',
        brandName: 'ALENDRONATE TB 70MG BLS 4 ACH',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '66',
        packPrice: 8.41,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d78f87a',
        DIN: '2284006',
        genericName: 'ALENDRONATE SODIUM TABLET 70 M',
        supplierCode: 'PMS',
        brandName: 'ALENDRONATE FC TB 70MG BLS 1X4 PMS',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '65',
        packPrice: 8.41,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba26f65f11ad3d79ae9b',
        DIN: '2394871',
        genericName: 'ALENDRONATE SODIUM TABLET 70 M',
        supplierCode: 'MPH',
        brandName: 'ALENDRONATE TB 70MG UD BLST 4 MINT',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '65',
        packPrice: 8.41,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba27f65f11ad3d79c092',
        DIN: '2288109',
        genericName: 'ALENDRONATE SODIUM TABLET 70 M',
        supplierCode: 'SDZ',
        brandName: 'ALENDRONATE TB 70MG BLST 1X4 SDZ',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '62',
        packPrice: 8.41,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d790576',
        DIN: '2385031',
        genericName: 'ALENDRONATE SODIUM TABLET 70 M',
        supplierCode: 'JAP',
        brandName: 'ALENDRONATE TB 70MG UD 4 JAMP',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '60',
        packPrice: 8.41,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79d7d8',
        DIN: '2248730',
        genericName: 'ALENDRONATE SODIUM TABLET 70 M',
        supplierCode: 'APX',
        brandName: 'ALENDRONATE TB 70MG BLS 4 APO',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '10',
        packPrice: 8.41,
        dailyAverageDispensed: 2,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d713',
        DIN: '2261715',
        genericName: 'ALENDRONATE SODIUM TABLET 70 M',
        supplierCode: 'TEV',
        brandName: 'ALENDRONATE TB 70MG BLS PK 4 TEVA',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '1',
        packPrice: 8.41,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba20f65f11ad3d790a3e',
        DIN: '2245329',
        genericName: 'ALENDRONATE SODIUM TABLET 70 M',
        supplierCode: 'ORN',
        brandName: 'FOSAMAX TB 70MG BLISTER 4',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 50.75,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba23f65f11ad3d795e1c',
        DIN: '2352966',
        genericName: 'ALENDRONATE SODIUM TABLET 70 M',
        supplierCode: 'SNS',
        brandName: 'ALENDRONATE SOD TB 70MG UD 50 SNS',
        form: 'TABLET',
        packSize: '50',
        rebateValue: null,
        packPrice: 105.07,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790ed7',
        DIN: '2388553',
        genericName: 'ALENDRONATE SODIUM TABLET 70 M',
        supplierCode: 'AUP',
        brandName: 'ALENDRONATE TB 70MG UD 4 AURO',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 8.41,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '47198',
    genericName: 'QUETIAPINE FUMARATE TABLET 300',
    strength: '300MG',
    unitPrice: 4.284,
    MFRs: {
      AHC: {
        _id: '6201ba25f65f11ad3d7997a5',
        DIN: '2387832',
        genericName: 'QUETIAPINE FUMARATE TABLET 300',
        supplierCode: 'AHC',
        brandName: 'QUETIAPINE TB 300MG 100 ACH',
        form: 'TAB IR',
        packSize: '100',
        rebateValue: '69',
        packPrice: 38.63,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba21f65f11ad3d792625',
        DIN: '2438054',
        genericName: 'QUETIAPINE FUMARATE TABLET 300',
        supplierCode: 'MPH',
        brandName: 'QUETIAPINE TB 300MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 38.63,
        dailyAverageDispensed: 1,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79a91e',
        DIN: '2501678',
        genericName: 'QUETIAPINE FUMARATE TABLET 300',
        supplierCode: 'APX',
        brandName: 'QUETIAPINE FUMARATE TB 300MG 100 APO',
        form: 'TAB IR',
        packSize: '100',
        rebateValue: '50',
        packPrice: 38.63,
        dailyAverageDispensed: 1,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79c956',
        DIN: '2296608',
        genericName: 'QUETIAPINE FUMARATE TABLET 300',
        supplierCode: 'PMS',
        brandName: 'QUETIAPINE TB 300MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '42',
        packPrice: 38.63,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba27f65f11ad3d79c5bf',
        DIN: '2330466',
        genericName: 'QUETIAPINE FUMARATE TABLET 300',
        supplierCode: 'JAP',
        brandName: 'QUETIAPINE TB 300MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 38.63,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c613',
        DIN: '2316129',
        genericName: 'QUETIAPINE FUMARATE TABLET 300',
        supplierCode: 'TEV',
        brandName: 'QUETIAPINE TB 300MG 100 ACT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 38.63,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba26f65f11ad3d79ba9b',
        DIN: '2244107',
        genericName: 'QUETIAPINE FUMARATE TABLET 300',
        supplierCode: 'AST',
        brandName: 'SEROQUEL TB 300MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 428.44,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f3db',
        DIN: '2353202',
        genericName: 'QUETIAPINE FUMARATE TABLET 300',
        supplierCode: 'SNS',
        brandName: 'QUETIAPINE TB 300MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 38.63,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790be2',
        DIN: '2390256',
        genericName: 'QUETIAPINE FUMARATE TABLET 300',
        supplierCode: 'AUP',
        brandName: 'QUETIAPINE TB 300MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 38.63,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '47327',
    genericName: 'TOLTERODINE TARTRATE CAP ER 24',
    strength: '4MG',
    unitPrice: 2.175,
    MFRs: {
      SDZ: {
        _id: '6201ba21f65f11ad3d792d8b',
        DIN: '2413159',
        genericName: 'TOLTERODINE TARTRATE CAP ER 24',
        supplierCode: 'SDZ',
        brandName: 'TOLTERODINE LA CAPS 4MG 30 SDZ',
        form: 'CAPSULE EXT REL',
        packSize: '30',
        rebateValue: '55',
        packPrice: 14.73,
        dailyAverageDispensed: 2,
      },
      TEV: {
        _id: '6201ba22f65f11ad3d7940ea',
        DIN: '2412209',
        genericName: 'TOLTERODINE TARTRATE CAP ER 24',
        supplierCode: 'TEV',
        brandName: 'TOLTERODINE ER CAPS 4MG 30 TEVA',
        form: 'CAPSULE EXT REL',
        packSize: '30',
        rebateValue: '1',
        packPrice: 14.73,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba22f65f11ad3d794e8e',
        DIN: '2244613',
        genericName: 'TOLTERODINE TARTRATE CAP ER 24',
        supplierCode: 'UPJ',
        brandName: 'DETROL LA CAPS 4MG 30',
        form: 'CAPSULE EXT REL',
        packSize: '30',
        rebateValue: null,
        packPrice: 65.26,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba21f65f11ad3d792aee',
        DIN: '2404192',
        genericName: 'TOLTERODINE TARTRATE CAP ER 24',
        supplierCode: 'MYL',
        brandName: 'TOLTERODINE ER CAPS 4MG 30 MYLAN',
        form: 'CAPSULE EXT REL',
        packSize: '30',
        rebateValue: null,
        packPrice: 14.73,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '48703',
    genericName: 'AMPHET ASP/AMPHET/D-AMPHET CAP',
    strength: '30MG',
    unitPrice: 3.814,
    MFRs: {
      APX: {
        _id: '6201ba24f65f11ad3d7973cf',
        DIN: '2445549',
        genericName: 'AMPHET ASP/AMPHET/D-AMPHET CAP',
        supplierCode: 'APX',
        brandName: 'AMPHETAMINE XR CAPS 30MG 100 APO',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '40',
        packPrice: 90.38,
        dailyAverageDispensed: 2,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d792965',
        DIN: '2439298',
        genericName: 'AMPHET ASP/AMPHET/D-AMPHET CAP',
        supplierCode: 'TEV',
        brandName: 'AMPHETAMINE XR CAPS 30MG 100 ACT',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 90.38,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba22f65f11ad3d794005',
        DIN: '2457342',
        genericName: 'AMPHET ASP/AMPHET/D-AMPHET CAP',
        supplierCode: 'SDZ',
        brandName: 'AMPHETAMINE XR CAPS 30MG 100 SDZ',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 90.38,
        dailyAverageDispensed: 0,
      },
      NYC: {
        _id: '6201ba26f65f11ad3d79aed0',
        DIN: '2248813',
        genericName: 'AMPHET ASP/AMPHET/D-AMPHET CAP',
        supplierCode: 'NYC',
        brandName: 'ADDERALL XR CAPS 30MG 100',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 381.41,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '50289',
    genericName: 'OLMESARTAN MEDOXOMIL TABLET 20',
    strength: '20MG',
    unitPrice: 1.406,
    MFRs: {
      AHC: {
        _id: '6201ba25f65f11ad3d799089',
        DIN: '2456311',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 20',
        supplierCode: 'AHC',
        brandName: 'OLMESARTAN TB 20MG BLST 30 ACH',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '69',
        packPrice: 9.06,
        dailyAverageDispensed: 0,
      },
      NRI: {
        _id: '6201ba25f65f11ad3d7997c4',
        DIN: '2499258',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 20',
        supplierCode: 'NRI',
        brandName: 'OLMESARTAN TB 20MG 90 NRA',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '60',
        packPrice: 27.17,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba22f65f11ad3d7945ff',
        DIN: '2461641',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 20',
        supplierCode: 'JAP',
        brandName: 'OLMESARTAN TB 20MG BLST 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '57',
        packPrice: 9.06,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba22f65f11ad3d79470e',
        DIN: '2461307',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 20',
        supplierCode: 'PMS',
        brandName: 'OLMESARTAN TB 20MG BLST 3X10 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '55',
        packPrice: 9.06,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba22f65f11ad3d7945ce',
        DIN: '2443414',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 20',
        supplierCode: 'SDZ',
        brandName: 'OLMESARTAN TB 20MG BLST 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '40',
        packPrice: 9.06,
        dailyAverageDispensed: 1,
      },
      APX: {
        _id: '6201ba25f65f11ad3d799c2d',
        DIN: '2453452',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 20',
        supplierCode: 'APX',
        brandName: 'OLMESARTAN TB 20MG 90 APO',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '40',
        packPrice: 27.17,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba22f65f11ad3d794599',
        DIN: '2442191',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 20',
        supplierCode: 'TEV',
        brandName: 'OLMESARTAN TB 20MG BLST 30 ACT',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 9.06,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba20f65f11ad3d7906dc',
        DIN: '2318660',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 20',
        supplierCode: 'ORN',
        brandName: 'OLMETEC TB 20MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 42.18,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d7945bc',
        DIN: '2443864',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 20',
        supplierCode: 'AUP',
        brandName: 'OLMESARTAN TB 20MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 30.19,
        dailyAverageDispensed: 0,
      },
      GLN: {
        _id: '6201ba24f65f11ad3d797659',
        DIN: '2469812',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 20',
        supplierCode: 'GLN',
        brandName: 'OLMESARTAN TB 20MG 30 GLN',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 9.06,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba24f65f11ad3d7986e8',
        DIN: '2481057',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 20',
        supplierCode: 'SNS',
        brandName: 'OLMESARTAN TB 20MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 30.19,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '50290',
    genericName: 'OLMESARTAN MEDOXOMIL TABLET 40',
    strength: '40MG',
    unitPrice: 1.406,
    MFRs: {
      AHC: {
        _id: '6201ba25f65f11ad3d79908f',
        DIN: '2456338',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 40',
        supplierCode: 'AHC',
        brandName: 'OLMESARTAN TB 40MG BLST 30 ACH',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '69',
        packPrice: 9.06,
        dailyAverageDispensed: 0,
      },
      NRI: {
        _id: '6201ba25f65f11ad3d7997c5',
        DIN: '2499266',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 40',
        supplierCode: 'NRI',
        brandName: 'OLMESARTAN TB 40MG 90 NRA',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '60',
        packPrice: 27.17,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba22f65f11ad3d7945fe',
        DIN: '2461668',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 40',
        supplierCode: 'JAP',
        brandName: 'OLMESARTAN TB 40MG BLST 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '57',
        packPrice: 9.06,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba22f65f11ad3d79470f',
        DIN: '2461315',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 40',
        supplierCode: 'PMS',
        brandName: 'OLMESARTAN TB 40MG BLST 3X10 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '55',
        packPrice: 9.06,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba22f65f11ad3d7945cf',
        DIN: '2443422',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 40',
        supplierCode: 'SDZ',
        brandName: 'OLMESARTAN TB 40MG BLST 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '40',
        packPrice: 9.06,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba25f65f11ad3d799c35',
        DIN: '2453460',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 40',
        supplierCode: 'APX',
        brandName: 'OLMESARTAN TB 40MG 90 APO',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '40',
        packPrice: 27.17,
        dailyAverageDispensed: 2,
      },
      TEV: {
        _id: '6201ba22f65f11ad3d794598',
        DIN: '2442205',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 40',
        supplierCode: 'TEV',
        brandName: 'OLMESARTAN TB 40MG BLST 30 ACT',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 9.06,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba20f65f11ad3d7906f0',
        DIN: '2318679',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 40',
        supplierCode: 'ORN',
        brandName: 'OLMETEC TB 40MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 42.18,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d7945bb',
        DIN: '2443872',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 40',
        supplierCode: 'AUP',
        brandName: 'OLMESARTAN TB 40MG BLST 3X10 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 9.06,
        dailyAverageDispensed: 0,
      },
      GLN: {
        _id: '6201ba24f65f11ad3d797664',
        DIN: '2469820',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 40',
        supplierCode: 'GLN',
        brandName: 'OLMESARTAN TB 40MG 30 GLN',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 9.06,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba24f65f11ad3d7986e9',
        DIN: '2481065',
        genericName: 'OLMESARTAN MEDOXOMIL TABLET 40',
        supplierCode: 'SNS',
        brandName: 'OLMESARTAN TB 40MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 30.19,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '51971',
    genericName: 'DICLOFENAC SODIUM DROPS 1.50%',
    strength: '1.5%/W/W',
    unitPrice: 1.256,
    MFRs: {
      PMS: {
        _id: '6201ba21f65f11ad3d791f8d',
        DIN: '2356783',
        genericName: 'DICLOFENAC SODIUM DROPS 1.50%',
        supplierCode: 'PMS',
        brandName: 'DICLOFENAC TOP SOL 1.5% 60ML PMS',
        form: 'SOLUTION',
        packSize: '60',
        rebateValue: '50',
        packPrice: 37.36,
        dailyAverageDispensed: 2,
      },
      JAP: {
        _id: '6201ba23f65f11ad3d795f4f',
        DIN: '2472309',
        genericName: 'DICLOFENAC SODIUM DROPS 1.50%',
        supplierCode: 'JAP',
        brandName: 'DICLOFENAC TOPICAL SOL 1.5% 2X60ML JAMP',
        form: 'SOLUTION',
        packSize: '60',
        rebateValue: '40',
        packPrice: 74.72,
        dailyAverageDispensed: 0,
      },
      TAR: {
        _id: '6201ba21f65f11ad3d79391a',
        DIN: '2420988',
        genericName: 'DICLOFENAC SODIUM DROPS 1.50%',
        supplierCode: 'TAR',
        brandName: 'DICLOFENAC TOPICAL SOL 1.5% 60ML TARO',
        form: 'SOLUTION',
        packSize: '60',
        rebateValue: '1',
        packPrice: 37.36,
        dailyAverageDispensed: 0,
      },
      PLI: {
        _id: '6201ba21f65f11ad3d7921e7',
        DIN: '2247265',
        genericName: 'DICLOFENAC SODIUM DROPS 1.50%',
        supplierCode: 'PLI',
        brandName: 'PENNSAID SOL TOP 1.5% 1X60ML',
        form: 'SOLUTION',
        packSize: '60',
        rebateValue: null,
        packPrice: 75.34,
        dailyAverageDispensed: 0,
      },
      SMI: {
        _id: '6201ba21f65f11ad3d7928f5',
        DIN: '2434571',
        genericName: 'DICLOFENAC SODIUM DROPS 1.50%',
        supplierCode: 'SMI',
        brandName: 'DICLOFENAC TOPICAL SOL 1.5% 2X60ML',
        form: 'SOLUTION',
        packSize: '60',
        rebateValue: null,
        packPrice: 74.71,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '57690',
    genericName: 'TELMISARTAN/HYDROCHLOROTHIAZID',
    strength: '80/25MG',
    unitPrice: 1.316,
    MFRs: {
      AHC: {
        _id: '6201ba22f65f11ad3d7953e8',
        DIN: '2419122',
        genericName: 'TELMISARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'AHC',
        brandName: 'TELMISARTAN HCTZ TB 80/25MG 100 ACH',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '74',
        packPrice: 20.98,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba25f65f11ad3d799b49',
        DIN: '2389959',
        genericName: 'TELMISARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'JAP',
        brandName: 'TELMISARTAN HCT TB 80/25MG UD BLST 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '55',
        packPrice: 6.29,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d79061f',
        DIN: '2393565',
        genericName: 'TELMISARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'SDZ',
        brandName: 'TELMISARTAN HCTZ TB 80/25MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '35',
        packPrice: 20.98,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d79009a',
        DIN: '2379252',
        genericName: 'TELMISARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'TEV',
        brandName: 'TELMISARTAN/HCTZ TB 80/25MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '25',
        packPrice: 6.29,
        dailyAverageDispensed: 1,
      },
      BOE: {
        _id: '6201ba27f65f11ad3d79cdaa',
        DIN: '2318709',
        genericName: 'TELMISARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'BOE',
        brandName: 'MICARDIS PLUS TB 80MG/25MG BLST 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 36.86,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d790843',
        DIN: '2395363',
        genericName: 'TELMISARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'SNS',
        brandName: 'TELMISARTAN HCTZ TB 80/25MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 20.98,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d794588',
        DIN: '2456397',
        genericName: 'TELMISARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'AUP',
        brandName: 'TELMISARTAN HCTZ TB 80/25MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 20.98,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '57982',
    genericName: 'SOLIFENACIN SUCCINATE TABLET 5',
    strength: '5MG',
    unitPrice: 1.782,
    MFRs: {
      RAN: {
        _id: '6201ba21f65f11ad3d792d7f',
        DIN: '2437988',
        genericName: 'SOLIFENACIN SUCCINATE TABLET 5',
        supplierCode: 'RAN',
        brandName: 'SOLIFENACIN TB 5MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '62',
        packPrice: 30.41,
        dailyAverageDispensed: 1,
      },
      PMS: {
        _id: '6201ba21f65f11ad3d792b45',
        DIN: '2417723',
        genericName: 'SOLIFENACIN SUCCINATE TABLET 5',
        supplierCode: 'PMS',
        brandName: 'SOLIFENACIN TB 5MG 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '45',
        packPrice: 9.12,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d792dbe',
        DIN: '2424339',
        genericName: 'SOLIFENACIN SUCCINATE TABLET 5',
        supplierCode: 'JAP',
        brandName: 'SOLIFENACIN TB 5MG 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '40',
        packPrice: 9.12,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba21f65f11ad3d792977',
        DIN: '2399032',
        genericName: 'SOLIFENACIN SUCCINATE TABLET 5',
        supplierCode: 'SDZ',
        brandName: 'SOLIFENACIN TB 5MG BLST 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '35',
        packPrice: 9.12,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d79272a',
        DIN: '2397900',
        genericName: 'SOLIFENACIN SUCCINATE TABLET 5',
        supplierCode: 'TEV',
        brandName: 'SOLIFENACIN TB 5MG 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 9.12,
        dailyAverageDispensed: 0,
      },
      ASP: {
        _id: '6201ba26f65f11ad3d79b6d3',
        DIN: '2277263',
        genericName: 'SOLIFENACIN SUCCINATE TABLET 5',
        supplierCode: 'ASP',
        brandName: 'VESICARE TB 5MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 53.47,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d79359a',
        DIN: '2446375',
        genericName: 'SOLIFENACIN SUCCINATE TABLET 5',
        supplierCode: 'AUP',
        brandName: 'SOLIFENACIN TB 5MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 9.12,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba22f65f11ad3d79400c',
        DIN: '2458241',
        genericName: 'SOLIFENACIN SUCCINATE TABLET 5',
        supplierCode: 'SNS',
        brandName: 'SOLIFENACIN TB 5MG 30 SNS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 9.12,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '57983',
    genericName: 'SOLIFENACIN SUCCINATE TABLET 1',
    strength: '10MG',
    unitPrice: 1.782,
    MFRs: {
      RAN: {
        _id: '6201ba21f65f11ad3d792d81',
        DIN: '2437996',
        genericName: 'SOLIFENACIN SUCCINATE TABLET 1',
        supplierCode: 'RAN',
        brandName: 'SOLIFENACIN TB 10MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '62',
        packPrice: 30.41,
        dailyAverageDispensed: 1,
      },
      PMS: {
        _id: '6201ba21f65f11ad3d79297f',
        DIN: '2417731',
        genericName: 'SOLIFENACIN SUCCINATE TABLET 1',
        supplierCode: 'PMS',
        brandName: 'SOLIFENACIN TB 10MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '45',
        packPrice: 30.41,
        dailyAverageDispensed: 1,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d792dc0',
        DIN: '2424347',
        genericName: 'SOLIFENACIN SUCCINATE TABLET 1',
        supplierCode: 'JAP',
        brandName: 'SOLIFENACIN TB 10MG 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '40',
        packPrice: 9.12,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba21f65f11ad3d79297a',
        DIN: '2399040',
        genericName: 'SOLIFENACIN SUCCINATE TABLET 1',
        supplierCode: 'SDZ',
        brandName: 'SOLIFENACIN TB 10MG BLST 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '35',
        packPrice: 9.12,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d792727',
        DIN: '2397919',
        genericName: 'SOLIFENACIN SUCCINATE TABLET 1',
        supplierCode: 'TEV',
        brandName: 'SOLIFENACIN TB 10MG 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 9.12,
        dailyAverageDispensed: 0,
      },
      ASP: {
        _id: '6201ba26f65f11ad3d79b6d4',
        DIN: '2277271',
        genericName: 'SOLIFENACIN SUCCINATE TABLET 1',
        supplierCode: 'ASP',
        brandName: 'VESICARE TB 10MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 53.47,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d793598',
        DIN: '2446383',
        genericName: 'SOLIFENACIN SUCCINATE TABLET 1',
        supplierCode: 'AUP',
        brandName: 'SOLIFENACIN TB 10MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 9.12,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba22f65f11ad3d79400a',
        DIN: '2458268',
        genericName: 'SOLIFENACIN SUCCINATE TABLET 1',
        supplierCode: 'SNS',
        brandName: 'SOLIFENACIN TB 10MG 30 SNS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 9.12,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '58390',
    genericName: 'LANTHANUM CARBONATE TAB CHEW 5',
    strength: '500MG',
    unitPrice: 2.539,
    MFRs: {
      NYC: {
        _id: '6201ba24f65f11ad3d797642',
        DIN: '2287153',
        genericName: 'LANTHANUM CARBONATE TAB CHEW 5',
        supplierCode: 'NYC',
        brandName: 'FOSRENOL TB CHEW 500MG 2X45',
        form: 'TAB CHEW',
        packSize: '90',
        rebateValue: null,
        packPrice: 228.54,
        dailyAverageDispensed: 0,
      },
      NTC: {
        _id: '6201ba25f65f11ad3d798f7d',
        DIN: '2498758',
        genericName: 'LANTHANUM CARBONATE TAB CHEW 5',
        supplierCode: 'NTC',
        brandName: 'LANTHANUM TB 500MG 90 NAT',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 184.12,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '59082',
    genericName: 'IRBESARTAN/HYDROCHLOROTHIAZIDE',
    strength: '300/25MG',
    unitPrice: 0.218,
    MFRs: {
      SDZ: {
        _id: '6201ba1ff65f11ad3d78f6a7',
        DIN: '2337444',
        genericName: 'IRBESARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'SDZ',
        brandName: 'IRBESARTAN HCT TB 300/25MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '45',
        packPrice: 21.84,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d78f6bc',
        DIN: '2328534',
        genericName: 'IRBESARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'PMS',
        brandName: 'IRBESARTAN HCTZ TB 300/25MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '35',
        packPrice: 21.84,
        dailyAverageDispensed: 2,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d78f72a',
        DIN: '2330539',
        genericName: 'IRBESARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'TEV',
        brandName: 'IRBESARTAN HCTZ TB 300/25MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 21.84,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d792b9d',
        DIN: '2372908',
        genericName: 'IRBESARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'SNS',
        brandName: 'IRBESARTAN HCTZ TB 300/25MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 21.84,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d794539',
        DIN: '2447894',
        genericName: 'IRBESARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'AUP',
        brandName: 'IRBESARTAN HCT TB 300/25MG 90 AURO',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 19.66,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '62750',
    genericName: 'QUETIAPINE FUMARATE TAB ER 24H',
    strength: '400MG',
    unitPrice: 5.6,
    MFRs: {
      NRI: {
        _id: '6201ba26f65f11ad3d79b201',
        DIN: '2510715',
        genericName: 'QUETIAPINE FUMARATE TAB ER 24H',
        supplierCode: 'NRI',
        brandName: 'QUETIAPINE XR TB 400MG 60 NRA',
        form: 'TABLET EXT REL',
        packSize: '60',
        rebateValue: '85',
        packPrice: 79.62,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d790ac8',
        DIN: '2395487',
        genericName: 'QUETIAPINE FUMARATE TAB ER 24H',
        supplierCode: 'TEV',
        brandName: 'QUETIAPINE XR TB 400MG 60 TEVA',
        form: 'TABLET EXT REL',
        packSize: '60',
        rebateValue: '70',
        packPrice: 79.62,
        dailyAverageDispensed: 2,
      },
      AHC: {
        _id: '6201ba24f65f11ad3d79764b',
        DIN: '2450909',
        genericName: 'QUETIAPINE FUMARATE TAB ER 24H',
        supplierCode: 'AHC',
        brandName: 'QUETIAPINE XR TB 400MG 60 ACH',
        form: 'TABLET EXT REL',
        packSize: '60',
        rebateValue: '66',
        packPrice: 79.62,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba21f65f11ad3d792d46',
        DIN: '2407736',
        genericName: 'QUETIAPINE FUMARATE TAB ER 24H',
        supplierCode: 'SDZ',
        brandName: 'QUETIAPINE XRT TB 400MG 100 SDZ',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '40',
        packPrice: 132.7,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba23f65f11ad3d795dd2',
        DIN: '2457261',
        genericName: 'QUETIAPINE FUMARATE TAB ER 24H',
        supplierCode: 'APX',
        brandName: 'QUETIAPINE XR TB 400MG 60 APO',
        form: 'TABLET EXT REL',
        packSize: '60',
        rebateValue: '40',
        packPrice: 79.62,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba27f65f11ad3d79cca9',
        DIN: '2300214',
        genericName: 'QUETIAPINE FUMARATE TAB ER 24H',
        supplierCode: 'AST',
        brandName: 'SEROQUEL XR TB 400MG 60',
        form: 'TABLET EXT REL',
        packSize: '60',
        rebateValue: null,
        packPrice: 336,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79b360',
        DIN: '2516659',
        genericName: 'QUETIAPINE FUMARATE TAB ER 24H',
        supplierCode: 'SNS',
        brandName: 'QUETIAPINE FUMARATE XR TB 400MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 132.7,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '63422',
    genericName: 'TRAMADOL HCL TBMP 24HR 100 MG',
    strength: '100MG',
    unitPrice: 0.88,
    MFRs: {
      TAR: {
        _id: '6201ba21f65f11ad3d793115',
        DIN: '2450429',
        genericName: 'TRAMADOL HCL TBMP 24HR 100 MG',
        supplierCode: 'TAR',
        brandName: 'TARO TRAMADOL ER TB 100MG 100',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 78.53,
        dailyAverageDispensed: 2,
      },
      PLI: {
        _id: '6201ba21f65f11ad3d793975',
        DIN: '2296381',
        genericName: 'TRAMADOL HCL TBMP 24HR 100 MG',
        supplierCode: 'PLI',
        brandName: 'TRIDURAL TB 100MG 100',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 88,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '64050',
    genericName: 'FENOFIBRATE, MICROCOATED TABLE',
    strength: '100MG',
    unitPrice: 0.831,
    MFRs: {
      AAA: {
        _id: '6201ba26f65f11ad3d79adde',
        DIN: '2246859',
        genericName: 'FENOFIBRATE, MICROCOATED TABLE',
        supplierCode: 'AAA',
        brandName: 'FENO SUPER TB 100MG 100 AA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 83.09,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '66328',
    genericName: 'NAPROXEN/ESOMEPRAZOLE MAG TAB',
    strength: '500/20MG',
    unitPrice: 1.208,
    MFRs: {
      AST: {
        _id: '6201ba1ff65f11ad3d78f582',
        DIN: '2361728',
        genericName: 'NAPROXEN/ESOMEPRAZOLE MAG TAB',
        supplierCode: 'AST',
        brandName: 'VIMOVO 500/20MG TB BOTTLE 60',
        form: 'TABLET',
        packSize: '60',
        rebateValue: null,
        packPrice: 72.45,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba22f65f11ad3d794231',
        DIN: '2443449',
        genericName: 'NAPROXEN/ESOMEPRAZOLE MAG TAB',
        supplierCode: 'MYL',
        brandName: 'NAPROXEN/ESOMEPRAZOLE MR TB 500/20MG 60 MYLAN',
        form: 'TAB MOD RELEASE',
        packSize: '60',
        rebateValue: null,
        packPrice: 55.64,
        dailyAverageDispensed: 2,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '66781',
    genericName: 'DABIGATRAN ETEXILATE MESYLATE',
    strength: '150MG',
    unitPrice: 1.807,
    MFRs: {
      APX: {
        _id: '6201ba23f65f11ad3d796e58',
        DIN: '2468913',
        genericName: 'DABIGATRAN ETEXILATE MESYLATE',
        supplierCode: 'APX',
        brandName: 'DABIGATRAN CAPS 150MG 60 APO',
        form: 'CAPSULE',
        packSize: '60',
        rebateValue: '1',
        packPrice: 75.24,
        dailyAverageDispensed: 2,
      },
      BOE: {
        _id: '6201ba20f65f11ad3d7908fd',
        DIN: '2358808',
        genericName: 'DABIGATRAN ETEXILATE MESYLATE',
        supplierCode: 'BOE',
        brandName: 'PRADAXA CAPS 150MG BLST 60',
        form: 'CAPSULE',
        packSize: '60',
        rebateValue: null,
        packPrice: 108.39,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '68880',
    genericName: 'ADAPALENE/BENZOYL PEROXIDE GEL',
    strength: '0.1%/2.5%',
    unitPrice: 1.642,
    MFRs: {
      SDZ: {
        _id: '6201ba24f65f11ad3d797efb',
        DIN: '2489007',
        genericName: 'ADAPALENE/BENZOYL PEROXIDE GEL',
        supplierCode: 'SDZ',
        brandName: 'ADAPALENE / BENZOYL PEROXIDE GEL 0.1%/2.5 70G SDZ',
        form: 'GEL',
        packSize: '70',
        rebateValue: '48',
        packPrice: 89.03,
        dailyAverageDispensed: 2,
      },
      TAR: {
        _id: '6201ba24f65f11ad3d797ed3',
        DIN: '2456923',
        genericName: 'ADAPALENE/BENZOYL PEROXIDE GEL',
        supplierCode: 'TAR',
        brandName: 'ADAPALENE / BENZOYL PEROXIDE GEL 0.1%/2.5 70G TARO',
        form: 'GEL',
        packSize: '70',
        rebateValue: '1',
        packPrice: 89.03,
        dailyAverageDispensed: 0,
      },
      GAC: {
        _id: '6201ba20f65f11ad3d791932',
        DIN: '2365871',
        genericName: 'ADAPALENE/BENZOYL PEROXIDE GEL',
        supplierCode: 'GAC',
        brandName: 'TACTUPUMP GEL 0.1%/2.5% 70G',
        form: 'GEL',
        packSize: '70',
        rebateValue: null,
        packPrice: 114.97,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '74495',
    genericName: 'ADAPALENE/BENZOYL PEROXIDE GEL',
    strength: '0.3/2.5%/W',
    unitPrice: 2.678,
    MFRs: {
      TAR: {
        _id: '6201ba26f65f11ad3d79b4c7',
        DIN: '2505053',
        genericName: 'ADAPALENE/BENZOYL PEROXIDE GEL',
        supplierCode: 'TAR',
        brandName: 'ADAPALENE / BENZOYL PEROXIDE GEL 0.3%/2.5 70G TARO',
        form: 'GEL',
        packSize: '70',
        rebateValue: '1',
        packPrice: 151.04,
        dailyAverageDispensed: 1,
      },
      SDZ: {
        _id: '6201ba26f65f11ad3d79b5ea',
        DIN: '2517205',
        genericName: 'ADAPALENE/BENZOYL PEROXIDE GEL',
        supplierCode: 'SDZ',
        brandName: 'ADAPALENE/BENZOYL PEROXIDE GEL 0.3%/2.5% 70G SDZ',
        form: 'GEL',
        packSize: '70',
        rebateValue: '1',
        packPrice: 151.04,
        dailyAverageDispensed: 1,
      },
      GAC: {
        _id: '6201ba21f65f11ad3d792d68',
        DIN: '2446235',
        genericName: 'ADAPALENE/BENZOYL PEROXIDE GEL',
        supplierCode: 'GAC',
        brandName: 'TACTUPUMP FORTE GEL 0.3%/2.5% 70G',
        form: 'GEL',
        packSize: '70',
        rebateValue: null,
        packPrice: 187.47,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 2,
      total: 0,
    },
  },
  {
    groupCode: '390',
    genericName: 'LISINOPRIL TABLET 10 MG ORAL',
    strength: '10MG',
    unitPrice: 0.732,
    MFRs: {
      RAN: {
        _id: '6201ba27f65f11ad3d79cc66',
        DIN: '2294249',
        genericName: 'LISINOPRIL TABLET 10 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'LISINOPRIL TB 10MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '67',
        packPrice: 16.19,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79cc5a',
        DIN: '2285126',
        genericName: 'LISINOPRIL TABLET 10 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'LISINOPRIL Z TB 10MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 16.19,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba28f65f11ad3d79dd8e',
        DIN: '2217503',
        genericName: 'LISINOPRIL TABLET 10 MG ORAL',
        supplierCode: 'APX',
        brandName: 'LISINOPRIL TB 10MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 16.19,
        dailyAverageDispensed: 1,
      },
      SGP: {
        _id: '6201ba27f65f11ad3d79c050',
        DIN: '2049376',
        genericName: 'LISINOPRIL TABLET 10 MG ORAL',
        supplierCode: 'SGP',
        brandName: 'ZESTRIL TB 10MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 73.17,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790d15',
        DIN: '2394480',
        genericName: 'LISINOPRIL TABLET 10 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'LISINOPRIL TB 10MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 16.19,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '419',
    genericName: 'ATENOLOL/CHLORTHALIDONE TABLET',
    strength: '100/25MG',
    unitPrice: 0.924,
    MFRs: {
      AAA: {
        _id: '6201ba25f65f11ad3d7993a6',
        DIN: '2248764',
        genericName: 'ATENOLOL/CHLORTHALIDONE TABLET',
        supplierCode: 'AAA',
        brandName: 'ATENIDONE TB 100/25MG 100 AA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 92.37,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '468',
    genericName: 'NITROGLYCERIN PATCH TD24 0.2MG',
    strength: '0.2MG/HR',
    unitPrice: 0.446,
    MFRs: {
      DRD: {
        _id: '6201ba23f65f11ad3d795fda',
        DIN: '1911910',
        genericName: 'NITROGLYCERIN PATCH TD24 0.2MG',
        supplierCode: 'DRD',
        brandName: 'NITRO DUR 0.2MG 30',
        form: 'PATCH MED.',
        packSize: '30',
        rebateValue: null,
        packPrice: 13.39,
        dailyAverageDispensed: 0,
      },
      BAU: {
        _id: '6201ba27f65f11ad3d79ce79',
        DIN: '2162806',
        genericName: 'NITROGLYCERIN PATCH TD24 0.2MG',
        supplierCode: 'BAU',
        brandName: 'MINITRAN TRANSDERMAL SYSTEM 0.2MG/HR 30',
        form: 'PATCH MED.',
        packSize: '30',
        rebateValue: null,
        packPrice: 21.88,
        dailyAverageDispensed: 0,
      },
      PLI: {
        _id: '6201ba23f65f11ad3d796618',
        DIN: '2230732',
        genericName: 'NITROGLYCERIN PATCH TD24 0.2MG',
        supplierCode: 'PLI',
        brandName: 'TRINIPATCH 0.2MG 30',
        form: 'PATCH MED.',
        packSize: '30',
        rebateValue: null,
        packPrice: 21.21,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba20f65f11ad3d7911bd',
        DIN: '2407442',
        genericName: 'NITROGLYCERIN PATCH TD24 0.2MG',
        supplierCode: 'MYL',
        brandName: 'NITRO PATCH 0.2MG/HR 30 MYLAN',
        form: 'PATCH MED.',
        packSize: '30',
        rebateValue: null,
        packPrice: 13.39,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '575',
    genericName: 'DILTIAZEM HCL TABLET 60 MG ORA',
    strength: '60MG',
    unitPrice: 0.345,
    MFRs: {
      AAA: {
        _id: '6201ba25f65f11ad3d798fdc',
        DIN: '771384',
        genericName: 'DILTIAZEM HCL TABLET 60 MG ORA',
        supplierCode: 'AAA',
        brandName: 'DILTIAZ TB 60MG 100 AA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 34.54,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d62f',
        DIN: '862932',
        genericName: 'DILTIAZEM HCL TABLET 60 MG ORA',
        supplierCode: 'TEV',
        brandName: 'DILTIAZEM TB 60MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 32.73,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '2185',
    genericName: 'CALCITRIOL CAPSULE 0.5MCG ORAL',
    strength: '0.5MCG',
    unitPrice: 0.372,
    MFRs: {
      TAR: {
        _id: '6201ba24f65f11ad3d7976fd',
        DIN: '2485729',
        genericName: 'CALCITRIOL CAPSULE 0.5MCG ORAL',
        supplierCode: 'TAR',
        brandName: 'CALCITRIOL CAPS 0.5MCG 90 TARO',
        form: 'CAPSULE',
        packSize: '90',
        rebateValue: '42',
        packPrice: 33.51,
        dailyAverageDispensed: 0,
      },
      ODN: {
        _id: '6201ba21f65f11ad3d792370',
        DIN: '2431645',
        genericName: 'CALCITRIOL CAPSULE 0.5MCG ORAL',
        supplierCode: 'ODN',
        brandName: 'CALCITRIOL-ODAN CAPS 0.5MCG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 37.23,
        dailyAverageDispensed: 1,
      },
      SIP: {
        _id: '6201ba25f65f11ad3d7998ec',
        DIN: '2495902',
        genericName: 'CALCITRIOL CAPSULE 0.5MCG ORAL',
        supplierCode: 'SIP',
        brandName: 'CALCITRIOL CAPS 0.5MCG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 37.23,
        dailyAverageDispensed: 0,
      },
      SGP: {
        _id: '6201ba22f65f11ad3d79421f',
        DIN: '481815',
        genericName: 'CALCITRIOL CAPSULE 0.5MCG ORAL',
        supplierCode: 'SGP',
        brandName: 'ROCALTROL CAPS 0.5MCG BLS 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 122.21,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '3271',
    genericName: 'MEDROXYPROGESTERONE ACET TABLE',
    strength: '10MG',
    unitPrice: 0.167,
    MFRs: {
      TEV: {
        _id: '6201ba28f65f11ad3d79e1bc',
        DIN: '2221306',
        genericName: 'MEDROXYPROGESTERONE ACET TABLE',
        supplierCode: 'TEV',
        brandName: 'MEDROXYPROGESTERONE ACETATE TB 10MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 16.7,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79bed3',
        DIN: '2277298',
        genericName: 'MEDROXYPROGESTERONE ACET TABLE',
        supplierCode: 'APX',
        brandName: 'MEDROXY TB 10MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 16.7,
        dailyAverageDispensed: 1,
      },
      PFC: {
        _id: '6201ba27f65f11ad3d79c2b4',
        DIN: '729973',
        genericName: 'MEDROXYPROGESTERONE ACET TABLE',
        supplierCode: 'PFC',
        brandName: 'PROVERA TB 10MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 99.81,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '3694',
    genericName: 'TRIAZOLAM TABLET 0.25 MG ORAL',
    strength: '0.25MG',
    unitPrice: 0.269,
    MFRs: {
      AAA: {
        _id: '6201ba20f65f11ad3d791977',
        DIN: '808571',
        genericName: 'TRIAZOLAM TABLET 0.25 MG ORAL',
        supplierCode: 'AAA',
        brandName: 'TRIAZOLAM TB 0.25MG UD 10X7',
        form: 'TABLET',
        packSize: '70',
        rebateValue: '1',
        packPrice: 18.85,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '3759',
    genericName: 'LORAZEPAM TABLET 2 MG ORAL',
    strength: '2MG',
    unitPrice: 0.086,
    MFRs: {
      PMS: {
        _id: '6201ba27f65f11ad3d79cb54',
        DIN: '728209',
        genericName: 'LORAZEPAM TABLET 2 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'LORAZEPAM TB 2MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 6.99,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba20f65f11ad3d791878',
        DIN: '655767',
        genericName: 'LORAZEPAM TABLET 2 MG ORAL',
        supplierCode: 'APX',
        brandName: 'LORAZEPAM TB 2MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '30',
        packPrice: 6.99,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79bf9f',
        DIN: '637750',
        genericName: 'LORAZEPAM TABLET 2 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'LORAZEPAM TB 2MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 6.99,
        dailyAverageDispensed: 0,
      },
      PFC: {
        _id: '6201ba27f65f11ad3d79bf0a',
        DIN: '2041448',
        genericName: 'LORAZEPAM TABLET 2 MG ORAL',
        supplierCode: 'PFC',
        brandName: 'ATIVAN TB 2MG 1000',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: null,
        packPrice: 86.09,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '3772',
    genericName: 'OXAZEPAM TABLET 15 MG ORAL',
    strength: '15MG',
    unitPrice: 0.055,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79d3d9',
        DIN: '402745',
        genericName: 'OXAZEPAM TABLET 15 MG ORAL',
        supplierCode: 'APX',
        brandName: 'OXAZEPAM TB 15MG 1000 APO',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '10',
        packPrice: 55,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '3773',
    genericName: 'ALPRAZOLAM TABLET 0.25 MG ORAL',
    strength: '0.25MG',
    unitPrice: 0.063,
    MFRs: {
      APX: {
        _id: '6201ba26f65f11ad3d79ba9c',
        DIN: '865397',
        genericName: 'ALPRAZOLAM TABLET 0.25 MG ORAL',
        supplierCode: 'APX',
        brandName: 'ALPRAZ TB 0.25MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 6.33,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d792fe0',
        DIN: '1913484',
        genericName: 'ALPRAZOLAM TABLET 0.25 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'ALPRAZOLAM TB 0.25MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 6.33,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba20f65f11ad3d7918f7',
        DIN: '2400111',
        genericName: 'ALPRAZOLAM TABLET 0.25 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'ALPRAZOLAM TB 0.25MG 500 JAMP',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '1',
        packPrice: 31.65,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba27f65f11ad3d79d97a',
        DIN: '548359',
        genericName: 'ALPRAZOLAM TABLET 0.25 MG ORAL',
        supplierCode: 'UPJ',
        brandName: 'XANAX TB 0.25MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 33.16,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '3774',
    genericName: 'ALPRAZOLAM TABLET 0.5 MG ORAL',
    strength: '0.5MG',
    unitPrice: 0.076,
    MFRs: {
      APX: {
        _id: '6201ba26f65f11ad3d79ba9e',
        DIN: '865400',
        genericName: 'ALPRAZOLAM TABLET 0.5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'ALPRAZ TB 0.5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 7.57,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d792ff1',
        DIN: '1913492',
        genericName: 'ALPRAZOLAM TABLET 0.5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'ALPRAZOLAM TB 0.5MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 7.57,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba20f65f11ad3d7918f9',
        DIN: '2400138',
        genericName: 'ALPRAZOLAM TABLET 0.5 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'ALPRAZOLAM TB 0.5MG 500 JAMP',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '1',
        packPrice: 37.85,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba27f65f11ad3d79d97b',
        DIN: '548367',
        genericName: 'ALPRAZOLAM TABLET 0.5 MG ORAL',
        supplierCode: 'UPJ',
        brandName: 'XANAX TB 0.5MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 39.65,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '3775',
    genericName: 'ALPRAZOLAM TABLET 1 MG ORAL',
    strength: '1MG',
    unitPrice: 0.46,
    MFRs: {
      APX: {
        _id: '6201ba1ff65f11ad3d790271',
        DIN: '2243611',
        genericName: 'ALPRAZOLAM TABLET 1 MG ORAL',
        supplierCode: 'APX',
        brandName: 'ALPRAZ TB 1MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 46.03,
        dailyAverageDispensed: 1,
      },
      UPJ: {
        _id: '6201ba27f65f11ad3d79d81d',
        DIN: '723770',
        genericName: 'ALPRAZOLAM TABLET 1 MG ORAL',
        supplierCode: 'UPJ',
        brandName: 'XANAX TB 1MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 71.37,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '3781',
    genericName: 'BUSPIRONE HCL TABLET 10 MG ORA',
    strength: '10MG',
    unitPrice: 0.271,
    MFRs: {
      JAP: {
        _id: '6201ba26f65f11ad3d79b04e',
        DIN: '2509911',
        genericName: 'BUSPIRONE HCL TABLET 10 MG ORA',
        supplierCode: 'JAP',
        brandName: 'BUSPIRONE TB 10MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 27.13,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba28f65f11ad3d79e0ae',
        DIN: '2211076',
        genericName: 'BUSPIRONE HCL TABLET 10 MG ORA',
        supplierCode: 'APX',
        brandName: 'BUSPIRONE TB 10MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 27.13,
        dailyAverageDispensed: 1,
      },
      PMS: {
        _id: '6201ba26f65f11ad3d79b83d',
        DIN: '2230942',
        genericName: 'BUSPIRONE HCL TABLET 10 MG ORA',
        supplierCode: 'PMS',
        brandName: 'BUSPIRONE TB 10MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 27.13,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c774',
        DIN: '2231492',
        genericName: 'BUSPIRONE HCL TABLET 10 MG ORA',
        supplierCode: 'TEV',
        brandName: 'BUSPIRONE HCL TB 10MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 27.13,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba26f65f11ad3d79b4b4',
        DIN: '2519054',
        genericName: 'BUSPIRONE HCL TABLET 10 MG ORA',
        supplierCode: 'MPH',
        brandName: 'BUSPIRONE TB 10MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 27.13,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d79356b',
        DIN: '2447851',
        genericName: 'BUSPIRONE HCL TABLET 10 MG ORA',
        supplierCode: 'SNS',
        brandName: 'BUSPIRONE HCL TB 10MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 27.13,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba25f65f11ad3d799acb',
        DIN: '2500213',
        genericName: 'BUSPIRONE HCL TABLET 10 MG ORA',
        supplierCode: 'AUP',
        brandName: 'BUSPIRONE TB 10MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 27.13,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '3977',
    genericName: 'HALOPERIDOL TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 0.488,
    MFRs: {
      TEV: {
        _id: '6201ba27f65f11ad3d79bf4b',
        DIN: '363650',
        genericName: 'HALOPERIDOL TABLET 5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'HALOPERIDOL TB 5MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 48.77,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '4521',
    genericName: 'PHENYTOIN SODIUM EXTENDED CAPS',
    strength: '100MG',
    unitPrice: 0.1,
    MFRs: {
      AAA: {
        _id: '6201ba26f65f11ad3d79a9b7',
        DIN: '2460912',
        genericName: 'PHENYTOIN SODIUM EXTENDED CAPS',
        supplierCode: 'AAA',
        brandName: 'PHENYTOIN NA ER CAPS 100MG 1000 APO',
        form: 'CAPSULE',
        packSize: '1000',
        rebateValue: '1',
        packPrice: 70.16,
        dailyAverageDispensed: 1,
      },
      UPJ: {
        _id: '6201ba24f65f11ad3d79869c',
        DIN: '22780',
        genericName: 'PHENYTOIN SODIUM EXTENDED CAPS',
        supplierCode: 'UPJ',
        brandName: 'DILANTIN CAPS 100MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 9.99,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '4538',
    genericName: 'DIVALPROEX SODIUM TABLET DR 12',
    strength: '125MG',
    unitPrice: 0.154,
    MFRs: {
      APX: {
        _id: '6201ba26f65f11ad3d79ba8d',
        DIN: '2239698',
        genericName: 'DIVALPROEX SODIUM TABLET DR 12',
        supplierCode: 'APX',
        brandName: 'DIVALPROEX TB 125MG 100 APO',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: '10',
        packPrice: 15.39,
        dailyAverageDispensed: 1,
      },
      MYL: {
        _id: '6201ba22f65f11ad3d7945a2',
        DIN: '2458926',
        genericName: 'DIVALPROEX SODIUM TABLET DR 12',
        supplierCode: 'MYL',
        brandName: 'DIVALPROEX TB 125MG 100 MYLAN',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: null,
        packPrice: 15.39,
        dailyAverageDispensed: 0,
      },
      BGP: {
        _id: '6201ba26f65f11ad3d79be16',
        DIN: '596418',
        genericName: 'DIVALPROEX SODIUM TABLET DR 12',
        supplierCode: 'BGP',
        brandName: 'EPIVAL TB 125MG 100',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: null,
        packPrice: 34.91,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '6506',
    genericName: 'TRANEXAMIC ACID TABLET 500 MG',
    strength: '500MG',
    unitPrice: 1.48,
    MFRs: {
      JAP: {
        _id: '6201ba26f65f11ad3d79b3ac',
        DIN: '2519194',
        genericName: 'TRANEXAMIC ACID TABLET 500 MG',
        supplierCode: 'JAP',
        brandName: 'TRANEXAMIC ACID TB 500MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 80.71,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba25f65f11ad3d798d25',
        DIN: '2496232',
        genericName: 'TRANEXAMIC ACID TABLET 500 MG',
        supplierCode: 'MRC',
        brandName: 'TRANEXAMIC ACID TB 500MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 80.71,
        dailyAverageDispensed: 1,
      },
      PFC: {
        _id: '6201ba27f65f11ad3d79d95e',
        DIN: '2064405',
        genericName: 'TRANEXAMIC ACID TABLET 500 MG',
        supplierCode: 'PFC',
        brandName: 'CYKLOKAPRON TB 500MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 148.02,
        dailyAverageDispensed: 0,
      },
      SMI: {
        _id: '6201ba22f65f11ad3d793d86',
        DIN: '2401231',
        genericName: 'TRANEXAMIC ACID TABLET 500 MG',
        supplierCode: 'SMI',
        brandName: 'TRANEXAMIC ACID TB 500MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 80.71,
        dailyAverageDispensed: 0,
      },
      PFI: {
        _id: '6201ba20f65f11ad3d791642',
        DIN: '2409097',
        genericName: 'TRANEXAMIC ACID TABLET 500 MG',
        supplierCode: 'PFI',
        brandName: 'TRANEXAMIC ACID TB 500MG 100 GD',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 80.71,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '6562',
    genericName: 'WARFARIN SODIUM TABLET 5 MG OR',
    strength: '5MG',
    unitPrice: 0.068,
    MFRs: {
      TAR: {
        _id: '6201ba28f65f11ad3d79e0d9',
        DIN: '2242685',
        genericName: 'WARFARIN SODIUM TABLET 5 MG OR',
        supplierCode: 'TAR',
        brandName: 'WARFARIN TB 5MG 100 TARO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 6.75,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79bff9',
        DIN: '2242928',
        genericName: 'WARFARIN SODIUM TABLET 5 MG OR',
        supplierCode: 'APX',
        brandName: 'WARFARIN TB 5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 6.75,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '7008',
    genericName: 'TERCONAZOLE CREAM/APPL 0.40% V',
    strength: '0.40%',
    unitPrice: 0.635,
    MFRs: {
      TAR: {
        _id: '6201ba27f65f11ad3d79d085',
        DIN: '2247651',
        genericName: 'TERCONAZOLE CREAM/APPL 0.40% V',
        supplierCode: 'TAR',
        brandName: 'TERCONAZOLE VAG CR 0.4% 45G TARO',
        form: 'CREAM',
        packSize: '45',
        rebateValue: '12',
        packPrice: 28.59,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '7562',
    genericName: 'BETAMET DIPROP/PROP GLY OINT.',
    strength: '0.05%',
    unitPrice: 0.547,
    MFRs: {
      TEV: {
        _id: '6201ba27f65f11ad3d79d83d',
        DIN: '849669',
        genericName: 'BETAMET DIPROP/PROP GLY OINT.',
        supplierCode: 'TEV',
        brandName: 'TOPILENE TOPICAL OINT W/ GLYCOL 0.05% 15G TEVA',
        form: 'OINTMENT',
        packSize: '15',
        rebateValue: '1',
        packPrice: 7.78,
        dailyAverageDispensed: 1,
      },
      ORN: {
        _id: '6201ba23f65f11ad3d795dbc',
        DIN: '629367',
        genericName: 'BETAMET DIPROP/PROP GLY OINT.',
        supplierCode: 'ORN',
        brandName: 'DIPROLENE OINT 0.05% 50G',
        form: 'OINTMENT',
        packSize: '50',
        rebateValue: null,
        packPrice: 27.36,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '7573',
    genericName: 'BETAMETHASONE VALERATE OINT. (',
    strength: '0.10%',
    unitPrice: 0.089,
    MFRs: {
      TAR: {
        _id: '6201ba27f65f11ad3d79bf5a',
        DIN: '716650',
        genericName: 'BETAMETHASONE VALERATE OINT. (',
        supplierCode: 'TAR',
        brandName: 'BETADERM OINT 0.1% 454G',
        form: 'OINTMENT',
        packSize: '454',
        rebateValue: '52',
        packPrice: 40.36,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '7616',
    genericName: 'FLUOCINONIDE CREAM (G) 0.05% T',
    strength: '0.05%',
    unitPrice: 0.209,
    MFRs: {
      TAR: {
        _id: '6201ba26f65f11ad3d79ab27',
        DIN: '716863',
        genericName: 'FLUOCINONIDE CREAM (G) 0.05% T',
        supplierCode: 'TAR',
        brandName: 'LYDERM CR 0.05% 60G',
        form: 'CREAM',
        packSize: '60',
        rebateValue: '1',
        packPrice: 14.27,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '7617',
    genericName: 'FLUOCINONIDE OINT. (G) 0.05% T',
    strength: '0.05%',
    unitPrice: 0.304,
    MFRs: {
      TAR: {
        _id: '6201ba27f65f11ad3d79c9b6',
        DIN: '2236996',
        genericName: 'FLUOCINONIDE OINT. (G) 0.05% T',
        supplierCode: 'TAR',
        brandName: 'LYDERM OINT 0.05% 60G',
        form: 'OINTMENT',
        packSize: '60',
        rebateValue: '1',
        packPrice: 18.21,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '7620',
    genericName: 'DESONIDE CREAM (G) 0.05% TOPIC',
    strength: '0.05%',
    unitPrice: 0.279,
    MFRs: {
      PPI: {
        _id: '6201ba27f65f11ad3d79c5c6',
        DIN: '2229315',
        genericName: 'DESONIDE CREAM (G) 0.05% TOPIC',
        supplierCode: 'PPI',
        brandName: 'DESONIDE CR 0.05% 60G PDP',
        form: 'CREAM',
        packSize: '60',
        rebateValue: null,
        packPrice: 16.76,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '7622',
    genericName: 'DESONIDE OINT. (G) 0.05% TOPIC',
    strength: '0.05%',
    unitPrice: 0.279,
    MFRs: {
      PPI: {
        _id: '6201ba27f65f11ad3d79c5e1',
        DIN: '2229323',
        genericName: 'DESONIDE OINT. (G) 0.05% TOPIC',
        supplierCode: 'PPI',
        brandName: 'DESONIDE OINT 0.05% 60G PDP',
        form: 'OINTMENT',
        packSize: '60',
        rebateValue: null,
        packPrice: 16.76,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '7727',
    genericName: 'CLINDAMYCIN PHOSPHATE SOLUTION',
    strength: '1%',
    unitPrice: 0.261,
    MFRs: {
      TAR: {
        _id: '6201ba28f65f11ad3d79da4e',
        DIN: '2266938',
        genericName: 'CLINDAMYCIN PHOSPHATE SOLUTION',
        supplierCode: 'TAR',
        brandName: 'CLINDAMYCIN TOPICAL SOL 1% 30ML TARO',
        form: 'SOLUTION',
        packSize: '30',
        rebateValue: '1',
        packPrice: 7.84,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '7894',
    genericName: 'PREDNISOLONE ACETATE DROPS SUS',
    strength: '1%',
    unitPrice: 1.94,
    MFRs: {
      SDZ: {
        _id: '6201ba21f65f11ad3d7939b3',
        DIN: '1916203',
        genericName: 'PREDNISOLONE ACETATE DROPS SUS',
        supplierCode: 'SDZ',
        brandName: 'PREDNISOLONE OPHT 1% 5ML SDZ',
        form: 'SUSPENSION',
        packSize: '5',
        rebateValue: '1',
        packPrice: 9.7,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c15b',
        DIN: '700401',
        genericName: 'PREDNISOLONE ACETATE DROPS SUS',
        supplierCode: 'TEV',
        brandName: 'PREDNISOLONE SOL 1% 5ML TEVA',
        form: 'SUSPENSION',
        packSize: '5',
        rebateValue: '1',
        packPrice: 9.7,
        dailyAverageDispensed: 1,
      },
      ALL: {
        _id: '6201ba25f65f11ad3d799ada',
        DIN: '301175',
        genericName: 'PREDNISOLONE ACETATE DROPS SUS',
        supplierCode: 'ALL',
        brandName: 'PRED FORTE OPHT SUSP DROPS 1% 5ML',
        form: 'SUSPENSION',
        packSize: '5',
        rebateValue: null,
        packPrice: 27.9,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '8349',
    genericName: 'IBUPROFEN TABLET 600 MG ORAL',
    strength: '600MG',
    unitPrice: 0.131,
    MFRs: {
      APX: {
        _id: '6201ba26f65f11ad3d79be63',
        DIN: '585114',
        genericName: 'IBUPROFEN TABLET 600 MG ORAL',
        supplierCode: 'APX',
        brandName: 'IBUPROFEN TB 600MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 13.13,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '8361',
    genericName: 'NAPROXEN TABLET 375 MG ORAL',
    strength: '375MG',
    unitPrice: 0.146,
    MFRs: {
      APX: {
        _id: '6201ba26f65f11ad3d79be86',
        DIN: '600806',
        genericName: 'NAPROXEN TABLET 375 MG ORAL',
        supplierCode: 'APX',
        brandName: 'NAPROXEN TB 375MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 14.58,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba26f65f11ad3d79bbeb',
        DIN: '627097',
        genericName: 'NAPROXEN TABLET 375 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'NAPROXEN TB 375MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 14.58,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f4ce',
        DIN: '2350769',
        genericName: 'NAPROXEN TABLET 375 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'NAPROXEN TB 375MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 14.58,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '8916',
    genericName: 'CLOXACILLIN SODIUM CAPSULE 500',
    strength: '500MG',
    unitPrice: 0.449,
    MFRs: {
      TEV: {
        _id: '6201ba26f65f11ad3d79bc71',
        DIN: '337773',
        genericName: 'CLOXACILLIN SODIUM CAPSULE 500',
        supplierCode: 'TEV',
        brandName: 'CLOXACILLIN SOD CAPS 500MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 44.94,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '9048',
    genericName: 'CEPHALEXIN MONOHYDRATE TABLET',
    strength: '250MG',
    unitPrice: 0.087,
    MFRs: {
      TEV: {
        _id: '6201ba28f65f11ad3d79dbdb',
        DIN: '583413',
        genericName: 'CEPHALEXIN MONOHYDRATE TABLET',
        supplierCode: 'TEV',
        brandName: 'CEPHALEXIN TB 250MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 8.66,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d796009',
        DIN: '2470578',
        genericName: 'CEPHALEXIN MONOHYDRATE TABLET',
        supplierCode: 'AUP',
        brandName: 'CEPHALEXIN TB 250MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 8.66,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79af37',
        DIN: '768723',
        genericName: 'CEPHALEXIN MONOHYDRATE TABLET',
        supplierCode: 'APX',
        brandName: 'CEPHALEX TB 250MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '0',
        packPrice: 8.66,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '9510',
    genericName: 'CIPROFLOXACIN HCL TABLET 500 M',
    strength: '500MG',
    unitPrice: 0.502,
    MFRs: {
      RAN: {
        _id: '6201ba27f65f11ad3d79d623',
        DIN: '2303736',
        genericName: 'CIPROFLOXACIN HCL TABLET 500 M',
        supplierCode: 'RAN',
        brandName: 'CIPROFLOXACIN TB 500MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 50.25,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba26f65f11ad3d79be8b',
        DIN: '2248438',
        genericName: 'CIPROFLOXACIN HCL TABLET 500 M',
        supplierCode: 'PMS',
        brandName: 'CIPROFLOXACIN TB 500MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 50.25,
        dailyAverageDispensed: 1,
      },
      SDZ: {
        _id: '6201ba26f65f11ad3d79bcdc',
        DIN: '2248757',
        genericName: 'CIPROFLOXACIN HCL TABLET 500 M',
        supplierCode: 'SDZ',
        brandName: 'CIPROFLOXACIN TB 500MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 50.25,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba1ff65f11ad3d79041a',
        DIN: '2379694',
        genericName: 'CIPROFLOXACIN HCL TABLET 500 M',
        supplierCode: 'MRC',
        brandName: 'CIPROFLOXACIN TB 500MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '58',
        packPrice: 50.25,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d7903fc',
        DIN: '2380366',
        genericName: 'CIPROFLOXACIN HCL TABLET 500 M',
        supplierCode: 'JAP',
        brandName: 'CIPROFLOXACIN TB 500MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 50.25,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d791aef',
        DIN: '2423561',
        genericName: 'CIPROFLOXACIN HCL TABLET 500 M',
        supplierCode: 'MPH',
        brandName: 'CIPROFLOXACIN TB 500MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '45',
        packPrice: 50.25,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba26f65f11ad3d79bd49',
        DIN: '2247340',
        genericName: 'CIPROFLOXACIN HCL TABLET 500 M',
        supplierCode: 'TEV',
        brandName: 'CIPROFLOXACIN TB 500MG 100 ACT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 50.25,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f3c7',
        DIN: '2353326',
        genericName: 'CIPROFLOXACIN HCL TABLET 500 M',
        supplierCode: 'SNS',
        brandName: 'CIPROFLOXACIN TB 500MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 50.25,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba1ff65f11ad3d7905ad',
        DIN: '2381923',
        genericName: 'CIPROFLOXACIN HCL TABLET 500 M',
        supplierCode: 'AUP',
        brandName: 'CIPROFLOXACIN TB 500MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 50.25,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '9560',
    genericName: 'QUININE SULFATE CAPSULE 200 MG',
    strength: '200MG',
    unitPrice: 0.239,
    MFRs: {
      TEV: {
        _id: '6201ba25f65f11ad3d799341',
        DIN: '21008',
        genericName: 'QUININE SULFATE CAPSULE 200 MG',
        supplierCode: 'TEV',
        brandName: 'QUININE CAPS 200MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 23.9,
        dailyAverageDispensed: 1,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d793849',
        DIN: '2445190',
        genericName: 'QUININE SULFATE CAPSULE 200 MG',
        supplierCode: 'JAP',
        brandName: 'QUININE CAPS 200MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 23.9,
        dailyAverageDispensed: 0,
      },
      ODN: {
        _id: '6201ba27f65f11ad3d79bfa9',
        DIN: '695440',
        genericName: 'QUININE SULFATE CAPSULE 200 MG',
        supplierCode: 'ODN',
        brandName: 'QUININE ODAN CAPS 200MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 23.9,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '9580',
    genericName: 'HYDROXYCHLOROQUINE SULFATE TAB',
    strength: '200MG',
    unitPrice: 0.665,
    MFRs: {
      JAP: {
        _id: '6201ba24f65f11ad3d798723',
        DIN: '2491427',
        genericName: 'HYDROXYCHLOROQUINE SULFATE TAB',
        supplierCode: 'JAP',
        brandName: 'HYDROXYCHLOROQUINE SULFATE TB 200MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 15.76,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79bc82',
        DIN: '2246691',
        genericName: 'HYDROXYCHLOROQUINE SULFATE TAB',
        supplierCode: 'APX',
        brandName: 'HYDROXYQUINE TB 200MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 15.76,
        dailyAverageDispensed: 1,
      },
      SAC: {
        _id: '6201ba22f65f11ad3d793f5d',
        DIN: '2017709',
        genericName: 'HYDROXYCHLOROQUINE SULFATE TAB',
        supplierCode: 'SAC',
        brandName: 'PLAQUENIL TB 200MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 66.49,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d791c47',
        DIN: '2424991',
        genericName: 'HYDROXYCHLOROQUINE SULFATE TAB',
        supplierCode: 'MPH',
        brandName: 'HYDROXYCHLOROQUINE TB 200MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '0',
        packPrice: 15.76,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '11859',
    genericName: 'HYDROXYZINE HCL CAPSULE 25 MG',
    strength: '25MG',
    unitPrice: 0.245,
    MFRs: {
      AAA: {
        _id: '6201ba24f65f11ad3d7972cd',
        DIN: '646024',
        genericName: 'HYDROXYZINE HCL CAPSULE 25 MG',
        supplierCode: 'AAA',
        brandName: 'HYDROXYZINE CAPS 25MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 24.47,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c299',
        DIN: '738832',
        genericName: 'HYDROXYZINE HCL CAPSULE 25 MG',
        supplierCode: 'TEV',
        brandName: 'HYDROXYZINE HCL CAPS 25MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 21.65,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '11885',
    genericName: 'MORPHINE SULFATE TABLET 5 MG O',
    strength: '5MG',
    unitPrice: 0.131,
    MFRs: {
      PFR: {
        _id: '6201ba1ff65f11ad3d78f6ff',
        DIN: '2014203',
        genericName: 'MORPHINE SULFATE TABLET 5 MG O',
        supplierCode: 'PFR',
        brandName: 'MS/IR TB 5MG 60',
        form: 'TAB IR',
        packSize: '60',
        rebateValue: null,
        packPrice: 7.85,
        dailyAverageDispensed: 0,
      },
      PLI: {
        _id: '6201ba26f65f11ad3d79b7cd',
        DIN: '594652',
        genericName: 'MORPHINE SULFATE TABLET 5 MG O',
        supplierCode: 'PLI',
        brandName: 'STATEX TB 5MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 12.32,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '11894',
    genericName: 'METHOTRIMEPRAZINE MALEATE TABL',
    strength: '5MG',
    unitPrice: 0.11,
    MFRs: {
      AAA: {
        _id: '6201ba20f65f11ad3d790e58',
        DIN: '2238404',
        genericName: 'METHOTRIMEPRAZINE MALEATE TABL',
        supplierCode: 'AAA',
        brandName: 'METHOPRAZINE TB 5MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 11.01,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '14198',
    genericName: 'WARFARIN SODIUM TABLET 1 MG OR',
    strength: '1MG',
    unitPrice: 0.08,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79bfaf',
        DIN: '2242924',
        genericName: 'WARFARIN SODIUM TABLET 1 MG OR',
        supplierCode: 'APX',
        brandName: 'WARFARIN TB 1MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 7.96,
        dailyAverageDispensed: 1,
      },
      TAR: {
        _id: '6201ba28f65f11ad3d79e0de',
        DIN: '2242680',
        genericName: 'WARFARIN SODIUM TABLET 1 MG OR',
        supplierCode: 'TAR',
        brandName: 'WARFARIN TB 1MG 100 TARO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 7.96,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '14503',
    genericName: 'CODEINE/ACETAMINOPHEN/CAFFEINE',
    strength: '300-15-15',
    unitPrice: 0.089,
    MFRs: {
      TEV: {
        _id: '6201ba26f65f11ad3d79a89e',
        DIN: '653241',
        genericName: 'CODEINE/ACETAMINOPHEN/CAFFEINE',
        supplierCode: 'TEV',
        brandName: 'LENOLTEC #2 CODEINE TB 300-15-15MG 500 TEVA',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '1',
        packPrice: 44.45,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '15349',
    genericName: 'CLOBETASOL PROPIONATE SOLUTION',
    strength: '0.05%',
    unitPrice: 0.199,
    MFRs: {
      TEV: {
        _id: '6201ba28f65f11ad3d79de6c',
        DIN: '1910299',
        genericName: 'CLOBETASOL PROPIONATE SOLUTION',
        supplierCode: 'TEV',
        brandName: 'CLOBETASOL SCALP LOTION 0.05% 20ML TEVA',
        form: 'LOTION',
        packSize: '20',
        rebateValue: '1',
        packPrice: 3.98,
        dailyAverageDispensed: 0,
      },
      TAR: {
        _id: '6201ba27f65f11ad3d79d4ea',
        DIN: '2213281',
        genericName: 'CLOBETASOL PROPIONATE SOLUTION',
        supplierCode: 'TAR',
        brandName: 'DERMOVATE SCALP LOT 0.05% 60ML',
        form: 'SOLUTION',
        packSize: '60',
        rebateValue: '1',
        packPrice: 44.08,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba1ff65f11ad3d78f3c8',
        DIN: '2216213',
        genericName: 'CLOBETASOL PROPIONATE SOLUTION',
        supplierCode: 'MYL',
        brandName: 'CLOBETASOL SCALP LOT 0.05% 60ML MYLAN',
        form: 'LOTION',
        packSize: '60',
        rebateValue: null,
        packPrice: 11.94,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '16295',
    genericName: 'FELODIPINE TAB ER 24H 5 MG ORA',
    strength: '5MG',
    unitPrice: 0.357,
    MFRs: {
      APX: {
        _id: '6201ba21f65f11ad3d793255',
        DIN: '2452375',
        genericName: 'FELODIPINE TAB ER 24H 5 MG ORA',
        supplierCode: 'APX',
        brandName: 'FELODIPINE TB ER 5MG 100 APO',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '40',
        packPrice: 35.65,
        dailyAverageDispensed: 1,
      },
      SDZ: {
        _id: '6201ba26f65f11ad3d79b9b0',
        DIN: '2280264',
        genericName: 'FELODIPINE TAB ER 24H 5 MG ORA',
        supplierCode: 'SDZ',
        brandName: 'FELODIPINE TB ER 5MG 100 SDZ',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 35.65,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba20f65f11ad3d791469',
        DIN: '851779',
        genericName: 'FELODIPINE TAB ER 24H 5 MG ORA',
        supplierCode: 'AST',
        brandName: 'PLENDIL TB ER 5MG 30',
        form: 'TABLET EXT REL',
        packSize: '30',
        rebateValue: null,
        packPrice: 23.91,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '16296',
    genericName: 'FELODIPINE TAB ER 24H 10 MG OR',
    strength: '10MG',
    unitPrice: 0.535,
    MFRs: {
      APX: {
        _id: '6201ba21f65f11ad3d793256',
        DIN: '2452383',
        genericName: 'FELODIPINE TAB ER 24H 10 MG OR',
        supplierCode: 'APX',
        brandName: 'FELODIPINE TB ER 10MG 100 APO',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '40',
        packPrice: 53.5,
        dailyAverageDispensed: 1,
      },
      SDZ: {
        _id: '6201ba26f65f11ad3d79b9b1',
        DIN: '2280272',
        genericName: 'FELODIPINE TAB ER 24H 10 MG OR',
        supplierCode: 'SDZ',
        brandName: 'FELODIPINE TB ER 10MG 100 SDZ',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 53.5,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba20f65f11ad3d79146b',
        DIN: '851787',
        genericName: 'FELODIPINE TAB ER 24H 10 MG OR',
        supplierCode: 'AST',
        brandName: 'PLENDIL TB ER 10MG 30',
        form: 'TABLET EXT REL',
        packSize: '30',
        rebateValue: null,
        packPrice: 35.86,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '16373',
    genericName: 'CLARITHROMYCIN TABLET 250 MG O',
    strength: '250MG',
    unitPrice: 1.776,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79c9d6',
        DIN: '2274744',
        genericName: 'CLARITHROMYCIN TABLET 250 MG O',
        supplierCode: 'APX',
        brandName: 'CLARITHROMYCIN TB 250MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '54',
        packPrice: 41.22,
        dailyAverageDispensed: 1,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79cab1',
        DIN: '2247573',
        genericName: 'CLARITHROMYCIN TABLET 250 MG O',
        supplierCode: 'PMS',
        brandName: 'CLARITHROMYCIN TB 250MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 41.22,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba27f65f11ad3d79d433',
        DIN: '2266539',
        genericName: 'CLARITHROMYCIN TABLET 250 MG O',
        supplierCode: 'SDZ',
        brandName: 'CLARITHROMYCIN TB 250MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 41.22,
        dailyAverageDispensed: 0,
      },
      RAN: {
        _id: '6201ba1ff65f11ad3d78f867',
        DIN: '2361426',
        genericName: 'CLARITHROMYCIN TABLET 250 MG O',
        supplierCode: 'RAN',
        brandName: 'CLARITHROMYCIN TB 250MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 41.22,
        dailyAverageDispensed: 0,
      },
      BGP: {
        _id: '6201ba27f65f11ad3d79d810',
        DIN: '1984853',
        genericName: 'CLARITHROMYCIN TABLET 250 MG O',
        supplierCode: 'BGP',
        brandName: 'BIAXIN TB 250MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 177.59,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba22f65f11ad3d795088',
        DIN: '2466120',
        genericName: 'CLARITHROMYCIN TABLET 250 MG O',
        supplierCode: 'SNS',
        brandName: 'CLARITHROMYCIN TB 250MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 41.22,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '16404',
    genericName: 'KETOROLAC TROMETHAMINE TABLET',
    strength: '10MG',
    unitPrice: 0.791,
    MFRs: {
      MPH: {
        _id: '6201ba24f65f11ad3d797801',
        DIN: '2461455',
        genericName: 'KETOROLAC TROMETHAMINE TABLET',
        supplierCode: 'MPH',
        brandName: 'KETOROLAC TB 10MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 60.28,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba26f65f11ad3d79a9e1',
        DIN: '2510855',
        genericName: 'KETOROLAC TROMETHAMINE TABLET',
        supplierCode: 'JAP',
        brandName: 'KETOROLAC TB 10MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '40',
        packPrice: 60.28,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79ba48',
        DIN: '2229080',
        genericName: 'KETOROLAC TROMETHAMINE TABLET',
        supplierCode: 'APX',
        brandName: 'KETOROLAC TB 10MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '30',
        packPrice: 60.28,
        dailyAverageDispensed: 1,
      },
      MRC: {
        _id: '6201ba22f65f11ad3d794889',
        DIN: '2465124',
        genericName: 'KETOROLAC TROMETHAMINE TABLET',
        supplierCode: 'MRC',
        brandName: 'KETOROLAC TB 10MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 60.28,
        dailyAverageDispensed: 0,
      },
      AAA: {
        _id: '6201ba26f65f11ad3d79b63a',
        DIN: '2162660',
        genericName: 'KETOROLAC TROMETHAMINE TABLET',
        supplierCode: 'AAA',
        brandName: 'TORADOL TB 10MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 79.07,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '16576',
    genericName: 'SIMVASTATIN TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 0.102,
    MFRs: {
      RAN: {
        _id: '6201ba27f65f11ad3d79c00c',
        DIN: '2329131',
        genericName: 'SIMVASTATIN TABLET 5 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'SIMVASTATIN TB 5MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 10.23,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c066',
        DIN: '2247011',
        genericName: 'SIMVASTATIN TABLET 5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'SIMVASTATIN TB 5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '67',
        packPrice: 10.23,
        dailyAverageDispensed: 1,
      },
      MPH: {
        _id: '6201ba1ff65f11ad3d78fc70',
        DIN: '2372932',
        genericName: 'SIMVASTATIN TABLET 5 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'SIMVASTATIN TB 5MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 10.23,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba27f65f11ad3d79cab7',
        DIN: '2375591',
        genericName: 'SIMVASTATIN TABLET 5 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'SIMVASTATIN TB 5MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 10.23,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d795844',
        DIN: '2469979',
        genericName: 'SIMVASTATIN TABLET 5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'SIMVASTATIN TB 5MG BLST 30 PHARMA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '55',
        packPrice: 3.07,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d310',
        DIN: '2250144',
        genericName: 'SIMVASTATIN TABLET 5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'SIMVASTATIN TB 5MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '35',
        packPrice: 3.07,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba25f65f11ad3d79a032',
        DIN: '2284723',
        genericName: 'SIMVASTATIN TABLET 5 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'SIMVASTATIN TB 5MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 10.23,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d791941',
        DIN: '2405148',
        genericName: 'SIMVASTATIN TABLET 5 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'SIMVASTATIN TB 5MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 10.23,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '16577',
    genericName: 'SIMVASTATIN TABLET 10 MG ORAL',
    strength: '10MG',
    unitPrice: 0.202,
    MFRs: {
      RAN: {
        _id: '6201ba27f65f11ad3d79c012',
        DIN: '2329158',
        genericName: 'SIMVASTATIN TABLET 10 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'SIMVASTATIN TB 10MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 20.23,
        dailyAverageDispensed: 1,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c3cf',
        DIN: '2247012',
        genericName: 'SIMVASTATIN TABLET 10 MG ORAL',
        supplierCode: 'APX',
        brandName: 'SIMVASTATIN TB 10MG BLS 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '67',
        packPrice: 6.07,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba1ff65f11ad3d78fc74',
        DIN: '2372940',
        genericName: 'SIMVASTATIN TABLET 10 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'SIMVASTATIN TB 10MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 20.23,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba27f65f11ad3d79cbd4',
        DIN: '2375605',
        genericName: 'SIMVASTATIN TABLET 10 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'SIMVASTATIN TB 10MG 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 6.07,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba1ff65f11ad3d790696',
        DIN: '2375044',
        genericName: 'SIMVASTATIN TABLET 10 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'SIMVASTATIN TB 10MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '57',
        packPrice: 20.23,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d795845',
        DIN: '2469987',
        genericName: 'SIMVASTATIN TABLET 10 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'SIMVASTATIN TB 10MG 100 PHARMA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 20.23,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d312',
        DIN: '2250152',
        genericName: 'SIMVASTATIN TABLET 10 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'SIMVASTATIN TB 10MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '35',
        packPrice: 6.07,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba25f65f11ad3d79a033',
        DIN: '2284731',
        genericName: 'SIMVASTATIN TABLET 10 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'SIMVASTATIN TB 10MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 20.23,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d79193f',
        DIN: '2405156',
        genericName: 'SIMVASTATIN TABLET 10 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'SIMVASTATIN TB 10MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 20.23,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba27f65f11ad3d79c23e',
        DIN: '884332',
        genericName: 'SIMVASTATIN TABLET 10 MG ORAL',
        supplierCode: 'ORN',
        brandName: 'ZOCOR TB 10MG BLST PK28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 79.31,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '16578',
    genericName: 'SIMVASTATIN TABLET 20 MG ORAL',
    strength: '20MG',
    unitPrice: 0.25,
    MFRs: {
      RAN: {
        _id: '6201ba27f65f11ad3d79c01f',
        DIN: '2329166',
        genericName: 'SIMVASTATIN TABLET 20 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'SIMVASTATIN TB 20MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 25.01,
        dailyAverageDispensed: 1,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c3d3',
        DIN: '2247013',
        genericName: 'SIMVASTATIN TABLET 20 MG ORAL',
        supplierCode: 'APX',
        brandName: 'SIMVASTATIN TB 20MG BLS 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '67',
        packPrice: 7.5,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba1ff65f11ad3d78fc71',
        DIN: '2372959',
        genericName: 'SIMVASTATIN TABLET 20 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'SIMVASTATIN TB 20MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 25.01,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba27f65f11ad3d79cbd3',
        DIN: '2375613',
        genericName: 'SIMVASTATIN TABLET 20 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'SIMVASTATIN TB 20MG 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 7.5,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d7956ad',
        DIN: '2469995',
        genericName: 'SIMVASTATIN TABLET 20 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'SIMVASTATIN TB 20MG 100 PHARMA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 25.01,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d313',
        DIN: '2250160',
        genericName: 'SIMVASTATIN TABLET 20 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'SIMVASTATIN TB 20MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '35',
        packPrice: 7.5,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba25f65f11ad3d79a02f',
        DIN: '2284758',
        genericName: 'SIMVASTATIN TABLET 20 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'SIMVASTATIN TB 20MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 25.01,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d791943',
        DIN: '2405164',
        genericName: 'SIMVASTATIN TABLET 20 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'SIMVASTATIN TB 20MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 25.01,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba27f65f11ad3d79bffc',
        DIN: '884340',
        genericName: 'SIMVASTATIN TABLET 20 MG ORAL',
        supplierCode: 'ORN',
        brandName: 'ZOCOR TB 20MG BLST PK 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 98.02,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '16777',
    genericName: 'METOPROLOL TARTRATE TABLET ER',
    strength: '100MG',
    unitPrice: 0.178,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79ca3f',
        DIN: '2285169',
        genericName: 'METOPROLOL TARTRATE TABLET ER',
        supplierCode: 'APX',
        brandName: 'METOPROLOL SR TB 100MG 100 APO',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '20',
        packPrice: 17.82,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '17117',
    genericName: 'MIDODRINE HCL TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 0.384,
    MFRs: {
      MRC: {
        _id: '6201ba23f65f11ad3d796099',
        DIN: '2473992',
        genericName: 'MIDODRINE HCL TABLET 5 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'MIDODRINE TB 5MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 38.42,
        dailyAverageDispensed: 1,
      },
      APX: {
        _id: '6201ba23f65f11ad3d796af2',
        DIN: '2278685',
        genericName: 'MIDODRINE HCL TABLET 5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'MIDODRINE TB 5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 38.42,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '17135',
    genericName: 'CHOLECALCIFEROL (VITAMIN D3) T',
    strength: '10000IU',
    unitPrice: 0.21,
    MFRs: {
      JAP: {
        _id: '6201ba1ff65f11ad3d7901e3',
        DIN: '2379007',
        genericName: 'CHOLECALCIFEROL (VITAMIN D3) T',
        supplierCode: 'JAP',
        brandName: 'VIT D TB 10000IU 60 JAMP',
        form: 'TABLET',
        packSize: '60',
        rebateValue: '25',
        packPrice: 12.6,
        dailyAverageDispensed: 0,
      },
      OPH: {
        _id: '6201ba20f65f11ad3d7916f0',
        DIN: '2417685',
        genericName: 'CHOLECALCIFEROL (VITAMIN D3) T',
        supplierCode: 'OPH',
        brandName: 'VIDEXTRA VITAMIN D 10000IU TB 60',
        form: 'TABLET',
        packSize: '60',
        rebateValue: null,
        packPrice: 13.3,
        dailyAverageDispensed: 0,
      },
      RIV: {
        _id: '6201ba27f65f11ad3d79c91a',
        DIN: '821772',
        genericName: 'CHOLECALCIFEROL (VITAMIN D3) T',
        supplierCode: 'RIV',
        brandName: 'D TABS TB 10000IU 60',
        form: 'TABLET',
        packSize: '60',
        rebateValue: null,
        packPrice: 12.6,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '17549',
    genericName: 'TESTOSTERONE UNDECANOATE CAPSU',
    strength: '40MG',
    unitPrice: 0.47,
    MFRs: {
      TAR: {
        _id: '6201ba20f65f11ad3d791ced',
        DIN: '2421186',
        genericName: 'TESTOSTERONE UNDECANOATE CAPSU',
        supplierCode: 'TAR',
        brandName: 'TESTOSTERONE CAPS 40MG UD 60 TARO',
        form: 'CAPSULE',
        packSize: '60',
        rebateValue: '52',
        packPrice: 28.2,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba28f65f11ad3d79ddfa',
        DIN: '2322498',
        genericName: 'TESTOSTERONE UNDECANOATE CAPSU',
        supplierCode: 'PMS',
        brandName: 'TESTOSTERONE CAPS 40MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 47,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '17785',
    genericName: 'CODEINE PHOSPHATE TABLET 15 MG',
    strength: '15MG',
    unitPrice: 0.054,
    MFRs: {
      TEV: {
        _id: '6201ba22f65f11ad3d79426b',
        DIN: '593435',
        genericName: 'CODEINE PHOSPHATE TABLET 15 MG',
        supplierCode: 'TEV',
        brandName: 'CODEINE TB 15MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 5.42,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '18368',
    genericName: 'FLUTICASONE PROPIONATE SPRAY S',
    strength: '50MCG/DS',
    unitPrice: 0.183,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79c627',
        DIN: '2294745',
        genericName: 'FLUTICASONE PROPIONATE SPRAY S',
        supplierCode: 'APX',
        brandName: 'FLUTICASONE NAS SP 50MCG/DS 120 APO',
        form: 'SPRAY',
        packSize: '120',
        rebateValue: '35',
        packPrice: 21.97,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba23f65f11ad3d79598c',
        DIN: '2453738',
        genericName: 'FLUTICASONE PROPIONATE SPRAY S',
        supplierCode: 'TEV',
        brandName: 'FLUTICASONE NAS SPRAY 50MCG/DS 120DS TEVA',
        form: 'AEROSOL MET DS',
        packSize: '120',
        rebateValue: '1',
        packPrice: 21.97,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '18434',
    genericName: 'NAPROXEN TABLET DR 250 MG ORAL',
    strength: '250MG',
    unitPrice: 0.284,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79cb10',
        DIN: '2246699',
        genericName: 'NAPROXEN TABLET DR 250 MG ORAL',
        supplierCode: 'APX',
        brandName: 'NAPROXEN EC TB 250MG 100 APO',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: '10',
        packPrice: 28.35,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c727',
        DIN: '2243312',
        genericName: 'NAPROXEN TABLET DR 250 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'NAPROXEN-EC TB 250MG 100 TEVA',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: '1',
        packPrice: 28.35,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f4d2',
        DIN: '2350785',
        genericName: 'NAPROXEN TABLET DR 250 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'NAPROXEN EC TB 250MG 100 SNS',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: null,
        packPrice: 28.35,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '19486',
    genericName: 'WARFARIN SODIUM TABLET 4 MG OR',
    strength: '4MG',
    unitPrice: 0.104,
    MFRs: {
      TAR: {
        _id: '6201ba28f65f11ad3d79e0da',
        DIN: '2242684',
        genericName: 'WARFARIN SODIUM TABLET 4 MG OR',
        supplierCode: 'TAR',
        brandName: 'WARFARIN TB 4MG 100 TARO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 10.43,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79bfc7',
        DIN: '2242927',
        genericName: 'WARFARIN SODIUM TABLET 4 MG OR',
        supplierCode: 'APX',
        brandName: 'WARFARIN TB 4MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 10.43,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '19879',
    genericName: 'METRONIDAZOLE CAPSULE 500 MG O',
    strength: '500MG',
    unitPrice: 0.274,
    MFRs: {
      PMS: {
        _id: '6201ba26f65f11ad3d79a562',
        DIN: '783137',
        genericName: 'METRONIDAZOLE CAPSULE 500 MG O',
        supplierCode: 'PMS',
        brandName: 'METRONIDAZOLE CAPS 500MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '50',
        packPrice: 27.39,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba23f65f11ad3d795adc',
        DIN: '2248562',
        genericName: 'METRONIDAZOLE CAPSULE 500 MG O',
        supplierCode: 'APX',
        brandName: 'METRONIDAZOLE CAPS 500MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '40',
        packPrice: 27.39,
        dailyAverageDispensed: 1,
      },
      ODN: {
        _id: '6201ba27f65f11ad3d79cd2e',
        DIN: '1926853',
        genericName: 'METRONIDAZOLE CAPSULE 500 MG O',
        supplierCode: 'ODN',
        brandName: 'FLAGYL CAPS 500MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 27.39,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d795ce8',
        DIN: '2470284',
        genericName: 'METRONIDAZOLE CAPSULE 500 MG O',
        supplierCode: 'AUP',
        brandName: 'METRONIDAZOLE CAPS 500MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 29.39,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '21156',
    genericName: 'RISPERIDONE TABLET 3 MG ORAL',
    strength: '3MG',
    unitPrice: 0.718,
    MFRs: {
      PMS: {
        _id: '6201ba24f65f11ad3d798b0a',
        DIN: '2252058',
        genericName: 'RISPERIDONE TABLET 3 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'RISPERIDONE TB 3MG BLST 60 PMS',
        form: 'TABLET',
        packSize: '60',
        rebateValue: '72',
        packPrice: 43.08,
        dailyAverageDispensed: 1,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79b6dc',
        DIN: '2282151',
        genericName: 'RISPERIDONE TABLET 3 MG ORAL',
        supplierCode: 'APX',
        brandName: 'RISPERIDONE TB 3MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '64',
        packPrice: 71.8,
        dailyAverageDispensed: 0,
      },
      RAN: {
        _id: '6201ba1ff65f11ad3d7903f9',
        DIN: '2328364',
        genericName: 'RISPERIDONE TABLET 3 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'RISPERIDONE TB 3MG BLS 60 RAN',
        form: 'TABLET',
        packSize: '60',
        rebateValue: '62',
        packPrice: 43.08,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba25f65f11ad3d79a211',
        DIN: '2279827',
        genericName: 'RISPERIDONE TABLET 3 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'RISPERIDONE TB 3MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 71.8,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba1ff65f11ad3d78f83e',
        DIN: '2359839',
        genericName: 'RISPERIDONE TABLET 3 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'RISPERIDONE TB 3MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 71.8,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d78f976',
        DIN: '2359561',
        genericName: 'RISPERIDONE TABLET 3 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'RISPERIDONE TB 3MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 71.8,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d78fd26',
        DIN: '2264226',
        genericName: 'RISPERIDONE TABLET 3 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'RISPERIDONE TB 3MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 71.8,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d791745',
        DIN: '2356929',
        genericName: 'RISPERIDONE TABLET 3 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'RISPERIDONE TB 3MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 71.8,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '21796',
    genericName: 'TACROLIMUS CAPSULE 1 MG ORAL',
    strength: '1MG',
    unitPrice: 2.738,
    MFRs: {
      SDZ: {
        _id: '6201ba20f65f11ad3d79139c',
        DIN: '2416824',
        genericName: 'TACROLIMUS CAPSULE 1 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'TACROLIMUS CAPS 1MG 100 SDZ',
        form: 'CAPS IR',
        packSize: '100',
        rebateValue: '1',
        packPrice: 189,
        dailyAverageDispensed: 1,
      },
      AHC: {
        _id: '6201ba23f65f11ad3d796eb8',
        DIN: '2456095',
        genericName: 'TACROLIMUS CAPSULE 1 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'TACROLIMUS CAPS 1MG 100 ACH',
        form: 'CAPS IR',
        packSize: '100',
        rebateValue: '1',
        packPrice: 129.78,
        dailyAverageDispensed: 0,
      },
      ASP: {
        _id: '6201ba28f65f11ad3d79e1a6',
        DIN: '2175991',
        genericName: 'TACROLIMUS CAPSULE 1 MG ORAL',
        supplierCode: 'ASP',
        brandName: 'PROGRAF CAPS 1MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 273.84,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '21844',
    genericName: 'TRANDOLAPRIL CAPSULE 2 MG ORAL',
    strength: '2MG',
    unitPrice: 0.872,
    MFRs: {
      PMS: {
        _id: '6201ba23f65f11ad3d795d41',
        DIN: '2357771',
        genericName: 'TRANDOLAPRIL CAPSULE 2 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'TRANDOLAPRIL CAPS 2MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '45',
        packPrice: 20.25,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d795eab',
        DIN: '2325756',
        genericName: 'TRANDOLAPRIL CAPSULE 2 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'TRANDOLAPRIL CAPS 2MG 100 SDZ',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '30',
        packPrice: 20.25,
        dailyAverageDispensed: 1,
      },
      BGP: {
        _id: '6201ba20f65f11ad3d791eba',
        DIN: '2231460',
        genericName: 'TRANDOLAPRIL CAPSULE 2 MG ORAL',
        supplierCode: 'BGP',
        brandName: 'MAVIK CAPS 2MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 87.19,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d7964d5',
        DIN: '2471884',
        genericName: 'TRANDOLAPRIL CAPSULE 2 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'TRANDOLAPRIL CAPS 2MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 20.25,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '23465',
    genericName: 'LOSARTAN/HYDROCHLOROTHIAZIDE T',
    strength: '50/12.5MG',
    unitPrice: 1.944,
    MFRs: {
      PMS: {
        _id: '6201ba1ff65f11ad3d7904d7',
        DIN: '2392224',
        genericName: 'LOSARTAN/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'PMS',
        brandName: 'LOSARTAN HCTZ TB 50/12.5MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 31.47,
        dailyAverageDispensed: 1,
      },
      JAP: {
        _id: '6201ba20f65f11ad3d791817',
        DIN: '2408244',
        genericName: 'LOSARTAN/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'JAP',
        brandName: 'LOSARTAN HCTZ TB 50/12.5MG UD 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '57',
        packPrice: 9.44,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d78ff2b',
        DIN: '2313375',
        genericName: 'LOSARTAN/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'SDZ',
        brandName: 'LOSARTAN HCT TB 50/12.5MG BLST 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '40',
        packPrice: 9.44,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d790cc8',
        DIN: '2389657',
        genericName: 'LOSARTAN/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'MPH',
        brandName: 'LOSARTAN/HCTZ TB 50/12.5MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 31.47,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba27f65f11ad3d79d877',
        DIN: '2230047',
        genericName: 'LOSARTAN/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'ORN',
        brandName: 'HYZAAR TB 50/12.5MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 58.31,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d7940a9',
        DIN: '2423642',
        genericName: 'LOSARTAN/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'AUP',
        brandName: 'LOSARTAN HCT TB 50/12.5MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 9.44,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d791c6a',
        DIN: '2427648',
        genericName: 'LOSARTAN/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'SNS',
        brandName: 'LOSARTAN HCT TB 50/12.5MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 31.47,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '23882',
    genericName: 'CYCLOSPORINE, MODIFIED CAPSULE',
    strength: '25MG',
    unitPrice: 1.643,
    MFRs: {
      SDZ: {
        _id: '6201ba1ff65f11ad3d7902e8',
        DIN: '2247073',
        genericName: 'CYCLOSPORINE, MODIFIED CAPSULE',
        supplierCode: 'SDZ',
        brandName: 'CYCLOSPORINE CAPS 25MG BLS 30 SDZ',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: '1',
        packPrice: 29.86,
        dailyAverageDispensed: 1,
      },
      NPC: {
        _id: '6201ba27f65f11ad3d79c9a7',
        DIN: '2150689',
        genericName: 'CYCLOSPORINE, MODIFIED CAPSULE',
        supplierCode: 'NPC',
        brandName: 'NEORAL CAPS 25MG 30',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 49.28,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '24515',
    genericName: 'ANASTROZOLE TABLET 1 MG ORAL',
    strength: '1MG',
    unitPrice: 5.776,
    MFRs: {
      APX: {
        _id: '6201ba1ff65f11ad3d7905cf',
        DIN: '2374420',
        genericName: 'ANASTROZOLE TABLET 1 MG ORAL',
        supplierCode: 'APX',
        brandName: 'ANASTROZOLE TB 1MG UD 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '68',
        packPrice: 28.57,
        dailyAverageDispensed: 1,
      },
      AHC: {
        _id: '6201ba1ff65f11ad3d7905b6',
        DIN: '2351218',
        genericName: 'ANASTROZOLE TABLET 1 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'ANASTROZOLE TB 1MG BLST 30 ACH',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '66',
        packPrice: 28.57,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d79052a',
        DIN: '2338467',
        genericName: 'ANASTROZOLE TABLET 1 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'ANASTROZOLE TB 1MG BLST 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 28.57,
        dailyAverageDispensed: 0,
      },
      TAR: {
        _id: '6201ba1ff65f11ad3d790650',
        DIN: '2365650',
        genericName: 'ANASTROZOLE TABLET 1 MG ORAL',
        supplierCode: 'TAR',
        brandName: 'ANASTROZOLE TB 1MG UD 30 TARO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 28.57,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d790980',
        DIN: '2393573',
        genericName: 'ANASTROZOLE TABLET 1 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'ANASTROZOLE TB 1MG UD 30 MINT',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '55',
        packPrice: 28.57,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d790693',
        DIN: '2320738',
        genericName: 'ANASTROZOLE TABLET 1 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'ANASTROZOLE TB 1MG BLST 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 28.57,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d790520',
        DIN: '2339080',
        genericName: 'ANASTROZOLE TABLET 1 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'ANASTROZOLE TB 1MG UD 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 28.57,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba1ff65f11ad3d790540',
        DIN: '2379562',
        genericName: 'ANASTROZOLE TABLET 1 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'ANASTROZOLE TB 1MG UD 30 MAR',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 28.57,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d790698',
        DIN: '2394898',
        genericName: 'ANASTROZOLE TABLET 1 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'ANASTROZOLE TB 1MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 28.57,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba27f65f11ad3d79d4bf',
        DIN: '2224135',
        genericName: 'ANASTROZOLE TABLET 1 MG ORAL',
        supplierCode: 'AST',
        brandName: 'ARIMIDEX TB 1MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 173.29,
        dailyAverageDispensed: 0,
      },
      RIV: {
        _id: '6201ba1ff65f11ad3d790699',
        DIN: '2392259',
        genericName: 'ANASTROZOLE TABLET 1 MG ORAL',
        supplierCode: 'RIV',
        brandName: 'ANASTROZOLE TB 1MG UD 30 RIVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 28.57,
        dailyAverageDispensed: 0,
      },
      NTC: {
        _id: '6201ba21f65f11ad3d792713',
        DIN: '2417855',
        genericName: 'ANASTROZOLE TABLET 1 MG ORAL',
        supplierCode: 'NTC',
        brandName: 'ANASTROZOLE TB 1MG 30 NAT',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 28.57,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d792f0d',
        DIN: '2442736',
        genericName: 'ANASTROZOLE TABLET 1 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'ANASTROZOLE TB 1MG BLST 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 28.57,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '24676',
    genericName: 'CLONAZEPAM TABLET 0.25 MG ORAL',
    strength: '0.25MG',
    unitPrice: 0.09,
    MFRs: {
      PMS: {
        _id: '6201ba20f65f11ad3d791042',
        DIN: '2179660',
        genericName: 'CLONAZEPAM TABLET 0.25 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'CLONAZEPAM TB 0.25MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 8.97,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '26721',
    genericName: 'AZITHROMYCIN TABLET 250 MG ORA',
    strength: '250MG',
    unitPrice: 5.738,
    MFRs: {
      APX: {
        _id: '6201ba20f65f11ad3d7915e6',
        DIN: '2415542',
        genericName: 'AZITHROMYCIN TABLET 250 MG ORA',
        supplierCode: 'APX',
        brandName: 'AZITHROMYCIN Z TB 250MG UD 6 APO',
        form: 'TABLET',
        packSize: '6',
        rebateValue: '62',
        packPrice: 5.65,
        dailyAverageDispensed: 1,
      },
      PMS: {
        _id: '6201ba28f65f11ad3d79df87',
        DIN: '2261634',
        genericName: 'AZITHROMYCIN TABLET 250 MG ORA',
        supplierCode: 'PMS',
        brandName: 'AZITHROMYCIN TB 250MG BLS 1X6 PMS',
        form: 'TABLET',
        packSize: '6',
        rebateValue: '55',
        packPrice: 5.65,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba27f65f11ad3d79d974',
        DIN: '2265826',
        genericName: 'AZITHROMYCIN TABLET 250 MG ORA',
        supplierCode: 'SDZ',
        brandName: 'AZITHROMYCIN TB 250MG BLST 6 SDZ',
        form: 'TABLET',
        packSize: '6',
        rebateValue: '55',
        packPrice: 5.65,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d793983',
        DIN: '2452308',
        genericName: 'AZITHROMYCIN TABLET 250 MG ORA',
        supplierCode: 'JAP',
        brandName: 'AZITHROMYCIN TB 250MG BLST 6 JAMP',
        form: 'TABLET',
        packSize: '6',
        rebateValue: '55',
        packPrice: 5.65,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba28f65f11ad3d79dcd0',
        DIN: '2267845',
        genericName: 'AZITHROMYCIN TABLET 250 MG ORA',
        supplierCode: 'TEV',
        brandName: 'AZITHROMYCIN TB 250MG BLS 6 TEVA',
        form: 'TABLET',
        packSize: '6',
        rebateValue: '1',
        packPrice: 5.65,
        dailyAverageDispensed: 0,
      },
      PFC: {
        _id: '6201ba26f65f11ad3d79b2ac',
        DIN: '2212021',
        genericName: 'AZITHROMYCIN TABLET 250 MG ORA',
        supplierCode: 'PFC',
        brandName: 'ZITHROMAX TB 250MG BLST 6',
        form: 'TABLET',
        packSize: '6',
        rebateValue: null,
        packPrice: 34.43,
        dailyAverageDispensed: 0,
      },
      RIV: {
        _id: '6201ba26f65f11ad3d79ba54',
        DIN: '2275309',
        genericName: 'AZITHROMYCIN TABLET 250 MG ORA',
        supplierCode: 'RIV',
        brandName: 'AZITHROMYCIN TB 250MG UD 6 RIVA',
        form: 'TABLET',
        packSize: '6',
        rebateValue: null,
        packPrice: 5.65,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba22f65f11ad3d7951ba',
        DIN: '2330881',
        genericName: 'AZITHROMYCIN TABLET 250 MG ORA',
        supplierCode: 'SNS',
        brandName: 'AZITHROMYCIN TB 250MG UD 6 SNS',
        form: 'TABLET',
        packSize: '6',
        rebateValue: null,
        packPrice: 5.65,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '28915',
    genericName: 'HYDROCHLOROTHIAZIDE TABLET 12.',
    strength: '12.5MG',
    unitPrice: 0.032,
    MFRs: {
      PMS: {
        _id: '6201ba21f65f11ad3d7923e6',
        DIN: '2274086',
        genericName: 'HYDROCHLOROTHIAZIDE TABLET 12.',
        supplierCode: 'PMS',
        brandName: 'HYDROCHLOROTHIAZIDE TB 12.5MG 500 PMS',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '50',
        packPrice: 16.12,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c89f',
        DIN: '2327856',
        genericName: 'HYDROCHLOROTHIAZIDE TABLET 12.',
        supplierCode: 'APX',
        brandName: 'HYDRO TB 12.5MG 500 APO',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '10',
        packPrice: 16.12,
        dailyAverageDispensed: 1,
      },
      MPH: {
        _id: '6201ba23f65f11ad3d796338',
        DIN: '2425947',
        genericName: 'HYDROCHLOROTHIAZIDE TABLET 12.',
        supplierCode: 'MPH',
        brandName: 'HYDROCHLOROTHIAZIDE TB 12.5MG 500 MINT',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '1',
        packPrice: 16.1,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '29077',
    genericName: 'OLANZAPINE TABLET 2.5 MG ORAL',
    strength: '2.5MG',
    unitPrice: 2.22,
    MFRs: {
      TEV: {
        _id: '6201ba26f65f11ad3d79b6c9',
        DIN: '2276712',
        genericName: 'OLANZAPINE TABLET 2.5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'OLANZAPINE TB 2.5MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 17.72,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba28f65f11ad3d79dda6',
        DIN: '2281791',
        genericName: 'OLANZAPINE TABLET 2.5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'OLANZAPINE TB 2.5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 17.72,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba24f65f11ad3d797b12',
        DIN: '2410141',
        genericName: 'OLANZAPINE TABLET 2.5 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'OLANZAPINE TB 2.5MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 17.72,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79cf30',
        DIN: '2303116',
        genericName: 'OLANZAPINE TABLET 2.5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'OLANZAPINE TB 2.5MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 17.72,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d792daf',
        DIN: '2417243',
        genericName: 'OLANZAPINE TABLET 2.5 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'OLANZAPINE FC TB 2.5MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 17.72,
        dailyAverageDispensed: 1,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d78f82c',
        DIN: '2310341',
        genericName: 'OLANZAPINE TABLET 2.5 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'OLANZAPINE TB 2.5MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 17.72,
        dailyAverageDispensed: 0,
      },
      LIL: {
        _id: '6201ba1ff65f11ad3d78f985',
        DIN: '2229250',
        genericName: 'OLANZAPINE TABLET 2.5 MG ORAL',
        supplierCode: 'LIL',
        brandName: 'ZYPREXA TB 2.5MG 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 62.15,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78fdd3',
        DIN: '2372819',
        genericName: 'OLANZAPINE TABLET 2.5 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'OLANZAPINE TB 2.5MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 17.72,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '29159',
    genericName: 'ROPINIROLE HCL TABLET 0.25 MG',
    strength: '0.25MG',
    unitPrice: 0.071,
    MFRs: {
      RAN: {
        _id: '6201ba27f65f11ad3d79d1ff',
        DIN: '2314037',
        genericName: 'ROPINIROLE HCL TABLET 0.25 MG',
        supplierCode: 'RAN',
        brandName: 'ROPINIROLE TB 0.25MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 7.1,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79ced8',
        DIN: '2316846',
        genericName: 'ROPINIROLE HCL TABLET 0.25 MG',
        supplierCode: 'TEV',
        brandName: 'ROPINIROLE TB 0.25MG 100 ACT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 7.1,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f3dc',
        DIN: '2353040',
        genericName: 'ROPINIROLE HCL TABLET 0.25 MG',
        supplierCode: 'SNS',
        brandName: 'ROPINIROLE TB 0.25MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 7.1,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '37050',
    genericName: 'FINASTERIDE TABLET 1 MG ORAL',
    strength: '1MG',
    unitPrice: 2.032,
    MFRs: {
      PMS: {
        _id: '6201ba26f65f11ad3d79b63b',
        DIN: '2320169',
        genericName: 'FINASTERIDE TABLET 1 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'FINASTERIDE TB 1MG BLS 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 34.36,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba26f65f11ad3d79bdba',
        DIN: '2339471',
        genericName: 'FINASTERIDE TABLET 1 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'FINASTERIDE A TB 1MG BLST 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 34.36,
        dailyAverageDispensed: 1,
      },
      ORN: {
        _id: '6201ba26f65f11ad3d79a5d5',
        DIN: '2238213',
        genericName: 'FINASTERIDE TABLET 1 MG ORAL',
        supplierCode: 'ORN',
        brandName: 'PROPECIA TB 1MG UD BLST 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 56.9,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d7944ea',
        DIN: '2428148',
        genericName: 'FINASTERIDE TABLET 1 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'FINASTERIDE TB 1MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 34.37,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d79389e',
        DIN: '2445085',
        genericName: 'FINASTERIDE TABLET 1 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'FINASTERIDE TB 1MG 30 SNS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 34.36,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '38375',
    genericName: 'PREDNISOLONE SOD PHOSPHATE SOL',
    strength: '5MG/5ML',
    unitPrice: 0.151,
    MFRs: {
      PMS: {
        _id: '6201ba27f65f11ad3d79c6e4',
        DIN: '2245532',
        genericName: 'PREDNISOLONE SOD PHOSPHATE SOL',
        supplierCode: 'PMS',
        brandName: 'PREDNISOLONE SOL ORAL 5MG/5ML 120ML PMS',
        form: 'SOLUTION',
        packSize: '120',
        rebateValue: '50',
        packPrice: 11.4,
        dailyAverageDispensed: 1,
      },
      SAC: {
        _id: '6201ba21f65f11ad3d792799',
        DIN: '2230619',
        genericName: 'PREDNISOLONE SOD PHOSPHATE SOL',
        supplierCode: 'SAC',
        brandName: 'PEDIAPRED ORAL SOL 5MG/5ML 120ML',
        form: 'SOLUTION',
        packSize: '120',
        rebateValue: null,
        packPrice: 18.11,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '39531',
    genericName: 'DORZOLAMIDE HCL/TIMOLOL MALEAT',
    strength: '20/5MG/ML',
    unitPrice: 8.558,
    MFRs: {
      JAP: {
        _id: '6201ba24f65f11ad3d798432',
        DIN: '2457539',
        genericName: 'DORZOLAMIDE HCL/TIMOLOL MALEAT',
        supplierCode: 'JAP',
        brandName: 'DORZOLAMIDE-TIMOLOL SOL 20MG/ML-5MG/ML 10ML JAMP',
        form: 'SOLUTION',
        packSize: '10',
        rebateValue: '60',
        packPrice: 20.95,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba23f65f11ad3d795d80',
        DIN: '2299615',
        genericName: 'DORZOLAMIDE HCL/TIMOLOL MALEAT',
        supplierCode: 'APX',
        brandName: 'DORZO TIMOP DRPS 20MG/5MG/ML 10ML APO',
        form: 'DROP MED',
        packSize: '10',
        rebateValue: '1',
        packPrice: 20.95,
        dailyAverageDispensed: 1,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d78f3f9',
        DIN: '2344351',
        genericName: 'DORZOLAMIDE HCL/TIMOLOL MALEAT',
        supplierCode: 'SDZ',
        brandName: 'DORZOLAMIDE 2% / TIMOLOL .5% OPHT SOL 10ML SDZ',
        form: 'SOLUTION',
        packSize: '10',
        rebateValue: '1',
        packPrice: 20.95,
        dailyAverageDispensed: 0,
      },
      ELV: {
        _id: '6201ba22f65f11ad3d7946b6',
        DIN: '2240113',
        genericName: 'DORZOLAMIDE HCL/TIMOLOL MALEAT',
        supplierCode: 'ELV',
        brandName: 'COSOPT OCUMETER DRPS 10ML',
        form: 'SOLUTION',
        packSize: '10',
        rebateValue: null,
        packPrice: 85.58,
        dailyAverageDispensed: 0,
      },
      GMP: {
        _id: '6201ba21f65f11ad3d792bde',
        DIN: '2437686',
        genericName: 'DORZOLAMIDE HCL/TIMOLOL MALEAT',
        supplierCode: 'GMP',
        brandName: 'DORZOLAMIDE 2% TIMOLOL 0.5% OPHT SOL 10ML MED',
        form: 'SOLUTION',
        packSize: '10',
        rebateValue: null,
        packPrice: 22.11,
        dailyAverageDispensed: 0,
      },
      TEL: {
        _id: '6201ba25f65f11ad3d798c42',
        DIN: '2489635',
        genericName: 'DORZOLAMIDE HCL/TIMOLOL MALEAT',
        supplierCode: 'TEL',
        brandName: 'DORZOLAMIDE & TIMOLOL EYE DROPS BP 2%/0.5% 10ML',
        form: 'SOLUTION',
        packSize: '10',
        rebateValue: null,
        packPrice: 20.95,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '39545',
    genericName: 'PANTOPRAZOLE SODIUM TABLET DR',
    strength: '20MG',
    unitPrice: 1.908,
    MFRs: {
      SDZ: {
        _id: '6201ba27f65f11ad3d79d44f',
        DIN: '2301075',
        genericName: 'PANTOPRAZOLE SODIUM TABLET DR',
        supplierCode: 'SDZ',
        brandName: 'PANTOPRAZOLE TB 20MG 100 SDZ',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: '68',
        packPrice: 127.5,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c699',
        DIN: '2292912',
        genericName: 'PANTOPRAZOLE SODIUM TABLET DR',
        supplierCode: 'APX',
        brandName: 'PANTOPRAZOLE TB 20MG 100 APO',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: '65',
        packPrice: 127.5,
        dailyAverageDispensed: 1,
      },
      JAP: {
        _id: '6201ba26f65f11ad3d79b2b1',
        DIN: '2392615',
        genericName: 'PANTOPRAZOLE SODIUM TABLET DR',
        supplierCode: 'JAP',
        brandName: 'PANTOPRAZOLE SODIUM TB 20MG 100 JAMP',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: '55',
        packPrice: 127.5,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d1d3',
        DIN: '2285479',
        genericName: 'PANTOPRAZOLE SODIUM TABLET DR',
        supplierCode: 'TEV',
        brandName: 'PANTOPRAZOLE TB 20MG 100 TEVA',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: '50',
        packPrice: 127.5,
        dailyAverageDispensed: 0,
      },
      NYC: {
        _id: '6201ba27f65f11ad3d79da04',
        DIN: '2241804',
        genericName: 'PANTOPRAZOLE SODIUM TABLET DR',
        supplierCode: 'NYC',
        brandName: 'PANTOLOC TB 20MG 100',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: null,
        packPrice: 190.84,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '40157',
    genericName: 'RIVASTIGMINE TARTRATE CAPSULE',
    strength: '4.5MG',
    unitPrice: 3.007,
    MFRs: {
      JAP: {
        _id: '6201ba24f65f11ad3d7980d8',
        DIN: '2485389',
        genericName: 'RIVASTIGMINE TARTRATE CAPSULE',
        supplierCode: 'JAP',
        brandName: 'RIVASTIGMINE CAPS 4.5MG BLST 56 JAMP',
        form: 'CAPSULE',
        packSize: '56',
        rebateValue: '70',
        packPrice: 36.48,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba26f65f11ad3d79be9b',
        DIN: '2324598',
        genericName: 'RIVASTIGMINE TARTRATE CAPSULE',
        supplierCode: 'SDZ',
        brandName: 'RIVASTIGMINE CAPS 4.5MG BL 4X14 SDZ',
        form: 'CAPSULE',
        packSize: '56',
        rebateValue: '1',
        packPrice: 36.48,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79bb7d',
        DIN: '2336731',
        genericName: 'RIVASTIGMINE TARTRATE CAPSULE',
        supplierCode: 'APX',
        brandName: 'RIVASTIGMINE CAPS 4.5MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 65.15,
        dailyAverageDispensed: 1,
      },
      NPC: {
        _id: '6201ba28f65f11ad3d79dc5e',
        DIN: '2242117',
        genericName: 'RIVASTIGMINE TARTRATE CAPSULE',
        supplierCode: 'NPC',
        brandName: 'EXELON GELCAPS 4.5MG BLS 4X14',
        form: 'CAPSULE',
        packSize: '56',
        rebateValue: null,
        packPrice: 168.38,
        dailyAverageDispensed: 0,
      },
      GMP: {
        _id: '6201ba21f65f11ad3d7920c1',
        DIN: '2401630',
        genericName: 'RIVASTIGMINE TARTRATE CAPSULE',
        supplierCode: 'GMP',
        brandName: 'RIVASTIGMINE CAPS 4.5MG BLST 56 MED',
        form: 'CAPSULE',
        packSize: '56',
        rebateValue: null,
        packPrice: 38.49,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '40303',
    genericName: 'ACETYLSAL ACID/DIPYRID CPMP 12',
    strength: '200MG/25MG',
    unitPrice: 0.666,
    MFRs: {
      TAR: {
        _id: '6201ba23f65f11ad3d796470',
        DIN: '2471051',
        genericName: 'ACETYLSAL ACID/DIPYRID CPMP 12',
        supplierCode: 'TAR',
        brandName: 'DIPYRIDAMOLE ASA CAPS 200MG/25MG 60',
        form: 'CAPSULE',
        packSize: '60',
        rebateValue: '1',
        packPrice: 39.94,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '40923',
    genericName: 'LOSARTAN/HYDROCHLOROTHIAZIDE T',
    strength: '100/25MG',
    unitPrice: 1.944,
    MFRs: {
      PMS: {
        _id: '6201ba1ff65f11ad3d7904da',
        DIN: '2392240',
        genericName: 'LOSARTAN/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'PMS',
        brandName: 'LOSARTAN HCTZ TB 100/25MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 31.47,
        dailyAverageDispensed: 1,
      },
      JAP: {
        _id: '6201ba20f65f11ad3d79181b',
        DIN: '2408252',
        genericName: 'LOSARTAN/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'JAP',
        brandName: 'LOSARTAN HCTZ TB 100/25MG UD 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '57',
        packPrice: 9.44,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d78ff30',
        DIN: '2313383',
        genericName: 'LOSARTAN/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'SDZ',
        brandName: 'LOSARTAN HCT DS TB 100/25MG BLS 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '40',
        packPrice: 9.44,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d790cc7',
        DIN: '2389673',
        genericName: 'LOSARTAN/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'MPH',
        brandName: 'LOSARTAN/HCTZ TB 100/25MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 31.47,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba27f65f11ad3d79d881',
        DIN: '2241007',
        genericName: 'LOSARTAN/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'ORN',
        brandName: 'HYZAAR DS TB 100/25MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 58.31,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d794081',
        DIN: '2423669',
        genericName: 'LOSARTAN/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'AUP',
        brandName: 'LOSARTAN HCT TB 100/25MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 9.44,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d791c69',
        DIN: '2427664',
        genericName: 'LOSARTAN/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'SNS',
        brandName: 'LOSARTAN HCT TB 100/25MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 31.47,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '41286',
    genericName: 'CELECOXIB CAPSULE 200 MG ORAL',
    strength: '200MG',
    unitPrice: 1.529,
    MFRs: {
      PMS: {
        _id: '6201ba21f65f11ad3d791ecf',
        DIN: '2355450',
        genericName: 'CELECOXIB CAPSULE 200 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'CELECOXIB CAPS 200MG 100 PMS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '67',
        packPrice: 25.58,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba21f65f11ad3d791efb',
        DIN: '2420066',
        genericName: 'CELECOXIB CAPSULE 200 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'CELECOXIB CAPS 200MG 100 MAR',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '52',
        packPrice: 25.58,
        dailyAverageDispensed: 1,
      },
      APX: {
        _id: '6201ba20f65f11ad3d791eb4',
        DIN: '2418940',
        genericName: 'CELECOXIB CAPSULE 200 MG ORAL',
        supplierCode: 'APX',
        brandName: 'CELECOXIB CAPS 200MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '50',
        packPrice: 25.58,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d791f50',
        DIN: '2424541',
        genericName: 'CELECOXIB CAPSULE 200 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'CELECOXIB CAPS 200MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '50',
        packPrice: 25.58,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba21f65f11ad3d791f18',
        DIN: '2412500',
        genericName: 'CELECOXIB CAPSULE 200 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'CELECOXIB CAPS 200MG 100 MINT',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '45',
        packPrice: 25.58,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba26f65f11ad3d79bb2d',
        DIN: '2239942',
        genericName: 'CELECOXIB CAPSULE 200 MG ORAL',
        supplierCode: 'UPJ',
        brandName: 'CELEBREX CAPS 200MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 152.93,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba25f65f11ad3d799b95',
        DIN: '2436302',
        genericName: 'CELECOXIB CAPSULE 200 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'CELECOXIB CAPS 200MG 100 SNS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 25.58,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d793987',
        DIN: '2445689',
        genericName: 'CELECOXIB CAPSULE 200 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'CELECOXIB CAPS 200MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 25.58,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '41897',
    genericName: 'IRBESARTAN/HYDROCHLOROTHIAZIDE',
    strength: '300/12.5MG',
    unitPrice: 1.337,
    MFRs: {
      SDZ: {
        _id: '6201ba1ff65f11ad3d78f6ab',
        DIN: '2337436',
        genericName: 'IRBESARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'SDZ',
        brandName: 'IRBESARTAN HCT TB 300/12.5MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '45',
        packPrice: 22.81,
        dailyAverageDispensed: 1,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d78f6bb',
        DIN: '2328526',
        genericName: 'IRBESARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'PMS',
        brandName: 'IRBESARTAN HCTZ TB 300/12.5MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '35',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d78f729',
        DIN: '2330520',
        genericName: 'IRBESARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'TEV',
        brandName: 'IRBESARTAN HCTZ TB 300/12.5MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '25',
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      SAC: {
        _id: '6201ba27f65f11ad3d79d5ca',
        DIN: '2241819',
        genericName: 'IRBESARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'SAC',
        brandName: 'AVALIDE TB 300MG/12.5MG 90',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 120.32,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d792de8',
        DIN: '2372894',
        genericName: 'IRBESARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'SNS',
        brandName: 'IRBESARTAN HCTZ TB 300/12.5MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 22.81,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d79453c',
        DIN: '2447886',
        genericName: 'IRBESARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'AUP',
        brandName: 'IRBESARTAN HCT TB 300/12.5MG 90 AURO',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 20.53,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '44697',
    genericName: 'CROMOLYN SODIUM DROPS 2% OPHTH',
    strength: '2%',
    unitPrice: 1.038,
    MFRs: {
      PPI: {
        _id: '6201ba26f65f11ad3d79a810',
        DIN: '2009277',
        genericName: 'CROMOLYN SODIUM DROPS 2% OPHTH',
        supplierCode: 'PPI',
        brandName: 'CROMOLYN OPTH SOL 2% 5ML',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: null,
        packPrice: 5.19,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '46204',
    genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
    strength: '40MG',
    unitPrice: 1.617,
    MFRs: {
      APX: {
        _id: '6201ba21f65f11ad3d792790',
        DIN: '2246057',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'APX',
        brandName: 'CITALOPRAM TB 40MG UD 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '68',
        packPrice: 4,
        dailyAverageDispensed: 1,
      },
      PMS: {
        _id: '6201ba22f65f11ad3d793aea',
        DIN: '2248011',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'PMS',
        brandName: 'CITALOPRAM TB 40MG UD 3X10 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 4,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba26f65f11ad3d79bb26',
        DIN: '2293226',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'TEV',
        brandName: 'CITALOPRAM TB 40MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 4,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba1ff65f11ad3d79040b',
        DIN: '2371901',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'MRC',
        brandName: 'CITALOPRAM TB 40MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 13.32,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba21f65f11ad3d793351',
        DIN: '2429713',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'MPH',
        brandName: 'CITALOPRAM TB 40MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 13.32,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d792fc4',
        DIN: '2430568',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'JAP',
        brandName: 'CITALOPRAM TB 40MG BLST 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 4,
        dailyAverageDispensed: 0,
      },
      LUN: {
        _id: '6201ba26f65f11ad3d79b70a',
        DIN: '2239608',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'LUN',
        brandName: 'CELEXA TB 40MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 48.5,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba1ff65f11ad3d78fed4',
        DIN: '2275570',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'AUP',
        brandName: 'CITALOPRAM TB 40MG BLST 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 4,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d79311d',
        DIN: '2353679',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'SNS',
        brandName: 'CITALOPRAM HBR TB 40MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 13.32,
        dailyAverageDispensed: 0,
      },
      NTC: {
        _id: '6201ba25f65f11ad3d799047',
        DIN: '2409038',
        genericName: 'CITALOPRAM HYDROBROMIDE TABLET',
        supplierCode: 'NTC',
        brandName: 'CITALOPRAM TB 40MG BLST 30 NAT',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 4,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '46217',
    genericName: 'FLUOXETINE HCL SOLUTION 20 MG/',
    strength: '20MG/5ML',
    unitPrice: 0.308,
    MFRs: {
      APX: {
        _id: '6201ba28f65f11ad3d79e041',
        DIN: '2231328',
        genericName: 'FLUOXETINE HCL SOLUTION 20 MG/',
        supplierCode: 'APX',
        brandName: 'FLUOXETINE ORAL SOL 20MG/5ML 120ML APO',
        form: 'SOLUTION',
        packSize: '120',
        rebateValue: '70',
        packPrice: 37.01,
        dailyAverageDispensed: 1,
      },
      ODN: {
        _id: '6201ba22f65f11ad3d7940ef',
        DIN: '2459361',
        genericName: 'FLUOXETINE HCL SOLUTION 20 MG/',
        supplierCode: 'ODN',
        brandName: 'FLUOXETINE SOL 20MG/5ML 120ML ODAN',
        form: 'SOLUTION',
        packSize: '120',
        rebateValue: null,
        packPrice: 37.01,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '46222',
    genericName: 'PAROXETINE HCL TABLET 10 MG OR',
    strength: '10MG',
    unitPrice: 1.989,
    MFRs: {
      JAP: {
        _id: '6201ba1ff65f11ad3d79023a',
        DIN: '2368862',
        genericName: 'PAROXETINE HCL TABLET 10 MG OR',
        supplierCode: 'JAP',
        brandName: 'PAROXETINE TB 10MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 104.3,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79b774',
        DIN: '2240907',
        genericName: 'PAROXETINE HCL TABLET 10 MG OR',
        supplierCode: 'APX',
        brandName: 'PAROXETINE TB 10MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '67',
        packPrice: 104.3,
        dailyAverageDispensed: 1,
      },
      PMS: {
        _id: '6201ba26f65f11ad3d79ba53',
        DIN: '2247750',
        genericName: 'PAROXETINE HCL TABLET 10 MG OR',
        supplierCode: 'PMS',
        brandName: 'PAROXETINE TB 10MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 104.3,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba21f65f11ad3d791f96',
        DIN: '2421372',
        genericName: 'PAROXETINE HCL TABLET 10 MG OR',
        supplierCode: 'MPH',
        brandName: 'PAROXETINE TB 10MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 104.3,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d79189b',
        DIN: '2411946',
        genericName: 'PAROXETINE HCL TABLET 10 MG OR',
        supplierCode: 'MRC',
        brandName: 'PAROXETINE TB 10MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 104.3,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c7da',
        DIN: '2248556',
        genericName: 'PAROXETINE HCL TABLET 10 MG OR',
        supplierCode: 'TEV',
        brandName: 'PAROXETINE TB 10MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 104.3,
        dailyAverageDispensed: 0,
      },
      GSK: {
        _id: '6201ba21f65f11ad3d792cfe',
        DIN: '2027887',
        genericName: 'PAROXETINE HCL TABLET 10 MG OR',
        supplierCode: 'GSK',
        brandName: 'PAXIL TB 10MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 59.68,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79ad02',
        DIN: '2282844',
        genericName: 'PAROXETINE HCL TABLET 10 MG OR',
        supplierCode: 'SNS',
        brandName: 'PAROXETINE HCL TB 10MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 104.3,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790798',
        DIN: '2383276',
        genericName: 'PAROXETINE HCL TABLET 10 MG OR',
        supplierCode: 'AUP',
        brandName: 'PAROXETINE TB 10MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 104.3,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '46450',
    genericName: 'MIRTAZAPINE TABLET 15 MG ORAL',
    strength: '15MG',
    unitPrice: 0.375,
    MFRs: {
      PMS: {
        _id: '6201ba28f65f11ad3d79de94',
        DIN: '2273942',
        genericName: 'MIRTAZAPINE TABLET 15 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'MIRTAZAPINE TB 15MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 37.5,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79bc06',
        DIN: '2286610',
        genericName: 'MIRTAZAPINE TABLET 15 MG ORAL',
        supplierCode: 'APX',
        brandName: 'MIRTAZAPINE TB 15MG 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '54',
        packPrice: 11.25,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba25f65f11ad3d79a2bf',
        DIN: '2250594',
        genericName: 'MIRTAZAPINE TABLET 15 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'MIRTAZAPINE TB 15MG 50 SDZ',
        form: 'TABLET',
        packSize: '50',
        rebateValue: '50',
        packPrice: 18.75,
        dailyAverageDispensed: 1,
      },
      MYL: {
        _id: '6201ba27f65f11ad3d79d071',
        DIN: '2256096',
        genericName: 'MIRTAZAPINE TABLET 15 MG ORAL',
        supplierCode: 'MYL',
        brandName: 'MIRTAZAPINE TB 15MG 100 MYLAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 37.5,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d7915f7',
        DIN: '2411695',
        genericName: 'MIRTAZAPINE TABLET 15 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'MIRTAZAPINE TB 15MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 11.25,
        dailyAverageDispensed: 0,
      },
      SIV: {
        _id: '6201ba25f65f11ad3d7991d1',
        DIN: '2496666',
        genericName: 'MIRTAZAPINE TABLET 15 MG ORAL',
        supplierCode: 'SIV',
        brandName: 'MIRTAZAPINE TB 15MG 30 SIVEM',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 11.25,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '46649',
    genericName: 'ESOMEPRAZOLE MAGNESIUM TABLET',
    strength: '20MG',
    unitPrice: 2.509,
    MFRs: {
      TEV: {
        _id: '6201ba21f65f11ad3d792992',
        DIN: '2423855',
        genericName: 'ESOMEPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'TEV',
        brandName: 'ESOMEPRAZOLE TB 20MG UD 30 ACT',
        form: 'TAB DEL RELEASE',
        packSize: '30',
        rebateValue: '80',
        packPrice: 56.07,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79ad6e',
        DIN: '2339099',
        genericName: 'ESOMEPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'APX',
        brandName: 'ESOMEPRAZOLE DR TB 20MG BLST 30 APO',
        form: 'TAB DEL RELEASE',
        packSize: '30',
        rebateValue: '40',
        packPrice: 56.07,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d79649a',
        DIN: '2460920',
        genericName: 'ESOMEPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'SDZ',
        brandName: 'ESOMEPRAZOLE TB 20MG 100 SDZ',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: '40',
        packPrice: 186.9,
        dailyAverageDispensed: 0,
      },
      RAN: {
        _id: '6201ba21f65f11ad3d7922b0',
        DIN: '2423979',
        genericName: 'ESOMEPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'RAN',
        brandName: 'ESOMEPRAZOLE TB 20MG 100 RAN',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 186.9,
        dailyAverageDispensed: 1,
      },
      AST: {
        _id: '6201ba21f65f11ad3d7924c2',
        DIN: '2244521',
        genericName: 'ESOMEPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'AST',
        brandName: 'NEXIUM TB 20MG BLST 4X7',
        form: 'TAB DEL RELEASE',
        packSize: '28',
        rebateValue: null,
        packPrice: 70.25,
        dailyAverageDispensed: 0,
      },
      SIV: {
        _id: '6201ba26f65f11ad3d79b09f',
        DIN: '2442493',
        genericName: 'ESOMEPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'SIV',
        brandName: 'ESOMEPRAZOLE TB 20MG 30 SIVEM',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 59.16,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba24f65f11ad3d79886a',
        DIN: '2479419',
        genericName: 'ESOMEPRAZOLE MAGNESIUM TABLET',
        supplierCode: 'MYL',
        brandName: 'ESOMEPRAZOLE TB 20MG 100 MYLAN',
        form: 'TAB DEL RELEASE',
        packSize: '100',
        rebateValue: null,
        packPrice: 186.9,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '47318',
    genericName: 'METHYLPHENIDATE HCL TAB ER 24',
    strength: '54MG',
    unitPrice: 4.613,
    MFRs: {
      APX: {
        _id: '6201ba21f65f11ad3d7935e6',
        DIN: '2330377',
        genericName: 'METHYLPHENIDATE HCL TAB ER 24',
        supplierCode: 'APX',
        brandName: 'METHYLPHENIDATE ER TB 54MG 100 APO',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 84.79,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba22f65f11ad3d7951c1',
        DIN: '2441969',
        genericName: 'METHYLPHENIDATE HCL TAB ER 24',
        supplierCode: 'TEV',
        brandName: 'METHYLPHENIDATE ER TB 54MG 100 ACT',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 84.79,
        dailyAverageDispensed: 0,
      },
      JNO: {
        _id: '6201ba27f65f11ad3d79c8d5',
        DIN: '2247734',
        genericName: 'METHYLPHENIDATE HCL TAB ER 24',
        supplierCode: 'JNO',
        brandName: 'CONCERTA TB 54MG 100',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 461.34,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '48366',
    genericName: 'CALCIPOTRIOL/BETAMETHAS DIP OI',
    strength: '0.5MG/50MC',
    unitPrice: 1.853,
    MFRs: {
      TEV: {
        _id: '6201ba25f65f11ad3d7997d2',
        DIN: '2427419',
        genericName: 'CALCIPOTRIOL/BETAMETHAS DIP OI',
        supplierCode: 'TEV',
        brandName: 'BETAMETHASONE/CALCIPOT OINT 0.5MG/50MCG/G 60G TEVA',
        form: 'OINTMENT',
        packSize: '60',
        rebateValue: '1',
        packPrice: 75.27,
        dailyAverageDispensed: 1,
      },
      LEO: {
        _id: '6201ba21f65f11ad3d7933f6',
        DIN: '2244126',
        genericName: 'CALCIPOTRIOL/BETAMETHAS DIP OI',
        supplierCode: 'LEO',
        brandName: 'DOVOBET OINTMENT 0.5MG/50MCG 120G',
        form: 'OINTMENT',
        packSize: '120',
        rebateValue: null,
        packPrice: 222.34,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '48399',
    genericName: 'VALSARTAN TABLET 320 MG ORAL',
    strength: '320MG',
    unitPrice: 1.364,
    MFRs: {
      SDZ: {
        _id: '6201ba1ff65f11ad3d78f5b3',
        DIN: '2356775',
        genericName: 'VALSARTAN TABLET 320 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'VALSARTAN TB 320MG BLS 2X15 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '20',
        packPrice: 6.29,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d78f5ea',
        DIN: '2356686',
        genericName: 'VALSARTAN TABLET 320 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'VALSARTAN TB 320MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 6.29,
        dailyAverageDispensed: 0,
      },
      NPC: {
        _id: '6201ba27f65f11ad3d79c1c6',
        DIN: '2289504',
        genericName: 'VALSARTAN TABLET 320 MG ORAL',
        supplierCode: 'NPC',
        brandName: 'DIOVAN TB 320MG BLS 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 38.19,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d79063a',
        DIN: '2366975',
        genericName: 'VALSARTAN TABLET 320 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'VALSARTAN TB 320MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 20.98,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d79251d',
        DIN: '2414244',
        genericName: 'VALSARTAN TABLET 320 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'VALSARTAN TB 320MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 20.98,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '48400',
    genericName: 'VALSARTAN TABLET 160 MG ORAL',
    strength: '160MG',
    unitPrice: 1.401,
    MFRs: {
      SDZ: {
        _id: '6201ba1ff65f11ad3d78f5af',
        DIN: '2356767',
        genericName: 'VALSARTAN TABLET 160 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'VALSARTAN TB 160MG BL 2X15 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '20',
        packPrice: 6.48,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d78f5f2',
        DIN: '2356678',
        genericName: 'VALSARTAN TABLET 160 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'VALSARTAN TB 160MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 6.48,
        dailyAverageDispensed: 0,
      },
      RAN: {
        _id: '6201ba1ff65f11ad3d78f60d',
        DIN: '2363119',
        genericName: 'VALSARTAN TABLET 160 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'VALSARTAN TB 160MG 100 TARO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 21.59,
        dailyAverageDispensed: 0,
      },
      NPC: {
        _id: '6201ba27f65f11ad3d79cdec',
        DIN: '2244782',
        genericName: 'VALSARTAN TABLET 160 MG ORAL',
        supplierCode: 'NPC',
        brandName: 'DIOVAN TB 160MG BLS 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 39.22,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d790629',
        DIN: '2366967',
        genericName: 'VALSARTAN TABLET 160 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'VALSARTAN TB 160MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 21.59,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d79188a',
        DIN: '2414236',
        genericName: 'VALSARTAN TABLET 160 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'VALSARTAN TB 160MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 21.59,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '48401',
    genericName: 'VALSARTAN TABLET 80 MG ORAL',
    strength: '80MG',
    unitPrice: 1.406,
    MFRs: {
      SDZ: {
        _id: '6201ba1ff65f11ad3d78f5b0',
        DIN: '2356759',
        genericName: 'VALSARTAN TABLET 80 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'VALSARTAN TB 80MG BLS 2X15 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '20',
        packPrice: 6.48,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d78f5e6',
        DIN: '2356651',
        genericName: 'VALSARTAN TABLET 80 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'VALSARTAN TB 80MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 6.48,
        dailyAverageDispensed: 0,
      },
      RAN: {
        _id: '6201ba1ff65f11ad3d78f60b',
        DIN: '2363100',
        genericName: 'VALSARTAN TABLET 80 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'VALSARTAN TB 80MG 100 TARO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 21.59,
        dailyAverageDispensed: 0,
      },
      NPC: {
        _id: '6201ba27f65f11ad3d79cded',
        DIN: '2244781',
        genericName: 'VALSARTAN TABLET 80 MG ORAL',
        supplierCode: 'NPC',
        brandName: 'DIOVAN TB 80MG BLS 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 39.37,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d790628',
        DIN: '2366959',
        genericName: 'VALSARTAN TABLET 80 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'VALSARTAN TB 80MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 21.59,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d791889',
        DIN: '2414228',
        genericName: 'VALSARTAN TABLET 80 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'VALSARTAN TB 80MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 21.59,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '48843',
    genericName: 'TENOFOVIR DISOPROXIL FUMARATE',
    strength: '300MG',
    unitPrice: 20.629,
    MFRs: {
      APX: {
        _id: '6201ba22f65f11ad3d7949fd',
        DIN: '2451980',
        genericName: 'TENOFOVIR DISOPROXIL FUMARATE',
        supplierCode: 'APX',
        brandName: 'TENOFOVIR/DISPROXIL TB 300MG 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '75',
        packPrice: 146.65,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba22f65f11ad3d794993',
        DIN: '2403889',
        genericName: 'TENOFOVIR DISOPROXIL FUMARATE',
        supplierCode: 'TEV',
        brandName: 'TENOFOVIR TB 300MG 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 146.65,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d795b1c',
        DIN: '2453940',
        genericName: 'TENOFOVIR DISOPROXIL FUMARATE',
        supplierCode: 'PMS',
        brandName: 'TENOFOVIR TB 300MG 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 146.65,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba23f65f11ad3d796dbb',
        DIN: '2479087',
        genericName: 'TENOFOVIR DISOPROXIL FUMARATE',
        supplierCode: 'JAP',
        brandName: 'TENOFOVIR TB 300MG 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 146.65,
        dailyAverageDispensed: 1,
      },
      GSI: {
        _id: '6201ba22f65f11ad3d7944e7',
        DIN: '2247128',
        genericName: 'TENOFOVIR DISOPROXIL FUMARATE',
        supplierCode: 'GSI',
        brandName: 'VIREAD TB 300MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 618.88,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba22f65f11ad3d794ad4',
        DIN: '2452634',
        genericName: 'TENOFOVIR DISOPROXIL FUMARATE',
        supplierCode: 'MYL',
        brandName: 'TENOFOVIR/DISPROXIL TB 300MG 30 MYLAN',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 146.65,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d794a4c',
        DIN: '2460173',
        genericName: 'TENOFOVIR DISOPROXIL FUMARATE',
        supplierCode: 'AUP',
        brandName: 'TENOFOVIR TB 300MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 146.65,
        dailyAverageDispensed: 0,
      },
      NTC: {
        _id: '6201ba23f65f11ad3d795c53',
        DIN: '2472511',
        genericName: 'TENOFOVIR DISOPROXIL FUMARATE',
        supplierCode: 'NTC',
        brandName: 'TENOFOVIR TB 300MG 30 NAT',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 146.65,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79aab7',
        DIN: '2512327',
        genericName: 'TENOFOVIR DISOPROXIL FUMARATE',
        supplierCode: 'SNS',
        brandName: 'TENOFOVIR TB 300MG 30 SNS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 146.65,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba26f65f11ad3d79aaf7',
        DIN: '2512939',
        genericName: 'TENOFOVIR DISOPROXIL FUMARATE',
        supplierCode: 'MPH',
        brandName: 'TENOFOVIR TB 300MG 30 MINT',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '0',
        packPrice: 146.65,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '50364',
    genericName: 'RISEDRONATE SODIUM TABLET 35 M',
    strength: '35MG',
    unitPrice: 12.243,
    MFRs: {
      TEV: {
        _id: '6201ba28f65f11ad3d79de30',
        DIN: '2298392',
        genericName: 'RISEDRONATE SODIUM TABLET 35 M',
        supplierCode: 'TEV',
        brandName: 'RISEDRONATE TB 35MG UD 4 TEVA',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '75',
        packPrice: 7.91,
        dailyAverageDispensed: 1,
      },
      PMS: {
        _id: '6201ba28f65f11ad3d79e027',
        DIN: '2302209',
        genericName: 'RISEDRONATE SODIUM TABLET 35 M',
        supplierCode: 'PMS',
        brandName: 'RISEDRONATE TB 35MG BL 4 PMS',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '72',
        packPrice: 7.91,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba28f65f11ad3d79dc3a',
        DIN: '2327295',
        genericName: 'RISEDRONATE SODIUM TABLET 35 M',
        supplierCode: 'SDZ',
        brandName: 'RISEDRONATE TB 35MG BL 4 SDZ',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '67',
        packPrice: 7.91,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba28f65f11ad3d79e12d',
        DIN: '2353687',
        genericName: 'RISEDRONATE SODIUM TABLET 35 M',
        supplierCode: 'APX',
        brandName: 'RISEDRONATE TB 35MG UD 4 APO',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '60',
        packPrice: 7.91,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d7902b1',
        DIN: '2368552',
        genericName: 'RISEDRONATE SODIUM TABLET 35 M',
        supplierCode: 'JAP',
        brandName: 'RISEDRONATE TB 35MG UD 4 JAMP',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '50',
        packPrice: 7.91,
        dailyAverageDispensed: 0,
      },
      ALL: {
        _id: '6201ba25f65f11ad3d798ee3',
        DIN: '2246896',
        genericName: 'RISEDRONATE SODIUM TABLET 35 M',
        supplierCode: 'ALL',
        brandName: 'ACTONEL TB 35MG BLS 4',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 48.97,
        dailyAverageDispensed: 0,
      },
      RIV: {
        _id: '6201ba28f65f11ad3d79dd9b',
        DIN: '2341077',
        genericName: 'RISEDRONATE SODIUM TABLET 35 M',
        supplierCode: 'RIV',
        brandName: 'RISEDRONATE TB 35MG 4 RIVA',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 7.91,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d7902b2',
        DIN: '2370255',
        genericName: 'RISEDRONATE SODIUM TABLET 35 M',
        supplierCode: 'SNS',
        brandName: 'RISEDRONATE TB 35MG BLST 4 SNS',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 7.91,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d7919e0',
        DIN: '2406306',
        genericName: 'RISEDRONATE SODIUM TABLET 35 M',
        supplierCode: 'AUP',
        brandName: 'RISEDRONATE TB 35MG BLST 4 AURO',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 7.91,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '50805',
    genericName: 'VALSARTAN TABLET 40 MG ORAL',
    strength: '40MG',
    unitPrice: 1.377,
    MFRs: {
      SDZ: {
        _id: '6201ba1ff65f11ad3d78f5ab',
        DIN: '2356740',
        genericName: 'VALSARTAN TABLET 40 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'VALSARTAN TB 40MG BLS 2X15 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '25',
        packPrice: 17.47,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d78f5e3',
        DIN: '2356643',
        genericName: 'VALSARTAN TABLET 40 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'VALSARTAN TB 40MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 17.47,
        dailyAverageDispensed: 0,
      },
      RAN: {
        _id: '6201ba1ff65f11ad3d78f60a',
        DIN: '2363062',
        genericName: 'VALSARTAN TABLET 40 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'VALSARTAN TB 40MG 100 TARO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 58.23,
        dailyAverageDispensed: 0,
      },
      NPC: {
        _id: '6201ba28f65f11ad3d79dca1',
        DIN: '2270528',
        genericName: 'VALSARTAN TABLET 40 MG ORAL',
        supplierCode: 'NPC',
        brandName: 'DIOVAN TB 40MG BLS 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 38.55,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d790627',
        DIN: '2366940',
        genericName: 'VALSARTAN TABLET 40 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'VALSARTAN TB 40MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 58.23,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d791886',
        DIN: '2414201',
        genericName: 'VALSARTAN TABLET 40 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'VALSARTAN TB 40MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 58.23,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '51333',
    genericName: 'ARIPIPRAZOLE TABLET 10 MG ORAL',
    strength: '10MG',
    unitPrice: 4.647,
    MFRs: {
      MPH: {
        _id: '6201ba26f65f11ad3d79ab0a',
        DIN: '2483572',
        genericName: 'ARIPIPRAZOLE TABLET 10 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'ARIPIPRAZOLE TB 10MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 107.54,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba23f65f11ad3d795a63',
        DIN: '2471108',
        genericName: 'ARIPIPRAZOLE TABLET 10 MG ORAL',
        supplierCode: 'APX',
        brandName: 'ARIPIPRAZOLE TB 10MG 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 32.26,
        dailyAverageDispensed: 1,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d795eb0',
        DIN: '2466651',
        genericName: 'ARIPIPRAZOLE TABLET 10 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'ARIPIPRAZOLE TB 10MG 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 32.26,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d795ea2',
        DIN: '2473674',
        genericName: 'ARIPIPRAZOLE TABLET 10 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'ARIPIPRAZOLE TB 10MG 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '63',
        packPrice: 32.26,
        dailyAverageDispensed: 0,
      },
      OTS: {
        _id: '6201ba27f65f11ad3d79cfc5',
        DIN: '2322390',
        genericName: 'ARIPIPRAZOLE TABLET 10 MG ORAL',
        supplierCode: 'OTS',
        brandName: 'ABILIFY TB 10MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 139.42,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d795e89',
        DIN: '2460041',
        genericName: 'ARIPIPRAZOLE TABLET 10 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'ARIPIPRAZOLE TB 10MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 32.26,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79a40e',
        DIN: '2506726',
        genericName: 'ARIPIPRAZOLE TABLET 10 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'ARIPIPRAZOLE TB 10MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 107.54,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '51334',
    genericName: 'ARIPIPRAZOLE TABLET 15 MG ORAL',
    strength: '15MG',
    unitPrice: 5.485,
    MFRs: {
      MPH: {
        _id: '6201ba26f65f11ad3d79ab0b',
        DIN: '2483580',
        genericName: 'ARIPIPRAZOLE TABLET 15 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'ARIPIPRAZOLE TB 15MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 126.92,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba23f65f11ad3d795a61',
        DIN: '2471116',
        genericName: 'ARIPIPRAZOLE TABLET 15 MG ORAL',
        supplierCode: 'APX',
        brandName: 'ARIPIPRAZOLE TB 15MG 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 38.08,
        dailyAverageDispensed: 1,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d795eb1',
        DIN: '2466678',
        genericName: 'ARIPIPRAZOLE TABLET 15 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'ARIPIPRAZOLE TB 15MG 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 38.08,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d795ea3',
        DIN: '2473682',
        genericName: 'ARIPIPRAZOLE TABLET 15 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'ARIPIPRAZOLE TB 15MG 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '63',
        packPrice: 38.08,
        dailyAverageDispensed: 0,
      },
      OTS: {
        _id: '6201ba27f65f11ad3d79cfd1',
        DIN: '2322404',
        genericName: 'ARIPIPRAZOLE TABLET 15 MG ORAL',
        supplierCode: 'OTS',
        brandName: 'ABILIFY TB 15MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 164.54,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d795e91',
        DIN: '2460068',
        genericName: 'ARIPIPRAZOLE TABLET 15 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'ARIPIPRAZOLE TB 15MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 38.08,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79a407',
        DIN: '2506734',
        genericName: 'ARIPIPRAZOLE TABLET 15 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'ARIPIPRAZOLE TB 15MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 126.92,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '51335',
    genericName: 'ARIPIPRAZOLE TABLET 20 MG ORAL',
    strength: '20MG',
    unitPrice: 4.328,
    MFRs: {
      MPH: {
        _id: '6201ba26f65f11ad3d79af84',
        DIN: '2483599',
        genericName: 'ARIPIPRAZOLE TABLET 20 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'ARIPIPRAZOLE TB 20MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 100.17,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba23f65f11ad3d795a67',
        DIN: '2471124',
        genericName: 'ARIPIPRAZOLE TABLET 20 MG ORAL',
        supplierCode: 'APX',
        brandName: 'ARIPIPRAZOLE TB 20MG 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 30.05,
        dailyAverageDispensed: 1,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d795eb2',
        DIN: '2466686',
        genericName: 'ARIPIPRAZOLE TABLET 20 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'ARIPIPRAZOLE TB 20MG 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 30.05,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d795ea5',
        DIN: '2473690',
        genericName: 'ARIPIPRAZOLE TABLET 20 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'ARIPIPRAZOLE TB 20MG 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '63',
        packPrice: 30.05,
        dailyAverageDispensed: 0,
      },
      OTS: {
        _id: '6201ba27f65f11ad3d79cfdc',
        DIN: '2322412',
        genericName: 'ARIPIPRAZOLE TABLET 20 MG ORAL',
        supplierCode: 'OTS',
        brandName: 'ABILIFY TB 20MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 129.85,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d795e90',
        DIN: '2460076',
        genericName: 'ARIPIPRAZOLE TABLET 20 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'ARIPIPRAZOLE TB 20MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 30.05,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79a40a',
        DIN: '2506750',
        genericName: 'ARIPIPRAZOLE TABLET 20 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'ARIPIPRAZOLE TB 20MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 100.17,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '51336',
    genericName: 'ARIPIPRAZOLE TABLET 30 MG ORAL',
    strength: '30MG',
    unitPrice: 4.328,
    MFRs: {
      MPH: {
        _id: '6201ba26f65f11ad3d79ab0c',
        DIN: '2483602',
        genericName: 'ARIPIPRAZOLE TABLET 30 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'ARIPIPRAZOLE TB 30MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '75',
        packPrice: 100.17,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba23f65f11ad3d795a69',
        DIN: '2471132',
        genericName: 'ARIPIPRAZOLE TABLET 30 MG ORAL',
        supplierCode: 'APX',
        brandName: 'ARIPIPRAZOLE TB 30MG 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 30.05,
        dailyAverageDispensed: 1,
      },
      PMS: {
        _id: '6201ba23f65f11ad3d795eb4',
        DIN: '2466694',
        genericName: 'ARIPIPRAZOLE TABLET 30 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'ARIPIPRAZOLE TB 30MG 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '65',
        packPrice: 30.05,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d795ea6',
        DIN: '2473704',
        genericName: 'ARIPIPRAZOLE TABLET 30 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'ARIPIPRAZOLE TB 30MG 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '63',
        packPrice: 30.05,
        dailyAverageDispensed: 0,
      },
      OTS: {
        _id: '6201ba27f65f11ad3d79d00a',
        DIN: '2322455',
        genericName: 'ARIPIPRAZOLE TABLET 30 MG ORAL',
        supplierCode: 'OTS',
        brandName: 'ABILIFY TB 30MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 129.85,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d795eb9',
        DIN: '2460084',
        genericName: 'ARIPIPRAZOLE TABLET 30 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'ARIPIPRAZOLE TB 30MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 30.05,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79a40b',
        DIN: '2506785',
        genericName: 'ARIPIPRAZOLE TABLET 30 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'ARIPIPRAZOLE TB 30MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 100.17,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '53689',
    genericName: 'AMLODIPINE/ATORVASTATIN TABLET',
    strength: '5MG/10MG',
    unitPrice: 2.572,
    MFRs: {
      APX: {
        _id: '6201ba20f65f11ad3d791233',
        DIN: '2411253',
        genericName: 'AMLODIPINE/ATORVASTATIN TABLET',
        supplierCode: 'APX',
        brandName: 'AMLODIPINE/ATORVASTATIN TB 5/10MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 116.03,
        dailyAverageDispensed: 1,
      },
      UPJ: {
        _id: '6201ba28f65f11ad3d79dea7',
        DIN: '2273233',
        genericName: 'AMLODIPINE/ATORVASTATIN TABLET',
        supplierCode: 'UPJ',
        brandName: 'CADUET TB 5MG/10MG 90',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 231.49,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '53693',
    genericName: 'AMLODIPINE/ATORVASTATIN TABLET',
    strength: '10MG/10MG',
    unitPrice: 2.716,
    MFRs: {
      APX: {
        _id: '6201ba20f65f11ad3d791239',
        DIN: '2411318',
        genericName: 'AMLODIPINE/ATORVASTATIN TABLET',
        supplierCode: 'APX',
        brandName: 'AMLODIPINE/ATORVASTATIN TB 10/10MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 122.5,
        dailyAverageDispensed: 1,
      },
      UPJ: {
        _id: '6201ba28f65f11ad3d79dea1',
        DIN: '2273284',
        genericName: 'AMLODIPINE/ATORVASTATIN TABLET',
        supplierCode: 'UPJ',
        brandName: 'CADUET TB 10MG/10MG 90',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 244.41,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '53937',
    genericName: 'PRIMIDONE TABLET 125 MG ORAL',
    strength: '125MG',
    unitPrice: 0.06,
    MFRs: {
      AAA: {
        _id: '6201ba1ff65f11ad3d78f9ce',
        DIN: '399310',
        genericName: 'PRIMIDONE TABLET 125 MG ORAL',
        supplierCode: 'AAA',
        brandName: 'PRIMIDONE TB 125MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 5.96,
        dailyAverageDispensed: 1,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '58239',
    genericName: 'GALANTAMINE HBR CAP24H PEL 16',
    strength: '16MG',
    unitPrice: 1.246,
    MFRs: {
      JNO: {
        _id: '6201ba1ff65f11ad3d78f66a',
        DIN: '2316951',
        genericName: 'GALANTAMINE HBR CAP24H PEL 16',
        supplierCode: 'JNO',
        brandName: 'GALANTAMINE ER CAPS 16MG 30 PAT',
        form: 'CAPSULE EXT REL',
        packSize: '30',
        rebateValue: null,
        packPrice: 37.39,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba1ff65f11ad3d78f4f7',
        DIN: '2339447',
        genericName: 'GALANTAMINE HBR CAP24H PEL 16',
        supplierCode: 'MYL',
        brandName: 'GALANTAMINE ER CAPS 16MG 30 MYLAN',
        form: 'CAPSULE EXT REL',
        packSize: '30',
        rebateValue: null,
        packPrice: 37.4,
        dailyAverageDispensed: 1,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d793191',
        DIN: '2425165',
        genericName: 'GALANTAMINE HBR CAP24H PEL 16',
        supplierCode: 'AUP',
        brandName: 'GALANTAMINE ER CAPS 16MG 30 AURO',
        form: 'CAPSULE EXT REL',
        packSize: '30',
        rebateValue: null,
        packPrice: 37.4,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d79378e',
        DIN: '2443023',
        genericName: 'GALANTAMINE HBR CAP24H PEL 16',
        supplierCode: 'SNS',
        brandName: 'GALANTAMINE ER CAPS 16MG 100 SNS',
        form: 'CAPSULE EXT REL',
        packSize: '100',
        rebateValue: null,
        packPrice: 124.65,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '58934',
    genericName: 'ENTECAVIR TABLET 0.5 MG ORAL',
    strength: '0.5MG',
    unitPrice: 23.906,
    MFRs: {
      PMS: {
        _id: '6201ba20f65f11ad3d791c0b',
        DIN: '2430576',
        genericName: 'ENTECAVIR TABLET 0.5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'ENTECAVIR TB 0.5MG 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 165,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba20f65f11ad3d7908f0',
        DIN: '2396955',
        genericName: 'ENTECAVIR TABLET 0.5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'ENTECAVIR TB 0.5MG 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 165,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba23f65f11ad3d795e1d',
        DIN: '2467232',
        genericName: 'ENTECAVIR TABLET 0.5 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'ENTECAVIR TB 0.5MG 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 165,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba24f65f11ad3d798197',
        DIN: '2485907',
        genericName: 'ENTECAVIR TABLET 0.5 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'ENTECAVIR TB 0.5MG 30 MINT',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 165,
        dailyAverageDispensed: 0,
      },
      BQU: {
        _id: '6201ba26f65f11ad3d79ba8c',
        DIN: '2282224',
        genericName: 'ENTECAVIR TABLET 0.5 MG ORAL',
        supplierCode: 'BQU',
        brandName: 'BARACLUDE TB 0.5MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 717.19,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d793903',
        DIN: '2448777',
        genericName: 'ENTECAVIR TABLET 0.5 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'ENTECAVIR TB 0.5MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 165,
        dailyAverageDispensed: 1,
      },
      SIP: {
        _id: '6201ba25f65f11ad3d799ae7',
        DIN: '2453797',
        genericName: 'ENTECAVIR TABLET 0.5 MG ORAL',
        supplierCode: 'SIP',
        brandName: 'ENTECAVIR TB 0.5MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 165,
        dailyAverageDispensed: 0,
      },
      ACE: {
        _id: '6201ba24f65f11ad3d798a34',
        DIN: '2479907',
        genericName: 'ENTECAVIR TABLET 0.5 MG ORAL',
        supplierCode: 'ACE',
        brandName: 'ENTECAVIR TB 0.5MG 30 ACCEL',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 132,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '60781',
    genericName: 'VALSARTAN/HYDROCHLOROTHIAZIDE',
    strength: '320/12.5MG',
    unitPrice: 1.406,
    MFRs: {
      SDZ: {
        _id: '6201ba1ff65f11ad3d78f5bc',
        DIN: '2356724',
        genericName: 'VALSARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'SDZ',
        brandName: 'VALSARTAN HCT TB 320/12.5MG BL 2X15 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '25',
        packPrice: 6.71,
        dailyAverageDispensed: 1,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d78f5f1',
        DIN: '2357038',
        genericName: 'VALSARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'TEV',
        brandName: 'VALSARTAN HCT TB 320/12.5MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 6.71,
        dailyAverageDispensed: 0,
      },
      NPC: {
        _id: '6201ba27f65f11ad3d79d3b0',
        DIN: '2308908',
        genericName: 'VALSARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'NPC',
        brandName: 'DIOVAN HCT TB 320/12.5MG BLST 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 39.37,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d79063f',
        DIN: '2367033',
        genericName: 'VALSARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'SNS',
        brandName: 'VALSARTAN HCT TB 320/12.5MG BLST 30 SNS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 6.71,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d7915df',
        DIN: '2408147',
        genericName: 'VALSARTAN/HYDROCHLOROTHIAZIDE',
        supplierCode: 'AUP',
        brandName: 'VALSARTAN HCT TB 320/12.5MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 6.71,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '61273',
    genericName: 'METFORMIN HCL TABERGR24H 1000',
    strength: '1000MG',
    unitPrice: 1.328,
    MFRs: {
      APX: {
        _id: '6201ba22f65f11ad3d794de9',
        DIN: '2460653',
        genericName: 'METFORMIN HCL TABERGR24H 1000',
        supplierCode: 'APX',
        brandName: 'METFORMIN ER TB 1000MG 100 APO',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 101.53,
        dailyAverageDispensed: 1,
      },
      BAU: {
        _id: '6201ba27f65f11ad3d79cf43',
        DIN: '2300451',
        genericName: 'METFORMIN HCL TABERGR24H 1000',
        supplierCode: 'BAU',
        brandName: 'GLUMETZA TB 1000MG 90',
        form: 'TABLET EXT REL',
        packSize: '90',
        rebateValue: null,
        packPrice: 119.53,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '62314',
    genericName: 'RAMIPRIL/HYDROCHLOROTHIAZIDE T',
    strength: '10/25MG',
    unitPrice: 0.59,
    MFRs: {
      PMS: {
        _id: '6201ba28f65f11ad3d79e192',
        DIN: '2342170',
        genericName: 'RAMIPRIL/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'PMS',
        brandName: 'RAMIPRIL HCTZ TB 10/25MG BL 30 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '25',
        packPrice: 7.9,
        dailyAverageDispensed: 1,
      },
      RAN: {
        _id: '6201ba22f65f11ad3d795434',
        DIN: '2449471',
        genericName: 'RAMIPRIL/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'RAN',
        brandName: 'RAMIPRIL HCTZ TB 10/25MG 100 TARO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 26.34,
        dailyAverageDispensed: 0,
      },
      BAU: {
        _id: '6201ba26f65f11ad3d79be39',
        DIN: '2283182',
        genericName: 'RAMIPRIL/HYDROCHLOROTHIAZIDE T',
        supplierCode: 'BAU',
        brandName: 'ALTACE HCT TB 10/25MG BLS 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 16.52,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '62456',
    genericName: 'CLINDAMYCIN PHOS/BENZOYL PERX',
    strength: '1%/5% W/W',
    unitPrice: 1.043,
    MFRs: {
      TAR: {
        _id: '6201ba21f65f11ad3d7927bf',
        DIN: '2440180',
        genericName: 'CLINDAMYCIN PHOS/BENZOYL PERX',
        supplierCode: 'TAR',
        brandName: 'TARO CLINDAMYCIN/BENZOYL PEROXIDE GEL 1%/5% 45G',
        form: 'GEL',
        packSize: '45',
        rebateValue: '1',
        packPrice: 30.86,
        dailyAverageDispensed: 1,
      },
      STI: {
        _id: '6201ba27f65f11ad3d79d982',
        DIN: '2243158',
        genericName: 'CLINDAMYCIN PHOS/BENZOYL PERX',
        supplierCode: 'STI',
        brandName: 'CLINDOXYL GEL 45G',
        form: 'GEL',
        packSize: '45',
        rebateValue: null,
        packPrice: 46.92,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '62663',
    genericName: 'ALENDRONATE SOD/VITAMIN D3 TAB',
    strength: '70/5600',
    unitPrice: 5.45,
    MFRs: {
      TEV: {
        _id: '6201ba20f65f11ad3d7910ff',
        DIN: '2403641',
        genericName: 'ALENDRONATE SOD/VITAMIN D3 TAB',
        supplierCode: 'TEV',
        brandName: 'ALENDRONATE/CHOLECALCIFEROL TB 70/5600 BLS 4 TEVA',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '50',
        packPrice: 4.87,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba21f65f11ad3d7935e0',
        DIN: '2454475',
        genericName: 'ALENDRONATE SOD/VITAMIN D3 TAB',
        supplierCode: 'APX',
        brandName: 'APO ALENDRONATE/VITAMIN D3 TB 70MG/5600IU BLST 4',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '10',
        packPrice: 4.87,
        dailyAverageDispensed: 1,
      },
      ORN: {
        _id: '6201ba1ff65f11ad3d78ff8d',
        DIN: '2314940',
        genericName: 'ALENDRONATE SOD/VITAMIN D3 TAB',
        supplierCode: 'ORN',
        brandName: 'FOSAVANCE TB 70/5600 BLS 4',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 21.8,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '64285',
    genericName: 'CANDESARTAN/HYDROCHLOROTHIAZID',
    strength: '32MG+25MG',
    unitPrice: 1.482,
    MFRs: {
      SDZ: {
        _id: '6201ba20f65f11ad3d7918db',
        DIN: '2420740',
        genericName: 'CANDESARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'SDZ',
        brandName: 'CANDESARTAN PLUS TB 32/25MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 30.08,
        dailyAverageDispensed: 1,
      },
      JAP: {
        _id: '6201ba24f65f11ad3d797451',
        DIN: '2473267',
        genericName: 'CANDESARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'JAP',
        brandName: 'CANDESARTAN HCT TB 32/25MG BLST 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 9.02,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba27f65f11ad3d79bfb4',
        DIN: '2332957',
        genericName: 'CANDESARTAN/HYDROCHLOROTHIAZID',
        supplierCode: 'AST',
        brandName: 'ATACAND PLUS TB 32MG+25MG UD 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 44.45,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '66215',
    genericName: 'PRUCALOPRIDE SUCCINATE TABLET',
    strength: '2MG',
    unitPrice: 4.574,
    MFRs: {
      JAP: {
        _id: '6201ba25f65f11ad3d79a09e',
        DIN: '2493497',
        genericName: 'PRUCALOPRIDE SUCCINATE TABLET',
        supplierCode: 'JAP',
        brandName: 'PRUCALOPRIDE TB 2MG UD BLST 28 JAMP',
        form: 'TABLET',
        packSize: '28',
        rebateValue: '30',
        packPrice: 86.87,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba24f65f11ad3d798582',
        DIN: '2491605',
        genericName: 'PRUCALOPRIDE SUCCINATE TABLET',
        supplierCode: 'APX',
        brandName: 'PRUCALOPRIDE 2MG TB 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 310.25,
        dailyAverageDispensed: 1,
      },
      JNO: {
        _id: '6201ba1ff65f11ad3d78fea9',
        DIN: '2377020',
        genericName: 'PRUCALOPRIDE SUCCINATE TABLET',
        supplierCode: 'JNO',
        brandName: 'RESOTRAN TB 2MG 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 128.06,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 1,
      total: 0,
    },
  },
  {
    groupCode: '509',
    genericName: 'ISOSORBIDE DINITRATE TABLET 30',
    strength: '30MG',
    unitPrice: 0.092,
    MFRs: {
      AAA: {
        _id: '6201ba1ff65f11ad3d78f881',
        DIN: '441694',
        genericName: 'ISOSORBIDE DINITRATE TABLET 30',
        supplierCode: 'AAA',
        brandName: 'ISDN TB 30MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 9.23,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '1194',
    genericName: 'SODIUM POLYSTYRENE SULFONATE P',
    strength: '100%',
    unitPrice: 0.098,
    MFRs: {
      JAP: {
        _id: '6201ba25f65f11ad3d799da3',
        DIN: '2497557',
        genericName: 'SODIUM POLYSTYRENE SULFONATE P',
        supplierCode: 'JAP',
        brandName: 'SODIUM POLYSTYRENE SULFONATE PD 1G/G 454G JAMP',
        form: 'POWDER FOR SUSP',
        packSize: '454',
        rebateValue: '20',
        packPrice: 29.42,
        dailyAverageDispensed: 0,
      },
      SAC: {
        _id: '6201ba26f65f11ad3d79bc60',
        DIN: '2026961',
        genericName: 'SODIUM POLYSTYRENE SULFONATE P',
        supplierCode: 'SAC',
        brandName: 'KAYEXALATE PD 454G',
        form: 'POWDER',
        packSize: '454',
        rebateValue: null,
        packPrice: 44.34,
        dailyAverageDispensed: 0,
      },
      ODN: {
        _id: '6201ba23f65f11ad3d7966ed',
        DIN: '2473941',
        genericName: 'SODIUM POLYSTYRENE SULFONATE P',
        supplierCode: 'ODN',
        brandName: 'SODIUM POLYSTYRENE SULFONATE 454G',
        form: 'POWDER FOR SUSP',
        packSize: '454',
        rebateValue: null,
        packPrice: 29.42,
        dailyAverageDispensed: 0,
      },
      PPI: {
        _id: '6201ba20f65f11ad3d791404',
        DIN: '755338',
        genericName: 'SODIUM POLYSTYRENE SULFONATE P',
        supplierCode: 'PPI',
        brandName: 'SOLYSTAT POWDER 454G',
        form: 'POWDER',
        packSize: '454',
        rebateValue: null,
        packPrice: 31.04,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '2329',
    genericName: 'CYANOCOBALAM (VIT B-12) VIAL',
    strength: '1000MCG/ML',
    unitPrice: 0.306,
    MFRs: {
      SDZ: {
        _id: '6201ba28f65f11ad3d79dfee',
        DIN: '521515',
        genericName: 'CYANOCOBALAM (VIT B-12) VIAL',
        supplierCode: 'SDZ',
        brandName: 'VITAMIN B12 INJ AMP 1000UG/ML 10X1ML SDZ',
        form: 'LIQUID',
        packSize: '1',
        rebateValue: '1',
        packPrice: 22.85,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '2599',
    genericName: 'TRIAMCINOLONE ACETONIDE PASTE',
    strength: '0.10%',
    unitPrice: 1.404,
    MFRs: {
      TAR: {
        _id: '6201ba28f65f11ad3d79de65',
        DIN: '1964054',
        genericName: 'TRIAMCINOLONE ACETONIDE PASTE',
        supplierCode: 'TAR',
        brandName: 'ORACORT PASTE 0.1% 7.5G',
        form: 'PASTE',
        packSize: '7.5',
        rebateValue: '1',
        packPrice: 10.53,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '3147',
    genericName: 'TESTOSTERONE CYPIONATE VIAL 10',
    strength: '100MG/ML',
    unitPrice: 3.953,
    MFRs: {
      TAR: {
        _id: '6201ba25f65f11ad3d7992a2',
        DIN: '2496003',
        genericName: 'TESTOSTERONE CYPIONATE VIAL 10',
        supplierCode: 'TAR',
        brandName: 'TESTOSTERONE CYPIONATE INJ 100MG/ML 10ML TARO',
        form: 'SOLUTION',
        packSize: '10',
        rebateValue: '1',
        packPrice: 39.53,
        dailyAverageDispensed: 0,
      },
      PFC: {
        _id: '6201ba26f65f11ad3d79bc38',
        DIN: '30783',
        genericName: 'TESTOSTERONE CYPIONATE VIAL 10',
        supplierCode: 'PFC',
        brandName: 'DEPO TESTOSTERONE 100MG/ML 10ML',
        form: 'SOLUTION',
        packSize: '10',
        rebateValue: null,
        packPrice: 50.78,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '3735',
    genericName: 'CHLORDIAZEPOXIDE HCL CAPSULE 2',
    strength: '25MG',
    unitPrice: 0.184,
    MFRs: {
      AAA: {
        _id: '6201ba20f65f11ad3d790d3e',
        DIN: '522996',
        genericName: 'CHLORDIAZEPOXIDE HCL CAPSULE 2',
        supplierCode: 'AAA',
        brandName: 'CHLORDIAZEPOXIDE CAPS 25MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 18.36,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '3768',
    genericName: 'DIAZEPAM TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 0.206,
    MFRs: {
      AAA: {
        _id: '6201ba24f65f11ad3d79770d',
        DIN: '362158',
        genericName: 'DIAZEPAM TABLET 5 MG ORAL',
        supplierCode: 'AAA',
        brandName: 'DIAZEPAM TB 5MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 6.86,
        dailyAverageDispensed: 0,
      },
      HLR: {
        _id: '6201ba1ff65f11ad3d78fc6e',
        DIN: '13285',
        genericName: 'DIAZEPAM TABLET 5 MG ORAL',
        supplierCode: 'HLR',
        brandName: 'VALIUM TB 5MG BLS 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 20.63,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '4110',
    genericName: 'HYDROMORPHONE HCL TABLET 2 MG',
    strength: '2MG',
    unitPrice: 0.15,
    MFRs: {
      PMS: {
        _id: '6201ba24f65f11ad3d7981a2',
        DIN: '885436',
        genericName: 'HYDROMORPHONE HCL TABLET 2 MG',
        supplierCode: 'PMS',
        brandName: 'HYDROMORPHONE TB 2MG BLST 6X15 PMS',
        form: 'TABLET',
        packSize: '90',
        rebateValue: '35',
        packPrice: 25.22,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba20f65f11ad3d79086a',
        DIN: '2364123',
        genericName: 'HYDROMORPHONE HCL TABLET 2 MG',
        supplierCode: 'APX',
        brandName: 'HYDROMORPHONE TB 2MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '20',
        packPrice: 14.17,
        dailyAverageDispensed: 0,
      },
      PFR: {
        _id: '6201ba24f65f11ad3d798974',
        DIN: '125083',
        genericName: 'HYDROMORPHONE HCL TABLET 2 MG',
        supplierCode: 'PFR',
        brandName: 'DILAUDID TB 2MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 14.95,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '4169',
    genericName: 'ACETAMINOPHEN WITH CODEINE TAB',
    strength: '300-60',
    unitPrice: 0.169,
    MFRs: {
      TEV: {
        _id: '6201ba26f65f11ad3d79a8e9',
        DIN: '621463',
        genericName: 'ACETAMINOPHEN WITH CODEINE TAB',
        supplierCode: 'TEV',
        brandName: 'LENOLTEC #4 CODEINE TB 300-60MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 16.85,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '4225',
    genericName: 'OXYCODONE HCL TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 0.307,
    MFRs: {
      PMS: {
        _id: '6201ba27f65f11ad3d79d7bb',
        DIN: '2319977',
        genericName: 'OXYCODONE HCL TABLET 5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'OXYCODONE TB 5MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 23.33,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba27f65f11ad3d79cceb',
        DIN: '789739',
        genericName: 'OXYCODONE HCL TABLET 5 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'SUPEUDOL TB 5MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 23.33,
        dailyAverageDispensed: 0,
      },
      PFR: {
        _id: '6201ba1ff65f11ad3d78f70d',
        DIN: '2231934',
        genericName: 'OXYCODONE HCL TABLET 5 MG ORAL',
        supplierCode: 'PFR',
        brandName: 'OXY/IR TB 5MG 60',
        form: 'TABLET',
        packSize: '60',
        rebateValue: null,
        packPrice: 18.43,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '4489',
    genericName: 'ACETAMINOPHEN TABLET 325 MG OR',
    strength: '325MG',
    unitPrice: 0.025,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79cbc8',
        DIN: '2362341',
        genericName: 'ACETAMINOPHEN TABLET 325 MG OR',
        supplierCode: 'APX',
        brandName: 'ACETAMINOPHEN CPLT 325MG 100 APC',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 3.36,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba26f65f11ad3d79b80f',
        DIN: '1938088',
        genericName: 'ACETAMINOPHEN TABLET 325 MG OR',
        supplierCode: 'JAP',
        brandName: 'ACETAMINOPHEN CPLT 325MG 1000 JAMP',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '1',
        packPrice: 24.5,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba22f65f11ad3d79541d',
        DIN: '389218',
        genericName: 'ACETAMINOPHEN TABLET 325 MG OR',
        supplierCode: 'TEV',
        brandName: 'GESIC CPLT 325MG 100 NOVO',
        form: 'CAPLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 2.45,
        dailyAverageDispensed: 0,
      },
      RIV: {
        _id: '6201ba27f65f11ad3d79bfcb',
        DIN: '2362198',
        genericName: 'ACETAMINOPHEN TABLET 325 MG OR',
        supplierCode: 'RIV',
        brandName: 'ACETAMINOPHEN CPLT 325MG 1000',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: null,
        packPrice: 24.5,
        dailyAverageDispensed: 0,
      },
      JJC: {
        _id: '6201ba27f65f11ad3d79c088',
        DIN: '559393',
        genericName: 'ACETAMINOPHEN TABLET 325 MG OR',
        supplierCode: 'JJC',
        brandName: 'TYLENOL RG TB 325MG 12',
        form: 'TABLET',
        packSize: '12',
        rebateValue: null,
        packPrice: 2.06,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '4490',
    genericName: 'ACETAMINOPHEN TABLET 500 MG OR',
    strength: '',
    unitPrice: 0.01,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79cbad',
        DIN: '2362244',
        genericName: 'ACETAMINOPHEN TABLET 500 MG OR',
        supplierCode: 'APX',
        brandName: 'ACETAMINOPHEN TB 500MG 100 APC',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 3.36,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba26f65f11ad3d79b805',
        DIN: '1939122',
        genericName: 'ACETAMINOPHEN TABLET 500 MG OR',
        supplierCode: 'JAP',
        brandName: 'ACETAMINOPHEN CPLT 500MG 1000 JAMP',
        form: 'CAPLET',
        packSize: '1000',
        rebateValue: '1',
        packPrice: 28.5,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba22f65f11ad3d79543b',
        DIN: '482323',
        genericName: 'ACETAMINOPHEN TABLET 500 MG OR',
        supplierCode: 'TEV',
        brandName: 'GESIC FORTE CPLT 500MG 100 NOVO',
        form: 'CAPLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 2.85,
        dailyAverageDispensed: 0,
      },
      STB: {
        _id: '6201ba1ff65f11ad3d78fa19',
        DIN: '0',
        genericName: 'ACETAMINOPHEN TABLET 500 MG OR',
        supplierCode: 'STB',
        brandName: 'MCVITIES HOB NOBS 300G',
        form: '',
        packSize: '300',
        rebateValue: null,
        packPrice: 2.85,
        dailyAverageDispensed: 0,
      },
      RIV: {
        _id: '6201ba27f65f11ad3d79bfcc',
        DIN: '2362228',
        genericName: 'ACETAMINOPHEN TABLET 500 MG OR',
        supplierCode: 'RIV',
        brandName: 'ACETAMINOPHEN CPLT 500MG 1000',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: null,
        packPrice: 28.5,
        dailyAverageDispensed: 0,
      },
      JJC: {
        _id: '6201ba27f65f11ad3d79c086',
        DIN: '559407',
        genericName: 'ACETAMINOPHEN TABLET 500 MG OR',
        supplierCode: 'JJC',
        brandName: 'TYLENOL XST TB 500MG BP 10',
        form: 'TABLET',
        packSize: '10',
        rebateValue: null,
        packPrice: 1.96,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '4560',
    genericName: 'CLONAZEPAM TABLET 0.5 MG ORAL',
    strength: '0.5MG',
    unitPrice: 0.042,
    MFRs: {
      PMS: {
        _id: '6201ba27f65f11ad3d79c0da',
        DIN: '2048701',
        genericName: 'CLONAZEPAM TABLET 0.5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'CLONAZEPAM TB 0.5MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 4.18,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79d3e8',
        DIN: '2177889',
        genericName: 'CLONAZEPAM TABLET 0.5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'CLONAZEPAM TB 0.5MG 500 APO',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '1',
        packPrice: 20.9,
        dailyAverageDispensed: 0,
      },
      HLR: {
        _id: '6201ba26f65f11ad3d79b80c',
        DIN: '382825',
        genericName: 'CLONAZEPAM TABLET 0.5 MG ORAL',
        supplierCode: 'HLR',
        brandName: 'RIVOTRIL TB 0.5MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 26.16,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '4562',
    genericName: 'CLONAZEPAM TABLET 2 MG ORAL',
    strength: '2MG',
    unitPrice: 0.072,
    MFRs: {
      PMS: {
        _id: '6201ba27f65f11ad3d79c0d2',
        DIN: '2048736',
        genericName: 'CLONAZEPAM TABLET 2 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'CLONAZEPAM TB 2MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 7.21,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79d3ea',
        DIN: '2177897',
        genericName: 'CLONAZEPAM TABLET 2 MG ORAL',
        supplierCode: 'APX',
        brandName: 'CLONAZEPAM TB 2MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 7.21,
        dailyAverageDispensed: 0,
      },
      HLR: {
        _id: '6201ba26f65f11ad3d79b80d',
        DIN: '382841',
        genericName: 'CLONAZEPAM TABLET 2 MG ORAL',
        supplierCode: 'HLR',
        brandName: 'RIVOTRIL TB 2MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 45.1,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '4590',
    genericName: 'BENZTROPINE MESYLATE TABLET 1',
    strength: '1MG',
    unitPrice: 0.055,
    MFRs: {
      PPI: {
        _id: '6201ba27f65f11ad3d79c457',
        DIN: '706531',
        genericName: 'BENZTROPINE MESYLATE TABLET 1',
        supplierCode: 'PPI',
        brandName: 'BENZTROPINE TB 1MG 1000 PDP',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: null,
        packPrice: 55.08,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '4814',
    genericName: 'ATROPINE SULFATE AMPUL 0.6MG/M',
    strength: '0.6MG/ML',
    unitPrice: 26.25,
    MFRs: {
      SDZ: {
        _id: '6201ba27f65f11ad3d79caf2',
        DIN: '392693',
        genericName: 'ATROPINE SULFATE AMPUL 0.6MG/M',
        supplierCode: 'SDZ',
        brandName: 'ATROPINE INJ AMP 0.6MG 10X1ML SDZ',
        form: 'SOLUTION',
        packSize: '1',
        rebateValue: '1',
        packPrice: 24.88,
        dailyAverageDispensed: 0,
      },
      TEL: {
        _id: '6201ba27f65f11ad3d79d420',
        DIN: '2432196',
        genericName: 'ATROPINE SULFATE AMPUL 0.6MG/M',
        supplierCode: 'TEL',
        brandName: 'ATROPINE AMP 0.6MG/ML 10X1ML',
        form: 'SOLUTION',
        packSize: '1',
        rebateValue: null,
        packPrice: 26.25,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '6754',
    genericName: 'PREDNISONE TABLET 50 MG ORAL',
    strength: '50MG',
    unitPrice: 0.174,
    MFRs: {
      TEV: {
        _id: '6201ba1ff65f11ad3d78f40f',
        DIN: '232378',
        genericName: 'PREDNISONE TABLET 50 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'PREDNISONE TB 50MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 17.35,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79da2f',
        DIN: '550957',
        genericName: 'PREDNISONE TABLET 50 MG ORAL',
        supplierCode: 'APX',
        brandName: 'PREDNISONE TB 50MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 17.35,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '6789',
    genericName: 'DEXAMETHASONE TABLET 4 MG ORAL',
    strength: '4MG',
    unitPrice: 0.305,
    MFRs: {
      PMS: {
        _id: '6201ba27f65f11ad3d79c737',
        DIN: '1964070',
        genericName: 'DEXAMETHASONE TABLET 4 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'DEXAMETHASONE TB 4MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 30.46,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c248',
        DIN: '2250055',
        genericName: 'DEXAMETHASONE TABLET 4 MG ORAL',
        supplierCode: 'APX',
        brandName: 'DEXAMETHASONE TB 4MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 30.46,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '7282',
    genericName: 'NYSTATIN CREAM (G) 100000/G TO',
    strength: '100000U/G',
    unitPrice: 0.096,
    MFRs: {
      TAR: {
        _id: '6201ba21f65f11ad3d792a7a',
        DIN: '716871',
        genericName: 'NYSTATIN CREAM (G) 100000/G TO',
        supplierCode: 'TAR',
        brandName: 'NYADERM CR TUBE 30G',
        form: 'CREAM',
        packSize: '30',
        rebateValue: '1',
        packPrice: 2.88,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '7334',
    genericName: 'KETOCONAZOLE CREAM (G) 2% TOPI',
    strength: '2%',
    unitPrice: 0.317,
    MFRs: {
      TAR: {
        _id: '6201ba27f65f11ad3d79c6f7',
        DIN: '2245662',
        genericName: 'KETOCONAZOLE CREAM (G) 2% TOPI',
        supplierCode: 'TAR',
        brandName: 'KETODERM CR 2% 30G',
        form: 'CREAM',
        packSize: '30',
        rebateValue: '12',
        packPrice: 9.5,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '7631',
    genericName: 'AMCINONIDE CREAM (G) 0.10% TOP',
    strength: '0.10%',
    unitPrice: 0.196,
    MFRs: {
      TAR: {
        _id: '6201ba27f65f11ad3d79c4a0',
        DIN: '2246714',
        genericName: 'AMCINONIDE CREAM (G) 0.10% TOP',
        supplierCode: 'TAR',
        brandName: 'AMCINONIDE CR 0.1% 30G TARO',
        form: 'CREAM',
        packSize: '30',
        rebateValue: '47',
        packPrice: 5.87,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '7635',
    genericName: 'CLOBETASOL PROPIONATE OINT. (G',
    strength: '0.05%',
    unitPrice: 0.228,
    MFRs: {
      TEV: {
        _id: '6201ba28f65f11ad3d79de6e',
        DIN: '1910280',
        genericName: 'CLOBETASOL PROPIONATE OINT. (G',
        supplierCode: 'TEV',
        brandName: 'CLOBETASOL TOPICAL OINT 0.05% 15G TEVA',
        form: 'OINTMENT',
        packSize: '15',
        rebateValue: '1',
        packPrice: 3.42,
        dailyAverageDispensed: 0,
      },
      TAR: {
        _id: '6201ba26f65f11ad3d79b772',
        DIN: '2213273',
        genericName: 'CLOBETASOL PROPIONATE OINT. (G',
        supplierCode: 'TAR',
        brandName: 'DERMOVATE OINT 0.05% 15G',
        form: 'OINTMENT',
        packSize: '15',
        rebateValue: '1',
        packPrice: 13.74,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba26f65f11ad3d79bd80',
        DIN: '2026767',
        genericName: 'CLOBETASOL PROPIONATE OINT. (G',
        supplierCode: 'MYL',
        brandName: 'CLOBETASOL OINT 0.05% 50G MYLAN',
        form: 'OINTMENT',
        packSize: '50',
        rebateValue: null,
        packPrice: 11.4,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '7639',
    genericName: 'MOMETASONE FUROATE OINT. (G) 0',
    strength: '0.10%',
    unitPrice: 0.225,
    MFRs: {
      TEV: {
        _id: '6201ba23f65f11ad3d7961a6',
        DIN: '2248130',
        genericName: 'MOMETASONE FUROATE OINT. (G) 0',
        supplierCode: 'TEV',
        brandName: 'MOMETASONE TOPICAL OINT 0.1% 15G TEVA',
        form: 'OINTMENT',
        packSize: '15',
        rebateValue: '1',
        packPrice: 3.38,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba25f65f11ad3d799db7',
        DIN: '851736',
        genericName: 'MOMETASONE FUROATE OINT. (G) 0',
        supplierCode: 'ORN',
        brandName: 'ELOCOM OINT 0.1% 50G',
        form: 'OINTMENT',
        packSize: '50',
        rebateValue: null,
        packPrice: 38.73,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '7670',
    genericName: 'ACYCLOVIR OINT. (G) 5% TOPICAL',
    strength: '5%',
    unitPrice: 12.313,
    MFRs: {
      APX: {
        _id: '6201ba24f65f11ad3d7970da',
        DIN: '2477130',
        genericName: 'ACYCLOVIR OINT. (G) 5% TOPICAL',
        supplierCode: 'APX',
        brandName: 'ACYCLOVIR OINT 5% W/W TUBE 4G APO',
        form: 'OINTMENT',
        packSize: '4',
        rebateValue: '10',
        packPrice: 49.25,
        dailyAverageDispensed: 0,
      },
      BAU: {
        _id: '6201ba26f65f11ad3d79bd2d',
        DIN: '569771',
        genericName: 'ACYCLOVIR OINT. (G) 5% TOPICAL',
        supplierCode: 'BAU',
        brandName: 'ZOVIRAX OINT 5% 4G',
        form: 'OINTMENT',
        packSize: '4',
        rebateValue: null,
        packPrice: 64.85,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '7856',
    genericName: 'TIMOLOL MALEATE DROPS 0.50% OP',
    strength: '0.50%',
    unitPrice: 1.214,
    MFRs: {
      JAP: {
        _id: '6201ba25f65f11ad3d799325',
        DIN: '2447800',
        genericName: 'TIMOLOL MALEATE DROPS 0.50% OP',
        supplierCode: 'JAP',
        brandName: 'TIMOLOL 0.5% W/V 5ML JAMP',
        form: 'DROP MED',
        packSize: '5',
        rebateValue: '40',
        packPrice: 6.07,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c681',
        DIN: '755834',
        genericName: 'TIMOLOL MALEATE DROPS 0.50% OP',
        supplierCode: 'APX',
        brandName: 'TIMOP OPHT SOL 0.5% 10ML APO',
        form: 'SOLUTION',
        packSize: '10',
        rebateValue: '5',
        packPrice: 12.15,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba28f65f11ad3d79df05',
        DIN: '2166720',
        genericName: 'TIMOLOL MALEATE DROPS 0.50% OP',
        supplierCode: 'SDZ',
        brandName: 'TIMOLOL MALEATE OPHT SOL 0.5% 5ML SDZ',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: '1',
        packPrice: 6.07,
        dailyAverageDispensed: 0,
      },
      MKS: {
        _id: '6201ba25f65f11ad3d798edb',
        DIN: '0',
        genericName: 'TIMOLOL MALEATE DROPS 0.50% OP',
        supplierCode: 'MKS',
        brandName: 'TIMO-STULLN TIMOLOL GERMANY OPHT SOL 0.5% 5ML',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: null,
        packPrice: 6.07,
        dailyAverageDispensed: 0,
      },
      ELV: {
        _id: '6201ba22f65f11ad3d7946b8',
        DIN: '451207',
        genericName: 'TIMOLOL MALEATE DROPS 0.50% OP',
        supplierCode: 'ELV',
        brandName: 'TIMOPTIC OPHT SOL DRP 0.5% 10ML',
        form: 'SOLUTION',
        packSize: '10',
        rebateValue: null,
        packPrice: 63.25,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '7948',
    genericName: 'ERYTHROMYCIN BASE OINT. (G) 5M',
    strength: '5MG/G',
    unitPrice: 6.331,
    MFRs: {
      PPI: {
        _id: '6201ba27f65f11ad3d79cbb4',
        DIN: '1912755',
        genericName: 'ERYTHROMYCIN BASE OINT. (G) 5M',
        supplierCode: 'PPI',
        brandName: 'ERYTHROMYCIN OINT 0.5% 50X1G PDP',
        form: 'OINTMENT',
        packSize: '1',
        rebateValue: null,
        packPrice: 316.49,
        dailyAverageDispensed: 0,
      },
      SRG: {
        _id: '6201ba26f65f11ad3d79b1ca',
        DIN: '2326663',
        genericName: 'ERYTHROMYCIN BASE OINT. (G) 5M',
        supplierCode: 'SRG',
        brandName: 'ERYTHROMYCIN OPHTHALMIC OINTMENT USP 5MG/G 50X1G',
        form: 'OINTMENT',
        packSize: '1',
        rebateValue: null,
        packPrice: 290.13,
        dailyAverageDispensed: 0,
      },
      ODN: {
        _id: '6201ba22f65f11ad3d793f27',
        DIN: '641324',
        genericName: 'ERYTHROMYCIN BASE OINT. (G) 5M',
        supplierCode: 'ODN',
        brandName: 'ODAN-ERYTHROMYCIN OPHT OINT 5MG/G 3.5G',
        form: 'OINTMENT',
        packSize: '3.5',
        rebateValue: null,
        packPrice: 13.39,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '8798',
    genericName: 'METHOTREXATE SODIUM VIAL 25 MG',
    strength: '25MG/ML',
    unitPrice: 16.46,
    MFRs: {
      AHC: {
        _id: '6201ba25f65f11ad3d7995af',
        DIN: '2464365',
        genericName: 'METHOTREXATE SODIUM VIAL 25 MG',
        supplierCode: 'AHC',
        brandName: 'METHOTREXATE W/PRESERVATIVE VL 25MG/ML 2ML ACH',
        form: 'SOLUTION',
        packSize: '2',
        rebateValue: '80',
        packPrice: 6.24,
        dailyAverageDispensed: 0,
      },
      PFC: {
        _id: '6201ba1ff65f11ad3d78fa6c',
        DIN: '2182777',
        genericName: 'METHOTREXATE SODIUM VIAL 25 MG',
        supplierCode: 'PFC',
        brandName: 'METHOTREXATE USP W/PRESERVATIVE 25MG/ML 5X2ML',
        form: 'SOLUTION',
        packSize: '2',
        rebateValue: null,
        packPrice: 32.92,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '8992',
    genericName: 'AMOXICILLIN/POTASSIUM CLAV TAB',
    strength: '500/125MG',
    unitPrice: 1.695,
    MFRs: {
      JAP: {
        _id: '6201ba26f65f11ad3d79b471',
        DIN: '2508257',
        genericName: 'AMOXICILLIN/POTASSIUM CLAV TAB',
        supplierCode: 'JAP',
        brandName: 'AMOXI CLAV TB 500/125MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 75.55,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba24f65f11ad3d798776',
        DIN: '2243351',
        genericName: 'AMOXICILLIN/POTASSIUM CLAV TAB',
        supplierCode: 'APX',
        brandName: 'AMOXI CLAV TB 500/125MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 75.55,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d7970b9',
        DIN: '2482576',
        genericName: 'AMOXICILLIN/POTASSIUM CLAV TAB',
        supplierCode: 'SDZ',
        brandName: 'AMOXI CLAV TB 500/125MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 75.55,
        dailyAverageDispensed: 0,
      },
      GSK: {
        _id: '6201ba1ff65f11ad3d790223',
        DIN: '1916858',
        genericName: 'AMOXICILLIN/POTASSIUM CLAV TAB',
        supplierCode: 'GSK',
        brandName: 'CLAVULIN TB 500MG BLS 4X5',
        form: 'TABLET',
        packSize: '20',
        rebateValue: null,
        packPrice: 33.89,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '8995',
    genericName: 'AMOXICILLIN CAPSULE 250 MG ORA',
    strength: '250MG',
    unitPrice: 0.067,
    MFRs: {
      JAP: {
        _id: '6201ba21f65f11ad3d79292c',
        DIN: '2433060',
        genericName: 'AMOXICILLIN CAPSULE 250 MG ORA',
        supplierCode: 'JAP',
        brandName: 'AMOXICILLIN CAPS 250MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '55',
        packPrice: 6.72,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba20f65f11ad3d79180c',
        DIN: '628115',
        genericName: 'AMOXICILLIN CAPSULE 250 MG ORA',
        supplierCode: 'APX',
        brandName: 'AMOXI CAPS 250MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '40',
        packPrice: 6.72,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba22f65f11ad3d79533e',
        DIN: '406724',
        genericName: 'AMOXICILLIN CAPSULE 250 MG ORA',
        supplierCode: 'TEV',
        brandName: 'AMOXICILLIN CAPS 250MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 6.72,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f4b4',
        DIN: '2352710',
        genericName: 'AMOXICILLIN CAPSULE 250 MG ORA',
        supplierCode: 'SNS',
        brandName: 'AMOXICILLIN CAPS 250MG 100 SNS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 6.72,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790b31',
        DIN: '2388073',
        genericName: 'AMOXICILLIN CAPSULE 250 MG ORA',
        supplierCode: 'AUP',
        brandName: 'AMOXICILLIN CAPS 250MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 6.72,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '9340',
    genericName: 'CLINDAMYCIN HCL CAPSULE 300 MG',
    strength: '300MG',
    unitPrice: 3.06,
    MFRs: {
      NRI: {
        _id: '6201ba25f65f11ad3d79928f',
        DIN: '2493756',
        genericName: 'CLINDAMYCIN HCL CAPSULE 300 MG',
        supplierCode: 'NRI',
        brandName: 'CLINDAMYCIN CAPS 300MG 100 NRA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '62',
        packPrice: 44.34,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba24f65f11ad3d797afc',
        DIN: '2483742',
        genericName: 'CLINDAMYCIN HCL CAPSULE 300 MG',
        supplierCode: 'JAP',
        brandName: 'CLINDAMYCIN CAPS 300MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '40',
        packPrice: 44.34,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d11d',
        DIN: '2241710',
        genericName: 'CLINDAMYCIN HCL CAPSULE 300 MG',
        supplierCode: 'TEV',
        brandName: 'CLINDAMYCIN CAPS 300MG 100 TEVA',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '1',
        packPrice: 44.34,
        dailyAverageDispensed: 0,
      },
      PFC: {
        _id: '6201ba27f65f11ad3d79c197',
        DIN: '2182866',
        genericName: 'CLINDAMYCIN HCL CAPSULE 300 MG',
        supplierCode: 'PFC',
        brandName: 'DALACIN C CAPS 300MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 306.04,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba22f65f11ad3d7940d9',
        DIN: '2436914',
        genericName: 'CLINDAMYCIN HCL CAPSULE 300 MG',
        supplierCode: 'AUP',
        brandName: 'CLINDAMYCIN CAPS 300MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 44.34,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '9396',
    genericName: 'SULFAMETHOXAZOLE/TRIMETHOPRIM',
    strength: '800-160MG',
    unitPrice: 0.219,
    MFRs: {
      AAA: {
        _id: '6201ba24f65f11ad3d797364',
        DIN: '445282',
        genericName: 'SULFAMETHOXAZOLE/TRIMETHOPRIM',
        supplierCode: 'AAA',
        brandName: 'SULFATRIM DS TB 800-160MG 500',
        form: 'TABLET',
        packSize: '500',
        rebateValue: '1',
        packPrice: 109.41,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '9508',
    genericName: 'NORFLOXACIN TABLET 400 MG ORAL',
    strength: '400MG',
    unitPrice: 1.961,
    MFRs: {
      AAA: {
        _id: '6201ba23f65f11ad3d796421',
        DIN: '2229524',
        genericName: 'NORFLOXACIN TABLET 400 MG ORAL',
        supplierCode: 'AAA',
        brandName: 'NORFLOX TB 400MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 196.09,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '9509',
    genericName: 'CIPROFLOXACIN HCL TABLET 250 M',
    strength: '250MG',
    unitPrice: 0.445,
    MFRs: {
      RAN: {
        _id: '6201ba27f65f11ad3d79d622',
        DIN: '2303728',
        genericName: 'CIPROFLOXACIN HCL TABLET 250 M',
        supplierCode: 'RAN',
        brandName: 'CIPROFLOXACIN TB 250MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '77',
        packPrice: 44.54,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba26f65f11ad3d79be8a',
        DIN: '2248437',
        genericName: 'CIPROFLOXACIN HCL TABLET 250 M',
        supplierCode: 'PMS',
        brandName: 'CIPROFLOXACIN TB 250MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 44.54,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba26f65f11ad3d79bcda',
        DIN: '2248756',
        genericName: 'CIPROFLOXACIN HCL TABLET 250 M',
        supplierCode: 'SDZ',
        brandName: 'CIPROFLOXACIN TB 250MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 44.54,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba1ff65f11ad3d790418',
        DIN: '2379686',
        genericName: 'CIPROFLOXACIN HCL TABLET 250 M',
        supplierCode: 'MRC',
        brandName: 'CIPROFLOXACIN TB 250MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '58',
        packPrice: 44.54,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d7903fd',
        DIN: '2380358',
        genericName: 'CIPROFLOXACIN HCL TABLET 250 M',
        supplierCode: 'JAP',
        brandName: 'CIPROFLOXACIN TB 250MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 44.54,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba28f65f11ad3d79de9b',
        DIN: '2423553',
        genericName: 'CIPROFLOXACIN HCL TABLET 250 M',
        supplierCode: 'MPH',
        brandName: 'CIPROFLOXACIN TB 250MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '45',
        packPrice: 44.54,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba26f65f11ad3d79bd47',
        DIN: '2247339',
        genericName: 'CIPROFLOXACIN HCL TABLET 250 M',
        supplierCode: 'TEV',
        brandName: 'CIPROFLOXACIN TB 250MG 100 ACT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 44.54,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f3c6',
        DIN: '2353318',
        genericName: 'CIPROFLOXACIN HCL TABLET 250 M',
        supplierCode: 'SNS',
        brandName: 'CIPROFLOXACIN TB 250MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 44.54,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba1ff65f11ad3d7905ae',
        DIN: '2381907',
        genericName: 'CIPROFLOXACIN HCL TABLET 250 M',
        supplierCode: 'AUP',
        brandName: 'CIPROFLOXACIN TB 250MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 44.54,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '11582',
    genericName: 'DIPHENHYDRAMINE HCL CAPSULE 25',
    strength: '25MG',
    unitPrice: 0.388,
    MFRs: {
      JJC: {
        _id: '6201ba26f65f11ad3d79ba04',
        DIN: '2304252',
        genericName: 'DIPHENHYDRAMINE HCL CAPSULE 25',
        supplierCode: 'JJC',
        brandName: 'BENADRYL RG LIQUIGEL 20',
        form: 'CAPSULE',
        packSize: '20',
        rebateValue: null,
        packPrice: 8.95,
        dailyAverageDispensed: 0,
      },
      PPI: {
        _id: '6201ba22f65f11ad3d7947bd',
        DIN: '757683',
        genericName: 'DIPHENHYDRAMINE HCL CAPSULE 25',
        supplierCode: 'PPI',
        brandName: 'PDP-DIPHENHYDRAMINE CAPS 25MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 9.82,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '11862',
    genericName: 'LORAZEPAM TAB SUBL 1 MG SUBLIN',
    strength: '1MG',
    unitPrice: 0.169,
    MFRs: {
      AAA: {
        _id: '6201ba23f65f11ad3d795ae0',
        DIN: '2410753',
        genericName: 'LORAZEPAM TAB SUBL 1 MG SUBLIN',
        supplierCode: 'AAA',
        brandName: 'LORAZEPAM SUBLINGUAL TB 1MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 13.76,
        dailyAverageDispensed: 0,
      },
      PFC: {
        _id: '6201ba21f65f11ad3d793302',
        DIN: '2041464',
        genericName: 'LORAZEPAM TAB SUBL 1 MG SUBLIN',
        supplierCode: 'PFC',
        brandName: 'ATIVAN SUBLINGUAL TB 1MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 16.95,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '11863',
    genericName: 'OXAZEPAM TABLET 10 MG ORAL',
    strength: '10MG',
    unitPrice: 0.035,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79d3da',
        DIN: '402680',
        genericName: 'OXAZEPAM TABLET 10 MG ORAL',
        supplierCode: 'APX',
        brandName: 'OXAZEPAM TB 10MG 1000 APO',
        form: 'TABLET',
        packSize: '1000',
        rebateValue: '10',
        packPrice: 35,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '11923',
    genericName: 'BETAMETHASONE VALERATE OINT. (',
    strength: '0.05%',
    unitPrice: 0.06,
    MFRs: {
      TAR: {
        _id: '6201ba27f65f11ad3d79bf59',
        DIN: '716642',
        genericName: 'BETAMETHASONE VALERATE OINT. (',
        supplierCode: 'TAR',
        brandName: 'BETADERM OINT 0.05% 454G',
        form: 'OINTMENT',
        packSize: '454',
        rebateValue: '52',
        packPrice: 27.06,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d78fd38',
        DIN: '2357879',
        genericName: 'BETAMETHASONE VALERATE OINT. (',
        supplierCode: 'PMS',
        brandName: 'CELESTODERM V/2 OINT 450G',
        form: 'OINTMENT',
        packSize: '450',
        rebateValue: '1',
        packPrice: 26.82,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '11943',
    genericName: 'PENICILLIN V POTASSIUM TABLET',
    strength: '300MG',
    unitPrice: 0.198,
    MFRs: {
      AAA: {
        _id: '6201ba20f65f11ad3d7918a8',
        DIN: '642215',
        genericName: 'PENICILLIN V POTASSIUM TABLET',
        supplierCode: 'AAA',
        brandName: 'PEN VK TB 300MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 19.77,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '13262',
    genericName: 'BECLOMETHASONE DIPROPIONATE SP',
    strength: '50MCG',
    unitPrice: 0.061,
    MFRs: {
      APX: {
        _id: '6201ba22f65f11ad3d793e87',
        DIN: '2238796',
        genericName: 'BECLOMETHASONE DIPROPIONATE SP',
        supplierCode: 'APX',
        brandName: 'BECLO AQ SP 50MCG 200DS APO',
        form: 'SPRAY',
        packSize: '200',
        rebateValue: '1',
        packPrice: 12.26,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba27f65f11ad3d79c325',
        DIN: '2172712',
        genericName: 'BECLOMETHASONE DIPROPIONATE SP',
        supplierCode: 'MYL',
        brandName: 'BECLO AQ 50MCG 22ML 200DS SPRAY MYLAN',
        form: 'MET DOSE PUMP',
        packSize: '200',
        rebateValue: null,
        packPrice: 12.26,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '13431',
    genericName: 'LORAZEPAM TAB SUBL 2 MG SUBLIN',
    strength: '2MG',
    unitPrice: 0.263,
    MFRs: {
      AAA: {
        _id: '6201ba23f65f11ad3d796e23',
        DIN: '2410761',
        genericName: 'LORAZEPAM TAB SUBL 2 MG SUBLIN',
        supplierCode: 'AAA',
        brandName: 'LORAZEPAM SUBLINGUAL TB 2MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 21.38,
        dailyAverageDispensed: 0,
      },
      PFC: {
        _id: '6201ba22f65f11ad3d794167',
        DIN: '2041472',
        genericName: 'LORAZEPAM TAB SUBL 2 MG SUBLIN',
        supplierCode: 'PFC',
        brandName: 'ATIVAN SUBLINGUAL TB 2MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 26.34,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '13725',
    genericName: 'FLUCONAZOLE TABLET 50 MG ORAL',
    strength: '50MG',
    unitPrice: 1.29,
    MFRs: {
      APX: {
        _id: '6201ba22f65f11ad3d793e65',
        DIN: '2237370',
        genericName: 'FLUCONAZOLE TABLET 50 MG ORAL',
        supplierCode: 'APX',
        brandName: 'FLUCONAZOLE TB 50MG 50 APO',
        form: 'TABLET',
        packSize: '50',
        rebateValue: '61',
        packPrice: 64.52,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79ceca',
        DIN: '2281260',
        genericName: 'FLUCONAZOLE TABLET 50 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'FLUCONAZOLE TB 50MG 50 ACT',
        form: 'TABLET',
        packSize: '50',
        rebateValue: '1',
        packPrice: 64.52,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79cb49',
        DIN: '2245643',
        genericName: 'FLUCONAZOLE TABLET 50 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'FLUCONAZOLE TB 50MG 50 PMS',
        form: 'TABLET',
        packSize: '50',
        rebateValue: '1',
        packPrice: 64.52,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba27f65f11ad3d79cb88',
        DIN: '2245292',
        genericName: 'FLUCONAZOLE TABLET 50 MG ORAL',
        supplierCode: 'MYL',
        brandName: 'FLUCONAZOLE TB 50MG 50 MYLAN',
        form: 'TABLET',
        packSize: '50',
        rebateValue: null,
        packPrice: 64.52,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79b479',
        DIN: '2517396',
        genericName: 'FLUCONAZOLE TABLET 50 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'FLUCONAZOLE TB 50MG 50',
        form: 'TABLET',
        packSize: '50',
        rebateValue: null,
        packPrice: 64.52,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '15695',
    genericName: 'BROMAZEPAM TABLET 3 MG ORAL',
    strength: '3MG',
    unitPrice: 0.09,
    MFRs: {
      TEV: {
        _id: '6201ba20f65f11ad3d791403',
        DIN: '2230584',
        genericName: 'BROMAZEPAM TABLET 3 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'BROMAZEPAM TB 3MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '10',
        packPrice: 8.97,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79d5e9',
        DIN: '2177161',
        genericName: 'BROMAZEPAM TABLET 3 MG ORAL',
        supplierCode: 'APX',
        brandName: 'BROMAZEPAM TB 3MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 8.97,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '16008',
    genericName: 'DICLOFENAC SODIUM DROPS 0.10%',
    strength: '0.10%',
    unitPrice: 4.006,
    MFRs: {
      APX: {
        _id: '6201ba21f65f11ad3d79283d',
        DIN: '2441020',
        genericName: 'DICLOFENAC SODIUM DROPS 0.10%',
        supplierCode: 'APX',
        brandName: 'DICLOFENAC 0.1% OPHT SOL 5ML APO',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: '1',
        packPrice: 6.2,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba21f65f11ad3d7939ae',
        DIN: '2454807',
        genericName: 'DICLOFENAC SODIUM DROPS 0.10%',
        supplierCode: 'SDZ',
        brandName: 'DICLOFENAC OPTHA SOL 0.1% 5ML SDZ',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: '1',
        packPrice: 6.2,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba25f65f11ad3d7993ec',
        DIN: '2475197',
        genericName: 'DICLOFENAC SODIUM DROPS 0.10%',
        supplierCode: 'MPH',
        brandName: 'DICLOFENAC OPHT SOL 0.1% 5ML MINT',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: '1',
        packPrice: 6.2,
        dailyAverageDispensed: 0,
      },
      NPC: {
        _id: '6201ba27f65f11ad3d79d61b',
        DIN: '1940414',
        genericName: 'DICLOFENAC SODIUM DROPS 0.10%',
        supplierCode: 'NPC',
        brandName: 'VOLTAREN OPHT DROPS 5ML',
        form: 'DROP MED',
        packSize: '5',
        rebateValue: null,
        packPrice: 20.03,
        dailyAverageDispensed: 0,
      },
      MKS: {
        _id: '6201ba24f65f11ad3d7978d8',
        DIN: '2475065',
        genericName: 'DICLOFENAC SODIUM DROPS 0.10%',
        supplierCode: 'MKS',
        brandName: 'DICLOFENAC OPHT SOL 0.1% 50X0.3ML',
        form: 'SOLUTION',
        packSize: '0.3',
        rebateValue: null,
        packPrice: 18.6,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '16775',
    genericName: 'DICLOFENAC SODIUM TABLET ER 75',
    strength: '75MG',
    unitPrice: 0.243,
    MFRs: {
      TEV: {
        _id: '6201ba25f65f11ad3d799317',
        DIN: '2158582',
        genericName: 'DICLOFENAC SODIUM TABLET ER 75',
        supplierCode: 'TEV',
        brandName: 'DICLOFENAC SODIUM SR TB 75MG 100 TEVA',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 24.33,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c0e1',
        DIN: '2162814',
        genericName: 'DICLOFENAC SODIUM TABLET ER 75',
        supplierCode: 'APX',
        brandName: 'DICLO SR TB 75MG 100 APO',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 24.33,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '17037',
    genericName: 'CETIRIZINE HCL TABLET 10 MG OR',
    strength: '10MG',
    unitPrice: 0.664,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79d0e5',
        DIN: '2240266',
        genericName: 'CETIRIZINE HCL TABLET 10 MG OR',
        supplierCode: 'APX',
        brandName: 'CETIRIZINE HYD XS 10MG 30 APC',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 13.38,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba24f65f11ad3d79888a',
        DIN: '2427133',
        genericName: 'CETIRIZINE HCL TABLET 10 MG OR',
        supplierCode: 'MRC',
        brandName: 'CETIRIZINE TB 10MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 40.83,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d793471',
        DIN: '2451778',
        genericName: 'CETIRIZINE HCL TABLET 10 MG OR',
        supplierCode: 'JAP',
        brandName: 'CETIRIZINE TB 10MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 40.83,
        dailyAverageDispensed: 0,
      },
      JJC: {
        _id: '6201ba23f65f11ad3d795d77',
        DIN: '2223554',
        genericName: 'CETIRIZINE HCL TABLET 10 MG OR',
        supplierCode: 'JJC',
        brandName: 'REACTINE XST 10MG 3',
        form: 'TABLET',
        packSize: '3',
        rebateValue: null,
        packPrice: 3.37,
        dailyAverageDispensed: 0,
      },
      NTC: {
        _id: '6201ba25f65f11ad3d7991a5',
        DIN: '2496461',
        genericName: 'CETIRIZINE HCL TABLET 10 MG OR',
        supplierCode: 'NTC',
        brandName: 'CETIRIZINE TB 10MG 100 NAT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 40.83,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '17616',
    genericName: 'DESOGESTREL-ETHINYL ESTRADIOL',
    strength: '0.15/0.03',
    unitPrice: 0.711,
    MFRs: {
      APX: {
        _id: '6201ba20f65f11ad3d7913d3',
        DIN: '2410249',
        genericName: 'DESOGESTREL-ETHINYL ESTRADIOL',
        supplierCode: 'APX',
        brandName: 'MIRVALA TB 0.15/0.03MG 21',
        form: 'TABLET',
        packSize: '21',
        rebateValue: '40',
        packPrice: 7.77,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d790c30',
        DIN: '2317192',
        genericName: 'DESOGESTREL-ETHINYL ESTRADIOL',
        supplierCode: 'TEV',
        brandName: 'APRI TB 0.15/0.03MG BLS 21 TEVA',
        form: 'TABLET',
        packSize: '21',
        rebateValue: '1',
        packPrice: 7.77,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba21f65f11ad3d793894',
        DIN: '2042487',
        genericName: 'DESOGESTREL-ETHINYL ESTRADIOL',
        supplierCode: 'ORN',
        brandName: 'MARVELON TB 21',
        form: 'TABLET',
        packSize: '21',
        rebateValue: null,
        packPrice: 19.91,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba20f65f11ad3d790d07',
        DIN: '2396491',
        genericName: 'DESOGESTREL-ETHINYL ESTRADIOL',
        supplierCode: 'MYL',
        brandName: 'FREYA TB 0.15/0.03MG UD 21',
        form: 'TABLET',
        packSize: '21',
        rebateValue: null,
        packPrice: 7.77,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '18165',
    genericName: 'BUDESONIDE AMPUL-NEB 1 MG/2 ML',
    strength: '0.5MG/ML',
    unitPrice: 20.17,
    MFRs: {
      TEV: {
        _id: '6201ba22f65f11ad3d795413',
        DIN: '2465957',
        genericName: 'BUDESONIDE AMPUL-NEB 1 MG/2 ML',
        supplierCode: 'TEV',
        brandName: 'BUDESONIDE 0.5MG/ML 20X2ML TEVA',
        form: 'SUSPENSION',
        packSize: '2',
        rebateValue: '1',
        packPrice: 18.24,
        dailyAverageDispensed: 0,
      },
      TAR: {
        _id: '6201ba25f65f11ad3d7992a1',
        DIN: '2494280',
        genericName: 'BUDESONIDE AMPUL-NEB 1 MG/2 ML',
        supplierCode: 'TAR',
        brandName: 'BUDESONIDE 0.5MG/ML 20X2ML TARO',
        form: 'SUSPENSION',
        packSize: '2',
        rebateValue: '1',
        packPrice: 18.24,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba27f65f11ad3d79ce93',
        DIN: '1978926',
        genericName: 'BUDESONIDE AMPUL-NEB 1 MG/2 ML',
        supplierCode: 'AST',
        brandName: 'PULMICORT NEBUAMP 0.5MG/ML 20X2ML',
        form: 'SUSPENSION',
        packSize: '2',
        rebateValue: null,
        packPrice: 40.34,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '18436',
    genericName: 'NAPROXEN TABLET DR 500 MG ORAL',
    strength: '500MG',
    unitPrice: 1.165,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79cb12',
        DIN: '2246701',
        genericName: 'NAPROXEN TABLET DR 500 MG ORAL',
        supplierCode: 'APX',
        brandName: 'NAPROXEN EC TB 500MG 100 APO',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: '10',
        packPrice: 68.94,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c731',
        DIN: '2243314',
        genericName: 'NAPROXEN TABLET DR 500 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'NAPROXEN-EC TB 500MG 100 TEVA',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: '1',
        packPrice: 68.94,
        dailyAverageDispensed: 0,
      },
      MPA: {
        _id: '6201ba20f65f11ad3d7913d2',
        DIN: '2162423',
        genericName: 'NAPROXEN TABLET DR 500 MG ORAL',
        supplierCode: 'MPA',
        brandName: 'NAPROSYN E TB 500MG 100',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: null,
        packPrice: 116.47,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f4d4',
        DIN: '2350807',
        genericName: 'NAPROXEN TABLET DR 500 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'NAPROXEN EC TB 500MG 100 SNS',
        form: 'TABLET EC',
        packSize: '100',
        rebateValue: null,
        packPrice: 68.94,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '18632',
    genericName: 'FLUCONAZOLE CAPSULE 150 MG ORA',
    strength: '150MG',
    unitPrice: 16.78,
    MFRs: {
      APX: {
        _id: '6201ba22f65f11ad3d793ac9',
        DIN: '2241895',
        genericName: 'FLUCONAZOLE CAPSULE 150 MG ORA',
        supplierCode: 'APX',
        brandName: 'FLUCONAZOLE CAPS 150MG 1 APO',
        form: 'CAPSULE',
        packSize: '1',
        rebateValue: '61',
        packPrice: 3.94,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d792506',
        DIN: '2432471',
        genericName: 'FLUCONAZOLE CAPSULE 150 MG ORA',
        supplierCode: 'JAP',
        brandName: 'FLUCONAZOLE CAPS 150MG 1 JAMP',
        form: 'CAPSULE',
        packSize: '1',
        rebateValue: '30',
        packPrice: 3.94,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba22f65f11ad3d793e03',
        DIN: '2428792',
        genericName: 'FLUCONAZOLE CAPSULE 150 MG ORA',
        supplierCode: 'MRC',
        brandName: 'FLUCONAZOLE CAPS 150MG 1 MAR',
        form: 'CAPSULE',
        packSize: '1',
        rebateValue: '1',
        packPrice: 3.94,
        dailyAverageDispensed: 0,
      },
      PFC: {
        _id: '6201ba20f65f11ad3d791520',
        DIN: '2141442',
        genericName: 'FLUCONAZOLE CAPSULE 150 MG ORA',
        supplierCode: 'PFC',
        brandName: 'DIFLUCAN ONE CONVENIENCE PACK 1',
        form: 'CAPSULE',
        packSize: '1',
        rebateValue: null,
        packPrice: 16.78,
        dailyAverageDispensed: 0,
      },
      BAY: {
        _id: '6201ba25f65f11ad3d799114',
        DIN: '2311690',
        genericName: 'FLUCONAZOLE CAPSULE 150 MG ORA',
        supplierCode: 'BAY',
        brandName: 'CANESORAL CAPS 1',
        form: 'CAPSULE',
        packSize: '1',
        rebateValue: null,
        packPrice: 16.84,
        dailyAverageDispensed: 0,
      },
      PHP: {
        _id: '6201ba26f65f11ad3d79a53e',
        DIN: '2433702',
        genericName: 'FLUCONAZOLE CAPSULE 150 MG ORA',
        supplierCode: 'PHP',
        brandName: 'FLUCONAZOLE CAPS 150MG 1 PRIVA',
        form: 'CAPSULE',
        packSize: '1',
        rebateValue: null,
        packPrice: 4.31,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '21871',
    genericName: 'FOSFOMYCIN TROMETHAMINE PACKET',
    strength: '3G/SACHET',
    unitPrice: 7.253,
    MFRs: {
      JAP: {
        _id: '6201ba23f65f11ad3d796175',
        DIN: '2473801',
        genericName: 'FOSFOMYCIN TROMETHAMINE PACKET',
        supplierCode: 'JAP',
        brandName: 'FOSFOMYCIN PD SACHET 3G JAMP',
        form: 'POWDER',
        packSize: '3',
        rebateValue: '1',
        packPrice: 14.81,
        dailyAverageDispensed: 0,
      },
      PLI: {
        _id: '6201ba1ff65f11ad3d790554',
        DIN: '2240335',
        genericName: 'FOSFOMYCIN TROMETHAMINE PACKET',
        supplierCode: 'PLI',
        brandName: 'MONUROL PD SACHET 3G',
        form: 'POWDER FOR SOL',
        packSize: '3',
        rebateValue: null,
        packPrice: 21.76,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '21887',
    genericName: 'HYDROQUINONE GEL (GRAM) 4% TOP',
    strength: '4%',
    unitPrice: 1.512,
    MFRs: {
      ERF: {
        _id: '6201ba21f65f11ad3d793640',
        DIN: '2491702',
        genericName: 'HYDROQUINONE GEL (GRAM) 4% TOP',
        supplierCode: 'ERF',
        brandName: 'HYDROQUINONE GEL 4% 30G ERFA',
        form: 'GEL',
        packSize: '30',
        rebateValue: null,
        packPrice: 45.37,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '21995',
    genericName: 'FAMCICLOVIR TABLET 500 MG ORAL',
    strength: '500MG',
    unitPrice: 8.17,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79c0c0',
        DIN: '2292068',
        genericName: 'FAMCICLOVIR TABLET 500 MG ORAL',
        supplierCode: 'APX',
        brandName: 'FAMCICLOVIR TB 500MG 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '40',
        packPrice: 40.31,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba27f65f11ad3d79ca3b',
        DIN: '2177102',
        genericName: 'FAMCICLOVIR TABLET 500 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'FAMVIR TB 500MG BLS 3X7',
        form: 'TABLET',
        packSize: '21',
        rebateValue: '1',
        packPrice: 171.57,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79d770',
        DIN: '2305704',
        genericName: 'FAMCICLOVIR TABLET 500 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'FAMCICLOVIR TB 500MG UD 21 ACT',
        form: 'TABLET',
        packSize: '21',
        rebateValue: '1',
        packPrice: 28.22,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '22479',
    genericName: 'SUMATRIPTAN SUCCINATE TABLET 5',
    strength: '50MG',
    unitPrice: 17.573,
    MFRs: {
      PMS: {
        _id: '6201ba28f65f11ad3d79da92',
        DIN: '2256436',
        genericName: 'SUMATRIPTAN SUCCINATE TABLET 5',
        supplierCode: 'PMS',
        brandName: 'SUMATRIPTAN TB 50MG UD 6 PMS',
        form: 'TABLET',
        packSize: '6',
        rebateValue: '70',
        packPrice: 54.39,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba28f65f11ad3d79da76',
        DIN: '2268388',
        genericName: 'SUMATRIPTAN SUCCINATE TABLET 5',
        supplierCode: 'APX',
        brandName: 'SUMATRIPTAN TB 50MG UD 6 APO',
        form: 'TABLET',
        packSize: '6',
        rebateValue: '50',
        packPrice: 54.39,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba26f65f11ad3d79bd6c',
        DIN: '2286823',
        genericName: 'SUMATRIPTAN SUCCINATE TABLET 5',
        supplierCode: 'TEV',
        brandName: 'SUMATRIPTAN DF TB 50MG UD 6 TEVA',
        form: 'TABLET',
        packSize: '6',
        rebateValue: '1',
        packPrice: 54.39,
        dailyAverageDispensed: 0,
      },
      GSK: {
        _id: '6201ba1ff65f11ad3d78f847',
        DIN: '2212153',
        genericName: 'SUMATRIPTAN SUCCINATE TABLET 5',
        supplierCode: 'GSK',
        brandName: 'IMITREX DF TB 50MG 6',
        form: 'TABLET',
        packSize: '6',
        rebateValue: null,
        packPrice: 105.44,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba28f65f11ad3d79da6d',
        DIN: '2268914',
        genericName: 'SUMATRIPTAN SUCCINATE TABLET 5',
        supplierCode: 'MYL',
        brandName: 'SUMATRIPTAN TB 50MG UD 6 MYLAN',
        form: 'TABLET',
        packSize: '6',
        rebateValue: null,
        packPrice: 54.39,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba28f65f11ad3d79dec1',
        DIN: '2286521',
        genericName: 'SUMATRIPTAN SUCCINATE TABLET 5',
        supplierCode: 'SNS',
        brandName: 'SUMATRIPTAN TB 50MG UD 6 SNS',
        form: 'TABLET',
        packSize: '6',
        rebateValue: null,
        packPrice: 54.39,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '23513',
    genericName: 'DORZOLAMIDE HCL DROPS 2% OPHTH',
    strength: '2%',
    unitPrice: 5.428,
    MFRs: {
      JAP: {
        _id: '6201ba24f65f11ad3d798433',
        DIN: '2453347',
        genericName: 'DORZOLAMIDE HCL DROPS 2% OPHTH',
        supplierCode: 'JAP',
        brandName: 'DORZOLAMIDE SOL 2% 5ML JAMP',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: '30',
        packPrice: 10.54,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d78f3fa',
        DIN: '2316307',
        genericName: 'DORZOLAMIDE HCL DROPS 2% OPHTH',
        supplierCode: 'SDZ',
        brandName: 'DORZOLAMIDE 2% OPHT SOL 5ML SDZ',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: '5',
        packPrice: 10.54,
        dailyAverageDispensed: 0,
      },
      ELV: {
        _id: '6201ba22f65f11ad3d7946bb',
        DIN: '2216205',
        genericName: 'DORZOLAMIDE HCL DROPS 2% OPHTH',
        supplierCode: 'ELV',
        brandName: 'TRUSOPT OPHT SOL 2% 5ML',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: null,
        packPrice: 27.14,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '24153',
    genericName: 'BICALUTAMIDE TABLET 50 MG ORAL',
    strength: '50MG',
    unitPrice: 7.438,
    MFRs: {
      PMS: {
        _id: '6201ba28f65f11ad3d79df8d',
        DIN: '2275589',
        genericName: 'BICALUTAMIDE TABLET 50 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'BICALUTAMIDE TB 50MG BLST 2X15 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 38.07,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba28f65f11ad3d79e001',
        DIN: '2270226',
        genericName: 'BICALUTAMIDE TABLET 50 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'BICALUTAMIDE TB 50MG UD 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 38.07,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba1ff65f11ad3d78f57c',
        DIN: '2296063',
        genericName: 'BICALUTAMIDE TABLET 50 MG ORAL',
        supplierCode: 'APX',
        brandName: 'BICALUTAMIDE TB 50MG BLST 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 38.07,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba27f65f11ad3d79d5a8',
        DIN: '2325985',
        genericName: 'BICALUTAMIDE TABLET 50 MG ORAL',
        supplierCode: 'AHC',
        brandName: 'BICALUTAMIDE USP TB 50MG BLST 30 ACH',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 38.07,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d78f7b1',
        DIN: '2357216',
        genericName: 'BICALUTAMIDE TABLET 50 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'BICALUTAMIDE TB 50MG UD 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 38.07,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba27f65f11ad3d79cfb9',
        DIN: '2184478',
        genericName: 'BICALUTAMIDE TABLET 50 MG ORAL',
        supplierCode: 'AST',
        brandName: 'CASODEX TB 50MG BLS 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 223.14,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '24457',
    genericName: 'IPRATROPIUM BROMIDE SPRAY 21 M',
    strength: '0.03%',
    unitPrice: 0.798,
    MFRs: {
      PMS: {
        _id: '6201ba26f65f11ad3d79beb9',
        DIN: '2239627',
        genericName: 'IPRATROPIUM BROMIDE SPRAY 21 M',
        supplierCode: 'PMS',
        brandName: 'IPRATROPIUM NASAL SP 0.03% 30ML PMS',
        form: 'SPRAY',
        packSize: '30',
        rebateValue: '1',
        packPrice: 23.95,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '24668',
    genericName: 'AMOXICILLIN/POTASSIUM CLAV TAB',
    strength: '875/125MG',
    unitPrice: 2.471,
    MFRs: {
      JAP: {
        _id: '6201ba26f65f11ad3d79b46f',
        DIN: '2508265',
        genericName: 'AMOXICILLIN/POTASSIUM CLAV TAB',
        supplierCode: 'JAP',
        brandName: 'AMOXI CLAV TB 875/125MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '65',
        packPrice: 111.03,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba27f65f11ad3d79c259',
        DIN: '2245623',
        genericName: 'AMOXICILLIN/POTASSIUM CLAV TAB',
        supplierCode: 'APX',
        brandName: 'AMOXI CLAV TB 875/125MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 111.03,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba23f65f11ad3d7970b8',
        DIN: '2482584',
        genericName: 'AMOXICILLIN/POTASSIUM CLAV TAB',
        supplierCode: 'SDZ',
        brandName: 'AMOXI CLAV TB 875/125MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 111.03,
        dailyAverageDispensed: 0,
      },
      GSK: {
        _id: '6201ba1ff65f11ad3d78ffb4',
        DIN: '2238829',
        genericName: 'AMOXICILLIN/POTASSIUM CLAV TAB',
        supplierCode: 'GSK',
        brandName: 'CLAVULIN TB 875MG BLS 5X4',
        form: 'TABLET',
        packSize: '20',
        rebateValue: null,
        packPrice: 49.41,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '25738',
    genericName: 'CABERGOLINE TABLET 0.5 MG ORAL',
    strength: '0.5MG',
    unitPrice: 20.846,
    MFRs: {
      APX: {
        _id: '6201ba22f65f11ad3d794433',
        DIN: '2455897',
        genericName: 'CABERGOLINE TABLET 0.5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'CABERGOLINE TB 0.5MG 8 APO',
        form: 'TABLET',
        packSize: '8',
        rebateValue: '10',
        packPrice: 119.48,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79ce35',
        DIN: '2301407',
        genericName: 'CABERGOLINE TABLET 0.5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'CABERGOLINE TB 0.5MG UD 8 CO',
        form: 'TABLET',
        packSize: '8',
        rebateValue: '1',
        packPrice: 84.95,
        dailyAverageDispensed: 0,
      },
      PLI: {
        _id: '6201ba28f65f11ad3d79ddca',
        DIN: '2242471',
        genericName: 'CABERGOLINE TABLET 0.5 MG ORAL',
        supplierCode: 'PLI',
        brandName: 'DOSTINEX TB 0.5MG 8',
        form: 'TABLET',
        packSize: '8',
        rebateValue: null,
        packPrice: 166.77,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '26177',
    genericName: 'RISPERIDONE SOLUTION 1 MG/ML O',
    strength: '1MG/ML',
    unitPrice: 1.494,
    MFRs: {
      PMS: {
        _id: '6201ba26f65f11ad3d79b73d',
        DIN: '2279266',
        genericName: 'RISPERIDONE SOLUTION 1 MG/ML O',
        supplierCode: 'PMS',
        brandName: 'RISPERIDONE ORAL SOL 1MG/ML 30ML PMS',
        form: 'SOLUTION',
        packSize: '30',
        rebateValue: '50',
        packPrice: 14.41,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba22f65f11ad3d7953df',
        DIN: '2454319',
        genericName: 'RISPERIDONE SOLUTION 1 MG/ML O',
        supplierCode: 'JAP',
        brandName: 'RISPERIDONE 1MG/ML 30ML JAMP',
        form: 'SOLUTION',
        packSize: '30',
        rebateValue: '1',
        packPrice: 14.41,
        dailyAverageDispensed: 0,
      },
      JNO: {
        _id: '6201ba27f65f11ad3d79ca87',
        DIN: '2236950',
        genericName: 'RISPERIDONE SOLUTION 1 MG/ML O',
        supplierCode: 'JNO',
        brandName: 'RISPERDAL SOL 1MG/ML 30ML',
        form: 'SOLUTION',
        packSize: '30',
        rebateValue: null,
        packPrice: 44.82,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '27370',
    genericName: 'LATANOPROST DROPS 0.005% OPHTH',
    strength: '0.01%',
    unitPrice: 13.08,
    MFRs: {
      APX: {
        _id: '6201ba1ff65f11ad3d78fb9b',
        DIN: '2296527',
        genericName: 'LATANOPROST DROPS 0.005% OPHTH',
        supplierCode: 'APX',
        brandName: 'LATANOPROST 50MCG/ML OPHT SOL 2.5ML APO',
        form: 'SOLUTION',
        packSize: '2.5',
        rebateValue: '40',
        packPrice: 9.58,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d79056b',
        DIN: '2367335',
        genericName: 'LATANOPROST DROPS 0.005% OPHTH',
        supplierCode: 'SDZ',
        brandName: 'LATANOPROST OPH SOL 50MCG/ML 2.5ML SDZ',
        form: 'SOLUTION',
        packSize: '2.5',
        rebateValue: '40',
        packPrice: 9.58,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba1ff65f11ad3d78fdad',
        DIN: '2254786',
        genericName: 'LATANOPROST DROPS 0.005% OPHTH',
        supplierCode: 'TEV',
        brandName: 'LATANOPROST OPHT SOL 2.5ML TEVA',
        form: 'SOLUTION',
        packSize: '2.5',
        rebateValue: '1',
        packPrice: 9.58,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba24f65f11ad3d798863',
        DIN: '2453355',
        genericName: 'LATANOPROST DROPS 0.005% OPHTH',
        supplierCode: 'JAP',
        brandName: 'LATANOPROST OPHT SOL 50MCG/ML 2.5ML JAMP',
        form: 'SOLUTION',
        packSize: '2.5',
        rebateValue: '1',
        packPrice: 9.58,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba27f65f11ad3d79d4a1',
        DIN: '2231493',
        genericName: 'LATANOPROST DROPS 0.005% OPHTH',
        supplierCode: 'UPJ',
        brandName: 'XALATAN OPHT SOL 0.005% 2.5ML',
        form: 'SOLUTION',
        packSize: '2.5',
        rebateValue: null,
        packPrice: 32.7,
        dailyAverageDispensed: 0,
      },
      GMP: {
        _id: '6201ba24f65f11ad3d79752f',
        DIN: '2426935',
        genericName: 'LATANOPROST DROPS 0.005% OPHTH',
        supplierCode: 'GMP',
        brandName: 'LATANOPROST 50MCG/ML OPHT SOL 2.5ML MED',
        form: 'SOLUTION',
        packSize: '2.5',
        rebateValue: null,
        packPrice: 10.11,
        dailyAverageDispensed: 0,
      },
      TEL: {
        _id: '6201ba25f65f11ad3d798f62',
        DIN: '2489570',
        genericName: 'LATANOPROST DROPS 0.005% OPHTH',
        supplierCode: 'TEL',
        brandName: 'LATANOPROST OPHTALMIC SOL 50MCG/ML 2.5ML',
        form: 'SOLUTION',
        packSize: '2.5',
        rebateValue: null,
        packPrice: 9.58,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '27882',
    genericName: 'BRIMONIDINE TARTRATE DROPS 0.2',
    strength: '0.20%',
    unitPrice: 4.008,
    MFRs: {
      SDZ: {
        _id: '6201ba27f65f11ad3d79ce43',
        DIN: '2305429',
        genericName: 'BRIMONIDINE TARTRATE DROPS 0.2',
        supplierCode: 'SDZ',
        brandName: 'BRIMONIDINE OPHT SOL 0.2% 5ML SDZ',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: '1',
        packPrice: 5.78,
        dailyAverageDispensed: 0,
      },
      ALL: {
        _id: '6201ba27f65f11ad3d79c7f6',
        DIN: '2236876',
        genericName: 'BRIMONIDINE TARTRATE DROPS 0.2',
        supplierCode: 'ALL',
        brandName: 'ALPHAGAN DROPS 0.2% 5ML',
        form: 'LIQUID',
        packSize: '5',
        rebateValue: null,
        packPrice: 20.04,
        dailyAverageDispensed: 0,
      },
      TEL: {
        _id: '6201ba26f65f11ad3d79add4',
        DIN: '2515377',
        genericName: 'BRIMONIDINE TARTRATE DROPS 0.2',
        supplierCode: 'TEL',
        brandName: 'BRIMONIDINE TARTRATE OPHT 0.2%/W/V 5ML',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: null,
        packPrice: 5.78,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '29927',
    genericName: 'LEVOFLOXACIN TABLET 250 MG ORA',
    strength: '250MG',
    unitPrice: 1.204,
    MFRs: {
      APX: {
        _id: '6201ba28f65f11ad3d79db8d',
        DIN: '2284707',
        genericName: 'LEVOFLOXACIN TABLET 250 MG ORA',
        supplierCode: 'APX',
        brandName: 'LEVOFLOXACIN TB 250MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 120.38,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba27f65f11ad3d79caee',
        DIN: '2298635',
        genericName: 'LEVOFLOXACIN TABLET 250 MG ORA',
        supplierCode: 'SDZ',
        brandName: 'LEVOFLOXACIN TB 250MG 50 SDZ',
        form: 'TABLET',
        packSize: '50',
        rebateValue: '1',
        packPrice: 60.19,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba26f65f11ad3d79b96f',
        DIN: '2315424',
        genericName: 'LEVOFLOXACIN TABLET 250 MG ORA',
        supplierCode: 'TEV',
        brandName: 'LEVOFLOXACIN TB 250MG 50 ACT',
        form: 'TABLET',
        packSize: '50',
        rebateValue: '1',
        packPrice: 60.19,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba26f65f11ad3d79a4d2',
        DIN: '2505797',
        genericName: 'LEVOFLOXACIN TABLET 250 MG ORA',
        supplierCode: 'MPH',
        brandName: 'LEVOFLOXACIN TB 250MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 120.38,
        dailyAverageDispensed: 0,
      },
      RIV: {
        _id: '6201ba24f65f11ad3d798b29',
        DIN: '2492725',
        genericName: 'LEVOFLOXACIN TABLET 250 MG ORA',
        supplierCode: 'RIV',
        brandName: 'LEVOFLOXACIN TB 250MG 100 RIVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 120.38,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '30274',
    genericName: 'TIZANIDINE HCL TABLET 4 MG ORA',
    strength: '4MG',
    unitPrice: 0.87,
    MFRs: {
      AAA: {
        _id: '6201ba21f65f11ad3d792d6a',
        DIN: '2259893',
        genericName: 'TIZANIDINE HCL TABLET 4 MG ORA',
        supplierCode: 'AAA',
        brandName: 'TIZANIDINE TB 4MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 86.96,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '30495',
    genericName: 'CLARITHROMYCIN TAB ER 24H 500',
    strength: '500MG',
    unitPrice: 1.257,
    MFRs: {
      APX: {
        _id: '6201ba20f65f11ad3d791ca0',
        DIN: '2413345',
        genericName: 'CLARITHROMYCIN TAB ER 24H 500',
        supplierCode: 'APX',
        brandName: 'CLARITHROMYCIN XL TB 500MG 100 APO',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '40',
        packPrice: 125.72,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d791f9c',
        DIN: '2403196',
        genericName: 'CLARITHROMYCIN TAB ER 24H 500',
        supplierCode: 'TEV',
        brandName: 'CLARITHROMYCIN XL TB 500MG 60 ACT',
        form: 'TABLET EXT REL',
        packSize: '60',
        rebateValue: '1',
        packPrice: 75.43,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '30607',
    genericName: 'VALACYCLOVIR HCL TABLET 1000 M',
    strength: '1000MG',
    unitPrice: 7.745,
    MFRs: {
      APX: {
        _id: '6201ba1ff65f11ad3d78fc86',
        DIN: '2354705',
        genericName: 'VALACYCLOVIR HCL TABLET 1000 M',
        supplierCode: 'APX',
        brandName: 'VALACYCLOVIR TB 1000MG BLST 21 APO',
        form: 'TABLET',
        packSize: '21',
        rebateValue: '50',
        packPrice: 122.93,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d7900ab',
        DIN: '2381230',
        genericName: 'VALACYCLOVIR HCL TABLET 1000 M',
        supplierCode: 'PMS',
        brandName: 'VALACYCLOVIR CPLT 1000MG UD 21 PMS',
        form: 'CAPLET',
        packSize: '21',
        rebateValue: '40',
        packPrice: 122.93,
        dailyAverageDispensed: 0,
      },
      GSK: {
        _id: '6201ba27f65f11ad3d79d7db',
        DIN: '2246559',
        genericName: 'VALACYCLOVIR HCL TABLET 1000 M',
        supplierCode: 'GSK',
        brandName: 'VALTREX CPLT 1000MG BLIS 7X3',
        form: 'TABLET',
        packSize: '21',
        rebateValue: null,
        packPrice: 162.65,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba28f65f11ad3d79e0aa',
        DIN: '2351560',
        genericName: 'VALACYCLOVIR HCL TABLET 1000 M',
        supplierCode: 'MYL',
        brandName: 'VALACYCLOVIR CPLT 1000MG 100 MYLAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 585.37,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba25f65f11ad3d799873',
        DIN: '2405059',
        genericName: 'VALACYCLOVIR HCL TABLET 1000 M',
        supplierCode: 'AUP',
        brandName: 'VALACYCLOVIR TB 1000MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 585.37,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '30796',
    genericName: 'OLOPATADINE HCL DROPS 0.10% OP',
    strength: '0.10%',
    unitPrice: 6.546,
    MFRs: {
      JAP: {
        _id: '6201ba22f65f11ad3d794ef1',
        DIN: '2458411',
        genericName: 'OLOPATADINE HCL DROPS 0.10% OP',
        supplierCode: 'JAP',
        brandName: 'OLOPATADINE OPHT SOL 0.1% 5ML JAMP',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: '65',
        packPrice: 26.13,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba21f65f11ad3d792327',
        DIN: '2422727',
        genericName: 'OLOPATADINE HCL DROPS 0.10% OP',
        supplierCode: 'MPH',
        brandName: 'OLOPATADINE OPHT SOL 0.1% 5ML MINT',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: '60',
        packPrice: 26.13,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba20f65f11ad3d790f04',
        DIN: '2305054',
        genericName: 'OLOPATADINE HCL DROPS 0.10% OP',
        supplierCode: 'APX',
        brandName: 'OLOPATADINE OPHT SOL 0.1% 5ML APO',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: '50',
        packPrice: 26.13,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba20f65f11ad3d790f09',
        DIN: '2358913',
        genericName: 'OLOPATADINE HCL DROPS 0.10% OP',
        supplierCode: 'SDZ',
        brandName: 'OLOPATADINE OPHT SOL 0.1% 5ML SDZ',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: '45',
        packPrice: 26.13,
        dailyAverageDispensed: 0,
      },
      NPC: {
        _id: '6201ba27f65f11ad3d79ca29',
        DIN: '2233143',
        genericName: 'OLOPATADINE HCL DROPS 0.10% OP',
        supplierCode: 'NPC',
        brandName: 'PATANOL 0.1% 5ML',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: null,
        packPrice: 32.73,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '30986',
    genericName: 'LEVONORGESTREL-ETH ESTRA TABLE',
    strength: '0.1-0.02',
    unitPrice: 0.792,
    MFRs: {
      APX: {
        _id: '6201ba20f65f11ad3d790774',
        DIN: '2387875',
        genericName: 'LEVONORGESTREL-ETH ESTRA TABLE',
        supplierCode: 'APX',
        brandName: 'ALYSENA TB 0.1MG/0.02MG UD 21',
        form: 'TABLET',
        packSize: '21',
        rebateValue: '20',
        packPrice: 9.74,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba20f65f11ad3d790c29',
        DIN: '2298538',
        genericName: 'LEVONORGESTREL-ETH ESTRA TABLE',
        supplierCode: 'TEV',
        brandName: 'AVIANE 0.1MG/0.02MG TB BLS 21 TEVA',
        form: 'TABLET',
        packSize: '21',
        rebateValue: '1',
        packPrice: 9.74,
        dailyAverageDispensed: 0,
      },
      PFC: {
        _id: '6201ba27f65f11ad3d79cc87',
        DIN: '2236974',
        genericName: 'LEVONORGESTREL-ETH ESTRA TABLE',
        supplierCode: 'PFC',
        brandName: 'ALESSE COMPACT TB 21',
        form: 'TABLET',
        packSize: '21',
        rebateValue: null,
        packPrice: 16.64,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '31186',
    genericName: 'MOMETASONE FUROATE SPRAY/PUMP',
    strength: '50MCG/ACT',
    unitPrice: 0.227,
    MFRs: {
      APX: {
        _id: '6201ba20f65f11ad3d790bb5',
        DIN: '2403587',
        genericName: 'MOMETASONE FUROATE SPRAY/PUMP',
        supplierCode: 'APX',
        brandName: 'MOMETASONE NASAL SP 50MCG 140DS APO',
        form: 'SPRAY',
        packSize: '140',
        rebateValue: '50',
        packPrice: 21.69,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba21f65f11ad3d793653',
        DIN: '2449811',
        genericName: 'MOMETASONE FUROATE SPRAY/PUMP',
        supplierCode: 'SDZ',
        brandName: 'MOMETASONE 50MCG 140DS SDZ',
        form: 'SPRAY',
        packSize: '140',
        rebateValue: '1',
        packPrice: 21.69,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba23f65f11ad3d79687e',
        DIN: '2475863',
        genericName: 'MOMETASONE FUROATE SPRAY/PUMP',
        supplierCode: 'TEV',
        brandName: 'MOMETASONE NASAL SP 50MCG 140DS TEVA',
        form: 'SPRAY',
        packSize: '140',
        rebateValue: '1',
        packPrice: 21.69,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba21f65f11ad3d792666',
        DIN: '2238465',
        genericName: 'MOMETASONE FUROATE SPRAY/PUMP',
        supplierCode: 'ORN',
        brandName: 'NASONEX NASAL SP 50MCG ODOUR FREE PUMP 140DS',
        form: 'MET DOSE PUMP',
        packSize: '140',
        rebateValue: null,
        packPrice: 31.75,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '33530',
    genericName: 'OMEPRAZOLE CAPSULE DR 20 MG OR',
    strength: '20MG',
    unitPrice: 0.229,
    MFRs: {
      APX: {
        _id: '6201ba21f65f11ad3d792317',
        DIN: '2435683',
        genericName: 'OMEPRAZOLE CAPSULE DR 20 MG OR',
        supplierCode: 'APX',
        brandName: 'HEARTBURN CONTROL CAPS 20MG BLST 14 APC',
        form: 'CAPSULE',
        packSize: '14',
        rebateValue: '70',
        packPrice: 10,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba26f65f11ad3d79b17c',
        DIN: '2296446',
        genericName: 'OMEPRAZOLE CAPSULE DR 20 MG OR',
        supplierCode: 'SDZ',
        brandName: 'OMEPRAZOLE CAPS 20MG 100 SDZ',
        form: 'CAPS DEL REL',
        packSize: '100',
        rebateValue: '0',
        packPrice: 22.87,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba22f65f11ad3d793fe9',
        DIN: '2348691',
        genericName: 'OMEPRAZOLE CAPSULE DR 20 MG OR',
        supplierCode: 'SNS',
        brandName: 'OMEPRAZOLE CAPS 20MG 500 SNS',
        form: 'CAPS DEL REL',
        packSize: '500',
        rebateValue: null,
        packPrice: 114.35,
        dailyAverageDispensed: 0,
      },
      XPT: {
        _id: '6201ba26f65f11ad3d79add1',
        DIN: '846503',
        genericName: 'OMEPRAZOLE CAPSULE DR 20 MG OR',
        supplierCode: 'XPT',
        brandName: 'LOSEC CAPS 20MG BTL 30',
        form: 'CAPS DEL REL',
        packSize: '30',
        rebateValue: null,
        packPrice: 35.83,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '34188',
    genericName: 'QUETIAPINE FUMARATE TABLET 100',
    strength: '100MG',
    unitPrice: 1.462,
    MFRs: {
      JAP: {
        _id: '6201ba27f65f11ad3d79c531',
        DIN: '2330423',
        genericName: 'QUETIAPINE FUMARATE TABLET 100',
        supplierCode: 'JAP',
        brandName: 'QUETIAPINE TB 100MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '70',
        packPrice: 13.18,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba25f65f11ad3d79979e',
        DIN: '2387808',
        genericName: 'QUETIAPINE FUMARATE TABLET 100',
        supplierCode: 'AHC',
        brandName: 'QUETIAPINE TB 100MG 100 ACH',
        form: 'TAB IR',
        packSize: '100',
        rebateValue: '66',
        packPrice: 13.18,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba27f65f11ad3d79c650',
        DIN: '2316099',
        genericName: 'QUETIAPINE FUMARATE TABLET 100',
        supplierCode: 'TEV',
        brandName: 'QUETIAPINE TB 100MG 100 ACT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 13.18,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba21f65f11ad3d792623',
        DIN: '2438011',
        genericName: 'QUETIAPINE FUMARATE TABLET 100',
        supplierCode: 'MPH',
        brandName: 'QUETIAPINE TB 100MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 13.18,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79a91c',
        DIN: '2501643',
        genericName: 'QUETIAPINE FUMARATE TABLET 100',
        supplierCode: 'APX',
        brandName: 'QUETIAPINE FUMARATE TB 100MG 100 APO',
        form: 'TAB IR',
        packSize: '100',
        rebateValue: '50',
        packPrice: 13.18,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba27f65f11ad3d79c94f',
        DIN: '2296578',
        genericName: 'QUETIAPINE FUMARATE TABLET 100',
        supplierCode: 'PMS',
        brandName: 'QUETIAPINE TB 100MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '42',
        packPrice: 13.18,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba27f65f11ad3d79d98e',
        DIN: '2236952',
        genericName: 'QUETIAPINE FUMARATE TABLET 100',
        supplierCode: 'AST',
        brandName: 'SEROQUEL TB 100MG 100',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 146.23,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba1ff65f11ad3d78f3d8',
        DIN: '2353172',
        genericName: 'QUETIAPINE FUMARATE TABLET 100',
        supplierCode: 'SNS',
        brandName: 'QUETIAPINE TB 100MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 13.18,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba20f65f11ad3d790bde',
        DIN: '2390213',
        genericName: 'QUETIAPINE FUMARATE TABLET 100',
        supplierCode: 'AUP',
        brandName: 'QUETIAPINE TB 100MG 100 AURO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 13.18,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '37020',
    genericName: 'CICLOPIROX SOLUTION 8% TOPICAL',
    strength: '8%',
    unitPrice: 7.743,
    MFRs: {
      APX: {
        _id: '6201ba27f65f11ad3d79cafc',
        DIN: '2298953',
        genericName: 'CICLOPIROX SOLUTION 8% TOPICAL',
        supplierCode: 'APX',
        brandName: 'CICLOPIROX 8% NAIL LACQUER 6G APO',
        form: 'SOLUTION',
        packSize: '6',
        rebateValue: '20',
        packPrice: 46.46,
        dailyAverageDispensed: 0,
      },
      TAR: {
        _id: '6201ba1ff65f11ad3d78fb26',
        DIN: '2353288',
        genericName: 'CICLOPIROX SOLUTION 8% TOPICAL',
        supplierCode: 'TAR',
        brandName: 'CICLOPIROX ANTIFUNGAL NAIL LACQUER 8% 6G TARO',
        form: 'SOLUTION',
        packSize: '6',
        rebateValue: '1',
        packPrice: 46.46,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '37219',
    genericName: 'LANSOPRAZL/AMOX TR/CLARITH COM',
    strength: '30MG 500MG',
    unitPrice: 1.213,
    MFRs: {
      APX: {
        _id: '6201ba23f65f11ad3d7960be',
        DIN: '2470780',
        genericName: 'LANSOPRAZL/AMOX TR/CLARITH COM',
        supplierCode: 'APX',
        brandName: 'LANSOPR-AMOXIC-CLARITH 30MG 500MG 500MG BLS 56 APO',
        form: 'TABLET',
        packSize: '56',
        rebateValue: '70',
        packPrice: 67.91,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '38588',
    genericName: 'TOBRAMYCIN DROPS 0.30% OPHTHAL',
    strength: '0.30%',
    unitPrice: 1.362,
    MFRs: {
      SDZ: {
        _id: '6201ba20f65f11ad3d790cb6',
        DIN: '2241755',
        genericName: 'TOBRAMYCIN DROPS 0.30% OPHTHAL',
        supplierCode: 'SDZ',
        brandName: 'TOBRAMYCIN OPHT SOL 0.3% 5ML SDZ',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: '1',
        packPrice: 6.81,
        dailyAverageDispensed: 0,
      },
      NPC: {
        _id: '6201ba27f65f11ad3d79d926',
        DIN: '513962',
        genericName: 'TOBRAMYCIN DROPS 0.30% OPHTHAL',
        supplierCode: 'NPC',
        brandName: 'TOBREX OPHT SOL 5ML',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: null,
        packPrice: 10.24,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '39190',
    genericName: 'SILDENAFIL CITRATE TABLET 50 M',
    strength: '50MG',
    unitPrice: 9.335,
    MFRs: {
      TEV: {
        _id: '6201ba20f65f11ad3d790709',
        DIN: '2308746',
        genericName: 'SILDENAFIL CITRATE TABLET 50 M',
        supplierCode: 'TEV',
        brandName: 'SILDENAFIL TB 50MG BLS 4 NOVO',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '80',
        packPrice: 35.39,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba20f65f11ad3d79109a',
        DIN: '2317575',
        genericName: 'SILDENAFIL CITRATE TABLET 50 M',
        supplierCode: 'PMS',
        brandName: 'SILDENAFIL TB 50MG BLST 4',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '80',
        packPrice: 35.39,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba20f65f11ad3d790a1f',
        DIN: '2393077',
        genericName: 'SILDENAFIL CITRATE TABLET 50 M',
        supplierCode: 'MPH',
        brandName: 'SILDENAFIL TB 50MG UD 4 MINT',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '75',
        packPrice: 35.39,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba20f65f11ad3d791203',
        DIN: '2405679',
        genericName: 'SILDENAFIL CITRATE TABLET 50 M',
        supplierCode: 'JAP',
        brandName: 'SILDENAFIL TB 50MG UD 4 JAMP',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '75',
        packPrice: 35.39,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba20f65f11ad3d79073c',
        DIN: '2248202',
        genericName: 'SILDENAFIL CITRATE TABLET 50 M',
        supplierCode: 'APX',
        brandName: 'SILDENAFIL TB 50MG UD 4 APO',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '71',
        packPrice: 35.39,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba1ff65f11ad3d78f967',
        DIN: '2239767',
        genericName: 'SILDENAFIL CITRATE TABLET 50 M',
        supplierCode: 'UPJ',
        brandName: 'VIAGRA TB 50MG 1X4',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 37.34,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d790fbc',
        DIN: '2406152',
        genericName: 'SILDENAFIL CITRATE TABLET 50 M',
        supplierCode: 'SNS',
        brandName: 'SILDENAFIL TB 50MG UD 4 SNS',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 35.39,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba24f65f11ad3d79766d',
        DIN: '2414376',
        genericName: 'SILDENAFIL CITRATE TABLET 50 M',
        supplierCode: 'AUP',
        brandName: 'SILDENAFIL TB 50MG UD BLST 4 AURO',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 35.39,
        dailyAverageDispensed: 0,
      },
      ACE: {
        _id: '6201ba24f65f11ad3d797bee',
        DIN: '2479818',
        genericName: 'SILDENAFIL CITRATE TABLET 50 M',
        supplierCode: 'ACE',
        brandName: 'SILDENAFIL TB 50MG BLST 4 ACCEL',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 13.6,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '39191',
    genericName: 'SILDENAFIL CITRATE TABLET 100',
    strength: '100MG',
    unitPrice: 9.708,
    MFRs: {
      TEV: {
        _id: '6201ba20f65f11ad3d79070b',
        DIN: '2308754',
        genericName: 'SILDENAFIL CITRATE TABLET 100',
        supplierCode: 'TEV',
        brandName: 'SILDENAFIL TB 100MG UD 4 NOVO',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '80',
        packPrice: 36.8,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba20f65f11ad3d79109c',
        DIN: '2317583',
        genericName: 'SILDENAFIL CITRATE TABLET 100',
        supplierCode: 'PMS',
        brandName: 'SILDENAFIL TB 100MG BLST 4',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '80',
        packPrice: 36.8,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba22f65f11ad3d793e02',
        DIN: '2402548',
        genericName: 'SILDENAFIL CITRATE TABLET 100',
        supplierCode: 'MRC',
        brandName: 'SILDENAFIL TB 100MG BLS 4 MAR',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '75',
        packPrice: 36.8,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba25f65f11ad3d799cf0',
        DIN: '2503506',
        genericName: 'SILDENAFIL CITRATE TABLET 100',
        supplierCode: 'JAP',
        brandName: 'SILDENAFIL TB 100MG BLST 4 JAMP',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '75',
        packPrice: 36.8,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba20f65f11ad3d79073e',
        DIN: '2248203',
        genericName: 'SILDENAFIL CITRATE TABLET 100',
        supplierCode: 'APX',
        brandName: 'SILDENAFIL TB 100MG UD 4 APO',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '71',
        packPrice: 36.8,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba26f65f11ad3d79ae9c',
        DIN: '2393085',
        genericName: 'SILDENAFIL CITRATE TABLET 100',
        supplierCode: 'MPH',
        brandName: 'SILDENAFIL TB 100MG UD BLST 4 MINT',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '60',
        packPrice: 36.8,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba1ff65f11ad3d78f98b',
        DIN: '2239768',
        genericName: 'SILDENAFIL CITRATE TABLET 100',
        supplierCode: 'UPJ',
        brandName: 'VIAGRA TB 100MG 1X4',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 38.83,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d790fbb',
        DIN: '2406160',
        genericName: 'SILDENAFIL CITRATE TABLET 100',
        supplierCode: 'SNS',
        brandName: 'SILDENAFIL TB 100MG UD 4 SNS',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 36.8,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba24f65f11ad3d797656',
        DIN: '2414384',
        genericName: 'SILDENAFIL CITRATE TABLET 100',
        supplierCode: 'AUP',
        brandName: 'SILDENAFIL TB 100MG UD 4 AURO',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 36.8,
        dailyAverageDispensed: 0,
      },
      MTP: {
        _id: '6201ba26f65f11ad3d79a9af',
        DIN: '2430037',
        genericName: 'SILDENAFIL CITRATE TABLET 100',
        supplierCode: 'MTP',
        brandName: 'M-SILDENAFIL TB 100MG UD BLST 4',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 38.83,
        dailyAverageDispensed: 0,
      },
      ACE: {
        _id: '6201ba24f65f11ad3d797bed',
        DIN: '2479826',
        genericName: 'SILDENAFIL CITRATE TABLET 100',
        supplierCode: 'ACE',
        brandName: 'SILDENAFIL TB 100MG BLST 4 ACCEL',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 14.14,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '40221',
    genericName: 'RIZATRIPTAN BENZOATE TABLET 5',
    strength: '5MG',
    unitPrice: 12.597,
    MFRs: {
      APX: {
        _id: '6201ba20f65f11ad3d790afe',
        DIN: '2393468',
        genericName: 'RIZATRIPTAN BENZOATE TABLET 5',
        supplierCode: 'APX',
        brandName: 'RIZATRIPTAN TB 5MG UD 6 APO',
        form: 'TABLET',
        packSize: '6',
        rebateValue: '60',
        packPrice: 75.58,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba22f65f11ad3d79447b',
        DIN: '2429233',
        genericName: 'RIZATRIPTAN BENZOATE TABLET 5',
        supplierCode: 'JAP',
        brandName: 'RIZATRIPTAN IR TB 5MG BLST 6 JAMP',
        form: 'TABLET',
        packSize: '6',
        rebateValue: '25',
        packPrice: 66.69,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '40223',
    genericName: 'RIZATRIPTAN BENZOATE TAB RAPDI',
    strength: '5MG',
    unitPrice: 20.979,
    MFRs: {
      PMS: {
        _id: '6201ba1ff65f11ad3d790691',
        DIN: '2393360',
        genericName: 'RIZATRIPTAN BENZOATE TAB RAPDI',
        supplierCode: 'PMS',
        brandName: 'RIZATRIPTAN RDT TB 5MG BLS 6 PMS',
        form: 'TAB ORAL DISINT',
        packSize: '6',
        rebateValue: '70',
        packPrice: 66.69,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba23f65f11ad3d79569f',
        DIN: '2465086',
        genericName: 'RIZATRIPTAN BENZOATE TAB RAPDI',
        supplierCode: 'JAP',
        brandName: 'RIZATRIPTAN ODT TB 5MG BLST 6 JAMP',
        form: 'TAB ORAL DISINT',
        packSize: '6',
        rebateValue: '65',
        packPrice: 66.69,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba23f65f11ad3d79558d',
        DIN: '2462788',
        genericName: 'RIZATRIPTAN BENZOATE TAB RAPDI',
        supplierCode: 'MRC',
        brandName: 'RIZATRIPTAN ODT TB 5MG BLST 6 MAR',
        form: 'TAB ORAL DISINT',
        packSize: '6',
        rebateValue: '63',
        packPrice: 66.69,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba1ff65f11ad3d78ff7e',
        DIN: '2351870',
        genericName: 'RIZATRIPTAN BENZOATE TAB RAPDI',
        supplierCode: 'SDZ',
        brandName: 'RIZATRIPTAN ODT TB 5MG BLS 1X6 SDZ',
        form: 'TAB ORAL DISINT',
        packSize: '6',
        rebateValue: '50',
        packPrice: 66.69,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d79213f',
        DIN: '2396661',
        genericName: 'RIZATRIPTAN BENZOATE TAB RAPDI',
        supplierCode: 'TEV',
        brandName: 'RIZATRIPTAN ODT TB 5MG BLS 6 TEVA',
        form: 'TAB ORAL DISINT',
        packSize: '6',
        rebateValue: '1',
        packPrice: 66.69,
        dailyAverageDispensed: 0,
      },
      ORN: {
        _id: '6201ba1ff65f11ad3d78f561',
        DIN: '2240518',
        genericName: 'RIZATRIPTAN BENZOATE TAB RAPDI',
        supplierCode: 'ORN',
        brandName: 'MAXALT RPD 5MG WAFERS BLST 12',
        form: 'TAB ORAL DISINT',
        packSize: '12',
        rebateValue: null,
        packPrice: 251.75,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba1ff65f11ad3d79021b',
        DIN: '2379198',
        genericName: 'RIZATRIPTAN BENZOATE TAB RAPDI',
        supplierCode: 'MYL',
        brandName: 'RIZATRIPTAN ODT TB 5MG UD 6 MYLAN',
        form: 'TAB ORAL DISINT',
        packSize: '6',
        rebateValue: null,
        packPrice: 66.69,
        dailyAverageDispensed: 0,
      },
      NTC: {
        _id: '6201ba25f65f11ad3d799158',
        DIN: '2436604',
        genericName: 'RIZATRIPTAN BENZOATE TAB RAPDI',
        supplierCode: 'NTC',
        brandName: 'RIZATRIPTAN ODT TB 5MG BLST 1X6 NAT',
        form: 'TAB ORAL DISINT',
        packSize: '6',
        rebateValue: null,
        packPrice: 66.99,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba21f65f11ad3d792e78',
        DIN: '2442906',
        genericName: 'RIZATRIPTAN BENZOATE TAB RAPDI',
        supplierCode: 'SNS',
        brandName: 'RIZATRIPTAN RPD TB 5MG 6 SNS',
        form: 'TAB ORAL DISINT',
        packSize: '6',
        rebateValue: null,
        packPrice: 66.69,
        dailyAverageDispensed: 0,
      },
      ACE: {
        _id: '6201ba24f65f11ad3d798133',
        DIN: '2483270',
        genericName: 'RIZATRIPTAN BENZOATE TAB RAPDI',
        supplierCode: 'ACE',
        brandName: 'RIZATRIPTAN TB ODT 5MG BLST 6 ACCEL',
        form: 'TAB ORAL DISINT',
        packSize: '6',
        rebateValue: null,
        packPrice: 46.68,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '41285',
    genericName: 'CELECOXIB CAPSULE 100 MG ORAL',
    strength: '100MG',
    unitPrice: 0.765,
    MFRs: {
      PMS: {
        _id: '6201ba26f65f11ad3d79b103',
        DIN: '2517116',
        genericName: 'CELECOXIB CAPSULE 100 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'CELECOXIB CAPS 100MG 100 PMSC',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '67',
        packPrice: 12.79,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba21f65f11ad3d791efd',
        DIN: '2420058',
        genericName: 'CELECOXIB CAPSULE 100 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'CELECOXIB CAPS 100MG 100 MAR',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '52',
        packPrice: 12.79,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba20f65f11ad3d791eb0',
        DIN: '2418932',
        genericName: 'CELECOXIB CAPSULE 100 MG ORAL',
        supplierCode: 'APX',
        brandName: 'CELECOXIB CAPS 100MG 100 APO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '50',
        packPrice: 12.79,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d791f4e',
        DIN: '2424533',
        genericName: 'CELECOXIB CAPSULE 100 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'CELECOXIB CAPS 100MG 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '50',
        packPrice: 12.79,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba21f65f11ad3d791f16',
        DIN: '2412497',
        genericName: 'CELECOXIB CAPSULE 100 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'CELECOXIB CAPS 100MG 100 MINT',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '45',
        packPrice: 12.79,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba26f65f11ad3d79bb29',
        DIN: '2239941',
        genericName: 'CELECOXIB CAPSULE 100 MG ORAL',
        supplierCode: 'UPJ',
        brandName: 'CELEBREX CAPS 100MG 100',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 76.46,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba25f65f11ad3d799b94',
        DIN: '2436299',
        genericName: 'CELECOXIB CAPSULE 100 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'CELECOXIB CAPS 100MG 100 SNS',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 12.79,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d793984',
        DIN: '2445670',
        genericName: 'CELECOXIB CAPSULE 100 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'CELECOXIB CAPS 100MG 100 AURO',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: null,
        packPrice: 12.79,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '42923',
    genericName: 'RISPERIDONE TABLET 0.5 MG ORAL',
    strength: '0.5MG',
    unitPrice: 0.174,
    MFRs: {
      PMS: {
        _id: '6201ba24f65f11ad3d798a4c',
        DIN: '2252015',
        genericName: 'RISPERIDONE TABLET 0.5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'RISPERIDONE TB 0.5MG 100 PMS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '72',
        packPrice: 17.35,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba26f65f11ad3d79b6df',
        DIN: '2282127',
        genericName: 'RISPERIDONE TABLET 0.5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'RISPERIDONE TB 0.5MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '64',
        packPrice: 17.35,
        dailyAverageDispensed: 0,
      },
      RAN: {
        _id: '6201ba1ff65f11ad3d78f594',
        DIN: '2328313',
        genericName: 'RISPERIDONE TABLET 0.5 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'RISPERIDONE TB 0.5MG 100 RAN',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '62',
        packPrice: 17.35,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba22f65f11ad3d7953ff',
        DIN: '2303663',
        genericName: 'RISPERIDONE TABLET 0.5 MG ORAL',
        supplierCode: 'SDZ',
        brandName: 'RISPERIDONE TB 0.5MG 100 SDZ',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 17.35,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba20f65f11ad3d790b73',
        DIN: '2371774',
        genericName: 'RISPERIDONE TABLET 0.5 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'RISPERIDONE TB 0.5MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '60',
        packPrice: 17.35,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba1ff65f11ad3d78f83b',
        DIN: '2359804',
        genericName: 'RISPERIDONE TABLET 0.5 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'RISPERIDONE TB 0.5MG 100 MINT',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '55',
        packPrice: 17.35,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba1ff65f11ad3d78f973',
        DIN: '2359537',
        genericName: 'RISPERIDONE TABLET 0.5 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'RISPERIDONE TB 0.5MG 100 JAMP',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 17.35,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba26f65f11ad3d79b767',
        DIN: '2264188',
        genericName: 'RISPERIDONE TABLET 0.5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'RISPERIDONE TB 0.5MG 100 TEVA',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 17.35,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d79167c',
        DIN: '2356899',
        genericName: 'RISPERIDONE TABLET 0.5 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'RISPERIDONE TB 0.5MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 17.35,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '43367',
    genericName: 'FLUTICASONE/SALMETEROL DISK W/',
    strength: '50/250MCG',
    unitPrice: 1.921,
    MFRs: {
      PMS: {
        _id: '6201ba24f65f11ad3d798842',
        DIN: '2494515',
        genericName: 'FLUTICASONE/SALMETEROL DISK W/',
        supplierCode: 'PMS',
        brandName: 'FLUTICASONE SALMETEROL DPI 250 250/50MCG 60DS PMS',
        form: 'POWDER',
        packSize: '60',
        rebateValue: '1',
        packPrice: 50.76,
        dailyAverageDispensed: 0,
      },
      GSK: {
        _id: '6201ba27f65f11ad3d79c97e',
        DIN: '2240836',
        genericName: 'FLUTICASONE/SALMETEROL DISK W/',
        supplierCode: 'GSK',
        brandName: 'ADVAIR DISKUS 50/250MCG INHALER 60DS',
        form: 'POWDER',
        packSize: '60',
        rebateValue: null,
        packPrice: 115.28,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba24f65f11ad3d79888d',
        DIN: '2495600',
        genericName: 'FLUTICASONE/SALMETEROL DISK W/',
        supplierCode: 'MYL',
        brandName: 'WIXELA 250MCG/50MCG INHALER 60DS MYLAN',
        form: 'POWDER',
        packSize: '60',
        rebateValue: null,
        packPrice: 50.76,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '43879',
    genericName: 'MOXIFLOXACIN HCL TABLET 400 MG',
    strength: '400MG',
    unitPrice: 1.523,
    MFRs: {
      JAP: {
        _id: '6201ba21f65f11ad3d792ba9',
        DIN: '2443929',
        genericName: 'MOXIFLOXACIN HCL TABLET 400 MG',
        supplierCode: 'JAP',
        brandName: 'MOXIFLOXACIN TB 400MG 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 45.69,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba21f65f11ad3d792dcb',
        DIN: '2447053',
        genericName: 'MOXIFLOXACIN HCL TABLET 400 MG',
        supplierCode: 'MRC',
        brandName: 'MOXIFLOXACIN TB 400MG 100 MAR',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '50',
        packPrice: 152.3,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba22f65f11ad3d79524f',
        DIN: '2383381',
        genericName: 'MOXIFLOXACIN HCL TABLET 400 MG',
        supplierCode: 'SDZ',
        brandName: 'MOXIFLOXACIN TB 400MG 30 SDZ',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '45',
        packPrice: 45.69,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba25f65f11ad3d799071',
        DIN: '2404923',
        genericName: 'MOXIFLOXACIN HCL TABLET 400 MG',
        supplierCode: 'APX',
        brandName: 'MOXIFLOXACIN TB 400MG 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '40',
        packPrice: 45.69,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d792b1a',
        DIN: '2375702',
        genericName: 'MOXIFLOXACIN HCL TABLET 400 MG',
        supplierCode: 'TEV',
        brandName: 'MOXIFLOXACIN TB 400MG 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 45.69,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d792b57',
        DIN: '2432242',
        genericName: 'MOXIFLOXACIN HCL TABLET 400 MG',
        supplierCode: 'AUP',
        brandName: 'MOXIFLOXACIN TB 400MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 45.69,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '46120',
    genericName: 'CLOMIPRAMINE HCL CAPSULE 25 MG',
    strength: '25MG',
    unitPrice: 0.342,
    MFRs: {
      TAR: {
        _id: '6201ba25f65f11ad3d798d50',
        DIN: '2497506',
        genericName: 'CLOMIPRAMINE HCL CAPSULE 25 MG',
        supplierCode: 'TAR',
        brandName: 'CLOMIPRAMINE CAPS 25MG 90 TARO',
        form: 'CAPSULE',
        packSize: '90',
        rebateValue: '1',
        packPrice: 30.75,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '47612',
    genericName: 'TRAVOPROST DROPS 0.00% OPHTHAL',
    strength: '0.00%',
    unitPrice: 12.442,
    MFRs: {
      SDZ: {
        _id: '6201ba20f65f11ad3d791afa',
        DIN: '2413167',
        genericName: 'TRAVOPROST DROPS 0.00% OPHTHAL',
        supplierCode: 'SDZ',
        brandName: 'TRAVOPROST OPHT SOL 0.004% 5ML SDZ',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: '35',
        packPrice: 28.76,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba20f65f11ad3d791aeb',
        DIN: '2415739',
        genericName: 'TRAVOPROST DROPS 0.00% OPHTHAL',
        supplierCode: 'APX',
        brandName: 'TRAVOPROST Z OPHT SOL 0.004% 5ML APO',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: '10',
        packPrice: 28.76,
        dailyAverageDispensed: 0,
      },
      NPC: {
        _id: '6201ba27f65f11ad3d79d7f9',
        DIN: '2318008',
        genericName: 'TRAVOPROST DROPS 0.00% OPHTHAL',
        supplierCode: 'NPC',
        brandName: 'TRAVATAN Z OPHT SOL 0.004% 5ML',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: null,
        packPrice: 62.21,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '48354',
    genericName: 'TIMOLOL MALEATE/LATANOPROST DR',
    strength: '50MCG/5MG',
    unitPrice: 14.804,
    MFRs: {
      TEV: {
        _id: '6201ba21f65f11ad3d792970',
        DIN: '2436256',
        genericName: 'TIMOLOL MALEATE/LATANOPROST DR',
        supplierCode: 'TEV',
        brandName: 'LATANOPROST/TIMOLOL OPHT 50MCG/5MG/ML 2.5ML ACT',
        form: 'SOLUTION',
        packSize: '2.5',
        rebateValue: '1',
        packPrice: 11.07,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba24f65f11ad3d79873a',
        DIN: '2453770',
        genericName: 'TIMOLOL MALEATE/LATANOPROST DR',
        supplierCode: 'JAP',
        brandName: 'LATANOPROST/TIMOLOL OPHT 50MCG-5MG/ML 2.5ML JAMP',
        form: 'SOLUTION',
        packSize: '2.5',
        rebateValue: '1',
        packPrice: 11.07,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba20f65f11ad3d790fa1',
        DIN: '2246619',
        genericName: 'TIMOLOL MALEATE/LATANOPROST DR',
        supplierCode: 'UPJ',
        brandName: 'XALACOM OPHT 50MCG/5MG 2.5ML',
        form: 'SOLUTION',
        packSize: '2.5',
        rebateValue: null,
        packPrice: 37.01,
        dailyAverageDispensed: 0,
      },
      TEL: {
        _id: '6201ba25f65f11ad3d798ff1',
        DIN: '2489368',
        genericName: 'TIMOLOL MALEATE/LATANOPROST DR',
        supplierCode: 'TEL',
        brandName: 'LATANOPROST/TIMOLOL OPHT 50MCG-5MG/ML 2.5ML',
        form: 'SOLUTION',
        packSize: '2.5',
        rebateValue: null,
        packPrice: 11.07,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '48986',
    genericName: 'FROVATRIPTAN SUCCINATE TABLET',
    strength: '2.5MG',
    unitPrice: 16.146,
    MFRs: {
      JAP: {
        _id: '6201ba25f65f11ad3d799ca5',
        DIN: '2497131',
        genericName: 'FROVATRIPTAN SUCCINATE TABLET',
        supplierCode: 'JAP',
        brandName: 'FROVATRIPTAN TB 2.5MG BLST 6 JAMP',
        form: 'TABLET',
        packSize: '6',
        rebateValue: '35',
        packPrice: 75.02,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba20f65f11ad3d791ab7',
        DIN: '2426471',
        genericName: 'FROVATRIPTAN SUCCINATE TABLET',
        supplierCode: 'APX',
        brandName: 'FROVATRIPTAN TB 2.5MG UD 6 APO',
        form: 'TABLET',
        packSize: '6',
        rebateValue: '20',
        packPrice: 75.02,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d79202a',
        DIN: '2415844',
        genericName: 'FROVATRIPTAN SUCCINATE TABLET',
        supplierCode: 'TEV',
        brandName: 'FROVATRIPTAN TB 2.5MG UD 7 TEVA',
        form: 'TABLET',
        packSize: '7',
        rebateValue: '1',
        packPrice: 87.52,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba26f65f11ad3d79a975',
        DIN: '2509687',
        genericName: 'FROVATRIPTAN SUCCINATE TABLET',
        supplierCode: 'MPH',
        brandName: 'FROVATRIPTAN TB 2.5MG UD BLST 7 MINT',
        form: 'TABLET',
        packSize: '7',
        rebateValue: '1',
        packPrice: 87.52,
        dailyAverageDispensed: 0,
      },
      PLI: {
        _id: '6201ba27f65f11ad3d79d05f',
        DIN: '2257084',
        genericName: 'FROVATRIPTAN SUCCINATE TABLET',
        supplierCode: 'PLI',
        brandName: 'FROVA TB 2.5MG 7',
        form: 'TABLET',
        packSize: '7',
        rebateValue: null,
        packPrice: 113.02,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '49606',
    genericName: 'ELETRIPTAN HYDROBROMIDE TABLET',
    strength: '40MG',
    unitPrice: 16.357,
    MFRs: {
      TEV: {
        _id: '6201ba20f65f11ad3d7912f6',
        DIN: '2382105',
        genericName: 'ELETRIPTAN HYDROBROMIDE TABLET',
        supplierCode: 'TEV',
        brandName: 'ELETRIPTAN TB 40MG BLS 6 TEVA',
        form: 'TABLET',
        packSize: '6',
        rebateValue: '1',
        packPrice: 60.51,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba24f65f11ad3d79889d',
        DIN: '2386062',
        genericName: 'ELETRIPTAN HYDROBROMIDE TABLET',
        supplierCode: 'APX',
        brandName: 'ELETRIPTAN TB 40MG BLST 6 APO',
        form: 'TABLET',
        packSize: '6',
        rebateValue: '1',
        packPrice: 60.51,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba26f65f11ad3d79b403',
        DIN: '2493691',
        genericName: 'ELETRIPTAN HYDROBROMIDE TABLET',
        supplierCode: 'JAP',
        brandName: 'ELETRIPTAN TB 40MG BLST 6 JAMP',
        form: 'TABLET',
        packSize: '6',
        rebateValue: '1',
        packPrice: 60.51,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba27f65f11ad3d79d183',
        DIN: '2256304',
        genericName: 'ELETRIPTAN HYDROBROMIDE TABLET',
        supplierCode: 'UPJ',
        brandName: 'RELPAX TB 40MG 6',
        form: 'TABLET',
        packSize: '6',
        rebateValue: null,
        packPrice: 98.14,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba24f65f11ad3d797655',
        DIN: '2479478',
        genericName: 'ELETRIPTAN HYDROBROMIDE TABLET',
        supplierCode: 'AUP',
        brandName: 'ELETRIPTAN TB 40MG BLST 6 AURO',
        form: 'TABLET',
        packSize: '6',
        rebateValue: null,
        packPrice: 60.51,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79aa41',
        DIN: '2511274',
        genericName: 'ELETRIPTAN HYDROBROMIDE TABLET',
        supplierCode: 'SNS',
        brandName: 'ELETRIPTAN TB 40MG UD BLST 6',
        form: 'TABLET',
        packSize: '6',
        rebateValue: null,
        packPrice: 60.51,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '50831',
    genericName: 'NORGESTIMATE-ETHINYL ESTRADIOL',
    strength: '7DAYSX3LO',
    unitPrice: 0.451,
    MFRs: {
      APX: {
        _id: '6201ba20f65f11ad3d79190e',
        DIN: '2401967',
        genericName: 'NORGESTIMATE-ETHINYL ESTRADIOL',
        supplierCode: 'APX',
        brandName: 'TRICIRA LO TB UD 21',
        form: 'TABLET',
        packSize: '21',
        rebateValue: '20',
        packPrice: 9.47,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '51656',
    genericName: 'TADALAFIL TABLET 10 MG ORAL',
    strength: '10MG',
    unitPrice: 13.778,
    MFRs: {
      PMS: {
        _id: '6201ba21f65f11ad3d7934c5',
        DIN: '2409437',
        genericName: 'TADALAFIL TABLET 10 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'TADALAFIL TB 10MG BLST 2X2 PMS',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '50',
        packPrice: 47.7,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba21f65f11ad3d793512',
        DIN: '2422107',
        genericName: 'TADALAFIL TABLET 10 MG ORAL',
        supplierCode: 'APX',
        brandName: 'TADALAFIL TB 10MG BLST 4 APO',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '1',
        packPrice: 47.7,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d793509',
        DIN: '2440164',
        genericName: 'TADALAFIL TABLET 10 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'TADALAFIL TB 10MG BLS 4 TEVA',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '1',
        packPrice: 47.7,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d793549',
        DIN: '2451840',
        genericName: 'TADALAFIL TABLET 10 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'TADALAFIL TB 10MG BLST 4 JAMP',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '1',
        packPrice: 47.7,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba21f65f11ad3d793567',
        DIN: '2452251',
        genericName: 'TADALAFIL TABLET 10 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'TADALAFIL TB 10MG BLS 4 MAR',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '1',
        packPrice: 47.7,
        dailyAverageDispensed: 0,
      },
      LIL: {
        _id: '6201ba27f65f11ad3d79bf68',
        DIN: '2248088',
        genericName: 'TADALAFIL TABLET 10 MG ORAL',
        supplierCode: 'LIL',
        brandName: 'CIALIS TB 10MG 4',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 55.11,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba21f65f11ad3d793451',
        DIN: '2410656',
        genericName: 'TADALAFIL TABLET 10 MG ORAL',
        supplierCode: 'MYL',
        brandName: 'TADALAFIL TB 10MG BLS 4 MYLAN',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 47.7,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d793583',
        DIN: '2435934',
        genericName: 'TADALAFIL TABLET 10 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'TADALAFIL TB 10MG BLS 2X2 AURO',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 47.7,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79a95f',
        DIN: '2457024',
        genericName: 'TADALAFIL TABLET 10 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'TADALAFIL TB 10MG 4',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 47.7,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '51657',
    genericName: 'TADALAFIL TABLET 20 MG ORAL',
    strength: '20MG',
    unitPrice: 14.253,
    MFRs: {
      PMS: {
        _id: '6201ba21f65f11ad3d7934c6',
        DIN: '2409445',
        genericName: 'TADALAFIL TABLET 20 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'TADALAFIL TB 20MG BLST 2X2 PMS',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '80',
        packPrice: 49.43,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba21f65f11ad3d793517',
        DIN: '2422115',
        genericName: 'TADALAFIL TABLET 20 MG ORAL',
        supplierCode: 'APX',
        brandName: 'TADALAFIL TB 20MG BLST 4 APO',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '80',
        packPrice: 49.43,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d793502',
        DIN: '2440172',
        genericName: 'TADALAFIL TABLET 20 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'TADALAFIL TB 20MG BLS 4 TEVA',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '80',
        packPrice: 49.43,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba21f65f11ad3d7934d8',
        DIN: '2451697',
        genericName: 'TADALAFIL TABLET 20 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'TADALAFIL TB 20MG BLS 4 MINT',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '75',
        packPrice: 49.43,
        dailyAverageDispensed: 0,
      },
      RAN: {
        _id: '6201ba21f65f11ad3d793540',
        DIN: '2452111',
        genericName: 'TADALAFIL TABLET 20 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'TADALAFIL TB 20MG BLST 4 RAN',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '72',
        packPrice: 49.43,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d793548',
        DIN: '2451859',
        genericName: 'TADALAFIL TABLET 20 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'TADALAFIL TB 20MG BLST 4 JAMP',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '65',
        packPrice: 49.43,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba21f65f11ad3d793552',
        DIN: '2452243',
        genericName: 'TADALAFIL TABLET 20 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'TADALAFIL TB 20MG BLS 4 MAR',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '1',
        packPrice: 49.43,
        dailyAverageDispensed: 0,
      },
      LIL: {
        _id: '6201ba27f65f11ad3d79bf67',
        DIN: '2248089',
        genericName: 'TADALAFIL TABLET 20 MG ORAL',
        supplierCode: 'LIL',
        brandName: 'CIALIS TB 20MG 4',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 57.01,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba21f65f11ad3d793452',
        DIN: '2410664',
        genericName: 'TADALAFIL TABLET 20 MG ORAL',
        supplierCode: 'MYL',
        brandName: 'TADALAFIL TB 20MG BLS 4 MYLAN',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 49.43,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d793582',
        DIN: '2435942',
        genericName: 'TADALAFIL TABLET 20 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'TADALAFIL TB 20MG BLS 2X2 AURO',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 49.43,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79a960',
        DIN: '2457032',
        genericName: 'TADALAFIL TABLET 20 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'TADALAFIL TB 20MG 4',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 49.43,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '51884',
    genericName: 'VARDENAFIL HCL TABLET 20 MG OR',
    strength: '20MG',
    unitPrice: 14.5,
    MFRs: {
      APX: {
        _id: '6201ba23f65f11ad3d796ad4',
        DIN: '2471663',
        genericName: 'VARDENAFIL HCL TABLET 20 MG OR',
        supplierCode: 'APX',
        brandName: 'VARDENAFIL TB 20MG BLST 4 APO',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '60',
        packPrice: 46.72,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba23f65f11ad3d796757',
        DIN: '2475693',
        genericName: 'VARDENAFIL HCL TABLET 20 MG OR',
        supplierCode: 'JAP',
        brandName: 'VARDENAFIL TB BLST 20MG 4 JAMP',
        form: 'TABLET',
        packSize: '4',
        rebateValue: '50',
        packPrice: 46.72,
        dailyAverageDispensed: 0,
      },
      BAH: {
        _id: '6201ba26f65f11ad3d79adf6',
        DIN: '2250489',
        genericName: 'VARDENAFIL HCL TABLET 20 MG OR',
        supplierCode: 'BAH',
        brandName: 'LEVITRA TB 20MG 4',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 58,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba23f65f11ad3d7967a5',
        DIN: '2464233',
        genericName: 'VARDENAFIL HCL TABLET 20 MG OR',
        supplierCode: 'MYL',
        brandName: 'VARDENAFIL TB BLST 20MG 4 MYLAN',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 46.72,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79b58a',
        DIN: '2520400',
        genericName: 'VARDENAFIL HCL TABLET 20 MG OR',
        supplierCode: 'SNS',
        brandName: 'VARDENAFIL TB 20MG 4 SNS',
        form: 'TABLET',
        packSize: '4',
        rebateValue: null,
        packPrice: 46.72,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '52050',
    genericName: 'MOXIFLOXACIN HCL DROPS 0.50% O',
    strength: '0.50%',
    unitPrice: 4.907,
    MFRs: {
      JAP: {
        _id: '6201ba23f65f11ad3d7970ba',
        DIN: '2472120',
        genericName: 'MOXIFLOXACIN HCL DROPS 0.50% O',
        supplierCode: 'JAP',
        brandName: 'MOXIFLOXACIN OPHT SOL 0.5% 3ML JAMP',
        form: 'SOLUTION',
        packSize: '3',
        rebateValue: '65',
        packPrice: 11.27,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba21f65f11ad3d792b2a',
        DIN: '2432218',
        genericName: 'MOXIFLOXACIN HCL DROPS 0.50% O',
        supplierCode: 'PMS',
        brandName: 'MOXIFLOXACIN HCL OPHT SOL 0.5% 3ML PMS',
        form: 'SOLUTION',
        packSize: '3',
        rebateValue: '55',
        packPrice: 11.27,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba21f65f11ad3d792b37',
        DIN: '2411520',
        genericName: 'MOXIFLOXACIN HCL DROPS 0.50% O',
        supplierCode: 'SDZ',
        brandName: 'MOXIFLOXACIN HCL OPHT SOL 0.5% 3ML SDZ',
        form: 'SOLUTION',
        packSize: '3',
        rebateValue: '50',
        packPrice: 11.27,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba21f65f11ad3d792b7f',
        DIN: '2406373',
        genericName: 'MOXIFLOXACIN HCL DROPS 0.50% O',
        supplierCode: 'APX',
        brandName: 'MOXIFLOXACIN OPHT SOL 0.5% 3ML APO',
        form: 'SOLUTION',
        packSize: '3',
        rebateValue: '20',
        packPrice: 11.27,
        dailyAverageDispensed: 0,
      },
      NPC: {
        _id: '6201ba27f65f11ad3d79cde4',
        DIN: '2252260',
        genericName: 'MOXIFLOXACIN HCL DROPS 0.50% O',
        supplierCode: 'NPC',
        brandName: 'VIGAMOX OPHT SOL 0.5% 3ML',
        form: 'SOLUTION',
        packSize: '3',
        rebateValue: null,
        packPrice: 14.72,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '53296',
    genericName: 'TADALAFIL TABLET 5 MG ORAL',
    strength: '5MG',
    unitPrice: 4.265,
    MFRs: {
      PMS: {
        _id: '6201ba21f65f11ad3d7934c3',
        DIN: '2409429',
        genericName: 'TADALAFIL TABLET 5 MG ORAL',
        supplierCode: 'PMS',
        brandName: 'TADALAFIL TB 5MG BLST 2X15 PMS',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '50',
        packPrice: 109.41,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba21f65f11ad3d793511',
        DIN: '2422093',
        genericName: 'TADALAFIL TABLET 5 MG ORAL',
        supplierCode: 'APX',
        brandName: 'TADALAFIL TB 5MG BLST 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 109.41,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba21f65f11ad3d79350a',
        DIN: '2440156',
        genericName: 'TADALAFIL TABLET 5 MG ORAL',
        supplierCode: 'TEV',
        brandName: 'TADALAFIL TB 5MG BLS 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 109.41,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba21f65f11ad3d7934d9',
        DIN: '2451670',
        genericName: 'TADALAFIL TABLET 5 MG ORAL',
        supplierCode: 'MPH',
        brandName: 'TADALAFIL TB 5MG BLS 28 MINT',
        form: 'TABLET',
        packSize: '28',
        rebateValue: '1',
        packPrice: 102.12,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba21f65f11ad3d79354b',
        DIN: '2451832',
        genericName: 'TADALAFIL TABLET 5 MG ORAL',
        supplierCode: 'JAP',
        brandName: 'TADALAFIL TB 5MG BLST 28 JAMP',
        form: 'TABLET',
        packSize: '28',
        rebateValue: '1',
        packPrice: 102.12,
        dailyAverageDispensed: 0,
      },
      RAN: {
        _id: '6201ba21f65f11ad3d79353d',
        DIN: '2452073',
        genericName: 'TADALAFIL TABLET 5 MG ORAL',
        supplierCode: 'RAN',
        brandName: 'TADALAFIL TB 5MG BLST 28 RAN',
        form: 'TABLET',
        packSize: '28',
        rebateValue: '1',
        packPrice: 102.12,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba21f65f11ad3d79354a',
        DIN: '2452278',
        genericName: 'TADALAFIL TABLET 5 MG ORAL',
        supplierCode: 'MRC',
        brandName: 'TADALAFIL TB 5MG BLS 28 MAR',
        form: 'TABLET',
        packSize: '28',
        rebateValue: '1',
        packPrice: 102.12,
        dailyAverageDispensed: 0,
      },
      LIL: {
        _id: '6201ba27f65f11ad3d79d059',
        DIN: '2296896',
        genericName: 'TADALAFIL TABLET 5 MG ORAL',
        supplierCode: 'LIL',
        brandName: 'CIALIS TB 5MG 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 119.41,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba21f65f11ad3d793450',
        DIN: '2410648',
        genericName: 'TADALAFIL TABLET 5 MG ORAL',
        supplierCode: 'MYL',
        brandName: 'TADALAFIL TB 5MG BLS 28 MYLAN',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 102.12,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba21f65f11ad3d793581',
        DIN: '2435926',
        genericName: 'TADALAFIL TABLET 5 MG ORAL',
        supplierCode: 'AUP',
        brandName: 'TADALAFIL TB 5MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 109.41,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79a961',
        DIN: '2457016',
        genericName: 'TADALAFIL TABLET 5 MG ORAL',
        supplierCode: 'SNS',
        brandName: 'TADALAFIL TB 5MG 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 102.12,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '53694',
    genericName: 'AMLODIPINE/ATORVASTATIN TABLET',
    strength: '10MG/20MG',
    unitPrice: 3.386,
    MFRs: {
      APX: {
        _id: '6201ba20f65f11ad3d79123a',
        DIN: '2411326',
        genericName: 'AMLODIPINE/ATORVASTATIN TABLET',
        supplierCode: 'APX',
        brandName: 'AMLODIPINE/ATORVASTATIN TB 10/20MG 100 APO',
        form: 'TABLET',
        packSize: '100',
        rebateValue: '1',
        packPrice: 152.72,
        dailyAverageDispensed: 0,
      },
      UPJ: {
        _id: '6201ba28f65f11ad3d79dea5',
        DIN: '2273292',
        genericName: 'AMLODIPINE/ATORVASTATIN TABLET',
        supplierCode: 'UPJ',
        brandName: 'CADUET TB 10MG/20MG 90',
        form: 'TABLET',
        packSize: '90',
        rebateValue: null,
        packPrice: 304.7,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '53762',
    genericName: 'CINACALCET HCL TABLET 30 MG OR',
    strength: '30MG',
    unitPrice: 12.716,
    MFRs: {
      JAP: {
        _id: '6201ba25f65f11ad3d799f14',
        DIN: '2500094',
        genericName: 'CINACALCET HCL TABLET 30 MG OR',
        supplierCode: 'JAP',
        brandName: 'CINACALCET TB 30MG 30 JAMP',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '70',
        packPrice: 305.84,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba21f65f11ad3d7933c6',
        DIN: '2452693',
        genericName: 'CINACALCET HCL TABLET 30 MG OR',
        supplierCode: 'APX',
        brandName: 'CINACALCET TB 30MG 30 APO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '60',
        packPrice: 305.84,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba22f65f11ad3d793f14',
        DIN: '2441624',
        genericName: 'CINACALCET HCL TABLET 30 MG OR',
        supplierCode: 'TEV',
        brandName: 'CINACALCET TB 30MG 30 TEVA',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 305.84,
        dailyAverageDispensed: 0,
      },
      MRC: {
        _id: '6201ba23f65f11ad3d796878',
        DIN: '2480298',
        genericName: 'CINACALCET HCL TABLET 30 MG OR',
        supplierCode: 'MRC',
        brandName: 'CINACALCET TB 30MG 30 MAR',
        form: 'TABLET',
        packSize: '30',
        rebateValue: '1',
        packPrice: 305.84,
        dailyAverageDispensed: 0,
      },
      AMN: {
        _id: '6201ba27f65f11ad3d79cbed',
        DIN: '2257130',
        genericName: 'CINACALCET HCL TABLET 30 MG OR',
        supplierCode: 'AMN',
        brandName: 'SENSIPAR TB 30MG 30',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 381.49,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba21f65f11ad3d7934c2',
        DIN: '2434539',
        genericName: 'CINACALCET HCL TABLET 30 MG OR',
        supplierCode: 'MYL',
        brandName: 'CINACALCET TB 30MG 30 MYLAN',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 305.84,
        dailyAverageDispensed: 0,
      },
      AUP: {
        _id: '6201ba23f65f11ad3d796fe5',
        DIN: '2478900',
        genericName: 'CINACALCET HCL TABLET 30 MG OR',
        supplierCode: 'AUP',
        brandName: 'CINACALCET TB 30MG 30 AURO',
        form: 'TABLET',
        packSize: '30',
        rebateValue: null,
        packPrice: 305.84,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '60548',
    genericName: 'ETHINYL ESTRADIOL/DROSPIRENONE',
    strength: '0.02-3(24)',
    unitPrice: 0.622,
    MFRs: {
      APX: {
        _id: '6201ba20f65f11ad3d791896',
        DIN: '2415380',
        genericName: 'ETHINYL ESTRADIOL/DROSPIRENONE',
        supplierCode: 'APX',
        brandName: 'MYA TB 3/0.02MG UD 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: '1',
        packPrice: 12.39,
        dailyAverageDispensed: 0,
      },
      BAH: {
        _id: '6201ba27f65f11ad3d79c195',
        DIN: '2321157',
        genericName: 'ETHINYL ESTRADIOL/DROSPIRENONE',
        supplierCode: 'BAH',
        brandName: 'YAZ TB 28',
        form: 'TABLET',
        packSize: '28',
        rebateValue: null,
        packPrice: 17.43,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '60838',
    genericName: 'TIMOLOL MALEATE/TRAVOPROST DRO',
    strength: '0.004/0.5%',
    unitPrice: 11.964,
    MFRs: {
      APX: {
        _id: '6201ba24f65f11ad3d798865',
        DIN: '2415305',
        genericName: 'TIMOLOL MALEATE/TRAVOPROST DRO',
        supplierCode: 'APX',
        brandName: 'TRAVOPROST TIMOP PQ 0.004/0.5% OPTH SOL 5ML APO',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: '10',
        packPrice: 44.21,
        dailyAverageDispensed: 0,
      },
      NPC: {
        _id: '6201ba26f65f11ad3d79b972',
        DIN: '2278251',
        genericName: 'TIMOLOL MALEATE/TRAVOPROST DRO',
        supplierCode: 'NPC',
        brandName: 'DUOTRAV PQ 0.004/0.5% OPHT SOL 5ML',
        form: 'SOLUTION',
        packSize: '5',
        rebateValue: null,
        packPrice: 59.82,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '60897',
    genericName: 'VARENICLINE TARTRATE TABLET 1',
    strength: '1MG',
    unitPrice: 1.949,
    MFRs: {
      APX: {
        _id: '6201ba26f65f11ad3d79ad4c',
        DIN: '2419890',
        genericName: 'VARENICLINE TARTRATE TABLET 1',
        supplierCode: 'APX',
        brandName: 'VARENICLINE TB 1MG 56 APO',
        form: 'TABLET',
        packSize: '56',
        rebateValue: '10',
        packPrice: 51.72,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba24f65f11ad3d7974b8',
        DIN: '2426234',
        genericName: 'VARENICLINE TARTRATE TABLET 1',
        supplierCode: 'TEV',
        brandName: 'VARENICLINE TB 1MG BLST 28 TEVA',
        form: 'TABLET',
        packSize: '28',
        rebateValue: '1',
        packPrice: 25.86,
        dailyAverageDispensed: 0,
      },
      PFC: {
        _id: '6201ba21f65f11ad3d791fe3',
        DIN: '2291185',
        genericName: 'VARENICLINE TARTRATE TABLET 1',
        supplierCode: 'PFC',
        brandName: 'CHAMPIX TB 1MG BLS 2X28',
        form: 'TABLET',
        packSize: '56',
        rebateValue: null,
        packPrice: 109.12,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '60898',
    genericName: 'VARENICLINE TARTRATE TAB DS PK',
    strength: '0.5MG/1MG',
    unitPrice: 1.942,
    MFRs: {
      APX: {
        _id: '6201ba23f65f11ad3d795b78',
        DIN: '2435675',
        genericName: 'VARENICLINE TARTRATE TAB DS PK',
        supplierCode: 'APX',
        brandName: 'VARENICLINE TB 0.5MG/1MG BLST 53 APO',
        form: 'TABLET',
        packSize: '53',
        rebateValue: '10',
        packPrice: 48.78,
        dailyAverageDispensed: 0,
      },
      TEV: {
        _id: '6201ba24f65f11ad3d7974b5',
        DIN: '2426781',
        genericName: 'VARENICLINE TARTRATE TAB DS PK',
        supplierCode: 'TEV',
        brandName: 'VARENICLINE TB 0.5/1MG BLST 25 TEVA',
        form: 'TABLET',
        packSize: '25',
        rebateValue: '1',
        packPrice: 22.91,
        dailyAverageDispensed: 0,
      },
      PFC: {
        _id: '6201ba21f65f11ad3d791f4b',
        DIN: '2298309',
        genericName: 'VARENICLINE TARTRATE TAB DS PK',
        supplierCode: 'PFC',
        brandName: 'CHAMPIX TB 0.5MG/1MG 4 WEEKS STARTER KIT 53',
        form: 'TABLET',
        packSize: '53',
        rebateValue: null,
        packPrice: 102.92,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '62065',
    genericName: 'OLOPATADINE HCL DROPS 0.20% OP',
    strength: '0.2%/W/V',
    unitPrice: 13.092,
    MFRs: {
      APX: {
        _id: '6201ba20f65f11ad3d791769',
        DIN: '2402823',
        genericName: 'OLOPATADINE HCL DROPS 0.20% OP',
        supplierCode: 'APX',
        brandName: 'OLOPATADINE OPHT SOL 0.2% 2.5ML APO',
        form: 'SOLUTION',
        packSize: '2.5',
        rebateValue: '50',
        packPrice: 26.13,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba20f65f11ad3d7915c9',
        DIN: '2420171',
        genericName: 'OLOPATADINE HCL DROPS 0.20% OP',
        supplierCode: 'SDZ',
        brandName: 'OLOPATADINE OPHT SOL 0.2% 2.5ML SDZ',
        form: 'SOLUTION',
        packSize: '2.5',
        rebateValue: '45',
        packPrice: 26.13,
        dailyAverageDispensed: 0,
      },
      NPC: {
        _id: '6201ba1ff65f11ad3d78f746',
        DIN: '2362171',
        genericName: 'OLOPATADINE HCL DROPS 0.20% OP',
        supplierCode: 'NPC',
        brandName: 'PATADAY OPHT SOLUTION 2.5ML',
        form: 'SOLUTION',
        packSize: '2.5',
        rebateValue: null,
        packPrice: 32.73,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '62588',
    genericName: 'CHOLESTYRAMINE/ASPARTAME POWD',
    strength: '4G',
    unitPrice: 2.77,
    MFRs: {
      JAP: {
        _id: '6201ba24f65f11ad3d7977c0',
        DIN: '2478595',
        genericName: 'CHOLESTYRAMINE/ASPARTAME POWD',
        supplierCode: 'JAP',
        brandName: 'CHOLESTYRAMINE SACH PD LIGHT ORANGE FLVR30X4G JAMP',
        form: 'POWDER FOR SUSP',
        packSize: '4',
        rebateValue: '25',
        packPrice: 11.08,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba1ff65f11ad3d78f57f',
        DIN: '890960',
        genericName: 'CHOLESTYRAMINE/ASPARTAME POWD',
        supplierCode: 'PMS',
        brandName: 'OLESTYR LIGHT POWDER SUGAR FREE ORANGE 30X5G',
        form: 'POWDER',
        packSize: '5',
        rebateValue: '1',
        packPrice: 11.08,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '62651',
    genericName: 'CHOLECALCIFEROL (VITAMIN D3) C',
    strength: '50000 UNIT',
    unitPrice: 0.686,
    MFRs: {
      JAP: {
        _id: '6201ba21f65f11ad3d7934d1',
        DIN: '2449110',
        genericName: 'CHOLECALCIFEROL (VITAMIN D3) C',
        supplierCode: 'JAP',
        brandName: 'VITAMIN D SOFTGEL 50000IU 100 JAMP',
        form: 'CAPSULE',
        packSize: '100',
        rebateValue: '25',
        packPrice: 95,
        dailyAverageDispensed: 0,
      },
      AUR: {
        _id: '6201ba26f65f11ad3d79af1e',
        DIN: '2371502',
        genericName: 'CHOLECALCIFEROL (VITAMIN D3) C',
        supplierCode: 'AUR',
        brandName: 'EURO D 50000 IU CAPS 30',
        form: 'CAPSULE',
        packSize: '30',
        rebateValue: null,
        packPrice: 20.58,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '62756',
    genericName: 'PEG3350/NA SULF,BICAR,CL/KCL S',
    strength: '280G/4L',
    unitPrice: 4.493,
    MFRs: {
      PPI: {
        _id: '6201ba27f65f11ad3d79d123',
        DIN: '777838',
        genericName: 'PEG3350/NA SULF,BICAR,CL/KCL S',
        supplierCode: 'PPI',
        brandName: 'PEGLYTE PD FRUIT FLAVOR 280G/4L',
        form: 'POWDER',
        packSize: '4',
        rebateValue: null,
        packPrice: 17.97,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '62870',
    genericName: 'RIVASTIGMINE PATCH TD24 4.6MG/',
    strength: '9MG/PATCH',
    unitPrice: 5.075,
    MFRs: {
      SDZ: {
        _id: '6201ba21f65f11ad3d79217d',
        DIN: '2426293',
        genericName: 'RIVASTIGMINE PATCH TD24 4.6MG/',
        supplierCode: 'SDZ',
        brandName: 'RIVASTIGMINE PATCH 5 9MG/5SQ CM 30 SDZ',
        form: 'PATCH MED.',
        packSize: '30',
        rebateValue: '1',
        packPrice: 119.32,
        dailyAverageDispensed: 0,
      },
      NPC: {
        _id: '6201ba27f65f11ad3d79ced4',
        DIN: '2302845',
        genericName: 'RIVASTIGMINE PATCH TD24 4.6MG/',
        supplierCode: 'NPC',
        brandName: 'EXELON PATCH 9MG/5CM 4.6MG/24H 30',
        form: 'PATCH MED.',
        packSize: '30',
        rebateValue: null,
        packPrice: 152.24,
        dailyAverageDispensed: 0,
      },
      MYL: {
        _id: '6201ba22f65f11ad3d793eea',
        DIN: '2423413',
        genericName: 'RIVASTIGMINE PATCH TD24 4.6MG/',
        supplierCode: 'MYL',
        brandName: 'RIVASTIGMINE PATCH 5 9MG 30 MYLAN',
        form: 'PATCH MED.',
        packSize: '30',
        rebateValue: null,
        packPrice: 119.32,
        dailyAverageDispensed: 0,
      },
      SIP: {
        _id: '6201ba26f65f11ad3d79a829',
        DIN: '2479540',
        genericName: 'RIVASTIGMINE PATCH TD24 4.6MG/',
        supplierCode: 'SIP',
        brandName: 'RIVASTIGMINE PATCH 5 4.6MG/24H 30',
        form: 'PATCH MED.',
        packSize: '30',
        rebateValue: null,
        packPrice: 119.32,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '63240',
    genericName: 'QUETIAPINE FUMARATE TAB ER 24H',
    strength: '50MG',
    unitPrice: 1.055,
    MFRs: {
      TEV: {
        _id: '6201ba20f65f11ad3d790ac4',
        DIN: '2395444',
        genericName: 'QUETIAPINE FUMARATE TAB ER 24H',
        supplierCode: 'TEV',
        brandName: 'QUETIAPINE XR TB 50MG 60 TEVA',
        form: 'TABLET EXT REL',
        packSize: '60',
        rebateValue: '1',
        packPrice: 15.01,
        dailyAverageDispensed: 0,
      },
      SDZ: {
        _id: '6201ba21f65f11ad3d792d38',
        DIN: '2407671',
        genericName: 'QUETIAPINE FUMARATE TAB ER 24H',
        supplierCode: 'SDZ',
        brandName: 'QUETIAPINE XRT TB 50MG 100 SDZ',
        form: 'TABLET EXT REL',
        packSize: '100',
        rebateValue: '1',
        packPrice: 25.01,
        dailyAverageDispensed: 0,
      },
      AHC: {
        _id: '6201ba25f65f11ad3d799be5',
        DIN: '2450860',
        genericName: 'QUETIAPINE FUMARATE TAB ER 24H',
        supplierCode: 'AHC',
        brandName: 'QUETIAPINE XR TB 50MG 60 ACH',
        form: 'TAB SUST RELEAS',
        packSize: '60',
        rebateValue: '1',
        packPrice: 15.01,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba23f65f11ad3d795dd1',
        DIN: '2457229',
        genericName: 'QUETIAPINE FUMARATE TAB ER 24H',
        supplierCode: 'APX',
        brandName: 'QUETIAPINE XR TB 50MG 60 APO',
        form: 'TABLET EXT REL',
        packSize: '60',
        rebateValue: '1',
        packPrice: 15.01,
        dailyAverageDispensed: 0,
      },
      NRI: {
        _id: '6201ba26f65f11ad3d79b1f3',
        DIN: '2510677',
        genericName: 'QUETIAPINE FUMARATE TAB ER 24H',
        supplierCode: 'NRI',
        brandName: 'QUETIAPINE XR TB 50MG 60 NRA',
        form: 'TABLET EXT REL',
        packSize: '60',
        rebateValue: '1',
        packPrice: 15.01,
        dailyAverageDispensed: 0,
      },
      AST: {
        _id: '6201ba27f65f11ad3d79ccad',
        DIN: '2300184',
        genericName: 'QUETIAPINE FUMARATE TAB ER 24H',
        supplierCode: 'AST',
        brandName: 'SEROQUEL XR TB 50MG 60',
        form: 'TABLET EXT REL',
        packSize: '60',
        rebateValue: null,
        packPrice: 63.33,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba26f65f11ad3d79b355',
        DIN: '2516616',
        genericName: 'QUETIAPINE FUMARATE TAB ER 24H',
        supplierCode: 'SNS',
        brandName: 'QUETIAPINE FUMARATE XR TB 50MG 100 SNS',
        form: 'TABLET',
        packSize: '100',
        rebateValue: null,
        packPrice: 25.01,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '63736',
    genericName: 'DESVENLAFAXINE SUCCINATE TAB E',
    strength: '50MG',
    unitPrice: 2.47,
    MFRs: {
      APX: {
        _id: '6201ba22f65f11ad3d794ea3',
        DIN: '2466988',
        genericName: 'DESVENLAFAXINE SUCCINATE TAB E',
        supplierCode: 'APX',
        brandName: 'DESVENLAFAXINE TB 50MG 30 APO',
        form: 'TABLET EXT REL',
        packSize: '30',
        rebateValue: '1',
        packPrice: 70.23,
        dailyAverageDispensed: 0,
      },
      PFC: {
        _id: '6201ba22f65f11ad3d7942ea',
        DIN: '2321092',
        genericName: 'DESVENLAFAXINE SUCCINATE TAB E',
        supplierCode: 'PFC',
        brandName: 'PRISTIQ TB 50MG BLST 30',
        form: 'TABLET EXT REL',
        packSize: '30',
        rebateValue: null,
        packPrice: 74.1,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '63756',
    genericName: 'NEOMY/GRAMICIDIN/NYST/TRIAM CR',
    strength: '0.10%',
    unitPrice: 0.403,
    MFRs: {
      TEV: {
        _id: '6201ba27f65f11ad3d79c3c6',
        DIN: '550507',
        genericName: 'NEOMY/GRAMICIDIN/NYST/TRIAM CR',
        supplierCode: 'TEV',
        brandName: 'TRIACOMB TOPICAL CR 0.1% 15G RATIO',
        form: 'CREAM',
        packSize: '15',
        rebateValue: '1',
        packPrice: 6.04,
        dailyAverageDispensed: 0,
      },
      TAR: {
        _id: '6201ba27f65f11ad3d79d32e',
        DIN: '717002',
        genericName: 'NEOMY/GRAMICIDIN/NYST/TRIAM CR',
        supplierCode: 'TAR',
        brandName: 'VIADERM KC CR 15G',
        form: 'CREAM',
        packSize: '15',
        rebateValue: '1',
        packPrice: 6.35,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '63925',
    genericName: 'RISEDRONATE SODIUM TABLET 150',
    strength: '150MG',
    unitPrice: 49.95,
    MFRs: {
      TEV: {
        _id: '6201ba20f65f11ad3d79138c',
        DIN: '2413809',
        genericName: 'RISEDRONATE SODIUM TABLET 150',
        supplierCode: 'TEV',
        brandName: 'RISEDRONATE TB 150MG BLS 1 TEVA',
        form: 'TABLET',
        packSize: '1',
        rebateValue: '75',
        packPrice: 11.19,
        dailyAverageDispensed: 0,
      },
      PMS: {
        _id: '6201ba20f65f11ad3d7918dc',
        DIN: '2424177',
        genericName: 'RISEDRONATE SODIUM TABLET 150',
        supplierCode: 'PMS',
        brandName: 'RISEDRONATE TB 150MG BLST 1 PMS',
        form: 'TABLET',
        packSize: '1',
        rebateValue: '72',
        packPrice: 11.19,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba1ff65f11ad3d7902ca',
        DIN: '2377721',
        genericName: 'RISEDRONATE SODIUM TABLET 150',
        supplierCode: 'APX',
        brandName: 'RISEDRONATE TB 150MG BLST 1 APO',
        form: 'TABLET',
        packSize: '1',
        rebateValue: '60',
        packPrice: 11.19,
        dailyAverageDispensed: 0,
      },
      ALL: {
        _id: '6201ba26f65f11ad3d79b39d',
        DIN: '2316838',
        genericName: 'RISEDRONATE SODIUM TABLET 150',
        supplierCode: 'ALL',
        brandName: 'ACTONEL TB 150MG BLST 1',
        form: 'TABLET',
        packSize: '1',
        rebateValue: null,
        packPrice: 49.95,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '65335',
    genericName: 'ZOLPIDEM TARTRATE TAB SUBL 5 M',
    strength: '5MG',
    unitPrice: 1.489,
    MFRs: {
      PMS: {
        _id: '6201ba22f65f11ad3d793eec',
        DIN: '2436175',
        genericName: 'ZOLPIDEM TARTRATE TAB SUBL 5 M',
        supplierCode: 'PMS',
        brandName: 'ZOLPIDEM ODT 5MG BLST 30 PMS',
        form: 'TAB ORAL DISINT',
        packSize: '30',
        rebateValue: '50',
        packPrice: 35.48,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba21f65f11ad3d792256',
        DIN: '2436159',
        genericName: 'ZOLPIDEM TARTRATE TAB SUBL 5 M',
        supplierCode: 'APX',
        brandName: 'ZOLPIDEM ODT 5MG BLST 30 APO',
        form: 'TAB ORAL DISINT',
        packSize: '30',
        rebateValue: '1',
        packPrice: 35.48,
        dailyAverageDispensed: 0,
      },
      MPH: {
        _id: '6201ba25f65f11ad3d79a0b7',
        DIN: '2472821',
        genericName: 'ZOLPIDEM TARTRATE TAB SUBL 5 M',
        supplierCode: 'MPH',
        brandName: 'ZOLPIDEM ODT TB SUBL 5MG UD BLST 30 MINT',
        form: 'TAB SUBL',
        packSize: '30',
        rebateValue: '1',
        packPrice: 35.48,
        dailyAverageDispensed: 0,
      },
      BAU: {
        _id: '6201ba20f65f11ad3d790960',
        DIN: '2391678',
        genericName: 'ZOLPIDEM TARTRATE TAB SUBL 5 M',
        supplierCode: 'BAU',
        brandName: 'SUBLINOX TB 5MG 30',
        form: 'TAB ORAL DISINT',
        packSize: '30',
        rebateValue: null,
        packPrice: 44.66,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '66762',
    genericName: 'SOD PICOSULF/MAG OXID/CIT AC P',
    strength: '10-12/16.1',
    unitPrice: 13.105,
    MFRs: {
      FEI: {
        _id: '6201ba1ff65f11ad3d78f864',
        DIN: '2254794',
        genericName: 'SOD PICOSULF/MAG OXID/CIT AC P',
        supplierCode: 'FEI',
        brandName: 'PICO-SALAX CRANBERRY SACHETS 2',
        form: 'POWDER',
        packSize: '2',
        rebateValue: null,
        packPrice: 26.21,
        dailyAverageDispensed: 0,
      },
      ODN: {
        _id: '6201ba20f65f11ad3d7909c6',
        DIN: '2317966',
        genericName: 'SOD PICOSULF/MAG OXID/CIT AC P',
        supplierCode: 'ODN',
        brandName: 'PURG-ODAN SOLO PD SACHET 1',
        form: 'POWDER',
        packSize: '1',
        rebateValue: null,
        packPrice: 5.89,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '67899',
    genericName: 'SALBUTAMOL SULFATE HFA AER AD',
    strength: '100MCG/DS',
    unitPrice: 0.025,
    MFRs: {
      TEV: {
        _id: '6201ba25f65f11ad3d798cc5',
        DIN: '0',
        genericName: 'SALBUTAMOL SULFATE HFA AER AD',
        supplierCode: 'TEV',
        brandName: 'SALBUTAMOL HFA AEROSOL 100MCG UK 200DS TEVA',
        form: 'AEROSOL MET DS',
        packSize: '200',
        rebateValue: '40',
        packPrice: 5,
        dailyAverageDispensed: 0,
      },
      JAP: {
        _id: '6201ba25f65f11ad3d798dee',
        DIN: '99113779',
        genericName: 'SALBUTAMOL SULFATE HFA AER AD',
        supplierCode: 'JAP',
        brandName: 'SALBUTAMOL 100MCG 200DS JAMP SPAIN',
        form: 'AEROSOL MET DS',
        packSize: '200',
        rebateValue: '20',
        packPrice: 8.75,
        dailyAverageDispensed: 0,
      },
      APX: {
        _id: '6201ba24f65f11ad3d798541',
        DIN: '2245669',
        genericName: 'SALBUTAMOL SULFATE HFA AER AD',
        supplierCode: 'APX',
        brandName: 'SALBUTAMOL 100MCG HFA 200DS APO',
        form: 'AEROSOL MET DS',
        packSize: '200',
        rebateValue: '10',
        packPrice: 5,
        dailyAverageDispensed: 0,
      },
      BAU: {
        _id: '6201ba28f65f11ad3d79e0ad',
        DIN: '2232570',
        genericName: 'SALBUTAMOL SULFATE HFA AER AD',
        supplierCode: 'BAU',
        brandName: 'AIROMIR INHALER 100MCG/DOSE 200DS',
        form: 'AEROSOL MET DS',
        packSize: '200',
        rebateValue: null,
        packPrice: 6.17,
        dailyAverageDispensed: 0,
      },
      GSK: {
        _id: '6201ba27f65f11ad3d79c70f',
        DIN: '2241497',
        genericName: 'SALBUTAMOL SULFATE HFA AER AD',
        supplierCode: 'GSK',
        brandName: 'VENTOLIN HFA AERO 100MCG/DS 200DS',
        form: 'AEROSOL MET DS',
        packSize: '200',
        rebateValue: null,
        packPrice: 6.33,
        dailyAverageDispensed: 0,
      },
      SNS: {
        _id: '6201ba20f65f11ad3d791847',
        DIN: '2419858',
        genericName: 'SALBUTAMOL SULFATE HFA AER AD',
        supplierCode: 'SNS',
        brandName: 'SALBUTAMOL HFA 100MCG INH 200DS SNS',
        form: 'AEROSOL MET DS',
        packSize: '200',
        rebateValue: null,
        packPrice: 5,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
  {
    groupCode: '77156',
    genericName: 'METHOTREXATE SODIUM SYRINGE 20',
    strength: '20MG/0.4ML',
    unitPrice: 69.25,
    MFRs: {
      AHC: {
        _id: '6201ba24f65f11ad3d798845',
        DIN: '2491346',
        genericName: 'METHOTREXATE SODIUM SYRINGE 20',
        supplierCode: 'AHC',
        brandName: 'METHOTREXATE PRE-FILLED SYR 20MG/0.40ML ACH',
        form: 'SOLUTION',
        packSize: '0.4',
        rebateValue: '1',
        packPrice: 26.25,
        dailyAverageDispensed: 0,
      },
      PED: {
        _id: '6201ba22f65f11ad3d793d6a',
        DIN: '2454866',
        genericName: 'METHOTREXATE SODIUM SYRINGE 20',
        supplierCode: 'PED',
        brandName: 'METOJECT PREFILLED SYRINGE 20MG/0.4ML 1',
        form: 'SOLUTION',
        packSize: '0.4',
        rebateValue: null,
        packPrice: 27.7,
        dailyAverageDispensed: 0,
      },
    },
    quantity: {
      requested: null,
      dailyAverage: 0,
      total: 0,
    },
  },
]
