<template>
  <div
    id="scroller"
    data-intercom-target="ratesTable"
  >
    <v-card>
      <v-card-text>
        <v-row class="match-height">
          <v-col
            cols="12"
            sm="4"
          >
            Commercial Rates Table
          </v-col>
          <v-col
            cols="12"
            offset-md="4"
            md="4"
          >
          </v-col>
        </v-row>
      </v-card-text>

      <div>
        <v-row class="match-height">
          <v-col
            cols="12"
            sm="12"
          >
            <v-card-text>
              <v-row>
                <v-col
                  id="tableOptionsButton"
                  cols="12"
                  md="3"
                >
                  <p class="tw-t">
                    Last Saved: {{ this.date }}
                    <v-btn
                      v-if="saving"
                      color="success"
                      loading
                      x-small
                    ></v-btn>
                  </p>
                </v-col>

                <v-col
                  id="searchField"
                  cols="12"
                  offset-md="5"
                  md="4"
                >
                  <v-text-field
                    v-model="search"
                    :append-icon="icons.mdiMagnify"
                    data-intercom-target="searchField"
                    label="Search"
                    single-line
                    hide-details
                    dense
                    clearable
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-form
              ref="form"
              v-model="isValidRebateInput"
            >
              <v-data-table
                id="ratesDashboardDataTable"
                :headers="headers"
                :items="drugCatalogData"
                :search="search"
                :items-per-page="10"
                :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50, -1]}"
                :loading="loading"
                class="table-kitchen-sink"
              >
                <template
                  v-for="mfr in mfrList"
                  v-slot:[`item.${mfr}`]="{ item }"
                  class="tw-pt-2 tw-mt-2"
                >
                  <v-text-field
                    v-if="item.MFRs[mfr]"
                    :key="item.MFRs[mfr].DIN"
                    clearable
                    :style="{backgroundColor: `hsl(${getRebateValue(item, mfr) * 1.2}, 100%, 75%)` }"
                    class="custom-placeholder-chip input-center"
                    :rules="[integerValidator,betweeInclusiveValidator]"
                    :value="getRebateValue(item, mfr)"
                    @input="debouncedUpdateGroupCode(item.groupCode, mfr, $event)"
                  ></v-text-field>
                </template>
              </v-data-table>
            </v-form>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <v-dialog
      v-model="isDialogVisibleOverlay"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="pt-3">
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import useAppConfig from '@core/@app-config/useAppConfig'

import { mdiMagnify } from '@mdi/js'
import { debounce } from 'lodash'
import { integerValidator, between } from '@core/utils/validation'
import { drugCatalogData } from './drugCatalogData'
import { rebateValuesData } from './rebateValuesData'
import { drugCatalog } from './drugCatalog'
import { rebateColumns } from './rebateColumns'
import { mfrNames } from './mfrNames'

export default {
  name: 'RebateTable',
  components: {},
  data() {
    return {
      drugCatalog,
      mfrNames,
      rebateColumns,
      isValidRebateInput: true,
      rebateValuesBulkUpdate: {},
      saving: false,
      date: '',
      selectedActiveMfrListKey: 0,
      selectedMfrKey: 0,
      refreshkey: 0,
      rowIndex: [],
      activeMfr: [],
      activeMfrTable: [],
      activeMfrTableClean: [],
      activeMfrTableFull: [],
      integerValidator,
      isDialogVisibleOverlay: false,
      showRebateTable: true,
      isDialogVisible: false,
      mfrList: [],
      loadingselectedMfrList: false,
      selectedMfrListValue: [
        {
          text: 'APOTEX INC - APX',
          value: 'APX',
        },
        {
          text: 'TARO PHARMACEUTICALS INC - TAR',
          value: 'TAR',
        },
        {
          text: 'TARO PHARMA (RANBAXY PHARMA) - RAN',
          value: 'RAN',
        },
        {
          text: 'MINT PHARMACEUTICALS INC - MPH',
          value: 'MPH',
        },
        {
          text: 'PHARMASCIENCE CANADA (ATM) - PMS',
          value: 'PMS',
        },
        {
          text: 'AURO PHARMA INC - AUP',
          value: 'AUP',
        },
      ],
      selectedMfrList: ['APX', 'TAR', 'RAN', 'MPH', 'PMS', 'AUP'],
      loading: false,
      drugCatalogData,
      rebateValuesData,
      search: '',
      icons: {
        mdiMagnify,
      },
      headers: [],
      status: {
        1: 'success',
        2: 'info',
        3: 'warning',
        4: 'error',
      },
      activeHeaders: [
        {
          text: 'Configured Mfrs',
          value: 'text',
        },
        {
          text: '%',
          value: 'rateNum',
        },
        {
          text: 'Chart',
          value: 'rate',
        },
      ],
    }
  },
  setup() {
    const { menuIsVerticalNavMini, menuIsMenuHidden } = useAppConfig()

    return {
      menuIsVerticalNavMini,
      menuIsMenuHidden,
      between,
    }
  },
  computed: {},
  watch: {},

  async beforeMount() {
    this.isDialogVisibleOverlay = true
    try {
      await this.getRebatesStates()
      this.getFromLocalStorage()
      this.populateData()
    } catch (e) {
      console.log(e)
    }

    this.loadingselectedMfrList = false
    this.isDialogVisibleOverlay = false
  },
  mounted() {
    this.getFromLocalStorage()
    this.populateData()
    this.displayTable()
  },
  methods: {
    betweeInclusiveValidator(value) {
      return between(value, 0, 100)()
    },
    currentDate() {
      this.date = new Date()
    },
    savingTimer() {
      this.currentDate()
      this.saving = true
      setTimeout(() => {
        this.saving = false
      }, 1500)
    },
    getFromLocalStorage() {
      if (!localStorage.getItem('selectedMfrList')) {
        localStorage.removeItem('selectedMfrList')
      } else {
        this.selectedMfrListValue = this.convertToArrayOfObjects(localStorage.getItem('selectedMfrList').split(','))
        this.displayTable()
      }
    },

    // Filtering an array of objects by an array of objects.
    filterObjectArray(arr, filterArr) {
      return arr.filter(el => filterArr.some(f => f.text === el.text))
    },

    // Filtering an array of objects by a list of objects.
    filterObjectArrayMfr(arr, filterArr) {
      return arr.filter(el => filterArr.some(f => f.value === el.value))
    },

    // Converting an array of strings into an array of objects.
    convertToArrayOfObjects(arr) {
      return arr.map(el => ({
        text: el,
        value: el.split('- ')[1],
      }))
    },

    // Removing all the empty values from the object.
    removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, v]) => v != null)
          .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v]),
      )
    },

    // Converting the object to an array of objects.
    convertObjecttoArrayOfObjects(obj) {
      return Object.entries(obj).map(([k, v]) => ({
        mfr: k,
        value: v,
      }))
    },

    // Merging two arrays of objects
    mergeArrayObjects(arr1, arr2) {
      let start = 0
      const merge = []

      while (start < arr1.length) {
        if (arr1[start].value === arr2[start].value) {
          // pushing the merged objects into array
          merge.push({
            ...arr1[start],
            ...arr2[start],
          })
        }

        // incrementing start value
        start += 1
      }

      return merge
    },

    SortArray(x, y) {
      return x.value.localeCompare(y.value)
    },

    // Merging two arrays of objects based on a key.
    mergeArrayOfObjBasedOnKey(arr1, arr2) {
      const merged = []

      for (let i = 0; i < arr1.length; i++) {
        merged.push({
          ...arr1[i],
          ...arr2[i],
        })
      }

      return merged
    },

    displayTable() {
      this.selectedMfrList = this.selectedMfrListValue.map(x => x.value)
      this.getHeaders()
      this.showRebateTable = true
      localStorage.setItem(
        'selectedMfrList',
        this.selectedMfrListValue.map(x => x.text),
      )
    },

    async getRebatesStates() {
      this.filteredList = JSON.parse(JSON.stringify(this.drugCatalog))
      this.mfrColumns = JSON.parse(JSON.stringify(this.rebateColumns))
    },
    getHeaders() {
      this.headers = [
        {
          text: 'Generic Drugs',
          value: 'genericName',
          width: '200',
        },
        {
          text: 'Strength',
          value: 'strength',
          width: '100',
        },
        ...this.selectedMfrList.map(mfr => ({
          text: mfr,
          value: mfr,
          width: '100',
          align: 'center',
        })),
      ]
    },
    populateData() {
      if (this.drugCatalog.length > 0) {
        // allow sorting for MFR
        this.drugCatalogData.forEach(drug => {
          Object.values(drug.MFRs).forEach(mfr => {
            drug[mfr.supplierCode] = mfr.rebateValue
          })
        })

        // this.rebateValuesData = JSON.parse(JSON.stringify(this.rebateValues))
        this.mfrList = Object.keys(this.rebateValuesData.MFRs || {})
        this.activeMfr = this.removeEmpty(this.rebateValuesData.MFRs)
        this.activeMfrTable = this.convertObjecttoArrayOfObjects(this.activeMfr).map(({ mfr, value }) => ({
          value: mfr,
          rate: value,
        }))
        this.activeMfrTableClean = this.filterObjectArrayMfr(this.mfrNames, this.activeMfrTable)

        // The above code is merging the two arrays of objects based on the key of the objects.
        this.activeMfrTableFull = this.mergeArrayObjects(
          this.activeMfrTable.sort(this.SortArray),
          this.activeMfrTableClean.sort(this.SortArray),
        )
      }
    },
    debouncedUpdateMfrBulk: debounce(function (mfr, rebateValue) {
      if (rebateValue === '') {
        this.rebateValuesBulkUpdate[mfr] = null

        return
      }
      this.rebateValuesBulkUpdate[mfr] = rebateValue
    }, 200),
    debouncedUpdateGroupCode: debounce(function (groupCode, mfr, rebateValue) {
      //   if (this.isInt(rebateValue) || rebateValue === '') {
      if (this.isValidRebateInput) {
        this.savingTimer()
        if (!this.rebateValuesData.groupCodes[groupCode]) {
          this.rebateValuesData.groupCodes[groupCode] = {}
        }
        this.rebateValuesData.groupCodes[groupCode][mfr] = rebateValue

        this.saveRebate({
          [groupCode]: {
            //   ...this.rebateValuesData.groupCodes[groupCode],
            [mfr]: rebateValue,
          },
        })
      } else {
        console.error('rebateValue is not a number')
      }
    }, 500),
    isInt(value) {
      return !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10))
    },

    getRebateValue(item, mfr) {
      // if a rebateValue is set for that groupCode in for this specific mfr, use it.

      // else then send back the default (bulk) MFR rebateValue or 0 if none is set

      if (this.rebateValuesData.groupCodes[item.groupCode]?.[mfr]) {
        return this.rebateValuesData.groupCodes[item.groupCode][mfr]
      }

      return this.rebateValuesData.MFRs[mfr] || 0
    },
    async saveRebate(groupCode = {}) {
      this.loading = true
      try {
        // in the case of a specific group code has a rebate value

        this.selectedActiveMfrListKey += 1
      } catch (err) {
        console.error(err)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.custom-placeholer-color input,
.custom-label-color input {
  color: black !important;
  text-align: center;
  font-weight: bold;
}

.custom-placeholder-chip {
  padding: 0;
  max-height: 32px;
  border-radius: 5px;
}

.input-center input {
  text-align: center;
}

.ps-customizer {
  height: calc(100% - 81px) !important;
}

.app-customizer-toggler {
  z-index: 7;
  position: fixed;
  transform: translateX(-50%);
  background: var(--v-primary-base);

  @include ltr() {
    right: -22px;
  }

  @include rtl() {
    left: 20px;
  }
}

@include theme(app-customizer-drawer) using ($material) {
  background-color: map-deep-get($material, 'cards');
}

.app-customizer {
  z-index: 7;

  .v-label {
    font-size: 0.875rem;
  }

  .app-customizer-header {
    position: relative;

    .icon-customizer-close {
      position: absolute;

      @include ltr() {
        right: 20px;
      }

      @include rtl() {
        left: 20px;
      }

      top: 50%;
      transform: translateY(-50%);
    }
  }

  .customizer-section {
    padding: 24px;
  }

  // Fixes Overlay is shown below SystemBar
  @at-root {
    .v-overlay {
      & + .v-application--wrap > .v-system-bar {
        z-index: 6 !important;
      }
    }
  }
}
</style>
