export const mfrNames = [{
  text: 'A LIGNE HEALTH CARE PROD INC - ALH',
  value: 'ALH',
}, {
  text: 'ABBVIE CORPORATION - ABV',
  value: 'ABV',
}, {
  text: 'ABUNDANCE NATURALLY LTD - ABU',
  value: 'ABU',
}, {
  text: 'ACCEL PHARMA INC - ACE',
  value: 'ACE',
}, {
  text: 'ACCELERA PHARMA CAN INC - ACA',
  value: 'ACA',
}, {
  text: 'ACERUS PHARMA CORP (MSD) - ACU',
  value: 'ACU',
}, {
  text: 'ADVANTAGE SOLUTIONS(PREST) - ASN',
  value: 'ASN',
}, {
  text: 'ALERA SKIN CARE PRODUCTS INC. - ASK',
  value: 'ASK',
}, {
  text: 'ALK ABELLO PHARMACEUTICALS INC - AAP',
  value: 'AAP',
}, {
  text: 'ALLERGAN INC - ALL',
  value: 'ALL',
}, {
  text: 'ANB CANADA INC.(OTC) - ANB',
  value: 'ANB',
}, {
  text: 'ARALEZ PHARMA - TPC',
  value: 'TPC',
}, {
  text: 'ASCENSIA DIAB CARE CAN INC. - ADI',
  value: 'ADI',
}, {
  text: 'ASPEN PHARMACARE CDA (LYNDEN) - APE',
  value: 'APE',
}, {
  text: 'ASTELLAS PHARMA CANADA INC - ASP',
  value: 'ASP',
}, {
  text: 'ASTRAZENECA - AST',
  value: 'AST',
}, {
  text: 'ATLANTIC PACKAGING LTD - APP',
  value: 'APP',
}, {
  text: 'ATLAS LABORATORIES INC (ATM) - ATL',
  value: 'ATL',
}, {
  text: 'AUTO CONTROL MED - AUM',
  value: 'AUM',
}, {
  text: 'AVRICORE HEALTH INC. - VAN',
  value: 'VAN',
}, {
  text: 'BAXTER - BXC',
  value: 'BXC',
}, {
  text: 'BEIERSDORF CANADA INC (CPG) - BDF',
  value: 'BDF',
}, {
  text: 'BIOCODEX CANADA INC - BDX',
  value: 'BDX',
}, {
  text: 'BIOCODEX LTD - BIX',
  value: 'BIX',
}, {
  text: 'BIOVENTUS COOPERATIEF U.A. - BOP',
  value: 'BOP',
}, {
  text: 'BOIRON CANADA INC - BOI',
  value: 'BOI',
}, {
  text: 'BUFFALO ORIGINAL INC - BUF',
  value: 'BUF',
}, {
  text: 'CALMOSEPTINE INC - CAO',
  value: 'CAO',
}, {
  text: 'CANDORPHARM INC - CDP',
  value: 'CDP',
}, {
  text: 'CANMED PHARMA CORP. - CND',
  value: 'CND',
}, {
  text: 'CANPREV NATURAL HLT PROD LTD - CAQ',
  value: 'CAQ',
}, {
  text: 'CARDINAL HEALTH CAN INC - SMD',
  value: 'SMD',
}, {
  text: 'CELGENE INC - CEL',
  value: 'CEL',
}, {
  text: 'CHOICEMMED CDN TECHNOLOGY INC - CHD',
  value: 'CHD',
}, {
  text: 'CLOROX CO OF CANADA LTD (HABA) - CLX',
  value: 'CLX',
}, {
  text: 'CLOVER LEAF SEAFOODS L.P. - CLF',
  value: 'CLF',
}, {
  text: 'COLGATE-PALMOLIVE CAN INC - COL',
  value: 'COL',
}, {
  text: 'COMBE INC - CMB',
  value: 'CMB',
}, {
  text: 'CORPORATION GENACOL CANADA INC - DRT',
  value: 'DRT',
}, {
  text: 'COUNSELTRON LTD - CNM',
  value: 'CNM',
}, {
  text: 'COVIS PHARMA - COV',
  value: 'COV',
}, {
  text: 'CTW ENTERPRISES INC (NAT CALM) - CTW',
  value: 'CTW',
}, {
  text: 'CURA HEALTH INC - CUR',
  value: 'CUR',
}, {
  text: 'DORMER LABORATORIES INC - DOR',
  value: 'DOR',
}, {
  text: "DR. REDDY'S LAB - DRD",
  value: 'DRD',
}, {
  text: 'DRIVE MEDICAL CANADA INC - DMC',
  value: 'DMC',
}, {
  text: 'EISAI LTD - EIS',
  value: 'EIS',
}, {
  text: 'ELVIUM LIFE SCIENCES GP INC - ELV',
  value: 'ELV',
}, {
  text: 'EMD INC - EMD',
  value: 'EMD',
}, {
  text: 'ETHICAL REM / 2168852 ONT INC - ETH',
  value: 'ETH',
}, {
  text: 'FEM MED FORMULAS LTD - FMF',
  value: 'FMF',
}, {
  text: 'FLORA MANUFACT. DISTR. INC - FLM',
  value: 'FLM',
}, {
  text: 'FRESENIUS MEDICAL CARE CAN INC - FRM',
  value: 'FRM',
}, {
  text: 'GALDERMA CANADA INC (MOL)OTC - GAC',
  value: 'GAC',
}, {
  text: 'GENUINE HEALTH INC - GHI',
  value: 'GHI',
}, {
  text: 'GILEAD SCIENCES CA - GSI',
  value: 'GSI',
}, {
  text: 'GLENMARK PHARMACEUTICALS CAN - GLN',
  value: 'GLN',
}, {
  text: 'GREENICHE D/O 7674830 CAN INC - GNH',
  value: 'GNH',
}, {
  text: 'GROUPE MARCELLE INC - GML',
  value: 'GML',
}, {
  text: 'GSK CONS HEALTHCARE ULC (OTC) - GSK',
  value: 'GSK',
}, {
  text: 'HAIN CELESTIAL CANADA ULC - HAI',
  value: 'HAI',
}, {
  text: 'HEALTHY BODY SERVICES INC - HBS',
  value: 'HBS',
}, {
  text: 'HENKEL CONS GOODS CAN - DCO',
  value: 'DCO',
}, {
  text: 'HERBALAND NATURALS INC - HNI',
  value: 'HNI',
}, {
  text: 'HOMEOCAN INC (OTC) - HOC',
  value: 'HOC',
}, {
  text: 'HUMN PHARMACEUTICALS INC. - TRR',
  value: 'TRR',
}, {
  text: 'HUNTER AMENITIES INTERNATIONAL - HUN',
  value: 'HUN',
}, {
  text: 'I3 BIO MEDICAL INC - IBM',
  value: 'IBM',
}, {
  text: 'ICE RIVER SPRINGS WTR CO - IRS',
  value: 'IRS',
}, {
  text: 'INNERWORKINGS CANADA INC - INW',
  value: 'INW',
}, {
  text: 'ITWAL CLIF BAR CO (EDI) - ICB',
  value: 'ICB',
}, {
  text: 'JACOBUS PHARMACEUTICAL CO INC - JPC',
  value: 'JPC',
}, {
  text: 'JAMP PHARMA CORP - JAP',
  value: 'JAP',
}, {
  text: 'JL FREEMAN SEC (OTC) - JLF',
  value: 'JLF',
}, {
  text: 'JOHNSON JOHNSON INC (CONS) - JJC',
  value: 'JJC',
}, {
  text: 'JOHNVINCE FOODS - JVF',
  value: 'JVF',
}, {
  text: 'KAZ CANADA INC BOX 911480 - KAZ',
  value: 'KAZ',
}, {
  text: 'KEURIG CANADA INC. - GMC',
  value: 'GMC',
}, {
  text: "L'OREAL CANADA (HAIR) - CSM",
  value: 'CSM',
}, {
  text: "L'OREAL CANADA (SKIN) - MBE",
  value: 'MBE',
}, {
  text: 'LABORATOIRE DUCHESNAY INC - DUI',
  value: 'DUI',
}, {
  text: 'LABORATOIRE RIVA INC - RIV',
  value: 'RIV',
}, {
  text: 'LEADIANT BIOSCIENCES INC. - SGT',
  value: 'SGT',
}, {
  text: 'LEDA HEALTH INNOVATIONS (OTC) - LHI',
  value: 'LHI',
}, {
  text: 'LIFEAGEN BIO SCIENCES INC - LBS',
  value: 'LBS',
}, {
  text: 'LIFESCAN CA ULC - LIF',
  value: 'LIF',
}, {
  text: 'LOGISTICS SUPPORT UNIT(LSU)INC - LSU',
  value: 'LSU',
}, {
  text: 'LOVE CHILD (BRANDS) INC. - LOV',
  value: 'LOV',
}, {
  text: 'LUNDBECK INC. - LUN',
  value: 'LUN',
}, {
  text: 'LUPIN PHARMA CANADA LIMITED - LPN',
  value: 'LPN',
}, {
  text: 'MAGTAR SALES INC - MAG',
  value: 'MAG',
}, {
  text: 'MALITEK INTERNATIONAL INC - MKI',
  value: 'MKI',
}, {
  text: 'MALLINCKRODT CANADA - MNK',
  value: 'MNK',
}, {
  text: 'MANSFIELD MED DIST - MMD',
  value: 'MMD',
}, {
  text: 'MARS CANADA INC - MCI',
  value: 'MCI',
}, {
  text: 'MAXILL INC - MXL',
  value: 'MXL',
}, {
  text: 'MCK SPEC DIST (PHARMA STULLN) - MKS',
  value: 'MKS',
}, {
  text: 'MCK SPEC DIST (SANTEN CANADA) - MSA',
  value: 'MSA',
}, {
  text: 'MEDEXUS INC - MDX',
  value: 'MDX',
}, {
  text: 'MEDEXUS PHARMACEUTICALS INC - PED',
  value: 'PED',
}, {
  text: 'MEDISCA PHARMACEUTIQUE INC - MDS',
  value: 'MDS',
}, {
  text: 'MEDISTIK INC. - MSI',
  value: 'MSI',
}, {
  text: 'MEDUNIK CANADA INC - MDK',
  value: 'MDK',
}, {
  text: 'MENTHOLATUM CO OF CANADA LTD - MNT',
  value: 'MNT',
}, {
  text: 'MERUS LABS INC - MLI',
  value: 'MLI',
}, {
  text: 'MINISTRY OF HEALTH OGPMSS - MHO',
  value: 'MHO',
}, {
  text: 'MINT PHARMACEUTICALS INC - MPH',
  value: 'MPH',
}, {
  text: 'MONTMED INC - MND',
  value: 'MND',
}, {
  text: 'MSD/RECORDATI (MSD) - REI',
  value: 'REI',
}, {
  text: 'MYLAN PHARMACEUTICALS ULC - MYL',
  value: 'MYL',
}, {
  text: 'MYRIAD PHARMA INC - MYR',
  value: 'MYR',
}, {
  text: 'NARIMYA PHARMACEUTICALS INC - NPH',
  value: 'NPH',
}, {
  text: 'NATCO PHARMA (CANADA) INC. - NTC',
  value: 'NTC',
}, {
  text: "NATURE'S WAY OF CANADA LTD - NWC",
  value: 'NWC',
}, {
  text: 'NEILMED PHARMACEUTICALS INC - NPI',
  value: 'NPI',
}, {
  text: 'NESTLE CANADA INC (GROCERIES) - NST',
  value: 'NST',
}, {
  text: 'NESTLE CLINICAL NUTRITION - NES',
  value: 'NES',
}, {
  text: 'NEW NORDIC INC - NNC',
  value: 'NNC',
}, {
  text: 'NEWELL BRANDS CANADA ULC - SUN',
  value: 'SUN',
}, {
  text: 'NO 1899130 ALBERTA INC - ABI',
  value: 'ABI',
}, {
  text: 'NO. 9055 7588 QUE INC (BIOSPE) - BSP',
  value: 'BSP',
}, {
  text: 'NORRIZON SALESMARKETING (REX) - NRZ',
  value: 'NRZ',
}, {
  text: 'NUTRAWISE CORPORATION - NWI',
  value: 'NWI',
}, {
  text: 'ODAN LABORATORIES LTD - ODN',
  value: 'ODN',
}, {
  text: 'OMEGA LABORATOIRES LTD - OMG',
  value: 'OMG',
}, {
  text: 'OMRON HEALTHCARE INC - OMR',
  value: 'OMR',
}, {
  text: 'ORAL SCIENCE INC - OSI',
  value: 'OSI',
}, {
  text: 'ORIMED PHARMA INC - OPH',
  value: 'OPH',
}, {
  text: 'OTSUKA CANADA PHARMA INC. - OTS',
  value: 'OTS',
}, {
  text: 'PCCA CORP - PCC',
  value: 'PCC',
}, {
  text: 'PHARMASYSTEMS INC(ATM)MED DIV - PHS',
  value: 'PHS',
}, {
  text: 'PHILIPS ELECTRONICS LTD - POH',
  value: 'POH',
}, {
  text: 'PIERRE FABRE DERMO-COSMETIQUE - PFD',
  value: 'PFD',
}, {
  text: 'PROCTER GAMBLE INC - PGA',
  value: 'PGA',
}, {
  text: 'PURESOURCE INC - PSR',
  value: 'PSR',
}, {
  text: 'QUIDEL CANADA ULC - QUI',
  value: 'QUI',
}, {
  text: 'RECKITT BENCKISER (CANADA) INC - RBH',
  value: 'RBH',
}, {
  text: 'RECKITT BENCKISER (CANADA) INC - RCO',
  value: 'RCO',
}, {
  text: 'RECOCHEM INC - REC',
  value: 'REC',
}, {
  text: 'RICHARDS PACKAGING INC - RCH',
  value: 'RCH',
}, {
  text: 'ROCHE DIAGNOSTICS-DIV HOFFMAN - RDH',
  value: 'RDH',
}, {
  text: 'RW CONSUMER PRODUCTS - RWP',
  value: 'RWP',
}, {
  text: 'SANIS HEALTH INC - SNS',
  value: 'SNS',
}, {
  text: 'SANOFI PASTEUR LTD - SPL',
  value: 'SPL',
}, {
  text: 'SANOFI-AVENTIS RBC LB T08977C - AVP',
  value: 'AVP',
}, {
  text: 'SANOFI AVENTIS RBC LB T08977C - SAC',
  value: 'SAC',
}, {
  text: 'SARALEX GROUP INC - SAR',
  value: 'SAR',
}, {
  text: 'SEARCHLIGHT PHARMA INC - SGP',
  value: 'SGP',
}, {
  text: 'SEQIRUS CANADA INC - SEQ',
  value: 'SEQ',
}, {
  text: 'SERVIER CANADA INC - SEV',
  value: 'SEV',
}, {
  text: 'SIVEM PHARMACEUTICALS ULC - SIV',
  value: 'SIV',
}, {
  text: 'SMUCKER FOODS OF CANADA CO - SFC',
  value: 'SFC',
}, {
  text: 'STERINOVA INC - SVA',
  value: 'SVA',
}, {
  text: 'STORCK CANADA INC - STO',
  value: 'STO',
}, {
  text: 'STRIDES PHARMA CANADA INC - SIP',
  value: 'SIP',
}, {
  text: 'SULCABRUSH INC - SUL',
  value: 'SUL',
}, {
  text: 'SUN PRODUCTS CAN CORP,HENKEL - TSP',
  value: 'TSP',
}, {
  text: 'SUNOVION PHARMA CAN INC - SPH',
  value: 'SPH',
}, {
  text: 'SUNSTAR AMERICAS INC - SSA',
  value: 'SSA',
}, {
  text: 'SZIO PLUS INC - SZI',
  value: 'SZI',
}, {
  text: 'TANTA PHARMACEUTICALS INC - TAN',
  value: 'TAN',
}, {
  text: 'TARO PHARMA (RANBAXY PHARMA) - RAN',
  value: 'RAN',
}, {
  text: 'TCP HRB ACQUISITION LLC - THQ',
  value: 'THQ',
}, {
  text: 'TENDER CORPORATION - TEN',
  value: 'TEN',
}, {
  text: 'TFB ASSOCIATES LTD - TFB',
  value: 'TFB',
}, {
  text: 'THANE DIRECT CANADA INC - TDC',
  value: 'TDC',
}, {
  text: 'THE AVON COMPANY CANADA LTD - FPA',
  value: 'FPA',
}, {
  text: 'THERMOR LTD - THR',
  value: 'THR',
}, {
  text: 'THOMAS LARGE SINGER INC - TSI',
  value: 'TSI',
}, {
  text: 'THREE M CANADA INC (PERS CARE) - TMC',
  value: 'TMC',
}, {
  text: "TOM'S OF MAINE INC (CDN) - TMS",
  value: 'TMS',
}, {
  text: 'UCB CANADA - UCB',
  value: 'UCB',
}, {
  text: 'UPJOHN CANADA ULC - UPJ',
  value: 'UPJ',
}, {
  text: 'VALEO PHARMA INC - VAL',
  value: 'VAL',
}, {
  text: 'VITA HEALTH PROD INC - VIT',
  value: 'VIT',
}, {
  text: 'VITAL SCIENCE CORP - XXX',
  value: 'XXX',
}, {
  text: 'VPI PHARMACEUTICALS INC. - VPP',
  value: 'VPP',
}, {
  text: 'WAMPOLE DIV JAMP PHARMA CORP - WAM',
  value: 'WAM',
}, {
  text: 'WESTERN IMPACT DISTRIBUTION LT - WES',
  value: 'WES',
}, {
  text: 'WN PHARMACEUTICALS LTD - WNP',
  value: 'WNP',
}, {
  text: 'XEDITON PHARMACEUTICALS INC - XPT',
  value: 'XPT',
}, {
  text: 'XENEX LABORATORIES INC - XEN',
  value: 'XEN',
}, {
  text: 'YVES PONROY LABORATORIES INC - YPL',
  value: 'YPL',
}, {
  text: 'ZAX HEALTHCARE INC - ZAX',
  value: 'ZAX',
}, {
  text: 'KYE PHARMACEUTICALS INC - KYE',
  value: 'KYE',
}, {
  text: 'AA PHARMA INC - AAA',
  value: 'AAA',
}, {
  text: 'ABBOTT LABS LTD-ABB DIAB CARE - ADC',
  value: 'ADC',
}, {
  text: 'ABBOTT RAPID DIAGNOSTICS ULC - ALE',
  value: 'ALE',
}, {
  text: 'ACCELERA PHARMA CANADA INC(RX) - ACL',
  value: 'ACL',
}, {
  text: 'ACCORD HEALTHCARE INC - AHC',
  value: 'AHC',
}, {
  text: 'ACI BRANDS INC - ACI',
  value: 'ACI',
}, {
  text: 'ACTELION PHARMACEUTICALS CAN - APC',
  value: 'APC',
}, {
  text: 'ACTION CANDY COMPANY LTD - ACT',
  value: 'ACT',
}, {
  text: 'ADAPT PHARMA CANADA INC. - ADP',
  value: 'ADP',
}, {
  text: 'ADVANTAGE SALES MARKETING CANA - ASM',
  value: 'ASM',
}, {
  text: 'AGUETTANT CANADA INC - AGU',
  value: 'AGU',
}, {
  text: 'AIRWAY SURGICAL APPLIANCES LTD - ARW',
  value: 'ARW',
}, {
  text: 'ALCON CANADA INC (OTC) - ALC',
  value: 'ALC',
}, {
  text: 'ALTIUS PHARMA DIV ASPRI PHARMA - APR',
  value: 'APR',
}, {
  text: 'AMG MEDICAL INC - AMG',
  value: 'AMG',
}, {
  text: 'AMGEN - AMN',
  value: 'AMN',
}, {
  text: 'AMO CANADA COMPANY - AMO',
  value: 'AMO',
}, {
  text: 'APOBIOLOGIX A DIV OF APOTEX - APB',
  value: 'APB',
}, {
  text: 'APOTEX INC - APX',
  value: 'APX',
}, {
  text: 'APOTHECUS PHARMACEUTICAL CORP - APT',
  value: 'APT',
}, {
  text: 'ASPEN PHARMACARE CANADA INC - APA',
  value: 'APA',
}, {
  text: 'ATKINS NUTRITIONALS INC - ATK',
  value: 'ATK',
}, {
  text: 'AURIUM PHARMA INC (OTC) - AUR',
  value: 'AUR',
}, {
  text: 'AURO PHARMA INC - AUP',
  value: 'AUP',
}, {
  text: 'AVARIA HEALTH BEAUTY CORP - AVB',
  value: 'AVB',
}, {
  text: 'AVIR PHARMA INC - AVR',
  value: 'AVR',
}, {
  text: 'BASIC RESEARCH LLC - BRL',
  value: 'BRL',
}, {
  text: 'BAUSCH + LOMB CORP(PHARM DIV) - BAL',
  value: 'BAL',
}, {
  text: 'BAUSCH HEALTH CANADA INC. - BAU',
  value: 'BAU',
}, {
  text: 'BAUSCH LOMB CANADA (OTC) - BSH',
  value: 'BSH',
}, {
  text: 'BAXTER CORP - BXT',
  value: 'BXT',
}, {
  text: 'BAYER INC (HEALTHCARE) - BAH',
  value: 'BAH',
}, {
  text: 'BAYER INC (OTC) - BAY',
  value: 'BAY',
}, {
  text: 'BECTON DICKINSON CANADA INC - BDI',
  value: 'BDI',
}, {
  text: 'BERRY PLASTICS GROUP INC - BEY',
  value: 'BEY',
}, {
  text: 'BGP PHARMA ULC - BGP',
  value: 'BGP',
}, {
  text: 'BGP PHARMA ULC (NARC) - ABB',
  value: 'ABB',
}, {
  text: 'BIO K PLUS INTERNATIONAL INC - BKI',
  value: 'BKI',
}, {
  text: 'BIOGEN IDEC CANADA INC(910280) - BOG',
  value: 'BOG',
}, {
  text: 'BIOSYENT PHARMA INC - BPI',
  value: 'BPI',
}, {
  text: 'BLISTEX CORPORATION - BLS',
  value: 'BLS',
}, {
  text: 'BODY PLUS NUTRITIONAL PRODUCTS - BPN',
  value: 'BPN',
}, {
  text: 'BOEHRINGER INGELHEIM CAN LTD - BOE',
  value: 'BOE',
}, {
  text: 'BRISTOL MYERS SQUIBB CA(PHARM) - BQU',
  value: 'BQU',
}, {
  text: 'BSN MEDICAL INC - BSN',
  value: 'BSN',
}, {
  text: 'C.B. POWELL LIMITED - CBW',
  value: 'CBW',
}, {
  text: "CANUS GOAT'S MILK SC PROD INC - CIE",
  value: 'CIE',
}, {
  text: 'CARD HEALTH CARE INC - CHC',
  value: 'CHC',
}, {
  text: 'CELOPHARMA INC. - CEI',
  value: 'CEI',
}, {
  text: 'CENTURA BRANDS INC - CTU',
  value: 'CTU',
}, {
  text: 'CFANDR SERVICES(LOTUS TROLLEY) - CFR',
  value: 'CFR',
}, {
  text: 'CHIFAM - CFM',
  value: 'CFM',
}, {
  text: 'CHURCH & DWIGHT CANADA (OTC) - CDC',
  value: 'CDC',
}, {
  text: 'CHURCH & DWIGHT LTD (CONS) - CDW',
  value: 'CDW',
}, {
  text: 'CIPHER PHARMA INC. (MSD) - CPJ',
  value: 'CPJ',
}, {
  text: 'CLAIMS ACCOUNT - A/P - CAP',
  value: 'CAP',
}, {
  text: 'CONVATEC - CON',
  value: 'CON',
}, {
  text: 'DERMTEK PHARMACEUTICALS LTD - DPT',
  value: 'DPT',
}, {
  text: 'DISTRIPHARM INC - DPI',
  value: 'DPI',
}, {
  text: 'DOMREX PHARMA INC - DRX',
  value: 'DRX',
}, {
  text: 'DURACELL CANADA INC. - DUL',
  value: 'DUL',
}, {
  text: 'DURACELL CANADA INC. - DUR',
  value: 'DUR',
}, {
  text: 'E.T. BROWNE DRUG CO INC - ETL',
  value: 'ETL',
}, {
  text: 'EDGEWELL PER.C.CN.(DIV P/CARE) - EDW',
  value: 'EDW',
}, {
  text: 'ENERGIZER CAN INC (BATTERY) - ENR',
  value: 'ENR',
}, {
  text: 'ERFA CDA 2012 INC (MSD) - ERF',
  value: 'ERF',
}, {
  text: 'EXZELL PHARMA INC - EXZ',
  value: 'EXZ',
}, {
  text: 'FARLEYCO MARKETING INC (REX) - FAR',
  value: 'FAR',
}, {
  text: 'FERRERO CANADA LTD - FRR',
  value: 'FRR',
}, {
  text: 'FERRING INC - FEI',
  value: 'FEI',
}, {
  text: 'FIRST STEP HEALTH SOLUTIONS IN - FST',
  value: 'FST',
}, {
  text: 'FLOW WATER INC - FLW',
  value: 'FLW',
}, {
  text: 'FORMEDICA LTEE/LTD - FMD',
  value: 'FMD',
}, {
  text: 'FRESENIUS KABI CANADA LTD - FKC',
  value: 'FKC',
}, {
  text: 'FRONTIER NATURAL PRODUCT COOP - FNP',
  value: 'FNP',
}, {
  text: 'GALENOVA INC (NARC) - GNI',
  value: 'GNI',
}, {
  text: 'GENERIC MEDICAL PARTNERS INC - GMP',
  value: 'GMP',
}, {
  text: "GEORGE'S CREAM INC - GCM",
  value: 'GCM',
}, {
  text: 'GOOD CLEAN LOVE LLC. - GCL',
  value: 'GCL',
}, {
  text: 'HERITAGE HOME FASHIONS INC - HHF',
  value: 'HHF',
}, {
  text: 'HERSHEY CANADA INC - HER',
  value: 'HER',
}, {
  text: 'HFC PRESTIGE INTL CAN (COSM) - HFP',
  value: 'HFP',
}, {
  text: 'HILL DERMACEUTICALS INC - HID',
  value: 'HID',
}, {
  text: 'HLS THERAPEUTICS - HLS',
  value: 'HLS',
}, {
  text: 'HOFFMANN LA ROCHE LIMITED - HLR',
  value: 'HLR',
}, {
  text: 'HOMEDICS GROUP CANADA CO - HOM',
  value: 'HOM',
}, {
  text: 'HPI HEALTH PRODUCTS INC - HPI',
  value: 'HPI',
}, {
  text: 'IDELLE LABS LTD BOX 911340 - ILL',
  value: 'ILL',
}, {
  text: 'INDIVIOR UK - IDV',
  value: 'IDV',
}, {
  text: "IOVATE HLTH SCIENCES INT'L INC - IHS",
  value: 'IHS',
}, {
  text: 'IPSEN BIOPHARMACEUTICALS CAN - IPS',
  value: 'IPS',
}, {
  text: 'IRVING CONSUMER PRODUCTS LTD - IRT',
  value: 'IRT',
}, {
  text: 'ISLAND ABBEY FOODS SCIENCE LTD - IAF',
  value: 'IAF',
}, {
  text: 'ITWAL LIMITED - KLG',
  value: 'KLG',
}, {
  text: 'ITWAL TLS VAN MELLE EDI - VNL',
  value: 'VNL',
}, {
  text: 'JAMIESON LABORATORIES LTD - JAM',
  value: 'JAM',
}, {
  text: 'JANSSEN INC - JNO',
  value: 'JNO',
}, {
  text: 'JAZZ PHARMACEUTICALS CANADA - JAZ',
  value: 'JAZ',
}, {
  text: 'JONES PACKAGING INC - JON',
  value: 'JON',
}, {
  text: 'JP MORGAN CHASE LCKBX(E LILLY) - LIL',
  value: 'LIL',
}, {
  text: 'JUNO PHARMACEUTICALS CORP - MDA',
  value: 'MDA',
}, {
  text: 'KAO CANADA INC - KAO',
  value: 'KAO',
}, {
  text: 'KEGO CORPORATION - KEG',
  value: 'KEG',
}, {
  text: 'KERR BROS LTD - KER',
  value: 'KER',
}, {
  text: 'KIMBERLY CLARK OF CAN LTD - KCL',
  value: 'KCL',
}, {
  text: 'KNIGHT THERAPEUTICS INC. - KNT',
  value: 'KNT',
}, {
  text: 'LANDMARK MEDICAL SYSTEMS INC - LMI',
  value: 'LMI',
}, {
  text: 'LEO PHARMA INC - LEO',
  value: 'LEO',
}, {
  text: 'LES LABORATOIRES NICAR INC - LNI',
  value: 'LNI',
}, {
  text: 'LIFE SCIENCE NUTRITIONALS INC - LSN',
  value: 'LSN',
}, {
  text: 'LINDT SPRUNGLI (CANADA) INC - LSC',
  value: 'LSC',
}, {
  text: 'LIVCORP INC - LVC',
  value: 'LVC',
}, {
  text: 'LUMINARIE CANADA INC (MSD) - LUC',
  value: 'LUC',
}, {
  text: 'MADD GEAR INC. - MDG',
  value: 'MDG',
}, {
  text: 'MANTRA PHARMA INC - MTP',
  value: 'MTP',
}, {
  text: 'MARC ANTHONY COSMETICS LTD - MCA',
  value: 'MCA',
}, {
  text: 'MARCAN PHARMACEUTICALS INC. - MRC',
  value: 'MRC',
}, {
  text: 'MASDEL INC - GMI',
  value: 'GMI',
}, {
  text: 'MCCAUGHEY CONS PROD MGNT CONS - MCP',
  value: 'MCP',
}, {
  text: 'MCK SPEC DIST (GLENWOOD) - MGL',
  value: 'MGL',
}, {
  text: 'MCKESSON SPEC DIST(ERFA)NARC - MSF',
  value: 'MSF',
}, {
  text: 'MEAD JOHNSON CANADA - MJS',
  value: 'MJS',
}, {
  text: 'MEDELA CANADA INC - MEE',
  value: 'MEE',
}, {
  text: 'MEDICAL MART SUPPLIES LTD - MMS',
  value: 'MMS',
}, {
  text: 'MEDLINE CANADA CORPORATION - MDC',
  value: 'MDC',
}, {
  text: 'MEDTRONIC OF CANADA LTD B9233 - MED',
  value: 'MED',
}, {
  text: 'MERCK CANADA - MSD',
  value: 'MSD',
}, {
  text: 'MERZ PHARMA CANADA LTD (DRP) - MER',
  value: 'MER',
}, {
  text: 'METHAPHARM INC - MPA',
  value: 'MPA',
}, {
  text: 'METHOD PRODUCTS PBC - MET',
  value: 'MET',
}, {
  text: 'MONDELEZ CANADA INC - CAD',
  value: 'CAD',
}, {
  text: 'MSD/CHEPLAPHARM (MSD) - GDD',
  value: 'GDD',
}, {
  text: 'MSD/KALEO INC (MSD) - KAL',
  value: 'KAL',
}, {
  text: 'NEAL BROTHERS INC - NEA',
  value: 'NEA',
}, {
  text: 'NIC HIT INTERNATIONAL INC. - NIH',
  value: 'NIH',
}, {
  text: 'NO. 2010162 ONTARIO LTD(HYDES) - ZIP',
  value: 'ZIP',
}, {
  text: 'NO.3033911 CAN INC O/A IMPLUS - SPN',
  value: 'SPN',
}, {
  text: 'NORA PHARMA INC - NRI',
  value: 'NRI',
}, {
  text: 'NOVARTIS PHARMA CAN INC - NPC',
  value: 'NPC',
}, {
  text: 'NOVO NORDISK CANADA INC - NNM',
  value: 'NNM',
}, {
  text: 'NUTRITION PLUS PROD.INC. - NPD',
  value: 'NPD',
}, {
  text: 'ORGANON CANADA INC. - ORN',
  value: 'ORN',
}, {
  text: 'PALADIN LABS INC LOCKBOX210370 - PLI',
  value: 'PLI',
}, {
  text: 'PENDOPHARM DIV PHARMASCIENCE - PPI',
  value: 'PPI',
}, {
  text: 'PEPPER PINK INC. - PPP',
  value: 'PPP',
}, {
  text: 'PETITE CREATIONS - AII',
  value: 'AII',
}, {
  text: 'PFIZER CANADA ULC. - PFC',
  value: 'PFC',
}, {
  text: 'PFIZER CANADA ULC. - PFI',
  value: 'PFI',
}, {
  text: 'PHARMAPAR INC - PHP',
  value: 'PHP',
}, {
  text: 'PHARMASCIENCE CANADA (ATM) - PMS',
  value: 'PMS',
}, {
  text: 'PHEBRA CANADA INC - PHE',
  value: 'PHE',
}, {
  text: 'PRESTILUX INC - PLX',
  value: 'PLX',
}, {
  text: 'PRODUITS KRUGER S.E.C. - SCO',
  value: 'SCO',
}, {
  text: 'PURDUE PHARMA - PFR',
  value: 'PFR',
}, {
  text: 'PURITY LIFE HEALTH PROD LTD - PLH',
  value: 'PLH',
}, {
  text: 'RADION LABS DBA BOWERS - RDN',
  value: 'RDN',
}, {
  text: 'REHA ENTERPRISES LTD - RHA',
  value: 'RHA',
}, {
  text: 'REVLON (HABA) A/S DE T46101 - REV',
  value: 'REV',
}, {
  text: 'REYNOLDS CONS PROD CAN INC - NVL',
  value: 'NVL',
}, {
  text: 'RGR PHARMA LTD - RPL',
  value: 'RPL',
}, {
  text: 'RHINOSYSTEMS INC - RHN',
  value: 'RHN',
}, {
  text: 'SANDOZ CANADA INC - SDZ',
  value: 'SDZ',
}, {
  text: 'SANOFI CONSUMER HEALTH INC - CDX',
  value: 'CDX',
}, {
  text: 'SANTE NATURELLE LTEE - SNA',
  value: 'SNA',
}, {
  text: 'SC JOHNSON AND SONS LTD - SCJ',
  value: 'SCJ',
}, {
  text: 'SEAFORD PHARMACEUTICALS INC - SEP',
  value: 'SEP',
}, {
  text: 'SELECT BRAND DISTRIBUTORS INC - SBD',
  value: 'SBD',
}, {
  text: 'SHANDEX SALES GROUP LTD - SSG',
  value: 'SSG',
}, {
  text: 'SHIRE CANADA INC - SCI',
  value: 'SCI',
}, {
  text: 'SPECIALTY HEALTH NETWORK INC. - SHN',
  value: 'SHN',
}, {
  text: 'STALLERGENES CANADA INC (MSD) - STG',
  value: 'STG',
}, {
  text: 'STAR BRANDS CANADA INC - STB',
  value: 'STB',
}, {
  text: 'STERI MED PHARMA (FINACCES) - SRG',
  value: 'SRG',
}, {
  text: 'STERIMAX INC - SMI',
  value: 'SMI',
}, {
  text: 'STIEFEL CANADA INC(OTC) - STI',
  value: 'STI',
}, {
  text: 'SUNSTAR AMERICAS INC (RX) - SUS',
  value: 'SUS',
}, {
  text: 'TAKEDA - NYC',
  value: 'NYC',
}, {
  text: 'TARO PHARMACEUTICALS INC - TAR',
  value: 'TAR',
}, {
  text: 'TELIGENT CANADA INC. (NARC) - TEL',
  value: 'TEL',
}, {
  text: 'TERSERA THERAPEUTICS LLC - TRT',
  value: 'TRT',
}, {
  text: 'TEVA CANADA LTD T46288 - TEV',
  value: 'TEV',
}, {
  text: 'THAI UNITED FOOD TRADING LTD - TUF',
  value: 'TUF',
}, {
  text: 'THEA PHARMA INC(LAB THEA)(MSD) - THA',
  value: 'THA',
}, {
  text: 'TOLMAR PHARMACEUTIQUES CAN INC - TOL',
  value: 'TOL',
}, {
  text: "TOSUTA INT'L MARKETING (OTC) - TOM",
  value: 'TOM',
}, {
  text: 'TRAINERS CHOICE INC - TCI',
  value: 'TCI',
}, {
  text: 'TREE OF LIFE CANADA ULC - TLG',
  value: 'TLG',
}, {
  text: 'TRIMEDIC SUPPLY NETWORK LTD - TSN',
  value: 'TSN',
}, {
  text: 'TRITON PHARMA INC - TPH',
  value: 'TPH',
}, {
  text: 'TRUDELL MEDICAL INTERNATIONAL - TRU',
  value: 'TRU',
}, {
  text: 'TWININGS NORTH AMERICA INC - TNI',
  value: 'TNI',
}, {
  text: 'U.S. COTTON (CANADA) CO - USC',
  value: 'USC',
}, {
  text: 'UNILEVER CANADA M11115C - ULC',
  value: 'ULC',
}, {
  text: 'VALNEVA CAN INC - VAV',
  value: 'VAV',
}, {
  text: 'VAN DE WATER RAYMOND (MILUPA) - VDW',
  value: 'VDW',
}, {
  text: 'VERITY PHARMACEUTICALS INC - VRY',
  value: 'VRY',
}, {
  text: 'VIIV HEALTHCARE ULC - VHI',
  value: 'VHI',
}, {
  text: 'VINTAGE BRANDS LTD - VBL',
  value: 'VBL',
}, {
  text: 'VISION PRODUCTS INC - VPI',
  value: 'VPI',
}, {
  text: 'VIVUS PHARMACEUTICALS LIMITED - VIV',
  value: 'VIV',
}, {
  text: 'VOLO HEALTHCARE INC. - VOH',
  value: 'VOH',
}, {
  text: 'WATER PIK INC - WPC',
  value: 'WPC',
}, {
  text: 'WCN BIO TECH INC - WCN',
  value: 'WCN',
}, {
  text: 'WELLNX LIFE SCIENCES INC - NXC',
  value: 'NXC',
}, {
  text: 'WRIGLEY CANADA - WRG',
  value: 'WRG',
}, {
  text: 'ZWILLING J.A. HENCKELS CANADA - ZJA',
  value: 'ZJA',
}]
