export const rebateColumns = [
  {
    id: 'AAA',
    value: '1',
  },
  {
    id: 'ABB',
    value: null,
  },
  {
    id: 'ABU',
    value: null,
  },
  {
    id: 'ACE',
    value: null,
  },
  {
    id: 'ACU',
    value: null,
  },
  {
    id: 'AHC',
    value: '1',
  },
  {
    id: 'ALL',
    value: null,
  },
  {
    id: 'AMN',
    value: null,
  },
  {
    id: 'ANB',
    value: null,
  },
  {
    id: 'APE',
    value: null,
  },
  {
    id: 'APR',
    value: null,
  },
  {
    id: 'APX',
    value: '1',
  },
  {
    id: 'ASP',
    value: null,
  },
  {
    id: 'AST',
    value: null,
  },
  {
    id: 'AUP',
    value: '10',
  },
  {
    id: 'AUR',
    value: null,
  },
  {
    id: 'BAH',
    value: null,
  },
  {
    id: 'BAU',
    value: null,
  },
  {
    id: 'BAY',
    value: null,
  },
  {
    id: 'BGP',
    value: null,
  },
  {
    id: 'BOE',
    value: null,
  },
  {
    id: 'BQU',
    value: null,
  },
  {
    id: 'DRD',
    value: null,
  },
  {
    id: 'ELV',
    value: null,
  },
  {
    id: 'ERF',
    value: null,
  },
  {
    id: 'FEI',
    value: null,
  },
  {
    id: 'FKC',
    value: null,
  },
  {
    id: 'GAC',
    value: null,
  },
  {
    id: 'GLN',
    value: null,
  },
  {
    id: 'GMP',
    value: null,
  },
  {
    id: 'GSI',
    value: null,
  },
  {
    id: 'GSK',
    value: null,
  },
  {
    id: 'HLR',
    value: null,
  },
  {
    id: 'JAP',
    value: '1',
  },
  {
    id: 'JJC',
    value: null,
  },
  {
    id: 'JNO',
    value: null,
  },
  {
    id: 'LEO',
    value: null,
  },
  {
    id: 'LIL',
    value: null,
  },
  {
    id: 'LUN',
    value: null,
  },
  {
    id: 'MKS',
    value: null,
  },
  {
    id: 'MPA',
    value: null,
  },
  {
    id: 'MPH',
    value: '1',
  },
  {
    id: 'MRC',
    value: '1',
  },
  {
    id: 'MTP',
    value: null,
  },
  {
    id: 'MYL',
    value: null,
  },
  {
    id: 'NPC',
    value: null,
  },
  {
    id: 'NRI',
    value: '1',
  },
  {
    id: 'NTC',
    value: null,
  },
  {
    id: 'NYC',
    value: null,
  },
  {
    id: 'ODN',
    value: null,
  },
  {
    id: 'OPH',
    value: null,
  },
  {
    id: 'ORN',
    value: null,
  },
  {
    id: 'OTS',
    value: null,
  },
  {
    id: 'PED',
    value: null,
  },
  {
    id: 'PFC',
    value: null,
  },
  {
    id: 'PFI',
    value: null,
  },
  {
    id: 'PFR',
    value: null,
  },
  {
    id: 'PHP',
    value: null,
  },
  {
    id: 'PLI',
    value: null,
  },
  {
    id: 'PMS',
    value: '50',
  },
  {
    id: 'PPI',
    value: null,
  },
  {
    id: 'RAN',
    value: '1',
  },
  {
    id: 'RIV',
    value: null,
  },
  {
    id: 'SAC',
    value: null,
  },
  {
    id: 'SDZ',
    value: '1',
  },
  {
    id: 'SEV',
    value: null,
  },
  {
    id: 'SGP',
    value: null,
  },
  {
    id: 'SIP',
    value: null,
  },
  {
    id: 'SIV',
    value: null,
  },
  {
    id: 'SMI',
    value: null,
  },
  {
    id: 'SNS',
    value: null,
  },
  {
    id: 'SPL',
    value: null,
  },
  {
    id: 'SRG',
    value: null,
  },
  {
    id: 'STB',
    value: null,
  },
  {
    id: 'STI',
    value: null,
  },
  {
    id: 'TAR',
    value: '1',
  },
  {
    id: 'TEL',
    value: null,
  },
  {
    id: 'TEV',
    value: '1',
  },
  {
    id: 'UCB',
    value: null,
  },
  {
    id: 'UPJ',
    value: null,
  },
  {
    id: 'XPT',
    value: null,
  },
]
