export const rebateValuesData = {
  _id: '61f1d0d0998f436287982912',
  pharmacyID: '5b539d500d356d3568fddad2',
  MFRs: {
    AAA: '1', ABB: null, ACE: null, ACU: null, AHC: '1', ALL: null, AMN: null, ANB: null, APE: null, APR: null, APX: '1', ASP: null, AST: null, AUP: null, AUR: null, BAH: null, BAU: null, BAY: null, BGP: null, BOE: null, BQU: null, DRD: null, ELV: null, ERF: null, FEI: null, FKC: null, GAC: null, GLN: null, GMP: null, GSI: null, GSK: null, HLR: null, JAP: '1', JJC: null, JNO: null, LIL: null, LUN: null, MKS: null, MPA: null, MPH: '1', MRC: '1', MTP: null, MYL: null, NPC: null, NRI: '1', NTC: null, NYC: null, ODN: null, OPH: null, ORN: null, OTS: null, PFC: null, PFI: null, PFR: null, PHP: null, PLI: null, PMS: '50', PPI: null, RAN: '1', RIV: null, SAC: null, SDZ: '1', SEV: null, SGP: null, SIP: null, SIV: null, SMI: null, SNS: null, SRG: null, STB: null, STI: null, TAR: '1', TEL: null, TEV: '1', UCB: null, UPJ: null, XPT: null, SPL: null, ABU: null,
  },
  __v: 0,
  createdAt: '2022-01-26T22:53:03.955Z',
  groupCodes: {
    177: { APX: 11 },
    263: { APX: '30', JAP: '30' },
    265: { APX: '30', JAP: '30' },
    266: {
      APX: '60', PMS: '45', SDZ: '1', TEV: '60',
    },
    284: { APX: '20', JAP: '25', MPH: '25' },
    286: { APX: '20', JAP: '25', MPH: '25' },
    287: { APX: '20', JAP: '25', MPH: '25' },
    301: { APX: '40', PMS: '45', TEV: '55' },
    302: { APX: '40', PMS: '45', TEV: '55' },
    303: { APX: '40', PMS: '45', TEV: '55' },
    304: { APX: '40', PMS: '45', TEV: '55' },
    390: { APX: '40', RAN: '67', TEV: '50' },
    391: { APX: '40', TEV: '50' },
    393: { APX: '40', TEV: '50' },
    1194: { JAP: '20' },
    1774: { APX: '20', TEV: '1' },
    1775: { APX: '20', TEV: '1' },
    2184: { TAR: '42' },
    2185: { TAR: '42' },
    2535: { APX: '20', MRC: '25', AAA: '10' },
    2536: { APX: '20', MRC: '25', AAA: null },
    2538: { APX: '10', TEV: '10' },
    2766: { APX: '20' },
    3009: { TAR: '56' },
    3271: { TEV: '1' },
    3757: { APX: '30', PMS: '1', TEV: '1' },
    3758: { APX: '30', PMS: '1', TEV: '1' },
    3759: { APX: '30' },
    3772: { APX: '10' },
    3773: { APX: '40', JAP: '1', TEV: '1' },
    3774: { APX: '40', JAP: '1', TEV: '1' },
    3775: { APX: '40' },
    3781: {
      APX: '50', JAP: '65', MPH: '1', PMS: '1', TEV: '1',
    },
    4000: { APX: '10' },
    4001: { APX: '10' },
    4109: { APX: '20', PMS: '35' },
    4110: { APX: '20', PMS: '35' },
    4112: { APX: '20', PMS: '35' },
    4222: { APX: '51', SDZ: '1', TEV: '1' },
    4225: { PMS: '1', SDZ: '1' },
    4489: { APX: '10' },
    4490: { APX: '10', JAP: '1', TEV: '1' },
    4518: { JAP: '25', APX: '40' },
    4536: { APX: '10', PMS: '15' },
    4538: { APX: '10' },
    4539: { APX: '10' },
    4540: { APX: '10' },
    4558: { TAR: '52', TEV: '40' },
    4679: { APX: '50', PMS: '60' },
    4681: { APX: '65' },
    4929: { APX: '20', PMS: '40', TEV: '1' },
    5098: { APX: '10', MPH: '1' },
    5099: { APX: '10', MPH: '1' },
    5131: {
      APX: '54', JAP: '70', PMS: '60', TEV: '60',
    },
    5132: {
      APX: '54', JAP: '70', PMS: '60', TEV: '60',
    },
    5138: {
      APX: '64', JAP: '50', MRC: '58', MPH: '55', PMS: '65', RAN: '62', TEV: '60',
    },
    5139: {
      APX: '64', JAP: '50', MRC: '58', MPH: '55', PMS: '72', RAN: '62', TEV: '60',
    },
    6506: { JAP: '40' },
    6561: { APX: '20', TAR: '1' },
    6675: { JAP: '20', MRC: '0' },
    6753: { TEV: '1' },
    6754: { TEV: '1' },
    6814: { TEV: '1' },
    6816: { MPH: '40', TEV: '15' },
    6817: { MPH: '40', TEV: '15' },
    7008: { TAR: '12' },
    7334: { TAR: '12' },
    7572: { TAR: '52', TEV: '1' },
    7573: { TAR: '52' },
    7574: { TAR: '52', TEV: '1' },
    7631: { TAR: '47' },
    7670: { APX: '10' },
    7856: { APX: '5', JAP: '40', SDZ: '1' },
    7894: { SDZ: '1', TEV: '1' },
    8166: { AAA: '20' },
    8182: {
      APX: '10', MPH: '40', PMS: '35', TEV: '0',
    },
    8208: { APX: '10', MPH: '50', TEV: '0' },
    8209: { APX: '10', MPH: '50', TEV: '0' },
    8224: { APX: '20' },
    8227: { AAA: '20' },
    8334: { PMS: '50', SDZ: '45', JAP: '25' },
    8336: { MPH: '20', TEV: '40' },
    8349: { APX: '10' },
    8361: { APX: '10' },
    8362: { APX: '10' },
    8798: { AHC: '80' },
    8799: { APX: '20', PMS: '20', AHC: '1' },
    8992: { JAP: '65', SDZ: '1' },
    8995: { APX: '40', JAP: '55', TEV: '1' },
    8996: {
      APX: '40', JAP: '55', PMS: '50', TEV: '1',
    },
    9048: { APX: '0', TEV: '1' },
    9049: { APX: '0', TEV: '1' },
    9223: { APX: '40' },
    9339: { NRI: '62', JAP: '40' },
    9340: { NRI: '62', JAP: '40' },
    9509: {
      JAP: '50', MRC: '58', MPH: '45', PMS: '65', SDZ: '60', RAN: '77', TEV: '1',
    },
    9510: {
      JAP: '50', MRC: '58', MPH: '45', PMS: '65', SDZ: '60', RAN: '77', TEV: '1',
    },
    9537: { JAP: '20' },
    9580: { APX: '20', JAP: '25', MPH: '0' },
    11677: { JAP: '30' },
    11678: { JAP: '30' },
    11682: { APX: '20', TEV: '1' },
    11823: { APX: '40', TEV: '55' },
    11824: { APX: '40', TEV: '55' },
    11861: { AAA: '1' },
    11862: { AAA: '1' },
    11863: { APX: '10' },
    11922: { TAR: '52', TEV: '1' },
    11923: { TAR: '52', PMS: '1' },
    13173: { APX: '20', MRC: '25', AAA: null },
    13318: {
      JAP: '25', TEV: '25', PMS: '30', SDZ: '30', AUP: '0',
    },
    13574: { TEV: '1', APX: '20' },
    13725: { APX: '61', PMS: '1', TEV: '1' },
    14198: { APX: '20', TAR: '1' },
    15584: { JAP: '55', APX: '40' },
    15585: { JAP: '55', APX: '40' },
    15586: { JAP: '55', APX: '40' },
    15695: { APX: '1', TEV: '10' },
    15864: {
      JAP: '50', MRC: '58', MPH: '55', PMS: '72', RAN: '62', TEV: '60',
    },
    15939: {
      APX: '55', JAP: '40', PMS: '50', RAN: '67',
    },
    15940: {
      APX: '55', JAP: '40', MRC: '40', MPH: '55', PMS: '50', RAN: '67', TEV: '55',
    },
    15941: {
      APX: '55', JAP: '40', MRC: '40', MPH: '55', PMS: '50', RAN: '67', TEV: '55',
    },
    16017: { APX: '20', TEV: '25' },
    16018: { APX: '20', TEV: '35' },
    16031: {
      APX: '55', JAP: '40', MRC: '40', MPH: '55', PMS: '50', RAN: '67', TEV: '55',
    },
    16043: { AAA: '1' },
    16295: { APX: '40', SDZ: '1' },
    16296: { APX: '40', SDZ: '1' },
    16366: {
      APX: '69', JAP: '50', MPH: '50', SDZ: '50', RAN: '67', TEV: '1', AHC: '66',
    },
    16367: {
      APX: '69', JAP: '50', MPH: '50', SDZ: '55', PMS: '62', RAN: '67', TEV: '1', AHC: '66',
    },
    16373: { APX: '54' },
    16393: {
      APX: '70', JAP: '50', MRC: '62', MPH: '60', PMS: '78', SDZ: '50', TEV: '1',
    },
    16404: {
      APX: '30', JAP: '40', MRC: '25', MPH: '55', AAA: '1',
    },
    16441: {
      JAP: '50', PMS: '25', SDZ: '50', TEV: '1',
    },
    16576: {
      APX: '67', JAP: '65', MPH: '65', PMS: '55', RAN: '77', TEV: '35',
    },
    16577: {
      APX: '67', JAP: '65', MRC: '57', MPH: '65', PMS: '55', RAN: '77', TEV: '35',
    },
    16578: {
      APX: '67', JAP: '65', MPH: '65', PMS: '55', RAN: '77', TEV: '35',
    },
    16579: {
      APX: '67', JAP: '65', MPH: '65', PMS: '55', RAN: '77', TEV: '35',
    },
    16773: { SDZ: '15' },
    16777: { APX: '20' },
    16926: {
      APX: '75', JAP: '70', MRC: '60', MPH: '60', PMS: '75', SDZ: '74', RAN: '77', TEV: '75', AHC: '65', NRI: '70',
    },
    16927: {
      APX: '75', JAP: '70', MRC: '60', MPH: '60', PMS: '75', SDZ: '74', RAN: '77', TEV: '75', AHC: '65', NRI: '70',
    },
    16995: { PMS: '1', JAP: '41' },
    17027: { TEV: '1' },
    17029: {
      APX: '70', JAP: '55', MRC: '60', MPH: '75', PMS: '75', TEV: '70',
    },
    17117: { APX: '20', MRC: '25' },
    17129: { APX: '50', PMS: '70', TEV: '1' },
    17135: { JAP: '25' },
    17196: { APX: '20', PMS: '35' },
    17273: { APX: '20' },
    17302: { PMS: '54', TEV: '55' },
    17549: { TAR: '52', PMS: '1' },
    17616: { APX: '40' },
    17871: { APX: '52', PMS: '1' },
    17872: { APX: '52', PMS: '1' },
    17955: {
      APX: '41', TEV: '55', MPH: '40', SDZ: '66',
    },
    17956: {
      APX: '41', MPH: '40', SDZ: '66', TEV: '55',
    },
    18368: { APX: '35', TEV: '1' },
    18434: { APX: '10' },
    18436: { APX: '10' },
    18632: { APX: '61', JAP: '30', MRC: '1' },
    19067: { AAA: '1' },
    19293: {
      APX: '54', JAP: '0', PMS: '65', TEV: '15',
    },
    19412: { APX: '20', JAP: '1' },
    19879: { APX: '40' },
    20242: { MRC: '1' },
    20741: {
      APX: '69', JAP: '50', MPH: '50', PMS: '62', RAN: '67', SDZ: '55', AHC: '66',
    },
    21156: {
      APX: '64', JAP: '50', MPH: '55', PMS: '72', SDZ: '60', RAN: '62', TEV: '1',
    },
    21413: {
      APX: '45', JAP: '40', PMS: '45', TEV: '60', AHC: '68',
    },
    21414: {
      APX: '45', JAP: '40', PMS: '45', TEV: '60', AHC: '68',
    },
    21415: {
      APX: '45', JAP: '40', PMS: '45', TEV: '60', AHC: '68',
    },
    21694: { TEV: '1' },
    21731: { JAP: '20' },
    21844: { PMS: '45', SDZ: '30' },
    21995: { APX: '40' },
    22233: {
      APX: '54', JAP: '0', PMS: '65', TEV: '15',
    },
    22479: { APX: '50', PMS: '70', TEV: '1' },
    23381: {
      APX: '62', JAP: '60', MPH: '10', PMS: '60', SDZ: '40', TEV: '70',
    },
    23465: {
      JAP: '57', MPH: '10', PMS: '60', SDZ: '40',
    },
    23513: { SDZ: '5', JAP: '30' },
    23989: {
      APX: '50', JAP: '1', PMS: '52', SDZ: '42', TEV: '1',
    },
    24457: { PMS: '1' },
    24505: { APX: '51', PMS: '55' },
    24515: {
      APX: '68', JAP: '1', MRC: '1', MPH: '55', PMS: '50', SDZ: '65', TAR: '60', TEV: '1', AHC: '66',
    },
    24536: { JAP: '50' },
    24537: { JAP: '50' },
    24538: { JAP: '50' },
    24668: { JAP: '65', SDZ: '1' },
    25703: {
      JAP: '50', PMS: '1', TEV: '1', AHC: '66',
    },
    25738: { APX: '10', TEV: '1' },
    25848: {
      APX: '40', JAP: '40', MRC: '60', TEV: '1',
    },
    26170: {
      APX: '65', JAP: '55', MPH: '55', PMS: '68', SDZ: '55', TEV: '70',
    },
    26171: {
      APX: '65', JAP: '55', PMS: '68', SDZ: '55', TEV: '70',
    },
    26721: {
      APX: '62', JAP: '55', PMS: '55', SDZ: '55', TEV: '1',
    },
    27370: { SDZ: '40', APX: '40' },
    27462: {
      APX: '65', JAP: '55', MRC: '53', MPH: '10', PMS: '70', SDZ: '68', RAN: '67', TEV: '65',
    },
    27546: { SDZ: '30' },
    27882: { SDZ: '1' },
    27961: {
      APX: '70', JAP: '65', PMS: '65', MPH: '70', SDZ: '55', TEV: '70',
    },
    28108: {
      APX: '54', JAP: '0', PMS: '65', TEV: '15',
    },
    28109: {
      APX: '54', JAP: '0', PMS: '65', TEV: '15',
    },
    28754: { APX: '35', PMS: '25' },
    28915: { APX: '10' },
    29054: { JAP: '30', PMS: '20', TEV: '0' },
    29077: {
      APX: '70', JAP: '65', PMS: '65', MPH: '70', SDZ: '55', TEV: '70',
    },
    29156: { APX: '70', PMS: '68', TEV: '70' },
    29157: { APX: '70', PMS: '68', TEV: '70' },
    29334: {
      AHC: '66', APX: '72', JAP: '70', MRC: '63', MPH: '70', PMS: '65', SDZ: '60', RAN: '1', TAR: '1', TEV: '65',
    },
    29335: {
      AHC: '66', APX: '72', JAP: '70', MRC: '63', MPH: '70', PMS: '65', SDZ: '60', TAR: '1', TEV: '65',
    },
    29821: {
      APX: '69', JAP: '50', MRC: '60', MPH: '0', PMS: '66', SDZ: '65', TEV: '1', AHC: '66',
    },
    29837: {
      APX: '65', JAP: '55', MPH: '55', PMS: '68', SDZ: '55', TEV: '70',
    },
    29967: {
      APX: '70', JAP: '75', MRC: '60', MPH: '60', PMS: '75', SDZ: '67', RAN: '77', TEV: '67', AHC: '65',
    },
    29968: {
      APX: '70', JAP: '75', MRC: '60', MPH: '60', PMS: '75', SDZ: '67', RAN: '77', TEV: '67', AHC: '65',
    },
    29969: {
      APX: '70', JAP: '75', MRC: '60', MPH: '60', PMS: '75', SDZ: '67', RAN: '77', TEV: '67', AHC: '65',
    },
    30107: {
      APX: '70', PMS: '55', SDZ: '60', RAN: '54', TEV: '40',
    },
    30264: {
      APX: '70', JAP: '55', MRC: '60', MPH: '75', PMS: '75', TEV: '70',
    },
    30495: { APX: '40' },
    30607: { APX: '50', PMS: '40' },
    30796: {
      APX: '50', JAP: '65', MPH: '60', SDZ: '45',
    },
    30986: { APX: '20', TEV: '1' },
    31186: { APX: '50' },
    31782: { APX: '55', SDZ: '50', TEV: '1' },
    32492: {
      APX: '65', PMS: '50', SDZ: '70', TEV: '1',
    },
    32599: {
      APX: '52', JAP: '1', SDZ: '1', TEV: '30', AHC: '69',
    },
    33530: { APX: '70', SDZ: '0' },
    33722: {
      APX: '68', JAP: '75', MRC: '58', MPH: '60', PMS: '70', SDZ: '55', TEV: '70',
    },
    34187: {
      APX: '50', JAP: '70', MPH: '55', PMS: '42', TEV: '1', AHC: '66',
    },
    34188: {
      APX: '50', JAP: '70', MPH: '55', PMS: '42', TEV: '55', AHC: '66',
    },
    34189: {
      APX: '50', JAP: '70', MPH: '55', PMS: '42', TEV: '1', AHC: '66',
    },
    34468: {
      MPH: '25', PMS: '55', SDZ: '40', RAN: '77', TEV: '25',
    },
    34469: {
      MPH: '25', PMS: '55', SDZ: '40', RAN: '77', TEV: '25',
    },
    34470: {
      MPH: '25', PMS: '55', SDZ: '40', RAN: '77', TEV: '25',
    },
    34729: {
      APX: '50', JAP: '40', MRC: '1', RAN: '52', TEV: '60',
    },
    37015: {
      APX: '63', PMS: '50', SDZ: '50', RAN: '77',
    },
    37016: {
      APX: '63', JAP: '50', MPH: '10', PMS: '50', SDZ: '50', RAN: '77', TEV: '60', AHC: '65',
    },
    37017: {
      APX: '63', JAP: '50', MPH: '10', PMS: '50', SDZ: '50', RAN: '77', TEV: '60', AHC: '65',
    },
    37020: { APX: '20' },
    37050: { PMS: '1', SDZ: '1' },
    37219: { APX: '70' },
    38164: {
      APX: '62', JAP: '50', PMS: '60', RAN: '62', TEV: '70',
    },
    38451: {
      APX: '67', JAP: '65', MRC: '60', MPH: '60', PMS: '70', SDZ: '65', RAN: '82', TEV: '80', AHC: '63',
    },
    38686: {
      APX: '62', JAP: '60', MPH: '10', PMS: '60', SDZ: '50', TEV: '70',
    },
    39190: {
      APX: '71', JAP: '75', MPH: '75', PMS: '80', TEV: '80',
    },
    39191: {
      APX: '71', JAP: '75', MRC: '75', MPH: '60', PMS: '80', TEV: '80',
    },
    39531: { SDZ: '1', JAP: '60' },
    39545: {
      APX: '65', JAP: '55', SDZ: '68', TEV: '50',
    },
    40157: { JAP: '70' },
    40221: { APX: '60', JAP: '25' },
    40223: {
      JAP: '65', MRC: '63', PMS: '70', SDZ: '50', TEV: '1',
    },
    40549: { APX: '50', SDZ: '50', TEV: '65' },
    40550: { APX: '50', SDZ: '50', TEV: '1' },
    40659: {
      APX: '63', JAP: '50', PMS: '50', SDZ: '50', RAN: '77', TEV: '60', AHC: '65',
    },
    40910: {
      JAP: '65', MPH: '1', PMS: '45', SDZ: '45', TEV: '50', AHC: '70',
    },
    40911: {
      JAP: '65', MPH: '1', PMS: '45', SDZ: '45', TEV: '50', AHC: '70',
    },
    40923: {
      JAP: '57', MPH: '10', PMS: '60', SDZ: '40',
    },
    40941: { PMS: '45', SDZ: '35', RAN: '52' },
    40952: { PMS: '45', SDZ: '35', RAN: '52' },
    41234: { PMS: '35', SDZ: '45', TEV: '25' },
    41285: {
      APX: '50', JAP: '50', MRC: '52', MPH: '45', PMS: '67',
    },
    41286: {
      APX: '50', JAP: '50', MRC: '52', MPH: '45', PMS: '67',
    },
    41337: {
      APX: '68', JAP: '75', MRC: '58', MPH: '60', PMS: '70', SDZ: '55', TEV: '70',
    },
    41363: { PMS: '45', SDZ: '30' },
    41440: {
      APX: '41', JAP: '40', MPH: '60', PMS: '65', SDZ: '50', TEV: '70', AHC: '66',
    },
    41897: { PMS: '35', SDZ: '45', TEV: '25' },
    42783: {
      JAP: '75', APX: '68', MRC: '58', MPH: '60', PMS: '70', SDZ: '55', TEV: '70',
    },
    42922: {
      APX: '64', JAP: '57', MRC: '40', MPH: '55', PMS: '72', SDZ: '60', RAN: '62', TEV: '1',
    },
    42923: {
      APX: '64', JAP: '50', MRC: '60', MPH: '55', PMS: '72', SDZ: '60', RAN: '62', TEV: '1',
    },
    43060: { AAA: '1' },
    43258: { APX: '60', SDZ: '55', TEV: '55' },
    43367: { PMS: '1' },
    43714: { SDZ: '55' },
    43801: { PMS: '45', TEV: '1' },
    43879: {
      APX: '40', JAP: '50', MRC: '50', SDZ: '45', TEV: '1',
    },
    44186: { JAP: '40' },
    44632: {
      APX: '51', JAP: '40', MPH: '1', PMS: '35', SDZ: '48', TEV: '1', AHC: '70',
    },
    44633: {
      APX: '51', JAP: '40', MPH: '1', PMS: '35', SDZ: '48', TEV: '1', AHC: '70',
    },
    45052: { APX: '20', SDZ: '1' },
    45191: {
      APX: '69', JAP: '70', PMS: '60', MPH: '60', SDZ: '60',
    },
    45215: { TAR: '17' },
    45216: { TAR: '17' },
    45425: {
      JAP: '1', PMS: '35', SDZ: '55', TEV: '25',
    },
    45772: {
      APX: '70', JAP: '75', MRC: '60', PMS: '75', SDZ: '67', RAN: '77', TEV: '67', AHC: '65',
    },
    46043: {
      APX: '40', MRC: '25', PMS: '25', AAA: '1',
    },
    46046: {
      APX: '40', JAP: '0', MRC: '25', PMS: '60', AAA: '1',
    },
    46047: {
      APX: '40', MRC: '25', PMS: '60', AAA: '1',
    },
    46172: { APX: '10' },
    46203: {
      APX: '68', JAP: '77', MRC: '53', MPH: '50', PMS: '65', TEV: '60',
    },
    46204: { APX: '68' },
    46213: {
      APX: '70', JAP: '60', MPH: '70', PMS: '72', SDZ: '65', TEV: '75',
    },
    46214: {
      APX: '70', JAP: '60', MPH: '70', PMS: '72', SDZ: '65', TEV: '75',
    },
    46217: { APX: '70' },
    46222: {
      APX: '67', JAP: '70', MRC: '55', MPH: '60', PMS: '60', TEV: '1',
    },
    46223: {
      APX: '67', JAP: '70', MRC: '55', MPH: '60', PMS: '60', TEV: '1',
    },
    46231: {
      APX: '67', JAP: '50', MRC: '61', MPH: '60', PMS: '65', TEV: '65',
    },
    46232: {
      APX: '67', JAP: '50', MRC: '61', MPH: '60', PMS: '65', TEV: '65',
    },
    46233: {
      APX: '67', JAP: '50', MRC: '61', MPH: '60', PMS: '65', TEV: '65',
    },
    46241: {
      APX: '40', JAP: '1', PMS: '45', TEV: '50',
    },
    46242: {
      APX: '40', JAP: '1', PMS: '45', TEV: '50',
    },
    46403: {
      APX: '45', JAP: '1', PMS: '1', SDZ: '40', RAN: '52', TEV: '1',
    },
    46404: {
      APX: '45', JAP: '1', PMS: '1', SDZ: '40', RAN: '52', TEV: '1',
    },
    46405: {
      APX: '45', JAP: '1', PMS: '1', SDZ: '40', RAN: '52', TEV: '1',
    },
    46450: { APX: '54', PMS: '65', SDZ: '50' },
    46451: {
      APX: '54', PMS: '65', SDZ: '50', TEV: '1',
    },
    46649: {
      APX: '40', SDZ: '40', RAN: '1', TEV: '80',
    },
    46650: {
      APX: '40', SDZ: '40', RAN: '1', TEV: '80',
    },
    46941: {
      APX: '10', JAP: '60', MPH: '65', PMS: '65', SDZ: '62', TEV: '1', AHC: '66',
    },
    47198: {
      APX: '50', JAP: '40', MPH: '55', PMS: '42', TEV: '1', AHC: '69',
    },
    47304: {
      APX: '20', MPH: '10', SDZ: '45', RAN: '52',
    },
    47324: {
      JAP: '55', SDZ: '35', TEV: '25', AHC: '74',
    },
    47327: { SDZ: '55', TEV: '1' },
    47612: { APX: '10', SDZ: '35' },
    48333: { AAA: '20' },
    48354: { JAP: '1', TEV: '1' },
    48399: { SDZ: '20' },
    48400: { SDZ: '20' },
    48401: { SDZ: '20' },
    48456: {
      APX: '70', JAP: '65', MRC: '45', MPH: '75', RAN: '77', TEV: '1',
    },
    48703: { APX: '40', SDZ: '1', TEV: '1' },
    48843: {
      APX: '75', JAP: '65', MPH: '0', PMS: '70', TEV: '70',
    },
    48986: {
      APX: '20', JAP: '35', MPH: '1', TEV: '1',
    },
    49856: {
      APX: '75', JAP: '65', MRC: '60', MPH: '75', PMS: '55',
    },
    50289: {
      APX: '40', JAP: '57', PMS: '55', SDZ: '40', TEV: '1', AHC: '69', NRI: '60',
    },
    50290: {
      APX: '40', JAP: '57', PMS: '55', SDZ: '40', TEV: '1', AHC: '69', NRI: '60',
    },
    50364: {
      APX: '60', JAP: '50', PMS: '72', SDZ: '67', TEV: '75',
    },
    50628: { JAP: '35' },
    50631: {
      APX: '54', JAP: '70', PMS: '60', TEV: '60',
    },
    50712: {
      APX: '70', JAP: '82', MRC: '63', MPH: '65', PMS: '35', SDZ: '62', RAN: '77', TEV: '75', AHC: '63',
    },
    50760: {
      APX: '70', JAP: '82', MRC: '63', MPH: '65', PMS: '35', SDZ: '62', RAN: '77', TEV: '75', AHC: '63',
    },
    50805: { SDZ: '25' },
    50831: { APX: '20' },
    51036: { MPH: '10' },
    51037: { MPH: '10' },
    51214: {
      APX: '40', JAP: '50', RAN: '57', TEV: '60', PMS: '40', MRC: '55', MPH: '45', SDZ: '50', AHC: '68',
    },
    51246: {
      APX: '30', JAP: '35', MPH: '25', PMS: '35', TEV: '65',
    },
    51333: {
      APX: '70', MPH: '75', PMS: '65', SDZ: '63',
    },
    51334: {
      APX: '70', MPH: '75', PMS: '65', SDZ: '63',
    },
    51335: {
      APX: '70', MPH: '75', PMS: '65', SDZ: '63',
    },
    51336: {
      APX: '70', MPH: '75', PMS: '65', SDZ: '63',
    },
    51657: {
      APX: '80', JAP: '65', MRC: '1', MPH: '75', PMS: '80', RAN: '72', TEV: '80',
    },
    51784: {
      APX: '58', JAP: '75', PMS: '60', SDZ: '57', TAR: '77', TEV: '60', AHC: '66',
    },
    51785: {
      APX: '58', JAP: '75', TAR: '77', TEV: '60', PMS: '60', SDZ: '57', AHC: '67',
    },
    51786: {
      APX: '58', JAP: '75', PMS: '60', SDZ: '57', TAR: '77', TEV: '60', AHC: '66',
    },
    51884: { APX: '60', JAP: '50' },
    51971: { JAP: '40' },
    52050: {
      APX: '20', JAP: '65', PMS: '55', SDZ: '50',
    },
    52898: {
      APX: '70', MPH: '75', PMS: '65', SDZ: '63',
    },
    52911: { SDZ: '1', TAR: '1' },
    52944: {
      APX: '58', JAP: '75', PMS: '60', SDZ: '57', TAR: '77', TEV: '60', AHC: '65',
    },
    53006: { RAN: '57', TEV: '1' },
    53007: { RAN: '57', TEV: '1' },
    53518: { TEV: '55', PMS: '54' },
    53762: {
      APX: '60', JAP: '70', MRC: '1', TEV: '1',
    },
    57690: {
      JAP: '55', SDZ: '35', TEV: '25', AHC: '74',
    },
    57799: {
      APX: '65', JAP: '70', MPH: '40', PMS: '70', SDZ: '65', RAN: '72', AHC: '65', TEV: '70', NRI: '60',
    },
    57800: {
      APX: '65', JAP: '70', MPH: '40', PMS: '70', SDZ: '65', RAN: '72', AHC: '65', TEV: '70', NRI: '60',
    },
    57801: {
      JAP: '70', APX: '65', MPH: '40', PMS: '70', SDZ: '65', RAN: '72', TEV: '70', AHC: '65', NRI: '60',
    },
    57803: {
      APX: '65', JAP: '70', MPH: '40', PMS: '70', SDZ: '65', RAN: '72', TEV: '70', AHC: '65', NRI: '60',
    },
    57892: {
      APX: '65', JAP: '65', MPH: '70', MRC: '60', PMS: '65', SDZ: '61', TEV: '70',
    },
    57893: {
      APX: '65', JAP: '70', MPH: '70', MRC: '60', PMS: '65', SDZ: '61', TEV: '70',
    },
    57982: {
      JAP: '40', PMS: '45', SDZ: '35', RAN: '62', TEV: '1',
    },
    57983: {
      JAP: '40', PMS: '45', SDZ: '35', RAN: '62', TEV: '1',
    },
    59082: { PMS: '35', SDZ: '45', TEV: '25' },
    59110: { SDZ: '30', TEV: '1', APX: '49' },
    59136: { TEV: '50' },
    60225: {
      APX: '70', MPH: '75', PMS: '65', SDZ: '63',
    },
    60781: { SDZ: '25' },
    60838: { APX: '10' },
    60897: { APX: '10', TEV: '1' },
    60898: { APX: '10', TEV: '1' },
    61199: { SDZ: '25' },
    61200: { SDZ: '25', TAR: '1' },
    62065: { APX: '50', SDZ: '45' },
    62313: { PMS: '25', RAN: '1' },
    62314: { PMS: '25', RAN: '1' },
    62588: { JAP: '25', PMS: '1' },
    62651: { JAP: '25' },
    62663: { APX: '10', TEV: '50' },
    62750: {
      APX: '40', SDZ: '40', TEV: '70', AHC: '66', NRI: '85',
    },
    63369: {
      APX: '64', JAP: '75', MRC: '63', SDZ: '50', RAN: '77', TEV: '50',
    },
    63370: {
      APX: '64', JAP: '75', MRC: '63', SDZ: '50', RAN: '77', TEV: '50',
    },
    63371: {
      APX: '64', JAP: '75', MRC: '63', SDZ: '50', RAN: '77', TEV: '1',
    },
    63422: { TAR: '1' },
    63925: { APX: '60', PMS: '72', TEV: '75' },
    64050: { AAA: '1' },
    64051: { AAA: '1' },
    64285: { JAP: '1', SDZ: '55' },
    64830: { JAP: '30', MRC: '25', TEV: '65' },
    65272: { APX: '60', SDZ: '55', TEV: '55' },
    65975: {
      APX: '20', MPH: '10', SDZ: '45', RAN: '52',
    },
    66215: { JAP: '30' },
    66815: { APX: '1' },
    67899: { APX: '10', JAP: '20', TEV: '40' },
    68880: { SDZ: '48' },
    71804: { SDZ: '5' },
    73369: { APX: '10' },
    73371: { APX: '10' },
  },
  updatedAt: '2022-05-06T20:44:59.230Z',
  userID: '5b5572fbc7c30b7566668f58',
}
